/* eslint-disable no-restricted-syntax */

import ClientPJProps from '../dto/clientPJ';

export default function checkNull(model: any, objFromApi: any) {
  const newObj = { ...model };
  for (const member in objFromApi) {
    if (objFromApi[member] != null) {
      newObj[member] = objFromApi[member];
    }
  }
  return newObj;
}
