/* eslint-disable max-len */
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import api from '../../config/axios';
import DefaultLayout from '../../Layout';
import GatewayPrevisionModal from './GatewayPrevisionEditModal';

declare type PaymentOptions =
'debit'|
'credit_1' |
'credit_2' |
'credit_3' |
'credit_4' |
'credit_5' |
'credit_6' |
'credit_7' |
'credit_8' |
'credit_9' |
'credit_10' |
'credit_11' |
'credit_12' |
'credit_13' |
'credit_14' |
'credit_15' |
'credit_16' |
'credit_17' |
'credit_18' |
'PIX' |
'BOLETO' |
'DINHEIRO' |
'TED' |
'DOC'

export type PaymentPrizes = {
    id: number,
    intoBankAccountInDays: number,
    isCalcBasedInBussinessDays: boolean,
    isCard: boolean,
    paymentGatewayId: number,
    paymentOption: PaymentOptions,
}

export default function GatewaysPrevision() {
  const [loading, setLoading] = useState(false);
  const [gatewayTarget, setGatewayTarget] = useState('');
  const [gatewayMethodTarget, setGatewayMethodTarget] = useState('');
  const [modalRef] = useState(React.createRef<any>());
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [data, setData] = useState<PaymentPrizes[]>([]);
  const [checked, setChecked] = useState<null | boolean>(false);
  const [input, setInput] = useState<null | string>(null);

  async function fetchPaymentGatewayPrevision() {
    try {
      setLoading(true);
      const response = await api.get('/admin/configuration/payment-gateway-prevision', { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      console.log(response.data);
      setData(response.data);
      setLoading(false);
    } catch (err) {
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      setLoading(false);
    }
  }

  function showMethodPrize(gatewayId: number, method: PaymentOptions) {
    const filtered = data.filter((item) => item.paymentGatewayId === gatewayId && item.paymentOption === method);
    if (filtered.length > 0) {
      return filtered[0];
    }
    return null;
  }

  function showText(gatewayId: number, method: PaymentOptions) {
    const ctxMethod = showMethodPrize(gatewayId, method);
    if (ctxMethod) {
      if (ctxMethod.intoBankAccountInDays === 0) {
        return 'Mesmo dia';
      }
      return `${ctxMethod.intoBankAccountInDays} dias ${ctxMethod.isCalcBasedInBussinessDays ? 'útil(eis)' : 'corrido(s)'}`;
    }
    return 'Erro ao buscar prazos do método';
  }

  async function changeGalaxPayPIX() {
    try {
      setLoading(true);
      const filtered = data.filter((item) => item.paymentGatewayId === 5 && item.paymentOption === 'PIX');
      const response = await api.put(`/admin/configuration/payment-gateway-prevision/${filtered[0].id}`, {
        intoBankAccountInDays: Number(input),
        isCalcBasedInBussinessDays: checked,
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function changeGalaxPayBOLETO() {
    try {
      setLoading(true);
      const filtered = data.filter((item) => item.paymentGatewayId === 5 && item.paymentOption === 'BOLETO');
      console.log({
        intoBankAccountInDays: Number(input),
        isCalcBasedInBussinessDays: checked,
      });
      const response = await api.put(`/admin/configuration/payment-gateway-prevision/${filtered[0].id}`, {
        intoBankAccountInDays: Number(input),
        isCalcBasedInBussinessDays: checked,
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      await fetchPaymentGatewayPrevision();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function changeGalaxPayCredit() {
    try {
      setLoading(true);
      const arrayFunctions: Promise<AxiosResponse<any, any>>[] = [];
      for (let x = 0; x < 18; x += 1) {
        const filtered = data.filter((item) => item.paymentGatewayId === 5 && item.paymentOption === `credit_${x + 1}`);
        const response = api.put(`/admin/configuration/payment-gateway-prevision/${filtered[0].id}`, {
          intoBankAccountInDays: Number(input),
          isCalcBasedInBussinessDays: checked,
        }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
        arrayFunctions.push(response);
      }

      await Promise.all(arrayFunctions);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function changePagAzulBOLETO() {
    try {
      setLoading(true);
      const filtered = data.filter((item) => item.paymentGatewayId === 3 && item.paymentOption === 'BOLETO');
      const response = await api.put(`/admin/configuration/payment-gateway-prevision/${filtered[0].id}`, {
        intoBankAccountInDays: Number(input),
        isCalcBasedInBussinessDays: checked,
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function changeCieloDebit() {
    try {
      setLoading(true);
      const filtered = data.filter((item) => item.paymentGatewayId === 4 && item.paymentOption === 'debit');
      const response = await api.put(`/admin/configuration/payment-gateway-prevision/${filtered[0].id}`, {
        intoBankAccountInDays: Number(input),
        isCalcBasedInBussinessDays: checked,
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function changeCieloCredit() {
    try {
      setLoading(true);
      const arrayFunctions: Promise<AxiosResponse<any, any>>[] = [];
      for (let x = 0; x < 18; x += 1) {
        const filtered = data.filter((item) => item.paymentGatewayId === 4 && item.paymentOption === `credit_${x + 1}`);
        const response = api.put(`/admin/configuration/payment-gateway-prevision/${filtered[0].id}`, {
          intoBankAccountInDays: Number(input),
          isCalcBasedInBussinessDays: checked,
        }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
        arrayFunctions.push(response);
      }

      await Promise.all(arrayFunctions);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function changeRedesantaBOLETO() {
    try {
      setLoading(true);
      const filtered = data.filter((item) => item.paymentGatewayId === 2 && item.paymentOption === 'BOLETO');
      const response = await api.put(`/admin/configuration/payment-gateway-prevision/${filtered[0].id}`, {
        intoBankAccountInDays: Number(input),
        isCalcBasedInBussinessDays: checked,
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function changeRedesantaPIX() {
    try {
      setLoading(true);
      const filtered = data.filter((item) => item.paymentGatewayId === 2 && item.paymentOption === 'PIX');
      const response = await api.put(`/admin/configuration/payment-gateway-prevision/${filtered[0].id}`, {
        intoBankAccountInDays: Number(input),
        isCalcBasedInBussinessDays: checked,
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function changeRedesantaDOC() {
    try {
      setLoading(true);
      const filtered = data.filter((item) => item.paymentGatewayId === 2 && item.paymentOption === 'DOC');
      const response = await api.put(`/admin/configuration/payment-gateway-prevision/${filtered[0].id}`, {
        intoBankAccountInDays: Number(input),
        isCalcBasedInBussinessDays: checked,
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function changeRedesantaTED() {
    try {
      setLoading(true);
      const filtered = data.filter((item) => item.paymentGatewayId === 2 && item.paymentOption === 'TED');
      const response = await api.put(`/admin/configuration/payment-gateway-prevision/${filtered[0].id}`, {
        intoBankAccountInDays: Number(input),
        isCalcBasedInBussinessDays: checked,
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function changePagAulPresencialPIX() {
    try {
      setLoading(true);
      const filtered = data.filter((item) => item.paymentGatewayId === 1 && item.paymentOption === 'PIX');
      const response = await api.put(`/admin/configuration/payment-gateway-prevision/${filtered[0].id}`, {
        intoBankAccountInDays: Number(input),
        isCalcBasedInBussinessDays: checked,
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function changePagAulPresencialDebit() {
    try {
      setLoading(true);
      const filtered = data.filter((item) => item.paymentGatewayId === 1 && item.paymentOption === 'debit');
      const response = await api.put(`/admin/configuration/payment-gateway-prevision/${filtered[0].id}`, {
        intoBankAccountInDays: Number(input),
        isCalcBasedInBussinessDays: checked,
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function changePagAulPresencialCredit() {
    try {
      setLoading(true);
      const arrayFunctions: Promise<AxiosResponse<any, any>>[] = [];
      for (let x = 0; x < 18; x += 1) {
        const filtered = data.filter((item) => item.paymentGatewayId === 1 && item.paymentOption === `credit_${x + 1}`);
        const response = api.put(`/admin/configuration/payment-gateway-prevision/${filtered[0].id}`, {
          intoBankAccountInDays: Number(input),
          isCalcBasedInBussinessDays: checked,
        }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
        arrayFunctions.push(response);
      }

      await Promise.all(arrayFunctions);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  useEffect(() => {
    console.log(checked);
  }, [input, checked]);

  useEffect(() => {
    fetchPaymentGatewayPrevision();
  }, []);

  return (
    <DefaultLayout
      pageTitle="Gateways - Previsão para repasse"
      loading={loading}
      showToastSuccess={false}
      showToastError={false}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={null}
      setCloseRightSlider={() => null}
    >
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Prazos para repasse por Gateway de pagamento</h1>

          <div className="col-12">
            <h3 className="text-center fw-bold mt-4" style={{ color: '#6200EE' }}>Galaxpay</h3>
            <div className="col-12 d-flex justify-content-center mt-4">
              <div className="card-container-gateway-prizes">
                {showMethodPrize(5, 'PIX') && (
                <GatewayPrevisionModal
                  id="galaxpay-pix"
                  changeFunction={() => changeGalaxPayPIX()}
                  gatewayMethodTarget="PIX"
                  gatewayTarget="GALAXPAY"
                  gatewayProps={showMethodPrize(5, 'PIX') || {} as PaymentPrizes}
                  checked={checked}
                  text={input}
                  changeInput={(arg) => setInput(arg)}
                  changeChecked={(arg) => setChecked(arg)}
                />
                )}
                <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={modalRef}>
                  Launch static backdrop modal
                </button>
                <h5 className="text-center fw-bold text-muted">PIX</h5>
                <p className="text-center mt-2 mb-4 fw-bold">{showText(5, 'PIX')}</p>
                <button type="button" className="modal-toggler" onClick={() => null} data-bs-toggle="modal" data-bs-target="#galaxpay-pix">Editar</button>
              </div>
              <div className="card-container-gateway-prizes">
                {showMethodPrize(5, 'BOLETO') && (
                <GatewayPrevisionModal
                  checked={checked}
                  text={input}
                  id="galaxpay-boleto"
                  changeFunction={() => changeGalaxPayBOLETO()}
                  gatewayMethodTarget="BOLETO"
                  gatewayTarget="GALAXPAY"
                  gatewayProps={showMethodPrize(5, 'BOLETO')!}
                  changeInput={(arg) => setInput(arg)}
                  changeChecked={(arg) => setChecked(arg)}
                />
                )}
                <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={modalRef}>
                  Launch static backdrop modal
                </button>
                <h5 className="text-center fw-bold text-muted">BOLETO</h5>
                <p className="text-center mt-2 mb-4 fw-bold">{showText(5, 'BOLETO')}</p>
                <button type="button" className="modal-toggler" onClick={() => null} data-bs-toggle="modal" data-bs-target="#galaxpay-boleto">Editar</button>
              </div>
              <div className="card-container-gateway-prizes">
                {showMethodPrize(5, 'credit_10') && (
                <GatewayPrevisionModal
                  checked={checked}
                  text={input}
                  id="galaxpay-credito"
                  changeFunction={() => changeGalaxPayCredit()}
                  gatewayMethodTarget="CRÉDITO 1X à 18X"
                  gatewayTarget="GALAXPAY"
                  gatewayProps={showMethodPrize(5, 'credit_10') || {} as PaymentPrizes}
                  changeInput={(arg) => setInput(arg)}
                  changeChecked={(arg) => setChecked(arg)}
                />
                )}

                <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={modalRef}>
                  Launch static backdrop modal
                </button>
                <h5 className="text-center fw-bold text-muted">CRÉDITO 1X à 18X</h5>
                <p className="text-center mt-2 mb-4 fw-bold">{showText(5, 'credit_10')}</p>
                <button type="button" className="modal-toggler" onClick={() => null} data-bs-toggle="modal" data-bs-target="#galaxpay-credito">Editar</button>
              </div>
            </div>
          </div>

          <div className="col-12">
            <h3 className="text-center fw-bold mt-4" style={{ color: '#6200EE' }}>PagAzul WEB</h3>
            <div className="col-12 d-flex justify-content-center mt-4">
              <div className="card-container-gateway-prizes">
                {showMethodPrize(3, 'BOLETO') && (
                <GatewayPrevisionModal
                  checked={checked}
                  text={input}
                  id="pagazul-boleto"
                  changeFunction={() => changePagAzulBOLETO()}
                  gatewayMethodTarget="BOLETO"
                  gatewayTarget="PagAzul"
                  gatewayProps={showMethodPrize(3, 'BOLETO') || {} as PaymentPrizes}
                  changeInput={(arg) => setInput(arg)}
                  changeChecked={(arg) => setChecked(arg)}
                />
                )}

                <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={modalRef}>
                  Launch static backdrop modal
                </button>
                <h5 className="text-center fw-bold text-muted">BOLETO</h5>
                <p className="text-center mt-2 mb-4 fw-bold">{showText(3, 'BOLETO')}</p>
                <button type="button" className="modal-toggler" onClick={() => null} data-bs-toggle="modal" data-bs-target="#pagazul-boleto">Editar</button>
              </div>
            </div>
          </div>

          <div className="col-12">
            <h3 className="text-center fw-bold mt-4" style={{ color: '#6200EE' }}>PagAzul PRESENCIAL</h3>
            <div className="col-12 d-flex justify-content-center mt-4">
              <div className="card-container-gateway-prizes">
                {showMethodPrize(1, 'PIX') && (
                <GatewayPrevisionModal
                  checked={checked}
                  text={input}
                  id="pagazul-presencial-pix"
                  changeFunction={() => changePagAulPresencialPIX()}
                  gatewayMethodTarget="PIX"
                  gatewayTarget="PagAzul Presencial"
                  gatewayProps={showMethodPrize(1, 'PIX') || {} as PaymentPrizes}
                  changeInput={(arg) => setInput(arg)}
                  changeChecked={(arg) => setChecked(arg)}
                />
                )}

                <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={modalRef}>
                  Launch static backdrop modal
                </button>
                <h5 className="text-center fw-bold text-muted">PIX</h5>
                <p className="text-center mt-2 mb-4 fw-bold">{showText(1, 'PIX')}</p>
                <button type="button" className="modal-toggler" onClick={() => null} data-bs-toggle="modal" data-bs-target="#pagazul-presencial-pix">Editar</button>
              </div>
              <div className="card-container-gateway-prizes">
                {showMethodPrize(1, 'debit') && (
                <GatewayPrevisionModal
                  checked={checked}
                  text={input}
                  id="pagazul-presencial-debito"
                  changeFunction={() => changePagAulPresencialDebit()}
                  gatewayMethodTarget="DÉBITO"
                  gatewayTarget="PagAzul Presencial"
                  gatewayProps={showMethodPrize(1, 'debit') || {} as PaymentPrizes}
                  changeInput={(arg) => setInput(arg)}
                  changeChecked={(arg) => setChecked(arg)}
                />
                )}
                <h5 className="text-center fw-bold text-muted">DÉBITO</h5>
                <p className="text-center mt-2 mb-4 fw-bold">{showText(1, 'debit')}</p>
                <button type="button" className="modal-toggler" onClick={() => null} data-bs-toggle="modal" data-bs-target="#pagazul-presencial-debito">Editar</button>
              </div>
              <div className="card-container-gateway-prizes">
                {showMethodPrize(1, 'credit_1') && (
                <GatewayPrevisionModal
                  checked={checked}
                  text={input}
                  id="pagazul-presencial-credito"
                  changeFunction={() => changePagAulPresencialCredit()}
                  gatewayMethodTarget="CRÉDITO"
                  gatewayTarget="PagAzul Presencial"
                  gatewayProps={showMethodPrize(1, 'credit_1') || {} as PaymentPrizes}
                  changeInput={(arg) => setInput(arg)}
                  changeChecked={(arg) => setChecked(arg)}
                />
                )}
                <h5 className="text-center fw-bold text-muted">CRÉDITO 1X à 18X</h5>
                <p className="text-center mt-2 mb-4 fw-bold">{showText(1, 'credit_1')}</p>
                <button type="button" className="modal-toggler" onClick={() => null} data-bs-toggle="modal" data-bs-target="#pagazul-presencial-credito">Editar</button>
              </div>
            </div>
          </div>

          <div className="col-12">
            <h3 className="text-center fw-bold mt-4" style={{ color: '#6200EE' }}>CIELO</h3>
            <div className="col-12 d-flex justify-content-center mt-4">
              <div className="card-container-gateway-prizes">
                {showMethodPrize(4, 'debit') && (
                <GatewayPrevisionModal
                  checked={checked}
                  text={input}
                  id="cielo-debito"
                  changeFunction={() => changeCieloDebit()}
                  gatewayMethodTarget="DÉBITO"
                  gatewayTarget="CIELO"
                  gatewayProps={showMethodPrize(4, 'debit') || {} as PaymentPrizes}
                  changeInput={(arg) => setInput(arg)}
                  changeChecked={(arg) => setChecked(arg)}
                />
                )}

                <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={modalRef}>
                  Launch static backdrop modal
                </button>
                <h5 className="text-center fw-bold text-muted">DÉBITO</h5>
                <p className="text-center mt-2 mb-4 fw-bold">{showText(4, 'debit')}</p>
                <button type="button" className="modal-toggler" onClick={() => null} data-bs-toggle="modal" data-bs-target="#cielo-debito">Editar</button>
              </div>
              <div className="card-container-gateway-prizes">
                {showMethodPrize(4, 'credit_1') && (
                <GatewayPrevisionModal
                  checked={checked}
                  text={input}
                  id="cielo-credito"
                  changeFunction={() => changeCieloCredit()}
                  gatewayMethodTarget="CRÉDITO"
                  gatewayTarget="CIELO"
                  gatewayProps={showMethodPrize(4, 'credit_1') || {} as PaymentPrizes}
                  changeInput={(arg) => setInput(arg)}
                  changeChecked={(arg) => setChecked(arg)}
                />
                )}

                <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={modalRef}>
                  Launch static backdrop modal
                </button>
                <h5 className="text-center fw-bold text-muted">CRÉDITO</h5>
                <p className="text-center mt-2 mb-4 fw-bold">{showText(4, 'credit_1')}</p>
                <button type="button" className="modal-toggler" onClick={() => null} data-bs-toggle="modal" data-bs-target="#cielo-credito">Editar</button>
              </div>
            </div>
          </div>
          <div className="col-12">
            <h3 className="text-center fw-bold mt-4" style={{ color: '#6200EE' }}>REDESANTA</h3>
            <div className="col-12 d-flex justify-content-center mt-4">
              {/* <div className="card-container-gateway-prizes">
                {showMethodPrize(2, 'BOLETO') && (
                <GatewayPrevisionModal
                  checked={checked}
                  text={input}
                  id="redesanta-boleto"
                  changeFunction={() => changeRedesantaBOLETO()}
                  gatewayMethodTarget="BOLETO"
                  gatewayTarget="REDESANTA"
                  gatewayProps={showMethodPrize(2, 'BOLETO') || {} as PaymentPrizes}
                  changeInput={(arg) => setInput(arg)}
                  changeChecked={(arg) => setChecked(arg)}
                />
                )}

                <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={modalRef}>
                  Launch static backdrop modal
                </button>
                <h5 className="text-center fw-bold text-muted">BOLETO</h5>
                <p className="text-center mt-2 mb-4 fw-bold">{showText(2, 'BOLETO')}</p>
                <button type="button" className="modal-toggler" onClick={() => null} data-bs-toggle="modal" data-bs-target="#redesanta-boleto">Editar</button>
              </div> */}
              <div className="card-container-gateway-prizes">
                {showMethodPrize(2, 'PIX') && (
                <GatewayPrevisionModal
                  checked={checked}
                  text={input}
                  id="redesanta-pix"
                  changeFunction={() => changeRedesantaPIX()}
                  gatewayMethodTarget="PIX"
                  gatewayTarget="REDESANTA"
                  gatewayProps={showMethodPrize(2, 'PIX') || {} as PaymentPrizes}
                  changeInput={(arg) => setInput(arg)}
                  changeChecked={(arg) => setChecked(arg)}
                />
                )}

                <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={modalRef}>
                  Launch static backdrop modal
                </button>
                <h5 className="text-center fw-bold text-muted">PIX</h5>
                <p className="text-center mt-2 mb-4 fw-bold">{showText(2, 'PIX')}</p>
                <button type="button" className="modal-toggler" onClick={() => null} data-bs-toggle="modal" data-bs-target="#redesanta-pix">Editar</button>
              </div>
              <div className="card-container-gateway-prizes">
                {showMethodPrize(2, 'DOC') && (
                <GatewayPrevisionModal
                  checked={checked}
                  text={input}
                  id="redesanta-doc"
                  changeFunction={() => changeRedesantaDOC()}
                  gatewayMethodTarget="DOC"
                  gatewayTarget="REDESANTA"
                  gatewayProps={showMethodPrize(2, 'DOC') || {} as PaymentPrizes}
                  changeInput={(arg) => setInput(arg)}
                  changeChecked={(arg) => setChecked(arg)}
                />
                )}

                <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={modalRef}>
                  Launch static backdrop modal
                </button>
                <h5 className="text-center fw-bold text-muted">DOC</h5>
                <p className="text-center mt-2 mb-4 fw-bold">{showText(2, 'DOC')}</p>
                <button type="button" className="modal-toggler" onClick={() => null} data-bs-toggle="modal" data-bs-target="#redesanta-doc">Editar</button>
              </div>
              <div className="card-container-gateway-prizes">
                {showMethodPrize(2, 'TED') && (
                <GatewayPrevisionModal
                  checked={checked}
                  text={input}
                  id="redesanta-ted"
                  changeFunction={() => changeRedesantaTED()}
                  gatewayMethodTarget="TED"
                  gatewayTarget="REDESANTA"
                  gatewayProps={showMethodPrize(2, 'TED') || {} as PaymentPrizes}
                  changeInput={(arg) => setInput(arg)}
                  changeChecked={(arg) => setChecked(arg)}
                />
                )}

                <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={modalRef}>
                  Launch static backdrop modal
                </button>
                <h5 className="text-center fw-bold text-muted">TED</h5>
                <p className="text-center mt-2 mb-4 fw-bold">{showText(2, 'TED')}</p>
                <button type="button" className="modal-toggler" onClick={() => null} data-bs-toggle="modal" data-bs-target="#redesanta-ted">Editar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
