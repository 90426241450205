/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ModalActionType, ModalProps } from '../../../../components/Modal';
import api from '../../../../config/axios';
import { ProductsProps } from '../../../../dto/product';
import {
  maskCep, maskCPF, maskPhone, unmaskData,
} from '../../../../helpers/inputMasks.js';
import { normalizeToApiCardPaymentWays } from '../../../../helpers/showCardMethods';
import DefaultLayout from '../../../../Layout';
import { PagVipRefProps } from '../../../expensesAndProfits/InstallmentModal';
import EditContract from './EditContract';
import EditHolder from './EditHolder';
import EditPaymentPFLead from './EditPayment';

// eslint-disable-next-line no-shadow
export enum ActualPage {
    HOLDER,
    DEPENDENTS,
    CONTRACT,
    PAYMENT,
}

export type InstallmentDTO = {
  id: number,
  paymentGatewayId: number,
  dueDate: string,
  status: string,
  paymentDate: string,
  value: number,
  taxUsed: number,
  pagvipRef: null | PagVipRefProps,
  isSubscription: boolean,
  galaxpayUrl?: null | string
  isMain: boolean,
  galaxMyId?: null | string
  subscriptionNumber: null | number,
  subscriptionRef: null | string,
  nonCardTaxes: string | null,
  card: boolean,
  flag: null | string,
  cardLastDigits: null | string,
  cardPaymentOption: null | string
}

export type PaymentDTO = {
  contractPfId: number;
  contractValue: number;
  discount: number;
  aditional: number;
  installments: number;
  totalValue: number;
  approvalDate: string;
}

export type HolderDTO = {
  id: number,
  name: string,
  sex: 'm' | 'f',
  addressIbge: null | string,
  cpf: string,
  birthday: string,
  rg: string,
  emitent: string,
  addressStreet: string,
  addressNumber: string,
  addressComplement: string,
  addressNeighborhood: string,
  addressCity: string,
  addressState: string,
  addressZipcode: string,
  phone: string,
  phone2: string,
  email: string,
  observation?: string | null,
}

export type DependentDTO = {
  id: number,
  uuidCtx: string,
  addressIbge: string | null
  name: string,
  sex: 'm' | 'f',
  cpf: string,
  birthday: string,
  rg: string,
  role: 'son' | 'spouse' | 'other',
  emitent: string,
  addressStreet: string,
  addressNumber: string,
  addressComplement: string,
  addressNeighborhood: string,
  addressCity: string,
  addressState: string,
  addressZipcode: string,
  phone: string,
  phone2: string,
  email: string,
  observation?: null | string
}

export type ContractDTO = {
  id: number,
  productId: number,
  value: number,
  totalDependentsEnabled: number,
  signatureDate: string,
  dueDate: string
}

export default function CreateLead() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [actualPage, setActualPage] = useState(ActualPage.HOLDER);
  const [haveInstallments, setHaveInstallments] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, _] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  /* Holder */
  const [holder, setHolder] = useState<HolderDTO>({
    id: 0,
    cpf: '',
    email: '',
    name: '',
    rg: '',
    addressIbge: '',
    emitent: 'SSP/MA',
    birthday: '',
    addressStreet: '',
    addressNumber: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    sex: 'm',
    addressState: 'MA',
    addressZipcode: '',
    phone: '',
    phone2: '',
  });
  const [isConverted, setIsConverted] = useState(false);
  const [dependents, setDependents] = useState<DependentDTO[]>([]);
  const [originalDependents, setOriginalDependents] = useState<DependentDTO[]>([]);
  /* Contract */
  const [contract, setContract] = useState<ContractDTO>({
    id: 0,
    productId: 0,
    value: 0,
    totalDependentsEnabled: 0,
    signatureDate: '',
    dueDate: '',
  });
  /* Payments */
  const [payment, setPayment] = useState<PaymentDTO>({
    contractPfId: 0,
    contractValue: 0,
    discount: 0,
    aditional: 0,
    installments: 0,
    totalValue: 0,
    approvalDate: '',
  });
  const [installments, setInstallments] = useState<InstallmentDTO[]>([]);
  const [originalInstallments, setOriginalInstallments] = useState<InstallmentDTO[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [modalErrorRef] = useState(React.createRef<any>());

  const [selectedProduct, setSelectedProduct] = useState<ProductsProps>({
    id: 0,
    name: '',
    benefits: '',
    pricePerHolder: 0,
    pricePerDependent: 0,
    isActive: true,
  });
  const [products, setProducts] = useState<ProductsProps[]>([]);

  function filterNewDependents() {
    return dependents.filter((item) => item.id === 0);
  }

  function filterNonNewDependents() {
    return dependents.filter((item) => item.id !== 0);
  }

  function filterDependentsToRemove() {
    const ids: number[] = [];
    const originalIds = originalDependents.map((item) => item.id);
    const dependentsIds = dependents.map((item) => item.id);
    originalIds.forEach((item) => {
      if (!dependentsIds.includes(item)) {
        ids.push(item);
      }
    });
    return ids;
  }

  function filterNewInstallments() {
    return installments.filter((item) => item.id === 0);
  }

  function filterNonNewInstallments() {
    return installments.filter((item) => item.id !== 0);
  }

  function filterInstallmentsToRemove() {
    const ids: number[] = [];
    const originalIds = originalInstallments.map((item) => item.id);
    const installmentsIds = installments.map((item) => item.id);
    originalIds.forEach((item) => {
      if (!installmentsIds.includes(item)) {
        ids.push(item);
      }
    });
    return ids;
  }

  async function removeComponents() {
    await api.post('/admin/lead-delete-components', {
      deleteInstallments: filterInstallmentsToRemove().map((item) => ({
        id: item,
      })),
      deleteDependentsIds: filterDependentsToRemove().map((item) => ({ id: item })),
    }, token);
  }

  function changeNumberOfDependent(dependentParams: number) {
    const globalContract = (dependentParams * selectedProduct.pricePerDependent) + selectedProduct.pricePerHolder;
    setContract({ ...contract, totalDependentsEnabled: dependentParams, value: Number((globalContract * 12).toFixed(2)) });
  }

  function changePricePerHolder(price: number) {
    const globalContract = price + (contract.totalDependentsEnabled * selectedProduct.pricePerDependent);
    setContract({ ...contract, value: Number((globalContract * 12).toFixed(2)) });
    setSelectedProduct({ ...selectedProduct, pricePerHolder: price });
  }

  function changePricePerDependent(price: number) {
    const globalContract = (contract.totalDependentsEnabled * price) + selectedProduct.pricePerHolder;
    setContract({ ...contract, value: Number((globalContract * 12).toFixed(2)) });
    setSelectedProduct({ ...selectedProduct, pricePerDependent: price });
  }

  async function sendLead(keepOnPage?: boolean) {
    try {
      if (isConverted) {
        setModal({ title: 'Erro', children: 'Não é possível editar um Lead que ja foi convertido em contrato.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      } else {
        setLoading(true);
        await removeComponents();
        await api.put('admin/lead-update', {
          holder: {
            ...holder,
            cpf: holder.cpf.length && holder.cpf.length > 0 ? unmaskData(holder.cpf) : holder.cpf,
            phone: holder.phone && holder.phone.length > 0 ? unmaskData(holder.phone) : holder.phone,
            phone2: holder.phone2 && holder.phone2.length > 0 ? unmaskData(holder.phone2) : holder.phone2,
            addressZipcode: holder.addressZipcode && holder.cpf.length && holder.addressZipcode.length > 0 ? unmaskData(holder.addressZipcode) : holder.addressZipcode,
            email: holder.email.length > 0 ? holder.email : null,
            name: holder.name.length > 0 ? holder.name : null,
            rg: holder.rg.length > 0 ? holder.rg : null,
            emitent: 'SSP/MA',
            birthday: holder.birthday.length > 0 ? holder.birthday : null,
            addressStreet: holder.addressStreet.length > 0 ? holder.addressStreet : null,
            addressNumber: holder.addressNumber.length > 0 ? holder.addressNumber : null,
            addressComplement: holder.addressComplement.length > 0 ? holder.addressComplement : null,
            addressNeighborhood: holder.addressNeighborhood.length > 0 ? holder.addressNeighborhood : null,
            addressCity: holder.addressCity.length > 0 ? holder.addressCity : null,
            observation: holder.observation && holder.observation.length > 0 ? holder.observation : null,
          },
          dependents: [...filterNonNewDependents().map((item) => ({
            ...item,
            cpf: item.cpf && item.cpf.length > 0 ? unmaskData(item.cpf) : item.cpf,
            phone: item.phone && item.phone.length > 0 ? unmaskData(item.phone) : item.phone,
            phone2: item.phone2 && item.phone2.length > 0 ? unmaskData(item.phone2) : item.phone2,
            addressZipcode: item.addressZipcode && item.addressZipcode.length > 0 ? unmaskData(item.addressZipcode) : item.addressZipcode,
            email: item.email.length > 0 ? item.email : null,
            name: item.name.length > 0 ? item.name : null,
            rg: item.rg.length > 0 ? item.rg : null,
            emitent: 'SSP/MA',
            birthday: item.birthday.length > 0 ? item.birthday : null,
            addressStreet: item.addressStreet.length > 0 ? item.addressStreet : null,
            addressNumber: item.addressNumber.length > 0 ? item.addressNumber : null,
            addressComplement: item.addressComplement.length > 0 ? item.addressComplement : null,
            addressNeighborhood: item.addressNeighborhood.length > 0 ? item.addressNeighborhood : null,
            addressCity: item.addressCity.length > 0 ? item.addressCity : null,
            observation: item.observation && item.observation.length > 0 ? item.observation : null,
          }))],
          newDependents: [...filterNewDependents().map((item) => ({
            ...item,
            cpf: item.cpf && item.cpf.length > 0 ? unmaskData(item.cpf) : null,
            phone: item.phone && item.phone.length > 0 ? unmaskData(item.phone) : null,
            phone2: item.phone2 && item.phone2.length > 0 ? unmaskData(item.phone2) : null,
            addressZipcode: item.addressZipcode && item.addressZipcode.length > 0 ? unmaskData(item.addressZipcode) : null,
            observation: item.observation && item.observation.length > 0 ? item.observation : null,
          }))],
          lead: {
            ...contract, id: Number(id), pricePerHolder: selectedProduct.pricePerHolder, pricePerDependent: selectedProduct.pricePerDependent,
          },
          installments: [...filterNonNewInstallments().map((item) => ({ ...item, cardPaymentOption: normalizeToApiCardPaymentWays(item.cardPaymentOption || '') }))],
          newInstallments: [...filterNewInstallments().map((item) => ({ ...item, cardPaymentOption: normalizeToApiCardPaymentWays(item.cardPaymentOption || '') }))],
        }, token);
        setLoading(false);
        setErrors([]);
        setShowToastSuccess(true);
        setTimeout(() => {
          setShowToastSuccess(false);
        }, 3000);
        if (!keepOnPage) {
          setActualPage(ActualPage.HOLDER);
        }
      }
    } catch (err: any) {
      const errorCtx: string[] = [];
      console.log(err.response.data);
      if (err.response && err.response.status === 422) {
        err.response.data.errors.forEach((e: any) => {
          errorCtx.push(e.message);
        });
      } else {
        errorCtx.push('Ocorreu um erro interno e não foi possível processar o cadastro do lead.');
      }
      setModal({
        title: 'Ocorreu um erro',
        children: [
          <div className="d-flex flex-column">
            {errorCtx.map((item) => (
              <p className="w-100 text-center">
                -
                {' '}
                {item}
              </p>
            ))}
          </div>,
        ],
        actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }],
      });
      setLoading(false);
    }
  }

  async function fetchAllProducts() {
    try {
      setLoading(true);
      const response = await api.get('admin/product', token);
      setProducts(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível localizar os produtos do contrato.', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
    }
  }

  function changeGlobalValue() {
    const globalContract = (contract.totalDependentsEnabled * selectedProduct.pricePerDependent) + selectedProduct.pricePerHolder;
    setContract({ ...contract, value: Number((globalContract * 12).toFixed(2)), productId: selectedProduct.id });
  }

  async function fetchLead() {
    try {
      setLoading(true);
      const response = await api.get(`admin/lead/${id}`, token);
      console.log(response.data);
      console.log(response.data.pricePerHolder);
      setIsConverted(response.data.isConverted);
      const holderCtx = response.data.holder;
      setHolder({
        ...holderCtx,
        cpf: response.data.holder.cpf ? maskCPF(response.data.holder.cpf) : '',
        phone: response.data.holder.phone ? maskPhone(response.data.holder.phone) : '',
        phone2: response.data.holder.phone ? maskPhone(response.data.holder.phone) : '',
        addressZipcode: response.data.holder.addressZipcode ? maskCep(response.data.holder.addressZipcode) : '',
        email: response.data.holder.email ? response.data.holder.email : '',
        name: response.data.holder.name ? response.data.holder.name : '',
        rg: response.data.holder.rg ? response.data.holder.rg : '',
        emitent: 'SSP/MA',
        birthday: response.data.holder.birthday ? response.data.holder.birthday : '',
        addressStreet: response.data.holder.addressStreet ? response.data.holder.addressStreet : '',
        addressNumber: response.data.holder.addressNumber ? response.data.holder.addressNumber : '',
        addressComplement: response.data.holder.addressComplement ? response.data.holder.addressComplement : '',
        addressNeighborhood: response.data.holder.addressNeighborhood ? response.data.holder.addressNeighborhood : '',
        addressCity: response.data.holder.addressCity ? response.data.holder.addressCity : '',
        addressIbge: response.data.holder.addressIbge ? response.data.holder.addressIbge : '',
        observation: response.data.holder.observation || '',
      });
      setDependents(response.data.holder.leadDependent.map((item: any) => ({
        ...item,
        uuidCtx: item.id,
        cpf: item.cpf ? maskCPF(item.cpf) : '',
        phone: item.phone ? maskPhone(item.phone) : '',
        phone2: item.phone2 ? maskPhone(item.phone2) : '',
        addressZipcode: item.addressZipcode ? maskCep(item.addressZipcode) : '',
        email: item.email ? item.email : '',
        name: item.name ? item.name : '',
        rg: item.rg ? item.rg : '',
        emitent: 'SSP/MA',
        birthday: item.birthday ? item.birthday : '',
        addressStreet: item.addressStreet ? item.addressStreet : '',
        addressNumber: item.addressNumber ? item.addressNumber : '',
        addressComplement: item.addressComplement ? item.addressComplement : '',
        addressNeighborhood: item.addressNeighborhood ? item.addressNeighborhood : '',
        addressCity: item.addressCity ? item.addressCity : '',
        addressIbge: item.addressIbge ? item.addressIbge : '',
        observation: item.observation || '',
      })));
      setOriginalDependents(response.data.holder.leadDependent.map((item: any) => ({ ...item, uuidCtx: item.id })));
      setContract({
        ...contract,
        productId: response.data.productId,
        totalDependentsEnabled: response.data.totalDependentsEnabled,
        signatureDate: response.data.signatureDate,
        dueDate: response.data.dueDate,
        value: response.data.value,
      });
      setHaveInstallments(response.data.installment.length > 0);
      const filteredProduct = products.filter((item) => item.id === response.data.productId);
      if (filteredProduct.length > 0) {
        setSelectedProduct({ ...filteredProduct[0], pricePerDependent: response.data.pricePerDependent, pricePerHolder: response.data.pricePerHolder });
      }
      setInstallments(response.data.installment);
      setOriginalInstallments(response.data.installment);
      setPayment({ ...payment, installments: response.data.installment.length });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  async function generatePayment(installmentId: number) {
    try {
      setLoading(true);
      const response = await api.post('/admin/lead/pf/generatesinglecharge', {
        leadPfInstallmentId: installmentId,
      }, token);
      await sendLead();
      window.open(response.data.Charge.Transactions[0].Pix.page, '_blank');
      await fetchLead();
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
    }
  }

  async function cancelPayment(installmentId: number) {
    try {
      setLoading(true);
      const response = await api.post('admin/lead/pf/cancelcharge', {
        leadPfInstallmentId: installmentId,
      }, token);
      await fetchLead();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAllProducts();
  }, []);

  useEffect(() => {
    fetchLead();
  }, [products]);

  useEffect(() => {
    changeGlobalValue();
  }, [selectedProduct]);

  useEffect(() => {
  }, [dependents]);

  useEffect(() => {
    if (errors.length > 0) {
      modalErrorRef.current.click();
    }
  }, [errors]);

  useEffect(() => {
    console.log('contract', contract);
  }, [contract]);

  return (
    <DefaultLayout
      pageTitle="Termo de adesão"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      {actualPage === ActualPage.HOLDER && (
        <EditHolder
          holder={holder}
          setHolder={setHolder}
          setLoading={(arg) => setLoading(arg)}
          dependents={dependents}
          setDependents={setDependents}
          setActualPage={(arg) => setActualPage(arg)}
          setContractLifes={(holders, dep) => setContract({ ...contract, totalDependentsEnabled: dep })}
        />
      )}
      {actualPage === ActualPage.PAYMENT && (
        <EditPaymentPFLead
          setModal={(arg) => setModal(arg)}
          setActualPage={(arg) => setActualPage(arg)}
          payment={payment}
          haveInstallments={haveInstallments}
          setHaveInstallments={(arg) => setHaveInstallments(arg)}
          sendLead={() => sendLead()}
          setPayment={(data) => setPayment(data)}
          pInstallments={installments}
          setInstallments={(data) => setInstallments(data)}
          contractProps={contract}
          generateInstallment={(arg) => generatePayment(arg)}
          cancelPayment={(arg) => cancelPayment(arg)}
        />
      )}

      {actualPage === ActualPage.CONTRACT && (
        <EditContract
          products={products}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          contract={contract}
          setModal={(arg) => setModal(arg)}
          setContract={setContract}
          lifesAmount={dependents.length + 1}
          setLoading={(arg) => setLoading(arg)}
          setActualPage={(arg) => setActualPage(arg)}
          changeNumberOfDependent={(arg) => changeNumberOfDependent(arg)}
          changePricePerDependent={(arg) => changePricePerDependent(arg)}
          changePricePerHolder={(arg) => changePricePerHolder(arg)}
        />
      )}

    </DefaultLayout>
  );
}
