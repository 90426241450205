/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import api from './config/axios';
import ClientPF from './pages/ClientPF';
import EditHolderPF from './pages/ClientPF/EditClientPF';
import EditDependentPF from './pages/ClientPF/EditDependentPF';
import NewHolderPF from './pages/ClientPF/NewClientPF';
import AddNewDependentPF from './pages/ClientPF/NewDependent';
import ClientPJ from './pages/ClientPJ';
import AddNewDependent from './pages/ClientPJ/AddNewDependent';
import AddNewHolder from './pages/ClientPJ/AddNewHolder';
import EditClientPJ from './pages/ClientPJ/EditClient';
import EditDependent from './pages/ClientPJ/EditDependent';
import EditHolder from './pages/ClientPJ/EditHolder';
import NewClientPJ from './pages/ClientPJ/NewClientPJ';
import Config from './pages/Config';
import ContractPF from './pages/Contracts/PF';
import EditContractPF from './pages/Contracts/PF/EditContractPF';
import EditPaymentPF from './pages/Contracts/PF/EditPaymentPF';
import NewContractPF from './pages/Contracts/PF/NewContractPF';
import NewPaymentPF from './pages/Contracts/PF/Payment';
import ContractsPJ from './pages/Contracts/PJ';
import EditContract from './pages/Contracts/PJ/EditContract';
import EditPaymentPJ from './pages/Contracts/PJ/EditPayment';
import NewContract from './pages/Contracts/PJ/NewContract';
import NewPaymentPJ from './pages/Contracts/PJ/Payment';
import InstallmentsTable from './pages/dashboard/NonPaidInstallments/Table';
// import Dashboard from './pages/dashboard';
import Dashboard from './pages/dashboard/index2';
import SearchByName from './pages/dashboard/SearchByName';
import SituationByCPF from './pages/dashboard/SituationByCpf';
import ExpensesAndProfits from './pages/expensesAndProfits';
import EditExpense from './pages/expensesAndProfits/EditExpense';
import EditProfit from './pages/expensesAndProfits/EditProfits';
import FutureIncomes from './pages/expensesAndProfits/FutureIncomes';
import NewExpense from './pages/expensesAndProfits/NewExpense';
import NewProfit from './pages/expensesAndProfits/NewProfit';
import FreelancerSellers from './pages/FreelancerSellers';
import EditFreelancerSeller from './pages/FreelancerSellers/EditFreelancerSeller';
import NewFreelancer from './pages/FreelancerSellers/NewFreelancerSeller';
import Indicators from './pages/Indications';
import EditIndicator from './pages/Indications/EditIndications';
import NewIndicator from './pages/Indications/NewIndications';
import Login from './pages/login';
import NewAdminForm from './pages/NewAdminForm';
import Partners from './pages/Partners';
import EditPartner from './pages/Partners/EditPartners';
import NewPartner from './pages/Partners/NewPartner';
import Professionals from './pages/Partners/Professionals';
import NewProfessional from './pages/Partners/Professionals/CreateNewProfessional';
import EditCategory from './pages/Partners/Professionals/EditCategory';
import EditProfessional from './pages/Partners/Professionals/EditProfessional';
import NewCategory from './pages/Partners/Professionals/NewCategory';
import PaymentTax from './pages/PaymentTax';
import Product from './pages/Products';
import EditProduct from './pages/Products/EditProduct';
import NewProduct from './pages/Products/NewProduct';
import Profile from './pages/Profile';
import RecoveryPassword from './pages/RecoveryPassword';
import Reffers from './pages/Reffers';
import EditReffer from './pages/Reffers/EditReffer';
import NewReffer from './pages/Reffers/NewReffer';
import Sellers from './pages/Sellers';
import EditSeller from './pages/Sellers/EditSeller';
import NewSellerCPF from './pages/Sellers/NewSeller';
import UsersManagement from './pages/UsersManagement';
import { storeType } from './store';
import setAdmin from './store/actions/adminAction';
import logIn from './store/actions/loginAction';
import PermBlockedPF from './pages/PFPermBlock';
import ViewPartner from './pages/Partners/Professionals/FilterTable';
import ReportsByDate from './pages/ReportsByPeriod';
import NewIndicatorCategory from './pages/Indications/NewIndicationCategory';
import EditIndicationCategory from './pages/Indications/EditCategory';
import NewExamsCategory from './pages/Partners/Exams/NewCategory';
import NewExams from './pages/Partners/Exams/NewExams';
import ShowExamsCategories from './pages/Partners/Exams/ShowCategories';
import EditExamsCategory from './pages/Partners/Exams/EditCategory';
import ShowExams from './pages/Partners/Exams/ShowExams';
import EditExam from './pages/Partners/Exams/EditExam';
import Forbbiden from './pages/Forbbiden';
import Stratification from './pages/ReportsByPeriod/Stratification/StratificationModal';
import StratificationByDate from './pages/ReportsByPeriod/Stratification';
import NewProceduresCategory from './pages/Partners/Procedures/NewCategory';
import ShowProceduresCategories from './pages/Partners/Procedures/ShowCategories';
import EditProceduresCategory from './pages/Partners/Procedures/EditCategory';
import NewProcedures from './pages/Partners/Procedures/NewProcedure';
import ShowProcedures from './pages/Partners/Procedures/ShowProcedures';
import EditProcedure from './pages/Partners/Procedures/EditProcedure';
import EditPaymentPFV2 from './pages/Contracts/PF/EditPaymentPFV2';
import EditPaymentPJV2 from './pages/Contracts/PJ/EditPaymentPJV2';
import NewConsultations from './pages/Partners/Consultations/NewConsultations';
import ShowConsultation from './pages/Partners/Consultations/ShowConsultations';
import EditConsultation from './pages/Partners/Consultations/EditConsultation';
import Inconsistency from './pages/Inconsistency';
import Lifes from './pages/dashboard/Lifes';
import DemographicData from './pages/UserReport/Demographic';
import GatewaysPrevision from './pages/GatewaysPrevision';
import Lead from './pages/Lead';
import CreateLead from './pages/Lead/PF/CreateLead';
import EditLead from './pages/Lead/PF/Edit/EditLead';
import CreateLeadPJ from './pages/Lead/PJ/CreateLead';
import LeadPJ from './pages/Lead/PJ';
import EditLeadPJ from './pages/Lead/PJ/Edit/EditLead';
import ContractExpiration from './pages/Contracts/ContractExpiration';
import RenewContractPF from './pages/Contracts/PF/RenewContract';
import RenewContractPJ from './pages/Contracts/PJ/RenewContract';
import CancelJustification from './pages/CancelJustification';
import EditJustifications from './pages/CancelJustification/edit';
import CreateJustifications from './pages/CancelJustification/create';
import CreateClientBlockJustifications from './pages/CancelJustification/clients/create';
import CancelClientJustification from './pages/CancelJustification/clients';
import EditClientBlockJustifications from './pages/CancelJustification/clients/edit';

export default function AppRoutes() {
  const { isLoggedIn } = useSelector(({ loginReducer }: storeType) => loginReducer);
  const { access, role } = useSelector(({ adminReducer }: storeType) => adminReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    async function autoLogin() {
      try {
        const adminData = await api.get('/admin', {
          headers: {
            Authorization: `bearer ${localStorage.getItem('adminToken')}`,
          },
        });
        if (adminData.data.avatar === null) {
          setAdmin(dispatch, { ...adminData.data, avatar: { fileUrl: null } });
        } else {
          setAdmin(dispatch, adminData.data);
        }
        logIn(dispatch);
      } catch (err) {
        console.log('auto login failed, redirect to login page');
      }
    }
    autoLogin();
  }, []);

  function defineHomeByRole() {
    if (role === 'reffers') {
      return <SituationByCPF />;
    } if (access === 'admin') {
      return <Dashboard />;
    }
    return <ClientPF />;
  }

  if (isLoggedIn) {
    if (role === 'reffers') {
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={defineHomeByRole()} />
            {/* Perfil */}
            <Route path="/perfil" element={<Profile />} />
            <Route path="/dashboard/consulta-por-cpf" element={<SituationByCPF />} />
            <Route path="/dashboard/consulta-por-cpf/:cpf" element={<SituationByCPF />} />
            <Route path="/dashboard/consulta-por-nome" element={<SearchByName />} />
            <Route path="/dashboard" element={<SituationByCPF />} />
            <Route path="/dashboard/vidas" element={<Lifes />} />
            <Route path="*" element={<Forbbiden />} />
            {/* Clientes */}
            <Route path="/cliente/empresa" element={<ClientPJ />} />
            <Route path="/cliente/empresa/adicionar" element={<NewClientPJ />} />
            <Route path="/cliente/empresa/editar/:id" element={<EditClientPJ />} />
            <Route path="/cliente/empresa/adicionar-aderente-titular/:id" element={<AddNewHolder />} />
            <Route path="/cliente/editar-aderente/:clientId/:id" element={<EditHolder />} />
            <Route path="/cliente/adicionar-dependente/:clientId/:holderId" element={<AddNewDependent />} />
            <Route path="/cliente/editar-dependente/:id" element={<EditDependent />} />
            <Route path="/cliente/pessoa-fisica" element={<ClientPF />} />
            <Route path="/cliente/pessoa-fisica/adicionar-titular" element={<NewHolderPF />} />
            <Route path="/cliente/pessoa-fisica/editar-titular/:id" element={<EditHolderPF />} />
            <Route path="/cliente/adicionar-dependente-pf/:holderId" element={<AddNewDependentPF />} />
            <Route path="/cliente/editar-dependente-pf/:id" element={<EditDependentPF />} />
            <Route path="/cliente/pf/bloqueados-permanentemente" element={<PermBlockedPF />} />
            <Route path="/cliente/pf/lead" element={<Lead />} />
            <Route path="/cliente/pf/editarlead/:id" element={<EditLead />} />
            <Route path="/cliente/pf/criar-lead" element={<CreateLead />} />
            <Route path="/cliente/pj/criar-lead" element={<CreateLeadPJ />} />
            <Route path="/cliente/pj/lead" element={<LeadPJ />} />
            <Route path="/cliente/pj/editarlead/:id" element={<EditLeadPJ />} />
            {/* Contratos */}
            <Route path="/contratos/pj" element={<ContractsPJ />} />
            <Route path="/contratos/pj/adicionar" element={<NewContract />} />
            <Route path="/contratos/pj/editar/:id" element={<EditContract />} />
            <Route path="/contratos/pj/forma-de-pagamento/adicionar/:contractId" element={<NewPaymentPJ />} />
            <Route path="/contratos/pj/forma-de-pagamento/visualizar/:id" element={<EditPaymentPJV2 />} />
            <Route path="/contratos/pf/adicionar" element={<NewContractPF />} />
            <Route path="/contratos/pf/renovar/:id" element={<RenewContractPF />} />
            <Route path="/contratos/pj/renovar/:id" element={<RenewContractPJ />} />
            <Route path="/contratos/pf" element={<ContractPF />} />
            <Route path="/contratos/expiracao" element={<ContractExpiration />} />
            <Route path="/contratos/pf/visualizar/:id" element={<EditContractPF />} />
            <Route path="/contratos/pf/forma-de-pagamento/adicionar/:contractId" element={<NewPaymentPF />} />
            <Route path="/contratos/pf/forma-de-pagamento/visualizar/:id" element={<EditPaymentPFV2 />} />

          </Routes>
        </BrowserRouter>
      );
    }
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={defineHomeByRole()} />
          {/* Perfil */}
          <Route path="/perfil" element={<Profile />} />

          {/* Clientes */}
          <Route path="/cliente/motivos-cancelamento/criar" element={<CreateClientBlockJustifications />} />
          <Route path="/cliente/motivos-cancelamento" element={<CancelClientJustification />} />
          <Route path="/cliente/motivos-cancelamento/:id" element={<EditClientBlockJustifications />} />
          <Route path="/cliente/empresa" element={<ClientPJ />} />
          <Route path="/cliente/empresa/adicionar" element={<NewClientPJ />} />
          <Route path="/cliente/empresa/editar/:id" element={<EditClientPJ />} />
          <Route path="/cliente/empresa/adicionar-aderente-titular/:id" element={<AddNewHolder />} />
          <Route path="/cliente/editar-aderente/:clientId/:id" element={<EditHolder />} />
          <Route path="/cliente/adicionar-dependente/:clientId/:holderId" element={<AddNewDependent />} />
          <Route path="/cliente/editar-dependente/:id" element={<EditDependent />} />
          <Route path="/cliente/pessoa-fisica" element={<ClientPF />} />
          <Route path="/cliente/pessoa-fisica/adicionar-titular" element={<NewHolderPF />} />
          <Route path="/cliente/pessoa-fisica/editar-titular/:id" element={<EditHolderPF />} />
          <Route path="/cliente/adicionar-dependente-pf/:holderId" element={<AddNewDependentPF />} />
          <Route path="/cliente/editar-dependente-pf/:id" element={<EditDependentPF />} />
          <Route path="/cliente/pf/bloqueados-permanentemente" element={<PermBlockedPF />} />
          <Route path="/cliente/pf/lead" element={<Lead />} />
          <Route path="/cliente/pf/editarlead/:id" element={<EditLead />} />
          <Route path="/cliente/pf/criar-lead" element={<CreateLead />} />
          <Route path="/cliente/pj/criar-lead" element={<CreateLeadPJ />} />
          <Route path="/cliente/pj/lead" element={<LeadPJ />} />
          <Route path="/cliente/pj/editarlead/:id" element={<EditLeadPJ />} />

          {/* Contratos */}
          <Route path="/contratos/motivos-cancelamento/criar" element={<CreateJustifications />} />
          <Route path="/contratos/motivos-cancelamento" element={<CancelJustification />} />
          <Route path="/contratos/motivos-cancelamento/:id" element={<EditJustifications />} />
          <Route path="/contratos/pj" element={<ContractsPJ />} />
          <Route path="/contratos/pj/adicionar" element={<NewContract />} />
          <Route path="/contratos/pj/editar/:id" element={<EditContract />} />
          <Route path="/contratos/pj/forma-de-pagamento/adicionar/:contractId" element={<NewPaymentPJ />} />
          <Route path="/contratos/pj/forma-de-pagamento/visualizar/:id" element={<EditPaymentPJV2 />} />
          {/* <Route path="/contratos/pj/forma-de-pagamento/visualizar/:id" element={<EditPaymentPJ />} /> */}
          <Route path="/contratos/pf/adicionar" element={<NewContractPF />} />
          <Route path="/contratos/pf/renovar/:id" element={<RenewContractPF />} />
          <Route path="/contratos/pj/renovar/:id" element={<RenewContractPJ />} />
          <Route path="/contratos/pf" element={<ContractPF />} />
          <Route path="/contratos/expiracao" element={<ContractExpiration />} />
          <Route path="/contratos/pf/visualizar/:id" element={<EditContractPF />} />
          <Route path="/contratos/pf/forma-de-pagamento/adicionar/:contractId" element={<NewPaymentPF />} />
          <Route path="/contratos/pf/forma-de-pagamento/visualizar/:id" element={<EditPaymentPFV2 />} />
          {/* <Route path="/contratos/pf/forma-de-pagamento/visualizar/:id" element={<EditPaymentPF />} /> */}

          {/* Credenciados */}
          <Route path="/credenciados/filtro" element={<ViewPartner />} />
          <Route path="/credenciados" element={<Partners />} />
          <Route path="/credenciados/adicionar" element={<NewPartner />} />
          <Route path="/credenciados/editar/:thisId" element={<EditPartner />} />
          <Route path="/exames/visualizar" element={<ShowExams />} />
          <Route path="/exames/editar/:id" element={<EditExam />} />
          <Route path="/exames/criar" element={<NewExams />} />
          <Route path="/exames/categoria/criar" element={<NewExamsCategory />} />
          <Route path="/exames/categoria/editar/:id" element={<EditExamsCategory />} />
          <Route path="/exames/categoria" element={<ShowExamsCategories />} />
          <Route path="/procedimentos/categoria/criar" element={<NewProceduresCategory />} />
          <Route path="/procedimentos/categoria" element={<ShowProceduresCategories />} />
          <Route path="/procedimentos/categoria/editar/:id" element={<EditProceduresCategory />} />
          <Route path="/procedimentos/criar" element={<NewProcedures />} />
          <Route path="/procedimentos/visualizar" element={<ShowProcedures />} />
          <Route path="/procedimentos/editar/:id" element={<EditProcedure />} />
          <Route path="/profissionais" element={<Professionals />} />
          <Route path="/profissionais/especialidade/adicionar" element={<NewCategory />} />
          <Route path="/profissionais/especialidade/editar/:id" element={<EditCategory />} />
          <Route path="/profissionais/adicionar" element={<NewProfessional />} />
          <Route path="/profissionais/editar/:id" element={<EditProfessional />} />
          <Route path="/consultas/criar" element={<NewConsultations />} />
          <Route path="/consultas/visualizar" element={<ShowConsultation />} />
          <Route path="/consultas/editar/:id" element={<EditConsultation />} />
          <Route path="/dashboard/relatorios/demografico" element={<DemographicData />} />

          { access === 'admin' && (
          <>
            {/* Dashboard */}
            <Route path="/dashboard/consulta-por-cpf" element={<SituationByCPF />} />
            <Route path="/dashboard/consulta-por-cpf/:cpf" element={<SituationByCPF />} />
            <Route path="/dashboard/consulta-por-nome" element={<SearchByName />} />
            <Route path="/dashboard" element={<SituationByCPF />} />
            <Route path="/dashboard/vidas" element={<Lifes />} />

            {/* Vendedores, representantes */}
            <Route path="/vendedores" element={<Sellers />} />
            <Route path="/vendedores/editar/:id" element={<EditSeller />} />
            <Route path="/vendedores/adicionar" element={<NewSellerCPF />} />
            <Route path="/embaixador-master" element={<Reffers />} />
            <Route path="/embaixador-master/adicionar" element={<NewReffer />} />
            <Route path="/embaixador-master/editar/:id" element={<EditReffer />} />
            <Route path="/embaixador/adicionar-categoria" element={<NewIndicatorCategory />} />
            <Route path="/embaixador/editar-categoria/:id" element={<EditIndicationCategory />} />
            <Route path="/embaixador" element={<Indicators />} />
            <Route path="/embaixador/adicionar" element={<NewIndicator />} />
            <Route path="/embaixador/editar/:id" element={<EditIndicator />} />
            <Route path="/representantes" element={<FreelancerSellers />} />
            <Route path="/representantes/adicionar" element={<NewFreelancer />} />
            <Route path="/representantes/editar/:id" element={<EditFreelancerSeller />} />

            {/* Financeiro */}
            <Route path="/financeiro" element={<ExpensesAndProfits />} />
            <Route path="/financeiro/entradas-futuras" element={<FutureIncomes />} />
            <Route path="/financeiro/despesa/criar" element={<NewExpense />} />
            <Route path="/financeiro/despesa/editar/:id" element={<EditExpense />} />
            <Route path="/financeiro/entrada/criar" element={<NewProfit />} />
            <Route path="/financeiro/entrada/editar/:id" element={<EditProfit />} />
            <Route path="/dashboard/relatorio/parcelas" element={<InstallmentsTable />} />

            {/* configuracoes */}
            <Route path="/configuracoes" element={<Config />} />

            {/* Taxas */}
            <Route path="/taxas" element={<PaymentTax />} />
            <Route path="/previsao-por-gateway" element={<GatewaysPrevision />} />

            {/* Usuários */}
            <Route path="/usuarios" element={<UsersManagement />} />

            {/* Produtos */}
            <Route path="/produtos" element={<Product />} />
            <Route path="/produtos/adicionar" element={<NewProduct />} />
            <Route path="/produtos/editar/:id" element={<EditProduct />} />

            {/* Relatórios */}
            <Route path="/inconsistencias" element={<Inconsistency />} />
            <Route path="/relatorios-por-data" element={<ReportsByDate />} />
            <Route path="/relatorio-estratificado" element={<StratificationByDate />} />
          </>
          )}

          <Route path="*" element={<Forbbiden />} />
        </Routes>
      </BrowserRouter>
    );
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/cadastro/:secret" element={<NewAdminForm />} />
        <Route path="/recuperar/:token" element={<RecoveryPassword />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}
