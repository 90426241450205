import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import BasicInput from '../../components/BasicInput';
import BasicOption from '../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import SellerProps from '../../dto/seller';
import { statesOfBrazil } from '../../dto/states';
import DefaultLayout from '../../Layout';
import {
  maskCep, maskCPForCNPJ, maskPhone, unmaskData,
} from '../../helpers/inputMasks.js';
import { validateCnpjOrCpf } from '../../helpers/validate';
import api from '../../config/axios';
import showApiErrors from '../../helpers/showApiErrors';
import AddInput from '../../components/AddInput';
import CEPFinder from '../../helpers/findAddressByCep';

export default function NewSeller() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [repeatPassword, setRepeatPassword] = useState('');
  const [seller, setSeller] = useState({
    cpfCnpj: '',
    name: '',
    email: '',
    password: '',
    addressStreet: '',
    addressNumber: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    addressState: 'MA',
    addressZipcode: '',
    phone: '',
    isActive: true,
  });

  function enabledButton() {
    if (seller.name.length > 0
        && EmailValidator.validate(seller.email)
        && validateCnpjOrCpf(seller.cpfCnpj)
        && seller.addressStreet.length > 0
        && seller.addressNumber.length > 0
        && seller.addressNeighborhood.length > 0
        && seller.addressCity.length > 0
        && seller.addressCity.length > 0
        && seller.addressZipcode.length >= 10
        && seller.phone.length > 2
        && seller.password.length > 5
        && seller.password === repeatPassword
    ) {
      return true;
    }
    return false;
  }

  async function changeAddressByCep() {
    try {
      setLoading(true);
      const findCep = await new CEPFinder(unmaskData(seller.addressZipcode)).findCep();
      if (findCep !== null) {
        setSeller({
          ...seller,
          addressCity: findCep.city,
          addressState: findCep.state,
          addressNeighborhood: findCep.neighborhood,
          addressStreet: findCep.street,
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  async function saveSeller() {
    try {
      setLoading(true);
      const noMaskData = {
        ...seller,
        cpfCnpj: unmaskData(seller.cpfCnpj),
        addressZipcode: unmaskData(seller.addressZipcode),
        phone: unmaskData(seller.phone),
      };
      const response = await api.post('admin/seller', noMaskData, token);
      setLoading(false);
      navigate('/vendedores');
    } catch (err: any) {
      if (typeof err.response !== 'undefined') {
        if (err.response.status === 422) {
          setModal(showApiErrors(setModal, err.response.data.errors));
        } else {
          setShowToastError(true);
          setTimeout(() => {
            setShowToastError(false);
          }, 3000);
          setModal({ title: 'Ocorreu um erro', children: 'Não foi possível processar sua solicitação', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
        }
      }
      setLoading(false);
      console.log(err.response);
    }
  }
  return (
    <DefaultLayout
      pageTitle="Cadastro vendedor"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row new-sellers card-bg p-0">
        <section className="row">
          <h1>Cadastro Vendedor</h1>
          <div className="col-4">
            <BasicInput
              id="name-input"
              label="Nome"
              readOnly={false}
              helper="Ex: João Batista"
              error={seller.name.length < 1 ? 'Campo Obrigatório' : ''}
              value={seller.name}
              type="text"
              onChange={(data) => setSeller({ ...seller, name: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="cpf-cnpj-input"
              label="CPF / CNPJ"
              readOnly={false}
              helper="Ex: 000.000.000-10"
              error={!validateCnpjOrCpf(seller.cpfCnpj) ? 'Digite um CPF ou CNPJ válido.' : ''}
              value={seller.cpfCnpj}
              type="text"
              onChange={(data) => setSeller({ ...seller, cpfCnpj: maskCPForCNPJ(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="email-input"
              label="E-mail"
              readOnly={false}
              helper="Ex: joao@email.com"
              error={!EmailValidator.validate(seller.email) ? 'Insira um email válido' : ''}
              value={seller.email}
              type="text"
              onChange={(data) => setSeller({ ...seller, email: data })}
            />
          </div>
          <div className="col-4">
            <AddInput
              id="address-cep-input"
              label="CEP"
              readOnly={false}
              helper="Ex: 35.171-120"
              error={seller.addressZipcode.length < 10 ? 'Campo obrigatório' : ''}
              value={seller.addressZipcode}
              type="text"
              onAdd={() => changeAddressByCep()}
              onChange={(data) => setSeller({ ...seller, addressZipcode: maskCep(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-street-input"
              label="Rua"
              readOnly={false}
              helper="Ex: Rua Três"
              error={seller.addressStreet.length < 2 ? 'Campo Obrigatório' : ''}
              value={seller.addressStreet}
              type="text"
              onChange={(data) => setSeller({ ...seller, addressStreet: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-number-input"
              label="Número"
              readOnly={false}
              helper="Ex: 133"
              error={seller.addressNumber.length < 1 ? 'Campo Obrigatório' : ''}
              value={seller.addressNumber}
              type="text"
              onChange={(data) => setSeller({ ...seller, addressNumber: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-complement-input"
              label="Complemento"
              readOnly={false}
              helper="Ex: Bloco A"
              error=""
              value={seller.addressComplement}
              type="text"
              onChange={(data) => setSeller({ ...seller, addressComplement: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-neighborhood-input"
              label="Bairro"
              readOnly={false}
              helper="Centro"
              error={seller.addressNeighborhood.length < 1 ? 'Campo obrigatório' : ''}
              value={seller.addressNeighborhood}
              type="text"
              onChange={(data) => setSeller({ ...seller, addressNeighborhood: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-city-input"
              label="Cidade"
              readOnly
              helper="Ex: Imperatriz"
              error={seller.addressCity.length < 1 ? 'Campo obrigatório' : ''}
              value={seller.addressCity}
              type="text"
              onChange={(data) => setSeller({ ...seller, addressCity: data })}
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="address-state-input"
              label="Estado"
              readOnly
              helper=""
              error=""
              value={seller.addressState}
              onChange={(data) => setSeller({ ...seller, addressState: data })}
            >
              {statesOfBrazil}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicInput
              id="phone-input"
              label="Telefone"
              readOnly={false}
              helper="Ex: (31) 99999 - 9999"
              error={seller.phone.length < 1 ? 'Campo obrigatório' : ''}
              value={seller.phone}
              type="text"
              onChange={(data) => setSeller({ ...seller, phone: maskPhone(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="password-input"
              label="Senha"
              readOnly={false}
              helper="Mínimo 06 dígitos"
              error={seller.password.length < 6 ? 'Mínimo 06 dígitos' : ''}
              value={seller.password}
              type="password"
              onChange={(data) => setSeller({ ...seller, password: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="repeat-password-input"
              label="Repita a senha"
              readOnly={false}
              helper="Deve coincidir com a senha"
              error={repeatPassword !== seller.password ? 'Deve coincidir com a senha' : ''}
              value={repeatPassword}
              type="password"
              onChange={(data) => setRepeatPassword(data)}
            />
          </div>
          <div className="col-12 footer">
            <ButtonTextIcon
              label="salvar"
              type={BtnType.PRIMARY}
              icon="backup"
              active={enabledButton()}
              onClick={() => saveSeller()}
              ref={null}
            />
          </div>
        </section>
      </div>
    </DefaultLayout>
  );
}
