/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import download from 'downloadjs';
import Tooltip from '../../components/Tooltip';
import api from '../../config/axios';
import normalizeDate from '../../helpers/dates';
import { convertCardPaymentMethodFromApi } from '../../helpers/showCardMethods';
import showCompactName from '../../helpers/showCompactName';
import DefaultLayout from '../../Layout';
import { ModalActionType, ModalProps as IModal } from '../../components/Modal';
import { GatewayProps } from '../dashboard/NonPaidInstallments/Table';
import IncomeInstallmentModal, { PagVipRefProps } from './InstallmentModal';
import ProfitModalfilter from './ProfitModalFilters';
import CancelNFEModal from './CancelNFeModal';

export declare type methodProps = 'PIX' |
'DINHEIRO' |
'BOLETO' |
'TED' |
'DOC' |
'debit' |
'credit_1' |
'credit_2' |
'credit_3' |
'credit_4' |
'credit_5' |
'credit_6' |
'credit_7' |
'credit_8' |
'credit_9' |
'credit_10' |
'credit_11' |
'credit_12' |
'credit_13' |
'credit_14' |
'credit_15' |
'credit_16' |
'credit_17' |
'credit_18'

const gateways: GatewayProps[] = [
  {
    id: 1,
    name: 'PagAzul Presencial',
  },
  {
    id: 2,
    name: 'REDESANTA',
  },
  {
    id: 3,
    name: 'PagAzul WEB',
  },
  {
    id: 4,
    name: 'CIELO',
  },
  {
    id: 5,
    name: 'GALAXPAY',
  },
];

export type DataProps = {
    category: string
    created_at: string
    created_by_user_id: number
    created_by_user_name: string
    description: string
    description2: string
    id: number
    income_date: string
    installment_pf_id: number | null
    installment_pj_id: number | null
    invoice_issued: boolean | null
    liquid_value: number
    manual_payment: boolean
    notes: string
    paid_by_user_id: null | number
    payment_gateway_id: null | number
    payment_way: null | string
    paymentid: number
    position: number
    tax_used: number
    tipo: 1 | 2 | 3
    updated_at: string
    nfuuid: string | null
    rpspdf: string | null
    value: number
}

type MetaProps = {
    firstPage: number,
    lastPage: number,
    totalBruto: string,
    totalLiquido: string,
    currentPage: number
}
type ResponseProps = {
    meta: MetaProps,
    data: DataProps[]
}

type PageProps = {
    loading: boolean,
    setLoading: (arg0: boolean) => void;
    setShowToastSuccess: React.Dispatch<React.SetStateAction<boolean>>
    setModal: (arg0: IModal | null) => void
}

export type TableFilters = {
    currentPage: number
    minValue?: string | null
    maxValue?: string | null
    minLiquidValue?: string | null
    maxLiquidValue?: string | null
    minTaxUsed?: string | null
    maxTaxUsed?: string | null
    description?: string | null
    paymentWay?: string | null
    gatewayId?: number | null
    manualPayment?: boolean | null
    createdByUserName?: string | null
    orderPosition?: number | null
    initialIncomeDate?: string | null
    finalIncomeDate?: string | null
    invoiceIssued?: boolean | null
    initialCreatedAt?: string | null
    finalCreatedAt?: string | null
    tipo?: null | 1 | 2 | 3
    city?: string
    state?: string
}

type ModalProps = {
  installment: InstallmentDTO,
  isPf: boolean,
}

export type InstallmentDTO = {
  id: number
  contractPfPaymentId?: number
  contractPjPaymentId?: number
  uuid: string
  dueDate: string
  status: string
  value: number
  liquidValue: number,
  previsionDate: string,
  paidBy: string
  authorizationDate: string | null
  paymentDate: string | null
  paymentGatewayId: number
  orderPosition: number
  nonCardTaxes: string | null
  taxUsed: number
  pagvipRef: string | null
  card: boolean
  flag: string | null
  cardLastDigits: string | null
  cardPaymentOption: string | null
  boletoPayvip: PagVipRefProps | null
  isGalaxpaySubscription: boolean
  galaxpayPaymentMethod: 'boleto' | 'cartao' | 'pix'
  galaxpayInstallmentId: string | null
}

export default function Incomes({
  loading, setLoading, setShowToastSuccess, setModal,
}: PageProps) {
  const navigate = useNavigate();
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [minLiquidValue, setMinLiquidValue] = useState('');
  const [maxLiquidValue, setMaxLiquidValue] = useState('');
  const [minTaxUsed, setMinTaxUsed] = useState('');
  const [maxTaxUsed, setMaxTaxUsed] = useState('');
  const [description, setDescription] = useState('');
  const [paymentWay, setPaymentWay] = useState<methodProps | null>(null);
  const [gatewayId, setGatewayId] = useState<number | null>(null);
  const [manualPayment, setManualPayment] = useState<boolean | null>(null);
  const [createdByUserName, setCreatedByUsername] = useState('');
  const [orderPosition, setOrderPosition] = useState<number | null>(null);
  const [type, setType] = useState<1 | 2 | 3 | undefined | null>(null);
  const [initialIncomeDate, setInitialIncomeDate] = useState('');
  const [finalIncomeDate, setFinalIncomeDate] = useState('');
  const [invoceIssue, setInvoiceIssued] = useState<boolean | null>(null);
  const [city, setCity] = useState('');
  const [state, setState] = useState('TODOS');
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [targetUniqueId, setTargetUniqueId] = useState('');
  const [ref] = useState(React.createRef<any>());

  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [profits, setProfits] = useState<ResponseProps>({
    meta: {
      firstPage: 1,
      lastPage: 1,
      totalBruto: '0',
      totalLiquido: '0',
      currentPage: 1,
    },
    data: [],
  });
  const [tableFilters, setTableFilters] = useState<TableFilters>({
    currentPage: 1,
  });

  async function fetchIncome() {
    try {
      setLoading(true);
      const response = await api.post<ResponseProps>('/admin/income/paginate/search/', {
        currentPage: 1,
      }, token);
      setProfits(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  async function fetchIByFilter() {
    try {
      const obj = {};
      if (tableFilters.createdByUserName && tableFilters.createdByUserName !== '') {
        Object.assign(obj, { createdByUserName: tableFilters.createdByUserName });
      }
      if (tableFilters.description && tableFilters.description !== '') {
        Object.assign(obj, { description: tableFilters.description });
      }
      if (tableFilters.finalIncomeDate && tableFilters.finalIncomeDate !== '') {
        Object.assign(obj, { finalIncomeDate: tableFilters.finalIncomeDate });
      }
      if (tableFilters.paymentWay && tableFilters.paymentWay !== '') {
        Object.assign(obj, { paymentWay: tableFilters.paymentWay });
      }
      if (tableFilters.gatewayId) {
        Object.assign(obj, { gatewayId: tableFilters.gatewayId });
      }
      if (tableFilters.initialIncomeDate && tableFilters.initialIncomeDate !== '') {
        Object.assign(obj, { initialIncomeDate: tableFilters.initialIncomeDate });
      }
      if ((tableFilters.manualPayment !== null && typeof tableFilters.manualPayment !== 'undefined')) {
        Object.assign(obj, { manualPayment: tableFilters.manualPayment });
      }
      if (tableFilters.minLiquidValue) {
        Object.assign(obj, { minLiquidValue: Number(tableFilters.minLiquidValue) });
      }
      if (tableFilters.maxLiquidValue) {
        Object.assign(obj, { maxLiquidValue: Number(tableFilters.maxLiquidValue) });
      }
      if (tableFilters.minTaxUsed) {
        Object.assign(obj, { minTaxUsed: Number(tableFilters.minTaxUsed) });
      }
      if (tableFilters.maxTaxUsed) {
        Object.assign(obj, { maxTaxUsed: Number(tableFilters.maxTaxUsed) });
      }
      if (tableFilters.minValue) {
        Object.assign(obj, { minValue: Number(tableFilters.minValue) });
      }
      if (tableFilters.orderPosition) {
        Object.assign(obj, { orderPosition: Number(tableFilters.orderPosition) });
      }
      if (tableFilters.tipo) {
        Object.assign(obj, { tipo: tableFilters.tipo });
      }
      if (tableFilters.maxValue) {
        Object.assign(obj, { maxValue: Number(tableFilters.maxValue) });
      }
      if (tableFilters.invoiceIssued !== null) {
        Object.assign(obj, { invoiceIssued: invoceIssue });
      }
      if (tableFilters.city) {
        Object.assign(obj, { city });
      }
      if (tableFilters.state !== 'TODOS' && typeof tableFilters.state !== 'undefined') {
        Object.assign(obj, { state });
      }
      setLoading(true);
      const response = await api.post('/admin/income/paginate/search/', {
        currentPage: tableFilters.currentPage,
        ...obj,
      }, token);
      setProfits(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
    }
  }

  function resolveType(typeCtx: number) {
    if (typeCtx === 1) {
      return 'PF';
    }
    if (typeCtx === 2) {
      return 'PJ';
    }
    return 'TODOS';
  }

  useEffect(() => {
    fetchIncome();
  }, []);

  useEffect(() => {
    fetchIByFilter();
  }, [tableFilters]);

  function goToNextPage() {
    if (tableFilters.currentPage < profits.meta.lastPage) {
      setTableFilters((prev) => ({ ...prev, currentPage: profits.meta.currentPage + 1 }));
    }
  }

  function goToLastPage() {
    setTableFilters((prev) => ({ ...prev, currentPage: profits.meta.lastPage }));
  }

  function goToFirstPage() {
    setTableFilters((prev) => ({ ...prev, currentPage: profits.meta.firstPage }));
  }

  function goToPage(page: number) {
    setTableFilters({ ...tableFilters, currentPage: Number(page) });
  }

  function generatePagination(totalOfPages: number) {
    const pagination: number[] = [];
    for (let i = 0; i < totalOfPages; i += 1) {
      pagination.push(i + 1);
    }
    return pagination;
  }

  function filterGatewayNameById(gateway: number) {
    if (gateway === 1) {
      return 'PagAzul Presencial';
    }
    if (gateway === 2) {
      return 'REDESANTA';
    }
    if (gateway === 3) {
      return 'PagAzul WEB';
    }
    if (gateway === 4) {
      return 'CIELO';
    }
    return 'GALAXPAY';
  }

  async function onChangeModalProps(isPf: boolean, installmentPfId?: number | null, installmentPjId?: number | null) {
    try {
      if (isPf) {
        setLoading(true);
        const response = await api.get(`admin/contractpf/installment/info/${installmentPfId}`, token);
        setModalProps({
          isPf,
          installment: { ...response.data },
        });
        setLoading(false);
      } else {
        setLoading(true);
        const response = await api.get(`admin/contractpj/installment/info/${installmentPjId}`, token);
        setModalProps({
          isPf,
          installment: { ...response.data },
        });
        setForceRefresh((prev) => !prev);
        setLoading(false);
      }
    } catch (err: any) {
      setModalProps(null);
      console.log(err.response.data);
      setLoading(false);
    }
  }

  function openIncomesInNewTab(idCtx: number, evt: any) {
    evt.stopPropagation();
    if (evt.ctrlKey || evt.metaKey) {
      window.open(`https://painel.redesanta.com/financeiro/entrada/editar/${idCtx}`, '_blank');
    } else {
      navigate(`/financeiro/entrada/editar/${idCtx}`);
    }
  }

  async function generateNF(data: DataProps) {
    try {
      if (!data.installment_pf_id && !data.installment_pj_id) {
        setModal({ title: 'Impossível gerar RPS', children: 'Não é possível gerar via painel uma NFe que não está associada a uma parcela de um cliente', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      } else {
        setLoading(true);
        const route = data.installment_pf_id ? `admin/nfse/emitir-pf/${data.id}` : `admin/nfse/emitir-pj/${data.id}`;
        const response = await api.get(route, token);
        console.log(response);
        setTableFilters({ ...tableFilters, currentPage: profits.meta.currentPage });
        setShowToastSuccess(true);
        setTimeout(() => {
          setShowToastSuccess(false);
        }, 3000);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  }
  async function exportIncomesToExcel() {
    try {
      setLoading(true);
      const response = await api.get('/admin/entradas/to-excel', { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, 'Entradas.xlsx');
      setLoading(false);
    } catch (err: any) {
      console.log('error', err.response.data);
      setLoading(false);
    }
  }

  useEffect(() => {
    setForceRefresh((prev) => !prev);
  }, [targetUniqueId]);

  return (
    <>
      <ProfitModalfilter
        minValue={minValue}
        setMinValue={(arg) => setMinValue(arg)}
        maxValue={maxValue}
        setMaxValue={(arg) => setMaxValue(arg)}
        minLiquidValue={minLiquidValue}
        setMinLiquidValue={(arg) => setMinLiquidValue(arg)}
        maxLiquidValue={maxLiquidValue}
        setMaxLiquidValue={(arg) => setMaxLiquidValue(arg)}
        minTaxUsed={minTaxUsed}
        setMinTaxUsed={(arg) => setMinTaxUsed(arg)}
        maxTaxUsed={maxTaxUsed}
        setMaxTaxUsed={(arg) => setMaxTaxUsed(arg)}
        description={description}
        setDescription={(arg) => setDescription(arg)}
        paymentWay={paymentWay}
        setPaymentWay={(arg) => setPaymentWay(arg)}
        gatewayId={gatewayId}
        setGatewayId={(arg) => setGatewayId(arg)}
        manualPayment={manualPayment}
        setManualPayment={(arg) => setManualPayment(arg)}
        createdByUserName={createdByUserName}
        setCreatedByUsername={(arg) => setCreatedByUsername(arg)}
        orderPosition={orderPosition}
        setOrderPosition={(arg) => setOrderPosition(arg)}
        initialIncomeDate={initialIncomeDate}
        setInitialIncomeDate={(arg) => setInitialIncomeDate(arg)}
        finalIncomeDate={finalIncomeDate}
        setFinalIncomeDate={(arg) => setFinalIncomeDate(arg)}
        filter={(arg) => setTableFilters(arg)}
        type={type}
        setType={setType}
        invoiceIssued={invoceIssue}
        setInvoiceIssued={(arg) => setInvoiceIssued(arg)}
        removeAllFilters={() => setTableFilters({ currentPage: 1 })}
        city={city}
        setCity={setCity}
        state={state}
        setState={setState}
      />
      <div className="col-12 d-flex align-items-center justify-content-center mt-4">
        <button
          type="button"
          className="generate-excel-btn-wrapper"
          onClick={() => exportIncomesToExcel()}
        >
          <span className="material-icons-round">assignment_returned</span>
          Exportar todas entradas para Excel
        </button>
      </div>
      <div className="fetched-values-group mt-4 mb-2 justify-content-end">
        <div className="income-fetched-value-wrapper">
          <p className="" style={{ marginRight: 24 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#16C784" />
              <path d="M11.4758 19H12.5547L12.5598 17.7477C14.743 17.5781 15.9949 16.2875 16 14.4555C15.9949 12.5141 14.5038 11.6281 12.9364 11.2289L12.6005 11.1359L12.6209 8.01328C13.4351 8.16641 13.9593 8.69141 14.0407 9.47891H15.8575C15.827 7.73984 14.5547 6.47109 12.631 6.27422L12.6361 5H11.5573L11.5522 6.27422C9.64377 6.48203 8.26972 7.73984 8.27481 9.55C8.27481 11.1578 9.33842 12.082 11.0534 12.5578L11.5115 12.6836L11.4911 15.9922C10.5802 15.8391 9.92366 15.2812 9.85751 14.2914H8C8.07125 16.3422 9.38422 17.5617 11.4809 17.7477L11.4758 19ZM12.57 15.9922L12.5903 12.9898C13.5165 13.2797 14.1069 13.6625 14.112 14.4391C14.1069 15.2484 13.5013 15.8281 12.57 15.9922ZM11.5216 10.8406C10.8193 10.6 10.1781 10.2008 10.1883 9.42422C10.1934 8.71875 10.687 8.17188 11.542 8.01328L11.5216 10.8406Z" fill="white" />
            </svg>
            Total Bruto:
            {' '}
            <span className="">{`R$ ${profits.meta.totalBruto}`}</span>
          </p>
        </div>
        <div className="income-fetched-value-wrapper">
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#16C784" />
              <path d="M11.4758 19H12.5547L12.5598 17.7477C14.743 17.5781 15.9949 16.2875 16 14.4555C15.9949 12.5141 14.5038 11.6281 12.9364 11.2289L12.6005 11.1359L12.6209 8.01328C13.4351 8.16641 13.9593 8.69141 14.0407 9.47891H15.8575C15.827 7.73984 14.5547 6.47109 12.631 6.27422L12.6361 5H11.5573L11.5522 6.27422C9.64377 6.48203 8.26972 7.73984 8.27481 9.55C8.27481 11.1578 9.33842 12.082 11.0534 12.5578L11.5115 12.6836L11.4911 15.9922C10.5802 15.8391 9.92366 15.2812 9.85751 14.2914H8C8.07125 16.3422 9.38422 17.5617 11.4809 17.7477L11.4758 19ZM12.57 15.9922L12.5903 12.9898C13.5165 13.2797 14.1069 13.6625 14.112 14.4391C14.1069 15.2484 13.5013 15.8281 12.57 15.9922ZM11.5216 10.8406C10.8193 10.6 10.1781 10.2008 10.1883 9.42422C10.1934 8.71875 10.687 8.17188 11.542 8.01328L11.5216 10.8406Z" fill="white" />
            </svg>
            Total Líquido:
            {' '}
            <span className="">{`R$ ${profits.meta.totalLiquido}`}</span>
          </p>
        </div>
      </div>
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Entradas</h1>
          <div className="table-wrapper">
            <div className="table-actions justify-content-start">
              <button className="table-btn-primary" type="button" onClick={() => navigate('/financeiro/entrada/criar')}>
                <span className="material-icons-round">
                  add
                </span>
                adicionar
              </button>
              <button className="table-btn-primary" type="button" onClick={() => null} data-bs-toggle="modal" data-bs-target="#profitsModalFilter">
                <span className="material-icons-round">
                  filter_alt
                </span>
                Filtrar
              </button>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Descrição</th>
                  <th scope="col">NF Gerada?</th>
                  <th scope="col">Num. Parc.</th>
                  <th scope="col">Forma PGTO.</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Gateway</th>
                  <th scope="col">Dt. de entrada</th>
                  <th scope="col">Valor Bruto</th>
                  <th scope="col">Valor Líquido</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {profits.data.length > 0 ? profits.data.map((item, i) => (
                  <tr key={`${item.id}-${item.paymentid}-${item.created_at}`}>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.id}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.description2}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.invoice_issued ? 'SIM' : 'NÃO'}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.position}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.payment_way ? convertCardPaymentMethodFromApi(item.payment_way) : 'N/A'}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.tipo ? resolveType(item.tipo) : 'N/A'}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.payment_gateway_id ? filterGatewayNameById(item.payment_gateway_id) : 'N/A'}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{normalizeDate(item.income_date)}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{`R$ ${item.value.toFixed(2)}`}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{`R$ ${item.liquid_value.toFixed(2)}`}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">

                        {item.rpspdf && item.invoice_issued && (
                        <div className="table-action-icon">
                          <button
                            type="button"
                            onClick={(e) => null}
                            aria-describedby="manual"
                            data-bs-toggle="modal"
                            data-bs-target={`#income${item.id}`}
                            id="cancel-nfe"
                          >
                            <span className="material-icons-round text-danger">
                              cancel
                            </span>
                          </button>
                          <Tooltip id="cancel-nfe" text="Essa ação irá cancelar o RPS enviado." />
                          <CancelNFEModal id={`income${item.id}`} income={item} />
                        </div>
                        )}

                        {
                        !item.rpspdf && !item.invoice_issued && (
                          <div className="table-action-icon">
                            <button
                              type="button"
                              onClick={(e) => generateNF(item)}
                              aria-describedby="manual"
                              id="enviar-rps"
                            >
                              <span className="material-icons-round">
                                post_add
                              </span>
                            </button>
                            <Tooltip id="enviar rps" text="Enviar RPS dessa entrada." />
                          </div>
                        )
                       }

                        {item.rpspdf && (
                        <div className="table-action-icon">
                          <button
                            type="button"
                            onClick={(e) => window.open(item.rpspdf!, '_blank')}
                            aria-describedby="manual"
                            id="ver-rps"
                          >
                            <span className="material-icons-round">
                              picture_as_pdf
                            </span>
                          </button>
                          <Tooltip id="ver-rps" text="Ver PDF do RPS enviado." />
                        </div>
                        )}

                        {item.tipo && item.tipo !== 3 && (item.installment_pf_id || item.installment_pj_id) && (
                          <div className="table-action-icon">
                            <button type="button" onClick={() => setTargetUniqueId(`${item.category}-${item.id}-${item.paymentid}`)} data-bs-toggle="modal" data-bs-target={`#${item.category}-${item.id}-${item.paymentid}`}>
                              <span className="material-icons-round">
                                search
                              </span>
                            </button>
                            <button className="d-none" type="button" ref={ref}>
                              <span className="material-icons-round">
                                search
                              </span>
                            </button>

                            <IncomeInstallmentModal
                              uniqueId={`${item.category}-${item.id}-${item.paymentid}`}
                              installmentPfId={item.installment_pf_id}
                              installmentPjId={item.installment_pj_id}
                              isPf={item.tipo === 1}
                              targetUniqueId={targetUniqueId}
                            />

                          </div>
                        )}
                        <div className="table-action-icon">
                          <button type="button" onClick={(e) => openIncomesInNewTab(item.id, e)}>
                            <span className="material-icons-round">
                              more
                            </span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : (
                  <p>Não existem entradas cadastradas.</p>
                )}
              </tbody>
            </table>
            <div className="table-footer">
              <button type="button" onClick={() => goToFirstPage()}>Primeira</button>
              <p>Página</p>
              <select style={{ minWidth: 300 }} value={profits.meta.currentPage} onChange={(e) => goToPage(Number(e.target.value))}>
                {generatePagination(profits.meta.lastPage).map((item) => <option value={item}>{item}</option>)}
              </select>
              <p>
                de
                {' '}
                <strong>{profits.meta.lastPage}</strong>
              </p>
              <button
                type="button"
                onClick={() => goToNextPage()}
              >
                Próxima
              </button>
              <button
                type="button"
                onClick={() => goToLastPage()}
              >
                Última
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
