import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import api from '../../../config/axios';
import DefaultLayout from '../../../Layout';
import BasicInput from '../../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';

export default function CreateClientBlockJustifications() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [data, setData] = useState<string>('');

  function enableButton() {
    return data !== '';
  }

  async function saveJustification() {
    try {
      setLoading(true);
      await api.post('/admin/block-life-justifications', {
        justification: data,
      }, token);
      setLoading(false);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
      navigate('/cliente/motivos-cancelamento');
    } catch (err: any) {
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi salvar justificativa', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  return (
    <DefaultLayout
      pageTitle="Editar justificativa de cancelamento de clientes"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Criar justificativa</h1>
          <div className="row">
            <div className="col-4">
              <BasicInput
                id="title-input"
                label="Título"
                readOnly={false}
                helper="Ex: Mudou de país"
                error={data.length < 1 ? 'Campo obrigatório' : ''}
                value={data}
                type="text"
                onChange={(cb) => setData(cb)}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                active={enableButton()}
                onClick={() => saveJustification()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>

    </DefaultLayout>
  );
}
