import React from 'react';
import { ModalActionType, ModalProps } from '../../components/Modal';

export default function changeModalProductVisibility(
  setModal: (arg0: null | ModalProps) => void,
  changeVisibility: () => Promise<void>,
) {
  const modalProps: ModalProps = {
    children: 'Isso irá desativar o plano selecionado, porém, não irá removê-lo do sistema e não afetará os contratos viculados ao mesmo.',
    title: 'Alterar permissão',
    actions: [
      {
        label: 'Cancelar',
        onClick: () => setModal(null),
        type: ModalActionType.DANGER,
      },
      {
        label: 'Alterar',
        onClick: () => {
          changeVisibility();
          setModal(null);
        },
        type: ModalActionType.PRIMARY,
      },
    ],
  };
  return modalProps;
}
