/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import * as EmailValidator from 'email-validator';
import api from '../config/axios';
import BasicInput from './BasicInput';
import ButtonTextIcon, { BtnType } from './BtnIcon';
import { unmaskData } from '../helpers/inputMasks.js';

interface UpdateProps {
    cpf: string,
    emailProps: string,
}

// eslint-disable-next-line no-shadow
enum ActualScreen {
    FORM,
    LOADING,
    SUCCESS,
}

export default function UpdateUserModal({ cpf, emailProps }: UpdateProps) {
  const [actualForm, setActualForm] = useState<ActualScreen>(ActualScreen.FORM);
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [email, setEmail] = useState(emailProps);
  async function updateForm() {
    try {
      setActualForm(ActualScreen.LOADING);
      await api.put('/admin/manage/user', {
        cpf: unmaskData(cpf),
        password,
        email,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
        },
      });
      setActualForm(ActualScreen.SUCCESS);
    } catch (err: any) {
      console.log(err.response.data);
      if (!(err.response && err.response.data)) {
        setError('Erro interno.');
      } else if (err.response.data.errors) {
        setError(err.response.data.errors[0].message);
      } else {
        setError('Erro interno.');
      }
      setActualForm(ActualScreen.FORM);
    }
  }

  function validate() {
    if (!EmailValidator.validate(email)) {
      setError('Email inválido.');
    } else if (password.length < 6) {
      setError('Senha deverá ter no mínimo 6 dígitos');
    } else if (password !== repeatPassword) {
      setError('Senhas não coincidem');
    } else {
      updateForm();
    }
  }

  function onExit() {
    setActualForm(ActualScreen.FORM);
    setError('');
    setPassword('');
    setRepeatPassword('');
    setEmail(emailProps);
  }
  return (
    <div className="modal fade" id="updateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Alterar acesso no app</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onExit} />
          </div>
          <div className="modal-body">
            {actualForm === ActualScreen.LOADING ? (
              <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {error !== '' && (
                <div className="p-2 mb-2" style={{ background: 'orange' }}>
                  <p className="text-white">
                    Erro:
                    {' '}
                    {error}
                  </p>
                </div>
                )}

                {actualForm === ActualScreen.SUCCESS && (
                <p>Alteraçoes realizadas com sucesso!</p>
                )}

                {actualForm === ActualScreen.FORM && (
                <>
                  <div className="py-2">
                    <BasicInput
                      id="email"
                      label="Email"
                      readOnly={false}
                      helper=""
                      error=""
                      value={email}
                      type="email"
                      onChange={(data) => {
                        setEmail(data);
                      }}
                    />
                  </div>
                  <div className="py-2">
                    <BasicInput
                      id="password"
                      label="Senha"
                      readOnly={false}
                      helper=""
                      error={password.length < 6 ? 'Senha deverá ter no mínimo 6 dígitos' : ''}
                      value={password}
                      type="password"
                      onChange={(data) => {
                        setPassword(data);
                      }}
                    />
                  </div>
                  <div className="py-2">
                    <BasicInput
                      id="repeatPassword"
                      label="Repita a senha"
                      readOnly={false}
                      helper=""
                      error={password !== repeatPassword ? 'Senhas não coincidem' : ''}
                      value={repeatPassword}
                      type="password"
                      onChange={(data) => {
                        setRepeatPassword(data);
                      }}
                    />
                  </div>
                  <div className="py-2 w-100 flex">
                    <button className="btn btn-primary w-100" onClick={() => validate()} type="button">Enviar</button>
                  </div>
                </>
                )}

              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
