/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import api from '../../../config/axios';
import DefaultLayout from '../../../Layout';

type AccrecreditedProps = {
    id: number;
    name: string
}

type CategoryProps = {
    id: number;
    title: string;
}

type ExamsProceduresProps = {
    observation: string,
    value: string,
    description: string
}

const fakeCategories = [
  {
    id: 1,
    title: 'MAMOGRAFIA',
  },
  {
    id: 2,
    title: 'ECG',
  },
];

const fakeAccrediteds = [
  {
    id: 1,
    name: 'RD XAVIER',
  },
  {
    id: 2,
    name: 'REDESANTA',
  },
];

export default function NewProcedures() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [translation, setTranslation] = useState(1);
  const [accrediteds, setAccrediteds] = useState<AccrecreditedProps[]>(fakeAccrediteds);
  const [categories, setCategories] = useState<CategoryProps[]>(fakeCategories);
  const [selectedAccredited, setSelectedAccredited] = useState<AccrecreditedProps>({
    id: 10000000,
    name: 'SELECIONE',
  });
  const [selectedCategory, setSelectedCategory] = useState<CategoryProps>({
    id: 10000000,
    title: 'SELECIONE',
  });
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  const [Procedures, setProcedures] = useState<ExamsProceduresProps[]>([
    {
      observation: '',
      value: '0',
      description: '',
    },
  ]);

  const [forceUpdate, setForceUpdate] = useState(true);

  function calculateTranslation() {
    if (translation === 1) {
      window.document.getElementById('div1')?.classList.remove('d-none');
      window.document.getElementById('div2')?.classList.add('d-none');
      window.document.getElementById('div3')?.classList.add('d-none');
      return 'translateX(0px)';
    }
    if (translation === 2) {
      window.document.getElementById('div2')?.classList.remove('d-none');
      window.document.getElementById('div3')?.classList.add('d-none');
      window.document.getElementById('div1')?.classList.add('d-none');
      return 'translateX(-100%)';
    }
    window.document.getElementById('div3')?.classList.remove('d-none');
    window.document.getElementById('div2')?.classList.add('d-none');
    window.document.getElementById('div1')?.classList.add('d-none');
    return 'translateX(-200%)';
  }

  function forwardSlider() {
    if (translation < 3) {
      setTranslation((prev) => prev + 1);
    }
  }

  function backwardSlider() {
    if (translation > 1) {
      setTranslation((prev) => prev - 1);
    }
  }

  function changeDescription(data: string, idx: number) {
    Procedures[idx].description = data;
    setForceUpdate((prev) => !prev);
  }

  function changeValue(data: string, idx: number) {
    Procedures[idx].value = data;
    setForceUpdate((prev) => !prev);
  }

  function changeObservation(data: string, idx: number) {
    Procedures[idx].observation = data;
    setForceUpdate((prev) => !prev);
  }

  function addNewRow() {
    setProcedures((prev) => [...prev, { observation: '', value: '0', description: '' }]);
  }

  function deleteRow(idx: number) {
    const newElements = Procedures.filter((_, i) => i !== idx);

    setProcedures(newElements);
  }

  function validateFields() {
    let isValid = true;
    Procedures.forEach((item) => {
      if (item.description.length < 1) {
        isValid = false;
      }
    });

    return isValid;
  }

  async function fetchPreload() {
    try {
      setLoading(true);
      const response = await api.get('/admin/medical-preload', token);
      console.log('response', response.data);
      if (response.data.accrediteds.length < 1) {
        setModal({ title: 'Ocorreu um erro', children: 'Ao menos um credenciado deverá estar cadastrado para realizar inserção dos procedimentos', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
        return;
      }
      if (response.data.categories.length < 1) {
        setModal({ title: 'Ocorreu um erro', children: 'Ao menos uma categoria de procedimento deverá estar cadastrada para realizar inserção dos procedimentos', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
        return;
      }
      setAccrediteds(response.data.accrediteds);
      setSelectedAccredited(response.data.accrediteds[0]);
      setCategories(response.data.categories);
      setSelectedCategory(response.data.categories[0]);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível carregar os credenciados e categorias para realizar cadastro dos procedimentos', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
    }
  }

  async function saveProcedure() {
    try {
      setLoading(true);
      const response = await api.post('/admin/medical-procedures', {
        procedures: Procedures.map((item) => ({
          accreditedId: selectedAccredited.id.toString(),
          categoryProceduresId: selectedCategory.id.toString(),
          ...item,
        })),
      }, token);

      navigate(-1);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível cadastrar procedimento', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  useEffect(() => {
    fetchPreload();
  }, []);

  return (
    <DefaultLayout
      pageTitle="Novo(s) Procedimento(s)"
      loading={loading}
      showToastSuccess={false}
      showToastError={false}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4" style={{ overflowX: 'hidden', minHeight: translation === 3 ? Procedures.length * 220 : 'fit-content' }}>
          <h1>Criar Procedimento(s)</h1>
          <div className="slider-content-wrapper" style={{ transform: calculateTranslation() }}>
            <div className="div1" id="div1">
              <div className="row h-100">
                <div className="col-12 d-flex flex-column justify-content-center align-items-center h-100">
                  <h2 className="fw-bold text-muted">Selecione a categoria</h2>
                  <div className="w-50">
                    <BasicOption
                      id="category"
                      label="Categoria"
                      readOnly={false}
                      helper="Ex: Biópsia"
                      error=""
                      value={selectedCategory.title}
                      onChange={(data) => setSelectedCategory(categories.filter((item) => item.title === data)[0])}
                    >
                      {[...categories.map((item) => item.title)]}
                    </BasicOption>

                    <ButtonTextIcon
                      label="Próximo"
                      type={BtnType.PRIMARY}
                      icon="chevron_right"
                      active
                      onClick={() => forwardSlider()}
                      ref={null}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="div2" id="div2">
              <div className="row h-100">
                <div className="col-12 d-flex flex-column justify-content-center align-items-center h-100">
                  <div className="w-50" style={{ marginBottom: 32 }}>
                    <button type="button" className="d-flex align-items-center borderless-icon-btn" onClick={() => backwardSlider()}>
                      <span className="material-icons-round">
                        chevron_left
                      </span>
                      Voltar
                    </button>
                  </div>

                  <h2 className="fw-bold text-muted">Vincule ao credenciado </h2>
                  <div className="w-50">
                    <BasicOption
                      id="accredited"
                      label="Credenciado"
                      readOnly={false}
                      helper="Ex: HSM"
                      error=""
                      value={selectedAccredited.name}
                      onChange={(data) => setSelectedAccredited(accrediteds.filter((item) => item.name === data)[0])}
                    >
                      {[...accrediteds.map((item) => item.name)]}
                    </BasicOption>

                    <ButtonTextIcon
                      label="Próximo"
                      type={BtnType.PRIMARY}
                      icon="chevron_right"
                      active
                      onClick={() => forwardSlider()}
                      ref={null}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="div3" id="div3">
              <div className="row h-100">
                <div className="col-12 d-flex flex-column justify-content-center align-items-center h-100">
                  <div className="w-100" style={{ marginBottom: 32 }}>
                    <button type="button" className="d-flex align-items-center borderless-icon-btn" onClick={() => backwardSlider()}>
                      <span className="material-icons-round">
                        chevron_left
                      </span>
                      Voltar
                    </button>
                  </div>
                  <div className="w-50" style={{ marginBottom: 32 }}>
                    <div className="title-container">
                      <div className="title-wrapper">
                        <p>Categoria</p>
                        <p>{selectedCategory.title}</p>
                      </div>
                      <div className="divider-vertical" />
                      <div className="title-wrapper">
                        <p>Credenciado</p>
                        <p>{selectedAccredited.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 h" style={{ marginBottom: 32 }}>

                    <div className="row">
                      {Procedures.map((item, i) => (
                        <>
                          <div className="col-4">
                            <BasicInput
                              id={`description-${i}`}
                              label="Procedimento"
                              readOnly={false}
                              helper="Ex: Biópsia intra-oral"
                              error={item.description.length < 1 ? 'Obrigatório.' : ''}
                              value={item.description}
                              type="text"
                              onChange={(data) => changeDescription(data, i)}
                            />
                          </div>
                          <div className="col-1">
                            <BasicInput
                              id={`valor-${i}`}
                              label="valor"
                              readOnly={false}
                              helper="Ex: 100,00"
                              error=""
                              value={item.value}
                              type="number"
                              onChange={(data) => changeValue(data, i)}
                            />
                          </div>
                          <div className="col-6">
                            <BasicInput
                              id={`description-${i}`}
                              label="Observação"
                              readOnly={false}
                              helper="Ex: Corte Axial"
                              error=""
                              value={item.observation}
                              type="text"
                              onChange={(data) => changeObservation(data, i)}
                            />
                          </div>
                          {
                            i !== 0 && (
                            <div className="col-1 d-flex align-items-center">
                              <button type="button" className="borderless-icon-btn-danger mb-3" onClick={() => deleteRow(i)}>
                                <span className="material-icons-round">
                                  delete_forever
                                </span>
                              </button>
                            </div>
                            )
                          }
                        </>
                      ))}

                      <div className="col-12">
                        <ButtonTextIcon
                          label="ADICIONAR OUTRO"
                          type={BtnType.SECONDARY}
                          icon="add"
                          active
                          onClick={() => addNewRow()}
                          ref={null}
                        />
                      </div>
                      <div className="col-12 footer">
                        <ButtonTextIcon
                          label="Salvar"
                          type={BtnType.PRIMARY}
                          icon="backup"
                          active={validateFields()}
                          onClick={() => saveProcedure()}
                          ref={null}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
