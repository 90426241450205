import React from 'react';
import { ModalActionType, ModalProps } from '../../components/Modal';

export default function changeModalPermission(
  setModal: (arg0: null | ModalProps) => void,
  changePermission: () => Promise<void>,
) {
  const modalProps: ModalProps = {
    children: 'Essa alteração afetará diretamente a forma como o  administrador terá acesso às informações / ações dentro do painel. Tem certeza que desja continuar?',
    title: 'Alterar permissão',
    actions: [
      {
        label: 'Cancelar',
        onClick: () => setModal(null),
        type: ModalActionType.DANGER,
      },
      {
        label: 'Alterar',
        onClick: () => {
          changePermission();
          setModal(null);
        },
        type: ModalActionType.PRIMARY,
      },
    ],
  };
  return modalProps;
}
