/* eslint-disable max-len */
import { isValid } from '@fnando/cnpj';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import BasicInput from '../../components/BasicInput';
import BasicOption from '../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import api from '../../config/axios';
import ClientPJProps from '../../dto/clientPJ';
import { statesOfBrazil } from '../../dto/states';
import {
  maskCep, maskCNPJ, maskDate, maskPhone, unmaskData,
} from '../../helpers/inputMasks.js';
import showApiErrors from '../../helpers/showApiErrors';
import DefaultLayout from '../../Layout';
import { normalizeDateToApi } from '../../helpers/dates';
import AddInput from '../../components/AddInput';
import fetchCNPJ from './fetchCNPJ';
import CEPFinder from '../../helpers/findAddressByCep';

export default function NewClientPJ() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<null | ModalProps>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [client, setClient] = useState({
    cnpj: '',
    openingDate: '',
    name: '',
    nameFantasy: '',
    size: '',
    addressStreet: '',
    addressNumber: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    addressState: 'MA',
    addressZipcode: '',
    email: '',
    phone: '',
    addressIbge: '',
    password: '',
    isActive: true,
  });

  async function changeAddressByCep() {
    try {
      setLoading(true);
      const findCep = await new CEPFinder(unmaskData(client.addressZipcode)).findCep();
      if (findCep !== null) {
        setClient({
          ...client,
          addressCity: findCep.city,
          addressState: findCep.state,
          addressNeighborhood: findCep.neighborhood,
          addressStreet: findCep.street,
          addressIbge: findCep.ibge,
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  function enableSaveButton() {
    if (
      client.cnpj.length > 0
        && client.openingDate.length > 9
        && client.name.length > 0
        && client.nameFantasy.length > 0
        && client.addressStreet.length > 0
        && client.addressNumber.length > 0
        && client.addressNeighborhood.length > 0
        && client.addressCity.length > 0
        && client.addressZipcode.length > 0
        && client.phone.length > 0
        && EmailValidator.validate(client.email)
        && client.password.length > 5
        && client.password === repeatPassword
    ) {
      return true;
    }
    return false;
  }

  async function saveClient() {
    try {
      setLoading(true);
      const noMaskData = {
        ...client, cnpj: unmaskData(client.cnpj), addressZipcode: unmaskData(client.addressZipcode), phone: unmaskData(client.phone),
      };
      await api.post('/admin/client', noMaskData, token);
      setLoading(false);
      navigate('/cliente/empresa');
    } catch (err: any) {
      if (typeof err.response !== 'undefined') {
        if (err.response.status === 422) {
          setModal(showApiErrors(setModal, err.response.data.errors));
        } else {
          setShowToastError(true);
          setTimeout(() => {
            setShowToastError(false);
          }, 3000);
          setModal({ title: 'Ocorreu um erro', children: 'Não foi possível processar sua solicitação', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
        }
      }
      setLoading(false);
      console.log(err.response);
    }
  }

  async function findCNPJ() {
    setLoading(true);
    const response = await fetchCNPJ(unmaskData(client.cnpj));
    if (response !== null) {
      setClient({ ...client, ...response });
    } else {
      setModal({ title: 'Ocorreu um erro ao buscar', children: 'Não foi possível autocompletar os dados da empresa. Digite manualmente ou aguarde 01 (um) minuto para tentar novamente', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
    setLoading(false);
  }

  return (
    <DefaultLayout
      pageTitle="Cadastrar novo cliente PJ"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row client card-bg p-0">
        <section className="row">
          <h1>Cadastro Cliente PJ</h1>
          <div className="col-4">
            <AddInput
              id="cnpj-input"
              label="CNPJ"
              readOnly={false}
              helper="Ex: 33.000.167/0001-01"
              error={!isValid(client.cnpj) ? 'Digite um CNPJ válido' : ''}
              value={client.cnpj}
              onChange={(data) => setClient({ ...client, cnpj: maskCNPJ(data) })}
              onAdd={() => findCNPJ()}
              type="text"
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="name-fantasy-input"
              label="Nome Fantasia"
              readOnly={false}
              helper="Ex: Petrobrás"
              error={client.nameFantasy.length < 1 ? 'Campo obrigatório' : ''}
              value={client.nameFantasy}
              type=""
              onChange={(data) => setClient({ ...client, nameFantasy: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="name-input"
              label="Razão social"
              readOnly={false}
              helper="Petroleo brasileiro SA"
              error={client.name.length < 1 ? 'Campo obrigatório' : ''}
              value={client.name}
              type=""
              onChange={(data) => setClient({ ...client, name: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="opening-input"
              label="Data de abertura"
              readOnly={false}
              helper="Ex: 31/01/1990"
              error={client.openingDate.length < 10 ? 'Digite uma data válida' : ''}
              value={client.openingDate}
              type=""
              onChange={(data) => setClient({ ...client, openingDate: maskDate(data) })}
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="tipo-input"
              label="Tipo"
              readOnly={false}
              helper="Ex: ME"
              error=""
              value={client.size}
              onChange={(data) => setClient({ ...client, size: data })}
            >
              {[
                'ME',
                'MEI',
                'EPP',
                'OUTRO',
              ]}
            </BasicOption>
          </div>
          <div className="col-4">
            <AddInput
              id="address-cep-input"
              label="CEP"
              readOnly={false}
              helper="Ex: 35.171-120"
              error={client.addressZipcode.length < 10 ? 'Campo obrigatório' : ''}
              value={client.addressZipcode}
              type="text"
              onAdd={() => changeAddressByCep()}
              onChange={(data) => setClient({ ...client, addressZipcode: maskCep(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-street-input"
              label="LOGRADOURO"
              readOnly={false}
              helper="Ex: Rua Três"
              error={client.addressStreet.length < 2 ? 'Campo Obrigatório' : ''}
              value={client.addressStreet}
              type="text"
              onChange={(data) => setClient({ ...client, addressStreet: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-number-input"
              label="Número"
              readOnly={false}
              helper="Ex: 133"
              error={client.addressNumber.length < 1 ? 'Campo Obrigatório' : ''}
              value={client.addressNumber}
              type="text"
              onChange={(data) => setClient({ ...client, addressNumber: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-complement-input"
              label="Complemento"
              readOnly={false}
              helper="Ex: Bloco A"
              error=""
              value={client.addressComplement}
              type="text"
              onChange={(data) => setClient({ ...client, addressComplement: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-neighborhood-input"
              label="Bairro"
              readOnly={false}
              helper="Centro"
              error={client.addressNeighborhood.length < 1 ? 'Campo obrigatório' : ''}
              value={client.addressNeighborhood}
              type="text"
              onChange={(data) => setClient({ ...client, addressNeighborhood: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-city-input"
              label="Cidade"
              readOnly
              helper="Ex: Imperatriz"
              error={client.addressCity.length < 1 ? 'Campo obrigatório' : ''}
              value={client.addressCity}
              type="text"
              onChange={(data) => setClient({ ...client, addressCity: data })}
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="address-state-input"
              label="Estado"
              readOnly
              helper=""
              error=""
              value={client.addressState}
              onChange={(data) => setClient({ ...client, addressState: data })}
            >
              {statesOfBrazil}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicInput
              id="phone-input"
              label="Telefone"
              readOnly={false}
              helper="Ex: (31) 99999 - 9999"
              error={client.phone.length < 1 ? 'Campo obrigatório' : ''}
              value={client.phone}
              type="text"
              onChange={(data) => setClient({ ...client, phone: maskPhone(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="email-input"
              label="E-mail"
              readOnly={false}
              helper="email@email.com"
              error={!EmailValidator.validate(client.email) ? 'Digite um email válido' : ''}
              value={client.email}
              type="email"
              onChange={(data) => setClient({ ...client, email: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="password-input"
              label="Senha"
              readOnly={false}
              helper="Mínimo 06 dígitos"
              error={client.password.length < 6 ? 'Mínimo 06 dígitos' : ''}
              value={client.password}
              type="password"
              onChange={(data) => setClient({ ...client, password: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="repeat-password-input"
              label="Repita a senha"
              readOnly={false}
              helper="Deve coincidir com a senha"
              error={repeatPassword !== client.password ? 'Deve coincidir com a senha' : ''}
              value={repeatPassword}
              type="password"
              onChange={(data) => setRepeatPassword(data)}
            />
          </div>
          <div className="col-12 footer">
            <ButtonTextIcon
              label="salvar"
              type={BtnType.PRIMARY}
              icon="backup"
              active={enableSaveButton()}
              onClick={() => saveClient()}
              ref={null}
            />
          </div>
        </section>
      </div>
    </DefaultLayout>
  );
}
