/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddInput from '../../components/AddInput';
import { ModalProps } from '../../components/Modal';
import api from '../../config/axios';
import { maskCPF } from '../../helpers/inputMasks.js';
import DefaultLayout from '../../Layout';

type UsersProps = {
    id: number,
    name: string,
    cpf: string,
    type: 'holder' | 'dependent',
    isClientPf: boolean,
    pjClientId: number,
    isActive: boolean,
    registration: string,
    uuid: string,
}

type ResponseProps = {
  users: UsersProps[]
}

export default function SearchByName() {
  const navigate = useNavigate();
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [nameInput, setNameInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [searchResult, setSearchResult] = useState<ResponseProps>({ users: [] });

  async function fetchUser() {
    try {
      setLoading(true);
      const response = await api.post<ResponseProps>('/admin/search/name', {
        name: nameInput.trim(),
      }, token);
      setSearchResult(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function pushToProfile(user: UsersProps) {
    if (user.isClientPf) {
      if (user.type === 'holder') {
        navigate(`/cliente/pessoa-fisica/editar-titular/${user.id}`);
      } else {
        navigate(`/cliente/editar-dependente-pf/${user.uuid}`);
      }
    } else if (user.type === 'holder') {
      navigate(`/cliente/editar-aderente/${user.pjClientId}/${user.id}`);
    } else {
      navigate(`/cliente/editar-dependente/${user.uuid}`);
    }
  }
  return (
    <DefaultLayout
      pageTitle="Pesquisa por Nome"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Pesquisa de usuário por nome</h1>
          <div className="col-4">
            <AddInput
              id="name-input"
              label="Nome"
              icon="search"
              readOnly={false}
              helper="Ex: Valdecir"
              error=""
              value={nameInput}
              onChange={(data) => setNameInput(data)}
              onAdd={() => fetchUser()}
              type=""
            />
          </div>
        </div>
      </div>

      <div className="row products mt-4">
        <div className="col-12 card-bg p-4">
          <div className="row">
            <h1>Resultados</h1>
            <div className="table-wrapper">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">CPF</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Classificação</th>
                    <th className="text-end" scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResult.users.length < 1 ? (
                    <p>Nenhum resultado para sua busca</p>
                  )
                    : searchResult.users.map((item, i) => (
                      <tr key={i}>
                        <td>{item.name}</td>
                        <td>{maskCPF(item.cpf)}</td>
                        <td>{item.type === 'holder' ? 'Titular' : 'Dependente'}</td>
                        <td>{item.isClientPf ? 'PF' : 'PJ'}</td>

                        <td align="right">
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="table-action-icon">
                              <button type="button" onClick={() => navigate(`/dashboard/consulta-por-cpf/${item.cpf}`)}>
                                <span className="material-icons-round">
                                  search
                                </span>
                              </button>
                            </div>
                            <div className="table-action-icon">
                              <button type="button" onClick={() => pushToProfile(item)}>
                                <span className="material-icons-round">
                                  more
                                </span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
