import React, { useEffect, useState } from 'react';

interface AllTimeValueProps {
    id: string,
    title: string,
    text: string,
    value: string,

}

export default function AllTimeValueModal({
  id, title, text, value,
}: AllTimeValueProps) {
  return (
    <div className="modal fade" id={id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <p className="text-center fw-bold">
              {text}
            </p>
            <p className="text-center mt-4 text-primary fw-bold" style={{ fontSize: 52 }}>{value}</p>

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
