/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import BasicInput from '../../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import api from '../../../config/axios';
import { maskDate } from '../../../helpers/inputMasks.js';
import BookLogModal from './BookLogModal';

interface PageProps {
    setModal: (arg0: React.ReactNode) => void;
    setLoading: (arg0: boolean) => void;
}

type ModalProps = {
  total_clients: string
  total_de_dependentes_pf_inseridos_no_dia: string
  total_de_dependentes_pj_inseridos_no_dia: string
  total_de_titulares_pf_inseridos_no_dia: string
  total_de_titulares_pj_inseridos_no_dia: string
  total_de_vidas_bloqueadas_permanentemente: string
  total_de_vidas_bloqueadas_temporariamente: string
  total_de_vidas_inseridas_no_dia: string
  total_dependentes_pf_bloqueado_permanentemente: string
  total_dependentes_pf_bloqueado_temporariamente: string
  total_dependentes_pj_bloqueado_permanentemente: string
  total_dependentes_pj_bloqueado_temporariamente: string
  total_dependents: string
  total_holders: string
  total_lifes: string
  total_titular_pf_bloqueado_permanentemente: string
  total_titular_pf_bloqueado_temporariamente: string
  total_titular_pj_bloqueado_permanentemente: string
  total_titular_pj_bloqueado_temporariamente: string
  total_vidas_pf_bloqueado_permanentemente: string
  total_vidas_pf_bloqueado_temporariamente: string
  total_vidas_pj_bloqueado_permanentemente: string
  total_vidas_pj_bloqueado_temporariamente: string
  total_titulares_ativos_pf: string,
  total_dependentes_ativos_pf: string,
  total_titulares_ativos_pj: string,
  total_dependentes_ativos_pj: string,
}

export default function BookLog({ setModal, setLoading }: PageProps) {
  const [finalDateInput, setFinalDateInput] = useState(DateTime.now().toFormat('dd-MM-yyyy'));
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);
  const [modalBtnRef] = useState(React.createRef<any>());

  async function fetchData(finalDate: string) {
    try {
      const response = await api.post('admin/reports/byperiod/logbook', {
        finalDate,
      }, token);
      console.log(response.data);
      return response;
    } catch (err: any) {
      console.log(err.response.data);
      throw new Error('Erro ao buscar relatórios.');
    }
  }

  async function validateDate() {
    try {
      const finalDateAsDateTime = DateTime.fromFormat(finalDateInput, 'dd-MM-yyyy');
      if (!finalDateAsDateTime.isValid) {
        throw new Error('Data final inválida.');
      }
      return {
        finalDateAsDateTime,
      };
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  async function callApi() {
    try {
      setLoading(true);
      const date = await validateDate();
      const response = await fetchData(date.finalDateAsDateTime.toFormat('dd-MM-yyyy'));
      setModalProps(response.data);
      modalBtnRef.current.click();
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setModal(<p>{err.message}</p>);
    }
  }

  return (
    <>
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>
            Relatórios de
            {' '}
            <strong className="fw-bold text-primary">Diário de bordo</strong>
            {' '}
          </h1>
          <div className="row">
            <div className="col-4">
              <BasicInput
                id="date"
                label="Data"
                readOnly={false}
                helper="Ex: 13-06-2022"
                error=""
                value={finalDateInput}
                onChange={(data) => setFinalDateInput(maskDate(data))}
                type=""
              />
            </div>
            <div className="col-4 d-flex align-items-center mb-3">
              <ButtonTextIcon
                label="Gerar relatório"
                type={BtnType.PRIMARY}
                icon="search"
                active
                onClick={() => callApi()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>
      <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#bookLogModal" ref={modalBtnRef}>
        Launch static backdrop modal
      </button>
      <BookLogModal
        id="bookLogModal"
        finalDate={finalDateInput}
        data={modalProps || {
          total_clients: '0',
          total_de_dependentes_pf_inseridos_no_dia: '0',
          total_de_dependentes_pj_inseridos_no_dia: '0',
          total_de_titulares_pf_inseridos_no_dia: '0',
          total_de_titulares_pj_inseridos_no_dia: '0',
          total_de_vidas_bloqueadas_permanentemente: '0',
          total_de_vidas_bloqueadas_temporariamente: '0',
          total_de_vidas_inseridas_no_dia: '0',
          total_dependentes_pf_bloqueado_permanentemente: '0',
          total_dependentes_pf_bloqueado_temporariamente: '0',
          total_dependentes_pj_bloqueado_permanentemente: '0',
          total_dependentes_pj_bloqueado_temporariamente: '0',
          total_dependents: '0',
          total_holders: '0',
          total_lifes: '0',
          total_titular_pf_bloqueado_permanentemente: '0',
          total_titular_pf_bloqueado_temporariamente: '0',
          total_titular_pj_bloqueado_permanentemente: '0',
          total_titular_pj_bloqueado_temporariamente: '0',
          total_vidas_pf_bloqueado_permanentemente: '0',
          total_vidas_pf_bloqueado_temporariamente: '0',
          total_vidas_pj_bloqueado_permanentemente: '0',
          total_vidas_pj_bloqueado_temporariamente: '0',
          total_titulares_ativos_pf: '0',
          total_dependentes_ativos_pf: '0',
          total_titulares_ativos_pj: '0',
          total_dependentes_ativos_pj: '0',
        }}
      />
    </>
  );
}
