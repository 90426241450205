/* eslint-disable no-mixed-operators */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalActionType, ModalProps } from '../../components/Modal';
import api from '../../config/axios';
import DefaultLayout from '../../Layout';
import Incomes from './Incomes';
import generateProfitTableFilters, { ProfitFilterProps } from './profitFilters';

type Document = {
    id: number,
    filename: string,
    url: string,
    createdAt: string,
    updatedAt: string,
}

interface IExpense {
      id: number,
      description: string,
      category: string,
      value: number,
      expenseDate: string,
      payed: boolean,
      paymentDate: string,
      paymentWay: string,
      paymentReference: string,
      notes: string,
      createdByUserId: number,
      createdAt: string,
      updatedAt: string,
      documents: Document[]
}

interface IProfitData {
  id: number,
  description: string,
  value: number,
  liquidValue: number,
  incomeDate: string,
  paymentWay: string,
  notes: string,
  contractPf: { contractPfPaymentId: number } | null,
  contractPj: { contractPjPaymentId: number } | null,
  createdByUserId: number,
  createdAt: string,
  updatedAt: string,
  documents: Document[]
}

interface IProfit {
  meta: {
    total: number,
    per_page: number,
    current_page: number,
    last_page: number,
    first_page: number,
    first_page_url: string,
    last_page_url: string,
    next_page_url: string,
    previous_page_url: string

  }
  data: IProfitData[]
}

enum ActualPage {
  EXPENSE,
  PROFIT,
}

export default function ExpensesAndProfits() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [expenses, setExpenses] = useState<IExpense[]>([]);
  const [actualPage, setActualPage] = useState(ActualPage.PROFIT);
  const [solidFilterValue, setSolidFilterValue] = useState(0);
  const [liquidFilterValue, setLiquidFilterValue] = useState(0);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [profitTableFilters, setProfitTableFilters] = useState<ProfitFilterProps>({
    maxRowsPerPage: 15,
    actualPage: 1,
    initialDate: '',
    finalDate: '',
    profitType: '',
    category: null,
  });

  const [profits, setProfits] = useState<IProfit>({
    meta: {
      total: 0,
      per_page: 15,
      current_page: 1,
      last_page: 1,
      first_page: 1,
      first_page_url: '',
      last_page_url: '',
      next_page_url: '',
      previous_page_url: '',

    },
    data: [],
  });

  const [initialProfitDate, setInitialProfitDate] = useState('');
  const [finalProfitDate, setFinalProfitDate] = useState('');
  const [paymentWay, setPaymentWay] = useState('TODOS');
  const [allProfits, setAllProfits] = useState<{liquidValue: number, value: number, incomeDate: string }[]>([]);

  async function fetchExpenses() {
    try {
      const response = await api.get('admin/expense', token);
      setExpenses(response.data);
    } catch (err) {
      setModal({ title: 'Ocorreu um erro', actions: [{ label: 'Ok, entendi', onClick: () => null, type: ModalActionType.PRIMARY }], children: 'Não foi possível carregar as despesas. Tente novamente em instantes' });
    }
  }

  async function calcAllProfitBasedOnFilter() {
    try {
      const pages: number[] = [];
      for (let x = 0; x < profits.meta.last_page; x += 1) {
        pages.push(x + 1);
      }

      const fetch = pages.map((item) => api.get<IProfit>(`/admin/income/paginate/search/${generateProfitTableFilters({ ...profitTableFilters, actualPage: item })}`, token));

      const response = await Promise.all(fetch);

      const reduced = response.map((item) => {
        if (item.data.data.length > 0) {
          return item.data.data.map((data) => data.value).reduce((a, b) => a + b);
        }
        return 0;
      });
      if (reduced.length > 0) {
        setSolidFilterValue(reduced.reduce((a, b) => a + b));
      } else {
        setSolidFilterValue(0);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function calcAllLiquidProfitBasedOnFilter() {
    try {
      const pages: number[] = [];
      for (let x = 0; x < profits.meta.last_page; x += 1) {
        pages.push(x + 1);
      }

      const fetch = pages.map((item) => api.get<IProfit>(`/admin/income/paginate/search/${generateProfitTableFilters({ ...profitTableFilters, actualPage: item })}`, token));

      const response = await Promise.all(fetch);

      const reduced = response.map((item) => {
        if (item.data.data.length > 0) {
          return item.data.data.map((data) => data.liquidValue).reduce((a, b) => a + b);
        }
        return 0;
      });
      if (reduced.length > 0) {
        setLiquidFilterValue(reduced.reduce((a, b) => a + b));
      } else {
        setLiquidFilterValue(0);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchProfits(filters: string) {
    try {
      setLoading(true);
      const response = await api.get(`/admin/income/paginate/search/${filters}`, token);
      await calcAllLiquidProfitBasedOnFilter();
      await calcAllProfitBasedOnFilter();
      setProfits(response.data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log(err.response.data);
      setModal({ title: 'Ocorreu um erro', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }], children: 'Não foi possível carregar as entradas. Tente novamente em instantes' });
    }
  }

  async function fetchAllProfits() {
    try {
      setLoading(true);
      const response = await api.get('/admin/income', token);
      setAllProfits(response.data);
    } catch (err) {
      setModal({ title: 'Ocorreu um erro', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }], children: 'Não foi possível carregar as entradas. Tente novamente em instantes' });
    }
  }

  function calcAllExpenses() {
    if (expenses.length > 0) {
      const total = expenses.map((item) => item.value).reduce((prev, acc) => prev + acc);
      return total.toFixed(2);
    }
    return 0;
  }

  function calcAllSolidProfits() {
    if (allProfits.length > 0) {
      const total = allProfits.map((item) => item.value).reduce((prev, acc) => prev + acc);
      return total.toFixed(2);
    }
    return 0;
  }

  function calcAllLiquidProfits() {
    if (allProfits.length > 0) {
      const total = allProfits.map((item) => item.liquidValue).reduce((prev, acc) => prev + acc);
      return total.toFixed(2);
    }
    return 0;
  }

  function generatePagination(totalOfPages: number) {
    const pagination: number[] = [];
    for (let i = 0; i < totalOfPages; i += 1) {
      pagination.push(i + 1);
    }
    return pagination;
  }

  function profitTableGoToNextPage() {
    if (profitTableFilters.actualPage < profits.meta.last_page) {
      setProfitTableFilters((prev) => ({ ...prev, actualPage: prev.actualPage + 1 }));
    }
  }

  function filterByDate() {
    const category = paymentWay === 'TODOS' ? null : paymentWay;
    if (initialProfitDate.length === '00-00-0000'.length && finalProfitDate.length === '00-00-0000'.length) {
      setProfitTableFilters({
        ...profitTableFilters, initialDate: initialProfitDate, finalDate: finalProfitDate, category,
      });
    } else if (initialProfitDate.length === '00-00-0000'.length && finalProfitDate.length !== '00-00-0000'.length) {
      setProfitTableFilters({
        ...profitTableFilters, initialDate: initialProfitDate, finalDate: finalProfitDate, category,
      });
    } else if (initialProfitDate === '' && finalProfitDate === '') {
      setProfitTableFilters({
        ...profitTableFilters, initialDate: initialProfitDate, finalDate: finalProfitDate, category,
      });
    } else if (initialProfitDate === '' && finalProfitDate.length === '00-00-0000'.length) {
      setProfitTableFilters({
        ...profitTableFilters, initialDate: initialProfitDate, finalDate: finalProfitDate, category,
      });
    } else {
      setModal({ title: 'Ocorreu um erro', children: 'Adicione uma data correta', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  function removeFilters() {
    setPaymentWay('TODOS');
    setProfitTableFilters({
      ...profitTableFilters, initialDate: '', finalDate: '', category: null,
    });
    setInitialProfitDate('');
    setFinalProfitDate('');
  }

  useEffect(() => {
    fetchExpenses();
  }, []);

  return (
    <DefaultLayout
      pageTitle="Financeiro"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >

      <div className="row">
        <div className="col-12 header-btn-wrapper">
          <button
            type="button"
            className={actualPage === ActualPage.PROFIT ? 'btn-active' : 'btn-inactive'}
            onClick={() => setActualPage(ActualPage.PROFIT)}
          >
            Entradas
          </button>
          <button
            type="button"
            className={actualPage === ActualPage.EXPENSE ? 'btn-active' : 'btn-inactive'}
            onClick={() => setActualPage(ActualPage.EXPENSE)}
          >
            Saídas
          </button>
        </div>
      </div>

      {actualPage === ActualPage.EXPENSE ? (
        <>
          <div className="row mb-2">
            <div className="col-12">
              <div className="d-flex justify-content-end mt-4">
                <div className="fetched-values-group">
                  <div className="income-fetched-value-wrapper">
                    <p>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#16C784" />
                        <path d="M11.4758 19H12.5547L12.5598 17.7477C14.743 17.5781 15.9949 16.2875 16 14.4555C15.9949 12.5141 14.5038 11.6281 12.9364 11.2289L12.6005 11.1359L12.6209 8.01328C13.4351 8.16641 13.9593 8.69141 14.0407 9.47891H15.8575C15.827 7.73984 14.5547 6.47109 12.631 6.27422L12.6361 5H11.5573L11.5522 6.27422C9.64377 6.48203 8.26972 7.73984 8.27481 9.55C8.27481 11.1578 9.33842 12.082 11.0534 12.5578L11.5115 12.6836L11.4911 15.9922C10.5802 15.8391 9.92366 15.2812 9.85751 14.2914H8C8.07125 16.3422 9.38422 17.5617 11.4809 17.7477L11.4758 19ZM12.57 15.9922L12.5903 12.9898C13.5165 13.2797 14.1069 13.6625 14.112 14.4391C14.1069 15.2484 13.5013 15.8281 12.57 15.9922ZM11.5216 10.8406C10.8193 10.6 10.1781 10.2008 10.1883 9.42422C10.1934 8.71875 10.687 8.17188 11.542 8.01328L11.5216 10.8406Z" fill="white" />
                      </svg>
                      Total Saída:
                      {' '}
                      <span className="text-danger">{`R$ ${calcAllExpenses()}`}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row seller">
            <div className="col-12 p-0 card-bg p-4">
              <h1>Saídas</h1>
              <div className="table-wrapper">
                <div className="table-actions">
                  <button className="table-btn-primary" type="button" onClick={() => navigate('/financeiro/despesa/criar')}>
                    <span className="material-icons-round">
                      add
                    </span>
                    adicionar
                  </button>
                </div>
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Descrição</th>
                      <th scope="col">Categoria</th>
                      <th scope="col">Dt. Criação</th>
                      <th scope="col">Pago</th>
                      <th scope="col">Valor</th>
                      <th className="text-end" scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenses.length > 0 ? expenses.map((item, i) => (
                      <tr key={i}>
                        <td>{item.description}</td>
                        <td>{item.category}</td>
                        <td>{item.expenseDate}</td>
                        <td>{item.payed ? 'Pago' : 'Não pago'}</td>
                        <td>{`R$ ${item.value.toFixed(2)}`}</td>
                        <td align="right">
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="table-action-icon">
                              <button type="button" onClick={() => navigate(`/financeiro/despesa/editar/${item.id}`)}>
                                <span className="material-icons-round">
                                  more
                                </span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )) : (
                      <p>Não existem despesas cadastradas.</p>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Incomes
          loading={loading}
          setLoading={setLoading}
          setShowToastSuccess={setShowToastSuccess}
          setModal={(arg) => setModal(arg)}
        />
      )}

    </DefaultLayout>
  );
}
