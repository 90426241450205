/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingWithLabelFull from '../../components/LoadingWithLabelFull';
import { ModalActionType, ModalProps } from '../../components/Modal';
import api from '../../config/axios';
import DefaultLayout from '../../Layout';

interface ILifesContractPf {
    isPf: boolean,
    contractId: number,
    holderName: string,
    holderId: number,
    dependentsEnabledByContract: number,
    actualDependents: number
}

interface IHoldersPjInconsistency {
    isPf: false,
    contractId: number,
    clientName: string,
    clientId: number,
    holdersEnabledByContract: number,
    actualHoldersAmount: number
}

interface IDependentsPjInconsistency {
    isPf: boolean,
    contractId: number,
    clientId: number,
    clientName: string,
    dependentsEnabledByContract: number,
    actualDependentsAmount: number
}

interface IPaymentInstallmentsPJDiff {
    paymentPjId: number,
    contractPjId: number,
    totalValue: number,
    installmentsSum: number
}

interface IPaymentInstallmentsPFDiff {
    paymentPfId: number,
    contractPfId: number,
    totalValue: number,
    installmentsSum: number
}

interface IChargePDFwithDiffMethodThanBoletoPF {
    paymentId: number,
    installments:
        {
            id: number,
            contractPfPaymentId: number,
            dueDate: string,
            value: number,
            nonCardTaxes: string,
            card: boolean,
            flag: null | string,
            cardLastDigits: string,
        }[]
}

interface IChargePDFwithDiffMethodThanBoletoPJ {
    paymentId: number,
    installments:
        {
            id: number,
            contractPjPaymentId: number,
            dueDate: string,
            value: number,
            nonCardTaxes: string,
            card: boolean,
            flag: null | string,
            cardLastDigits: string,
        }[]
}

export default function Inconsistency() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [lifesContractPf, setLifesContractPf] = useState<ILifesContractPf[]>([]);
  const [holdersPjInconsistency, setHoldersPjInconsistency] = useState<IHoldersPjInconsistency[]>([]);
  const [dependentsPjInconsistency, setDependentsPjInconsistency] = useState<IDependentsPjInconsistency[]>([]);
  const [paymentInstallmentsPJDiff, setPaymentInstallmentsPJDiff] = useState<IPaymentInstallmentsPJDiff[]>([]);
  const [paymentInstallmentsPFDiff, setPaymentInstallmentsPFDiff] = useState<IPaymentInstallmentsPFDiff[]>([]);
  const [diffBoletoPF, setDiffBoletoPF] = useState<IChargePDFwithDiffMethodThanBoletoPF[]>([]);
  const [diffBoletoPJ, setDiffBoletoPJ] = useState<IChargePDFwithDiffMethodThanBoletoPJ[]>([]);
  const [percentage, setPercentage] = useState(0);
  const [label, setLabel] = useState('Buscando inconsistencia no cadastro de vidas PF');

  async function fetchInconsistency() {
    try {
      setLoading(true);
      const move = 100 / 7;
      const fetchLifesContractPf = await api.get<ILifesContractPf[]>('admin/reports/inconsistency/contractpflifes', token);
      setLifesContractPf(fetchLifesContractPf.data);
      setPercentage(move);

      setLabel('Buscando inconsistência no cadastro de Titulares PJ');
      const fetchHoldersPjInconsistency = await api.get<IHoldersPjInconsistency[]>('admin/reports/inconsistency/contractpjholderslifes', token);
      setHoldersPjInconsistency(fetchHoldersPjInconsistency.data);
      setPercentage((prev) => prev + move);

      setLabel('Buscando inconsistência no cadastro de Dependentes PJ');
      const fetchdependentsPjInconsistency = await api.get<IDependentsPjInconsistency[]>('admin/reports/inconsistency/contractpjdependentslifes', token);
      setDependentsPjInconsistency(fetchdependentsPjInconsistency.data);
      setPercentage((prev) => prev + move);

      setLabel('Buscando inconsistência nos valores de parcelas de pgto. PJ');
      const fetchpaymentInstallmentsPJDiff = await api.get<IPaymentInstallmentsPJDiff[]>('admin/reports/inconsistency/paymentpjinstallmentvalue', token);
      setPaymentInstallmentsPJDiff(fetchpaymentInstallmentsPJDiff.data);
      setPercentage((prev) => prev + move);

      setLabel('Buscando inconsistência nos valores de parcelas de pgto. PF');
      const fetchpaymentInstallmentsPFDiff = await api.get<IPaymentInstallmentsPFDiff[]>('admin/reports/inconsistency/paymentpfinstallmentvalue', token);
      setPaymentInstallmentsPFDiff(fetchpaymentInstallmentsPFDiff.data);
      setPercentage((prev) => prev + move);

      setLabel('Buscando inconsistência nos boletos gerados de pgto. PF');
      const fetchdiffBoletoPF = await api.get<IChargePDFwithDiffMethodThanBoletoPF[]>('admin/reports/inconsistency/paymentpfinstallmentchargepdf', token);
      setDiffBoletoPF(fetchdiffBoletoPF.data);
      setPercentage((prev) => prev + move);

      setLabel('Buscando inconsistência nos boletos gerados de pgto. PJ');
      const fetchdiffBoletoPJ = await api.get<IChargePDFwithDiffMethodThanBoletoPJ[]>('admin/reports/inconsistency/paymentpjinstallmentchargepdf', token);
      setDiffBoletoPJ(fetchdiffBoletoPJ.data);
      setPercentage((prev) => prev + move);

      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (err) {
      setModal({ title: 'Ocorreu um erro', children: 'Ocorreu um erro ao buscar inconsistências', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  useEffect(() => {
    fetchInconsistency();
  }, []);

  if (loading) {
    return (
      <DefaultLayout
        pageTitle="Possíveis inconsistências"
        loading={false}
        showToastSuccess={showToastSuccess}
        showToastError={showToastError}
        showRightSlider={false}
        rightSliderContent={undefined}
        modal={modal}
        setCloseRightSlider={() => null}
      >
        <div className="row">
          <LoadingWithLabelFull percentage={percentage} label={label} />
        </div>
      </DefaultLayout>
    );
  }
  return (
    <DefaultLayout
      pageTitle="Verifificar inconsistências"
      loading={false}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >

      {lifesContractPf.length === 0
        && holdersPjInconsistency.length === 0
        && dependentsPjInconsistency.length === 0
        && paymentInstallmentsPJDiff.length === 0
        && paymentInstallmentsPFDiff.length === 0
        && diffBoletoPF.length === 0
        && diffBoletoPJ.length === 0
        && (
        <div className="row mb-4">
          <div className="col-12 card-bg p-4">
            <p>Nenhuma inconsistência identificada.</p>
          </div>
        </div>
        ) }
      {lifesContractPf.length > 0 ? (
        <>
          <div className="row">
            <div className="col-12 d-flex align-items-center mb-2 mt-2">
              <h1 className="fw-bold">DEPENDENTES PF</h1>
              <div className="horizontal-divider" />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 card-bg p-4" style={{ backgroundColor: 'orange' }}>
              <p style={{ color: 'white' }}>As divergencias abaixo indicam que a quantidade de dependentes PF cadastrados não coincidem pelas estabelecidas em contrato, podendo implicar em inconsistência nos relatórios.</p>
            </div>
          </div>
          <div className="row products">
            <div className="col-12 p-0 card-bg p-4">
              <h1>Contratos PF vinculados</h1>
              <div className="table-wrapper">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">ID contrato</th>
                      <th scope="col">Titular</th>
                      <th scope="col">Total dep. contrato</th>
                      <th scope="col">Total dep. atual</th>
                      <th className="text-end" scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lifesContractPf.map((item, i) => (
                      <tr key={i}>
                        <td>{item.contractId}</td>
                        <td>{item.holderName}</td>
                        <td>{item.dependentsEnabledByContract}</td>
                        <td>{item.actualDependents}</td>
                        <td align="right">
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="table-action-icon">
                              <button type="button" onClick={() => navigate(`/contratos/pf/visualizar/${item.contractId}`)}>
                                <span className="material-icons-round">
                                  more
                                </span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {holdersPjInconsistency.length > 0 ? (
        <>
          <div className="row">
            <div className="col-12 d-flex align-items-center mb-2 mt-2">
              <h1 className="fw-bold">TITULARES PJ</h1>
              <div className="horizontal-divider" />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 card-bg p-4" style={{ backgroundColor: 'orange' }}>
              <p style={{ color: 'white' }}>As divergencias abaixo indicam que a quantidade de titulares PJ cadastrados nao coincidem pelas estabelecidas em contrato, podendo implicar em inconsistência nos relatórios.</p>
            </div>
          </div>
          <div className="row products">
            <div className="col-12 p-0 card-bg p-4">
              <h1>Contratos PJ vinculados</h1>
              <div className="table-wrapper">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">ID contrato</th>
                      <th scope="col">Cliente</th>
                      <th scope="col">Total titulares / Contrato</th>
                      <th scope="col">Cadastrados</th>
                      <th className="text-end" scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {holdersPjInconsistency.map((item, i) => (
                      <tr key={i}>
                        <td>{item.contractId}</td>
                        <td>{item.clientName}</td>
                        <td>{item.holdersEnabledByContract}</td>
                        <td>{item.actualHoldersAmount}</td>
                        <td align="right">
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="table-action-icon">
                              <button type="button" onClick={() => navigate(`/contratos/pj/editar/${item.contractId}`)}>
                                <span className="material-icons-round">
                                  more
                                </span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {dependentsPjInconsistency.length > 0 ? (
        <>
          <div className="row">
            <div className="col-12 d-flex align-items-center mb-2 mt-2">
              <h1 className="fw-bold">DEPENDENTES PJ</h1>
              <div className="horizontal-divider" />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 card-bg p-4" style={{ backgroundColor: 'orange' }}>
              <p style={{ color: 'white' }}>As divergencias abaixo indicam que a quantidade de dependentes PJ cadastrados nao coincidem pelas estabelecidas em contrato, podendo implicar em inconsistência nos relatórios.</p>
            </div>
          </div>
          <div className="row products">
            <div className="col-12 p-0 card-bg p-4">
              <h1>Contratos PJ vinculados</h1>
              <div className="table-wrapper">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">ID contrato</th>
                      <th scope="col">Cliente</th>
                      <th scope="col">Total dependentes / Contrato</th>
                      <th scope="col">Cadastrados</th>
                      <th className="text-end" scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dependentsPjInconsistency.map((item, i) => (
                      <tr key={i}>
                        <td>{item.contractId}</td>
                        <td>{item.clientName}</td>
                        <td>{item.dependentsEnabledByContract}</td>
                        <td>{item.actualDependentsAmount}</td>
                        <td align="right">
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="table-action-icon">
                              <button type="button" onClick={() => navigate(`/contratos/pj/editar/${item.contractId}`)}>
                                <span className="material-icons-round">
                                  more
                                </span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {paymentInstallmentsPJDiff.length > 0 ? (
        <>
          <div className="row">
            <div className="col-12 d-flex align-items-center mb-2 mt-2">
              <h1 className="fw-bold">PAGAMENTOS PJ</h1>
              <div className="horizontal-divider" />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 card-bg p-4" style={{ backgroundColor: 'orange' }}>
              <p style={{ color: 'white' }}>As divergencias abaixo indicam que a soma das parcelas não coincidem com o valor do pagamento.</p>
            </div>
          </div>
          <div className="row products">
            <div className="col-12 p-0 card-bg p-4">
              <h1>Pagamento PJ</h1>
              <div className="table-wrapper">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">ID PGTO.</th>
                      <th scope="col">ID Contrato</th>
                      <th scope="col">Valor pagamento</th>
                      <th scope="col">Total das parcelas</th>
                      <th className="text-end" scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentInstallmentsPJDiff.map((item, i) => (
                      <tr key={i}>
                        <td>{item.paymentPjId}</td>
                        <td>{item.contractPjId}</td>
                        <td>{item.totalValue}</td>
                        <td>{item.installmentsSum}</td>
                        <td align="right">
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="table-action-icon">
                              <button type="button" onClick={() => navigate(`/contratos/pj/forma-de-pagamento/visualizar/${item.paymentPjId}`)}>
                                <span className="material-icons-round">
                                  more
                                </span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {paymentInstallmentsPFDiff.length > 0 ? (
        <>
          <div className="row">
            <div className="col-12 d-flex align-items-center mb-2 mt-2">
              <h1 className="fw-bold">PAGAMENTOS PF</h1>
              <div className="horizontal-divider" />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 card-bg p-4" style={{ backgroundColor: 'orange' }}>
              <p style={{ color: 'white' }}>As divergencias abaixo indicam que a soma das parcelas não coincidem com o valor do pagamento.</p>
            </div>
          </div>
          <div className="row products">
            <div className="col-12 p-0 card-bg p-4">
              <h1>Pagamento PF</h1>
              <div className="table-wrapper">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">ID PGTO.</th>
                      <th scope="col">ID Contrato</th>
                      <th scope="col">Valor pagamento</th>
                      <th scope="col">Total das parcelas</th>
                      <th className="text-end" scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentInstallmentsPFDiff.map((item, i) => (
                      <tr key={i}>
                        <td>{item.paymentPfId}</td>
                        <td>{item.contractPfId}</td>
                        <td>{item.totalValue}</td>
                        <td>{item.installmentsSum}</td>
                        <td align="right">
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="table-action-icon">
                              <button type="button" onClick={() => navigate(`/contratos/pf/forma-de-pagamento/visualizar/${item.paymentPfId}`)}>
                                <span className="material-icons-round">
                                  more
                                </span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {diffBoletoPF.length > 0 ? (
        <>
          <div className="row">
            <div className="col-12 d-flex align-items-center mb-2 mt-2">
              <h1 className="fw-bold">BOLETOS NÃO CANCELADOS - PF</h1>
              <div className="horizontal-divider" />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 card-bg p-4" style={{ backgroundColor: 'orange' }}>
              <p style={{ color: 'white' }}>As divergencias abaixo indicam que existem boletos gerados para parcelas que estão cadastrados com métodos diferentes. O não cancelamento de um boleto pode implicar em duplicidade de pagamentos. OBS: apenas listando parcelas cujo status é DIFERENTE DE PAGO.</p>
              {' '}
            </div>
          </div>
          <div className="row products">
            <div className="col-12 p-0 card-bg p-4">
              <h1>Pagamento PF</h1>
              <div className="table-wrapper">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">ID PGTO.</th>
                      <th scope="col">Qtd. Parcelas com boleto gerado</th>
                      <th className="text-end" scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {diffBoletoPF.map((item, i) => (
                      <tr key={i}>
                        <td>{item.paymentId}</td>
                        <td>{item.installments.length}</td>
                        <td align="right">
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="table-action-icon">
                              <button type="button" onClick={() => navigate(`/contratos/pf/forma-de-pagamento/visualizar/${item.paymentId}`)}>
                                <span className="material-icons-round">
                                  more
                                </span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {diffBoletoPJ.length > 0 ? (
        <>
          <div className="row">
            <div className="col-12 d-flex align-items-center mb-2 mt-2">
              <h1 className="fw-bold">BOLETOS NÃO CANCELADOS - PJ</h1>
              <div className="horizontal-divider" />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 card-bg p-4" style={{ backgroundColor: 'orange' }}>
              <p style={{ color: 'white' }}>As divergencias abaixo indicam que existem boletos gerados para parcelas que estão cadastrados com métodos diferentes. O não cancelamento de um boleto pode implicar em duplicidade de pagamentos. OBS: apenas listando parcelas cujo status é DIFERENTE DE PAGO.</p>
            </div>
          </div>
          <div className="row products">
            <div className="col-12 p-0 card-bg p-4">
              <h1>Pagamento PF</h1>
              <div className="table-wrapper">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">ID PGTO.</th>
                      <th scope="col">Qtd. Parcelas com boleto gerado</th>
                      <th className="text-end" scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {diffBoletoPJ.map((item, i) => (
                      <tr key={i}>
                        <td>{item.paymentId}</td>
                        <td>{item.installments.length}</td>
                        <td align="right">
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="table-action-icon">
                              <button type="button" onClick={() => navigate(`/contratos/pj/forma-de-pagamento/visualizar/${item.paymentId}`)}>
                                <span className="material-icons-round">
                                  more
                                </span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : null}

    </DefaultLayout>
  );
}
