/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import api from '../../../config/axios';
import DefaultLayout from '../../../Layout';

type MetaProps = {
    total: number,
    per_page: number,
    current_page: number,
    last_page: number,
    first_page: number,
}

type AccreditedProps = {
    id: number,
    name: string,
}

type CategoryExamProps = {
    id: number,
    title: string
}

type ProceduresProps = {
    id: number,
    observation: string,
    description: string,
    value: string,
    accredited: AccreditedProps,
    categoryProcedure: CategoryExamProps
}

type ResponseProps = {
    meta: MetaProps,
    data: ProceduresProps[]
}

type AccrecreditedProps = {
  id: number;
  name: string
}

type CategoryProps = {
  id: number;
  title: string;
}

export default function ShowProcedures() {
  const navigate = useNavigate();
  const [procedures, setProcedures] = useState<ProceduresProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [accrediteds, setAccrediteds] = useState<AccrecreditedProps[]>([]);
  const [selectedAccredited, setSelectedAccredited] = useState<null | AccreditedProps>(null);
  const [selectedCategory, setSelectedCategory] = useState<null | CategoryProps>(null);
  const [categories, setCategories] = useState<CategoryProps[]>([]);
  const [procedureName, setProcedureName] = useState('');
  const [token] = useState({ headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` } });
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [actualPage, setActualPage] = useState(1);
  const [meta, setMeta] = useState<MetaProps>({
    total: 0,
    per_page: 0,
    current_page: 1,
    last_page: 1,
    first_page: 1,
  });

  async function fetchProcedures() {
    try {
      setLoading(true);
      const response = await api.post<ResponseProps>('/admin/medical-procedures-search', {
        page: actualPage,
        description: procedureName,
        category: selectedCategory ? selectedCategory.id : null,
        accredited: selectedAccredited ? selectedAccredited.id : null,
      }, token);
      console.log(response.data);
      setProcedures(response.data.data);
      setMeta(response.data.meta);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível carregar procedimentos', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
      setLoading(false);
    }
  }

  async function fetchPreload() {
    try {
      setLoading(true);
      const response = await api.get('/admin/find-medical-procedures-preload', token);
      setAccrediteds(response.data.accrediteds);
      setCategories(response.data.categories);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível carregar os credenciados e categorias.', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
    }
  }

  function generatePagination(totalOfPages: number) {
    const pagination: number[] = [];
    for (let i = 0; i < totalOfPages; i += 1) {
      pagination.push(i + 1);
    }
    return pagination;
  }

  function goToNextPage() {
    if (actualPage < meta.last_page) {
      setActualPage((prev) => prev + 1);
    }
  }

  function openInNewTab(dataId: number, evt: any) {
    evt.stopPropagation();
    if (evt.ctrlKey || evt.metaKey) {
      window.open(`https://painel.redesanta.com/procedimentos/editar/${dataId}`, '_blank');
    } else {
      navigate(`/procedimentos/editar/${dataId}`);
    }
  }

  useEffect(() => {
    fetchPreload();
    fetchProcedures();
  }, [actualPage]);

  return (
    <DefaultLayout
      pageTitle="Procedimentos"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Procedimentos</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <button className="table-btn-primary" type="button" onClick={() => navigate('/procedimentos/criar')}>
                <span className="material-icons-round">
                  add
                </span>
                adicionar
              </button>
              <div className="m-2 d-flex align-items-center">
                <div className="mt-1 installment-option extended-input" style={{ }}>
                  <BasicInput
                    id="Nome-exame"
                    label="Nome"
                    readOnly={false}
                    helper=""
                    error=""
                    value={procedureName}
                    onChange={(el) => setProcedureName(el)}
                    type="text"
                  />

                </div>
                <div className="mt-1 installment-option">
                  <BasicOption
                    id="Categoria"
                    label="Categoria"
                    readOnly={false}
                    helper=""
                    error=""
                    value={selectedCategory === null ? 'TODOS' : selectedCategory.title}
                    onChange={(el) => (el === 'TODOS' ? setSelectedCategory(null) : setSelectedCategory(categories.filter((item) => item.title === el)[0]))}
                  >
                    {['TODOS'].concat(categories.map((item) => item.title))}
                  </BasicOption>
                </div>
                <div className="mt-1 installment-option">
                  <BasicOption
                    id="Credenciado"
                    label="Credenciado"
                    readOnly={false}
                    helper=""
                    error=""
                    value={selectedAccredited === null ? 'TODOS' : selectedAccredited.name}
                    onChange={(el) => (el === 'TODOS' ? setSelectedAccredited(null) : setSelectedAccredited(accrediteds.filter((item) => item.name === el)[0]))}
                  >
                    {['TODOS'].concat(accrediteds.map((item) => item.name))}
                  </BasicOption>
                </div>
                <button className="table-btn-primary m-2" type="button" onClick={() => fetchProcedures()}>
                  <span className="material-icons-round">
                    search
                  </span>
                  filtrar
                </button>
              </div>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">Categoria</th>
                  <th scope="col">Credenciado</th>
                  <th scope="col">Valor</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {procedures.length > 0 ? procedures.map((item, i) => (
                  <tr>
                    <td>{item.description}</td>
                    <td>{item.categoryProcedure.title}</td>
                    <td><Link to={`/credenciados/editar/${item.accredited.id}`}>{item.accredited.name}</Link></td>
                    <td>{`R$ ${item.value}`}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <button type="button" onClick={(evt) => openInNewTab(item.id, evt)}>
                            <span className="material-icons-round">
                              more
                            </span>

                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : 'Nenhum procedimento encontrado'}
              </tbody>
            </table>
            <div className="table-footer">
              <button type="button" onClick={() => setActualPage(1)}>Primeira</button>
              <p>Página</p>
              <select style={{ minWidth: 300 }} value={meta.current_page} onChange={(e) => setActualPage(Number(e.target.value))}>
                {generatePagination(meta.last_page).map((item) => <option value={item}>{item}</option>)}
              </select>
              <p>
                de
                {' '}
                <strong>{meta.last_page}</strong>
              </p>
              <button
                type="button"
                onClick={() => goToNextPage()}
              >
                Próxima

              </button>
              <button
                type="button"
                onClick={() => setActualPage(meta.last_page)}
              >
                Última

              </button>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
