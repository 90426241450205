/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import * as EmailValidator from 'email-validator';
import { v4 as uuid } from 'uuid';
import download from 'downloadjs';
import { useParams } from 'react-router-dom';
import AddInput from '../../../../components/AddInput';
import BasicInput from '../../../../components/BasicInput';
import BasicOption from '../../../../components/BasicOption';
import rgEmittent from '../../../../dto/rgEmittent';
import { statesOfBrazil } from '../../../../dto/states';
import { validateCnpjOrCpf, validateCpf } from '../../../../helpers/validate';
import { ActualPage, DependentDTO, HolderDTO } from './EditLead';
import ButtonTextIcon, { BtnType } from '../../../../components/BtnIcon';
import {
  maskCep,
  maskCPF, maskDate, maskNumber, maskPhone, unmaskData,
} from '../../../../helpers/inputMasks.js';
import EditDependentModalModal from '../../PF/Edit/EditDependentr';
import HowManyDependentsModal from '../../PF/Edit/EditHowManyDependents';
import ErrorModal from '../../ErrorModal';
import CEPFinder from '../../../../helpers/findAddressByCep';
import api from '../../../../config/axios';
import TextAreaInput from '../../../../components/TextAreaInput';
import TermOptionModal from '../../../../components/TermOptionsModal';
import HolderProps from '../../../../dto/holder';

type PageProps = {
    pHolders: HolderDTO[]
    setPHolders: React.Dispatch<React.SetStateAction<HolderDTO[]>>
    setActualPage: React.Dispatch<React.SetStateAction<ActualPage>>
    setContractLifes: (holders: number, dep: number) => void
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default function EditHolderPJ({
  pHolders, setPHolders, setActualPage, setContractLifes, setLoading,
}: PageProps) {
  const { id } = useParams();
  const [holders, setHolders] = useState(pHolders);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [modalRef] = useState(React.createRef<any>());
  const [errorsRef] = useState(React.createRef<any>());
  const [errors, setErrors] = useState<string[]>([]);

  function addMoreHolder() {
    const holdersCtx = [...holders];
    holdersCtx.push({
      id: 0,
      uuidCtx: uuid(),
      cpf: '',
      isConverted: false,
      email: '',
      name: '',
      rg: '',
      emitent: 'SSP/MA',
      birthday: '',
      addressStreet: '',
      addressNumber: '',
      addressComplement: '',
      addressNeighborhood: '',
      addressCity: '',
      addressIbge: '',
      sex: 'm',
      addressState: 'MA',
      addressZipcode: '',
      phone: '',
      phone2: '',
      dependents: [],
    });
    setHolders(holdersCtx);
  }

  function changeName(idx: number, data: string) {
    holders[idx].name = data;
    setForceRefresh((prev) => !prev);
  }

  function changeCpf(idx: number, data: string) {
    holders[idx].cpf = data;
    setForceRefresh((prev) => !prev);
  }

  function changeRg(idx: number, data: string) {
    holders[idx].rg = data;
    setForceRefresh((prev) => !prev);
  }

  function changeEmitent(idx: number, data: string) {
    holders[idx].emitent = data;
    setForceRefresh((prev) => !prev);
  }

  function changeGender(idx: number, data: string) {
    holders[idx].sex = data === 'Masculo' ? 'm' : 'f';
    setForceRefresh((prev) => !prev);
  }

  function changeBirth(idx: number, data: string) {
    holders[idx].birthday = data;
    setForceRefresh((prev) => !prev);
  }

  function changeCEP(idx: number, data: string) {
    holders[idx].addressZipcode = data;
    setForceRefresh((prev) => !prev);
  }

  function changeStreet(idx: number, data: string) {
    holders[idx].addressStreet = data;
    setForceRefresh((prev) => !prev);
  }

  function changeNumber(idx: number, data: string) {
    holders[idx].addressNumber = data;
    setForceRefresh((prev) => !prev);
  }

  function changeComplement(idx: number, data: string) {
    holders[idx].addressComplement = data;
    setForceRefresh((prev) => !prev);
  }

  function changeNeighborhood(idx: number, data: string) {
    holders[idx].addressNeighborhood = data;
    setForceRefresh((prev) => !prev);
  }

  function changeCity(idx: number, data: string) {
    holders[idx].addressCity = data;
    setForceRefresh((prev) => !prev);
  }

  function changeState(idx: number, data: string) {
    holders[idx].addressState = data;
    setForceRefresh((prev) => !prev);
  }

  function changePhone(idx: number, data: string) {
    holders[idx].phone = data;
    setForceRefresh((prev) => !prev);
  }

  function changePhone2(idx: number, data: string) {
    holders[idx].phone2 = data;
    setForceRefresh((prev) => !prev);
  }

  function changeEmail(idx: number, data: string) {
    holders[idx].email = data;
    setForceRefresh((prev) => !prev);
  }

  function changeObservation(idx: number, data: string) {
    holders[idx].observation = data;
    setForceRefresh((prev) => !prev);
  }

  function removeDependents(holderIdx: number, dep: DependentDTO) {
    const newDependents = holders[holderIdx].dependents.filter((item) => item.uuidCtx !== dep.uuidCtx);
    holders[holderIdx].dependents = [...newDependents];
    setForceRefresh((prev) => !prev);
  }

  function removeHolder(holder: HolderDTO) {
    const newHolders = holders.filter((item) => item.uuidCtx !== holder.uuidCtx);
    setHolders([...newHolders]);
  }

  function changeDependent(holderIdx: number, dep: DependentDTO) {
    let idx = 0;
    holders[holderIdx].dependents.forEach((item, i) => {
      if (item.uuidCtx === dep.uuidCtx) {
        idx = i;
      }
    });
    holders[holderIdx].dependents[idx] = dep;
    setForceRefresh((prev) => !prev);
  }

  function addOneMoreDependent(holderIdx: number, dep: DependentDTO) {
    holders[holderIdx].dependents.push(dep);
    setForceRefresh((prev) => !prev);
  }

  async function changeAddressByCep(holderIdx: number, holder: HolderDTO) {
    try {
      setLoading(true);
      const findCep = await new CEPFinder(unmaskData(holder.addressZipcode)).findCep();
      if (findCep !== null) {
        holders[holderIdx].addressCity = findCep.city;
        holders[holderIdx].addressState = findCep.state;
        holders[holderIdx].addressNeighborhood = findCep.neighborhood;
        holders[holderIdx].addressStreet = findCep.street;
        holders[holderIdx].addressIbge = findCep.ibge;
      }
      setForceRefresh((prev) => !prev);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  async function generateContractPDF(holder: HolderDTO, model: 'Empresa paga para o titular e desconta em folha o valor do dependente'
  | 'Empresa desconta em folha os valores do titular e do dependente'
  | 'Empresa paga para o titular e dependente'
  | 'Empresa paga 50% para o titular') {
    try {
      setLoading(true);
      const response = await api.post('admin/leadpjpdfsingle', {
        leadId: id,
        holderId: holder.id,
        model,
      }, {
        headers:
         {
           Authorization: `bearer ${localStorage.getItem('adminToken')}`,
         },
        withCredentials: true,
        responseType: 'arraybuffer',
      });
      download(response.data, `ADESAO - ${holder.name}.pdf`);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
    }
  }

  function validate() {
    const errorsCtx: string[] = [];
    let totalOfHolders = 0;
    let totalOfDependents = 0;

    holders.forEach((item) => {
      if (item.name.length < 0) {
        errorsCtx.push('Todos titulares devem ter um nome');
      }
      if (!validateCpf(item.cpf)) {
        errorsCtx.push('CPF do titular inválido');
      }
      if (item.addressStreet.length < 1) {
        errorsCtx.push('O titular deve ter um logradouro');
      }
      if (item.addressNumber.length < 1) {
        errorsCtx.push('O titular deve ter um numero residencial');
      }
      if (item.addressNeighborhood.length < 1) {
        errorsCtx.push('Preencha o campo bairro do titular');
      }
      if (item.addressCity.length < 1) {
        errorsCtx.push('Preencha a cidade do titular');
      }
      if (item.birthday.length < 8) {
        errorsCtx.push('Digite o nascimento do titular no formato DD-MM-AAAA');
      }
      if (item.addressZipcode.length < 8) {
        errorsCtx.push('Preencha o cep do titular');
      }
      totalOfHolders += 1;
      totalOfDependents += item.dependents.length;
    });

    if (errorsCtx.length === 0) {
      setErrors([]);
      setContractLifes(totalOfHolders, totalOfDependents);
      setActualPage(ActualPage.CONTRACT);
    } else {
      setErrors(errorsCtx);
    }
  }

  useEffect(() => {
    setPHolders(holders);
  }, [holders]);

  useEffect(() => {
    setPHolders(holders);
  }, [forceRefresh]);

  useEffect(() => {
    if (errors.length > 0) {
      errorsRef.current.click();
    }
  }, [errors]);
  return (
    <>
      <>
        {holders.map((item, i) => (
          <>
            <div className="row products mt-4" key={item.uuidCtx}>
              <div className="col-12 card-bg p-4">
                <div className="row">
                  {i !== 0 && (
                  <div className="mb-4">
                    <ButtonTextIcon
                      label="Remover"
                      type={BtnType.DANGER}
                      icon="remove"
                      active
                      onClick={() => removeHolder(item)}
                      ref={null}
                    />
                  </div>
                  )}
                  <h1>Cadastro Aderente Titular</h1>
                  <div className="col-4">
                    <BasicInput
                      id="name-input"
                      label="Nome"
                      readOnly={false}
                      helper="Ex: João Batista"
                      error={item.name.length < 1 ? 'Campo Obrigatório' : ''}
                      value={item.name}
                      type="text"
                      onChange={(data) => changeName(i, data)}
                    />
                  </div>
                  <div className="col-4">
                    <BasicInput
                      id="cpf-input"
                      label="CPF"
                      readOnly={false}
                      helper="Ex: 000.000.000-10"
                      error={!validateCnpjOrCpf(item.cpf) ? 'Digite um CPF válido.' : ''}
                      value={maskCPF(item.cpf)}
                      type="text"
                      onChange={(data) => changeCpf(i, unmaskData(data))}
                    />
                  </div>
                  <div className="col-4">
                    <BasicInput
                      id="rg-input"
                      label="RG"
                      readOnly={false}
                      helper="Ex: 99999999"
                      error=""
                      value={item.rg}
                      type="text"
                      onChange={(data) => changeRg(i, maskNumber(data))}
                    />
                  </div>
                  <div className="col-4">
                    <BasicOption
                      id="rg-emittent-input"
                      label="RG / Emissor"
                      readOnly={false}
                      helper="Ex: SSP/MA"
                      error=""
                      value={item.emitent}
                      onChange={(data) => changeEmitent(i, data)}
                    >
                      {rgEmittent}
                    </BasicOption>
                  </div>
                  <div className="col-4">
                    <BasicOption
                      id="sex-input"
                      label="Sexo"
                      readOnly={false}
                      helper="Ex: Masculino / Feminino"
                      error=""
                      value={item.sex === 'm' ? 'Masculino' : 'Feminino'}
                      onChange={(data) => changeGender(i, data)}
                    >
                      {['Masculino', 'Feminino']}
                    </BasicOption>
                  </div>
                  <div className="col-4">
                    <BasicInput
                      id="birth-input"
                      label="DT. Nascimento"
                      readOnly={false}
                      helper="Ex: 13-01-1990"
                      error={item.birthday.length < 8 ? 'Campo obrigatório' : ''}
                      value={maskDate(item.birthday)}
                      type="text"
                      onChange={(data) => changeBirth(i, unmaskData(data))}
                    />
                  </div>
                  <div className="col-4">
                    <AddInput
                      id="address-cep-input"
                      label="CEP"
                      readOnly={false}
                      helper="Ex: 35.171-120"
                      error={item.addressZipcode.length < 8 ? 'Campo obrigatório' : ''}
                      value={maskCep(item.addressZipcode)}
                      type="text"
                      onAdd={() => changeAddressByCep(i, item)}
                      onChange={(data) => changeCEP(i, unmaskData(data))}
                    />
                  </div>
                  <div className="col-4">
                    <BasicInput
                      id="address-street-input"
                      label="Rua"
                      readOnly={false}
                      helper="Ex: Rua Três"
                      error={item.addressStreet.length < 2 ? 'Campo Obrigatório' : ''}
                      value={item.addressStreet}
                      type="text"
                      onChange={(data) => changeStreet(i, data)}
                    />
                  </div>
                  <div className="col-4">
                    <BasicInput
                      id="address-number-input"
                      label="Número"
                      readOnly={false}
                      helper="Ex: 133"
                      error={item.addressNumber.length < 1 ? 'Campo Obrigatório' : ''}
                      value={item.addressNumber}
                      type="text"
                      onChange={(data) => changeNumber(i, data)}
                    />
                  </div>
                  <div className="col-4">
                    <BasicInput
                      id="address-complement-input"
                      label="Complemento"
                      readOnly={false}
                      helper="Ex: Bloco A"
                      error=""
                      value={item.addressComplement}
                      type="text"
                                      //   onChange={(data) => setHolder({ ...holder, addressComplement: data })}
                      onChange={(data) => changeComplement(i, data)}
                    />
                  </div>
                  <div className="col-4">
                    <BasicInput
                      id="address-neighborhood-input"
                      label="Bairro"
                      readOnly={false}
                      helper="Centro"
                      error={item.addressNeighborhood.length < 1 ? 'Campo obrigatório' : ''}
                      value={item.addressNeighborhood}
                      type="text"
                      onChange={(data) => changeNeighborhood(i, data)}
                    />
                  </div>
                  <div className="col-4">
                    <BasicInput
                      id="address-city-input"
                      label="Cidade"
                      readOnly
                      helper="Ex: Imperatriz"
                      error={item.addressCity.length < 1 ? 'Campo obrigatório' : ''}
                      value={item.addressCity}
                      type="text"
                      onChange={(data) => changeCity(i, data)}
                    />
                  </div>
                  <div className="col-4">
                    <BasicOption
                      id="address-state-input"
                      label="Estado"
                      readOnly
                      helper=""
                      error=""
                      value={item.addressState}
                      onChange={(data) => changeState(i, data)}
                    >
                      {statesOfBrazil}
                    </BasicOption>
                  </div>
                  <div className="col-4">
                    <BasicInput
                      id="phone-input"
                      label="Telefone"
                      readOnly={false}
                      helper="Ex: (31) 99999 - 9999"
                      error=""
                      value={maskPhone(item.phone)}
                      type="text"
                      onChange={(data) => changePhone(i, unmaskData(data))}
                    />
                  </div>
                  <div className="col-4">
                    <BasicInput
                      id="phone2-input"
                      label="Telefone 2"
                      readOnly={false}
                      helper="Ex: (31) 99999 - 9999"
                      error=""
                      value={maskPhone(item.phone2)}
                      type="text"
                      onChange={(data) => changePhone2(i, unmaskData(data))}
                    />
                  </div>
                  <div className="col-4">
                    <BasicInput
                      id="email-input"
                      label="email"
                      readOnly={false}
                      helper="Ex: usuario@usuario.com"
                      error={item.email && item.email.length > 0 && !EmailValidator.validate(item.email) ? 'E-mail inválido' : ''}
                      value={item.email ? item.email : ''}
                      type="text"
                      onChange={(data) => changeEmail(i, data)}
                    />
                  </div>
                  <div className="col-12">
                    <TextAreaInput
                      id=""
                      label="Observação"
                      readOnly={false}
                      helper=""
                      error=""
                      value={item.observation || ''}
                      onChange={(data) => changeObservation(i, data)}
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <h5 className="fw-bold mb-2">
                      Dependentes:
                      {' '}
                      {item.dependents.length}
                    </h5>
                    <div className="d-flex">
                      {item.dependents.map((dep) => (
                        <>
                          <div className="btn btn-primary d-flex align-items-center justify-content-center mb-2" style={{ width: 'fit-content' }}>
                            {dep.name}
                            <div className="px-2" />
                            <button
                              type="button"
                              className="badge"
                              style={{ backgroundColor: 'white', border: 'none', color: '#6200EE' }}
                              data-bs-toggle="modal"
                              data-bs-target={`#EditDependent-${dep.uuidCtx}`}
                            >
                              Editar
                            </button>
                            <div className="px-1" />
                            <button
                              type="button"
                              className="badge"
                              style={{ backgroundColor: 'orange', border: 'none' }}
                              onClick={() => removeDependents(i, dep)}
                            >
                              Remover

                            </button>
                            <EditDependentModalModal
                              updateDependent={(arg) => changeDependent(i, arg)}
                              setLoading={(arg) => setLoading(arg)}
                              holder={{
                                ...item,
                                addressZipcode: item.addressZipcode.length > 0 ? maskCep(item.addressZipcode) : '',
                                phone: item.phone.length > 0 ? maskPhone(item.phone) : '',
                              }}
                              pDependent={{ ...dep }}
                              id={`EditDependent-${dep.uuidCtx}`}
                            />
                          </div>
                          <div className="px-2" />
                        </>
                      ))}
                    </div>

                  </div>
                  <div className="col-12 align-items-center justify-content-end d-flex">
                    {!item.isConverted && (
                    <>
                      <button
                        style={{ backgroundColor: '#6200EE', color: 'white' }}
                        type="button"
                        id="convert"
                        aria-describedby="adesao"
                        data-bs-toggle="modal"
                        data-bs-target={`#adesao${i}`}
                        className="btn d-flex align-items-center justify-content-center"
                      >
                        GERAR CONTRATO DE ADESAO
                        {' '}
                        <div className="px-1" />
                        <span className="material-icons-round text-white">description</span>
                      </button>
                      <div className="px-2" />
                      <TermOptionModal
                        fetchFunction={(arg) => generateContractPDF(item, arg)}
                        loading={false}
                        id={`adesao${i}`}
                      />
                    </>
                    )}
                    <button style={{ color: 'primary' }} type="button" className="btn btn-primary d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target={`#hiden-${item.uuidCtx}`}>
                      ADICIONAR DEPENDENTES
                      {' '}
                      <span className="material-icons-round text-white">add</span>
                    </button>
                    <HowManyDependentsModal
                      id={`hiden-${item.uuidCtx}`}
                      createDependent={(arg) => addOneMoreDependent(i, arg)}
                      setLoading={(arg) => setLoading(arg)}
                      holder={{
                        ...item,
                        addressZipcode: item.addressZipcode.length > 0 ? maskCep(item.addressZipcode) : '',
                        phone: item.phone.length > 0 ? maskPhone(item.phone) : '',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {i === holders.length - 1 && (
            <div className="row">
              <div className="col-12 d-flex justify-content-between">
                <ButtonTextIcon
                  label="Adicionar mais tiular"
                  type={BtnType.SECONDARY}
                  icon="add"
                  active={false}
                  onClick={() => addMoreHolder()}
                  ref={null}
                />
                <div className="d-flex">
                  <ButtonTextIcon
                    label="Voltar para cliente"
                    type={BtnType.SECONDARY}
                    icon="arrow_left"
                    active
                    onClick={() => setActualPage(ActualPage.CLIENT)}
                    ref={null}
                  />
                  <div className="px-2" />
                  <ButtonTextIcon
                    label="ir para contrato"
                    type={BtnType.PRIMARY}
                    icon="backup"
                    active
                    onClick={() => validate()}
                    ref={null}
                  />
                </div>

              </div>
            </div>
            )}
          </>
        ))}
      </>
      <ErrorModal errors={errors} />
      <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#errormodal" ref={errorsRef}>
        Launch static backdrop modal
      </button>
    </>
  );
}
