/* eslint-disable max-len */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicInput from '../../../../components/BasicInput';
import api from '../../../../config/axios';
import { AccreditedProps, CategoryProps } from '../FilterTable';
import ShowProfessionalModal from '../FilterProfessionals/ShowProfessionalModal';
import { showDoctorCompactName } from '../../../../helpers/showCompactName';
import BasicOption from '../../../../components/BasicOption';

export type ProfessionalProps = {
    accredited: AccreditedProps[],
    category: CategoryProps[],
    councilRegister?: string,
    id: number,
    name: string,
    phone?: string,
    whatsapp?: string,
}

export type Accredited = {
    id: 2,
    email: string,
    name: string,
    services: string,
    cpfCnpj: string,
    isActive: true,
    benefits: [],
    logo: {
      fileUrl: string,
    }
    professional: ProfessionalProps[]
}

export type AccreditedCategoriesProps = {
  title: string,
  id: number,
  accredited?: Accredited[]
}

export type ResponseProps = {
  data: AccreditedCategoriesProps[],
  meta: {
    total: number,
    showing: number,
  }
}

interface FilterProfessionalProps {
    setLoading: (arg0: boolean) => void;
}

export default function FilterAccredited({ setLoading }: FilterProfessionalProps) {
  const navigate = useNavigate();
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [categories, setCategories] = useState<AccreditedCategoriesProps[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<AccreditedCategoriesProps>({ title: 'TODOS', id: 10000000, accredited: [] });
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [data, setData] = useState<Accredited[]>([]);
  const [meta, setMeta] = useState({ total: 0, showing: 0 });

  function filterCategory(el: string) {
    const filteredCategory = categories.filter((item) => item.title === el);
    if (filteredCategory.length > 0) {
      setSelectedCategory(filteredCategory[0]);
    }
  }

  async function fetchAccreditedCategories() {
    try {
      setLoading(true);
      setLoadingCategories(true);
      const response = await api.get<AccreditedCategoriesProps[]>('admin/categories-ac', token);
      const orderedResponse = response.data.sort((a, b) => a.title.charCodeAt(0) - b.title.charCodeAt(0));
      setCategories([{ id: 230120302, title: 'TODOS' }].concat(orderedResponse));
      setLoading(false);
      setLoadingCategories(false);
    } catch (err: any) {
      setLoading(false);
      console.log(err.response.data);
    }
  }

  async function fetchDataByFilter() {
    try {
      setLoading(true);
      const response = await api.post<ResponseProps>('admin/accredited-list/accredited', {
        id: selectedCategory.title !== 'TODOS' ? selectedCategory.id : null,
      }, token);
      const newArray: Accredited[] = [];
      response.data.data.forEach((item) => {
        item.accredited!.forEach((item2) => {
          newArray.push(item2);
        });
      });
      setData(newArray.sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0)));
      setMeta(response.data.meta);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log(err.response.data);
    }
  }

  useEffect(() => {
    fetchAccreditedCategories();
  }, []);

  useEffect(() => {
    fetchDataByFilter();
  }, [selectedCategory]);
  return (

    <>
      <div className="row mb-2">
        <div className="col-12">
          <div className="d-flex justify-content-end">
            <div className="fetched-values-group">
              <div className="income-fetched-value-wrapper">
                <p className="" style={{ marginRight: 24 }}>
                  Exibindo:
                  {' '}
                  <span className="">{`${meta.showing} / ${meta.total}`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Credenciados</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <div className="m-2 d-flex align-items-center">
                <div className="mt-1 installment-option">
                  {loadingCategories ? (
                    <BasicInput
                      id="Categoria-loading"
                      label="Categoria"
                      readOnly
                      helper=""
                      error=""
                      value="Carregando..."
                      type=""
                      onChange={() => null}
                    />

                  ) : (
                    <BasicOption
                      id="Categoria"
                      label="Categoria"
                      readOnly={false}
                      helper=""
                      error=""
                      value={selectedCategory.title}
                      onChange={(el) => filterCategory(el)}
                    >
                      {categories.map((item) => item.title)}
                    </BasicOption>
                  )}

                </div>
              </div>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  {/* <th scope="col">Logo</th> */}
                  <th scope="col">Nome</th>
                  <th scope="col">Vínculo(s) profissionais</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {data.length === 0 && (
                <p>Nenhum resultado</p>
                )}
                {data.map((item, i) => (
                  <tr key={i}>
                    {/* <td>{item.logo.fileUrl ? (<img className="logo-partners-table" src={item.logo.fileUrl} alt="logo" />) : 'N/A' }</td> */}
                    <td>{item.name}</td>
                    <td>
                      {item.professional.length > 0 ? item.professional.map((x, y) => (
                        <>
                          <ShowProfessionalModal id={`show-professional-${x.id}`} data={x} />
                          <button type="button" className="btn-professional" data-bs-toggle="modal" data-bs-target={`#show-professional-${x.id}`}>
                            {showDoctorCompactName(x.name)}
                          </button>
                        </>
                      )) : 'Sem profissionais vinculados'}
                    </td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <button type="button" onClick={() => navigate(`/credenciados/editar/${item.id}`)}>
                            <span className="material-icons-round">
                              more
                            </span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  );
}
