/* eslint-disable default-param-last */

import { AnyAction } from 'redux';

/* eslint-disable no-shadow */
export interface AdminProps {
    id: number;
    name: string;
    email: string;
    cpf: string;
    role: string;
    rememberMeToken: null;
    access: 'limited' | 'admin' | 'reffers'
    isActive: boolean;
    createdAt: string;
    avatar: {
      fileUrl: null | string
    }
}

export enum adminActionEnum {
    SET_ADMIN = 'SET_ADMIN',
    RESET_ADMIN = 'RESET_ADMIN',
}

const INITIAL_STORE: AdminProps = {
  id: 0,
  email: '',
  role: '',
  name: '',
  access: 'limited',
  rememberMeToken: null,
  isActive: false,
  createdAt: '',
  cpf: '',
  avatar: {
    fileUrl: null,
  } || null,
};

export default function Store(state = INITIAL_STORE, action: AnyAction) {
  switch (action.type) {
    case adminActionEnum.SET_ADMIN:
      return action.payload;
    default: return state;
  }
}
