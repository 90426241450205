import React, { useEffect, useState } from 'react';
import DashboardCardOverlay from '../../../components/DashboardCardOverlay';
import api from '../../../config/axios';

type StatisticsProps = {
    totalUsers: string,
    totalHolders: string,
    totalDependents: string,
    totalClients: string,
    totalLifes: string,
    totalTitularPfBloqueadoTemporariamente: string,
    totalDependentesPfBloqueadoTemporariamente: string,
    totalVidasPfBloqueadoTemporariamente: string,
    totalTitularPfBloqueadoPermanentemente: string,
    totalDependentesPfBloqueadoPermanentemente: string,
    totalVidasPfBloqueadoPermanentemente: string,
    totalTitularPjBloqueadoTemporariamente: string,
    totalDependentesPjBloqueadoTemporariamente: string,
    totalVidasPjBloqueadoTemporariamente: string,
    totalTitularPjBloqueadoPermanentemente: string,
    totalDependentesPjBloqueadoPermanentemente: string,
    totalVidasPjBloqueadoPermanentemente: string,
    totalDeVidasBloqueadasTemporariamente: string,
    totalDeVidasBloqueadasPermanentemente: string
}

export default function Statistics() {
  const [statistics, setStatistics] = useState<StatisticsProps>({
    totalUsers: '0',
    totalHolders: '0',
    totalDependents: '0',
    totalClients: '0',
    totalLifes: '0',
    totalTitularPfBloqueadoTemporariamente: '0',
    totalDependentesPfBloqueadoTemporariamente: '0',
    totalVidasPfBloqueadoTemporariamente: '0',
    totalTitularPfBloqueadoPermanentemente: '0',
    totalDependentesPfBloqueadoPermanentemente: '0',
    totalVidasPfBloqueadoPermanentemente: '0',
    totalTitularPjBloqueadoTemporariamente: '0',
    totalDependentesPjBloqueadoTemporariamente: '0',
    totalVidasPjBloqueadoTemporariamente: '0',
    totalTitularPjBloqueadoPermanentemente: '0',
    totalDependentesPjBloqueadoPermanentemente: '0',
    totalVidasPjBloqueadoPermanentemente: '0',
    totalDeVidasBloqueadasTemporariamente: '0',
    totalDeVidasBloqueadasPermanentemente: '0',
  });
  const [loading, setLoading] = useState(false);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function fetchStatistics() {
    try {
      setLoading(true);
      const response = await api.get('/admin/totals', token);
      console.log(response.data);
      setStatistics(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchStatistics();
  }, []);

  return (
    <DashboardCardOverlay loading={loading}>
      <div className="card-bg p-4 h-100" style={{ minHeight: 200 }}>
        <h4 className="mb-4">Estatísticas</h4>
        <div className="row mt-4 d-flex align-items-center h-75">
          <div className="col-2 statistics">
            <div className="item-wrapper">
              <div className="item-icon">
                <span className="material-icons-round" style={{ background: '#EEEDFD', color: '#6359CF' }}>
                  trending_up
                </span>
              </div>
              <div className="item-text">
                <p>{statistics.totalClients}</p>
                <span>Clientes PJ ativos</span>
              </div>
            </div>
          </div>
          <div className="col-2 statistics">
            <div className="item-wrapper">
              <div className="item-icon">
                <span className="material-icons-round" style={{ background: '#edfdef', color: '#59cfa4' }}>
                  person_add
                </span>
              </div>
              <div className="item-text">
                <p>{statistics.totalLifes}</p>
                <span>Associados ativos</span>
              </div>
            </div>
          </div>
          <div className="col-2 statistics">
            <div className="item-wrapper">
              <div className="item-icon">
                <span className="material-icons-round" style={{ background: '#fdfced', color: '#c8b854' }}>
                  person
                </span>
              </div>
              <div className="item-text">
                <p>{statistics.totalHolders}</p>
                <span>Titulares ativos</span>
              </div>
            </div>
          </div>
          <div className="col-2 statistics">
            <div className="item-wrapper">
              <div className="item-icon">
                <span className="material-icons-round" style={{ background: '#fdeeed', color: '#c85a54' }}>
                  people
                </span>
              </div>
              <div className="item-text">
                <p>{statistics.totalDependents}</p>
                <span>Dependentes ativos</span>
              </div>
            </div>
          </div>
          <div className="col-2 statistics mt-2">
            <div className="item-wrapper">
              <div className="item-icon">
                <span className="material-icons-round" style={{ background: '#fdeeed', color: 'orange' }}>
                  cancel
                </span>
              </div>
              <div className="item-text">
                <p>{statistics.totalDeVidasBloqueadasTemporariamente}</p>
                <span>Associados Bloq. Temporariamente</span>
              </div>
            </div>
          </div>
          <div className="col-2 statistics mt-2">
            <div className="item-wrapper">
              <div className="item-icon">
                <span className="material-icons-round" style={{ background: '#fdeeed', color: '#c85a54' }}>
                  cancel
                </span>
              </div>
              <div className="item-text">
                <p>{statistics.totalDeVidasBloqueadasPermanentemente}</p>
                <span>Associados cancelados</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </DashboardCardOverlay>
  );
}
