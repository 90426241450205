/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as EmailValidator from 'email-validator';
import { useNavigate, useParams } from 'react-router-dom';
import AddInput from '../../components/AddInput';
import BasicInput from '../../components/BasicInput';
import BasicOption from '../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import Card, { ICard } from '../../components/Card/Card';
import { ModalActionType, ModalProps } from '../../components/Modal';
import api from '../../config/axios';
import rgEmittent from '../../dto/rgEmittent';
import { statesOfBrazil } from '../../dto/states';
import CEPFinder from '../../helpers/findAddressByCep';
import {
  maskCep, maskCPF, maskCPForCNPJ, maskDate, maskNumber, maskPhone, maskRegistration, unmaskData,
} from '../../helpers/inputMasks.js';
import showApiErrors from '../../helpers/showApiErrors';
import { validateCnpjOrCpf, validateCpf } from '../../helpers/validate';
import DefaultLayout from '../../Layout';
import { storeType } from '../../store';
import { BlockHistoryHolderOrDependentProps } from '../../dto/holder';
import TextAreaInput from '../../components/TextAreaInput';
import UpdateUserModal from '../../components/UpdateUserModal';

const blockHistory: BlockHistoryHolderOrDependentProps[] = [];
export default function EditDependentPF() {
  const navigate = useNavigate();
  const admin = useSelector(({ adminReducer }: storeType) => adminReducer);
  const { id } = useParams();
  const [appData, setAppData] = useState<{
    cpf: string,
    email: string
  }>();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [dependent, setDependent] = useState({
    cpf: '',
    name: '',
    cardname: '',
    sex: 'm',
    birthday: '',
    email: '',
    instagram: '',
    rg: '',
    registration: '',
    emitent: '',
    phone2: '',
    showCpfOnCard: true,
    addressStreet: '',
    addressNumber: '',
    addressIbge: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    addressState: '',
    addressZipcode: '',
    phone: '',
    enableTelemedicine: false,
    role: '',
    blockHistory,
    observation: '',
    printedCards: [],
  });

  const [cardData, setCardData] = useState<ICard>();
  const [isCardFront, setCardFront] = useState(false);

  async function changeAddressByCep() {
    try {
      setLoading(true);
      const findCep = await new CEPFinder(unmaskData(dependent.addressZipcode)).findCep();
      if (findCep !== null) {
        setDependent({
          ...dependent,
          addressCity: findCep.city,
          addressState: findCep.state,
          addressNeighborhood: findCep.neighborhood,
          addressStreet: findCep.street,
          addressIbge: findCep.ibge,
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  function enabledButton() {
    if (dependent.name.length > 0
        && validateCpf(dependent.cpf)
        && dependent.addressStreet.length > 0
        && dependent.addressNumber.length > 0
        && dependent.addressNeighborhood.length > 0
        && dependent.addressCity.length > 0
        && dependent.addressCity.length > 0
        && dependent.addressZipcode.length >= 10
        && dependent.phone.length > 2
    ) {
      return true;
    }
    return false;
  }

  function resolveRole() {
    if (dependent.role === 'Cônjuge') {
      return 'spouse';
    }
    if (dependent.role === 'Filho(a)') {
      return 'son';
    }
    return 'other';
  }

  async function fetchCard(cpf: string): Promise<string> {
    try {
      setLoading(true);
      const response = await api.post('/admin/card-generator/', { cpf }, token);
      setCardData(response.data.card);
      setLoading(false);
      return response.data.card.registration;
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
      return '';
    }
  }

  async function fetchDependent() {
    try {
      setLoading(true);
      const response = await api.get(`/admin/dependent/${id}`, token);
      console.log(response.data);
      const registration = await fetchCard(response.data.cpf);
      const defineRole = () => {
        if (response.data.role === 'spouse') {
          return 'Cônjuge';
        }
        if (response.data.role === 'son') {
          return 'Filho(a)';
        }
        return 'Outro';
      };
      setDependent({
        ...response.data,
        role: defineRole(),
        registration: maskRegistration(registration),
        cpf: maskCPF(response.data.cpf),
        phone: maskPhone(response.data.phone),
        phone2: response.data.phone2 ? maskPhone(response.data.phone2) : '',
        observation: response.data.observation || '',
        addressZipcode: maskCep(response.data.addressZipcode),
      });
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro ao buscar dependente', children: 'Não foi possível buscar dados de um dependente. Verifique a URL e conectividade. Caso o problema persista, favor entrar em contato com suporte.', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
    }
  }

  async function SaveDependent() {
    try {
      setLoading(true);
      await api.put(`admin/dependent/${id}`, {
        ...dependent,
        role: resolveRole(),
        cpf: unmaskData(dependent.cpf.trim()),
        cardname: dependent.cardname || null,
        email: dependent.email ? dependent.email : null,
        instagram: dependent.instagram ? dependent.instagram : null,
        registration: unmaskData(dependent.registration),
        phone2: dependent.phone2.length > 0 ? dependent.phone2 : null,
        observation: dependent.observation.length > 0 ? dependent.observation : null,
      }, token);
      setLoading(false);
      await fetchDependent();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      if (typeof err.response !== 'undefined') {
        if (err.response.status === 422) {
          setModal(showApiErrors(setModal, err.response.data.errors));
        }
      } else {
        setModal({ title: 'Ocorreu um erro ao atualizar', children: 'Não foi possível atualizar um dependente. Caso o problema persista, favor entrar em contato com suporte.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }
      console.log(err.response);
      setLoading(false);
    }
  }

  function resolveBlockStyle(block: BlockHistoryHolderOrDependentProps) {
    if (block.type === 'perma' && block.input === 'in') {
      return 'td-block-perma';
    }
    if (block.type === 'perma' && block.input === 'out') {
      return 'td-unblock';
    }
    if (block.input === 'out') {
      return 'td-unblock';
    }
    return 'td-block-temp';
  }

  async function fetchCpf() {
    try {
      setLoading(true);
      const response = await api.post('admin/manage/user', {
        cpf: unmaskData(dependent.cpf),
      }, token);
      setAppData({ ...response.data });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchDependent();
  }, []);

  useEffect(() => {
    fetchCpf();
  }, [dependent.cpf]);

  return (
    <DefaultLayout
      pageTitle={`${dependent.name} > Editar`}
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row holders card-bg p-0">
        <section className="row">
          <h1>Dados dependente</h1>
          {typeof cardData !== 'undefined' && (
            <div className="row mb-4 card-wrapper">
              <Card
                id="usercard"
                uuid={id as string}
                registration={cardData.registration}
                name={cardData.name}
                cardname={cardData.cardname}
                cpf={cardData.cpf}
                expiration={cardData.expiration}
                birth={cardData.birth}
                modality={cardData.modality}
                category={cardData.category}
                front={isCardFront}
                hideCpfOnCard={!dependent.showCpfOnCard}
                setCardSide={() => setCardFront((prev) => !prev)}
                isHolder={false}
                printedCards={dependent.printedCards}
              />
            </div>
          )}
          <div className="col-4">
            <BasicOption
              id="name-input"
              label="Relação com titular"
              readOnly={false}
              helper="Ex: Filho(a)"
              error=""
              value={dependent.role}
              onChange={(data) => setDependent({ ...dependent, role: data })}
            >
              {['Cônjuge', 'Filho(a)', 'Outro']}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicInput
              id="name-input"
              label="Nome"
              readOnly={false}
              helper="Ex: João Batista"
              error={dependent.name.length < 1 ? 'Campo Obrigatório' : ''}
              value={dependent.name}
              type="text"
              onChange={(data) => setDependent({ ...dependent, name: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="card-name-input"
              label="Nome no cartão"
              readOnly={false}
              helper="Ex: João B. C. Vieira"
              error=""
              value={dependent.cardname === null ? '' : dependent.cardname}
              type="text"
              onChange={(data) => setDependent({ ...dependent, cardname: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="card-register-input"
              label="Registro"
              readOnly={admin.id !== 2 && admin.id !== 3 && admin.id !== 4 && admin.id !== 18 && admin.id !== 9}
              helper=""
              error=""
              value={dependent.registration}
              type="text"
              onChange={(data) => setDependent({ ...dependent, registration: maskRegistration(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="cpf-input"
              label="CPF"
              readOnly={false}
              helper="Ex: 000.000.000-10"
              error={!validateCnpjOrCpf(dependent.cpf) ? 'Digite um CPF válido.' : ''}
              value={dependent.cpf}
              type="text"
              onChange={(data) => setDependent({ ...dependent, cpf: maskCPF(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="rg-input"
              label="RG"
              readOnly={false}
              helper="Ex: 99999999"
              error=""
              value={dependent.rg}
              type="text"
              onChange={(data) => setDependent({ ...dependent, rg: maskNumber(data) })}
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="rg-emittent-input"
              label="RG / Emissor"
              readOnly={false}
              helper="Ex: SSP/MA"
              error=""
              value={dependent.emitent}
              onChange={(data) => setDependent({ ...dependent, emitent: data })}
            >
              {rgEmittent}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicOption
              id="sex-input"
              label="Sexo"
              readOnly={false}
              helper="Ex: Masculino / Feminino"
              error=""
              value={dependent.sex === 'm' ? 'Masculino' : 'Feminino'}
              onChange={(data) => setDependent({ ...dependent, sex: data === 'Masculino' ? 'm' : 'f' })}
            >
              {['Masculino', 'Feminino']}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicInput
              id="birth-input"
              label="DT. Nascimento"
              readOnly={false}
              helper="Ex: 13-01-1990"
              error={dependent.birthday.length < 1 ? 'Campo obrigatório' : ''}
              value={dependent.birthday}
              type="text"
              onChange={(data) => setDependent({ ...dependent, birthday: maskDate(data) })}
            />
          </div>
          <div className="col-4">
            <AddInput
              id="address-cep-input"
              label="CEP"
              readOnly={false}
              helper="Ex: 35.171-120"
              error={dependent.addressZipcode.length < 10 ? 'Campo obrigatório' : ''}
              value={dependent.addressZipcode}
              type="text"
              onAdd={() => changeAddressByCep()}
              onChange={(data) => setDependent({ ...dependent, addressZipcode: maskCep(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-street-input"
              label="Rua"
              readOnly={false}
              helper="Ex: Rua Três"
              error={dependent.addressStreet.length < 2 ? 'Campo Obrigatório' : ''}
              value={dependent.addressStreet}
              type="text"
              onChange={(data) => setDependent({ ...dependent, addressStreet: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-number-input"
              label="Número"
              readOnly={false}
              helper="Ex: 133"
              error={dependent.addressNumber.length < 1 ? 'Campo Obrigatório' : ''}
              value={dependent.addressNumber}
              type="text"
              onChange={(data) => setDependent({ ...dependent, addressNumber: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-complement-input"
              label="Complemento"
              readOnly={false}
              helper="Ex: Bloco A"
              error=""
              value={dependent.addressComplement}
              type="text"
              onChange={(data) => setDependent({ ...dependent, addressComplement: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-neighborhood-input"
              label="Bairro"
              readOnly={false}
              helper="Centro"
              error={dependent.addressNeighborhood.length < 1 ? 'Campo obrigatório' : ''}
              value={dependent.addressNeighborhood}
              type="text"
              onChange={(data) => setDependent({ ...dependent, addressNeighborhood: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-city-input"
              label="Cidade"
              readOnly
              helper="Ex: Imperatriz"
              error={dependent.addressCity.length < 1 ? 'Campo obrigatório' : ''}
              value={dependent.addressCity}
              type="text"
              onChange={(data) => setDependent({ ...dependent, addressCity: data })}
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="address-state-input"
              label="Estado"
              readOnly
              helper=""
              error=""
              value={dependent.addressState}
              onChange={(data) => setDependent({ ...dependent, addressState: data })}
            >
              {statesOfBrazil}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicInput
              id="address-cep-input"
              label="CEP"
              readOnly={false}
              helper="Ex: 35.171-120"
              error={dependent.addressZipcode.length < 10 ? 'Campo obrigatório' : ''}
              value={dependent.addressZipcode}
              type="text"
              onChange={(data) => setDependent({ ...dependent, addressZipcode: maskCep(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="phone-input"
              label="Telefone"
              readOnly={false}
              helper="Ex: (31) 99999 - 9999"
              error={dependent.phone.length < 1 ? 'Campo obrigatório' : ''}
              value={dependent.phone}
              type="text"
              onChange={(data) => setDependent({ ...dependent, phone: maskPhone(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="phone2-input"
              label="Telefone 2"
              readOnly={false}
              helper="Ex: (31) 99999 - 9999"
              error=""
              value={dependent.phone2 || ''}
              type="text"
              onChange={(data) => setDependent({ ...dependent, phone2: maskPhone(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="email-input"
              label="email"
              readOnly={false}
              helper="Ex: usuario@usuario.com"
              error={dependent.email && dependent.email.length > 0 && !EmailValidator.validate(dependent.email) ? 'E-mail inválido' : ''}
              value={dependent.email ? dependent.email : ''}
              type="text"
              onChange={(data) => setDependent({ ...dependent, email: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="instagram-input"
              label="instagram"
              readOnly={false}
              helper="Ex: valdecir_roberto"
              error=""
              value={dependent.instagram ? dependent.instagram : ''}
              type="text"
              onChange={(data) => setDependent({ ...dependent, instagram: data })}
            />
          </div>
          <div className="col-12">
            <TextAreaInput
              id=""
              label="Observação"
              readOnly={false}
              helper=""
              error=""
              value={dependent.observation || ''}
              onChange={(data) => setDependent({ ...dependent, observation: data })}
            />
          </div>
          <div className="col-12 optionals mt-4 mb-4">
            <p>Opcionais</p>
            <div className="check-wrapper">
              <input type="checkbox" checked={!dependent.showCpfOnCard} onChange={() => setDependent({ ...dependent, showCpfOnCard: !dependent.showCpfOnCard })} />
              <p>Não mostrar CPF no cartão</p>
            </div>
            <div className="check-wrapper">
              <input type="checkbox" checked={dependent.enableTelemedicine} onChange={() => setDependent({ ...dependent, enableTelemedicine: !dependent.enableTelemedicine })} />
              <p>Telemedicina</p>
            </div>
          </div>
          <div className="col-12 footer">
            <ButtonTextIcon
              label="salvar"
              type={BtnType.PRIMARY}
              icon="backup"
              active={enabledButton()}
              onClick={() => SaveDependent()}
              ref={null}
            />
          </div>
        </section>
      </div>

      <div className="row products mt-4">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Histórico de bloqueios / desbloqueios</h1>
          <div className="table-wrapper">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Bloqueio / Cancelamento</th>
                  <th scope="col">Data</th>
                  <th scope="col">Motivo</th>
                  <th scope="col">Responsável</th>
                </tr>
              </thead>
              <tbody>
                {dependent.blockHistory.length > 0 ? dependent.blockHistory.map((item, i) => (
                  <tr key={i}>
                    <td className={resolveBlockStyle(item)}>{(item.input === 'in' ? 'Bloqueio' : 'Desbloqueio')}</td>
                    <td className={resolveBlockStyle(item)}>{item.blockDate}</td>
                    <td className={resolveBlockStyle(item)}>{item.reason}</td>
                    <td className={resolveBlockStyle(item)}>{item.blockedBy}</td>
                  </tr>
                )) : (
                  <p>Não existe(m) bloqueio(s) para esse dependente.</p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row products mt-4">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Alterar acesso ao APP</h1>
          <div className="table-wrapper">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">CPF</th>
                  <th scope="col">Email</th>
                  <th scope="col" className="text-end" align="right">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {appData ? (
                    <>
                      <td>{maskCPF(appData.cpf)}</td>
                      <td>{appData.email}</td>
                      <td align="right">
                        <div className="d-flex justify-content-end align-items-center">
                          <div className="table-action-icon">
                            <button type="button" data-bs-toggle="modal" data-bs-target="#updateModal">
                              <span className="material-icons-round">
                                more
                              </span>
                            </button>
                          </div>
                        </div>
                      </td>

                    </>
                  ) : (
                    <td>Cliente ainda nao realizou o cadastro no website!</td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {appData && (
      <UpdateUserModal emailProps={appData.email} cpf={maskCPF(appData.cpf)} />
      )}
    </DefaultLayout>

  );
}
