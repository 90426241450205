/* eslint-disable max-len */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import api from '../../../config/axios';
import { ProductsProps } from '../../../dto/product';
import { maskDate, unmaskData } from '../../../helpers/inputMasks.js';
import { normalizeToApiCardPaymentWays } from '../../../helpers/showCardMethods';
import DefaultLayout from '../../../Layout';
import { PagVipRefProps } from '../../Contracts/PF/EditPaymentPFV2';
import CreateClientPJ from './CreateClient';
import CreateContract from './CreateContract';
import CreateHolder from './CreateHolder';
import NewPaymentPJLead from './CreatePayment';

export enum ActualPage {
    CLIENT,
    HOLDER,
    DEPENDENTS,
    CONTRACT,
    PAYMENT,
}

export type InstallmentDTO = {
    paymentGatewayId: number,
    dueDate: string,
    status: string,
    paymentDate: string,
    value: number,
    taxUsed: number,
    pagvipRef: null | PagVipRefProps,
    isSubscription: boolean,
    subscriptionNumber: null | number,
    subscriptionRef: null | string,
    nonCardTaxes: string | null,
    card: boolean,
    isMain: boolean,
    flag: null | string,
    cardLastDigits: null | string,
    cardPaymentOption: null | string
  }

export type PaymentDTO = {
    contractPfId: number;
    contractValue: number;
    discount: number;
    aditional: number;
    installments: number;
    totalValue: number;
    approvalDate: string;
  }

export type HolderDTO = {
    uuidCtx: string
    name: string,
    sex: 'm' | 'f',
    cpf: string,
    birthday: string,
    addressIbge: null | string,
    rg: string,
    emitent: string,
    addressStreet: string,
    addressNumber: string,
    addressComplement: string,
    addressNeighborhood: string,
    addressCity: string,
    addressState: string,
    addressZipcode: string,
    phone: string,
    phone2: string,
    email: string,
    observation?: null | string,
    dependents: DependentDTO[]
  }

export type DependentDTO = {
    uuidCtx: string,
    name: string,
    sex: 'm' | 'f',
    cpf: string,
    birthday: string,
    addressIbge: string | null
    rg: string,
    role: 'son' | 'spouse' | 'other',
    emitent: string,
    addressStreet: string,
    addressNumber: string,
    addressComplement: string,
    addressNeighborhood: string,
    addressCity: string,
    addressState: string,
    addressZipcode: string,
    phone: string,
    phone2: string,
    email: string,
    observation?: null | string
  }

export type ContractDTO = {
    productId: number,
    value: number,
    totalDependentsEnabled: number,
    totalHoldersEnabled: number,
    signatureDate: string,
    dueDate: string
  }

export type ClientDTO = {
    cnpj: string,
    openingDate: string,
    name: string,
    nameFantasy: string,
    size: string,
    addressStreet: string,
    addressNumber: string,
    addressComplement: string,
    addressIbge: string | null,
    addressNeighborhood: string,
    addressCity: string,
    addressState: string,
    addressZipcode: string,
    email: string,
    phone: string,
    password: string,
    isActive: boolean,
}

export default function CreateLeadPJ() {
  const navigate = useNavigate();
  const [actualPage, setActualPage] = useState(ActualPage.CLIENT);
  const [loading, setLoading] = useState(false);
  const [haveInstallments, setHaveInstallments] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [holders, setHolders] = useState<HolderDTO[]>([{
    uuidCtx: uuid(),
    cpf: '',
    email: '',
    name: '',
    rg: '',
    emitent: 'SSP/MA',
    birthday: '',
    addressStreet: '',
    addressIbge: '',
    addressNumber: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    sex: 'm',
    addressState: 'MA',
    addressZipcode: '',
    phone: '',
    phone2: '',
    dependents: [],
  }]);
  const [contract, setContract] = useState<ContractDTO>({
    productId: 0,
    value: 0,
    totalDependentsEnabled: 0,
    totalHoldersEnabled: 0,
    signatureDate: '',
    dueDate: '',
  });
  const [payment, setPayment] = useState<PaymentDTO>({
    contractPfId: 0,
    contractValue: 0,
    discount: 0,
    aditional: 0,
    installments: 0,
    totalValue: 0,
    approvalDate: '',
  });
  const [installments, setInstallments] = useState<InstallmentDTO[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [modalErrorRef] = useState(React.createRef<any>());
  const [selectedProduct, setSelectedProduct] = useState<ProductsProps>({
    id: 0,
    name: '',
    benefits: '',
    pricePerHolder: 0,
    pricePerDependent: 0,
    isActive: true,
  });
  const [products, setProducts] = useState<ProductsProps[]>([]);
  const [client, setClient] = useState<ClientDTO>({
    cnpj: '',
    openingDate: '',
    name: '',
    nameFantasy: '',
    size: 'ME',
    addressStreet: '',
    addressNumber: '',
    addressIbge: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    addressState: 'MA',
    addressZipcode: '',
    email: '',
    phone: '',
    password: '',
    isActive: true,
  });
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  async function fetchAllProducts() {
    try {
      setLoading(true);
      const response = await api.get('admin/product', token);
      setProducts(response.data);
      if (contract.productId === 0) {
        setSelectedProduct(response.data[0]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  async function sendLead() {
    try {
      setLoading(true);
      await api.post('admin/lead-pj', {
        client: {
          ...client,
          cnpj: client.cnpj.length > 0 ? unmaskData(client.cnpj) : client.cnpj,
          phone: client.phone.length > 0 ? unmaskData(client.phone) : client.phone,
          addressZipcode: client.addressZipcode.length > 0 ? unmaskData(client.addressZipcode) : client.addressZipcode,
        },
        holders: [...holders.map((item) => ({
          ...item,
          cpf: item.cpf.length > 0 ? unmaskData(item.cpf) : item.cpf,
          phone: item.phone.length > 0 ? unmaskData(item.phone) : item.phone,
          phone2: item.phone2.length > 0 ? unmaskData(item.phone2) : item.phone2,
          addressZipcode: item.addressZipcode.length > 0 ? unmaskData(item.addressZipcode) : item.addressZipcode,
          birthday: maskDate(item.birthday),
          dependents: [...item.dependents.map((dep) => ({
            ...dep,
            cpf: dep.cpf.length > 0 ? unmaskData(dep.cpf) : dep.cpf,
            phone: dep.phone.length > 0 ? unmaskData(dep.phone) : dep.phone,
            phone2: dep.phone2.length > 0 ? unmaskData(dep.phone2) : dep.phone2,
            addressZipcode: dep.addressZipcode.length > 0 ? unmaskData(dep.addressZipcode) : dep.addressZipcode,
            birthday: maskDate(dep.birthday),
          }))],
        }))],
        lead: { ...contract, pricePerHolder: selectedProduct.pricePerHolder, pricePerDependent: selectedProduct.pricePerDependent },
        installments: [...installments.map((item) => ({ ...item, cardPaymentOption: normalizeToApiCardPaymentWays(item.cardPaymentOption || '') }))],
      }, token);
      setLoading(false);
      setErrors([]);
      navigate('/cliente/pj/lead');
    } catch (err: any) {
      const errorCtx: string[] = [];
      console.log(err.response.data);
      if (err.response && err.response.status === 422) {
        err.response.data.errors.forEach((e: any) => {
          errorCtx.push(e.message);
        });
        // setErrors(errorCtx);
      } else {
        errorCtx.push('Ocorreu um erro interno e não foi possível processar o cadastro do lead.');
      }
      setModal({
        title: 'Ocorreu um erro',
        children: [
          <div className="d-flex flex-column">
            {errorCtx.map((item) => (
              <p className="w-100 text-center">
                -
                {' '}
                {item}
              </p>
            ))}
          </div>,
        ],
        actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }],
      });
      setLoading(false);
    }
  }

  function changePricePerHolder(price: number) {
    const globalContract = (contract.totalHoldersEnabled * price) + (contract.totalDependentsEnabled * selectedProduct.pricePerDependent);
    setContract({ ...contract, value: Number((globalContract * 12).toFixed(2)) });
    setSelectedProduct({ ...selectedProduct, pricePerHolder: price });
  }

  function changePricePerDependent(price: number) {
    const globalContract = (contract.totalDependentsEnabled * price) + (contract.totalHoldersEnabled * selectedProduct.pricePerHolder);
    setContract({ ...contract, value: Number((globalContract * 12).toFixed(2)) });
    setSelectedProduct({ ...selectedProduct, pricePerDependent: price });
  }

  function changeGlobalValue() {
    const globalContract = (contract.totalDependentsEnabled * selectedProduct.pricePerDependent) + (selectedProduct.pricePerHolder * contract.totalHoldersEnabled);
    setContract({ ...contract, value: Number((globalContract * 12).toFixed(2)), productId: selectedProduct.id });
  }

  useEffect(() => {
    fetchAllProducts();
  }, []);

  useEffect(() => {
    changeGlobalValue();
  }, [selectedProduct]);

  return (
    <DefaultLayout
      pageTitle="Adesão"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      {actualPage === ActualPage.HOLDER && (
      <CreateHolder
        pHolders={holders}
        setLoading={setLoading}
        setPHolders={setHolders}
        setActualPage={setActualPage}
        setContractLifes={(holders, dep) => setContract({ ...contract, totalDependentsEnabled: dep, totalHoldersEnabled: holders })}
      />
      )}
      {actualPage === ActualPage.CLIENT && (
      <CreateClientPJ
        client={client}
        setClient={setClient}
        setActualPage={setActualPage}
        setLoading={setLoading}
      />
      )}
      {actualPage === ActualPage.CONTRACT && (
      <CreateContract
        lifesAmount={0}
        contract={contract}
        setContract={setContract}
        setLoading={setLoading}
        setActualPage={setActualPage}
        setModal={setModal}
        products={products}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        changePricePerHolder={(arg) => changePricePerHolder(arg)}
        changePricePerDependent={(arg) => changePricePerDependent(arg)}
      />
      )}
      {actualPage === ActualPage.PAYMENT && (
      <NewPaymentPJLead
        setModal={(arg) => setModal(arg)}
        setActualPage={(arg) => setActualPage(arg)}
        payment={payment}
        sendLead={() => sendLead()}
        haveInstallments={haveInstallments}
        setHaveInstallments={(arg) => setHaveInstallments(arg)}
        setPayment={(data) => setPayment(data)}
        pInstallments={installments}
        setInstallments={(data) => setInstallments(data)}
        contractProps={contract}
      />
      )}
    </DefaultLayout>
  );
}
