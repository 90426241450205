import React, { createRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import api from '../../../config/axios';
import dataURIToBlob from '../../../helpers/fileReader';
import DefaultLayout from '../../../Layout';
import placeholder from '../../../assets/placeholder.png';
import { maskPhone } from '../../../helpers/inputMasks.js';

type Professional = {
    name: string;
    councilRegister: string;
    sex: string;
    show: boolean;
    isActive: boolean;
    phone: string;
    whatsapp: string;
}

export default function NewProfessional() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [file, setFile] = useState<string | null | ArrayBuffer>(null);
  const [fileRef] = useState(createRef<any>());
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [professional, setProfessional] = useState<Professional>({
    name: '',
    councilRegister: '',
    sex: 'f',
    show: true,
    isActive: true,
    phone: '',
    whatsapp: '',
  });

  function createFormData() {
    const body = new FormData();
    const blob = file === null ? dataURIToBlob(placeholder) : dataURIToBlob(file! as string);
    body.append('file', blob);
    return body;
  }

  function createObjectURL(data: React.ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();
    reader.readAsDataURL(data.target.files![0]);
    reader.onload = (ctx) => {
      if (ctx.loaded / 1024 < 4000) {
        setFile(ctx.target!.result);
      } else {
        setModal({ title: 'Ocorreu um erro!', children: 'O arquivo selecionado ultrapassou o limite máximo de 4mb', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }
    };
  }

  function enableButton() {
    if (professional.name.length > 0
          && professional.councilRegister.length > 0
    ) {
      return true;
    }
    return false;
  }

  async function saveProfessionalAvatar(id: number) {
    try {
      await api.post(`/admin/professional/avatar/${id}`, createFormData(), token);
    } catch (err: any) {
      console.log(err.response.data);
      throw new Error(err);
    }
  }

  async function saveProfessional() {
    try {
      setLoading(true);
      const response = await api.post('/admin/professional', professional, token);
      await saveProfessionalAvatar(response.data.id);
      setLoading(false);
      navigate(-1);
    } catch (err: any) {
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível salvar o profissional. Tente novamente mais tarde.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
      setLoading(false);
    }
  }

  return (
    <DefaultLayout
      pageTitle="Adicionar Profissional"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row partners">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Profissional</h1>
          <section className="row">
            <h1 className="text-center">Avatar do credenciado</h1>
            <div className="col-12 mb-4 d-flex justify-content-center align-items-center flex-column">
              <div className="partner-photo-wrapper">
                <img src={file === null ? placeholder : file! as string} alt="" />
              </div>
              <p className="mb-4 helper">Melhor proporção: 4/4. Ex: 400px / 400px</p>
              <input
                type="file"
                className="d-none"
                onChange={(data) => createObjectURL(data)}
                accept=".png, .jpeg, .jpg"
                ref={fileRef}
              />
              <ButtonTextIcon
                label="Selecionar imagem"
                type={BtnType.PRIMARY}
                icon="image"
                active
                onClick={() => fileRef.current.click()}
                ref={fileRef}
              />
            </div>
          </section>
          <div className="row">
            <div className="col-4">
              <BasicInput
                id="name-input"
                label="Nome"
                readOnly={false}
                helper="Ex. Dr. Áureo"
                error={professional.name.length < 1 ? 'Campo obrigatório' : ''}
                value={professional.name}
                type="text"
                onChange={(data) => setProfessional({ ...professional, name: data })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="council-input"
                label="Registro no conselho"
                readOnly={false}
                helper="Ex. CRMMA-41234"
                error={professional.councilRegister.length < 1 ? 'Campo obrigatório' : ''}
                value={professional.councilRegister}
                type="text"
                onChange={(data) => setProfessional({ ...professional, councilRegister: data })}
              />
            </div>
            <div className="col-4">
              <BasicOption
                id="sexo-input"
                label="Sexo"
                readOnly={false}
                helper="Ex. Feminino"
                error=""
                value={professional.sex === 'f' ? 'Feminino' : 'Masculino'}
                onChange={(data) => setProfessional({ ...professional, sex: data === 'Feminino' ? 'f' : 'm' })}
              >
                {['Feminino', 'Masculino']}
              </BasicOption>
            </div>
            <div className="col-4">
              <BasicInput
                id="phone-input"
                label="Telefone"
                readOnly={false}
                helper="Ex. (62) 0000-0000"
                error=""
                value={professional.phone}
                type="text"
                onChange={(data) => setProfessional({ ...professional, phone: maskPhone(data) })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="whatsapp-input"
                label="Whatsapp"
                readOnly={false}
                helper="Ex. (62) 0000-0000"
                error=""
                value={professional.whatsapp}
                type="text"
                onChange={(data) => setProfessional({ ...professional, whatsapp: maskPhone(data) })}
              />
            </div>
          </div>
          <div className="col-12 mt-2 mb-4 allow-modifications">
            <input
              type="checkbox"
              onChange={() => setProfessional({ ...professional, show: !professional.show })}
              checked={professional.show}
            />
            <p>Exibir no site (www.redesanta.com/beneficios)</p>
          </div>
          <div className="col-12 footer">
            <ButtonTextIcon
              label="Salvar"
              type={BtnType.PRIMARY}
              icon="backup"
              active={enableButton()}
              onClick={() => saveProfessional()}
              ref={null}
            />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
