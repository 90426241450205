/* eslint-disable no-tabs */
import axios from 'axios';
import ICepFinder, { AddressProps } from '../dto/CEPfinder';
import api from '../config/axios';

export default class CEPFinder implements ICepFinder {
  cep: string = '';

  constructor(cepCtx: string) {
    this.cep = cepCtx;
  }

  public async findCep(): Promise<AddressProps | null> {
    try {
      const response = await api.post('/admin/cep', {
        cep: this.cep,
      }, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('adminToken')}`,
        },
      });
      console.log('response cep', response.data);
      return {
        street: response.data.logradouro,
        city: response.data.localidade,
        state: response.data.uf,
        neighborhood: response.data.bairro,
        ibge: response.data.ibge,
      };
    } catch (err: any) {
      console.log('erro na busca do cep', err.response.data);
      return null;
    }
  }
}
