/* eslint-disable max-len */
import React from 'react';

type DataProps = {
    PfValorTotal: string,
    PfValorDozeMeses: string,
    PfTicketMedio: string,
    PfTotalVidas: string,
    PfTotalTitular: string,
    PfTotalDependentes: string,
    PjValorTotal: string,
    PjValorDozeMeses : string,
    PjTicketMedio: string,
    PjTotalVidas: string,
    PjTotalTitular: string,
    PjTotalDependentes: string,
    ValorGlobal: string,
    ValorGlobalDozeMeses: string,
    ValorGlobalTicketMedio: string,
    TotalGlobalTitulares: string,
    TotalGlobalDependentes: string,
    TotalGlobalVidas: string
}

export interface IPrimaryModalContent {
    id: string,
    initialDate: string,
    finalDate: string,
    data: DataProps,
}

export default function TicketSellModal({
  id, initialDate, finalDate, data,
}: IPrimaryModalContent) {
  return (
    <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Relatório de Ticket Médio Por Venda</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body modal-report">
            <div className="d-flex w-100 justify-content-center">
              <p className="fw-bold">
                {initialDate}
                {' '}
                à
                {' '}
                {finalDate}
              </p>
            </div>
            <div className="bg-light mb-4 w-100 p4 text-center d-flex align-items-center justify-content-center mt-4" style={{ height: 24 }}>
              <p className="p-0 fw-bold">PF</p>
            </div>
            <div className="ticket-wrapper">
              <div className="ticket-medio-container">
                <p>Soma dos Contratos</p>
                <p>
                  R$
                  {' '}
                  {data.PfValorTotal}
                </p>
              </div>
              <div className="ticket-medio-container">
                <p>Soma dos Contratos / 12</p>
                <p>
                  R$
                  {' '}
                  {data.PfValorDozeMeses}
                </p>
              </div>
              <div className="ticket-medio-container">
                <p>Total de vidas</p>
                <p>{data.PfTotalVidas}</p>
              </div>
              <div className="ticket-medio-container">
                <p>Total de titulares</p>
                <p>{data.PfTotalTitular}</p>
              </div>
              <div className="ticket-medio-container">
                <p>Total de dependentes</p>
                <p>{data.PfTotalDependentes}</p>
              </div>
              <div className="ticket-medio-container-active">
                <p>Ticket Médio</p>
                <p>
                  R$
                  {' '}
                  {data.PfTicketMedio}
                </p>
              </div>
            </div>
            <div className="bg-light mb-4 w-100 p4 text-center d-flex align-items-center justify-content-center mt-4" style={{ height: 24 }}>
              <p className="p-0 fw-bold">PJ</p>
            </div>
            <div className="ticket-wrapper">
              <div className="ticket-medio-container">
                <p>Soma dos Contratos</p>
                <p>
                  R$
                  {' '}
                  {data.PjValorTotal}
                </p>
              </div>
              <div className="ticket-medio-container">
                <p>Soma dos Contratos / 12</p>
                <p>
                  R$
                  {' '}
                  {data.PjValorDozeMeses}
                </p>
              </div>
              <div className="ticket-medio-container">
                <p>Total de vidas</p>
                <p>{data.PjTotalVidas}</p>
              </div>
              <div className="ticket-medio-container">
                <p>Total de titulares</p>
                <p>{data.PjTotalTitular}</p>
              </div>
              <div className="ticket-medio-container">
                <p>Total de dependentes</p>
                <p>{data.PjTotalDependentes}</p>
              </div>
              <div className="ticket-medio-container-active">
                <p>Ticket Médio</p>
                <p>
                  R$
                  {' '}
                  {data.PjTicketMedio}
                </p>
              </div>
            </div>
            <div className="bg-light mb-4 w-100 p4 text-center d-flex align-items-center justify-content-center mt-4" style={{ height: 24 }}>
              <p className="p-0 fw-bold">GLOBAL</p>
            </div>
            <div className="ticket-wrapper">
              <div className="ticket-medio-container">
                <p>Soma dos Contratos</p>
                <p>
                  R$
                  {' '}
                  {data.ValorGlobal}
                </p>
              </div>
              <div className="ticket-medio-container">
                <p>Soma dos Contratos / 12</p>
                <p>
                  R$
                  {' '}
                  {data.ValorGlobalDozeMeses}
                </p>
              </div>
              <div className="ticket-medio-container">
                <p>Total de vidas</p>
                <p>{data.TotalGlobalVidas}</p>
              </div>
              <div className="ticket-medio-container">
                <p>Total de titulares</p>
                <p>{data.TotalGlobalTitulares}</p>
              </div>
              <div className="ticket-medio-container">
                <p>Total de dependentes</p>
                <p>{data.TotalGlobalDependentes}</p>
              </div>
              <div className="ticket-medio-container-active">
                <p>Ticket Médio</p>
                <p>
                  R$
                  {' '}
                  {data.ValorGlobalTicketMedio}
                </p>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Fechar

            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
