/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalProps } from '../../components/Modal';
import SwitcherPrimary from '../../components/SwitcherPrimary';
import api from '../../config/axios';
import DefaultLayout from '../../Layout';

type DataProps = {
  id: number,
  justification: string,
  isActive: boolean
}

type MetaProps = {
per_page: number,
current_page: number,
last_page: number,
first_page: number
}

type ResponseProps = {
meta: MetaProps,
data: DataProps[]
}

export default function CancelJustification() {
  const navigate = useNavigate();
  const [data, setData] = useState<ResponseProps>({
    data: [],
    meta: {
      current_page: 1,
      first_page: 1,
      last_page: 1,
      per_page: 15,
    },
  });
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [loading, setLoading] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [modal, setModal] = useState<null | ModalProps>(null);
  const [page, setPage] = useState(1);

  async function fetchJustifications() {
    try {
      setLoading(true);
      const response = await api.get(`admin/block-justifications?page=${page}`, token);
      console.log(response.data);
      setData(response.data);
      setLoading(false);
    } catch (err: any) {
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 3000);
      console.log(err.response);
    }
  }

  async function updateJustification(dataParam: DataProps) {
    try {
      setLoading(true);
      await api.put(`/admin/block-justifications/${dataParam.id}`, {
        ...dataParam,
      }, token);
      await fetchJustifications();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function goToFirstPage() {
    setPage(data.meta.first_page);
  }

  function goToPage(param: number) {
    setPage(Number(param));
  }

  function generatePagination(totalOfPages: number) {
    const pagination: number[] = [];
    for (let i = 0; i < totalOfPages; i += 1) {
      pagination.push(i + 1);
    }
    return pagination;
  }

  function goToNextPage() {
    if (data.meta.current_page < data.meta.last_page) {
      setPage(data.meta.current_page + 1);
    }
  }

  function goToLastPage() {
    setPage(data.meta.last_page);
  }

  useEffect(() => {
    fetchJustifications();
  }, [page]);

  return (
    <DefaultLayout
      pageTitle="Justificativas de cancelamento contratos"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Justificativas de cancelamento</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <button className="table-btn-primary" type="button" onClick={() => navigate('/contratos/motivos-cancelamento/criar')}>
                <span className="material-icons-round">
                  add
                </span>
                adicionar
              </button>
            </div>
            <div className="table-actions d-flex justify-content-start" />
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Título</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {data.data.length > 0 ? data.data.map((item, i) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.justification}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <SwitcherPrimary
                          id={`status-changer-${i}`}
                          onChange={
                              () => updateJustification({ ...item, isActive: !item.isActive })
                                    }
                          isActive={item.isActive}
                        />
                        <div className="table-action-icon">
                          <button type="button" onClick={() => navigate(`/contratos/motivos-cancelamento/${item.id}`)}>
                            <span className="material-icons-round">
                              more
                            </span>

                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : <p>Não existem justificativas cadastradas</p>}
              </tbody>
            </table>
            <div className="table-footer">
              <button type="button" onClick={() => goToFirstPage()}>Primeira</button>
              <p>Página</p>
              <select style={{ minWidth: 300 }} value={data.meta.current_page} onChange={(e) => goToPage(Number(e.target.value))}>
                {generatePagination(data.meta.last_page).map((item) => <option value={item}>{item}</option>)}
              </select>
              <p>
                de
                {' '}
                <strong>{data.meta.last_page}</strong>
              </p>
              <button
                type="button"
                onClick={() => goToNextPage()}
              >
                Próxima
              </button>
              <button
                type="button"
                onClick={() => goToLastPage()}
              >
                Última
              </button>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
