/* eslint-disable max-len */
import { DateTime } from 'luxon';
import React, { useState, useEffect } from 'react';
import DashboardCardOverlay from '../../../components/DashboardCardOverlay';
import api from '../../../config/axios';

type DataProps = {
  PfValorTotal: string,
  PfValorDozeMeses: string,
  PfTicketMedio: string,
  PfTotalVidas: string,
  PfTotalTitular: string,
  PfTotalDependentes: string,
  PjValorTotal: string,
  PjValorDozeMeses : string,
  PjTicketMedio: string,
  PjTotalVidas: string,
  PjTotalTitular: string,
  PjTotalDependentes: string,
  ValorGlobal: string,
  ValorGlobalDozeMeses: string,
  ValorGlobalTicketMedio: string,
  TotalGlobalTitulares: string,
  TotalGlobalDependentes: string,
  TotalGlobalVidas: string

}

export default function TicketDashboard() {
  const [data, setData] = useState<DataProps>();
  const [loading, setLoading] = useState(false);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function fetchData(initialDate: string, finalDate: string) {
    try {
      const response = await api.post('/admin/dashboard/ticket', {
        dataInicio: `${initialDate} 00:00:00`,
        dataFinal: `${finalDate} 23:59:59`,
      }, token);
      setData(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      throw new Error('Erro ao buscar relatórios.');
    }
  }

  useEffect(() => {
    fetchData('01-01-2022', DateTime.now().toFormat('dd-MM-yyyy'));
  }, []);
  return (
    <DashboardCardOverlay loading={loading}>
      <div className="card-contract-activities h-100" id="contract-activities">
        <div className="title-wrapper">
          <div className="">
            <h5 className="title">Ticket Médio - Considerando todo o período</h5>
          </div>
        </div>
        <div className="content-wrapper d-flex flex-column align-items-center">
          <div className="ticket-container-wapper w-100 d-flex justify-content-center">
            <div className="ticket-container">
              <p className="text-center text-muted">Total</p>
              <p>
                R$
                {data?.ValorGlobalTicketMedio}
              </p>
            </div>
          </div>
          <div className="ticket-container-wapper w-50 d-flex justify-content-center">
            <div className="ticket-container">
              <p className="text-center text-muted">APENAS PF</p>
              <p>
                R$
                {data?.PfTicketMedio}
              </p>
            </div>
            <div className="ticket-container">
              <p className="text-center text-muted">APENAS PJ</p>
              <p>
                R$
                {data?.PjTicketMedio}
              </p>
            </div>
          </div>
        </div>
      </div>
    </DashboardCardOverlay>
  );
}
