/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BasicOption from '../../components/BasicOption';
import { ModalProps } from '../../components/Modal';
import api from '../../config/axios';
import normalizeDate from '../../helpers/dates';
import DefaultLayout from '../../Layout';

type DataProps = {
    id: number,
    signature_date: string,
    contract_global_value: number,
    due_date: string,
    ispf: boolean,
    block_temp: boolean | null
    client: string
}

type MetaProps = {
  per_page: number,
  current_page: number,
  last_page: number,
  first_page: number
}

type ResponseProps = {
  meta: MetaProps,
  data: DataProps[]
}

export type FilterProps = {
  days: number,
  currentPage: number,
}

export default function ContractExpiration() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [contract, setContracts] = useState<ResponseProps>({
    meta: {
      first_page: 1,
      last_page: 1,
      current_page: 1,
      per_page: 50,
    },
    data: [],
  });
  const [filter, setFilter] = useState<FilterProps>({
    days: 7,
    currentPage: 1,
  });
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [modal, setModal] = useState<ModalProps | null>(null);

  function goToFirstPage() {
    setFilter((prev) => ({ ...prev, currentPage: contract.meta.first_page }));
  }

  function goToPage(page: number) {
    setFilter({ ...filter, currentPage: Number(page) });
  }

  function generatePagination(totalOfPages: number) {
    const pagination: number[] = [];
    for (let i = 0; i < totalOfPages; i += 1) {
      pagination.push(i + 1);
    }
    return pagination;
  }

  function goToNextPage() {
    if (contract.meta.current_page < contract.meta.last_page) {
      setFilter((prev) => ({ ...prev, currentPage: contract.meta.current_page + 1 }));
    }
  }

  function goToLastPage() {
    setFilter((prev) => ({ ...prev, currentPage: contract.meta.last_page }));
  }

  async function fetchContracts() {
    try {
      setLoading(true);
      console.log({
        currentPage: filter.currentPage,
        days: filter.days,
      });
      const response = await api.post('/admin/due-contracts/', {
        currentPage: filter.currentPage,
        days: filter.days,
      }, token);
      console.log(response.data);
      setContracts(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function changeDays(data: string) {
    if (data === '7 dias') {
      setFilter({ ...filter, days: 7, currentPage: 1 });
    }
    if (data === '15 dias') {
      setFilter({ ...filter, days: 15, currentPage: 1 });
    }
    if (data === '30 dias') {
      setFilter({ ...filter, days: 30, currentPage: 1 });
    }
    if (data === '60 dias') {
      setFilter({ ...filter, days: 60, currentPage: 1 });
    }
    if (data === '90 dias') {
      setFilter({ ...filter, days: 90, currentPage: 1 });
    }
    if (data === '180 dias') {
      setFilter({ ...filter, days: 180, currentPage: 1 });
    }
    if (data === '365 dias') {
      setFilter({ ...filter, days: 365, currentPage: 1 });
    }
  }

  function showDays() {
    if (filter.days === 7) {
      return '7 dias';
    }
    if (filter.days === 15) {
      return '15 dias';
    }
    if (filter.days === 30) {
      return '30 dias';
    }
    if (filter.days === 60) {
      return '60 dias';
    }
    if (filter.days === 90) {
      return '90 dias';
    }
    if (filter.days === 180) {
      return '180 dias';
    }
    return '365 dias';
  }

  useEffect(() => {
    fetchContracts();
  }, [filter]);

  return (
    <DefaultLayout
      pageTitle="Contratos a Expirar"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Contratos a Expirar</h1>
          <div className="table-wrapper">
            <div className="table-actions d-flex justify-content-start">
              <div style={{ width: 110 }}>
                <BasicOption
                  id=""
                  label="Expira em até"
                  readOnly={false}
                  helper=""
                  error=""
                  value={showDays()}
                  onChange={(data) => changeDays(data)}
                >
                  {['7 dias', '15 dias', '30 dias', '60 dias', '90 dias', '180 dias', '365 dias']}
                </BasicOption>
              </div>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Cliente</th>
                  <th scope="col">Dt. Assinatura</th>
                  <th scope="col">Dt. Vencimento</th>
                  <th scope="col">Bloq. Temp?</th>
                  <th scope="col">Valor Global</th>
                </tr>
              </thead>
              <tbody>
                {contract.data.length > 0 ? contract.data.map((item, i) => (
                  <tr>
                    <td style={{ color: item.block_temp ? 'orange' : 'black' }}>{item.ispf ? <Link to={`/contratos/pf/visualizar/${item.id}`}>{`PF${item.id}`}</Link> : <Link to={`/contratos/pj/editar/${item.id}`}>{`PJ${item.id}`}</Link>}</td>
                    <td style={{ color: item.block_temp ? 'orange' : 'black' }}>{item.client}</td>
                    <td style={{ color: item.block_temp ? 'orange' : 'black' }}>{normalizeDate(item.signature_date)}</td>
                    <td style={{ color: item.block_temp ? 'orange' : 'black' }}>{normalizeDate(item.due_date)}</td>
                    <td style={{ color: item.block_temp ? 'orange' : 'black' }}>{item.block_temp ? 'SIM' : 'NÃO'}</td>
                    <td style={{ color: item.block_temp ? 'orange' : 'black' }}>{item.contract_global_value}</td>
                  </tr>
                )) : <p>Não existem leads cadastrados</p>}
              </tbody>
            </table>
            <div className="table-footer">
              <button type="button" onClick={() => goToFirstPage()}>Primeira</button>
              <p>Página</p>
              <select style={{ minWidth: 300 }} value={contract.meta.current_page} onChange={(e) => goToPage(Number(e.target.value))}>
                {generatePagination(contract.meta.last_page).map((item) => <option value={item}>{item}</option>)}
              </select>
              <p>
                de
                {' '}
                <strong>{contract.meta.last_page}</strong>
              </p>
              <button
                type="button"
                onClick={() => goToNextPage()}
              >
                Próxima
              </button>
              <button
                type="button"
                onClick={() => goToLastPage()}
              >
                Última
              </button>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
