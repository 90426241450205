import React, { useEffect, useState } from 'react';
import { ResponseProps } from './FilterProfessional';

interface ModalProps{
    id: string;
    data: ResponseProps
}
function showCategoryByList(elements: string[]) {
  if (elements.length === 0) {
    return 'Sem especialidade';
  }

  let finalString = '';

  elements.forEach((item, i) => {
    if (elements.length === 1) {
      finalString = `${item}.`;
    } else if (i === elements.length - 1) {
      finalString += `${item}.`;
    } else {
      finalString += `${item} | `;
    }
  });

  return finalString;
}

function openAccreditInAnotherTab(accreditedId: number) {
  window.open(`https://painel.redesanta.com/credenciados/editar/${accreditedId}`, '_blank');
}

export default function ShowProfessionalModal({ id, data }: ModalProps) {
  return (
    <div className="modal fade" id={id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Profissional</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body show-professional-modal">
            <div className="title-wrapper">
              <div className="avatar" />
              <h1>{data.name}</h1>
              <p className="especialization">{showCategoryByList(data.category.map((item) => item.title))}</p>
              {data.councilRegister && (
                <p className="council-register">{data.councilRegister}</p>
              )}
            </div>
            {data.phone && (
            <>
              <h5>Telefone</h5>
              <p className="phone">
                {data.phone}
              </p>
            </>
            )}

            {data.whatsapp && (
            <>
              <h5>Whatsapp</h5>
              <p className="phone">
                {data.whatsapp}
              </p>
            </>
            )}

            {data.accredited.length > 0 ? (
              <div className="accredited-wrapper">
                <p className="accredited-link">Vínculo com parceiros</p>
                {data.accredited.map((item) => (
                  <div className="accredited-item">
                    <p className="accredited-title">{item.name}</p>
                    <button type="button" onClick={() => openAccreditInAnotherTab(item.id)}>
                      <span className="material-icons-round">
                        more
                      </span>
                    </button>
                  </div>
                ))}
              </div>
            ) : <p className="mt-4 text-center">Profissional não possui vínculo cadastrado.</p>}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
