import download from 'downloadjs';
import React, { createRef, useEffect, useState } from 'react';
import api from '../../config/axios';

interface AllTimeValueProps {
    id: string,
}

export default function OpenExcel({
  id,
}: AllTimeValueProps) {
  const [loading, setLoading] = useState(false);
  const [closeRef, setCloseRef] = useState(createRef<any>());

  async function fetchHolderToExcel() {
    try {
      setLoading(true);
      const response = await api.get('admin/reports/pf/getallholdersexcel', { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, 'Titulares REDESANTA.xlsx');
      setLoading(false);
      closeRef.current.click();
    } catch (err) {
      setLoading(false);
    }
  }

  async function fetchDependentToExcel() {
    try {
      setLoading(true);
      const response = await api.get('admin/reports/pf/getalldependentsexcel', { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, 'Dependentes REDESANTA.xlsx');
      setLoading(false);
      closeRef.current.click();
    } catch (err) {
      setLoading(false);
    }
  }

  async function fetchHoldersPfToExcel() {
    try {
      setLoading(true);
      const response = await api.get('admin/reports/pf/getonlypfholdersexcel', { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, 'Titulares PF REDESANTA.xlsx');
      setLoading(false);
      closeRef.current.click();
    } catch (err) {
      setLoading(false);
    }
  }

  async function fetchHoldersPjToExcel() {
    try {
      setLoading(true);
      const response = await api.get('admin/reports/pj/getonlypjholdersexcel', { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, 'Titulares PJ REDESANTA.xlsx');
      setLoading(false);
      closeRef.current.click();
    } catch (err) {
      setLoading(false);
    }
  }

  async function fetchDependentsPfToExcel() {
    try {
      setLoading(true);
      const response = await api.get('admin/reports/pf/getonlypfdependentsexcel', { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, 'Dependentes PF REDESANTA.xlsx');
      setLoading(false);
      closeRef.current.click();
    } catch (err) {
      setLoading(false);
    }
  }

  async function fetchDependentsPjToExcel() {
    try {
      setLoading(true);
      const response = await api.get('admin/reports/pj/getonlypjdependentsexcel', { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, 'Dependentes PJ REDESANTA.xlsx');
      setLoading(false);
      closeRef.current.click();
    } catch (err) {
      setLoading(false);
    }
  }

  async function fetchBenefitsToExcel() {
    try {
      setLoading(true);
      const response = await api.get('admin/benefitstoexcel', { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, 'Benefícios.xlsx');
      setLoading(false);
      closeRef.current.click();
    } catch (err) {
      setLoading(false);
    }
  }

  async function fetchExamsToExcel() {
    try {
      setLoading(true);
      const response = await api.get('admin/examstoexcel', { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, 'Exames.xlsx');
      setLoading(false);
      closeRef.current.click();
    } catch (err) {
      setLoading(false);
    }
  }

  async function fetchProceduresToExcel() {
    try {
      setLoading(true);
      const response = await api.get('admin/procedurestoexcel', { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, 'Precedimentos.xlsx');
      setLoading(false);
      closeRef.current.click();
    } catch (err) {
      setLoading(false);
    }
  }

  async function fetchConsultationToExcel() {
    try {
      setLoading(true);
      const response = await api.get('admin/consultationstoexcel', { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, 'Consultas.xlsx');
      setLoading(false);
      closeRef.current.click();
    } catch (err) {
      setLoading(false);
    }
  }

  async function fetchAccreditedsToExcel() {
    try {
      setLoading(true);
      const response = await api.get('admin/accreditedstoexcel', { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, 'Credenciados.xlsx');
      setLoading(false);
      closeRef.current.click();
    } catch (err) {
      setLoading(false);
    }
  }

  async function fetchProfessionalsToExcel() {
    try {
      setLoading(true);
      const response = await api.get('admin/professionalstoexcel', { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, 'Profissionais.xlsx');
      setLoading(false);
      closeRef.current.click();
    } catch (err) {
      setLoading(false);
    }
  }

  return (
    <div className="modal fade" id={id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Baixar em formato do MS EXCEL</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeRef} />
          </div>
          <div className="modal-body d-flex flex-column justify-content-center align-items-center">
            {loading ? (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <>
                <p className="text-center fw-bold mb-2 text-uppercase mt-2" style={{ fontSize: 12 }}>Clientes</p>
                <button type="button" className="generate-excel-btn-wrapper" onClick={() => fetchHolderToExcel()}>
                  <span className="material-icons-round">assignment_returned</span>
                  Dados de todos titulares
                </button>
                <button type="button" className="generate-excel-btn-wrapper mt-2" onClick={() => fetchDependentToExcel()}>
                  <span className="material-icons-round">assignment_returned</span>
                  Dados de todos dependentes
                </button>
                <button type="button" className="generate-excel-btn-wrapper mt-2" onClick={() => fetchHoldersPfToExcel()}>
                  <span className="material-icons-round">assignment_returned</span>
                  Dados de titulares PF
                </button>
                <button type="button" className="generate-excel-btn-wrapper mt-2" onClick={() => fetchHoldersPjToExcel()}>
                  <span className="material-icons-round">assignment_returned</span>
                  Dados de titulares PJ
                </button>
                <button type="button" className="generate-excel-btn-wrapper mt-2" onClick={() => fetchDependentsPfToExcel()}>
                  <span className="material-icons-round">assignment_returned</span>
                  Dados de dependentes PF
                </button>
                <button type="button" className="generate-excel-btn-wrapper mt-2" onClick={() => fetchDependentsPjToExcel()}>
                  <span className="material-icons-round">assignment_returned</span>
                  Dados de dependentes PJ
                </button>

                <p className="text-center fw-bold mb-2 text-uppercase mt-4" style={{ fontSize: 12 }}>Credenciados / Profissionais</p>
                <button type="button" className="generate-excel-btn-wrapper mt-2" onClick={() => fetchBenefitsToExcel()}>
                  <span className="material-icons-round">assignment_returned</span>
                  Benefícios
                </button>
                <button type="button" className="generate-excel-btn-wrapper mt-2" onClick={() => fetchExamsToExcel()}>
                  <span className="material-icons-round">assignment_returned</span>
                  Exames
                </button>
                <button type="button" className="generate-excel-btn-wrapper mt-2" onClick={() => fetchProceduresToExcel()}>
                  <span className="material-icons-round">assignment_returned</span>
                  Procedimentos
                </button>
                <button type="button" className="generate-excel-btn-wrapper mt-2" onClick={() => fetchConsultationToExcel()}>
                  <span className="material-icons-round">assignment_returned</span>
                  Consultas
                </button>
                <button type="button" className="generate-excel-btn-wrapper mt-2" onClick={() => fetchAccreditedsToExcel()}>
                  <span className="material-icons-round">assignment_returned</span>
                  Credenciados
                </button>
                <button type="button" className="generate-excel-btn-wrapper mt-2" onClick={() => fetchProfessionalsToExcel()}>
                  <span className="material-icons-round">assignment_returned</span>
                  Profissionais
                </button>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
