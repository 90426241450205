/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DefaultLayout from '../../../Layout';
import FilterCategory from './FilterCategory/FilterCategory';
import FilterAccredited from './FilterPartner/FilterPartner';
import FilterProfessional from './FilterProfessionals/FilterProfessional';

export type CategoryProps = {
    id: number,
    isActive: boolean,
    title: string,
    type: 'medic' | 'health'
}

// export type ProceduresProps = {
//     accreditedId: number,
//     currentPrice?: string,
//     description?: string,
//     discount?: string,
//     finalPrice?: string,
// }

export type AccreditedProps = {
    address: string,
    cpfCnpj: string,
    email: string,
    id: number,
    isActive: boolean,
    name: string,
    benefits: []
    services?: string,
}

enum ActualPage {
    PROFESSIONAL,
    ACCREDITED,
    CATEGORY,
}

export default function ViewPartner() {
  const [actualPage, setActualPage] = useState<ActualPage>(ActualPage.PROFESSIONAL);
  const [loading, setLoading] = useState(false);

  if (actualPage === ActualPage.PROFESSIONAL) {
    return (
      <DefaultLayout
        pageTitle="Filtrar por profissionais"
        loading={loading}
        showToastSuccess={false}
        showToastError={false}
        showRightSlider={false}
        rightSliderContent={undefined}
        modal={null}
        setCloseRightSlider={() => null}
      >
        <div className="view-partners">
          <div className="row mb-4">
            <div className="col-12 header-btn-wrapper">
              <button
                type="button"
                className="btn-active"
                onClick={() => setActualPage(ActualPage.PROFESSIONAL)}
                style={{ width: 'fit-content', padding: '8px 16px' }}
              >
                Profissionais
              </button>
              <button
                type="button"
                className="btn-inactive"
                onClick={() => setActualPage(ActualPage.CATEGORY)}
                style={{ width: 'fit-content', padding: '8px 16px' }}
              >
                Especialidades
              </button>
              <button
                type="button"
                className="btn-inactive"
                onClick={() => setActualPage(ActualPage.ACCREDITED)}
                style={{ width: 'fit-content', padding: '8px 16px' }}
              >
                Credenciados
              </button>
            </div>
          </div>
          <FilterProfessional setLoading={(arg) => setLoading(arg)} />
        </div>

      </DefaultLayout>
    );
  }
  if (actualPage === ActualPage.CATEGORY) {
    return (
      <DefaultLayout
        pageTitle="Filtrar por especialidade"
        loading={loading}
        showToastSuccess={false}
        showToastError={false}
        showRightSlider={false}
        rightSliderContent={undefined}
        modal={null}
        setCloseRightSlider={() => null}
      >
        <div className="view-partners">
          <div className="row mb-4">
            <div className="col-12 header-btn-wrapper">
              <button
                type="button"
                className="btn-inactive"
                onClick={() => setActualPage(ActualPage.PROFESSIONAL)}
                style={{ width: 'fit-content', padding: '8px 16px' }}
              >
                Profissionais
              </button>
              <button
                type="button"
                className="btn-active"
                onClick={() => setActualPage(ActualPage.CATEGORY)}
                style={{ width: 'fit-content', padding: '8px 16px' }}
              >
                Especialidades
              </button>
              <button
                type="button"
                className="btn-inactive"
                onClick={() => setActualPage(ActualPage.ACCREDITED)}
                style={{ width: 'fit-content', padding: '8px 16px' }}
              >
                Credenciados
              </button>
            </div>
          </div>
          <FilterCategory setLoading={(arg) => setLoading(arg)} />
        </div>

      </DefaultLayout>
    );
  }
  return (
    <DefaultLayout
      pageTitle="Filtrar por credenciado"
      loading={loading}
      showToastSuccess={false}
      showToastError={false}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={null}
      setCloseRightSlider={() => null}
    >
      <div className="view-partners">
        <div className="row mb-4">
          <div className="col-12 header-btn-wrapper">
            <button
              type="button"
              className="btn-inactive"
              onClick={() => setActualPage(ActualPage.PROFESSIONAL)}
              style={{ width: 'fit-content', padding: '8px 16px' }}
            >
              Profissionais
            </button>
            <button
              type="button"
              className="btn-inactive"
              onClick={() => setActualPage(ActualPage.CATEGORY)}
              style={{ width: 'fit-content', padding: '8px 16px' }}
            >
              Especialidades
            </button>
            <button
              type="button"
              className="btn-active"
              onClick={() => setActualPage(ActualPage.ACCREDITED)}
              style={{ width: 'fit-content', padding: '8px 16px' }}
            >
              Credenciados
            </button>
          </div>
        </div>
        <FilterAccredited setLoading={(arg) => setLoading(arg)} />
      </div>

    </DefaultLayout>
  );
}
