import React, { useState } from 'react';
import BasicInput from '../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalProps } from '../../components/Modal';
import api from '../../config/axios';
import showApiErrors from '../../helpers/showApiErrors';

interface SendEmailContainerProps {
    setCloseAndUpdate: () => void
    setClose: () => void;
    setModal: (arg0: React.SetStateAction<null | ModalProps>) => void,
}

export default function SendEmailContainer({
  setCloseAndUpdate, setClose, setModal,
}: SendEmailContainerProps) {
  const [emailInput, setEmailInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  async function sendEmail() {
    try {
      setLoading(true);
      await api.post('/admin/register', {
        email: emailInput.trim(),
        redirectUrl: 'https://painel.redesanta.com/cadastro',
      }, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('adminToken')}`,
        },
      });
      setLoading(false);
      setSuccess(true);
    } catch (err: any) {
      if (typeof err.response !== 'undefined') {
        if (err.response.status === 422) {
          setModal(showApiErrors(setModal, err.response.data.errors));
        }
      } else {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
        setLoading(false);
      }
      console.log(err.response);
    }
  }

  if (error) {
    return (
      <div className="row send-email-container">
        <div className="col-12">
          <div className="d-flex justify-content-center">
            <span className="material-icons-round big text-error">
              error
            </span>
          </div>
          <h1 className="text-center">Ocorreu um erro!</h1>
          <p className="p-0">
            Não foi possível processar sua requisição. Possíveis causas:
            <p className="p-0 mt-2">1. Email em formato errado.</p>
            <p className="p-0 mt-2">2. Esse email já está cadastrado no sistema.</p>
            <p className="p-0 mt-2">3. Sem conexão com a internet.</p>
          </p>
          <p className="p-0 mt-4">Caso o problema persista, favor entrar em contato com suporte</p>
        </div>
        <div className="col-12 d-flex justify-content-center mt-4">
          <ButtonTextIcon
            label="Fechar"
            type={BtnType.PRIMARY}
            icon="done"
            active
            onClick={() => setClose()}
            ref={null}
          />
        </div>
      </div>
    );
  }

  if (success) {
    return (
      <div className="row send-email-container">
        <div className="col-12">
          <div className="d-flex justify-content-center">
            <span className="material-icons-round big">
              done
            </span>
          </div>
          <h1 className="text-center">Convite enviado!</h1>
          <p>
            Em alguns minutos o email chegará ao destinatário com as instruções de cadastro.
          </p>
        </div>
        <div className="col-12 d-flex justify-content-center mt-4">
          <ButtonTextIcon
            label="Fechar"
            type={BtnType.PRIMARY}
            icon="done"
            active
            onClick={() => setCloseAndUpdate()}
            ref={null}
          />
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="row send-email-container">
        <div className="col-12">
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
          <p className="text-center text-primary mt-4">Aguarde...</p>
        </div>
      </div>
    );
  }
  return (
    <div className="row send-email-container">
      <div className="col-12">
        <div className="">
          <BasicInput
            id="email-input"
            label="Digite o email do administrador"
            readOnly={false}
            helper="Ex: john@gmail.com"
            error={emailInput.length < 2 ? 'Campo obrigatório' : ''}
            value={emailInput}
            type="email"
            onChange={(data) => setEmailInput(data)}
          />
        </div>
      </div>
      <div className="col-12 mt-4">
        <div className="footer">
          <ButtonTextIcon
            label="Enviar E-mail"
            type={BtnType.PRIMARY}
            icon="email"
            active={emailInput.length > 4}
            onClick={() => sendEmail()}
            ref={null}
          />
        </div>
      </div>
    </div>
  );
}
