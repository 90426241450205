import React from 'react';
import { ModalActionType, ModalProps } from '../components/Modal';

export type UnprocessableEntityErrorProps = [
        {
            rule: string,
            field: string,
            message: string
        }
]

export default function showApiErrors(
  setModal: (arg0: React.SetStateAction<ModalProps | null>) => void,
  error: UnprocessableEntityErrorProps,
) {
  const modal: ModalProps = {
    children: (
      <div className="d-flex flex-column">
        {error.map((item) => (
          <p>
            {`- ${item.message}`}
          </p>
        ))}
      </div>),
    actions: [
      {
        onClick: () => setModal(null),
        label: 'Ok, fechar esse aviso.',
        type: ModalActionType.PRIMARY,
      },
    ],
    title: 'Ocorreu um erro',
  };
  return modal;
}
