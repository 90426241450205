/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalProps } from '../../../components/Modal';
import api from '../../../config/axios';
import DefaultLayout from '../../../Layout';

type CategoryResponse = {
    id: number;
    isActive: boolean;
    title: string;
    type: string;
}

type ProfessionalResponse = {
  id: number;
  name: string;
  councilRegister: string
  sex: string
  isActive: boolean,
  createdAt: string
}

export default function Professionals() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [categories, setCategories] = useState<CategoryResponse[]>([]);
  const [professionals, setProfessionals] = useState<ProfessionalResponse[]>([]);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function fetchCategories() {
    try {
      const response = await api.get('/admin/professional-category', token);
      setCategories(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  }

  async function fetchProfessionals() {
    try {
      const response = await api.get('/admin/professional', token);
      setProfessionals(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  }

  async function fetchProfessionalsAndCategories() {
    setLoading(true);
    await fetchProfessionals();
    await fetchCategories();
    setLoading(false);
  }

  useEffect(() => {
    fetchProfessionalsAndCategories();
  }, []);

  return (
    <DefaultLayout
      pageTitle="Profissionais da Rede"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Especialidades</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <button className="table-btn-primary" type="button" onClick={() => navigate('/profissionais/especialidade/adicionar')}>
                <span className="material-icons-round">
                  add
                </span>
                adicionar
              </button>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Título</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {categories.length < 1 ? (
                  <p>Nenhuma especialidade cadastrada.</p>
                ) : categories.map((item, i) => (
                  <tr>
                    <td>{item.title}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <button type="button" onClick={() => navigate(`/profissionais/especialidade/editar/${item.id}`)}>
                            <span className="material-icons-round">
                              more
                            </span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Profissionais</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <button className="table-btn-primary" type="button" onClick={() => navigate('/profissionais/adicionar')}>
                <span className="material-icons-round">
                  add
                </span>
                adicionar
              </button>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Título</th>
                  <th scope="col">Dt. Inserção</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {professionals.length < 1 ? (
                  <p>Nenhum profissional cadastrado.</p>
                ) : professionals.map((item, i) => (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.createdAt}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <button type="button" onClick={() => navigate(`/profissionais/editar/${item.id}`)}>
                            <span className="material-icons-round">
                              more
                            </span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
