/* eslint-disable max-len */
import React from 'react';

interface ITooltip {
    id: string;
    text: string
}

export default function Tooltip({ id, text }: ITooltip) {
  return (
    <div role="tooltip" id={id}>
      <p>{text}</p>
    </div>
  );
}
