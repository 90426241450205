import React, { useState } from 'react';
import BasicOption from '../../components/BasicOption';
import api from '../../config/axios';
import { DataProps } from './Incomes';

type ModalProps = {
  income: DataProps
  id: string
}

// eslint-disable-next-line no-shadow
enum ActualPage {
    LOADING,
    SELECT,
    SUCCESS,
    ERROR,
}

export default function CancelNFEModal({
  income, id,
}: ModalProps) {
  const [motivo, setMotivo] = useState<'Erro na emissão' | 'Serviço não prestado' | 'Duplicidade da nota'>('Erro na emissão');
  const [actualPage, setActualPage] = useState<ActualPage>(ActualPage.SELECT);
  const [closeRef] = useState(React.createRef<any>());

  async function cancelNFE() {
    try {
      setActualPage(ActualPage.LOADING);
      let motivoCtx = 1;
      if (motivo === 'Erro na emissão') {
        motivoCtx = 1;
      } else if (motivo === 'Serviço não prestado') {
        motivoCtx = 2;
      } else {
        motivoCtx = 3;
      }
      await api.put('admin/nfse/cancelar', {
        uuid: income.nfuuid,
        motivo: motivoCtx,
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      window.location.reload();
      setActualPage(ActualPage.SUCCESS);
    } catch (err: any) {
      console.log(err.response);
      setActualPage(ActualPage.ERROR);
    }
  }
  return (
    <div className="modal fade" id={id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Cancelar NFE</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeRef} />
          </div>
          <div className="modal-body d-flex align-items-center" style={{ minHeight: 200 }}>
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              {actualPage === ActualPage.SELECT && (
              <BasicOption
                id="motivo-cancel"
                label="Motivo do cancelamento"
                readOnly={false}
                helper=""
                error=""
                value={motivo}
                onChange={(arg) => setMotivo(arg as typeof motivo)}
              >
                {['Erro na emissão', 'Serviço não prestado', 'Duplicidade da nota']}
              </BasicOption>
              )}
              {actualPage === ActualPage.LOADING && (
                <p className="text-center">Carregando, aguarde...</p>
              )}
              {actualPage === ActualPage.SUCCESS && (
                <p className="text-center">NFe cancelada com sucesso!</p>
              )}
              {actualPage === ActualPage.ERROR && (
              <p className="text-center">Ocorreu um erro ao cancelar NFE</p>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" style={{ backgroundColor: '#c5c5c5' }}>Fechar</button>
            <button type="button" className="btn btn-danger" style={{ backgroundColor: 'tomato' }} onClick={() => cancelNFE()}>Cancelar NFe</button>
          </div>
        </div>
      </div>
    </div>
  );
}
