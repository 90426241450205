/* eslint-disable max-len */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { ArchivedContractPFProps, ContractPFProps } from '../pages/Contracts/PF/types';
import ContractPJProps, { ArchivedContractPJProps } from '../dto/contractPJ';
import BasicInput from './BasicInput';
import BasicOption from './BasicOption';
import PriceInput from './PriceInput';

interface AllTimeValueProps {
    id: string,
    contract: ArchivedContractPFProps | ArchivedContractPJProps,
    seller: string,
    client: string,
    selectedProduct: string,
    reffer: string,
    indication: string,
    freelancer: string,
    totalHoldersEnabled?: number,
    totalDependentsEnabled?: number,

}

export default function ArchivedContractDataModal({
  id, contract, seller, client, selectedProduct, reffer, indication, freelancer, totalDependentsEnabled, totalHoldersEnabled,
}: AllTimeValueProps) {
  return (
    <div className="modal fade" id={id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Dados do contrato arquivado</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="col-12">
              <BasicInput
                id="vendedor-inout"
                label="Vendedor"
                readOnly
                helper="Quem intermediou o contrato"
                error=""
                value={seller}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-12">
              <BasicInput
                id="client-PF-input"
                label="Cliente PF"
                readOnly
                helper="Selecione um cliente titular"
                error=""
                value={client}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-12">
              <BasicInput
                id="product-selected-input"
                label="Produto escolhido"
                readOnly
                helper="Selecione o produto"
                error=""
                value={selectedProduct}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-12">
              <BasicInput
                id="SelectedReffer"
                label="Embaixador Master"
                readOnly
                helper=""
                error=""
                value={reffer}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-12">
              <BasicInput
                id="indication"
                label="Embaixador"
                readOnly
                helper=""
                error=""
                value={indication}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-12">
              <BasicInput
                id="freelancer"
                label="Representante"
                readOnly
                helper=""
                error=""
                value={freelancer}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-12">
              <BasicInput
                id="total-vidas-input"
                label="Qtd. Vidas"
                readOnly
                helper=""
                error={contract.totalLifes === 0 ? 'Mínimo de 1.' : ''}
                value={contract.totalLifes.toString()}
                type="number"
                onChange={(data) => null}
              />
            </div>
            {'totalHoldersEnabled' in contract && (
            <div className="col-12">
              <BasicInput
                id="total-titulares-input"
                label="Qtd. Titulares"
                readOnly
                helper="Ex: 20"
                error=""
                value={'totalHoldersEnabled' in contract ? contract.totalHoldersEnabled.toString() : ''}
                type="number"
                onChange={() => null}
              />
            </div>
            )}

            <div className="col-12">
              <BasicInput
                id="total-dependentes-input"
                label="Qtd. Dependentes"
                readOnly
                helper=""
                error={contract.totalDependentsEnabled + 1 > contract.totalLifes ? 'Ultrapassou o total de vidas' : ''}
                value={contract.totalDependentsEnabled.toString()}
                type="number"
                onChange={(data) => null}
              />
            </div>
            <div className="col-12">
              <BasicInput
                id="preco-titular-input"
                label="Preço por titular (R$)"
                readOnly
                helper=""
                error={contract.pricePerHolder === 0 ? 'Adicione um preço por titular' : ''}
                value={contract.pricePerHolder.toString()}
                type="number"
                onChange={(data) => null}
              />
            </div>
            <div className="col-12">
              <BasicInput
                id="preco-dependentes-input"
                label="Preço por dependente (R$)"
                readOnly
                helper=""
                error=""
                value={contract.pricePerDependent.toString()}
                type="number"
                onChange={(data) => null}
              />
            </div>
            <div className="col-12">
              <BasicInput
                id="global-value-input"
                label="Valor global do contrato (R$)"
                readOnly
                helper=""
                error=""
                value={contract.contractGlobalValue.toString()}
                type="number"
                onChange={(data) => null}
              />
            </div>
            <div className="col-12">
              <BasicInput
                id="date-start-input"
                label="Data de Início do contrato"
                readOnly
                helper=""
                error={contract.signatureDate.length < 10 ? 'Digite uma data válida' : ''}
                value={contract.signatureDate}
                type="text"
                onChange={(data) => null}
              />
            </div>
            <div className="col-12">
              <BasicInput
                id="date-start-input"
                label="Data de Vencimento do contrato"
                readOnly
                helper=""
                error=""
                value={contract.dueDate}
                type="text"
                onChange={(data) => null}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
