/* eslint-disable max-len */
import React, { createRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import BasicInput from '../../components/BasicInput';
import BasicOption from '../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import { statesOfBrazil } from '../../dto/states';
import DefaultLayout from '../../Layout';
import {
  maskCep, maskCPForCNPJ, maskHour, maskMinuteAndSeconds, maskPhone, unmaskData,
} from '../../helpers/inputMasks.js';
import { validateCnpjOrCpf } from '../../helpers/validate';
import api from '../../config/axios';
import showApiErrors from '../../helpers/showApiErrors';
import AddInput from '../../components/AddInput';
import CEPFinder from '../../helpers/findAddressByCep';

import placeholder from '../../assets/placeholder.png';
import dataURIToBlob from '../../helpers/fileReader';
import OpenStreetGeolocation from '../../helpers/openStreetGeoloc';
import Geolocation from '../../dto/geolocalization';
import Map from './Map';
import PartnersCategories from '../../dto/partnersCategories';
import TextAreaInput from '../../components/TextAreaInput';
import removeObjectField, { removeSomeObjectFields } from '../../helpers/removeObjectField';
import PriceInput from '../../components/PriceInput';

type PartnerAddress = {
  accreditedId: string,
  id: number;
  addressStreet: string,
  addressNumber: string,
  addressComplement: string,
  addressNeighborhood: string,
  addressCity: string,
  addressState: string,
  addressZipcode: string,
  addressLat: string,
  addressLong: string,
  phone: string | null,
  whatsapp: string | null
  openHour: string,
  closeHour: string,
  satOpen: boolean,
  satOpenHour: string,
  satCloseHour: string,
  sunOpen: boolean,
  sunOpenHour: string,
  sunCloseHour: string
}

type Benefits = {
  id: number,
  accreditedId: string,
  description: string,
  finalPrice: string,
  discount: string,
  observation: string,
}

interface ICreateNewPartner {
  cpfCnpj: string,
  services: string,
  show: boolean,
  name: string,
  email: string,
  password: string,
  isActive: boolean,
  address: PartnerAddress[],
  benefits: Benefits[],
}

export default function NewPartner() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [file, setFile] = useState<string | null | ArrayBuffer>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [repeatPassword, setRepeatPassword] = useState('');
  const [fileRef] = useState(createRef<any>());
  const [_, setForceRefresh] = useState(false);
  const [categories, setCategories] = useState<PartnersCategories[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [partner, setPartner] = useState<ICreateNewPartner>({
    cpfCnpj: '',
    name: '',
    email: '',
    password: '',
    show: true,
    services: '',
    address: [{
      accreditedId: '0',
      id: 1,
      addressStreet: '',
      addressNumber: '',
      addressComplement: '',
      addressNeighborhood: '',
      addressCity: '',
      addressState: '',
      addressZipcode: '',
      addressLat: '-5.51847',
      addressLong: '-47.4777',
      phone: '',
      whatsapp: '',
      openHour: '00:00',
      closeHour: '00:00',
      satOpen: false,
      satOpenHour: '00:00',
      satCloseHour: '00:00',
      sunOpen: false,
      sunOpenHour: '00:00',
      sunCloseHour: '00:00',
    }],
    benefits: [],
    isActive: true,
  });

  function createFormData(id: string) {
    const body = new FormData();
    const blob = file === null ? dataURIToBlob(placeholder) : dataURIToBlob(file! as string);
    body.append('id', id);
    body.append('file', blob);
    return body;
  }

  function createObjectURL(data: React.ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();
    reader.readAsDataURL(data.target.files![0]);
    reader.onload = (ctx) => {
      if (ctx.loaded / 1024 < 4000) {
        setFile(ctx.target!.result);
      } else {
        setModal({ title: 'Ocorreu um erro!', children: 'O arquivo selecionado ultrapassou o limite máximo de 4mb', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }
    };
  }

  function createNewAddressFields() {
    const newFields: PartnerAddress[] = [{
      accreditedId: '0',
      id: partner.address.length + 1,
      addressStreet: '',
      addressNumber: '',
      addressComplement: '',
      addressNeighborhood: '',
      addressCity: '',
      addressState: '',
      addressZipcode: '',
      addressLat: '-5.51847',
      addressLong: '-47.4777',
      phone: '',
      whatsapp: '',
      openHour: '00:00',
      closeHour: '00:00',
      satOpen: false,
      satOpenHour: '00:00',
      satCloseHour: '00:00',
      sunOpen: false,
      sunOpenHour: '00:00',
      sunCloseHour: '00:00',

    }];
    const newPartnerAddress = [...partner.address].concat(newFields);
    setPartner({ ...partner, address: newPartnerAddress });
  }

  function createNewABenefitsFields() {
    const newFields: Benefits[] = [{
      id: partner.benefits.length + 1,
      accreditedId: '0,00',
      description: '',
      finalPrice: '0,00',
      discount: '',
      observation: '',
    }];
    const newPartnerBenefits = [...partner.benefits].concat(newFields);
    setPartner({ ...partner, benefits: newPartnerBenefits });
  }

  function removeBenefitsField(id: number) {
    const filteredBenefits = partner.benefits.filter((b) => b.id !== id);
    setPartner({ ...partner, benefits: filteredBenefits });
  }

  function removeAddressField(id: number) {
    const filteredAddresses = partner.address.filter((a) => a.id !== id);
    setPartner({ ...partner, address: filteredAddresses });
  }

  function changeLatitudeInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        partner.address[i].addressLat = value;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeLongitudeInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        partner.address[i].addressLong = value;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeCepInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        partner.address[i].addressZipcode = maskCep(value);
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeStreetInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        partner.address[i].addressStreet = value;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeStateInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        partner.address[i].addressState = value;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeCityInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        partner.address[i].addressCity = value;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeNumberInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        partner.address[i].addressNumber = value;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeComplementInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        partner.address[i].addressComplement = value;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeNeighborhoodInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        partner.address[i].addressNeighborhood = value;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeWhatsAppInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        partner.address[i].whatsapp = maskPhone(value);
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changePhoneInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        partner.address[i].phone = maskPhone(value);
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeDailyHourInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        const splitted = partner.address[i].openHour.split(':');
        const splittedMinute = splitted[1];
        partner.address[i].openHour = `${value}:${splittedMinute}`;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeDailyMinuteInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        const splitted = partner.address[i].openHour.split(':');
        const splittedHour = splitted[0];
        const splittedMinute = splitted[1];
        partner.address[i].openHour = `${splittedHour}:${value}`;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeDailyCloseHourInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        const splitted = partner.address[i].closeHour.split(':');
        const splittedMinute = splitted[1];
        partner.address[i].closeHour = `${value}:${splittedMinute}`;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeDailyCloseMinuteInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        const splitted = partner.address[i].closeHour.split(':');
        const splittedHour = splitted[0];
        const splittedMinute = splitted[1];
        partner.address[i].closeHour = `${splittedHour}:${value}`;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeOpenedAtSaturdayInput(id: number, value: boolean) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        partner.address[i].satOpen = value;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeSaturdayHourInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        const splitted = partner.address[i].satOpenHour.split(':');
        const splittedMinute = splitted[1];
        partner.address[i].satOpenHour = `${value}:${splittedMinute}`;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeSaturdayMinuteInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        const splitted = partner.address[i].satOpenHour.split(':');
        const splittedHour = splitted[0];
        partner.address[i].satOpenHour = `${splittedHour}:${value}`;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeSaturdayCloseHourInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        const splitted = partner.address[i].satCloseHour.split(':');
        const splittedMinute = splitted[1];
        partner.address[i].satCloseHour = `${value}:${splittedMinute}`;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeSaturdayCloseMinuteInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        const splitted = partner.address[i].satCloseHour.split(':');
        const splittedHour = splitted[0];
        partner.address[i].satCloseHour = `${splittedHour}:${value}`;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeOpenedAtSundayInput(id: number, value: boolean) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        partner.address[i].sunOpen = value;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeSundayHourInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        const splitted = partner.address[i].sunOpenHour.split(':');
        const splittedMinute = splitted[1];
        partner.address[i].sunOpenHour = `${value}:${splittedMinute}`;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeSundayMinuteInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        const splitted = partner.address[i].sunOpenHour.split(':');
        const splittedHour = splitted[0];
        partner.address[i].sunOpenHour = `${splittedHour}:${value}`;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeSundayCloseHourInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        const splitted = partner.address[i].sunCloseHour.split(':');
        const splittedMinute = splitted[1];
        partner.address[i].sunCloseHour = `${value}:${splittedMinute}`;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeSundayCloseMinuteInput(id: number, value: string) {
    partner.address.forEach((address, i) => {
      if (address.id === id) {
        const splitted = partner.address[i].sunCloseHour.split(':');
        const splittedHour = splitted[0];
        partner.address[i].sunCloseHour = `${splittedHour}:${value}`;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeDescriptionInput(id: number, value: string) {
    partner.benefits.forEach((address, i) => {
      if (address.id === id) {
        partner.benefits[i].description = value;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeBenefitsFinalPriceInput(id: number, value: string) {
    partner.benefits.forEach((address, i) => {
      if (address.id === id) {
        partner.benefits[i].finalPrice = value;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeObservationInput(id: number, value: string) {
    partner.benefits.forEach((address, i) => {
      if (address.id === id) {
        partner.benefits[i].observation = value;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function changeBenefitsDiscountInput(id: number, value: string) {
    partner.benefits.forEach((address, i) => {
      if (address.id === id) {
        partner.benefits[i].discount = value;
        setForceRefresh((prev) => !prev);
      }
    });
  }

  function normalizeMinutesAndSecondsAndReturnPartner() {
    function normalize(time: string) {
      const splitted = time.split(':');
      let hour = splitted[0].length === 1 ? `0${splitted[0]}` : splitted[0];
      let minutes = splitted[1].length === 1 ? `0${splitted[1]}` : splitted[1];
      if (hour === '') {
        hour = '00';
      }
      if (minutes === '') {
        minutes = '00';
      }
      return `${hour}:${minutes}`;
    }
    partner.address.forEach((address, i) => {
      partner.address[i].openHour = normalize(partner.address[i].openHour);
      partner.address[i].closeHour = normalize(partner.address[i].closeHour);
      partner.address[i].satOpenHour = normalize(partner.address[i].satOpenHour);
      partner.address[i].satCloseHour = normalize(partner.address[i].satCloseHour);
      partner.address[i].sunOpenHour = normalize(partner.address[i].sunOpenHour);
      partner.address[i].sunCloseHour = normalize(partner.address[i].sunCloseHour);
    });
    return partner;
  }

  async function changeAddressByCep(id: number) {
    try {
      partner.address.forEach(async (address, i) => {
        if (address.id === id) {
          setLoading(true);
          const findCep = await new CEPFinder(unmaskData(address.addressZipcode)).findCep();
          if (findCep !== null) {
            partner.address[i].addressCity = findCep.city;
            partner.address[i].addressState = findCep.state;
            partner.address[i].addressNeighborhood = findCep.neighborhood;
            partner.address[i].addressStreet = findCep.street;
            setForceRefresh((prev) => !prev);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      });
    } catch (err: any) {
      setLoading(false);
    }
  }

  async function findLocation(id: number) {
    partner.address.forEach(async (address, i) => {
      if (address.id === id) {
        setLoading(true);
        const openStreet: Geolocation = new OpenStreetGeolocation(address.addressCity, address.addressStreet, address.addressNeighborhood, address.addressNumber);
        const location = await openStreet.findByAddress();
        if (location !== null) {
          partner.address[i].addressLat = location.latitude;
          partner.address[i].addressLong = location.longitude;
          setForceRefresh((prev) => !prev);
        } else {
          setLoading(false);
          setModal({ title: 'Erro ao buscar geolocalização', children: 'Não foi possível localizar coordenadas pelo endereço.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
        }
        setLoading(false);
      }
    });
  }

  async function openMap(lat: number, long: number) {
    setModal({ title: 'Ver no mapa', children: <Map lat={lat} long={long} />, actions: [{ label: 'Fechar', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
  }

  function checkAddress() {
    let isValid = false;
    partner.address.forEach((item) => {
      if (
        item.addressCity.length > 0
        && item.addressStreet.length > 0
        && item.addressNeighborhood.length > 0
        && item.addressNumber.length > 0
        && item.addressState.length > 0
        && item.addressLat.length > 0
        && item.addressLong.length > 0
        && item.addressZipcode.length >= 10
      ) {
        isValid = true;
      } else {
        isValid = false;
      }
    });
    return isValid;
  }

  function enabledButton() {
    if (partner.name.length > 0
        && EmailValidator.validate(partner.email)
        && validateCnpjOrCpf(partner.cpfCnpj)
        && partner.password.length > 5
        && partner.password === repeatPassword
        && selectedCategory !== ''
        && checkAddress()
    ) {
      return true;
    }
    return false;
  }

  async function fetchAllCategories() {
    try {
      setLoading(true);
      const response = await api.get('/admin/accredited/category/showallcategories', token);
      console.log('categorias', response.data);
      setCategories(response.data);
      if (response.data.length > 0) {
        setSelectedCategory(response.data[0].title);
      }
      setLoading(false);
    } catch (err) {
      setModal({ title: 'Ocorreu um erro!', children: 'Não foi possível buscar categorias. Adicione uma antes de adicionar um credenciado', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
    }
  }

  async function savePartner(): Promise<number | null> {
    try {
      const filteredCategory: PartnersCategories[] = categories.filter((item) => item.title === selectedCategory);
      if (filteredCategory.length > 0) {
        const response = await api.post(`/admin/accredited/${filteredCategory[0].id}`, removeObjectField(normalizeMinutesAndSecondsAndReturnPartner(), 'address'), token);
        return response.data.id;
      }
      return null;
    } catch (err: any) {
      console.log(err.response);
      if (typeof err.response !== 'undefined') {
        setModal({ title: 'Ocorreu um erro!', children: 'Não foi possível adicionar parceiro. Verifique todos campos e se o email do cliente já nao está cadastrado.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
        throw new Error(err.response);
      }
      return null;
    }
  }

  async function savePartnerAvatar(partnerId: number): Promise<boolean> {
    try {
      await api.post('/admin/accredited/logo/add', createFormData(partnerId.toString()), {
        headers: {
          'Content-Type': 'undefined',
          Authorization: `bearer ${localStorage.getItem('adminToken')}`,
        },
      });
      return true;
    } catch (err) {
      return false;
    }
  }

  async function savePartnerAddress(partnerId: number) {
    try {
      const normalizedPartnerAddress: PartnerAddress[] = partner.address.map((item) => ({ ...item, accreditedId: partnerId.toString() }));
      const promises = normalizedPartnerAddress.map((item) => api.post('/admin/accredited/address/add', item, token));
      await Promise.all(promises);
      return true;
    } catch (err) {
      console.log('err1', err);
      return false;
    }
  }

  async function saveBenefits(partnerId: number) {
    try {
      const normalizedPartnerBenefits: Benefits[] = partner.benefits.map((item) => ({ ...item, accreditedId: partnerId.toString() }));
      const promises = normalizedPartnerBenefits.map((item) => api.post('/admin/accredited/benefit/add', item, token));
      await Promise.all(promises);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async function sendForm() {
    try {
      setLoading(true);
      console.log(partner);
      const filteredCategory = categories.filter((item) => item.title === selectedCategory);
      console.log(filteredCategory);
      const id = await savePartner();
      if (id !== null) {
        const isAvatarSent = await savePartnerAvatar(id);
        const isAddressSaved = await savePartnerAddress(id);
        const isBenefitsSaved = await saveBenefits(id);

        if (!isAddressSaved) {
          setModal({ title: 'Ocorreu um erro', children: 'Não foi possível adicionar endereço ao credenciado', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
        }

        if (!isAvatarSent) {
          setModal({ title: 'Ocorreu um erro', children: 'Não foi possível adicionar uma foto ao credenciado', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
        }
        if (!isBenefitsSaved) {
          setModal({ title: 'Ocorreu um erro', children: 'Não foi possível adicionar benefícios ao credenciado', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
        }
      } else {
        setModal({ title: 'Ocorreu um erro', children: 'Não foi possível cadastrar credenciado', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }
      setLoading(false);
      navigate(-1);
    } catch (err: any) {
      setLoading(false);
      console.log('err');
    }
  }

  useEffect(() => {
    fetchAllCategories();
  }, []);

  return (
    <DefaultLayout
      pageTitle="Adicionar credenciado"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row partners card-bg p-0">
        <section className="row">
          <h1>Avatar do credenciado</h1>
          <div className="col-12 mb-4 d-flex justify-content-center align-items-center flex-column">
            <div className="partner-photo-wrapper">
              <img src={file === null ? placeholder : file! as string} alt="" />
            </div>
            <p className="mb-4 helper">Melhor proporção: 4/2. Ex: 400px / 200px</p>
            <input
              type="file"
              className="d-none"
              onChange={(data) => createObjectURL(data)}
              accept=".png, .jpeg, .jpg"
              ref={fileRef}
            />
            <ButtonTextIcon
              label="Selecionar imagem"
              type={BtnType.PRIMARY}
              icon="image"
              active
              onClick={() => fileRef.current.click()}
              ref={fileRef}
            />
          </div>
        </section>
      </div>

      <div className="row partners card-bg p-0 mt-4">
        <section className="row">
          <h1>Dados do credenciado</h1>
          <div className="col-4">
            <BasicOption
              id="categorias-input"
              label="Categoria"
              readOnly={false}
              helper=""
              error=""
              value={selectedCategory}
              onChange={(data) => setSelectedCategory(data)}
            >
              {categories.map((item) => item.title)}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicInput
              id="name-input"
              label="Nome"
              readOnly={false}
              helper="Ex: João Batista"
              error={partner.name.length < 1 ? 'Campo Obrigatório' : ''}
              value={partner.name}
              type="text"
              onChange={(data) => setPartner({ ...partner, name: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="cpf-cnpj-input"
              label="CPF / CNPJ"
              readOnly={false}
              helper="Ex: 000.000.000-10"
              error={!validateCnpjOrCpf(partner.cpfCnpj) ? 'Digite um CPF ou CNPJ válido.' : ''}
              value={partner.cpfCnpj}
              type="text"
              onChange={(data) => setPartner({ ...partner, cpfCnpj: maskCPForCNPJ(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="email-input"
              label="E-mail"
              readOnly={false}
              helper="Ex: joao@email.com"
              error={!EmailValidator.validate(partner.email) ? 'Insira um email válido' : ''}
              value={partner.email}
              type="text"
              onChange={(data) => setPartner({ ...partner, email: data })}
            />
          </div>
        </section>
      </div>

      <div className="row partners card-bg p-0 mt-4">
        <section className="row">
          <h1>Senha de acesso</h1>
          <div className="col-4">
            <BasicInput
              id="password-input"
              label="Senha"
              readOnly={false}
              helper="Mínimo 06 dígitos"
              error={partner.password.length < 6 ? 'Mínimo 06 dígitos' : ''}
              value={partner.password}
              type="password"
              onChange={(data) => setPartner({ ...partner, password: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="repeat-password-input"
              label="Repita a senha"
              readOnly={false}
              helper="Deve coincidir com a senha"
              error={repeatPassword !== partner.password ? 'Deve coincidir com a senha' : ''}
              value={repeatPassword}
              type="password"
              onChange={(data) => setRepeatPassword(data)}
            />
          </div>
        </section>
      </div>

      {partner.address.map((item, i) => (
        <div className="row partners card-bg p-0 mt-4">

          <section className="row">
            {item.id !== 1 ? (
              <div className="d-flex align-items-center mb-4">
                <h1 className="p-0 m-0">{`Endereço do credenciado - ${item.id}`}</h1>
                <div className="btn-card-small-danger" style={{ marginLeft: 24 }}>
                  <ButtonTextIcon
                    label="remover"
                    type={BtnType.DANGER}
                    icon="remove"
                    active
                    onClick={() => removeAddressField(item.id)}
                    ref={null}
                  />
                </div>

              </div>
            ) : (
              <h1>{`Endereço do credenciado - ${item.id}`}</h1>
            )}

            <div className="col-4">
              <AddInput
                id={`address-cep-input-${i}`}
                label="CEP"
                readOnly={false}
                helper="Ex: 35.171-120"
                error={item.addressZipcode.length < 10 ? 'Campo obrigatório' : ''}
                value={item.addressZipcode}
                type="text"
                onAdd={() => changeAddressByCep(item.id)}
                onChange={(data) => changeCepInput(item.id, data)}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id={`address-street-input-${i}`}
                label="Rua"
                readOnly={false}
                helper="Ex: Rua Três"
                error={item.addressStreet.length < 2 ? 'Campo Obrigatório' : ''}
                value={item.addressStreet}
                type="text"
                onChange={(data) => changeStreetInput(item.id, data)}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id={`address-number-input-${i}`}
                label="Número"
                readOnly={false}
                helper="Ex: 133"
                error={item.addressNumber.length < 1 ? 'Campo Obrigatório' : ''}
                value={item.addressNumber}
                type="text"
                onChange={(data) => changeNumberInput(item.id, data)}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id={`address-complement-input-${i}"`}
                label="Complemento"
                readOnly={false}
                helper="Ex: Bloco A"
                error=""
                value={item.addressComplement}
                type="text"
                onChange={(data) => changeComplementInput(item.id, data)}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id={`address-neighborhood-input-${i}`}
                label="Bairro"
                readOnly={false}
                helper="Centro"
                error={item.addressNeighborhood.length < 1 ? 'Campo obrigatório' : ''}
                value={item.addressNeighborhood}
                type="text"
                onChange={(data) => changeNeighborhoodInput(item.id, data)}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id={`address-city-input-${i}`}
                label="Cidade"
                readOnly
                helper="Ex: Imperatriz"
                error={item.addressCity.length < 1 ? 'Campo obrigatório' : ''}
                value={item.addressCity}
                type="text"
                onChange={(data) => changeCityInput(item.id, data)}
              />
            </div>
            <div className="col-4">
              <BasicOption
                id={`address-state-input-${i}`}
                label="Estado"
                readOnly
                helper=""
                error=""
                value={item.addressState}
                onChange={(data) => changeStateInput(item.id, data)}
              >
                {statesOfBrazil}
              </BasicOption>
            </div>
            <div className="col-4">
              <BasicInput
                id={`phone-input-${i}`}
                label="Telefone"
                readOnly={false}
                helper="Ex: (31) 99999-9999"
                error=""
                value={item.phone || ''}
                type="text"
                onChange={(data) => changePhoneInput(item.id, data)}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id={`whatsapp-input-${i}`}
                label="WhatsApp"
                readOnly={false}
                helper="Ex: (31) 99999-9999"
                error=""
                value={item.whatsapp || ''}
                type="text"
                onChange={(data) => changeWhatsAppInput(item.id, data)}
              />
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <BasicInput
                    id={`Abertura-hora-${i}`}
                    label="Abertura (Hora)"
                    readOnly={false}
                    helper="Ex: 07"
                    error=""
                    value={item.openHour.split(':')[0]}
                    type="text"
                    onChange={(data) => changeDailyHourInput(item.id, maskHour(data))}
                  />
                </div>
                <div className="col-3">
                  <BasicInput
                    id={`Abertura-minutos-${i}`}
                    label="Abertura (Minuto)"
                    readOnly={false}
                    helper="Ex: 30"
                    error=""
                    value={item.openHour.split(':')[1]}
                    type="text"
                    onChange={(data) => changeDailyMinuteInput(item.id, maskMinuteAndSeconds(data))}
                  />
                </div>
                <div className="col-3">
                  <BasicInput
                    id={`Fechamento-hora-${i}`}
                    label="Fechamento (Hora)"
                    readOnly={false}
                    helper="Ex: 18"
                    error=""
                    value={item.closeHour.split(':')[0]}
                    type="text"
                    onChange={(data) => changeDailyCloseHourInput(item.id, maskHour(data))}
                  />
                </div>
                <div className="col-3">
                  <BasicInput
                    id={`Fechamento-minutos-${i}`}
                    label="Fechamento (Minuto)"
                    readOnly={false}
                    helper="Ex: 30"
                    error=""
                    value={item.closeHour.split(':')[1]}
                    type="text"
                    onChange={(data) => changeDailyCloseMinuteInput(item.id, maskMinuteAndSeconds(data))}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 allow-modifications mb-4">
                <input
                  type="checkbox"
                  onChange={() => changeOpenedAtSaturdayInput(item.id, !item.satOpen)}
                  checked={item.satOpen}
                />
                <p>Abre aos no Sábados</p>
              </div>
              {item.satOpen && (
              <>
                <div className="col-3">
                  <BasicInput
                    id={`Abertura-hora-sábado-${i}`}
                    label="Abertura Sábado (Hora)"
                    readOnly={false}
                    helper="Ex: 07"
                    error=""
                    value={item.satOpenHour.split(':')[0]}
                    type="text"
                    onChange={(data) => changeSaturdayHourInput(item.id, maskHour(data))}
                  />
                </div>
                <div className="col-3">
                  <BasicInput
                    id={`Abertura-minutos-sábado-${i}`}
                    label="Abertura Sábado (Minuto)"
                    readOnly={false}
                    helper="Ex: 30"
                    error=""
                    value={item.satOpenHour.split(':')[1]}
                    type="text"
                    onChange={(data) => changeSaturdayMinuteInput(item.id, maskMinuteAndSeconds(data))}
                  />
                </div>
                <div className="col-3">
                  <BasicInput
                    id={`Fechamento-hora-sábado-${i}`}
                    label="Fechamento Sábado (Hora)"
                    readOnly={false}
                    helper="Ex: 18"
                    error=""
                    value={item.satCloseHour.split(':')[0]}
                    type="text"
                    onChange={(data) => changeSaturdayCloseHourInput(item.id, maskHour(data))}
                  />
                </div>
                <div className="col-3">
                  <BasicInput
                    id={`Fechamento-minutos-sábado-${i}`}
                    label="Fechamento Sábado (Minuto)"
                    readOnly={false}
                    helper="Ex: 30"
                    error=""
                    value={item.satCloseHour.split(':')[1]}
                    type="text"
                    onChange={(data) => changeSaturdayCloseMinuteInput(item.id, maskMinuteAndSeconds(data))}
                  />
                </div>
              </>
              )}
              <div className="col-12 allow-modifications mb-4">
                <input
                  type="checkbox"
                  onChange={() => changeOpenedAtSundayInput(item.id, !item.sunOpen)}
                  checked={item.sunOpen}
                />
                <p>Abre aos Domingos</p>
              </div>
              {item.sunOpen && (
              <>
                <div className="col-3">
                  <BasicInput
                    id={`Abertura-hora-domingo-${i}`}
                    label="Abertura Domingo (Hora)"
                    readOnly={false}
                    helper="Ex: 07"
                    error=""
                    value={item.sunOpenHour.split(':')[0]}
                    type="text"
                    onChange={(data) => changeSundayHourInput(item.id, maskHour(data))}
                  />
                </div>
                <div className="col-3">
                  <BasicInput
                    id={`Abertura-minutos-domingo-${i}`}
                    label="Abertura Domingo (Minuto)"
                    readOnly={false}
                    helper="Ex: 30"
                    error=""
                    value={item.sunOpenHour.split(':')[1]}
                    type="text"
                    onChange={(data) => changeSundayMinuteInput(item.id, maskMinuteAndSeconds(data))}
                  />
                </div>
                <div className="col-3">
                  <BasicInput
                    id={`Fechamento-hora-domingo-${i}`}
                    label="Fechamento Domingo (Hora)"
                    readOnly={false}
                    helper="Ex: 18"
                    error=""
                    value={item.sunCloseHour.split(':')[0]}
                    type="text"
                    onChange={(data) => changeSundayCloseHourInput(item.id, maskHour(data))}
                  />
                </div>
                <div className="col-3">
                  <BasicInput
                    id={`Fechamento-minutos-domingo-${i}`}
                    label="Fechamento Domingo (Minuto)"
                    readOnly={false}
                    helper="Ex: 30"
                    error=""
                    value={item.sunCloseHour.split(':')[1]}
                    type="text"
                    onChange={(data) => changeSundayCloseMinuteInput(item.id, maskMinuteAndSeconds(data))}
                  />
                </div>

              </>
              )}
            </div>
            <div className="col-12 mb-4 mt-4">
              <div className="col-2">
                <ButtonTextIcon
                  label="Buscar coordenadas"
                  type={BtnType.PRIMARY}
                  icon="place"
                  active={item.addressCity.length > 0 && item.addressNeighborhood.length > 0 && item.addressStreet.length > 0}
                  onClick={() => findLocation(item.id)}
                  ref={null}
                />
              </div>
            </div>
            <div className="col-4">
              <BasicInput
                id={`latitude-input-${i}`}
                label="Latitude"
                readOnly={false}
                helper="Ex: 42.0000"
                error=""
                value={item.addressLat}
                type="text"
                onChange={(data) => changeLatitudeInput(item.id, data)}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id={`longitude-input-${i}`}
                label="Longitude"
                readOnly={false}
                helper="Ex: -19.420000"
                error=""
                value={item.addressLong}
                type="text"
                onChange={(data) => changeLongitudeInput(item.id, data)}
              />
            </div>
            <div className="col-12 d-flex align-items-center">
              <ButtonTextIcon
                label="Mostrar no mapa"
                type={BtnType.SECONDARY}
                icon="map"
                active={item.addressLat.length > 0 && item.addressLong.length > 0}
                onClick={() => openMap(Number(item.addressLat), Number(item.addressLong))}
                ref={null}
              />
            </div>
          </section>
        </div>
      ))}

      <div className="col-12 mt-2 mb-4 p-0">
        <ButtonTextIcon
          label="Adicionar endereço"
          type={BtnType.SECONDARY}
          icon="add"
          active
          onClick={() => createNewAddressFields()}
          ref={null}
        />
      </div>

      <div className="row partners card-bg p-0 mt-4">
        <section className="row">
          <h1>Serviços</h1>
          <div className="col-12">
            <TextAreaInput
              id="servicos-input"
              label="Digite abaixo"
              readOnly={false}
              helper="Ex: 20% Desconto em consulta com especialista"
              error=""
              value={partner.services}
              onChange={(data) => setPartner({ ...partner, services: data })}
            />
          </div>
        </section>
      </div>

      {partner.benefits.map((item, i) => (
        <div className="row partners card-bg p-0 mt-4">
          <section className="row">
            {item.id !== 0 ? (
              <div className="d-flex align-items-center mb-4">
                <h1 className="p-0 m-0">{`Benefício - ${item.id}`}</h1>
                <div className="btn-card-small-danger" style={{ marginLeft: 24 }}>
                  <ButtonTextIcon
                    label="remover"
                    type={BtnType.DANGER}
                    icon="remove"
                    active
                    onClick={() => removeBenefitsField(item.id)}
                    ref={null}
                  />
                </div>

              </div>
            ) : (
              <h1>{`Benefício - ${item.id}`}</h1>
            )}
            <div className="col-4">
              <BasicInput
                id={`description-input-${i}`}
                label="Descrição"
                readOnly={false}
                helper="Ex: Óculos de sol"
                error=""
                value={item.description}
                type="text"
                onChange={(data) => changeDescriptionInput(item.id, data)}
              />
            </div>
            {/* <div className="col-4">
              <PriceInput
                id={`preconormal-input-${i}`}
                label="Preço particular praticado"
                readOnly={false}
                helper="Ex: R$ 280,00"
                error=""
                value={item.currentPrice}
                type="number"
                onChange={(data) => changeCurrentPriceInput(item.id, data)}
              />
            </div> */}

            <div className="col-4">
              <PriceInput
                id={`desconto-input-${i}`}
                label="% desconto redesanta"
                readOnly={false}
                helper="Ex: 20%"
                error=""
                icon="%"
                value={item.discount}
                type="number"
                onChange={(data) => changeBenefitsDiscountInput(item.id, data)}
              />
            </div>
            <div className="col-4">
              <PriceInput
                id={`finalprice-input-${i}`}
                label="Valor final redesanta"
                readOnly={false}
                helper="Ex: 260,00"
                error=""
                value={item.finalPrice}
                type="number"
                onChange={(data) => changeBenefitsFinalPriceInput(item.id, data)}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id={`observation-input-${i}`}
                label="Observação"
                readOnly={false}
                helper=""
                error=""
                value={item.observation}
                type="text"
                onChange={(data) => changeObservationInput(item.id, data)}
              />
            </div>
          </section>
        </div>
      ))}

      <div className="col-12 mt-2 mb-4 p-0">
        <ButtonTextIcon
          label="Adicionar benefício"
          type={BtnType.SECONDARY}
          icon="add"
          active
          onClick={() => createNewABenefitsFields()}
          ref={null}
        />
      </div>

      <div className="col-12 mt-2 mb-4 p-4 card-bg allow-modifications">
        <input
          type="checkbox"
          onChange={() => setPartner({ ...partner, show: !partner.show })}
          checked={partner.show}
        />
        <p>Exibir no site (www.redesanta.com/beneficios)</p>
      </div>

      <div className="col-12 footer mt-4">
        <ButtonTextIcon
          label="salvar"
          type={BtnType.PRIMARY}
          icon="backup"
          active={enabledButton()}
          onClick={() => sendForm()}
          ref={null}
        />
      </div>
    </DefaultLayout>
  );
}
