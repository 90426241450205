/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { IModalContent } from '../StratificationModal';

interface ICityProps {
    props: IModalContent | null
}
export default function City({
  props,
}: ICityProps) {
  const [changeSliderMovement, setChangeSliderMovement] = useState(false);
  function calculatePercentage(total: number, target: number) {
    const result = (target * 100) / total;
    return result;
  }

  useEffect(() => {
    setTimeout(() => {
      setChangeSliderMovement(true);
    }, 200);
  }, []);
  return (
    <>
      <h3 className="fw-bold text-center mt-4 text-uppercase" style={{ color: '#616161' }}>
        RESIDENCIA - Quantitativo geral
      </h3>
      <div className="item-container">
        <p className="fw-bold">
          Do total de
          {' '}
          {props!.holdersAmount + props!.dependentsAmount}
          {' '}
          usuários cadastrados no sistema dentre as datas.
        </p>
        <div className="item-wrapper">
          <p className="item-description">
            Imperatriz:
            {' '}
            <span>
              {props!.totalfromImperatriz}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalfromImperatriz).toFixed(2)}
              %
            </span>
          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalfromImperatriz)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            AÇAILANDIA:
            {' '}
            <span>
              {props ? props.totalfromAcailandia : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalfromAcailandia).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalfromAcailandia)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            aguiarnópolis:
            {' '}
            <span>
              {props ? props.totalfromAguiarnopolis : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalfromAguiarnopolis).toFixed(2)}
              %
            </span>
          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalfromAguiarnopolis)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            gov. edson lobão:
            {' '}
            <span>
              {props ? props.totalfromGovEdsonLobao : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalfromGovEdsonLobao).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalfromGovEdsonLobao)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            joão lisboa:
            {' '}
            <span>
              {props ? props.totalfromJoaoLisboa : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalfromJoaoLisboa).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalfromJoaoLisboa)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            araguatins:
            {' '}
            <span>
              {props ? props.totalfromAraguatins : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalfromAraguatins).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalfromAraguatins)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            Demais localidades:
            {' '}
            <span>
              {props ? props.totalfromAnotherCities : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalfromAnotherCities).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalfromAnotherCities)}%` : 0 }} />
          </div>
        </div>
      </div>

      <h3 className="fw-bold text-center mt-4 text-uppercase" style={{ color: '#616161' }}>
        RESIDENCIA - Apenas titulares
      </h3>
      <div className="item-container">
        <p className="fw-bold">
          Do total de
          {' '}
          {props!.holdersAmount}
          {' '}
          titulares cadastrados no sistema dentre as datas.
        </p>
        <div className="item-wrapper">
          <p className="item-description">
            Imperatriz:
            {' '}
            <span>
              {props!.holderfromImperatriz}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount, props!.holderfromImperatriz).toFixed(2)}
              %
            </span>
          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount, props!.holderfromImperatriz)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            AÇAILANDIA:
            {' '}
            <span>
              {props ? props.holderfromAcailandia : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount, props!.holderfromAcailandia).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount, props!.holderfromAcailandia)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            aguiarnópolis:
            {' '}
            <span>
              {props ? props.holderfromAguiarnopolis : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount, props!.holderfromAguiarnopolis).toFixed(2)}
              %
            </span>
          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount, props!.holderfromAguiarnopolis)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            gov. edson lobão:
            {' '}
            <span>
              {props ? props.holderfromGovEdsonLobao : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount, props!.holderfromGovEdsonLobao).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount, props!.holderfromGovEdsonLobao)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            joão lisboa:
            {' '}
            <span>
              {props ? props.holderfromJoaoLisboa : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount, props!.holderfromJoaoLisboa).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount, props!.holderfromJoaoLisboa)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            araguatins:
            {' '}
            <span>
              {props ? props.holderfromAraguatins : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount, props!.holderfromAraguatins).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount, props!.holderfromAraguatins)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            Demais localidades:
            {' '}
            <span>
              {props ? props.holderfromAnotherCities : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount, props!.holderfromAnotherCities).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount, props!.holderfromAnotherCities)}%` : 0 }} />
          </div>
        </div>
      </div>

      <h3 className="fw-bold text-center mt-4 text-uppercase" style={{ color: '#616161' }}>
        RESIDENCIA - Apenas Dependentes
      </h3>
      <div className="item-container">
        <p className="fw-bold">
          Do total de
          {' '}
          {props!.dependentsAmount}
          {' '}
          dependentes cadastrados no sistema dentre as datas.
        </p>
        <div className="item-wrapper">
          <p className="item-description">
            Imperatriz:
            {' '}
            <span>
              {props!.dependentfromImperatriz}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentfromImperatriz).toFixed(2)}
              %
            </span>
          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentfromImperatriz)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            AÇAILANDIA:
            {' '}
            <span>
              {props ? props.dependentfromAcailandia : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentfromAcailandia).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentfromAcailandia)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            aguiarnópolis:
            {' '}
            <span>
              {props ? props.dependentfromAguiarnopolis : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentfromAguiarnopolis).toFixed(2)}
              %
            </span>
          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentfromAguiarnopolis)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            gov. edson lobão:
            {' '}
            <span>
              {props ? props.dependentfromGovEdsonLobao : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentfromGovEdsonLobao).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentfromGovEdsonLobao)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            joão lisboa:
            {' '}
            <span>
              {props ? props.dependentfromJoaoLisboa : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentfromJoaoLisboa).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentfromJoaoLisboa)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            araguatins:
            {' '}
            <span>
              {props ? props.dependentfromAraguatins : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentfromAraguatins).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentfromAraguatins)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            Demais localidades:
            {' '}
            <span>
              {props ? props.dependentfromAnotherCities : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentfromAnotherCities).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentfromAnotherCities)}%` : 0 }} />
          </div>
        </div>
      </div>
    </>
  );
}
