/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import PriceInput from '../../../components/PriceInput';
import api from '../../../config/axios';
import { FreelancerSellersProps } from '../../../dto/freelancersSellers';
import { Indicators } from '../../../dto/indicators';
import { ProductsProps } from '../../../dto/product';
import { Reffer } from '../../../dto/reffers';
import SellerProps from '../../../dto/seller';
import { showIfDateOfParamsOneMajorThenParamsTwo } from '../../../helpers/dates';
import { maskDate } from '../../../helpers/inputMasks.js';
import DefaultLayout from '../../../Layout';
import { ClientPFProps, ContractPFProps } from './types';
import TextAreaInput from '../../../components/TextAreaInput';

export default function RenewContractPF() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [reffers, setReffers] = useState<Reffer[]>([]);
  const [freelancers, setFreelancers] = useState<FreelancerSellersProps[]>([]);
  const [indicators, setIndicators] = useState<Indicators[]>([]);
  const [selectedReffer, setSelectedReffer] = useState<Reffer | null>(null);
  const [products, setProducts] = useState<ProductsProps[]>([]);
  const [selectedClient, setSelectedClient] = useState<ClientPFProps | null>(null);
  const [selectedSeller, setSelectedSeller] = useState<SellerProps | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<ProductsProps | null>(null);
  const [selectedIndicator, setSelectedIndicator] = useState<Indicators | null>(null);
  const [selectedFreelancer, setSelectedFreelancer] = useState<FreelancerSellersProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [contract, setContract] = useState<ContractPFProps>({
    id: 0,
    uuid: '0',
    sellerId: 0,
    holderId: 0,
    holder: { name: '', blockPerma: false },
    productId: 0,
    refferId: null,
    freelancerId: null,
    indicationId: null,
    totalDependentsEnabled: 0,
    totalLifes: 0,
    pricePerHolder: 0,
    pricePerDependent: 0,
    contractGlobalValue: 0,
    signatureDate: '',
    dueDate: '',
    isActive: false,
    payment: [],
    files: [],
    blockHistory: [],
  });
  const [aditionalFields, setAditionalFields] = useState({
    observation: '',
    approvalDate: '',
    discount: 0,
    aditional: 0,
    totalValue: 0,
  });

  async function fetchProducts() {
    try {
      const response = await api.get('/admin/product', token);
      setProducts(response.data);
      setLoading(false);
    } catch (err: any) {
      setShowToastError(true);
    }
  }

  async function fetchReffers(contractCtx: ContractPFProps) {
    try {
      setLoading(true);
      const response = await api.get('/admin/reffer/load/active', token);
      const refferCtx: Reffer[] = [];

      if (typeof contractCtx.reffer !== 'undefined') {
        if (!contractCtx.reffer.isActive) {
          refferCtx.push(contractCtx.reffer);
          response.data.forEach((item: Reffer) => {
            refferCtx.push(item);
          });
          setReffers(refferCtx);
        } else {
          setReffers(response.data);
        }
      } else {
        setReffers(response.data);
      }
      setLoading(false);
    } catch (err: any) {
      throw new Error('');
    }
  }

  async function fetchIndication(contractCtx: ContractPFProps) {
    try {
      setLoading(true);
      const response = await api.get('/admin/indication/load/active', token);
      const indicationCtx: Indicators[] = [];
      console.log('indication', response.data);

      if (typeof contractCtx.indication !== 'undefined') {
        if (!contractCtx.indication.isActive) {
          indicationCtx.push(contractCtx.indication);
          response.data.forEach((item: Indicators) => {
            indicationCtx.push(item);
          });
          setIndicators(indicationCtx);
        } else {
          setIndicators(response.data);
        }
      } else {
        setIndicators(response.data);
      }
      setLoading(false);
    } catch (err: any) {
      throw new Error('');
    }
  }

  async function fetchFreelancer(contractCtx: ContractPFProps) {
    try {
      setLoading(true);
      const response = await api.get('/admin/freelancer/load/active', token);
      const freelancerCtx: FreelancerSellersProps[] = [];

      if (typeof contractCtx.freelancer !== 'undefined') {
        if (!contractCtx.freelancer.isActive) {
          freelancerCtx.push(contractCtx.freelancer);
          response.data.forEach((item: FreelancerSellersProps) => {
            freelancerCtx.push(item);
          });
          setFreelancers(freelancerCtx);
        } else {
          setFreelancers(response.data);
        }
      } else {
        setFreelancers(response.data);
      }
      setLoading(false);
    } catch (err: any) {
      throw new Error('');
    }
  }

  async function fetchContract() {
    try {
      setLoading(true);
      const response = await api.get(`/admin/contract/pf/${id}`, token);
      const responseSeller = await api.get(`/admin/seller/${response.data.sellerId}`, token);
      const responseProduct = await api.get(`/admin/product/${response.data.productId}`, token);
      const responseHolder = await api.get(`/admin/holder-pf/${response.data.holderId}`, token);
      await fetchProducts();
      await fetchReffers(response.data);
      await fetchIndication(response.data);
      await fetchFreelancer(response.data);
      setSelectedProduct(responseProduct.data);
      setSelectedSeller(responseSeller.data);
      setSelectedClient(responseHolder.data);
      setContract({ ...response.data, signatureDate: '', dueDate: '' });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.DANGER }] });
    }
  }

  function filterRefferByName(name: string, ctx: Reffer[]) {
    const filteredReffer = ctx.filter((item) => item.name === name);
    if (filteredReffer.length > 0) {
      setSelectedReffer(filteredReffer[0]);
      setContract({ ...contract, refferId: filteredReffer[0].id });
    } else {
      setSelectedReffer(null);
      setContract({ ...contract, refferId: null });
    }
  }

  function filterIndicatorByName(name: string, ctx: Indicators[]) {
    const filteredIndicator = ctx.filter((item) => item.name === name);
    if (filteredIndicator.length > 0) {
      setSelectedIndicator(filteredIndicator[0]);
      setContract({ ...contract, indicationId: filteredIndicator[0].id });
    } else {
      setSelectedIndicator(null);
      setContract({ ...contract, indicationId: null });
    }
  }

  function filterProductByName(name: string, ctx: ProductsProps[]) {
    const filteredProduct = ctx.filter((item) => item.name === name);
    if (filteredProduct.length > 0) {
      setSelectedProduct(filteredProduct[0]);
      setContract({
        ...contract,
        productId: filteredProduct[0].id,
        pricePerHolder: filteredProduct[0].pricePerHolder,
        pricePerDependent: filteredProduct[0].pricePerDependent,
        contractGlobalValue: (filteredProduct[0].pricePerHolder + (filteredProduct[0].pricePerDependent * Number(contract.totalDependentsEnabled))) * 12,
      });
    }
  }

  function filterFreelancerByName(name: string, ctx: FreelancerSellersProps[]) {
    const filteredFreelancer = ctx.filter((item) => item.name === name);
    if (filteredFreelancer.length > 0) {
      setSelectedFreelancer(filteredFreelancer[0]);
      setContract({ ...contract, freelancerId: filteredFreelancer[0].id });
    } else {
      setSelectedFreelancer(null);
      setContract({ ...contract, freelancerId: null });
    }
  }

  function showIndicatorById(ctxId: number | null) {
    const filtered = indicators.filter((item) => item.id === ctxId);
    if (filtered.length > 0) {
      return filtered[0].name;
    }
    return 'Nenhum';
  }

  function showFreelancerById(ctxId: number | null) {
    const filtered = freelancers.filter((item) => item.id === ctxId);
    if (filtered.length > 0) {
      return filtered[0].name;
    }
    return 'Nenhum';
  }

  function showRefferById(ctxId: number | null) {
    const filtered = reffers.filter((item) => item.id === ctxId);
    if (filtered.length > 0) {
      return filtered[0].name;
    }
    return 'Nenhum';
  }

  function changeNumberOfDependent(dependentParams: number) {
    const globalContract = (dependentParams * contract.pricePerDependent) + contract.pricePerHolder;
    setContract({ ...contract, totalDependentsEnabled: dependentParams, contractGlobalValue: Number((globalContract * 12).toFixed(2)) });
  }

  function changePricePerHolder(price: number) {
    const globalContract = price + (contract.totalDependentsEnabled * contract.pricePerDependent);
    setContract({ ...contract, pricePerHolder: price, contractGlobalValue: Number((globalContract * 12).toFixed(2)) });
  }

  function changePricePerDependent(price: number) {
    const globalContract = (contract.totalDependentsEnabled * price) + contract.pricePerHolder;
    setContract({ ...contract, pricePerDependent: price, contractGlobalValue: Number((globalContract * 12).toFixed(2)) });
  }

  function showDueDateErrors() {
    if (showIfDateOfParamsOneMajorThenParamsTwo(contract.signatureDate, contract.dueDate)) {
      return 'A data de vencimento deverá ser maior que a data de assinatura';
    } if (contract.dueDate.length < 10) {
      return 'Digite uma data válida';
    }
    return '';
  }

  async function renewContract() {
    try {
      setLoading(true);
      const response = await api.post('/admin/contract/pf/renew-contract', {
        ...contract,
        ...aditionalFields,
        totalValue: contract.contractGlobalValue,
        contractPfId: id,
      }, token);
      setLoading(false);
      setModal({ title: 'Tudo certo!', children: 'Contrato renovado com sucesso!', actions: [{ label: 'Ok, entendi', onClick: () => navigate(`/contratos/pf/forma-de-pagamento/visualizar/${response.data.paymentId}`), type: ModalActionType.PRIMARY }] });
    } catch (err: any) {
      console.log('erro renovacao', err.response.data);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Ocorreu um erro ao renovar contrato', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  //   function checkPermission() {
  //     if (admin.id === 2 || admin.id === 3 || admin.id === 4 || admin.id === 18 || admin.id === 9 || admin.id === 7 || admin.id === 63 || admin.id === 9) {
  //       return true;
  //     }
  //     return false;
  //   }

  useEffect(() => {
    fetchContract();
  }, []);

  const definitiveReffersArray: any[] = [];
  const definitiveIndicatorArray: any[] = [];
  const definitiveFreelancerArray: any[] = [];

  return (
    <DefaultLayout
      pageTitle="Visualizar contrato PF"
      loading={loading}
      showToastSuccess={false}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products mt-4">
        <div className="col-12 card-bg p-4">
          <div className="row">
            <h1>Renovar contrato PF</h1>
            <div className="col-4">
              <BasicInput
                id="vendedor-inout"
                label="Vendedor"
                readOnly={false}
                helper="Quem intermediou o contrato"
                error=""
                value={selectedSeller !== null ? selectedSeller.name : 'Nenhum vendedor encontrado'}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="client-PF-input"
                label="Cliente PF"
                readOnly={false}
                helper="Selecione um cliente titular"
                error=""
                value={selectedClient !== null ? selectedClient.name : 'Nenhum cliente encontrado'}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-4">
              <BasicOption
                id="product-selected-input"
                label="Produto escolhido"
                readOnly={false}
                helper="Selecione o produto"
                error=""
                value={selectedProduct !== null ? selectedProduct.name : 'Nenhum produto encontrado'}
                onChange={(data) => filterProductByName(data, products)}
              >
                {products !== null ? products.map((item) => item.name) : ['Nenhum produto encontrado']}
              </BasicOption>
            </div>
            {reffers.length > 0 ? (
              <div className="col-4">
                <BasicOption
                  id="representante-input"
                  label="Embaixador master"
                  readOnly={false}
                  helper=""
                  error=""
                  value={showRefferById(contract.refferId)}
                  onChange={(data) => filterRefferByName(data, reffers)}
                >
                  {definitiveReffersArray.concat(reffers).concat('Nenhum').map((item) => (typeof item.name !== 'undefined' ? item.name : 'Nenhum'))}
                </BasicOption>
              </div>
            ) : (
              <div className="col-4">
                <BasicOption
                  id="representante-input"
                  label="Embaixador master"
                  readOnly
                  helper=""
                  error=""
                  value="Nenhum cadastrado"
                  onChange={() => null}
                >
                  {['Nenhum cadastrado']}
                </BasicOption>
              </div>
            )}

            {indicators.length > 0 ? (
              <div className="col-4">
                <BasicOption
                  id="indications-input"
                  label="Embaixador"
                  readOnly={false}
                  helper="Quem indicou o contrato"
                  error=""
                  value={showIndicatorById(contract.indicationId)}
                  onChange={(data) => filterIndicatorByName(data, indicators)}
                >
                  {
                    definitiveIndicatorArray.concat(indicators).concat('Nenhum').map((item) => (typeof item.name !== 'undefined' ? item.name : 'Nenhum'))
                  }
                </BasicOption>
              </div>
            ) : (
              <div className="col-4">
                <BasicOption
                  id="representante-input"
                  label="Embaixador"
                  readOnly
                  helper="Quem indicou o contrato"
                  error=""
                  value="Nenhum cadastrado"
                  onChange={() => null}
                >
                  {['Nenhum cadastrado']}
                </BasicOption>
              </div>
            )}

            {freelancers.length > 0 ? (
              <div className="col-4">
                <BasicOption
                  id="freelancer-input"
                  label="Representante"
                  readOnly={false}
                  helper=""
                  error=""
                  value={showFreelancerById(contract.freelancerId)}
                  onChange={(data) => filterFreelancerByName(data, freelancers)}
                >
                  {
                    definitiveFreelancerArray.concat(freelancers).concat('Nenhum').map((item) => (typeof item.name !== 'undefined' ? item.name : 'Nenhum'))
                  }
                </BasicOption>
              </div>
            ) : (
              <div className="col-4">
                <BasicOption
                  id="freelancer-input"
                  label="Representante"
                  readOnly
                  helper=""
                  error=""
                  value="Nenhum cadastrado"
                  onChange={() => null}
                >
                  {['Nenhum cadastrado']}
                </BasicOption>
              </div>
            )}

            <div className="col-4">
              <BasicInput
                id="total-vidas-input"
                label="Qtd. Vidas"
                readOnly={false}
                helper="Ex: 100"
                error={contract.totalLifes === 0 ? 'Mínimo de 1.' : ''}
                value={contract.totalLifes.toString()}
                type="number"
                onChange={(data) => setContract({ ...contract, totalLifes: Number(data) })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="total-dependentes-input"
                label="Qtd. Dependentes"
                readOnly={false}
                helper="Ex: 20"
                error={contract.totalDependentsEnabled + 1 > contract.totalLifes ? 'Ultrapassou o total de vidas' : ''}
                value={contract.totalDependentsEnabled.toString()}
                type="number"
                onChange={(data) => changeNumberOfDependent(Number(data))}
              />
            </div>
            <div className="col-4">
              <PriceInput
                id="preco-titular-input"
                label="Preço por titular"
                readOnly={false}
                helper="Ex: R$20"
                error={contract.pricePerHolder === 0 ? 'Adicione um preço por titular' : ''}
                value={contract.pricePerHolder.toString()}
                type="number"
                onChange={(data) => changePricePerHolder(Number(data))}
              />
            </div>
            <div className="col-4">
              <PriceInput
                id="preco-dependentes-input"
                label="Preço por dependente"
                readOnly={false}
                helper="Ex: R$20"
                error=""
                value={contract.pricePerDependent.toString()}
                type="number"
                onChange={(data) => changePricePerDependent(Number(data))}
              />
            </div>
            <div className="col-4">
              <PriceInput
                id="global-value-input"
                label="Valor global do contrato"
                readOnly={false}
                helper="Ex: R$20"
                error=""
                value={contract.contractGlobalValue.toFixed(2)}
                type="number"
                onChange={(data) => setContract({ ...contract, contractGlobalValue: Number(data) })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="date-start-input"
                label="Data de Início do contrato"
                readOnly={false}
                helper="Ex: 30-01-2022"
                error={contract.signatureDate.length < 10 ? 'Digite uma data válida' : ''}
                value={contract.signatureDate}
                type="text"
                onChange={(data) => setContract({ ...contract, signatureDate: maskDate(data) })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="date-start-input"
                label="Data de Vencimento do contrato"
                readOnly={false}
                helper="Ex: 30-01-2022"
                error={showDueDateErrors()}
                value={contract.dueDate}
                type="text"
                onChange={(data) => setContract({ ...contract, dueDate: maskDate(data) })}
              />
            </div>
            <div className="col-12">
              <TextAreaInput
                id="observation"
                label="Observação"
                readOnly={false}
                helper=""
                error=""
                value={aditionalFields.observation}
                onChange={(data) => setAditionalFields({ ...aditionalFields, observation: data })}
              />
            </div>
            <div className="col-12 mb-4">
              <p className="fw-bold">Dados do pagamento </p>
            </div>
            <div className="col-4">
              <BasicInput
                id="Data-aprovacao"
                label="Data aprovação"
                readOnly={false}
                helper="Ex: 30-01-2022"
                error={aditionalFields.approvalDate.length !== '00-00-0000'.length ? 'Digite uma data válida' : ''}
                value={aditionalFields.approvalDate}
                type="text"
                onChange={(data) => setAditionalFields({ ...aditionalFields, approvalDate: maskDate(data) })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="Valor total pgto."
                label="Valor total pgto."
                readOnly
                helper="Ex: 800,00"
                error=""
                value={contract.contractGlobalValue.toFixed(2)}
                type="text"
                onChange={(data) => setAditionalFields({ ...aditionalFields, totalValue: Number(data) })}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                active={contract.dueDate.length === '00-00-0000'.length && aditionalFields.approvalDate.length === '00-00-0000'.length && !showIfDateOfParamsOneMajorThenParamsTwo(contract.signatureDate, contract.dueDate)}
                onClick={() => renewContract()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
