/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import Cards from 'react-credit-cards';
import BasicInput from './BasicInput';
import BasicOption from './BasicOption';
import ButtonTextIcon, { BtnType } from './BtnIcon';
import PriceInput from './PriceInput';
import api from '../config/axios';
import { showFutureDateBasedOnMonths, showTodayDate } from '../helpers/dates';
import { maskCardExpiration, maskDate } from '../helpers/inputMasks.js';
import showCardPaymentWays, { normalizeToApiCardPaymentWays } from '../helpers/showCardMethods';
import showNonCardPaymentWays from '../helpers/showNonCardMethods';
import { PaymentGateway } from '../pages/PaymentTax/types';

type PaymentProps = {
    installmentsValue: number;
    discount: number;
    aditional: number;
    installments: number;
    totalValue: number;
    approvalDate: string;
}

export type InstallmentDTO = {
  contractPfPaymentId?: number
  contractPjPaymentId?: number
  dueDate: string
  status: string
  value: number
  paidBy: string
  paymentGatewayId: number
  nonCardTaxes: string | null
  card: boolean
  flag: string | null
  cardLastDigits: string | null
  cardPaymentOption: string | null
}

// eslint-disable-next-line no-shadow
enum PageStatus {
    LOADING,
    FORM,
    SUCCESS,
    ERROR
}

interface OwnProps {
    id: string
    paymentId: number
    isPf: boolean
    clientMyId?: string,
    contractId?: number,
}

export default function AddSubscription(props: OwnProps) {
  const [pageStatus, setPageStatus] = useState(PageStatus.FORM);
  const [error, setError] = useState<string[]>([]);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [method, setMethod] = useState('Crédito 1X');
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolder, setCardHolder] = useState('');
  const [valDates, setValDates] = useState('');
  const [cvv, setCvv] = useState('');
  const [value, setValue] = useState(0);
  const [exp, setExp] = useState('');

  function enabledBtn() {
    if (
      cardHolder.length > 0
      && cardNumber.length > 0
      && exp.length === 7
      && cvv.length > 0
      && value > 0
    ) {
      return true;
    }
    return false;
  }

  function resolveNumberOfInstallments() {
    const numInstallments = method.split(' ')[1].split('X')[0];
    return Number(numInstallments);
  }

  async function tryFromExistingCard() {
    try {
      setPageStatus(PageStatus.LOADING);
      await api.post('/admin/galaxpay/renew-from-exists-card', {
        id: props.clientMyId,
        contractPfId: props.contractId,
        paymentPfId: props.paymentId,
      }, token);
      setPageStatus(PageStatus.SUCCESS);
    } catch (err: any) {
      console.log(err.response.data);
      setError(['Pagamento negado']);
      setPageStatus(PageStatus.ERROR);
    }
  }

  async function tryFromExistingCardPJ() {
    try {
      setPageStatus(PageStatus.LOADING);
      await api.post('/admin/galaxpay/renew-from-exists-card-pj', {
        id: props.clientMyId,
        contractPjId: props.contractId,
        paymentPjId: props.paymentId,
      }, token);
      setPageStatus(PageStatus.SUCCESS);
    } catch (err: any) {
      console.log(err.response.data);
      setError(['Pagamento negado']);
      setPageStatus(PageStatus.ERROR);
    }
  }

  async function sendSubscription() {
    try {
      setPageStatus(PageStatus.LOADING);
      await api.post('admin/add-subscription', {
        holder: cardHolder,
        number: cardNumber,
        expiration: exp,
        cvv,
        method,
        monthlyValue: value,
        isPf: props.isPf,
        paymentId: props.paymentId,
      }, token);
      setPageStatus(PageStatus.SUCCESS);
    } catch (err: any) {
      console.log(err.response.data);
      setError(['Pagamento negado']);
      setPageStatus(PageStatus.ERROR);
    }
  }

  function onClose() {
    window.location.reload();
  }

  return (
    <div className="modal fade" id={props.id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Adicionar recorrência</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => onClose()} />
          </div>
          <div className="modal-body" style={{ minHeight: 200, minWidth: 200 }}>
            {pageStatus === PageStatus.ERROR && (
            <div className="d-flex flex-column align-items-center justify-content-center w-100" style={{ height: 200 }}>
              {error.map((item) => (
                <p className="fw-bold">{item}</p>
              ))}
            </div>
            )}
            {pageStatus === PageStatus.SUCCESS && (
            <div className="d-flex flex-column align-items-center justify-content-center w-100" style={{ height: 200 }}>
              <p className="fw-bold">Recorrencia criada com sucesso</p>
            </div>
            )}
            {pageStatus === PageStatus.LOADING && (
            <div className="d-flex flex-column align-items-center justify-content-center w-100" style={{ height: 200 }}>
              <p className="fw-bold">Carregando, aguarde</p>
            </div>
            )}
            {pageStatus === PageStatus.FORM && (
              <div className="accordion" id="accordionExample">
                <p className="p-2 mb-4" style={{ background: 'orange' }}>Atenção: Pagamentos com parcelas inferiores a R$5,00 irá gerar erro dentro da GalaxPay e o pagamento não será processado.</p>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Manualmente
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">

                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-12">
                          <Cards
                            cvc={cvv}
                            expiry={exp}
                            name={cardHolder}
                            number={cardNumber}
                            locale={{ valid: 'Valido até' }}
                            placeholders={{ name: 'NOME DO TITULAR' }}
                          />
                        </div>
                        <div className="col-12 mt-4">
                          <BasicInput
                            id="card-number"
                            label="Número do cartão"
                            readOnly={false}
                            helper=""
                            error={cardNumber.length < 1 ? 'Campo obrigatório' : ''}
                            value={cardNumber}
                            type="text"
                            onChange={(data) => setCardNumber(data)}
                          />
                        </div>
                        <div className="col-12 mt-4">
                          <BasicInput
                            id="card-name"
                            label="Titular"
                            readOnly={false}
                            helper=""
                            error={cardHolder.length < 1 ? 'Campo obrigatório' : ''}
                            value={cardHolder}
                            type="text"
                            onChange={(data) => setCardHolder(data)}
                          />
                        </div>
                        <div className="col-6 mt-4">
                          <BasicInput
                            id="card-exp"
                            label="Expiração"
                            readOnly={false}
                            helper="Ex: 02/2029"
                            error={exp.length !== 7 ? 'Formato da validade: DD/AAAA. Ex: 02/2024' : ''}
                            value={exp}
                            type="text"
                            onChange={(data) => setExp(maskCardExpiration(data))}
                          />
                        </div>
                        <div className="col-6 mt-4">
                          <BasicInput
                            id="card-cvv"
                            label="CVV"
                            readOnly={false}
                            helper="Ex: 203"
                            error={cvv.length < 1 ? 'Campo obrigatório' : ''}
                            value={cvv}
                            type="number"
                            onChange={(data) => setCvv(data)}
                          />
                        </div>
                        <div className="col-12 mt-4">
                          <BasicOption
                            id="opcoes pagamento"
                            label="Opçoes de pagamento"
                            readOnly={false}
                            helper=""
                            error=""
                            value={method}
                            onChange={(data) => setMethod(data)}
                          >
                            {['Crédito 1X', 'Crédito 2X', 'Crédito 3X', 'Crédito 4X', 'Crédito 5X', 'Crédito 6X', 'Crédito 7X', 'Crédito 8X', 'Crédito 9X', 'Crédito 10X', 'Crédito 11X', 'Crédito 12X', 'Crédito 13X', 'Crédito 14X', 'Crédito 15X', 'Crédito 16X', 'Crédito 17X', 'Crédito 18X', 'Crédito 19X', 'Crédito 20X', 'Crédito 21X', 'Crédito 22X', 'Crédito 23X', 'Crédito 24X']}
                          </BasicOption>
                        </div>
                        <div className="col-12 mt-4">
                          <BasicInput
                            id="total"
                            label="Valor mensal"
                            readOnly={false}
                            helper=""
                            error={value < 1 ? 'Digite um valor' : ''}
                            value={value.toString()}
                            type="number"
                            onChange={(data) => setValue(Number(data))}
                          />
                        </div>
                        <div className="col-12 mt-4">
                          <BasicInput
                            id="total vaue"
                            label="Total"
                            readOnly
                            helper=""
                            error={value < 1 ? 'Digite um valor' : ''}
                            value={(resolveNumberOfInstallments() * value).toString()}
                            type="number"
                            onChange={() => null}
                          />
                        </div>
                        <div className="modal-footer">
                          {
            pageStatus === PageStatus.FORM && (
              <button type="button" disabled={!enabledBtn()} className="btn btn-primary" onClick={() => sendSubscription()}>Gerar</button>
            )
          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      De um cartão já cadastrado na GalaxPay
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ButtonTextIcon
                        icon=""
                        label="Tentar com um cartão já cadastrado"
                        type={BtnType.PRIMARY}
                        active
                        onClick={() => {
                          if (props.isPf) {
                            tryFromExistingCard();
                          } else {
                            tryFromExistingCardPJ();
                          }
                        }}
                        ref={null}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
