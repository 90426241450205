import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalActionType, ModalProps } from '../../components/Modal';
import api from '../../config/axios';
import DefaultLayout from '../../Layout';

type CidadeProps = {
    cidade: string,
    quantidade: string
}

type ResponseProps = {
    cidades: {
        cidadesDependentes: CidadeProps[],
        cidadesTitulares: CidadeProps[],
    },
    dependentes: {
      totalPfDependenteSexoMasculino: string,
      totalPfDependenteSexoFeminino: string,
      totalPjDependenteSexoMasculino: string,
      totalPjDependenteSexoFeminino: string,
      totalDependente: string,
      totalDependentesEsposaPf: string,
      totalDependentesFilhoPf: string,
      totalDependentesOutroPf: string,
      totalDependentesEsposaPj: string,
      totalDependentesFilhoPj: string,
      totalDependentesOutroPj: string,
      totalDependentesEsposa: string,
      totalDependentesFilho: string,
      totalDependentesOutro: string
    },
    idade: {
        mediaIdadeDependente: string
        mediaIdadeDependentePf: string
        mediaIdadeDependentePj: string
        mediaIdadeGeral: string
        mediaIdadePf: string
        mediaIdadePj: string
        mediaIdadeTitular: string
        mediaIdadeTitularPf: string
        mediaIdadeTitularPj: string,
        idadeTitulares: [
          {
            menor18: string,
            entre18e50: string,
            entre51e65: string,
            acima65: string,
          },
        ],
        idadeDependentes: [
          {
            menor18: string,
            entre18e50: string,
            entre51e65: string,
            acima65: string,
          },
        ],
    },
    titular: {
        totalPfTitularSexoFeminino: string
        totalPfTitularSexoMasculino: string
        totalPjTitularSexoFeminino: string
        totalPjTitularSexoMasculino: string
        totalTitular: string
    }
}

export default function DemographicData() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dontIncludeBlocked, setDontIncludeBlocked] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [data, setData] = useState<ResponseProps>({
    cidades: {
      cidadesDependentes: [],
      cidadesTitulares: [],
    },
    dependentes: {
      totalPfDependenteSexoMasculino: '0',
      totalPfDependenteSexoFeminino: '0',
      totalPjDependenteSexoMasculino: '0',
      totalPjDependenteSexoFeminino: '0',
      totalDependente: '0',
      totalDependentesEsposaPf: '0',
      totalDependentesFilhoPf: '0',
      totalDependentesOutroPf: '0',
      totalDependentesEsposaPj: '0',
      totalDependentesFilhoPj: '0',
      totalDependentesOutroPj: '0',
      totalDependentesEsposa: '0',
      totalDependentesFilho: '0',
      totalDependentesOutro: '0',
    },
    idade: {
      mediaIdadeDependente: '0',
      mediaIdadeDependentePf: '0',
      mediaIdadeDependentePj: '0',
      mediaIdadeGeral: '0',
      mediaIdadePf: '0',
      mediaIdadePj: '0',
      mediaIdadeTitular: '0',
      mediaIdadeTitularPf: '0',
      mediaIdadeTitularPj: '0',
      idadeTitulares: [
        {
          menor18: '0',
          entre18e50: '0',
          entre51e65: '0',
          acima65: '0',
        },
      ],
      idadeDependentes: [
        {
          menor18: '0',
          entre18e50: '0',
          entre51e65: '0',
          acima65: '0',
        },
      ],
    },
    titular: {
      totalPfTitularSexoFeminino: '0',
      totalPfTitularSexoMasculino: '0',
      totalPjTitularSexoFeminino: '0',
      totalPjTitularSexoMasculino: '0',
      totalTitular: '0',
    },
  });

  async function fetchData() {
    try {
      setLoading(true);
      const obj = {};
      if (dontIncludeBlocked) {
        Object.assign(obj, {
          naoIncluirBloqueados: true,
        });
      }
      const response = await api.post('admin/reports/demographicdata', {
        ...obj,
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      setData(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível buscar dados demográficos', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
    }
  }

  useEffect(() => {
    fetchData();
  }, [dontIncludeBlocked]);

  return (
    <DefaultLayout
      pageTitle="Relatório demográfico"
      loading={loading}
      showToastSuccess={false}
      showToastError={false}
      showRightSlider={false}
      rightSliderContent={null}
      modal={null}
      setCloseRightSlider={() => null}
    >
      <div className="row mt-4">
        <div className="col-12 card-bg p-4">
          <div className="col-12 allow-modifications">
            <input
              type="checkbox"
              onChange={() => {
                setDontIncludeBlocked((prev) => !prev);
              }}
              checked={dontIncludeBlocked}
            />
            <p>Não incluir associados bloqueados temporariamente.</p>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 card-bg p-4">
          <div className="col-12">
            <h4 className="mb-4 fw-bold text-primary">Titulares</h4>
            <div className="d-flex align-items-center">
              <p className="fw-bold">Sexo feminino (PF):</p>
              <p className="px-2">{data.titular.totalPfTitularSexoFeminino}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Sexo masculino (PF):</p>
              <p className="px-2">{data.titular.totalPfTitularSexoMasculino}</p>
            </div>
            <div className="d-flex align-items-center mt-4">
              <p className="fw-bold">Sexo feminino (PJ):</p>
              <p className="px-2">{data.titular.totalPjTitularSexoFeminino}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Sexo masculino (PJ):</p>
              <p className="px-2">{data.titular.totalPjTitularSexoMasculino}</p>
            </div>
            <div className="d-flex align-items-center mt-4">
              <p className="fw-bold">Total Titulares:</p>
              <p className="px-2">{data.titular.totalTitular}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 card-bg p-4">
          <div className="col-12">
            <h4 className="mb-4 fw-bold text-primary">Dependentes</h4>
            <div className="d-flex align-items-center">
              <p className="fw-bold">Sexo feminino (PF):</p>
              <p className="px-2">{data.dependentes.totalPfDependenteSexoFeminino}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Sexo masculino (PF):</p>
              <p className="px-2">{data.dependentes.totalPfDependenteSexoMasculino}</p>
            </div>
            <div className="d-flex align-items-center mt-4">
              <p className="fw-bold">Sexo feminino (PJ):</p>
              <p className="px-2">{data.dependentes.totalPjDependenteSexoFeminino}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Sexo masculino (PJ):</p>
              <p className="px-2">{data.dependentes.totalPjDependenteSexoMasculino}</p>
            </div>

            <div className="d-flex align-items-center mt-4">
              <p className="fw-bold">Cônjuge PF:</p>
              <p className="px-2">{data.dependentes.totalDependentesEsposaPf}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Filho(a) PF:</p>
              <p className="px-2">{data.dependentes.totalDependentesFilhoPf}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Outro PF:</p>
              <p className="px-2">{data.dependentes.totalDependentesOutroPf}</p>
            </div>

            <div className="d-flex align-items-center mt-4">
              <p className="fw-bold">Cônjuge PJ:</p>
              <p className="px-2">{data.dependentes.totalDependentesEsposaPj}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Filho(a) PJ:</p>
              <p className="px-2">{data.dependentes.totalDependentesFilhoPj}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Outro PJ:</p>
              <p className="px-2">{data.dependentes.totalDependentesOutroPj}</p>
            </div>

            <div className="d-flex align-items-center mt-4">
              <p className="fw-bold">Total Cônjuge:</p>
              <p className="px-2">{data.dependentes.totalDependentesEsposa}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Total Filho(a):</p>
              <p className="px-2">{data.dependentes.totalDependentesFilho}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Total Outro:</p>
              <p className="px-2">{data.dependentes.totalDependentesOutro}</p>
            </div>
            <div className="d-flex align-items-center mt-4">
              <p className="fw-bold">Total dependentes:</p>
              <p className="px-2">{data.dependentes.totalDependente}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 card-bg p-4">
          <div className="col-12">
            <h4 className="mb-4 fw-bold text-primary">Idade</h4>
            <div className="d-flex align-items-center">
              <p className="fw-bold">Média de idade dos titulares (PF):</p>
              <p className="px-2">{data.idade.mediaIdadeTitularPf}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Média de idade dos titulares (PJ):</p>
              <p className="px-2">{data.idade.mediaIdadeTitularPj}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Média de idade dos titulares:</p>
              <p className="px-2">{data.idade.mediaIdadeTitular}</p>
            </div>
            <div className="d-flex align-items-center mt-4">
              <p className="fw-bold">Média de idade dos dependentes (PF):</p>
              <p className="px-2">{data.idade.mediaIdadeDependentePf}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Média de idade dos dependentes (PJ):</p>
              <p className="px-2">{data.idade.mediaIdadeDependentePj}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Média de idade dos dependentes:</p>
              <p className="px-2">{data.idade.mediaIdadeDependente}</p>
            </div>
            <div className="d-flex align-items-center mt-4">
              <p className="fw-bold">Média de idade geral PF:</p>
              <p className="px-2">{data.idade.mediaIdadePf}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Média de idade geral PJ:</p>
              <p className="px-2">{data.idade.mediaIdadePj}</p>
            </div>
            <div className="d-flex align-items-center mt-4">
              <p className="fw-bold">Titulares menores de 18 anos:</p>
              <p className="px-2">{data.idade.idadeTitulares[0].menor18}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Titulares entre 18 e 50 anos:</p>
              <p className="px-2">{data.idade.idadeTitulares[0].entre18e50}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Titulares entre 51 e 65 anos:</p>
              <p className="px-2">{data.idade.idadeTitulares[0].entre51e65}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Titulares acima de 65 anos:</p>
              <p className="px-2">{data.idade.idadeTitulares[0].acima65}</p>
            </div>

            <div className="d-flex align-items-center mt-4">
              <p className="fw-bold">Dependentes menores de 18 anos:</p>
              <p className="px-2">{data.idade.idadeDependentes[0].menor18}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Dependentes entre 18 e 50 anos:</p>
              <p className="px-2">{data.idade.idadeDependentes[0].entre18e50}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Dependentes entre 51 e 65 anos:</p>
              <p className="px-2">{data.idade.idadeDependentes[0].entre51e65}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p className="fw-bold">Dependentes acima de 65 anos:</p>
              <p className="px-2">{data.idade.idadeDependentes[0].acima65}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 card-bg p-4">
          <div className="col-12">
            <h4 className="mb-4 fw-bold text-primary">Cidades</h4>
            <h5 className="fw-bold mb-3 text-uppercase" style={{ color: '#6200EE', fontSize: 12 }}>Cidades dos titulares</h5>
            {data.cidades.cidadesTitulares.map((item) => (
              <div className="d-flex align-items-center mt-1">
                <p className="fw-bold">
                  {item.cidade.toUpperCase()}
                  :
                </p>
                <p className="px-2">{item.quantidade}</p>
              </div>
            ))}
            <h5 className="fw-bold mb-3 text-uppercase mt-4" style={{ color: '#6200EE', fontSize: 12 }}>Cidades dos dependentes</h5>
            {data.cidades.cidadesDependentes.map((item) => (
              <div className="d-flex align-items-center mt-1">
                <p className="fw-bold">
                  {item.cidade.toUpperCase()}
                  :
                </p>
                <p className="px-2">{item.quantidade}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
