/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import PriceInput from '../../../components/PriceInput';
import api from '../../../config/axios';
import { maskDate } from '../../../helpers/inputMasks.js';
import resolvePaymentStatus from '../../../helpers/resolvePaymentStatus';
import showCardPaymentWays, { convertCardPaymentMethodFromApi, normalizeToApiCardPaymentWays } from '../../../helpers/showCardMethods';
import showNonCardPaymentWays from '../../../helpers/showNonCardMethods';
import { PaymentGateway } from '../../PaymentTax/types';
import { InstallmentDTO } from './EditPaymentPFV2';

interface AllTimeValueProps {
    id: string,
    title: string,
    installmentDTO: InstallmentDTO,
    installmentIndex: number,
    paymentGateways: PaymentGateway[],
    fetchAllInstallment: () => void

}

enum ActualPage {
  FORM,
  LOADING,
  SUCCESS,
  ERROR
}

export default function EditInstallmentModal({
  id, title, installmentDTO, paymentGateways, installmentIndex, fetchAllInstallment,
}: AllTimeValueProps) {
  const [forceRefresh, setForceRefresh] = useState(false);
  const [installmentCtx, setInstallmentCtx] = useState({ ...installmentDTO });
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [actualPage, setActualPage] = useState(ActualPage.FORM);

  function showNonCardPaymentMethods(installment: InstallmentDTO) {
    const result: string[] = [];
    if (paymentGateways.length > 0) {
      paymentGateways.filter((item) => item.id === installment.paymentGatewayId).forEach((item) => {
        item.taxes.forEach((taxes) => {
          if (taxes.boleto !== null) {
            result.push('BOLETO');
          }
          if (taxes.dinheiro !== null) {
            result.push('DINHEIRO');
          }
          if (taxes.doc !== null) {
            result.push('DOC');
          }
          if (taxes.pix !== null) {
            result.push('PIX');
          }
          if (taxes.ted !== null) {
            result.push('TED');
          }
        });
      });
    }

    return result;
  }

  function verifyIfInsallmentCantBeModified() {
    if (installmentCtx.status === 'PAID' || installmentCtx.boletoPayvip || installmentCtx.galaxpayPaymentMethod || installmentCtx.status === 'REVERSED') {
      return true;
    }
    return false;
  }

  function showCardPaymentMethods(installment: InstallmentDTO) {
    const filteredGateway = paymentGateways.filter((item) => item.id === installment.paymentGatewayId);
    if (filteredGateway.length > 0) {
      const filteredCardsByFlag = filteredGateway[0].card.filter((card) => {
        if (installment.flag === 'OUTRA') {
          return card.flag === 'OTHER';
        }
        return card.flag === installment.flag;
      });
      return showCardPaymentWays(filteredCardsByFlag);
    }
    return [];
  }

  function showFlag(flag: string) {
    if (flag === 'OTHER') {
      return 'OUTRA';
    }
    return flag;
  }

  function showCardFlag(installment: InstallmentDTO) {
    const filteredGateway = paymentGateways.filter((item) => item.id === installment.paymentGatewayId);

    if (filteredGateway.length > 0) {
      return filteredGateway[0].card.map((item) => {
        if (item.flag === 'OTHER') {
          return 'OUTRA';
        }
        return item.flag;
      });
    }
    return [];
  }

  function changePaymentOption(method: string) {
    if (method === 'CARTÃO') {
      installmentCtx.cieloInstallmentsGroup = null;
      installmentCtx.card = true;
      installmentCtx.nonCardTaxes = '';
      installmentCtx.flag = showCardFlag(installmentCtx)[0];
      installmentCtx.cardPaymentOption = showCardPaymentMethods(installmentCtx)[0];
      setForceRefresh((prev) => !prev);
    } else {
      const filteredGateway = paymentGateways.filter((item) => item.name === item.gateway);
      const nonCardPaymentOptions = showNonCardPaymentWays(filteredGateway[0]);
      installmentCtx.cieloInstallmentsGroup = null;
      installmentCtx.card = false;
      installmentCtx.flag = null;
      installmentCtx.cardPaymentOption = '';
      installmentCtx.cardLastDigits = '';
      installmentCtx.nonCardTaxes = nonCardPaymentOptions[0];
      setForceRefresh((prev) => !prev);
    }
  }

  function changePaymentGateway(gateway: string) {
    const filteredGateway = paymentGateways.filter((item) => item.name === gateway);

    const cardPaymentOptions = showCardPaymentWays(filteredGateway[0].card);
    const nonCardPaymentOptions = showNonCardPaymentWays(filteredGateway[0]);

    if (nonCardPaymentOptions.length > 0) {
      setInstallmentCtx({
        ...installmentCtx,
        paymentGatewayId: filteredGateway[0].id,
        card: false,
        cardLastDigits: '',
        flag: '',
        cieloInstallmentsGroup: null,
        cardPaymentOption: '',
        nonCardTaxes: nonCardPaymentOptions[0],
      });
    } else if (cardPaymentOptions.length > 0) {
      setInstallmentCtx({
        ...installmentCtx,
        paymentGatewayId: filteredGateway[0].id,
        card: true,
        cardLastDigits: '',
        cieloInstallmentsGroup: null,
        flag: filteredGateway[0].card[0].flag,
        cardPaymentOption: cardPaymentOptions[0],
        nonCardTaxes: '',
      });
    }
  }

  function showAvailablePaymentOption(idCtx: number) {
    const result: string[] = [];
    const filteredMethod = paymentGateways.filter((item) => item.id === idCtx);
    if (filteredMethod.length > 0) {
      if (filteredMethod[0].card.length > 0) {
        result.push('CARTÃO');
      }
      if (filteredMethod[0].taxes.length > 0) {
        result.push('OUTRO');
      }
    }
    return result;
  }

  function setCardFlag(flag: string) {
    setInstallmentCtx({ ...installmentCtx, flag, cardPaymentOption: showCardPaymentMethods(installmentCtx)[0] });
  }

  function changeCardPaymentOption(option: string) {
    setInstallmentCtx({ ...installmentCtx, cardPaymentOption: option, cieloInstallmentsGroup: null });
  }

  function setPaymentWay(way: string) {
    setInstallmentCtx({ ...installmentCtx, nonCardTaxes: way, cieloInstallmentsGroup: null });
  }

  function changeCardLastDigits(digits: string) {
    setInstallmentCtx({ ...installmentCtx, cardLastDigits: digits });
  }

  function changeInstallmentGroup(digits: number) {
    setInstallmentCtx({ ...installmentCtx, cieloInstallmentsGroup: digits });
  }

  function showGatewayName(idx: number) {
    if (idx === 1) {
      return 'PagAzul PRESENCIAL';
    }
    const filteredGateway = paymentGateways.filter((item) => item.id === idx);
    if (filteredGateway.length > 0) {
      return filteredGateway[0].name;
    }
    return '';
  }

  async function updateInstallment() {
    try {
      setActualPage(ActualPage.LOADING);
      const cardPaymentOption = installmentCtx.cardPaymentOption !== null ? installmentCtx.cardPaymentOption : '';
      const normalizedCardPaymentOption = normalizeToApiCardPaymentWays(cardPaymentOption);
      const flag = installmentCtx.flag === 'OUTRA' ? 'OTHER' : installmentCtx.flag;
      const payload = { ...installmentCtx, cardPaymentOption: normalizedCardPaymentOption, flag };
      await api.put('/admin/contractpf/installment/', {
        installments: [payload],
      }, token);
      setActualPage(ActualPage.SUCCESS);
    } catch (err: any) {
      console.log(err.response);
      setActualPage(ActualPage.ERROR);
    }
  }

  async function closeModal() {
    window.location.reload();
  }

  function validateBeforeSave() {
    if (
      installmentCtx.card
      && installmentCtx.paymentGatewayId === 4
      && installmentCtx.cardPaymentOption !== 'Débito'
      && installmentCtx.cardPaymentOption !== 'Crédito 1X'
      && !installmentCtx.cieloInstallmentsGroup
    ) {
      return false;
    }
    return true;
  }

  return (
    <div className="modal fade" id={id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {title}
              {' '}
              |
              {' '}
              PF_DB_ID:
              {' '}
              <strong className="fw-bold">
                {installmentCtx.id}
              </strong>
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeModal()} />
          </div>
          <div className="modal-body">
            { actualPage === ActualPage.LOADING && (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
              <p className="fw-bold text-muted">Alterando parcela, aguarde...</p>
            </div>
            )}

            { actualPage === ActualPage.SUCCESS && (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
              <p className="fw-bold text-muted">Parcela alterada com sucesso!</p>
            </div>
            )}

            {actualPage === ActualPage.ERROR && (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
              <p className="fw-bold text-muted">Ocorreu um erro ao alterar parcela!</p>
            </div>
            )}

            {actualPage === ActualPage.FORM && (
            <div className="container">
              <div className="row products">
                { installmentCtx.status === 'REVERSED' ? (
                  <div className="col-12 mb-4">
                    <p className="text-danger">Essa parcela não poderá ser alterada pois foi estornada e seus dados estarão disponíveis apenas para consulta.</p>
                  </div>
                ) : (
                  <>
                    {
                  installmentCtx.boletoPayvip && (
                    <div className="col-12 mb-4">
                      <p className="text-danger">Essa parcela não poderá ser alterada pois possui um boleto gerado. Para evitar inconsistências no sistema cancele-o antes caso queira fazer qualquer modificação.</p>
                    </div>
                  )
                }
                    {
                  installmentCtx.galaxpayPaymentMethod && (
                    <div className="col-12 mb-4">
                      <p className="text-danger">
                        Essa parcela não poderá ser alterada pois possui um pagamento vinculado à galaxpay (
                        <strong className="fw-bold">
                          galaxPayId:
                          {' '}
                          {installmentCtx.galaxpayInstallmentId}
                        </strong>
                        ). Para evitar inconsistências no sistema cancele-o antes caso queira fazer qualquer modificação.
                      </p>
                    </div>
                  )
                }
                  </>
                )}

                <div className="col-12 card-bg p-4">
                  <div className="row">
                    <div className="payment-status">
                      <h1>
                        {`Parcela - ${installmentIndex + 1}`}
                      </h1>
                      <span>
                        {resolvePaymentStatus(installmentCtx.status, installmentCtx.dueDate)}
                      </span>
                    </div>

                    <div className="col-12">
                      <BasicInput
                        id="data-vencimento"
                        label="Data de vencimento"
                        readOnly={verifyIfInsallmentCantBeModified()}
                        helper="Ex: 30-04-2022"
                        error={installmentCtx.dueDate.length < '00-00-0000'.length ? 'Digite uma data válida' : ''}
                        value={installmentCtx.dueDate}
                        type="text"
                        onChange={(data) => setInstallmentCtx({ ...installmentCtx, dueDate: maskDate(data) })}
                      />
                    </div>
                    <div className="col-12">
                      <PriceInput
                        id={`valor-parcela-${installmentIndex + 1}`}
                        label="Valor Bruto"
                        readOnly={verifyIfInsallmentCantBeModified()}
                        helper="Ex: 600"
                        error={installmentCtx.value === 0 ? 'Digite um valor' : ''}
                        value={installmentCtx.value.toString()}
                        type="number"
                        onChange={(data) => setInstallmentCtx({ ...installmentCtx, value: Number(data) })}
                      />
                    </div>
                    <div className="col-12">
                      <BasicOption
                        id={`gateway-de-pagamento-${installmentIndex + 1}`}
                        label="Gateway de pagamento"
                        readOnly={verifyIfInsallmentCantBeModified()}
                        helper="Ex: PagAzul Web"
                        error=""
                        value={showGatewayName(installmentCtx.paymentGatewayId)}
                        onChange={(data) => changePaymentGateway(data)}
                      >
                        {paymentGateways.map((gateway) => gateway.name)}
                      </BasicOption>
                    </div>
                    <div className="col-12">
                      <BasicOption
                        id={`opcoes-de-pagamento${installmentIndex + 1}`}
                        label="Opções de pagamento"
                        readOnly={verifyIfInsallmentCantBeModified()}
                        helper="Ex: Cartão"
                        error=""
                        value={installmentCtx.card ? 'CARTÃO' : 'OUTRO'}
                        onChange={(data) => changePaymentOption(data)}
                      >
                        {showAvailablePaymentOption(installmentCtx.paymentGatewayId).map((method) => method)}
                      </BasicOption>
                    </div>

                    {installmentCtx.card && (
                    <div className="col-12">
                      <BasicOption
                        id={`flag-${installmentIndex + 1}`}
                        label="Bandeira"
                        readOnly={verifyIfInsallmentCantBeModified()}
                        helper="Ex: VISA"
                        error=""
                        value={installmentCtx.flag !== null ? showFlag(installmentCtx.flag) : ''}
                        onChange={(data) => setCardFlag(data)}
                      >
                        {showCardFlag(installmentCtx)}
                      </BasicOption>
                    </div>
                    )}

                    {installmentCtx.card && (
                    <div className="col-12">
                      <BasicOption
                        id={`card-way-${installmentIndex + 1}`}
                        label="Forma de pagamento"
                        readOnly={verifyIfInsallmentCantBeModified()}
                        helper="Ex: Crédito 12x"
                        error=""
                        value={installmentCtx.cardPaymentOption !== null ? convertCardPaymentMethodFromApi(installmentCtx.cardPaymentOption) : ''}
                        onChange={(data) => changeCardPaymentOption(data)}
                      >
                        {showCardPaymentMethods(installmentCtx)}
                      </BasicOption>
                    </div>
                    )}

                    {installmentCtx.card && (
                    <div className="col-12">
                      <BasicInput
                        id={`card-last-digits-${installmentIndex + 1}`}
                        label="Ultimos 04 dígitos"
                        readOnly={verifyIfInsallmentCantBeModified()}
                        helper="Ex: 2144"
                        value={installmentCtx.cardLastDigits ? installmentCtx.cardLastDigits.toString() : ''}
                        onChange={(data) => (data.length < 5 ? changeCardLastDigits(data) : null)}
                        type="number"
                        error=""
                      />
                    </div>
                    )}

                    {installmentCtx.card && installmentCtx.paymentGatewayId === 4 && installmentCtx.cardPaymentOption !== 'Débito' && installmentCtx.cardPaymentOption !== 'Crédito 1X' && (
                      <div className="col-12">
                        <BasicInput
                          id={`cielogroup-${installmentIndex + 1}`}
                          label="Grupo de parcelas"
                          readOnly={verifyIfInsallmentCantBeModified()}
                          helper="Ex: 2"
                          value={installmentCtx.cieloInstallmentsGroup ? installmentCtx.cieloInstallmentsGroup.toString() : ''}
                          onChange={(data) => (data.length < 5 ? changeInstallmentGroup(Number(data)) : null)}
                          type="number"
                          error={!installmentCtx.cieloInstallmentsGroup ? 'Campo obrigatório' : ''}
                        />
                      </div>
                    )}

                    {!installmentCtx.card && (
                    <div className="col-12">
                      <BasicOption
                        id={`way-${installmentIndex + 1}`}
                        label="Forma de pagamento"
                        readOnly={verifyIfInsallmentCantBeModified()}
                        helper="Ex: PIX"
                        error=""
                        value={installmentCtx.nonCardTaxes !== null ? installmentCtx.nonCardTaxes : ''}
                        onChange={(data) => setPaymentWay(data)}
                      >
                        {showNonCardPaymentMethods(installmentCtx)}
                      </BasicOption>
                    </div>
                    )}
                    {
                 installmentCtx.paidBy !== null && (
                   <div className="col-12">
                     <BasicInput
                       id="baixa-realiazada-por"
                       label="Baixa realizada por"
                       readOnly
                       helper=""
                       error=""
                       value={installmentCtx.paidBy}
                       type="text"
                       onChange={() => null}
                     />
                   </div>
                 )
             }
                    {
                 installmentCtx.paymentDate !== null && (
                   <div className="col-12">
                     <BasicInput
                       id="data-da-baixa"
                       label="Data da baixa"
                       readOnly
                       helper=""
                       error=""
                       value={installmentCtx.paymentDate}
                       type="text"
                       onChange={() => null}
                     />
                   </div>
                 )
             }

                  </div>
                </div>
              </div>
            </div>
            )}

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => closeModal()}>Fechar</button>
            {!verifyIfInsallmentCantBeModified() && actualPage === ActualPage.FORM && (
            <button type="button" disabled={!validateBeforeSave()} className="btn btn-primary" onClick={() => updateInstallment()}>Salvar</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
