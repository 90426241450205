import { Card } from '../pages/PaymentTax/types';

export default function showCardPaymentWays(cards: Card[]) {
  const result: string[] = [];
  cards.forEach((item) => {
    if (typeof item.credit_1 !== 'undefined' && item.credit_1 !== null) {
      result.push('Crédito 1X');
    }
    if (typeof item.credit_2 !== 'undefined' && item.credit_2 !== null) {
      result.push('Crédito 2X');
    }
    if (typeof item.credit_3 !== 'undefined' && item.credit_3 !== null) {
      result.push('Crédito 3X');
    }
    if (typeof item.credit_4 !== 'undefined' && item.credit_4 !== null) {
      result.push('Crédito 4X');
    }
    if (typeof item.credit_5 !== 'undefined' && item.credit_5 !== null) {
      result.push('Crédito 5X');
    }
    if (typeof item.credit_6 !== 'undefined' && item.credit_6 !== null) {
      result.push('Crédito 6X');
    }
    if (typeof item.credit_7 !== 'undefined' && item.credit_7 !== null) {
      result.push('Crédito 7X');
    }
    if (typeof item.credit_8 !== 'undefined' && item.credit_8 !== null) {
      result.push('Crédito 8X');
    }
    if (typeof item.credit_9 !== 'undefined' && item.credit_9 !== null) {
      result.push('Crédito 9X');
    }
    if (typeof item.credit_10 !== 'undefined' && item.credit_10 !== null) {
      result.push('Crédito 10X');
    }
    if (typeof item.credit_11 !== 'undefined' && item.credit_11 !== null) {
      result.push('Crédito 11X');
    }
    if (typeof item.credit_12 !== 'undefined' && item.credit_12 !== null) {
      result.push('Crédito 12X');
    }
    if (typeof item.credit_13 !== 'undefined' && item.credit_13 !== null) {
      result.push('Crédito 13X');
    }
    if (typeof item.credit_14 !== 'undefined' && item.credit_14 !== null) {
      result.push('Crédito 14X');
    }
    if (typeof item.credit_15 !== 'undefined' && item.credit_15 !== null) {
      result.push('Crédito 15X');
    }
    if (typeof item.credit_16 !== 'undefined' && item.credit_16 !== null) {
      result.push('Crédito 16X');
    }
    if (typeof item.credit_17 !== 'undefined' && item.credit_17 !== null) {
      result.push('Crédito 17X');
    }
    if (typeof item.credit_18 !== 'undefined' && item.credit_18 !== null) {
      result.push('Crédito 18X');
    }
    if (typeof item.debit !== 'undefined' && item.debit !== null) {
      result.push('Débito');
    }
  });
  return result;
}

export function normalizeToApiCardPaymentWays(creditway: string) {
  if (creditway.toUpperCase() === 'Crédito 1X'.toUpperCase()) {
    return 'credit_1';
  }
  if (creditway.toUpperCase() === 'Crédito 2X'.toUpperCase()) {
    return 'credit_2';
  }
  if (creditway.toUpperCase() === 'Crédito 3X'.toUpperCase()) {
    return 'credit_3';
  }
  if (creditway.toUpperCase() === 'Crédito 4X'.toUpperCase()) {
    return 'credit_4';
  }
  if (creditway.toUpperCase() === 'Crédito 5X'.toUpperCase()) {
    return 'credit_5';
  }
  if (creditway.toUpperCase() === 'Crédito 6X'.toUpperCase()) {
    return 'credit_6';
  }
  if (creditway.toUpperCase() === 'Crédito 7X'.toUpperCase()) {
    return 'credit_7';
  }
  if (creditway.toUpperCase() === 'Crédito 8X'.toUpperCase()) {
    return 'credit_8';
  }
  if (creditway.toUpperCase() === 'Crédito 9X'.toUpperCase()) {
    return 'credit_9';
  }
  if (creditway.toUpperCase() === 'Crédito 10X'.toUpperCase()) {
    return 'credit_10';
  }
  if (creditway.toUpperCase() === 'Crédito 11X'.toUpperCase()) {
    return 'credit_11';
  }
  if (creditway.toUpperCase() === 'Crédito 12X'.toUpperCase()) {
    return 'credit_12';
  }
  if (creditway.toUpperCase() === 'Crédito 13X'.toUpperCase()) {
    return 'credit_13';
  }
  if (creditway.toUpperCase() === 'Crédito 14X'.toUpperCase()) {
    return 'credit_14';
  }
  if (creditway.toUpperCase() === 'Crédito 15X'.toUpperCase()) {
    return 'credit_15';
  }
  if (creditway.toUpperCase() === 'Crédito 16X'.toUpperCase()) {
    return 'credit_16';
  }
  if (creditway.toUpperCase() === 'Crédito 17X'.toUpperCase()) {
    return 'credit_17';
  }
  if (creditway.toUpperCase() === 'Crédito 18X'.toUpperCase()) {
    return 'credit_18';
  }
  if (creditway.toUpperCase() === 'Débito'.toUpperCase()) {
    return 'debit';
  }
  return creditway;
}

export function convertCardPaymentMethodFromApi(method: string) {
  if (method === 'credit_1') {
    return 'Crédito 1X';
  }
  if (method === 'credit_2') {
    return 'Crédito 2X';
  }
  if (method === 'credit_3') {
    return 'Crédito 3X';
  }
  if (method === 'credit_4') {
    return 'Crédito 4X';
  }
  if (method === 'credit_5') {
    return 'Crédito 5X';
  }
  if (method === 'credit_6') {
    return 'Crédito 6X';
  }
  if (method === 'credit_7') {
    return 'Crédito 7X';
  }
  if (method === 'credit_8') {
    return 'Crédito 8X';
  }
  if (method === 'credit_8') {
    return 'Crédito 8X';
  }
  if (method === 'credit_9') {
    return 'Crédito 9X';
  }
  if (method === 'credit_10') {
    return 'Crédito 10X';
  }
  if (method === 'credit_11') {
    return 'Crédito 11X';
  }
  if (method === 'credit_12') {
    return 'Crédito 12X';
  }
  if (method === 'credit_13') {
    return 'Crédito 13X';
  }
  if (method === 'credit_14') {
    return 'Crédito 14X';
  }
  if (method === 'credit_15') {
    return 'Crédito 15X';
  }
  if (method === 'credit_16') {
    return 'Crédito 16X';
  }
  if (method === 'credit_17') {
    return 'Crédito 17X';
  }
  if (method === 'credit_18') {
    return 'Crédito 18X';
  }
  if (method === 'debit') {
    return 'Débito';
  }
  return method;
}
