/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import BasicInput from '../BasicInput';
import { maskDate } from '../../helpers/inputMasks.js';
import BasicOption from '../BasicOption';
import TextAreaInput from '../TextAreaInput';
import api from '../../config/axios';
import { PrintedCardProps } from './Card';

type ReceiveCardModalProps = {
    isHolder: boolean,
    uuid?: string,
    id? : number,
    receivedCard: PrintedCardProps | null
}

export default function ReceiveCardModal({
  isHolder, uuid, id, receivedCard,
}: ReceiveCardModalProps) {
  const [received, setReceived] = useState({
    id: 100000,
    printedAt: '',
    isSecondCopy: false,
    pickedUp: false,
    pickedUpDate: '',
    observation: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  function setIsSecondCopy(value: string) {
    if (value === 'SIM') {
      setReceived({ ...received, isSecondCopy: true });
    } else {
      setReceived({ ...received, isSecondCopy: false });
    }
  }

  function resolveIsSecondCopy(value: boolean) {
    if (received.isSecondCopy) {
      return 'SIM';
    }
    return 'NÃO';
  }

  function setIsPickedUp(value: string) {
    if (value === 'SIM') {
      setReceived({ ...received, pickedUp: true });
    } else {
      setReceived({ ...received, pickedUp: false });
    }
  }

  function resolveIsPickedUp(value: boolean) {
    if (received.pickedUp) {
      return 'SIM';
    }
    return 'NÃO';
  }

  function validate() {
    return received.printedAt.trim().length === 'DD-MM-YYYY'.length;
  }

  async function saveReceived() {
    try {
      setIsLoading(true);
      const obj = {};
      console.log(id);
      if (isHolder) {
        Object.assign(obj, { holderId: Number(id) });
      } else {
        Object.assign(obj, { dependentUuid: uuid });
      }
      if (receivedCard) {
        console.log('alterar', received);
        await api.put('/admin/printedcard', {
          ...received,
          printedAt: received.printedAt === '' ? null : received.printedAt,
          pickedUpDate: received.pickedUpDate === '' ? null : received.pickedUpDate,
          observation: received.observation === '' ? null : received.observation,
        }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      } else {
        console.log('adicionar', { ...received, ...obj });
        await api.post('/admin/printedcard', {
          ...received,
          ...obj,
        }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      }
      setIsLoading(false);
      // window.location.reload();
    } catch (err: any) {
      setError(true);
      console.log(err.response.data);
      setIsLoading(false);
    }
  }

  function onClose() {
    window.location.reload();
  }

  useEffect(() => {
    if (receivedCard !== null) {
      setReceived(receivedCard);
    }
  }, [receivedCard]);

  return (
    <div className="modal fade" id="receivecard" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Controle do cartão</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => onClose()} />
          </div>
          {error ? (
            <div className="modal-body">
              <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
                <p className="text-center">Ocorreu um erro, verifique se as datas foram preenchidas de maneira correta.</p>
              </div>
            </div>
          ) : (
            <div className="modal-body">
              { isLoading ? (
                <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
                  <p className="text-center">Carregando, aguarde.</p>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <BasicInput
                      id="printedat"
                      label="Impresso em"
                      readOnly={false}
                      helper=""
                      error=""
                      value={received.printedAt}
                      type=""
                      onChange={(data) => setReceived({ ...received, printedAt: maskDate(data) })}
                    />
                  </div>
                  <div className="col-12">
                    <BasicOption
                      id="secondcopy"
                      label="Recebido pelo cliente ?"
                      readOnly={false}
                      helper=""
                      error=""
                      value={resolveIsPickedUp(received.pickedUp)}
                      onChange={(data) => setIsPickedUp(data)}
                    >
                      {['SIM', 'NÃO']}
                    </BasicOption>
                  </div>
                  <div className="col-12">
                    <BasicOption
                      id="issecondcopy"
                      label="É segunda cópia ?"
                      readOnly={false}
                      helper=""
                      error=""
                      value={resolveIsSecondCopy(received.isSecondCopy)}
                      onChange={(data) => setIsSecondCopy(data)}
                    >
                      {['SIM', 'NÃO']}
                    </BasicOption>
                  </div>
                  <div className="col-12">
                    <BasicInput
                      id="pickedup"
                      label="Dt. que o cliente recebeu"
                      readOnly={false}
                      helper=""
                      error=""
                      value={received.pickedUpDate}
                      type=""
                      onChange={(data) => setReceived({ ...received, pickedUpDate: maskDate(data) })}
                    />
                  </div>
                  <div className="col-12">
                    <TextAreaInput
                      id="observation"
                      label="Observação"
                      readOnly={false}
                      helper=""
                      error=""
                      value={received.observation}
                      onChange={(data) => setReceived({ ...received, observation: data })}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => onClose()}>Fechar</button>
            {!isLoading && (
            <button type="button" className="btn btn-primary" disabled={!validate()} onClick={() => saveReceived()}>Salvar alterações</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
