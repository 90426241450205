/* eslint-disable no-shadow */
import React from 'react';

export enum ModalActionType {
    PRIMARY,
    SECONDARY,
    DANGER,
    SUCCESS,
    ALERT,
}

export type ModalActionsProps = {
    label: string;
    onClick: () => void;
    type: ModalActionType
}

export interface ModalProps {
    children: React.ReactNode;
    title: string;
    actions: ModalActionsProps[]
}

export default function Modal({ children, title, actions }: ModalProps) {
  return (
    <div className="my-modal">
      <div className="container-fluid">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-12 my-modal-box">
            <div className="col-12 my-modal-title">
              <h1>{title}</h1>
            </div>
            <div className="col-12 my-modal-body">
              {children}
            </div>
            {actions.map((item) => {
              if (item.type === ModalActionType.PRIMARY) {
                return (
                  <div className="col-12 my-modal-footer-primary">
                    <button onClick={() => item.onClick()} type="button">{item.label}</button>
                  </div>
                );
              }
              if (item.type === ModalActionType.SECONDARY) {
                return (
                  <div className="col-12 my-modal-footer-secondary">
                    <button onClick={() => item.onClick()} type="button">{item.label}</button>
                  </div>
                );
              }
              if (item.type === ModalActionType.SUCCESS) {
                return (
                  <div className="col-12 my-modal-footer-success">
                    <button onClick={() => item.onClick()} type="button">{item.label}</button>
                  </div>
                );
              }
              if (item.type === ModalActionType.DANGER) {
                return (
                  <div className="col-12 my-modal-footer-danger">
                    <button onClick={() => item.onClick()} type="button">{item.label}</button>
                  </div>
                );
              }
              if (item.type === ModalActionType.ALERT) {
                return (
                  <div className="col-12 my-modal-footer-alert">
                    <button onClick={() => item.onClick()} type="button">{item.label}</button>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
