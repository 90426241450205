/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

interface RightFloatingAsideProps {
    children: React.ReactNode;
    setClose: () => void;
}

export default function RightFloatingAside({ children, setClose }: RightFloatingAsideProps) {
  return (
    <div className="right-floating-aside-container">
      <div className="dismiss-bg" onClick={() => setClose()} />
      <div className="right-content">
        <div className="close">
          <button onClick={() => setClose()} type="button">
            <span className="material-icons-round">
              close
            </span>

          </button>
        </div>
        <div className="children-content">
          { children }
        </div>
      </div>
    </div>
  );
}
