import React from 'react';
import TextAreaInput from './TextAreaInput';

type FilterProps = {
    id: string
    refundReason: string,
    setRefundReason: (arg0: string) => void,
    setRefund: () => void,
}

export default function RefundModal({
  refundReason,
  setRefundReason,
  setRefund,
  id,
}: FilterProps) {
  return (
    <div className="modal fade" id={id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Estorno</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <p className="mb-4 fw-bold">Essa é uma ação permanente. Tem certeza?</p>
            <TextAreaInput
              id="refundarea"
              label="Motivo do estorno"
              readOnly={false}
              helper="O porque está realizando o estorno."
              error=""
              value={refundReason}
              onChange={(data) => setRefundReason(data)}
            />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => setRefund()}>Estornar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
