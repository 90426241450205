/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import PriceInput from '../../../components/PriceInput';
import api from '../../../config/axios';
import { showTodayDate } from '../../../helpers/dates';
import { maskDate } from '../../../helpers/inputMasks.js';
import resolvePaymentStatus from '../../../helpers/resolvePaymentStatus';
import showCardPaymentWays, { convertCardPaymentMethodFromApi, normalizeToApiCardPaymentWays } from '../../../helpers/showCardMethods';
import showNonCardPaymentWays from '../../../helpers/showNonCardMethods';
import { PaymentGateway } from '../../PaymentTax/types';

interface AllTimeValueProps {
    id: string,
    title: string,
    paymentGateways: PaymentGateway[],
    paymentId: number,
}

enum ActualPage {
  FORM,
  LOADING,
  SUCCESS,
  ERROR
}

export type InstallmentDTO = {
  cieloInstallmentsGroup: number | null
  contractPjPaymentId: number
  dueDate: string
  status: string
  value: number
  paidBy: string
  paymentGatewayId: number
  nonCardTaxes: string | null
  card: boolean
  flag: string | null
  cardLastDigits: string | null
  cardPaymentOption: string | null
}

export default function NewInstallmentModal({
  id, title, paymentGateways, paymentId,
}: AllTimeValueProps) {
  const [forceRefresh, setForceRefresh] = useState(false);
  const [installmentCtx, setInstallmentCtx] = useState<InstallmentDTO>({
    contractPjPaymentId: 0,
    dueDate: '',
    status: 'UNPAID',
    paidBy: '',
    cieloInstallmentsGroup: null,
    value: 0,
    paymentGatewayId: 0,
    nonCardTaxes: '',
    cardLastDigits: '',
    cardPaymentOption: '',
    card: false,
    flag: '',
  });
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [actualPage, setActualPage] = useState(ActualPage.FORM);

  function showFirstNonCardMethod() {
    if (paymentGateways[0].taxes[0].boleto !== null) {
      return 'BOLETO';
    }
    if (paymentGateways[0].taxes[0].dinheiro !== null) {
      return 'DINHEIRO';
    }
    if (paymentGateways[0].taxes[0].doc !== null) {
      return 'DOC';
    }
    if (paymentGateways[0].taxes[0].pix !== null) {
      return 'PIX';
    }
    if (paymentGateways[0].taxes[0].ted !== null) {
      return 'TED';
    }
    return 'OUTRO';
  }

  function createForm() {
    setInstallmentCtx({
      contractPjPaymentId: 0,
      dueDate: showTodayDate(),
      status: 'UNPAID',
      paidBy: '',
      value: -0,
      paymentGatewayId: paymentGateways[0].id,
      nonCardTaxes: showFirstNonCardMethod(),
      cardLastDigits: '',
      cardPaymentOption: '',
      cieloInstallmentsGroup: null,
      card: false,
      flag: '',
    });
  }

  function showNonCardPaymentMethods(installment: InstallmentDTO) {
    const result: string[] = [];
    if (paymentGateways.length > 0) {
      paymentGateways.filter((item) => item.id === installment.paymentGatewayId).forEach((item) => {
        item.taxes.forEach((taxes) => {
          if (taxes.boleto !== null) {
            result.push('BOLETO');
          }
          if (taxes.dinheiro !== null) {
            result.push('DINHEIRO');
          }
          if (taxes.doc !== null) {
            result.push('DOC');
          }
          if (taxes.pix !== null) {
            result.push('PIX');
          }
          if (taxes.ted !== null) {
            result.push('TED');
          }
        });
      });
    }

    return result;
  }

  function showCardPaymentMethods(installment: InstallmentDTO) {
    const filteredGateway = paymentGateways.filter((item) => item.id === installment.paymentGatewayId);
    if (filteredGateway.length > 0) {
      const filteredCardsByFlag = filteredGateway[0].card.filter((card) => {
        if (installment.flag === 'OUTRA') {
          return card.flag === 'OTHER';
        }
        return card.flag === installment.flag;
      });
      return showCardPaymentWays(filteredCardsByFlag);
    }
    return [];
  }

  function showFlag(flag: string) {
    if (flag === 'OTHER') {
      return 'OUTRA';
    }
    return flag;
  }

  function showCardFlag(installment: InstallmentDTO) {
    const filteredGateway = paymentGateways.filter((item) => item.id === installment.paymentGatewayId);

    if (filteredGateway.length > 0) {
      return filteredGateway[0].card.map((item) => {
        if (item.flag === 'OTHER') {
          return 'OUTRA';
        }
        return item.flag;
      });
    }
    return [];
  }

  function changePaymentOption(method: string) {
    if (method === 'CARTÃO') {
      installmentCtx.card = true;
      installmentCtx.cieloInstallmentsGroup = null;
      installmentCtx.nonCardTaxes = '';
      installmentCtx.flag = showCardFlag(installmentCtx)[0];
      installmentCtx.cardPaymentOption = showCardPaymentMethods(installmentCtx)[0];
      setForceRefresh((prev) => !prev);
    } else {
      const filteredGateway = paymentGateways.filter((item) => item.name === item.gateway);
      const nonCardPaymentOptions = showNonCardPaymentWays(filteredGateway[0]);
      installmentCtx.card = false;
      installmentCtx.cieloInstallmentsGroup = null;
      installmentCtx.flag = null;
      installmentCtx.cardPaymentOption = '';
      installmentCtx.cardLastDigits = '';
      installmentCtx.nonCardTaxes = nonCardPaymentOptions[0];
      setForceRefresh((prev) => !prev);
    }
  }

  function changePaymentGateway(gateway: string) {
    const filteredGateway = paymentGateways.filter((item) => item.name === gateway);

    const cardPaymentOptions = showCardPaymentWays(filteredGateway[0].card);
    const nonCardPaymentOptions = showNonCardPaymentWays(filteredGateway[0]);

    if (nonCardPaymentOptions.length > 0) {
      installmentCtx.paymentGatewayId = filteredGateway[0].id;
      installmentCtx.card = false;
      installmentCtx.cieloInstallmentsGroup = null;
      installmentCtx.cardLastDigits = '';
      installmentCtx.flag = '';
      installmentCtx.cardPaymentOption = '';
      installmentCtx.nonCardTaxes = nonCardPaymentOptions[0];
      setForceRefresh((prev) => !prev);
    } else if (cardPaymentOptions.length > 0) {
      installmentCtx.paymentGatewayId = filteredGateway[0].id;
      installmentCtx.card = true;
      installmentCtx.cieloInstallmentsGroup = null;
      installmentCtx.cardLastDigits = '';
      installmentCtx.flag = filteredGateway[0].card[0].flag;
      installmentCtx.cardPaymentOption = cardPaymentOptions[0];
      installmentCtx.nonCardTaxes = '';
      setForceRefresh((prev) => !prev);
    }
    // } else {
    //   setModal({ title: 'Ocorreu um erro', children: 'O método selecionado não possui formas de pagamento disponíveis', actions: [{ label: 'Ok, entendi', type: ModalActionType.DANGER, onClick: () => setModal(null) }] });
    // }
  }

  function showAvailablePaymentOption(idCtx: number) {
    const result: string[] = [];
    const filteredMethod = paymentGateways.filter((item) => item.id === idCtx);
    if (filteredMethod.length > 0) {
      if (filteredMethod[0].card.length > 0) {
        result.push('CARTÃO');
      }
      if (filteredMethod[0].taxes.length > 0) {
        result.push('OUTRO');
      }
    }
    return result;
  }

  function setCardFlag(flag: string) {
    installmentCtx.flag = flag;
    installmentCtx.cardPaymentOption = showCardPaymentMethods(installmentCtx)[0];
    setForceRefresh((prev) => !prev);
  }

  function setPaymentWay(way: string) {
    installmentCtx.nonCardTaxes = way;
    installmentCtx.cieloInstallmentsGroup = null;
    setForceRefresh((prev) => !prev);
    // setInstallmentCtx({ ...installmentCtx, nonCardTaxes: way });
  }

  function changeInstallmentGroup(digits: number) {
    setInstallmentCtx({ ...installmentCtx, cieloInstallmentsGroup: digits });
  }

  function validateBeforeSave() {
    if (
      installmentCtx.card
      && installmentCtx.paymentGatewayId === 4
      && installmentCtx.cardPaymentOption !== 'Débito'
      && installmentCtx.cardPaymentOption !== 'Crédito 1X'
      && !installmentCtx.cieloInstallmentsGroup
    ) {
      return false;
    }
    return true;
  }

  function changeCardPaymentOption(option: string) {
    setInstallmentCtx({ ...installmentCtx, cardPaymentOption: option });
  }

  function changeCardLastDigits(digits: string) {
    setInstallmentCtx({ ...installmentCtx, cardLastDigits: digits });
  }

  function showGatewayName(idx: number) {
    if (idx === 1) {
      return 'PagAzul PRESENCIAL';
    }
    const filteredGateway = paymentGateways.filter((item) => item.id === idx);
    if (filteredGateway.length > 0) {
      return filteredGateway[0].name;
    }
    return '';
  }

  async function closeModal() {
    window.location.reload();
  }

  async function saveInstallment() {
    try {
      setActualPage(ActualPage.LOADING);
      const cardPaymentOption = installmentCtx.cardPaymentOption !== null ? installmentCtx.cardPaymentOption : '';
      const flag = installmentCtx.flag === 'OUTRA' ? 'OTHER' : installmentCtx.flag;
      const normalizedCardPaymentOption = normalizeToApiCardPaymentWays(cardPaymentOption);
      const payload = {
        ...installmentCtx, contractPjPaymentId: paymentId, cardPaymentOption: normalizedCardPaymentOption, flag,
      };
      await api.post('/admin/contractpj/installment/', {
        installments: [payload],
      }, token);
      setActualPage(ActualPage.SUCCESS);
    } catch (err: any) {
      console.log(err.response);
      setActualPage(ActualPage.ERROR);
    }
  }

  useEffect(() => {
    createForm();
  }, []);

  return (
    <div className="modal fade" id={id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeModal()} />
          </div>
          <div className="modal-body">

            { actualPage === ActualPage.LOADING && (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
              <p className="fw-bold text-muted">Criando parcela, aguarde...</p>
            </div>
            )}

            { actualPage === ActualPage.SUCCESS && (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
              <p className="fw-bold text-muted">Parcela criada com sucesso!</p>
            </div>
            )}

            {actualPage === ActualPage.ERROR && (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
              <p className="fw-bold text-muted">Ocorreu um erro ao criar parcela!</p>
            </div>
            )}

            {actualPage === ActualPage.FORM && (
            <div className="container">
              <div className="row products">
                <div className="col-12 card-bg p-4">
                  <div className="row">
                    <div className="payment-status">
                      <h1>
                        Nova parcela
                      </h1>
                      <span>
                        {resolvePaymentStatus(installmentCtx.status, installmentCtx.dueDate)}
                      </span>
                    </div>

                    <div className="col-12">
                      <BasicInput
                        id="data-vencimento"
                        label="Data de vencimento"
                        readOnly={false}
                        helper="Ex: 30-04-2022"
                        error={installmentCtx.dueDate.length < '00-00-0000'.length ? 'Digite uma data válida' : ''}
                        value={installmentCtx.dueDate}
                        type="text"
                        onChange={(data) => setInstallmentCtx({ ...installmentCtx, dueDate: maskDate(data) })}
                      />
                    </div>
                    <div className="col-12">
                      <PriceInput
                        id="valor-parcela"
                        label="Valor Bruto"
                        readOnly={false}
                        helper="Ex: 600"
                        error={installmentCtx.value === 0 ? 'Digite um valor' : ''}
                        value={installmentCtx.value.toString()}
                        type="number"
                        onChange={(data) => setInstallmentCtx({ ...installmentCtx, value: Number(data) })}
                      />
                    </div>
                    <div className="col-12">
                      <BasicOption
                        id="gateway-de-pagamento"
                        label="Gateway de pagamento"
                        readOnly={false}
                        helper="Ex: PagAzul Web"
                        error=""
                        value={showGatewayName(installmentCtx.paymentGatewayId)}
                        onChange={(data) => changePaymentGateway(data)}
                      >
                        {paymentGateways.map((gateway) => gateway.name)}
                      </BasicOption>
                    </div>
                    <div className="col-12">
                      <BasicOption
                        id="opcoes-de-pagamento"
                        label="Opções de pagamento"
                        readOnly={false}
                        helper="Ex: Cartão"
                        error=""
                        value={installmentCtx.card ? 'CARTÃO' : 'OUTRO'}
                        onChange={(data) => changePaymentOption(data)}
                      >
                        {showAvailablePaymentOption(installmentCtx.paymentGatewayId).map((method) => method)}
                      </BasicOption>
                    </div>

                    {installmentCtx.card && (
                    <div className="col-12">
                      <BasicOption
                        id="flag"
                        label="Bandeira"
                        readOnly={false}
                        helper="Ex: VISA"
                        error=""
                        value={installmentCtx.flag !== null ? showFlag(installmentCtx.flag) : ''}
                        onChange={(data) => setCardFlag(data)}
                      >
                        {showCardFlag(installmentCtx)}
                      </BasicOption>
                    </div>
                    )}

                    {installmentCtx.card && (
                    <div className="col-12">
                      <BasicOption
                        id="card-way-"
                        label="Forma de pagamento"
                        readOnly={false}
                        helper="Ex: Crédito 12x"
                        error=""
                        value={installmentCtx.cardPaymentOption !== null ? convertCardPaymentMethodFromApi(installmentCtx.cardPaymentOption) : ''}
                        onChange={(data) => changeCardPaymentOption(data)}
                      >
                        {showCardPaymentMethods(installmentCtx)}
                      </BasicOption>
                    </div>
                    )}

                    {installmentCtx.card && (
                    <div className="col-12">
                      <BasicInput
                        id="card-last-digits"
                        label="Ultimos 04 dígitos"
                        readOnly={false}
                        helper="Ex: 2144"
                        value={installmentCtx.cardLastDigits ? installmentCtx.cardLastDigits.toString() : ''}
                        onChange={(data) => (data.length < 5 ? changeCardLastDigits(data) : null)}
                        type="number"
                        error=""
                      />
                    </div>
                    )}

                    {installmentCtx.card && installmentCtx.paymentGatewayId === 4 && installmentCtx.cardPaymentOption !== 'Débito' && installmentCtx.cardPaymentOption !== 'Crédito 1X' && (
                    <div className="col-12">
                      <BasicInput
                        id="cielogroup"
                        label="Grupo de parcelas"
                        readOnly={false}
                        helper="Ex: 2"
                        value={installmentCtx.cieloInstallmentsGroup ? installmentCtx.cieloInstallmentsGroup.toString() : ''}
                        onChange={(data) => (data.length < 5 ? changeInstallmentGroup(Number(data)) : null)}
                        type="number"
                        error={!installmentCtx.cieloInstallmentsGroup ? 'Campo obrigatório' : ''}
                      />
                    </div>
                    )}

                    {!installmentCtx.card && (
                    <div className="col-12">
                      <BasicOption
                        id="way-"
                        label="Forma de pagamento"
                        readOnly={false}
                        helper="Ex: PIX"
                        error=""
                        value={installmentCtx.nonCardTaxes !== null ? installmentCtx.nonCardTaxes : ''}
                        onChange={(data) => setPaymentWay(data)}
                      >
                        {showNonCardPaymentMethods(installmentCtx)}
                      </BasicOption>
                    </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
            )}

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => closeModal()}>Fechar</button>
            {ActualPage.FORM === actualPage && (
              <button type="button" disabled={!validateBeforeSave()} className="btn btn-primary" onClick={() => (installmentCtx.value > 0 ? saveInstallment() : null)}>Salvar</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
