/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import api from '../../../config/axios';
import DefaultLayout from '../../../Layout';

type ExamProps = {
    id: number,
    categoryExamsId: number,
    accreditedId: number,
    description: string,
    value: string,
    observation: null | string,
}

type AccrecreditedProps = {
    id: number;
    name: string
}

type CategoryProps = {
    id: number;
    title: string;
}

export default function EditExam() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [accrediteds, setAccrediteds] = useState<AccrecreditedProps[]>([]);
  const [categories, setCategories] = useState<CategoryProps[]>([]);
  const [selectedAccredited, setSelectedAccredited] = useState<AccrecreditedProps>({
    id: 10000000,
    name: 'SELECIONE',
  });
  const [selectedCategory, setSelectedCategory] = useState<CategoryProps>({
    id: 10000000,
    title: 'SELECIONE',
  });
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [exam, setExam] = useState<ExamProps>({
    id: 100000000,
    categoryExamsId: 100000,
    accreditedId: 1000000000,
    description: '',
    value: '',
    observation: '',
  });

  function enableButton() {
    return exam.description !== '';
  }

  async function fetchPreload(): Promise<{
    accrediteds: AccrecreditedProps[],
    categories: CategoryProps[]
  }> {
    try {
      setLoading(true);
      const response = await api.get('/admin/exams-preload', token);
      setAccrediteds(response.data.accrediteds);
      setCategories(response.data.categories);
      return {
        accrediteds: response.data.accrediteds,
        categories: response.data.categories,
      };
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      return {
        accrediteds: [],
        categories: [],
      };
    }
  }

  async function fetchExams() {
    try {
      setLoading(true);
      const preload = await fetchPreload();
      if (preload.accrediteds.length === 0) {
        setModal({ title: 'Ocorreu um erro', children: 'Não foi possível carregar lista de credenciados.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => navigate(-1) }] });
        return;
      }
      if (preload.categories.length === 0) {
        setModal({ title: 'Ocorreu um erro', children: 'Não foi possível carregar lista de categorias.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => navigate(-1) }] });
        return;
      }
      const response = await api.get<ExamProps>(`admin/exams/${id}`, token);
      setExam(response.data);
      setSelectedAccredited(preload.accrediteds.filter((item) => item.id === response.data.accreditedId)[0]);
      setSelectedCategory(preload.categories.filter((item) => item.id === response.data.categoryExamsId)[0]);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível buscar exame.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => navigate(-1) }] });
    }
  }

  async function saveExam() {
    try {
      setLoading(true);
      await api.put(`admin/exams/${id}`, {
        ...exam,
        accreditedId: selectedAccredited.id,
        categoryExamsId: selectedCategory.id,
        observation: exam.observation === '' ? null : exam.observation,
      }, token);
      setLoading(false);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível editar exame.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  async function deleteExam() {
    try {
      setLoading(true);
      await api.delete(`admin/exams/${id}`, token);
      setLoading(false);
      navigate(-1);
    } catch (err: any) {
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível editar exame.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  useEffect(() => {
    fetchExams();
  }, []);

  return (
    <DefaultLayout
      pageTitle="Visualizar exame"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Visualizar Exame</h1>
          <div className="row">
            <div className="col-4">
              <BasicInput
                id="title-input"
                label="Descrição"
                readOnly={false}
                helper="Ultrassonografia"
                error={exam.description.length < 1 ? 'Campo obrigatório' : ''}
                value={exam.description}
                type="text"
                onChange={(data) => setExam({ ...exam, description: data })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="value-input"
                label="Valor"
                readOnly={false}
                helper="Ex: 100,00"
                error=""
                value={exam.value}
                type="text"
                onChange={(data) => setExam({ ...exam, value: data })}
              />
            </div>
            <div className="col-4">
              <BasicOption
                id="category-input"
                label="Categoria"
                readOnly={false}
                helper="Ex: 100,00"
                error=""
                value={selectedCategory.title}
                onChange={(data) => setSelectedCategory(categories.filter((item) => item.title === data)[0])}
              >
                {categories.map((item) => item.title)}
              </BasicOption>
            </div>
            <div className="col-4">
              <BasicOption
                id="accredited-input"
                label="Credenciado"
                readOnly={false}
                helper="Ex: HSM"
                error=""
                value={selectedAccredited.name}
                onChange={(data) => setSelectedAccredited(accrediteds.filter((item) => item.name === data)[0])}
              >
                {accrediteds.map((item) => item.name)}
              </BasicOption>
            </div>
            <div className="col-8">
              <BasicInput
                id="observation-input"
                label="Observação"
                readOnly={false}
                helper="Ex: Corte Axial"
                error=""
                value={exam.observation || ''}
                type="text"
                onChange={(data) => setExam({ ...exam, observation: data })}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="EXCLUIR"
                type={BtnType.DANGER}
                icon="delete"
                active
                onClick={() => deleteExam()}
                ref={null}
              />
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                active={enableButton()}
                onClick={() => saveExam()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>

    </DefaultLayout>
  );
}
