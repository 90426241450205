import React, { createRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicInput from '../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import DefaultLayout from '../../Layout';
import api from '../../config/axios';
import BasicOption from '../../components/BasicOption';
import PriceInput from '../../components/PriceInput';
import { maskDate } from '../../helpers/inputMasks.js';
import TextAreaInput from '../../components/TextAreaInput';
import dataURIToBlob from '../../helpers/fileReader';

interface IExpense {
    description: string,
    category: string,
    value: number,
    expenseDate: string,
    payed: boolean,
    paymentDate: string,
    paymentWay: string,
    paymentReference: string,
    notes: string,
  }

export default function NewExpense() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [file, setFile] = useState<string[] | null>([]);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [fileInputRef] = useState(createRef<any>());
  const [expense, setExpense] = useState<IExpense>({
    description: '',
    category: 'TRIBUTOS',
    value: 0,
    expenseDate: '',
    payed: false,
    paymentDate: '',
    paymentWay: 'PIX',
    paymentReference: '',
    notes: '',
  });

  function enableBtn() {
    if (
      expense.description.length > 0
          && expense.value.toString().length > 0
          && expense.expenseDate.length > 9
    ) {
      return true;
    }
    return false;
  }

  function createFormData() {
    const body = new FormData();
    if (file !== null) {
      file.forEach((item) => {
        const blob = dataURIToBlob(item);
        body.append('documents[]', blob);
      });
    } else {
      body.append('documents[]', '');
    }
    body.append('description', expense.description);
    body.append('category', expense.category);
    body.append('value', expense.value.toString());
    body.append('expenseDate', expense.expenseDate);
    body.append('payed', expense.payed.toString());
    body.append('paymentDate', expense.paymentDate);
    body.append('paymentWay', expense.paymentWay);
    body.append('paymentReference', expense.paymentReference);
    body.append('notes', expense.notes);

    return body;
  }

  function createObjectURL(data: React.ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();
    reader.readAsDataURL(data.target.files![0]);
    reader.onloadend = (ctx) => {
      if (ctx.loaded / 1024 < 4000) {
        if (file !== null) {
          const prevFiles = [...file];
          prevFiles.push(ctx.target!.result as string);
          setFile(prevFiles);
        } else {
          setFile([ctx.target!.result as string]);
        }
      } else {
        setModal({ title: 'Ocorreu um erro!', children: 'O arquivo selecionado ultrapassou o limite máximo de 4mb', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }
    };
    fileInputRef.current.value = '';
  }

  function removeLocalFile(fileCtx: string) {
    const newFiles = file!.filter((item) => item !== fileCtx);
    if (newFiles.length === 0) {
      setFile(null);
    } else {
      setFile(newFiles);
    }
  }

  async function saveExpense() {
    try {
      setLoading(true);
      const response = await api.post('/admin/expense', createFormData(), token);
      setLoading(false);
      navigate(-1);
    } catch (err: any) {
      setLoading(false);
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível cadastar despesa', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  return (
    <DefaultLayout
      pageTitle="Financeiro > Cadastrar despesa"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row new-sellers card-bg p-0">
        <section className="row">
          <h1>Cadastro de despesa</h1>
          <div className="col-4">
            <BasicInput
              id="Descrição-input"
              label="Descrição"
              readOnly={false}
              helper="Ex: Compra de papel A4"
              error={expense.description.length < 1 ? 'Campo obrigatório' : ''}
              value={expense.description}
              type="text"
              onChange={(data) => setExpense({ ...expense, description: data })}
            />
          </div>

          <div className="col-4">
            <BasicOption
              id="categoria-input"
              label="Tipo de despesa"
              readOnly={false}
              helper="Ex: Contabilidade"
              error=""
              value={expense.category}
              onChange={(data) => setExpense({ ...expense, category: data })}
            >
              {['TRIBUTOS', 'OUTROS', 'CONTABILIDADE']}
            </BasicOption>
          </div>

          <div className="col-4">
            <BasicOption
              id="formadepgto-input"
              label="Forma de pgto."
              readOnly={false}
              helper="Ex: PIX"
              error=""
              value={expense.paymentWay}
              onChange={(data) => setExpense({ ...expense, paymentWay: data })}
            >
              {['PIX',
                'CARTAO',
                'BOLETO',
                'DINHEIRO',
                'TED',
                'DOC',
                'OUTROS']}
            </BasicOption>
          </div>
          <div className="col-4">
            <PriceInput
              id="valor-input"
              label="Valor"
              readOnly={false}
              helper="Ex: 100.00"
              error={expense.value < 1 ? 'Campo obrigatório' : ''}
              value={expense.value.toString()}
              type="number"
              onChange={(data) => setExpense({ ...expense, value: Number(data) })}
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="foipago-input"
              label="Já foi pago?"
              readOnly={false}
              helper="Esse valor já foi pago?"
              error=""
              value={expense.payed ? 'Sim' : 'Não'}
              onChange={(data) => setExpense({ ...expense, payed: data === 'Sim' })}
            >
              {['Sim',
                'Não']}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicInput
              id="datadespesa-input"
              label="Data da despesa"
              readOnly={false}
              helper="Ex: 02-02-2022"
              error={expense.expenseDate.length < 1 ? 'Campo obrigatório' : ''}
              value={expense.expenseDate}
              type="text"
              onChange={(data) => setExpense({ ...expense, expenseDate: maskDate(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="datadespesa-input"
              label="Data de pagamento"
              readOnly={false}
              helper="Ex: 02-02-2022"
              error=""
              value={expense.paymentDate}
              type="text"
              onChange={(data) => setExpense({ ...expense, paymentDate: maskDate(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="numero-do-recibo-input"
              label="N. Recibo"
              readOnly={false}
              helper="Ex: Transação: 20202020 do Pix"
              error=""
              value={expense.paymentReference}
              type="text"
              onChange={(data) => setExpense({ ...expense, paymentReference: data })}
            />
          </div>
          <div className="col-12">
            <TextAreaInput
              id="notes-input"
              label="Observações"
              readOnly={false}
              helper="Ex: Pagamento referente ao contrato XPTO"
              error=""
              value={expense.notes}
              onChange={(data) => setExpense({ ...expense, notes: data })}
            />
          </div>
          {file !== null && (
          <div className="appends col-12 mt-4 mb-4">
            <p>Anexos</p>
            {file.map((item, i) => (
              <div className="append-wrapper">
                <span>{`Anexo ${i + 1}`}</span>
                <button type="button" className="text-danger" onClick={() => removeLocalFile(item)}>remover</button>
              </div>
            ))}
          </div>
          )}
          <div className="col-12 footer">
            <ButtonTextIcon
              label="ANEXO"
              type={BtnType.SECONDARY}
              icon="add"
              active
              onClick={() => fileInputRef.current.click()}
              ref={null}
            />
            <ButtonTextIcon
              label="salvar"
              type={BtnType.PRIMARY}
              icon="backup"
              active={enableBtn()}
              onClick={() => saveExpense()}
              ref={fileInputRef}
            />
            <input
              type="file"
              className="d-none"
              onChange={(data) => createObjectURL(data)}
              accept=".png, .jpeg, .jpg, .pdf, .xlss, .doc, .docx, .txt, .zip, .rar, .xls"
              ref={fileInputRef}
            />
          </div>
        </section>
      </div>
    </DefaultLayout>
  );
}
