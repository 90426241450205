import React, { createRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import BasicInput from '../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import DefaultLayout from '../../Layout';
import api from '../../config/axios';
import BasicOption from '../../components/BasicOption';
import PriceInput from '../../components/PriceInput';
import { maskDate } from '../../helpers/inputMasks.js';
import TextAreaInput from '../../components/TextAreaInput';
import dataURIToBlob from '../../helpers/fileReader';

interface IProfit {
    description: string,
    value: number,
    liquidValue: number,
    incomeDate: string,
    paymentWay: string,
    category: string,
    notes: string,
  }

export default function NewProfit() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [file, setFile] = useState<string[] | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [fileInputRef] = useState(createRef<any>());
  const [profit, setProfit] = useState<IProfit>({
    description: '',
    value: 0,
    incomeDate: '',
    paymentWay: 'PIX',
    category: 'INVESTIMENTO',
    liquidValue: 0,
    notes: '',
  });

  function enableBtn() {
    if (
      profit.description.length > 0
          && profit.value.toString().length > 0
          && profit.incomeDate.length > 9
    ) {
      return true;
    }
    return false;
  }

  function removeLocalFile(fileCtx: string) {
    const newFiles = file!.filter((item) => item !== fileCtx);
    if (newFiles.length === 0) {
      setFile(null);
    } else {
      setFile(newFiles);
    }
  }

  function createFormData() {
    const body = new FormData();
    if (file !== null) {
      file.forEach((item) => {
        const blob = dataURIToBlob(item);
        body.append('documents[]', blob);
      });
    } else {
      body.append('documents[]', '');
    }
    body.append('description', profit.description);
    body.append('value', profit.value.toString());
    body.append('category', profit.category);
    body.append('paymentWay', profit.paymentWay);
    body.append('incomeDate', profit.incomeDate);
    body.append('liquidValue', profit.liquidValue.toString());
    body.append('notes', profit.notes);

    return body;
  }

  function createObjectURL(data: React.ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();
    reader.readAsDataURL(data.target.files![0]);
    reader.onload = (ctx) => {
      if (ctx.loaded / 1024 < 4000) {
        if (file !== null) {
          const prevFiles = [...file];
          prevFiles.push(ctx.target!.result as string);
          setFile(prevFiles);
        } else {
          setFile([ctx.target!.result as string]);
        }
      } else {
        setModal({ title: 'Ocorreu um erro!', children: 'O arquivo selecionado ultrapassou o limite máximo de 4mb', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }
    };
    fileInputRef.current.value = '';
  }

  function changeProfit(data: string) {
    if (data === 'Crédito 1X') {
      setProfit({ ...profit, paymentWay: 'credit_1' });
    } else if (data === 'Crédito 2X') {
      setProfit({ ...profit, paymentWay: 'credit_2' });
    } else if (data === 'Crédito 3X') {
      setProfit({ ...profit, paymentWay: 'credit_3' });
    } else if (data === 'Crédito 4X') {
      setProfit({ ...profit, paymentWay: 'credit_4' });
    } else if (data === 'Crédito 5X') {
      setProfit({ ...profit, paymentWay: 'credit_5' });
    } else if (data === 'Crédito 6X') {
      setProfit({ ...profit, paymentWay: 'credit_6' });
    } else if (data === 'Crédito 7X') {
      setProfit({ ...profit, paymentWay: 'credit_7' });
    } else if (data === 'Crédito 8X') {
      setProfit({ ...profit, paymentWay: 'credit_8' });
    } else if (data === 'Crédito 9X') {
      setProfit({ ...profit, paymentWay: 'credit_9' });
    } else if (data === 'Crédito 10X') {
      setProfit({ ...profit, paymentWay: 'credit_10' });
    } else if (data === 'Crédito 11X') {
      setProfit({ ...profit, paymentWay: 'credit_11' });
    } else if (data === 'Crédito 12X') {
      setProfit({ ...profit, paymentWay: 'credit_12' });
    } else if (data === 'Crédito 13X') {
      setProfit({ ...profit, paymentWay: 'credit_13' });
    } else if (data === 'Crédito 14X') {
      setProfit({ ...profit, paymentWay: 'credit_14' });
    } else if (data === 'Crédito 15X') {
      setProfit({ ...profit, paymentWay: 'credit_15' });
    } else if (data === 'Crédito 16X') {
      setProfit({ ...profit, paymentWay: 'credit_16' });
    } else if (data === 'Crédito 17X') {
      setProfit({ ...profit, paymentWay: 'credit_17' });
    } else if (data === 'Crédito 18X') {
      setProfit({ ...profit, paymentWay: 'credit_18' });
    } else if (data === 'DÉBITO') {
      setProfit({ ...profit, paymentWay: 'debit' });
    } else {
      setProfit({ ...profit, paymentWay: data });
    }
  }

  function resolveProfit(data: string) {
    if (data === 'debit') {
      return 'DÉBITO';
    } if (data === 'credit_1') {
      return 'Crédito 1X';
    } if (data === 'credit_2') {
      return 'Crédito 2X';
    } if (data === 'credit_3') {
      return 'Crédito 3X';
    } if (data === 'credit_4') {
      return 'Crédito 4X';
    } if (data === 'credit_5') {
      return 'Crédito 5X';
    } if (data === 'credit_6') {
      return 'Crédito 6X';
    } if (data === 'credit_7') {
      return 'Crédito 7X';
    } if (data === 'credit_8') {
      return 'Crédito 8X';
    } if (data === 'credit_9') {
      return 'Crédito 9X';
    } if (data === 'credit_10') {
      return 'Crédito 10X';
    } if (data === 'credit_11') {
      return 'Crédito 11X';
    } if (data === 'credit_12') {
      return 'Crédito 12X';
    } if (data === 'credit_13') {
      return 'Crédito 13X';
    } if (data === 'credit_14') {
      return 'Crédito 14X';
    } if (data === 'credit_15') {
      return 'Crédito 15X';
    } if (data === 'credit_16') {
      return 'Crédito 16X';
    } if (data === 'credit_17') {
      return 'Crédito 17X';
    } if (data === 'credit_18') {
      return 'Crédito 18X';
    }
    return data;
  }

  async function saveProfit() {
    try {
      setLoading(true);
      await api.post('/admin/income', createFormData(), token);
      setLoading(false);
      navigate(-1);
    } catch (err: any) {
      setLoading(false);
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível cadastar entrada', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  return (
    <DefaultLayout
      pageTitle="Financeiro > Cadastrar entrada"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row new-sellers card-bg p-0">
        <section className="row">
          <h1>Cadastro de entradas</h1>
          <div className="col-4">
            <BasicInput
              id="Descrição-input"
              label="Descrição"
              readOnly={false}
              helper="Ex: Aporte financeiro"
              error={profit.description.length < 1 ? 'Campo obrigatório' : ''}
              value={profit.description}
              type="text"
              onChange={(data) => setProfit({ ...profit, description: data })}
            />
          </div>

          <div className="col-4">
            <PriceInput
              id="valor-input"
              label="Valor Bruto"
              readOnly={false}
              helper="Ex: 100.00"
              error={profit.value < 1 ? 'Campo obrigatório' : ''}
              value={profit.value.toString()}
              type="number"
              onChange={(data) => setProfit({ ...profit, value: Number(data) })}
            />
          </div>
          <div className="col-4">
            <PriceInput
              id="valor-liquido-input"
              label="Valor Líquido"
              readOnly={false}
              helper="Ex: 100.00"
              error={profit.liquidValue < 1 ? 'Campo obrigatório' : ''}
              value={profit.liquidValue.toString()}
              type="number"
              onChange={(data) => setProfit({ ...profit, liquidValue: Number(data) })}
            />
          </div>

          <div className="col-4">
            <BasicOption
              id="categoria-input"
              label="Tipo de receita"
              readOnly={false}
              helper="Ex: Investimento"
              error=""
              value={profit.category}
              onChange={(data) => setProfit({ ...profit, category: data })}
            >
              {['INVESTIMENTO', 'OUTROS']}
            </BasicOption>
          </div>

          <div className="col-4">
            <BasicOption
              id="formadeentrda-input"
              label="Forma da entrada."
              readOnly={false}
              helper="Ex: PIX"
              error=""
              value={resolveProfit(profit.paymentWay)}
              onChange={(data) => changeProfit(data)}
            >
              {[
                'Crédito 1X',
                'Crédito 2X',
                'Crédito 3X',
                'Crédito 4X',
                'Crédito 5X',
                'Crédito 6X',
                'Crédito 7X',
                'Crédito 8X',
                'Crédito 9X',
                'Crédito 10X',
                'Crédito 11X',
                'Crédito 12X',
                'Crédito 13X',
                'Crédito 14X',
                'Crédito 15X',
                'Crédito 16X',
                'Crédito 17X',
                'Crédito 18X',
                'PIX',
                'BOLETO',
                'DINHEIRO',
                'TED',
                'DOC',
                'DEPOSITO',
                'DÉBITO',
              ]}

            </BasicOption>
          </div>

          <div className="col-4">
            <BasicInput
              id="datadeprofit-input"
              label="Data de pagamento"
              readOnly={false}
              helper="Ex: 02-02-2022"
              error=""
              value={profit.incomeDate}
              type="text"
              onChange={(data) => setProfit({ ...profit, incomeDate: maskDate(data) })}
            />
          </div>

          <div className="col-12">
            <TextAreaInput
              id="notes-input"
              label="Observações"
              readOnly={false}
              helper="Ex: Informações adicionais"
              error=""
              value={profit.notes}
              onChange={(data) => setProfit({ ...profit, notes: data })}
            />
          </div>
          {file !== null && (
          <div className="appends col-12 mt-4 mb-4">
            <p>Novos Anexos</p>
            {file.map((item, i) => (
              <div className="append-wrapper">
                <span>{`Anexo ${i + 1}`}</span>
                <button type="button" className="text-danger" onClick={() => removeLocalFile(item)}>remover</button>
              </div>
            ))}
          </div>
          )}
          <div className="col-12 footer">
            <ButtonTextIcon
              label="ANEXO"
              type={BtnType.SECONDARY}
              icon="add"
              active
              onClick={() => fileInputRef.current.click()}
              ref={null}
            />
            <ButtonTextIcon
              label="salvar"
              type={BtnType.PRIMARY}
              icon="backup"
              active={enableBtn()}
              onClick={() => saveProfit()}
              ref={null}
            />
            <input
              type="file"
              className="d-none"
              onChange={(data) => createObjectURL(data)}
              accept=".png, .jpeg, .jpg, .pdf, .xlss, .doc, .docx, .txt, .zip, .rar, .xls"
              ref={fileInputRef}
            />
          </div>
        </section>
      </div>
    </DefaultLayout>
  );
}
