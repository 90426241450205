import React from 'react';
import BasicInput from '../../components/BasicInput';

interface IEditCategoryModal {
    id: number;
    name: string;
    onChange: (arg0: string) => void
}

export default function EditCategoryModal({ name, onChange, id }: IEditCategoryModal) {
  return (
    <BasicInput
      id={id.toString()}
      label="Nome da categoria"
      readOnly={false}
      helper="Ex: Óticas"
      error={name.length < 1 ? 'Campo obrigatório' : ''}
      value={name}
      type="text"
      onChange={(data) => onChange(data)}
    />
  );
}
