/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import BasicInput from '../../../../components/BasicInput';
import api from '../../../../config/axios';
import showListOfStringsOnTable from '../../../../helpers/showListOfStringsOnTable';
import { AccreditedProps, CategoryProps } from '../FilterTable';
import ShowProfessionalModal from './ShowProfessionalModal';

export type ResponseProps = {
    accredited: AccreditedProps[],
    category: CategoryProps[],
    councilRegister?: string,
    id: number,
    name: string,
    phone?: string,
    whatsapp?: string,
}

export type Response = {
  data: ResponseProps[],
  meta: {
    total: number,
    showing: number,
  }
}

interface FilterProfessionalProps {
    setLoading: (arg0: boolean) => void;
}

export default function FilterProfessional({ setLoading }: FilterProfessionalProps) {
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [data, setData] = useState<ResponseProps[]>([]);
  const [filter, setFilter] = useState('');
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [meta, setMeta] = useState({ total: 0, showing: 0 });

  async function fetchData() {
    try {
      setLoading(true);
      setIsFilterActive(false);
      const response = await api.post<Response>('admin/accredited-list/doctor', {}, token);
      setData(response.data.data);
      setMeta(response.data.meta);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log(err.response.data);
    }
  }

  async function fetchDataByFilter() {
    try {
      setLoading(true);
      setIsFilterActive(true);
      const response = await api.post<Response>('admin/accredited-list/doctor', {
        name: filter,
      }, token);
      setData(response.data.data);
      setMeta(response.data.meta);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log(err.response.data);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  return (

    <>
      <div className="row mb-2">
        <div className="col-12">
          <div className="d-flex justify-content-end">
            <div className="fetched-values-group">
              <div className="income-fetched-value-wrapper">
                <p className="" style={{ marginRight: 24 }}>
                  Exibindo:
                  {' '}
                  <span className="">{`${meta.showing} / ${meta.total}`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Profissionais</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <div className="m-2 d-flex align-items-center">
                <div className="mt-1">
                  <BasicInput
                    id="Busca-por-nome"
                    label="Nome"
                    readOnly={false}
                    helper=""
                    error=""
                    value={filter}
                    type="text"
                    onChange={(el) => setFilter(el)}
                  />
                </div>
                <button className="table-btn-primary m-2" type="button" onClick={() => fetchDataByFilter()}>
                  <span className="material-icons-round">
                    search
                  </span>
                  filtrar
                </button>
                {isFilterActive && (
                <button className="table-btn-danger m-2" type="button" onClick={() => fetchData()}>
                  <span className="material-icons-round">
                    delete
                  </span>
                  remover filtro
                </button>
                )}
              </div>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">Especialidade(s)</th>
                  <th scope="col">Telefone</th>
                  <th scope="col">WhatsApp</th>
                  <th scope="col">Vínculo(s)</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {data.length === 0 && (
                <p>Nenhum resultado</p>
                )}
                {data.map((item, i) => (
                  <>
                    <ShowProfessionalModal id={`show-professional-${item.id}`} data={item} />
                    <tr key={i}>
                      <td>{item.name}</td>
                      <td>{showListOfStringsOnTable(item.category.map((el) => el.title))}</td>
                      <td>{item.phone ? item.phone : 'N/A'}</td>
                      <td>{item.whatsapp ? item.whatsapp : 'N/A'}</td>
                      <td>{item.accredited.length}</td>
                      <td align="right">
                        <div className="d-flex justify-content-end align-items-center">
                          <div className="table-action-icon">
                            <button type="button" onClick={() => null} data-bs-toggle="modal" data-bs-target={`#show-professional-${item.id}`}>
                              <span className="material-icons-round">
                                more
                              </span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>

                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  );
}
