import React from 'react';

interface ILoadingWithLabelFullProps {
    label: string,
    percentage: number
}

export default function LoadingWithLabelFull({ label, percentage }: ILoadingWithLabelFullProps) {
  return (
    <div className="row loadingwithfulllabel">
      <div className="col-12 card-bg d-flex align-items-center justify-content-center flex-column" style={{ minHeight: 400 }}>
        <p className="label">{label}</p>
        <div className="loading-wrapper">
          <div className="loading-track-container">
            <div className="loading-tracker-bg" />
            <div className="loading-tracker" style={{ width: `${percentage}%` }} />
          </div>
        </div>
      </div>
    </div>
  );
}
