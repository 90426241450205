/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BasicInput from '../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import SwitcherPrimary from '../../components/SwitcherPrimary';
import Tooltip from '../../components/Tooltip';
import api from '../../config/axios';
import { maskCPF } from '../../helpers/inputMasks.js';
import showApiErrors from '../../helpers/showApiErrors';
import DefaultLayout from '../../Layout';

enum ActualPage {
  BLOCKED,
  NONBLOCKED,
}

type HolderPF = {
    id: number,
    name: string,
    cpf: string,
    uuid: string,
    dependents: number,
    activeContractId: number,
    isActive: boolean
}

type TableFilters = {
  total: number,
  perPage: number,
  currentPage: number,
  firstPage: number,
  lastPage: number,
  searchString: string,
  isActive: boolean,
}

export default function ClientPF() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [holders, setHolders] = useState<HolderPF[]>([]);
  const [blockedHolders, setBlockedHolders] = useState<HolderPF[]>([]);
  const [actualPage, setActualPage] = useState(ActualPage.NONBLOCKED);
  const [searchString, setSearchString] = useState('');
  const [tableFilters, setTableFilters] = useState<TableFilters>({
    total: 0,
    currentPage: 1,
    perPage: 20,
    firstPage: 1,
    lastPage: 1,
    searchString: '',
    isActive: false,
  });
  const [meta, setMeta] = useState<TableFilters>({
    total: 0,
    currentPage: 1,
    perPage: 20,
    firstPage: 1,
    lastPage: 1,
    searchString: '',
    isActive: false,
  });
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function fetchHolders() {
    try {
      setLoading(true);
      const response = tableFilters.searchString === '' ? await api.get(`/admin/holder-pf/?page=${tableFilters.currentPage}&block=${actualPage === ActualPage.BLOCKED}`, token) : await api.get(`/admin/holder-pf/?page=${tableFilters.currentPage}&search=${tableFilters.searchString}&block=${actualPage === ActualPage.BLOCKED}`, token);
      setHolders(response.data.data);
      setMeta(response.data.meta);
      setLoading(false);
    } catch (err) {
      setModal({ title: 'Ocorreu um erro!', children: 'Não foi possível buscar clientes', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.DANGER }] });
      setLoading(false);
    }
  }

  async function setActive(client: HolderPF) {
    try {
      setLoading(true);
      await api.put(`/admin/holder-pf/${client.id}`, {
        ...client, isActive: !client.isActive,
      }, token);
      fetchHolders();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      if (typeof err.response !== 'undefined') {
        if (err.response.status === 422) {
          setModal(showApiErrors(setModal, err.response.data.errors));
        } else {
          setShowToastError(true);
          setTimeout(() => {
            setShowToastError(false);
          }, 3000);
          setModal({ title: 'Ocorreu um erro', children: 'Não foi possível alterar status do titular', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
        }
      }
      console.log(err.response);
      setLoading(false);
    }
  }

  function generatePagination(totalOfPages: number) {
    const pagination: number[] = [];
    for (let i = 0; i < totalOfPages; i += 1) {
      pagination.push(i + 1);
    }
    return pagination;
  }

  function goToNextPage() {
    if (tableFilters.currentPage < meta.lastPage) {
      setTableFilters((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  }

  function searchByString() {
    setTableFilters({ ...tableFilters, isActive: true, searchString });
  }

  async function tempBlockPFClient(userId: number) {
    try {
      setLoading(true);
      setModal(null);
      await api.get(`admin/holder/block-temp/${userId}`, token);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
      await fetchHolders();
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível bloquear temporariamente o usuário', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function removeTempBlockPFClient(userId: number) {
    try {
      setLoading(true);
      setModal(null);
      await api.get(`admin/holder/unblock-temp/${userId}`, token);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
      setActualPage(ActualPage.NONBLOCKED);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível bloquear temporariamente o usuário', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  useEffect(() => {
    if (searchString === '') {
      setTableFilters({ ...tableFilters, searchString: '', isActive: false });
    }
  }, [searchString]);

  useEffect(() => {
    fetchHolders();
  }, [tableFilters]);

  useEffect(() => {
    fetchHolders();
  }, [actualPage]);

  useEffect(() => {
    fetchHolders();
  }, []);

  return (
    <DefaultLayout
      pageTitle="Clientes PF"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >

      <div className="row">
        <div className="col-12 header-btn-wrapper">
          <button
            type="button"
            className={actualPage === ActualPage.NONBLOCKED ? 'btn-active' : 'btn-inactive'}
            onClick={() => setActualPage(ActualPage.NONBLOCKED)}
            style={{ width: 'fit-content', paddingLeft: 8, paddingRight: 8 }}
          >
            Ativos
          </button>
          <button
            type="button"
            className={actualPage === ActualPage.BLOCKED ? 'btn-active' : 'btn-inactive'}
            onClick={() => setActualPage(ActualPage.BLOCKED)}
            style={{ width: 'fit-content', paddingLeft: 8, paddingRight: 8 }}
          >
            Bloqueados temporariamente
          </button>
        </div>
      </div>

      {actualPage === ActualPage.NONBLOCKED ? (
        <div className="row products mt-4">
          <div className="col-12 card-bg p-4">
            <div className="row">
              <h1>Titulares PF</h1>
              <div className="table-wrapper">
                <div className="table-actions">
                  <button className="table-btn-primary" type="button" onClick={() => navigate('/cliente/pessoa-fisica/adicionar-titular')}>
                    <span className="material-icons-round">
                      add
                    </span>
                    adicionar
                  </button>
                  <div className="m-2 d-flex align-items-center">
                    {tableFilters.isActive && (
                    <button
                      className="table-btn-primary m-2"
                      type="button"
                      onClick={() => {
                        setTableFilters({
                          ...tableFilters,
                          searchString: '',
                          isActive: false,
                        });
                        setSearchString('');
                      }}
                    >
                      <span className="material-icons-round">
                        delete
                      </span>
                      remover filtro
                    </button>
                    )}

                    <div className="mt-1">
                      <BasicInput
                        id="Busca-por-nome"
                        label="Nome"
                        readOnly={false}
                        helper=""
                        error=""
                        value={searchString}
                        type="text"
                        onChange={(data) => setSearchString(data)}
                      />
                    </div>
                    <button className="table-btn-primary m-2" type="button" onClick={() => searchByString()}>
                      <span className="material-icons-round">
                        search
                      </span>
                      filtrar
                    </button>
                  </div>
                </div>
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Nome</th>
                      <th scope="col">CPF</th>
                      <th scope="col">Dependentes</th>
                      <th scope="col">Contrato Ativo</th>
                      <th className="text-end" scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {holders.length === 0 && 'Nenhum resultado para sua busca.'}
                    {holders.map((item) => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{maskCPF(item.cpf)}</td>
                        <td>{item.dependents}</td>
                        <td>
                          {item.activeContractId ? (
                            <Link to={`/contratos/pf/visualizar/${item.activeContractId}`}>
                              PF000
                              {item.activeContractId}
                            </Link>
                          ) : ('N/A')}
                        </td>
                        <td align="right">
                          <div className="d-flex justify-content-end align-items-center">
                            {/* <div className="table-action-icon">
                              <button
                                type="button"
                                onClick={() => {
                                  setModal({ title: 'Desativar usuário', children: 'Deseja mesmo desativar temporariamente esse usuário?', actions: [{ label: 'Desativar', type: ModalActionType.PRIMARY, onClick: () => tempBlockPFClient(item.id) }, { label: 'Cancelar', type: ModalActionType.DANGER, onClick: () => setModal(null) }] });
                                }}
                                aria-describedby="manual"
                                id="baixa-manual"
                              >
                                <span className="material-icons-round" style={{ color: 'ORANGE' }}>
                                  lock_clock
                                </span>
                              </button>
                              <Tooltip id="baixa-manual" text="Essa ação irá desativar temporariamente o usuário." />
                            </div>
                            <SwitcherPrimary
                              id={`status-changer-${item.id}`}
                              onChange={() => setActive(item)}
                              isActive={item.isActive}
                            /> */}
                            <div className="table-action-icon">
                              <button type="button" onClick={() => navigate(`/cliente/pessoa-fisica/editar-titular/${item.id}`)}>
                                <span className="material-icons-round">
                                  more
                                </span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="table-footer">
                  <button type="button" onClick={() => setTableFilters((prev) => ({ ...prev, currentPage: 1 }))}>Primeira</button>
                  <p>Página</p>
                  <select style={{ minWidth: 300 }} value={meta.currentPage} onChange={(e) => setTableFilters({ ...tableFilters, currentPage: Number(e.target.value) })}>
                    {generatePagination(meta.lastPage).map((item) => <option value={item}>{item}</option>)}
                  </select>
                  <p>
                    de
                    {' '}
                    <strong>{meta.lastPage}</strong>
                  </p>
                  <button
                    type="button"
                    onClick={() => goToNextPage()}
                  >
                    Próxima

                  </button>
                  <button
                    type="button"
                    onClick={() => setTableFilters((prev) => ({ ...prev, currentPage: meta.lastPage }))}
                  >
                    Última

                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row products mt-4">
          <div className="col-12 card-bg p-4">
            <div className="row">
              <h1>Titulares PF</h1>
              <div className="table-wrapper">
                <div className="table-actions">
                  <button className="table-btn-primary" type="button" onClick={() => navigate('/cliente/pessoa-fisica/adicionar-titular')}>
                    <span className="material-icons-round">
                      add
                    </span>
                    adicionar
                  </button>
                  <div className="m-2 d-flex align-items-center">
                    {tableFilters.isActive && (
                    <button
                      className="table-btn-primary m-2"
                      type="button"
                      onClick={() => {
                        setTableFilters({
                          ...tableFilters,
                          searchString: '',
                          isActive: false,
                        });
                        setSearchString('');
                      }}
                    >
                      <span className="material-icons-round">
                        delete
                      </span>
                      remover filtro
                    </button>
                    )}

                    <div className="mt-1">
                      <BasicInput
                        id="Busca-por-nome"
                        label="Nome"
                        readOnly={false}
                        helper=""
                        error=""
                        value={searchString}
                        type="text"
                        onChange={(data) => setSearchString(data)}
                      />
                    </div>
                    <button className="table-btn-primary m-2" type="button" onClick={() => searchByString()}>
                      <span className="material-icons-round">
                        search
                      </span>
                      filtrar
                    </button>
                  </div>
                </div>
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Nome</th>
                      <th scope="col">CPF</th>
                      <th scope="col">Dependentes</th>
                      <th scope="col">Contrato Ativo</th>
                      <th className="text-end" scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {holders.length === 0 && 'Nenhum resultado para sua busca.'}
                    {holders.map((item) => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{maskCPF(item.cpf)}</td>
                        <td>{item.dependents}</td>
                        <td>
                          {item.activeContractId ? (
                            <Link to={`/contratos/pf/visualizar/${item.activeContractId}`}>
                              PF000
                              {item.activeContractId}
                            </Link>
                          ) : ('N/A')}
                        </td>
                        <td align="right">
                          <div className="d-flex justify-content-end align-items-center">
                            {/* <div className="table-action-icon">
                              <button
                                type="button"
                                onClick={() => {
                                  setModal({ title: 'Realizar baixa manual', children: 'Deseja mesmo remover o bloqueio temporário para esse usuário?', actions: [{ label: 'Remover bloqueio', type: ModalActionType.PRIMARY, onClick: () => removeTempBlockPFClient(item.id) }, { label: 'Cancelar', type: ModalActionType.DANGER, onClick: () => setModal(null) }] });
                                }}
                                aria-describedby="manual"
                                id="baixa-manual"
                              >
                                <span className="material-icons-round" style={{ color: 'ORANGE' }}>
                                  lock_reset
                                </span>
                              </button>
                              <Tooltip id="baixa-manual" text="Essa ação removerá o bloqueio temporário desse cliente." />
                            </div> */}
                            {/* <SwitcherPrimary
                              id={`status-changer-${item.id}`}
                              onChange={() => setActive(item)}
                              isActive={item.isActive}
                            /> */}
                            <div className="table-action-icon">
                              <button type="button" onClick={() => navigate(`/cliente/pessoa-fisica/editar-titular/${item.id}`)}>
                                <span className="material-icons-round">
                                  more
                                </span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="table-footer">
                  <button type="button" onClick={() => setTableFilters((prev) => ({ ...prev, currentPage: 1 }))}>Primeira</button>
                  <p>Página</p>
                  <select style={{ minWidth: 300 }} value={meta.currentPage} onChange={(e) => setTableFilters({ ...tableFilters, currentPage: Number(e.target.value) })}>
                    {generatePagination(meta.lastPage).map((item) => <option value={item}>{item}</option>)}
                  </select>
                  <p>
                    de
                    {' '}
                    <strong>{meta.lastPage}</strong>
                  </p>
                  <button
                    type="button"
                    onClick={() => goToNextPage()}
                  >
                    Próxima

                  </button>
                  <button
                    type="button"
                    onClick={() => setTableFilters((prev) => ({ ...prev, currentPage: meta.lastPage }))}
                  >
                    Última

                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </DefaultLayout>
  );
}
