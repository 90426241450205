/* eslint-disable no-useless-catch */
/* eslint-disable no-throw-literal */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BasicInput from '../../components/BasicInput';
import BasicOption from '../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import SwitcherPrimary from '../../components/SwitcherPrimary';
import Tooltip from '../../components/Tooltip';
import api from '../../config/axios';
import normalizeDate from '../../helpers/dates';
import { maskCPF, maskDate } from '../../helpers/inputMasks.js';
import showApiErrors from '../../helpers/showApiErrors';
import DefaultLayout from '../../Layout';
import UnblockClientModal from '../../components/UnblockPerma';

type Lifes = {
  name: string,
  id: number,
  contractid: number,
  isholder: boolean,
  ispf: boolean,
  uuid: string,
  reason: string | null,
  dtbloqueio: string | null
  clientid: null | number
}

type MetaProps = {
  total: number,
  totalHolder: number,
  totalDependent: number,
  totalPj: number,
  totalPf: number,
  per_page: number,
  current_page: number,
  last_page: number,
  first_page: number
}

type ResponseProps = {
  meta: MetaProps,
  data: Lifes[]
}

export default function PermBlockedPF() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [response, setResponse] = useState<ResponseProps>({
    meta: {
      total: 0,
      totalDependent: 0,
      totalHolder: 0,
      totalPf: 0,
      totalPj: 0,
      per_page: 50,
      current_page: 1,
      last_page: 1,
      first_page: 1,
    },
    data: [],
  });
  const [actualPage, setActualPage] = useState(1);
  const [nameFilter, setNameFilter] = useState('');
  const [reasonFilter, setReasolFilter] = useState('');
  const [isPfFilter, setIsPfFilter] = useState<boolean>();
  const [isHolderFilter, setIsHolderFilter] = useState<boolean>();
  const [initalDateFilter, setInitialDateFilter] = useState('');
  const [finalDateFilter, setFinalDateFilter] = useState('');
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function validateDate(date: string) {
    try {
      const dateCtx = DateTime.fromFormat(date, 'dd-MM-yyyy');
      if (!dateCtx.isValid) {
        throw new Error('Data inválida');
      }
      return {
        dateCtx,
      };
    } catch (err: any) {
      throw err.message;
    }
  }

  async function fetchData() {
    try {
      const obj = {
        currentPage: actualPage,
      };
      if (typeof isPfFilter !== 'undefined') {
        Object.assign(obj, { isPf: isPfFilter });
      }
      if (typeof isHolderFilter !== 'undefined') {
        Object.assign(obj, { isHolder: isHolderFilter });
      }
      if (initalDateFilter !== '') {
        await validateDate(initalDateFilter);
        Object.assign(obj, { initialDate: initalDateFilter });
      }
      if (finalDateFilter !== '') {
        await validateDate(finalDateFilter);
        Object.assign(obj, { finalDate: finalDateFilter });
      }
      if (reasonFilter !== '') {
        Object.assign(obj, { reason: reasonFilter });
      }
      if (nameFilter.trim() !== '') {
        Object.assign(obj, { name: nameFilter });
      }
      setLoading(true);
      const response = await api.post('admin/lifes-info/perma-block', {
        ...obj,
      }, token);
      setResponse(response.data);
      setLoading(false);
    } catch (err: any) {
      if (err === 'Data inválida') {
        setModal({ title: 'Ocorreu um erro!', children: 'Data do filtro inválida.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
      } else {
        setModal({ title: 'Ocorreu um erro!', children: 'Não foi possível buscar clientes', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.DANGER }] });
      }
      setLoading(false);
    }
  }

  async function refetch() {
    try {
      const obj = {
        currentPage: 1,
      };
      if (typeof isPfFilter !== 'undefined') {
        Object.assign(obj, { isPf: isPfFilter });
      }
      if (typeof isHolderFilter !== 'undefined') {
        Object.assign(obj, { isHolder: isHolderFilter });
      }
      if (initalDateFilter !== '') {
        await validateDate(initalDateFilter);
        Object.assign(obj, { initialDate: initalDateFilter });
      }
      if (finalDateFilter !== '') {
        await validateDate(finalDateFilter);
        Object.assign(obj, { finalDate: finalDateFilter });
      }
      if (reasonFilter !== '') {
        Object.assign(obj, { reason: reasonFilter });
      }
      if (nameFilter.trim() !== '') {
        Object.assign(obj, { name: nameFilter });
      }
      setLoading(true);
      const response = await api.post('admin/lifes-info/perma-block', {
        ...obj,
      }, token);
      setResponse(response.data);
      setLoading(false);
    } catch (err: any) {
      if (err === 'Data inválida') {
        setModal({ title: 'Ocorreu um erro!', children: 'Data do filtro inválida.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
      } else {
        setModal({ title: 'Ocorreu um erro!', children: 'Não foi possível buscar clientes', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.DANGER }] });
      }
      setLoading(false);
    }
  }

  function generateProfileLink(data: Lifes) {
    if (data.ispf) {
      if (data.isholder) {
        return `/cliente/pessoa-fisica/editar-titular/${data.id}`;
      }
      return `/cliente/editar-dependente-pf/${data.uuid}`;
    }
    if (data.isholder) {
      return `/cliente/editar-aderente/${data.clientid}/${data.id}`;
    }
    return `/cliente/editar-dependente/${data.uuid}`;
  }

  function generateContractLink(data: Lifes) {
    if (data.ispf) {
      return `/contratos/pf/visualizar/${data.contractid}`;
    }
    return `/contratos/pj/editar/${data.contractid}`;
  }

  function generatePagination(totalOfPages: number) {
    const pagination: number[] = [];
    for (let i = 0; i < totalOfPages; i += 1) {
      pagination.push(i + 1);
    }
    return pagination;
  }

  function goToNextPage() {
    if (actualPage < response.meta.last_page) {
      setActualPage((prev) => prev + 1);
    }
  }

  useEffect(() => {
    fetchData();
  }, [actualPage]);

  function resolveModality() {
    if (isPfFilter) {
      return 'Somente PF';
    }
    if (isPfFilter === false) {
      return 'Somente PJ';
    }
    return 'Todos';
  }

  function resolveType() {
    if (isHolderFilter) {
      return 'Somente titulares';
    }
    if (isHolderFilter === false) {
      return 'Somente dependentes';
    }
    return 'Todos';
  }

  function resolveUnblockType(isHolder: boolean, isPf: boolean): 'holderpf' | 'holderpj' | 'dependentpf' | 'dependentpj' | 'contractpf' | 'contractpj' {
    if (isHolder && isPf) {
      return 'holderpf';
    } if (isHolder && !isPf) {
      return 'holderpj';
    } if (!isHolder && isPf) {
      return 'dependentpf';
    }
    return 'dependentpj';
  }

  function resolveId(lifes: Lifes) {
    if (lifes.isholder && lifes.ispf) {
      return lifes.contractid.toString();
    }
    if (lifes.isholder && !lifes.ispf) {
      return lifes.id.toString();
    }
    return lifes.uuid.toString();
  }

  return (
    <DefaultLayout
      pageTitle="Associados cancelados"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row mb-2">
        <div className="col-12">
          <div className="d-flex justify-content-end mt-4">
            <div className="fetched-values-group">
              <div className="income-fetched-value-wrapper px-2">
                <p>
                  Total:
                  {' '}
                  <span className="text-primary">{`${response.meta.total}`}</span>
                </p>
              </div>
              <div className="income-fetched-value-wrapper px-2">
                <p>
                  Dependentes:
                  {' '}
                  <span className="text-primary">{`${response.meta.totalDependent}`}</span>
                </p>
              </div>
              <div className="income-fetched-value-wrapper px-2">
                <p>
                  Titulares:
                  {' '}
                  <span className="text-primary">{`${response.meta.totalHolder}`}</span>
                </p>
              </div>
              <div className="income-fetched-value-wrapper px-2">
                <p>
                  Total PJ:
                  {' '}
                  <span className="text-primary">{`${response.meta.totalPj}`}</span>
                </p>
              </div>
              <div className="income-fetched-value-wrapper px-2">
                <p>
                  Total PF:
                  {' '}
                  <span className="text-primary">{`${response.meta.totalPf}`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row products mt-4">
        <div className="col-12 card-bg p-4">
          <div className="row">
            <h1>Associados cancelados</h1>
            <div className="table-wrapper">
              <div className="table-actions">
                <div className="m-2 d-flex align-items-center">
                  <div className="mt-1" style={{ minWidth: 120 }}>
                    <BasicInput
                      id="Busca-por-nome"
                      label="Nome"
                      readOnly={false}
                      helper=""
                      error=""
                      value={nameFilter}
                      type="text"
                      onChange={(data) => setNameFilter(data)}
                    />
                  </div>
                  <div className="mt-1">
                    <BasicInput
                      id="motivo"
                      label="Motivo"
                      readOnly={false}
                      helper=""
                      error=""
                      value={reasonFilter}
                      type="text"
                      onChange={(data) => setReasolFilter(data)}
                    />
                  </div>
                  <div className="mt-1" style={{ width: 120, marginLeft: 20 }}>
                    <BasicOption
                      id="modalidade"
                      label="Modalidade"
                      readOnly={false}
                      helper=""
                      error=""
                      value={resolveModality()}
                      onChange={(data) => {
                        if (data === 'Todos') {
                          setIsPfFilter(undefined);
                        } else if (data === 'Somente PF') {
                          setIsPfFilter(true);
                        } else if (data === 'Somente PJ') {
                          setIsPfFilter(false);
                        }
                      }}
                    >
                      {['Todos', 'Somente PF', 'Somente PJ']}
                    </BasicOption>
                  </div>
                  <div className="mt-1" style={{ width: 120 }}>
                    <BasicOption
                      id="tipo"
                      label="Tipo"
                      readOnly={false}
                      helper=""
                      error=""
                      value={resolveType()}
                      onChange={(data) => {
                        if (data === 'Todos') {
                          setIsHolderFilter(undefined);
                        } else if (data === 'Somente titulares') {
                          setIsHolderFilter(true);
                        } else if (data === 'Somente dependentes') {
                          setIsHolderFilter(false);
                        }
                      }}
                    >
                      {['Todos', 'Somente titulares', 'Somente dependentes']}
                    </BasicOption>
                  </div>
                  <div className="mt-1">
                    <BasicInput
                      id="data-inicial"
                      label="Data inicial"
                      readOnly={false}
                      helper=""
                      error=""
                      value={initalDateFilter}
                      type="text"
                      onChange={(data) => setInitialDateFilter(maskDate(data))}
                    />
                  </div>
                  <div className="mt-1">
                    <BasicInput
                      id="data-final"
                      label="Data final"
                      readOnly={false}
                      helper=""
                      error=""
                      value={finalDateFilter}
                      type="text"
                      onChange={(data) => setFinalDateFilter(maskDate(data))}
                    />
                  </div>
                  <button className="table-btn-primary m-2" type="button" onClick={() => refetch()}>
                    <span className="material-icons-round">
                      search
                    </span>
                    filtrar
                  </button>
                </div>
              </div>
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Contrato</th>
                    <th scope="col">Motivo</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Data do cancelamento</th>
                    {/* {(admin.id === 2 || admin.id === 3) && (
                    <th className="text-end" scope="col">Ações</th>
                    )} */}
                  </tr>
                </thead>
                <tbody>
                  {response.data.length === 0 && 'Nenhum resultado para sua busca.'}
                  {response.data.map((item, i) => (
                    <tr key={i}>
                      <td><Link to={generateProfileLink(item)}>{item.name}</Link></td>
                      <td>
                        <Link to={generateContractLink(item)}>
                          {item.ispf ? 'PF' : 'PJ'}
                          {item.contractid}
                        </Link>
                      </td>
                      <td>{item.reason || ''}</td>
                      <td>{item.isholder ? 'Titular' : 'Dependente'}</td>
                      <td>{item.dtbloqueio ? normalizeDate(item.dtbloqueio) : ''}</td>
                      <td align="right">
                        <div className="d-flex justify-content-end align-items-center">
                          <div className="table-action-icon">
                            <button
                              type="button"
                              aria-describedby="manual"
                              id="unblock-perma"
                              data-bs-toggle="modal"
                              data-bs-target={`#unblock-perma-${item.id}`}
                            >
                              <span className="material-icons-round" style={{ color: 'orange' }}>
                                lock_open
                              </span>
                            </button>
                            <Tooltip id="bloq-perma" text="Essa ação irá desbloquear o usuário." />
                            <UnblockClientModal type={resolveUnblockType(item.isholder, item.ispf)} userId={resolveId(item)} id={`unblock-perma-${item.id}`} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="table-footer">
                <button type="button" onClick={() => setActualPage(1)}>Primeira</button>
                <p>Página</p>
                <select style={{ minWidth: 300 }} value={response.meta.current_page} onChange={(e) => setActualPage(Number(e.target.value))}>
                  {generatePagination(response.meta.last_page).map((item) => <option value={item}>{item}</option>)}
                </select>
                <p>
                  de
                  {' '}
                  <strong>{response.meta.last_page}</strong>
                </p>
                <button
                  type="button"
                  onClick={() => goToNextPage()}
                >
                  Próxima

                </button>
                <button
                  type="button"
                  onClick={() => setActualPage(response.meta.last_page)}
                >
                  Última
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
