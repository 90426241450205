/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalProps } from '../../components/Modal';
import api from '../../config/axios';
import DefaultLayout from '../../Layout';

type AllClientPJProps = {
  id: number,
  name: string,
  cnpj: string,
  block_perma: null | boolean,
  block_temp: null | boolean,
  is_active: true,
  activecontractid: number,
  holders: number,
  dependents: number,
  totaldependentecancelado: number,
  totaltitularcancelado: number,
  totaldependentebloqueado: number,
  totaltitularbloqueado: number
}

type TableFilters = {
  total: number,
  allActives: string,
  bloqueado: string,
  cancelado: string
  perPage: number,
  currentPage: number,
  firstPage: number,
  lastPage: number,
}

export default function ClientPJ() {
  const navigate = useNavigate();
  const [clientsPJ, setClientsPJ] = useState<AllClientPJProps[]>([]);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableFilters, setTableFilters] = useState<TableFilters>({
    total: 0,
    currentPage: 1,
    allActives: '0',
    bloqueado: '0',
    cancelado: '0',
    perPage: 20,
    firstPage: 1,
    lastPage: 1,
  });

  const [meta, setMeta] = useState<TableFilters>({
    total: 0,
    allActives: '0',
    bloqueado: '0',
    cancelado: '0',
    currentPage: 1,
    perPage: 20,
    firstPage: 1,
    lastPage: 1,
  });
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function fetchClientsPJ() {
    try {
      setLoading(true);
      const response = await api.get(`/admin/client/?page=${tableFilters.currentPage}`, token);
      console.log(response.data);
      setClientsPJ(response.data.data);
      setMeta(response.data.meta);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log(err);
    }
  }

  function generatePagination(totalOfPages: number) {
    const pagination: number[] = [];
    for (let i = 0; i < totalOfPages; i += 1) {
      pagination.push(i + 1);
    }
    return pagination;
  }

  function goToNextPage() {
    if (tableFilters.currentPage < meta.lastPage) {
      setTableFilters((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  }

  function calcTotalHoldersActive(total: number, bloq: number, cancel: number) {
    return Number(total) - (Number(bloq) + Number(cancel));
  }

  function calcTotalOfDependentsActive(total: number, bloq: number, cancel: number) {
    return Number(total) - (Number(bloq) + Number(cancel));
  }

  useEffect(() => {
    fetchClientsPJ();
  }, [tableFilters]);

  useEffect(() => {
    fetchClientsPJ();
  }, []);

  if (clientsPJ.length < 1) {
    return (
      <DefaultLayout
        pageTitle="Clientes Pessoa Jurídica"
        loading={loading}
        showToastSuccess={showToastSuccess}
        showToastError={showToastError}
        showRightSlider={false}
        rightSliderContent={undefined}
        modal={modal}
        setCloseRightSlider={() => null}
      >
        <div className="not-exsits">
          <span className="material-icons-round">
            not_interested
          </span>
          <h3 className="table-not-exists">Não há empresa cadastrada.</h3>
          <ButtonTextIcon
            label="Adicionar Empresa"
            type={BtnType.PRIMARY}
            icon="add"
            active
            onClick={() => navigate('/cliente/empresa/adicionar')}
            ref={null}
          />
        </div>

      </DefaultLayout>
    );
  }
  return (
    <DefaultLayout
      pageTitle="Clientes Pessoa Jurídica"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row mb-2">
        <div className="col-12">
          <div className="d-flex justify-content-end mt-4">
            <div className="fetched-values-group">
              <div className="income-fetched-value-wrapper">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#16C784" />
                    <path d="M11.4758 19H12.5547L12.5598 17.7477C14.743 17.5781 15.9949 16.2875 16 14.4555C15.9949 12.5141 14.5038 11.6281 12.9364 11.2289L12.6005 11.1359L12.6209 8.01328C13.4351 8.16641 13.9593 8.69141 14.0407 9.47891H15.8575C15.827 7.73984 14.5547 6.47109 12.631 6.27422L12.6361 5H11.5573L11.5522 6.27422C9.64377 6.48203 8.26972 7.73984 8.27481 9.55C8.27481 11.1578 9.33842 12.082 11.0534 12.5578L11.5115 12.6836L11.4911 15.9922C10.5802 15.8391 9.92366 15.2812 9.85751 14.2914H8C8.07125 16.3422 9.38422 17.5617 11.4809 17.7477L11.4758 19ZM12.57 15.9922L12.5903 12.9898C13.5165 13.2797 14.1069 13.6625 14.112 14.4391C14.1069 15.2484 13.5013 15.8281 12.57 15.9922ZM11.5216 10.8406C10.8193 10.6 10.1781 10.2008 10.1883 9.42422C10.1934 8.71875 10.687 8.17188 11.542 8.01328L11.5216 10.8406Z" fill="white" />
                  </svg>
                  Total bloqueados:
                  {' '}
                  <span className="text-primary">{meta.bloqueado}</span>
                </p>
              </div>
            </div>
            <div className="fetched-values-group" style={{ marginLeft: 24 }}>
              <div className="income-fetched-value-wrapper">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#16C784" />
                    <path d="M11.4758 19H12.5547L12.5598 17.7477C14.743 17.5781 15.9949 16.2875 16 14.4555C15.9949 12.5141 14.5038 11.6281 12.9364 11.2289L12.6005 11.1359L12.6209 8.01328C13.4351 8.16641 13.9593 8.69141 14.0407 9.47891H15.8575C15.827 7.73984 14.5547 6.47109 12.631 6.27422L12.6361 5H11.5573L11.5522 6.27422C9.64377 6.48203 8.26972 7.73984 8.27481 9.55C8.27481 11.1578 9.33842 12.082 11.0534 12.5578L11.5115 12.6836L11.4911 15.9922C10.5802 15.8391 9.92366 15.2812 9.85751 14.2914H8C8.07125 16.3422 9.38422 17.5617 11.4809 17.7477L11.4758 19ZM12.57 15.9922L12.5903 12.9898C13.5165 13.2797 14.1069 13.6625 14.112 14.4391C14.1069 15.2484 13.5013 15.8281 12.57 15.9922ZM11.5216 10.8406C10.8193 10.6 10.1781 10.2008 10.1883 9.42422C10.1934 8.71875 10.687 8.17188 11.542 8.01328L11.5216 10.8406Z" fill="white" />
                  </svg>
                  Total cancelados:
                  {' '}
                  <span className="text-primary">{meta.cancelado}</span>
                </p>
              </div>
            </div>
            <div className="fetched-values-group" style={{ marginLeft: 24 }}>
              <div className="income-fetched-value-wrapper">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#16C784" />
                    <path d="M11.4758 19H12.5547L12.5598 17.7477C14.743 17.5781 15.9949 16.2875 16 14.4555C15.9949 12.5141 14.5038 11.6281 12.9364 11.2289L12.6005 11.1359L12.6209 8.01328C13.4351 8.16641 13.9593 8.69141 14.0407 9.47891H15.8575C15.827 7.73984 14.5547 6.47109 12.631 6.27422L12.6361 5H11.5573L11.5522 6.27422C9.64377 6.48203 8.26972 7.73984 8.27481 9.55C8.27481 11.1578 9.33842 12.082 11.0534 12.5578L11.5115 12.6836L11.4911 15.9922C10.5802 15.8391 9.92366 15.2812 9.85751 14.2914H8C8.07125 16.3422 9.38422 17.5617 11.4809 17.7477L11.4758 19ZM12.57 15.9922L12.5903 12.9898C13.5165 13.2797 14.1069 13.6625 14.112 14.4391C14.1069 15.2484 13.5013 15.8281 12.57 15.9922ZM11.5216 10.8406C10.8193 10.6 10.1781 10.2008 10.1883 9.42422C10.1934 8.71875 10.687 8.17188 11.542 8.01328L11.5216 10.8406Z" fill="white" />
                  </svg>
                  Total ativos:
                  {' '}
                  <span className="text-primary">{meta.allActives}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row client">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Clientes PJ</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <button className="table-btn-primary" type="button" onClick={() => navigate('/cliente/empresa/adicionar')}>
                <span className="material-icons-round">
                  add
                </span>
                adicionar
              </button>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">Ass. Canc.</th>
                  <th scope="col">Ass. Bloq.</th>
                  <th scope="col">Titulares total</th>
                  <th scope="col">Titulares Atv.</th>
                  <th scope="col">Titulares Bloq.</th>
                  <th scope="col">Titulares Canc.</th>
                  <th scope="col">Dep. total</th>
                  <th scope="col">Dep Atv.</th>
                  <th scope="col">Dep Bloq.</th>
                  <th scope="col">Dep Canc.</th>
                  <th scope="col">Contrato</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {clientsPJ.map((item, i) => (
                  <tr key={i}>
                    <td className={item.block_perma ? 'link-red' : (item.block_temp ? 'link-orange' : '')}>{item.name}</td>
                    <td>{Number(item.totaltitularcancelado) + Number(item.totaldependentecancelado)}</td>
                    <td>{Number(item.totaldependentebloqueado) + Number(item.totaltitularbloqueado)}</td>
                    <td>{item.holders}</td>
                    <td>{calcTotalHoldersActive(item.holders, item.totaltitularbloqueado, item.totaltitularcancelado)}</td>
                    <td>{item.totaltitularbloqueado}</td>
                    <td>{item.totaltitularcancelado}</td>
                    <td>{item.dependents}</td>
                    <td>{calcTotalOfDependentsActive(item.dependents, item.totaldependentebloqueado, item.totaldependentecancelado)}</td>
                    <td>{item.totaldependentebloqueado}</td>
                    <td>{item.totaldependentecancelado}</td>
                    <td>
                      {item.activecontractid ? (
                        <Link to={`/contratos/pj/editar/${item.activecontractid}`}>
                          PJ000
                          {item.activecontractid}
                        </Link>
                      ) : ('N/A')}
                    </td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <button type="button" onClick={() => navigate(`/cliente/empresa/editar/${item.id}`)}>
                            <span className="material-icons-round">
                              more
                            </span>

                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="table-footer">
              <button type="button" onClick={() => setTableFilters((prev) => ({ ...prev, currentPage: 1 }))}>Primeira</button>
              <p>Página</p>
              <select style={{ minWidth: 300 }} value={meta.currentPage} onChange={(e) => setTableFilters({ ...tableFilters, currentPage: Number(e.target.value) })}>
                {generatePagination(meta.lastPage).map((item) => <option value={item}>{item}</option>)}
              </select>
              <p>
                de
                {' '}
                <strong>{meta.lastPage}</strong>
              </p>
              <button
                type="button"
                onClick={() => goToNextPage()}
              >
                Próxima

              </button>
              <button
                type="button"
                onClick={() => setTableFilters((prev) => ({ ...prev, currentPage: meta.lastPage }))}
              >
                Última

              </button>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
