/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import * as EmailValidator from 'email-validator';
import AddInput from '../../../../components/AddInput';
import BasicInput from '../../../../components/BasicInput';
import BasicOption from '../../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../../components/BtnIcon';
import rgEmittent from '../../../../dto/rgEmittent';
import { statesOfBrazil } from '../../../../dto/states';
import CEPFinder from '../../../../helpers/findAddressByCep';
import {
  maskCep, maskCPF, maskDate, maskNumber, maskPhone, unmaskData,
} from '../../../../helpers/inputMasks.js';
import { validateCnpjOrCpf, validateCpf } from '../../../../helpers/validate';
import { ActualPage, DependentDTO, HolderDTO } from './EditLead';
import HowManyDependentsModal from './EditHowManyDependents';
import EditDependentModalModal from './EditDependentr';
import ErrorModal from '../../ErrorModal';
import TextAreaInput from '../../../../components/TextAreaInput';

type CreateHolderProps = {
    holder: HolderDTO
    setHolder: React.Dispatch<React.SetStateAction<HolderDTO>>
    setLoading: (arg0: boolean) => void
    dependents: DependentDTO[],
    setDependents: React.Dispatch<React.SetStateAction<DependentDTO[]>>
    setActualPage: (arg0: ActualPage) => void
    setContractLifes: (holdersAmount: number, dependentsAmount: number) => void
}

export default function CreateHolder({
  holder,
  setHolder,
  setLoading,
  dependents,
  setDependents,
  setActualPage,
  setContractLifes,
}: CreateHolderProps) {
  const [modalRef] = useState(React.createRef<any>());
  const [errorsRef] = useState(React.createRef<any>());
  const [errors, setErrors] = useState<string[]>([]);

  function validate() {
    const errorsCtx: string[] = [];

    if (holder.name.length < 0) {
      errorsCtx.push('Digite um nome pro titular');
    }
    if (!validateCpf(holder.cpf)) {
      errorsCtx.push('CPF do titular inválido');
    }
    if (holder.addressStreet.length < 1) {
      errorsCtx.push('O titular deve ter um logradouro');
    }
    if (holder.birthday.length < 10) {
      errorsCtx.push('Digite o nascimento do titular no formato DD-MM-AAAA');
    }
    if (holder.addressNumber.length < 1) {
      errorsCtx.push('O titular deve ter um numero residencial');
    }
    if (holder.addressNeighborhood.length < 1) {
      errorsCtx.push('Preencha o campo bairro do titular');
    }
    if (holder.addressCity.length < 0) {
      errorsCtx.push('Preencha a cidade do titular');
    }
    if (holder.addressZipcode.length < 10) {
      errorsCtx.push('Preencha o cep do titular');
    }

    if (errorsCtx.length === 0) {
      setErrors([]);
      setContractLifes(1, dependents.length);
      setActualPage(ActualPage.CONTRACT);
    } else {
      setErrors(errorsCtx);
    }
  }

  async function changeAddressByCep() {
    try {
      setLoading(true);
      const findCep = await new CEPFinder(unmaskData(holder.addressZipcode)).findCep();
      if (findCep !== null) {
        setHolder({
          ...holder,
          addressCity: findCep.city,
          addressState: findCep.state,
          addressNeighborhood: findCep.neighborhood,
          addressStreet: findCep.street,
          addressIbge: findCep.ibge,
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  function addOneMoreDependent(ctx: DependentDTO) {
    const newDependents = [...dependents];
    newDependents.push(ctx);
    setDependents(newDependents);
  }

  function removeDependents(id: string) {
    setDependents((prev) => prev.filter((item) => item.uuidCtx !== id));
  }

  function changeDependent(ctx: DependentDTO) {
    let idx: number | null = null;
    dependents.forEach((item, i) => {
      if (item.uuidCtx === ctx.uuidCtx) {
        idx = i;
      }
    });
    if (idx !== null) {
      const newDep = [...dependents];
      newDep[idx] = { ...ctx };
      setDependents([...newDep]);
    }
  }

  useEffect(() => {
    if (errors.length > 0) {
      errorsRef.current.click();
    }
  }, [errors]);

  return (
    <div className="row products mt-4">
      <div className="col-12 card-bg p-4">
        <div className="row">
          <h1>Cadastro Aderente Titular</h1>
          <div className="col-4">
            <BasicInput
              id="name-input"
              label="Nome"
              readOnly={false}
              helper="Ex: João Batista"
              error={holder.name.length < 1 ? 'Campo Obrigatório' : ''}
              value={holder.name}
              type="text"
              onChange={(data) => setHolder({ ...holder, name: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="cpf-input"
              label="CPF"
              readOnly={false}
              helper="Ex: 000.000.000-10"
              error={!validateCnpjOrCpf(holder.cpf) ? 'Digite um CPF válido.' : ''}
              value={holder.cpf}
              type="text"
              onChange={(data) => setHolder({ ...holder, cpf: maskCPF(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="rg-input"
              label="RG"
              readOnly={false}
              helper="Ex: 99999999"
              error=""
              value={holder.rg}
              type="text"
              onChange={(data) => setHolder({ ...holder, rg: maskNumber(data) })}
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="rg-emittent-input"
              label="RG / Emissor"
              readOnly={false}
              helper="Ex: SSP/MA"
              error=""
              value={holder.emitent}
              onChange={(data) => setHolder({ ...holder, emitent: data })}
            >
              {rgEmittent}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicOption
              id="sex-input"
              label="Sexo"
              readOnly={false}
              helper="Ex: Masculino / Feminino"
              error=""
              value={holder.sex === 'm' ? 'Masculino' : 'Feminino'}
              onChange={(data) => setHolder({ ...holder, sex: data === 'Masculino' ? 'm' : 'f' })}
            >
              {['Masculino', 'Feminino']}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicInput
              id="birth-input"
              label="DT. Nascimento"
              readOnly={false}
              helper="Ex: 13-01-1990"
              error={holder.birthday.length < 10 ? 'Campo obrigatório' : ''}
              value={holder.birthday}
              type="text"
              onChange={(data) => setHolder({ ...holder, birthday: maskDate(data) })}
            />
          </div>
          <div className="col-4">
            <AddInput
              id="address-cep-input"
              label="CEP"
              readOnly={false}
              helper="Ex: 35.171-120"
              error={holder.addressZipcode.length < 10 ? 'Campo obrigatório' : ''}
              value={holder.addressZipcode}
              type="text"
              onAdd={() => changeAddressByCep()}
              onChange={(data) => setHolder({ ...holder, addressZipcode: maskCep(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-street-input"
              label="Rua"
              readOnly={false}
              helper="Ex: Rua Três"
              error={holder.addressStreet.length < 2 ? 'Campo Obrigatório' : ''}
              value={holder.addressStreet}
              type="text"
              onChange={(data) => setHolder({ ...holder, addressStreet: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-number-input"
              label="Número"
              readOnly={false}
              helper="Ex: 133"
              error={holder.addressNumber.length < 1 ? 'Campo Obrigatório' : ''}
              value={holder.addressNumber}
              type="text"
              onChange={(data) => setHolder({ ...holder, addressNumber: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-complement-input"
              label="Complemento"
              readOnly={false}
              helper="Ex: Bloco A"
              error=""
              value={holder.addressComplement}
              type="text"
              onChange={(data) => setHolder({ ...holder, addressComplement: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-neighborhood-input"
              label="Bairro"
              readOnly={false}
              helper="Centro"
              error={holder.addressNeighborhood.length < 1 ? 'Campo obrigatório' : ''}
              value={holder.addressNeighborhood}
              type="text"
              onChange={(data) => setHolder({ ...holder, addressNeighborhood: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-city-input"
              label="Cidade"
              readOnly
              helper="Ex: Imperatriz"
              error={holder.addressCity.length < 1 ? 'Campo obrigatório' : ''}
              value={holder.addressCity}
              type="text"
              onChange={(data) => setHolder({ ...holder, addressCity: data })}
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="address-state-input"
              label="Estado"
              readOnly
              helper=""
              error=""
              value={holder.addressState}
              onChange={(data) => setHolder({ ...holder, addressState: data })}
            >
              {statesOfBrazil}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicInput
              id="phone-input"
              label="Telefone"
              readOnly={false}
              helper="Ex: (31) 99999 - 9999"
              error=""
              value={holder.phone}
              type="text"
              onChange={(data) => setHolder({ ...holder, phone: maskPhone(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="phone2-input"
              label="Telefone 2"
              readOnly={false}
              helper="Ex: (31) 99999 - 9999"
              error=""
              value={holder.phone2 || ''}
              type="text"
              onChange={(data) => setHolder({ ...holder, phone2: maskPhone(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="email-input"
              label="email"
              readOnly={false}
              helper="Ex: usuario@usuario.com"
              error={holder.email && holder.email.length > 0 && !EmailValidator.validate(holder.email) ? 'E-mail inválido' : ''}
              value={holder.email ? holder.email : ''}
              type="text"
              onChange={(data) => setHolder({ ...holder, email: data })}
            />
          </div>
          <div className="col-12">
            <TextAreaInput
              id=""
              label="Observação"
              readOnly={false}
              helper=""
              error=""
              value={holder.observation || ''}
              onChange={(data) => setHolder({ ...holder, observation: data })}
            />
          </div>
          <div className="col-12 mb-4">
            <h5 className="fw-bold mb-2">
              Dependentes:
              {' '}
              {dependents.length}
            </h5>
            <div className="d-flex">
              {dependents.map((item) => (
                <>
                  <div className="btn btn-primary d-flex align-items-center justify-content-center mb-2" style={{ width: 'fit-content' }}>
                    {item.name}
                    <div className="px-2" />
                    <button
                      type="button"
                      className="badge"
                      style={{ backgroundColor: 'white', border: 'none', color: '#6200EE' }}
                      data-bs-toggle="modal"
                      data-bs-target={`#EditDependent-${item.uuidCtx}`}
                    >
                      Editar
                    </button>
                    <div className="px-1" />
                    <button
                      type="button"
                      className="badge"
                      style={{ backgroundColor: 'orange', border: 'none' }}
                      onClick={() => removeDependents(item.uuidCtx)}
                    >
                      Remover

                    </button>
                    <EditDependentModalModal
                      updateDependent={(arg) => changeDependent(arg)}
                      setLoading={(arg) => setLoading(arg)}
                      holder={{ ...holder }}
                      pDependent={{ ...item }}
                      id={`EditDependent-${item.uuidCtx}`}
                    />
                  </div>
                  <div className="px-2" />
                </>
              ))}
            </div>

          </div>
          <div className="col-12 align-items-center justify-content-end d-flex">
            <div className="d-flex">
              <ButtonTextIcon
                label="Adicionar dependentes"
                type={BtnType.PRIMARY}
                icon="add"
                active
                onClick={() => modalRef.current.click()}
               //   onClick={() => createOrRemoveDependentArray()}
                ref={null}
              />
              <div className="px-2" />
              <ButtonTextIcon
                label="Ir para contrato"
                type={BtnType.PRIMARY}
                icon="arrow_right"
                active
                onClick={() => validate()}
                ref={null}
              />
            </div>
            <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#howmanydependents" ref={modalRef}>
              Launch static backdrop modal
            </button>
            <HowManyDependentsModal
              createDependent={(arg) => addOneMoreDependent(arg)}
              setLoading={(arg) => setLoading(arg)}
              holder={{ ...holder }}
            />
            <ErrorModal errors={errors} />
            <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#errormodal" ref={errorsRef}>
              Launch static backdrop modal
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
