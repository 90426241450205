import React from 'react';

interface SwitcherPrimaryProps {
    id: string;
    onChange: () => void;
    isActive: boolean;
}

export default function SwitcherPrimary({ id, onChange, isActive }: SwitcherPrimaryProps) {
  return (
    <div className="custom-switcher-primary">
      <label htmlFor={id} className="switcher-bg">
        <input
          className="d-none input-switcher"
          type="checkbox"
          id={id}
          onChange={() => onChange()}
          checked={isActive}
        />
        <div className="floating-btn" />
        <div className="slider" />
      </label>
    </div>
  );
}
