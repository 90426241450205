/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { IModalContent } from '../StratificationModal';

interface ITicketProps {
    props: IModalContent | null
}
export default function Ticket({
  props,
}: ITicketProps) {
  const [changeSliderMovement, setChangeSliderMovement] = useState(false);
  function calculatePercentage(total: number, target: number) {
    const result = (target * 100) / total;
    return result;
  }

  useEffect(() => {
    setTimeout(() => {
      setChangeSliderMovement(true);
    }, 200);
  }, []);
  return (
    <>
      <h3 className="fw-bold text-center mt-4 text-uppercase" style={{ color: '#616161' }}>
        TICKET CONTRATOS - GERAL
      </h3>
      <div className="item-container">
        <p className="fw-bold">
          Soma do valor global de todos contratos PF + PJ dividido por 12
        </p>
        <div className="item-wrapper">
          <p className="item-description" style={{ fontSize: 24 }}>
            R$:
            {' '}
            <span>
              {props ? props!.ticketPerContractTotal.toFixed(2) : 0}
            </span>
          </p>
        </div>
      </div>

      <h3 className="fw-bold text-center mt-4 text-uppercase" style={{ color: '#616161' }}>
        TICKET CONTRATOS - CONTRATOS PF
      </h3>
      <div className="item-container">
        <p className="fw-bold">
          Soma do valor global de todos contratos PF dividido por 12
        </p>
        <div className="item-wrapper">
          <p className="item-description" style={{ fontSize: 24 }}>
            R$:
            {' '}
            <span>
              {props ? props!.ticketPerContractPf.toFixed(2) : 0}
            </span>
          </p>
        </div>
      </div>

      <h3 className="fw-bold text-center mt-4 text-uppercase" style={{ color: '#616161' }}>
        TICKET CONTRATOS - CONTRATOS PJ
      </h3>
      <div className="item-container">
        <p className="fw-bold">
          Soma do valor global de todos contratos PJ dividido por 12
        </p>
        <div className="item-wrapper">
          <p className="item-description" style={{ fontSize: 24 }}>
            R$:
            {' '}
            <span>
              {props ? props!.ticketPerContractPj.toFixed(2) : 0}
            </span>
          </p>
        </div>
      </div>

      <h3 className="fw-bold text-center mt-4 text-uppercase" style={{ color: '#616161' }}>
        TICKET POR VIDA - GERAL
      </h3>
      <div className="item-container">
        <p className="fw-bold">
          Soma do valor global de todos contratos PF + PJ dividido pela soma de todas vidas cadastradas no período especificado.
        </p>
        <div className="item-wrapper">
          <p className="item-description" style={{ fontSize: 24 }}>
            R$:
            {' '}
            <span>
              {props ? props!.ticketPerLifeTotal.toFixed(2) : 0}
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
