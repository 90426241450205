/* eslint-disable max-len */
import axios from 'axios';
import download from 'downloadjs';
import Chart from 'react-apexcharts';
import React, { useState, useEffect } from 'react';
import BasicOption from '../../components/BasicOption';
import { ModalProps } from '../../components/Modal';
import api from '../../config/axios';
import DefaultLayout from '../../Layout';
import IncomeGraph from './IncomeExpense/Income';
import ExpenseGraph from './IncomeExpense/Expense';
import ContractActivites from './IncomeExpense/ContractActivites';
import NumberOfContracts from './NumberOfContracts';
import Statistics from './Statistics';
import SellByProducts from './SellByProducts';
import SellBySellers from './SellBySellers';
import SellByFreelancer from './SellByFreelancer';
import SellByIndications from './SellbyIndications';
import SellByReffers from './SellByReffers';
import SellGraph from './Sell/SellReport';
import ContractsWithouPayment from './ContractsWithoutPayment';
import NonPaidInstallmentGraph from './NonPaidInstallments/NonPaidGraph';
import OpenExcel from './openExcelModal';
import Birthday from './Clients/Birthday';
import PFActivities from './Clients';
import TicketDashboard from './TicketDashboard';
import Expiration from './ContractsExpiration';
import LifesByYear from './LifesByYear';
import ActiveEvolutions from './ActiveEvolutions';

export default function Dashboard() {
  const [loading] = useState(false);
  const [modal] = useState<ModalProps | null>(null);
  const [showToastError] = useState(false);
  const [showToastSuccess] = useState(false);

  return (
    <DefaultLayout
      modal={modal}
      pageTitle="Dashboard"
      loading={loading}
      showToastError={showToastError}
      showToastSuccess={showToastSuccess}
      rightSliderContent={<div />}
      showRightSlider={false}
      setCloseRightSlider={() => null}
    >
      <div className="row mb-4 mt-4">
        <div className="col-12 ">
          <button
            type="button"
            className="generate-excel-btn-wrapper"
            data-bs-toggle="modal"
            data-bs-target="#open-excel"
          >
            <span className="material-icons-round">assignment_returned</span>
            Baixar para Excel
          </button>
        </div>
      </div>
      <div className="row dashboard">
        {/* <div className="col-sm-12 col-xl-4 congratulations p-45 mb-sm-4 mb-xl-0 position-relative">
          <NumberOfContracts />
        </div> */}

        <div className="col-sm-12 col-xl-12 position-relative">
          <Statistics />
        </div>

        <div className="col-sm-12 col-xl-4 d-flex position-relative">
          <IncomeGraph />
        </div>

        <div className="col-sm-12 col-xl-4 d-flex position-relative">
          <ExpenseGraph />
        </div>

        <div className="col-sm-12 col-xl-4 d-flex h-100 position-relative">
          <SellGraph />
        </div>

        <div className="col-sm-12 col-xl-6 d-flex position-relative">
          <ContractActivites />
        </div>

        <div className="col-sm-12 col-xl-6 col-mt-4 mt-sm-4 mt-xl-0 d-flex position-relative">
          <TicketDashboard />
        </div>

        {/* <div className="col-sm-12 col-xl-6 position-relative mt-4">
          <SellBySellers />
        </div>

        <div className="col-sm-12 col-xl-6 position-relative mt-4">
          <SellByFreelancer />
        </div> */}

        <div className="col-sm-12 col-xl-6 position-relative mt-sm-4 mt-xl-4">
          <LifesByYear />
        </div>

        <div className="col-sm-12 col-xl-6 position-relative mt-sm-4 mt-xl-4">
          <ActiveEvolutions />
        </div>
        {/* <div className="col-sm-12 col-xl-6 position-relative mt-sm-4">
          <SellByIndications />
        </div> */}
        {/*
        <div className="col-sm-12 col-xl-6 position-relative mt-sm-4">
          <SellByReffers />
        </div> */}

        <div className="col-sm-12 col-xl-6 position-relative mt-sm-4 mt-xl-4">
          <SellByProducts />
        </div>

        <div className="col-sm-12 col-xl-6 position-relative mt-4">
          <NonPaidInstallmentGraph />
        </div>

        {/* <div className="col-sm-12 col-xl-6 position-relative mt-4">
          <div className="row">
            <div className="col-sm-12 col-xl-6 position-relative">
              <ClientPFActivities />
            </div>
            <div className="col-sm-12 col-xl-6 position-relative mt-sm-4 mt-xl-0">
              <ClientPJActivities />
            </div>
          </div>
          <div className="row mt-4 position-relative">
            <div className="col-12">
              <DependentsActivities />
            </div>
          </div>
        </div> */}

        <PFActivities />
        <div className="col-sm-12 col-xl-12 position-relative mt-4">
          <div className="row">
            <div className="col-sm-12 col-xl-4 position-relative">
              <ContractsWithouPayment />
            </div>
            <div className="col-sm-12 col-xl-8 position-relative mt-sm-4 mt-xl-0">
              <Birthday />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-xl-12 position-relative mt-4">
          <div className="row">
            <div className="col-sm-12 col-xl-4 position-relative">
              <Expiration />
            </div>
          </div>
        </div>
        <OpenExcel id="open-excel" />
      </div>
    </DefaultLayout>
  );
}
