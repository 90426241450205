import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import DashboardCardOverlay from '../../../components/DashboardCardOverlay';
import api from '../../../config/axios';

export default function Expiration() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<number>(0);
  const [days, setDays] = useState(7);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function fetchExpiration() {
    try {
      setLoading(true);
      const response = await api.post('/admin/due-contracts/total', {
        days,
      }, token);
      setData(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function goToContractsExp() {
    navigate('/contratos/expiracao');
  }

  function changeDays(ctx: string) {
    if (ctx === '7 dias') {
      setDays(7);
    }
    if (ctx === '15 dias') {
      setDays(15);
    }
    if (ctx === '30 dias') {
      setDays(30);
    }
    if (ctx === '60 dias') {
      setDays(60);
    }
    if (ctx === '90 dias') {
      setDays(90);
    }
    if (ctx === '180 dias') {
      setDays(180);
    }
    if (ctx === '365 dias') {
      setDays(365);
    }
  }

  function showDays() {
    if (days === 7) {
      return '7 dias';
    }
    if (days === 15) {
      return '15 dias';
    }
    if (days === 30) {
      return '30 dias';
    }
    if (days === 60) {
      return '60 dias';
    }
    if (days === 90) {
      return '90 dias';
    }
    if (days === 180) {
      return '180 dias';
    }
    return '365 dias';
  }

  useEffect(() => {
    fetchExpiration();
  }, [days]);

  return (
    <DashboardCardOverlay loading={loading}>
      <div className="contract-reports-cards position-relative">
        <div className="title-wrapper">
          <div className="">
            <h5 className="title">
              Contratos a expirar
            </h5>
          </div>
        </div>
        <div className="table-wrapper px-4 w-100 d-flex align-items-center justify-content-center flex-column">
          <div className="table-actions">
            <div style={{ width: 110 }}>
              <BasicOption
                id=""
                label="Expira em até"
                readOnly={false}
                helper=""
                error=""
                value={showDays()}
                onChange={(ctx) => changeDays(ctx)}
              >
                {['7 dias', '15 dias', '30 dias', '60 dias', '90 dias', '180 dias', '365 dias']}
              </BasicOption>
            </div>
          </div>
          <h1 className="text-center text-number-alert">{data}</h1>
          <button type="button" className="open-new-tab mt-4" onClick={() => goToContractsExp()}>Abrir lista completa</button>
        </div>
      </div>
    </DashboardCardOverlay>
  );
}
