/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
import { DateTime } from 'luxon';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import BasicOption from '../../../components/BasicOption';
import DashboardCardOverlay from '../../../components/DashboardCardOverlay';
import NoDataGraphOverlay from '../../../components/NoDataGraphOverlay';
import api from '../../../config/axios';
import {
  showArrayOfMonthsLimitedByStart, showAvailableYears, showMonthAsNumberBasedOnName, showMonthAsStringBasedOnNumber,
} from '../../../helpers/dates';
import showCompactName from '../../../helpers/showCompactName';

type ResponseProps = {
  series: string[],
  data: number[]
}

export default function LifesByYear() {
  const [series, setSeries] = useState<string[]>([]);
  const [data, setData] = useState<number[]>([]);
  const [type, setType] = useState<'Geral' | 'PF' | 'PJ'>('Geral');
  const [loading, setLoading] = useState(false);
  const [allTimeFilter, setAllTimeFilter] = useState(false);
  const [filterYear, setFilterYear] = useState(DateTime.now().year);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function fetchData() {
    try {
      setLoading(true);
      const response = await api.get<ResponseProps>(`/admin/reports/get-lifes/${filterYear}/${type}`, token);
      setData(response.data.data);
      setSeries(response.data.series);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [filterYear, allTimeFilter, type]);

  const obj = {
    series: [
      {
        name: 'Vendas',
        data: [
          {
            x: 'Ouro',
            y: 1292,
          },
          {
            x: 'Prata',
            y: 4432,
          },
          {
            x: 'Bronze',
            y: 5423,
          },
        ],
      },
    ],
    options: {
      plotOptions: {
        bar: {
          columnWidth: '60%',
        },
      },
      colors: ['#7367F0'],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    },

  };

  function resolveTypeString() {
    if (type === 'Geral') return 'Geral';
    if (type === 'PF') return 'PF';
    return 'PJ';
  }
  return (
    <DashboardCardOverlay loading={loading}>
      <div className="contract-reports-cards position-relative h-100">
        <div className="title-wrapper">
          <div className="">
            <h5 className="title">
              Adesão mensal
              {' '}
              {resolveTypeString()}
            </h5>
          </div>
          <div className="d-flex align-items-center">
            <BasicOption
              id=""
              label=""
              readOnly={allTimeFilter}
              helper=""
              error=""
              value={type}
              onChange={(el) => setType(el as 'Geral' | 'PF' | 'PJ')}
            >
              {['Geral', 'PF', 'PJ']}
            </BasicOption>
            <BasicOption
              id=""
              label=""
              readOnly={allTimeFilter}
              helper=""
              error=""
              value={filterYear.toString()}
              onChange={(el) => setFilterYear(Number(el))}
            >
              {showAvailableYears()}
            </BasicOption>
          </div>
        </div>
        {data.length === 0 && (
          <NoDataGraphOverlay />
        )}

        {data.length !== 0 && (
          <ReactApexChart
            options={{ ...obj.options, grid: { show: false }, xaxis: { categories: series.map((item) => showCompactName(item)), type: 'category' } }}
            series={[{ name: 'VIDAS', data }]}
            type="bar"
            height={350}
            toolbar={{ show: false }}
          />
        )}

      </div>
    </DashboardCardOverlay>

  );
}
