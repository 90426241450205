/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { Children } from 'react';

interface BasicOptionProps {
    id: string;
    label: string;
    readOnly: boolean;
    helper: string;
    error: string;
    value: string;
    children: any[]
    onChange: (arg0: string) => void;
}

export default function BasicOption({
  id, label, readOnly, helper, error, value, onChange, children,
}: BasicOptionProps) {
  if (readOnly) {
    return (
      <div className="basic-input">
        <label htmlFor={id}>
          <p>{label}</p>
          <div className="select-wrapper-inactive">
            <select onChange={(data) => onChange(data.target.value)} defaultValue={value} value={value} className={`${readOnly ? 'readonly' : ''}`}>
              {
              children.map((item, i) => (
                <option key={i + 1} value={item}>{item}</option>
              ))
          }
            </select>
          </div>

          {helper.length > 0
            && error.length === 0
            && !readOnly && (
              <span className="input-helper">{helper}</span>
          ) }
          {error.length > 0
              && !readOnly
            && (
            <span className="input-error">{error}</span>
            )}
        </label>
      </div>

    );
  }
  return (
    <div className="basic-input">
      <label htmlFor={id}>
        <p>{label}</p>
        <div className="select-wrapper">
          <select onChange={(data) => onChange(data.target.value)} defaultValue={value} value={value}>
            {
              children.map((item, i) => (
                <option key={i + 1} value={item}>{item}</option>
              ))
          }
          </select>
        </div>

        {helper.length > 0
            && error.length === 0
            && !readOnly && (
              <span className="input-helper">{helper}</span>
        ) }
        {error.length > 0
              && !readOnly
            && (
            <span className="input-error">{error}</span>
            )}
      </label>
    </div>

  );
}
