import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import api from '../../../config/axios';
import DefaultLayout from '../../../Layout';

type NewCategoryProps = {
    title: string;
    type: string;
    isActive: boolean;
}

export default function EditCategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [category, setCategory] = useState<NewCategoryProps>({
    title: '',
    type: 'medic',
    isActive: true,
  });

  function enableButton() {
    return category.title !== '';
  }

  async function fetchCategory() {
    try {
      setLoading(true);
      const response = await api.get(`/admin/professional-category/${id}`, token);
      setCategory(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível localizar categoria.', actions: [{ label: 'Voltar', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
    }
  }

  function selectCategory(categoryCtx: string) {
    if (categoryCtx === 'Médico') {
      return 'medic';
    }
    if (categoryCtx === 'Dentista') {
      return 'dentist';
    }
    return 'health';
  }

  function showCategory() {
    if (category.type === 'medic') {
      return 'Médico';
    }
    if (category.type === 'dentist') {
      return 'Dentista';
    }
    return 'Outros';
  }

  async function deleteCategory() {
    try {
      setLoading(true);
      await api.delete(`/admin/professional-category/${id}`, token);
      setLoading(false);
      navigate(-1);
    } catch (err: any) {
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível excluir categoria', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  async function saveCategory() {
    try {
      setLoading(true);
      await api.put(`/admin/professional-category/${id}`, category, token);
      setLoading(false);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível salvar categoria no momento.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <DefaultLayout
      pageTitle="Adicionar Especialidade"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Especialidades</h1>
          <div className="row">
            <div className="col-4">
              <BasicOption
                id="title-input"
                label="Tipo"
                readOnly={false}
                helper=""
                error=""
                value={showCategory()}
                onChange={(data) => setCategory({ ...category, type: selectCategory(data) })}
              >
                {['Médico', 'Outros', 'Dentista']}
              </BasicOption>
            </div>
            <div className="col-4">
              <BasicInput
                id="title-input"
                label="Título"
                readOnly={false}
                helper={category.type === 'medic' ? 'Ex: Endocrinologia' : 'Ex: Fisioterapia'}
                error={category.title.length < 1 ? 'Campo obrigatório' : ''}
                value={category.title}
                type="text"
                onChange={(data) => setCategory({ ...category, title: data })}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Excluir"
                type={BtnType.DANGER}
                icon="delete"
                active={enableButton()}
                onClick={() => deleteCategory()}
                ref={null}
              />
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                active={enableButton()}
                onClick={() => saveCategory()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>

    </DefaultLayout>
  );
}
