/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import PriceInput from '../../../components/PriceInput';
import api from '../../../config/axios';
import { FreelancerSellersProps } from '../../../dto/freelancersSellers';
import { Indicators } from '../../../dto/indicators';
import { ProductsProps } from '../../../dto/product';
import { Reffer } from '../../../dto/reffers';
import SellerProps from '../../../dto/seller';
import { showIfDateOfParamsOneMajorThenParamsTwo, showTodayDate } from '../../../helpers/dates';
import { maskDate } from '../../../helpers/inputMasks.js';
import DefaultLayout from '../../../Layout';
import { ClientPFProps, ContractPFProps } from './types';

export default function NewContractPF() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [clients, setClients] = useState<ClientPFProps[]>([]);
  const [sellers, setSellers] = useState<SellerProps[]>([]);
  const [products, setProducts] = useState<ProductsProps[]>([]);
  const [reffers, setReffers] = useState<Reffer[]>([]);
  const [freelancers, setFreelancers] = useState<FreelancerSellersProps[]>([]);
  const [indicators, setIndicators] = useState<Indicators[]>([]);
  const [selectedReffer, setSelectedReffer] = useState<Reffer | null>(null);
  const [selectedClient, setSelectedClient] = useState<ClientPFProps | null>(null);
  const [selectedSeller, setSelectedSeller] = useState<SellerProps | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<ProductsProps | null>(null);
  const [selectedIndicator, setSelectedIndicator] = useState<Indicators | null>(null);
  const [selectedFreelancer, setSelectedFreelancer] = useState<FreelancerSellersProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [contract, setContract] = useState<ContractPFProps>({
    id: 0,
    uuid: '0',
    sellerId: 0,
    holderId: 0,
    holder: { name: '', blockPerma: false },
    productId: 0,
    refferId: null,
    indicationId: null,
    freelancerId: null,
    totalDependentsEnabled: 0,
    totalLifes: 0,
    pricePerHolder: 0,
    pricePerDependent: 0,
    contractGlobalValue: 0,
    signatureDate: '',
    dueDate: '',
    isActive: true,
    files: [],
    payment: [],
    blockHistory: [],
  });

  function filterSellerByName(name: string, ctx: SellerProps[]) {
    const filteredSeller = ctx.filter((item) => item.name === name);
    if (filteredSeller.length > 0) {
      setSelectedSeller(filteredSeller[0]);
      setContract({ ...contract, sellerId: filteredSeller[0].id });
    }
  }

  function filterRefferByName(name: string, ctx: Reffer[]) {
    const filteredReffer = ctx.filter((item) => item.name === name);
    if (filteredReffer.length > 0) {
      setSelectedReffer(filteredReffer[0]);
      setContract({ ...contract, refferId: filteredReffer[0].id });
    } else {
      setSelectedReffer(null);
      setContract({ ...contract, refferId: null });
    }
  }

  function filterIndicatorByName(name: string, ctx: Indicators[]) {
    const filteredIndicator = ctx.filter((item) => item.name === name);
    if (filteredIndicator.length > 0) {
      setSelectedIndicator(filteredIndicator[0]);
      setContract({ ...contract, indicationId: filteredIndicator[0].id });
    } else {
      setSelectedIndicator(null);
      setContract({ ...contract, indicationId: null });
    }
  }

  function filterFreelancerByName(name: string, ctx: FreelancerSellersProps[]) {
    const filteredFreelancer = ctx.filter((item) => item.name === name);
    if (filteredFreelancer.length > 0) {
      setSelectedFreelancer(filteredFreelancer[0]);
      setContract({ ...contract, freelancerId: filteredFreelancer[0].id });
    } else {
      setSelectedFreelancer(null);
      setContract({ ...contract, freelancerId: null });
    }
  }

  function filterProductByName(name: string, ctx: ProductsProps[]) {
    const filteredProduct = ctx.filter((item) => item.name === name);
    if (filteredProduct.length > 0) {
      setSelectedProduct(filteredProduct[0]);
      setContract({
        ...contract,
        productId: filteredProduct[0].id,
        pricePerHolder: filteredProduct[0].pricePerHolder,
        pricePerDependent: filteredProduct[0].pricePerDependent,
        contractGlobalValue: (filteredProduct[0].pricePerHolder + (filteredProduct[0].pricePerDependent * Number(contract.totalDependentsEnabled))) * 12,
      });
    }
  }

  function changeNumberOfDependent(dependentParams: number) {
    const globalContract = (dependentParams * contract.pricePerDependent) + contract.pricePerHolder;
    setContract({ ...contract, totalDependentsEnabled: dependentParams, contractGlobalValue: Number((globalContract * 12).toFixed(2)) });
  }

  function changePricePerHolder(price: number) {
    const globalContract = price + (contract.totalDependentsEnabled * contract.pricePerDependent);
    setContract({ ...contract, pricePerHolder: price, contractGlobalValue: Number((globalContract * 12).toFixed(2)) });
  }

  function changePricePerDependent(price: number) {
    const globalContract = (contract.totalDependentsEnabled * price) + contract.pricePerHolder;
    setContract({ ...contract, pricePerDependent: price, contractGlobalValue: Number((globalContract * 12).toFixed(2)) });
  }

  function calculateTotalOfLifes(dependents: string) {
    const dependentsAsNumber = Number(dependents);
    return dependentsAsNumber + 1;
  }

  function filterSellerProductAndClientOnFirsLoad(data: {sellers: SellerProps[], products: ProductsProps[], clients: ClientPFProps[], reffers: Reffer[], indications: Indicators[], freelancers: FreelancerSellersProps[]}) {
    setSelectedSeller(data.sellers[0]);
    setSelectedClient(data.clients[0]);
    setSelectedProduct(data.products[0]);
    setClients(data.clients);
    setSellers(data.sellers);
    setProducts(data.products);
    setReffers(data.reffers);
    setFreelancers(data.freelancers);
    setIndicators(data.indications);
    setContract({
      ...contract,
      sellerId: data.sellers[0].id,
      productId: data.products[0].id,
      holderId: data.clients[0].id,
      pricePerHolder: data.products[0].pricePerHolder,
      pricePerDependent: data.products[0].pricePerDependent,
      totalLifes: calculateTotalOfLifes(data.clients[0].dependents),
      totalDependentsEnabled: Number(data.clients[0].dependents),
      contractGlobalValue: (data.products[0].pricePerHolder + (data.products[0].pricePerDependent * Number(data.clients[0].dependents))) * 12,
      signatureDate: showTodayDate(),
    });
  }

  async function fetchClientsAndSellers() {
    try {
      setLoading(true);
      const response = await api.get('/admin/contractpf/preload', token);
      console.log('preload', response.data);
      if (response.data.products.length === 0) {
        setModal({ title: 'Atenção!', children: 'É necessário possuir pelo menos um produto cadastrado para realizar um contrato.', actions: [{ label: 'Cadastrar Produto', type: ModalActionType.PRIMARY, onClick: () => navigate('/produtos/adicionar') }, { label: 'Voltar', type: ModalActionType.DANGER, onClick: () => navigate(-1) }] });
        return;
      }
      if (response.data.sellers.length === 0) {
        setModal({ title: 'Atenção!', children: 'É necessário possuir pelo menos um vendedor cadastrado para realizar um contrato.', actions: [{ label: 'Cadastrar Vendedor', type: ModalActionType.PRIMARY, onClick: () => navigate('/vendedores/adicionar') }, { label: 'Voltar', type: ModalActionType.DANGER, onClick: () => navigate(-1) }] });
        return;
      }

      if (response.data.clients.length === 0) {
        setModal({ title: 'Atenção!', children: 'É necessário possuir pelo menos um cliente PF cadastrado para realizar um contrato.', actions: [{ label: 'Cadastrar Cliente', type: ModalActionType.PRIMARY, onClick: () => navigate('/cliente/pessoa-fisica/adicionar-titular') }, { label: 'Voltar', type: ModalActionType.DANGER, onClick: () => navigate(-1) }] });
        return;
      }

      filterSellerProductAndClientOnFirsLoad({ ...response.data, clients: response.data.clients });
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setModal({ title: 'Ocorreu um erro!', children: 'Não foi possível se comunicar com a API. Verifique sua conectividade. Caso persista o problema entre em contato com o suporte.', actions: [{ label: 'Ok, Entendi', type: ModalActionType.PRIMARY, onClick: () => navigate(-1) }] });
      setLoading(false);
    }
  }

  function filterClientByName(name: string, ctx: ClientPFProps[]) {
    const filteredClient = ctx.filter((item) => item.name === name);
    if (filteredClient.length > 0) {
      setSelectedClient(filteredClient[0]);
      setContract({
        ...contract,
        totalLifes: calculateTotalOfLifes(filteredClient[0].dependents),
        totalDependentsEnabled: Number(filteredClient[0].dependents),
        holderId: filteredClient[0].id,
      });
    }
  }

  function showDueDateErrors() {
    if (showIfDateOfParamsOneMajorThenParamsTwo(contract.signatureDate, contract.dueDate)) {
      return 'A data de vencimento deverá ser maior que a data de assinatura';
    } if (contract.dueDate.length < 10) {
      return 'Digite uma data válida';
    }
    return '';
  }

  async function saveContract() {
    try {
      setLoading(true);
      await api.post('admin/contract/pf', contract, token);
      setLoading(false);
      navigate('/contratos/pf');
    } catch (err: any) {
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 3000);
      console.log(err.response);
      if (typeof err.response !== 'undefined') {
        if (typeof err.response.data.message !== 'undefined') {
          setModal({ title: 'Ocorreu um erro!', children: err.response.data.message, actions: [{ label: 'Ok, Entendi', type: ModalActionType.PRIMARY, onClick: () => navigate(-1) }] });
        }
      } else {
        setModal({ title: 'Ocorreu um erro!', children: 'Não foi possível cadastrar contrato. Verifique sua conectividade. Caso persista o problema entre em contato com o suporte.', actions: [{ label: 'Ok, Entendi', type: ModalActionType.PRIMARY, onClick: () => navigate(-1) }] });
      }
    }
  }

  useEffect(() => {
    fetchClientsAndSellers();
  }, []);

  const definitiveReffersArray: any[] = [];
  const definitiveIndicatorArray: any[] = [];
  const definitiveFreelancerArray: any[] = [];

  return (
    <DefaultLayout
      pageTitle="Adicionar contrato PF"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products mt-4">
        <div className="col-12 card-bg p-4">
          <div className="row">
            <h1>Adicionar contrato PF</h1>
            <div className="col-4">
              <BasicOption
                id="vendedor-inout"
                label="Vendedor"
                readOnly={false}
                helper="Quem intermediou o contrato"
                error=""
                value={selectedSeller !== null ? selectedSeller.name : 'Nenhum vendedor encontrado'}
                onChange={(data) => filterSellerByName(data, sellers)}
              >
                {sellers !== null ? sellers.map((item) => item.name) : ['Nenhum vendedor encontrado']}
              </BasicOption>
            </div>
            <div className="col-4">
              <BasicOption
                id="client-PF-input"
                label="Cliente PF"
                readOnly={false}
                helper="Selecione um cliente titular"
                error=""
                value={selectedClient !== null ? selectedClient.name : 'Nenhum cliente encontrado'}
                onChange={(data) => filterClientByName(data, clients)}
              >
                {clients !== null ? clients.map((item) => item.name) : ['Nenhum cliente encontrado']}
              </BasicOption>
            </div>
            <div className="col-4">
              <BasicOption
                id="product-selected-input"
                label="Produto escolhido"
                readOnly={false}
                helper="Selecione o produto"
                error=""
                value={selectedProduct !== null ? selectedProduct.name : 'Nenhum produto encontrado'}
                onChange={(data) => filterProductByName(data, products)}
              >
                {products !== null ? products.map((item) => item.name) : ['Nenhum produto encontrado']}
              </BasicOption>
            </div>
            {reffers.length > 0 ? (
              <div className="col-4">
                <BasicOption
                  id="representante-input"
                  label="Embaixador Master"
                  readOnly={false}
                  helper=""
                  error=""
                  value={selectedReffer ? selectedReffer!.name : 'Nenhum'}
                  onChange={(data) => filterRefferByName(data, reffers)}
                >
                  {
                    definitiveReffersArray.concat(reffers).concat('Nenhum').map((item) => (typeof item.name !== 'undefined' ? item.name : 'Nenhum'))
                  }
                </BasicOption>
              </div>
            ) : (
              <div className="col-4">
                <BasicOption
                  id="representante-input"
                  label="Embaixador Master"
                  readOnly
                  helper=""
                  error=""
                  value="Nenhum cadastrado"
                  onChange={() => null}
                >
                  {['Nenhum']}
                </BasicOption>
              </div>
            )}

            {indicators.length > 0 ? (
              <div className="col-4">
                <BasicOption
                  id="indications-input"
                  label="Embaixador"
                  readOnly={false}
                  helper=""
                  error=""
                  value={selectedIndicator ? selectedIndicator!.name : 'Nenhum'}
                  onChange={(data) => filterIndicatorByName(data, indicators)}
                >
                  {
                    definitiveIndicatorArray.concat(indicators).concat('Nenhum').map((item) => (typeof item.name !== 'undefined' ? item.name : 'Nenhum'))
                  }
                </BasicOption>
              </div>
            ) : (
              <div className="col-4">
                <BasicOption
                  id="representante-input"
                  label="Embaixador"
                  readOnly
                  helper=""
                  error=""
                  value="Nenhum cadastrado"
                  onChange={() => null}
                >
                  {['Nenhum']}
                </BasicOption>
              </div>
            )}

            {freelancers.length > 0 ? (
              <div className="col-4">
                <BasicOption
                  id="indications-input"
                  label="Representante"
                  readOnly={false}
                  helper=""
                  error=""
                  value={selectedFreelancer ? selectedFreelancer!.name : 'Nenhum'}
                  onChange={(data) => filterFreelancerByName(data, freelancers)}
                >
                  {
                    definitiveFreelancerArray.concat(freelancers).concat('Nenhum').map((item) => (typeof item.name !== 'undefined' ? item.name : 'Nenhum'))
                  }
                </BasicOption>
              </div>
            ) : (
              <div className="col-4">
                <BasicOption
                  id="autonomo-input"
                  label="Representante"
                  readOnly
                  helper=""
                  error=""
                  value="Nenhum cadastrado"
                  onChange={() => null}
                >
                  {['Nenhum']}
                </BasicOption>
              </div>
            )}

            <div className="col-4">
              <BasicInput
                id="total-vidas-input"
                label="Qtd. Vidas"
                readOnly
                helper="Ex: 100"
                error={contract.totalLifes === 0 ? 'Mínimo de 1.' : ''}
                value={contract.totalLifes.toString()}
                type="number"
                onChange={(data) => setContract({ ...contract, totalLifes: Number(data) })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="total-dependentes-input"
                label="Qtd. Dependentes"
                readOnly
                helper="Ex: 20"
                error={contract.totalDependentsEnabled > contract.totalLifes ? 'Ultrapassou o total de vidas' : ''}
                value={contract.totalDependentsEnabled.toString()}
                type="number"
                onChange={(data) => changeNumberOfDependent(Number(data))}
              />
            </div>
            <div className="col-4">
              <PriceInput
                id="preco-titular-input"
                label="Preço por titular"
                readOnly={false}
                helper="Ex: R$20"
                error={contract.pricePerHolder === 0 ? 'Adicione um preço por titular' : ''}
                value={contract.pricePerHolder.toString()}
                type="number"
                onChange={(data) => changePricePerHolder(Number(data))}
              />
            </div>
            <div className="col-4">
              <PriceInput
                id="preco-dependentes-input"
                label="Preço por dependente"
                readOnly={false}
                helper="Ex: R$20"
                error=""
                value={contract.pricePerDependent.toString()}
                type="number"
                onChange={(data) => changePricePerDependent(Number(data))}
              />
            </div>
            <div className="col-4">
              <PriceInput
                id="global-value-input"
                label="Valor global do contrato"
                readOnly={false}
                helper="Ex: R$20"
                error=""
                value={contract.contractGlobalValue.toString()}
                type="number"
                onChange={(data) => setContract({ ...contract, contractGlobalValue: Number(data) })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="date-start-input"
                label="Data de Início do contrato"
                readOnly={false}
                helper="Ex: 30-01-2022"
                error={contract.signatureDate.length < 10 ? 'Digite uma data válida' : ''}
                value={contract.signatureDate}
                type="text"
                onChange={(data) => setContract({ ...contract, signatureDate: maskDate(data) })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="date-start-input"
                label="Data de Vencimento do contrato"
                readOnly={false}
                helper="Ex: 30-01-2022"
                error={showDueDateErrors()}
                value={contract.dueDate}
                type="text"
                onChange={(data) => setContract({ ...contract, dueDate: maskDate(data) })}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                // active
                // onClick={() => console.log(contract)}
                active={contract.dueDate.length === '00-00-0000'.length && !showIfDateOfParamsOneMajorThenParamsTwo(contract.signatureDate, contract.dueDate)}
                onClick={() => saveContract()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>

    </DefaultLayout>
  );
}
