import React from 'react';

export default function Loading() {
  return (
    <div className="loading">
      <div className="spinner-wrapper">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <p>Processando, aguarde...</p>
      </div>
    </div>
  );
}
