import axios from 'axios';

// const baseURL = 'http://localhost:3333';
// const baseURL = 'http://177.222.252.116:3333/';
const baseURL = 'https://api.redesanta.com';
const api = axios.create({
  baseURL,
});

export default api;
