/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import api from '../../../config/axios';
import normalizeDate from '../../../helpers/dates';
import { convertCardPaymentMethodFromApi } from '../../../helpers/showCardMethods';
import showCompactName from '../../../helpers/showCompactName';
import DefaultLayout from '../../../Layout';
import InstallmentsReportModalFilter from './InstallmentsReportModalFilter';

type ResponseDataProps = {
  clientid: number,
  titular: string,
  contractid: number,
  paymentid: number,
  vencimento: string,
  gateway: number,
  method: string,
  cartao: boolean,
  bandeira: null | string,
  valor: number,
  valorliquido: number,
  installmentnumber: number,
  paymentdate: string | null,
  cardpaymentoption: string | null,
  noncardtaxes: string | null
  prevision: string,
  ispj: boolean,
  status: 'UNPAID' | 'PAID' | 'OTHER' | 'REVERSED' | 'ONTIME' | 'EXPIRED'
}

export declare type flagProps = 'MASTERCARD' | 'VISA' | 'ELO' | 'AMEX' | 'HIPERCARD' | 'OTHER';

export declare type methodProps = 'PIX' |
'DINHEIRO' |
'BOLETO' |
'TED' |
'DOC' |
'debit' |
'credit_1' |
'credit_2' |
'credit_3' |
'credit_4' |
'credit_5' |
'credit_6' |
'credit_7' |
'credit_8' |
'credit_9' |
'credit_10' |
'credit_11' |
'credit_12' |
'credit_13' |
'credit_14' |
'credit_15' |
'credit_16' |
'credit_17' |
'credit_18'

export declare type StatusProps = 'PAID' | 'REVERSED' | 'OTHER' | 'ONTIME' | 'EXPIRED'

export type GatewayProps = {
  id: number,
  name: string
}

type ResponseProps = {
  meta: MetaProps,
  data: ResponseDataProps[]
}

type MetaProps = {
  firstPage: number,
  lastPage: number,
  totalByFilter: number,
  status: 'EXPIRED' | 'ONTIME' | 'PAID' | 'REVERSED' | 'OTHER'
  totalBruto: number,
  totalLiquido: number,
  totalInstallments: number,
  currentPage: number,
}

export type TableFilters = {
  currentPage: number,
  dueDtInitial: string,
  dueDtFinal: string,
  previsionDtInitial: string,
  previsionDtFinal: string,
  paymentDateInitial: string,
  paymentDateFinal: string,
  status: StatusProps,
  subscription: null | boolean,
  gateway: number | null
  method: string | null
  flag: string | null
  minValue: number | null,
  maxValue: number | null,
  installmentNum: number | null,
  lastDigit: null | string
}

export default function NonPaidInstallmentsTable() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [installaments, setInstallments] = useState<ResponseProps>({
    meta: {
      firstPage: 1,
      lastPage: 1,
      status: 'EXPIRED',
      totalBruto: 0,
      totalByFilter: 0,
      totalLiquido: 0,
      totalInstallments: 0,
      currentPage: 1,
    },
    data: [],
  });
  const [tableFilters, setTableFilters] = useState<TableFilters>({
    currentPage: 1,
    dueDtInitial: '',
    dueDtFinal: '',
    previsionDtInitial: '',
    previsionDtFinal: '',
    paymentDateInitial: '',
    paymentDateFinal: '',
    status: 'EXPIRED',
    subscription: false,
    gateway: null,
    flag: null,
    method: null,
    minValue: null,
    maxValue: null,
    installmentNum: null,
    lastDigit: null,
  });
  const [dtInitial, setDtInitial] = useState('');
  const [dtFinal, setDtFinal] = useState('');
  const [previsionDtInitial, setPrevisionDtInitial] = useState('');
  const [previsionDtFinal, setPrevisionDtFinal] = useState('');
  const [paymentDateInitial, setPaymentDateInitial] = useState('');
  const [paymentDateFinal, setPaymentDateFinal] = useState('');
  const [gateway, setGateway] = useState<GatewayProps | null>(null);
  const [method, setMethod] = useState<methodProps | null>(null);
  const [flag, setFlag] = useState<flagProps | null>(null);
  const [status, setStatus] = useState<StatusProps>('EXPIRED');
  const [installmentNum, setInstallmentNum] = useState<string | null>(null);
  const [subscription, setSubscription] = useState<boolean>(false);
  const [lastDigit, setLastDigit] = useState<string | null>(null);
  const [minValue, setMinValue] = useState<number | null>(null);
  const [maxValue, setMaxValue] = useState<number | null>(null);

  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function fetchInstallments() {
    try {
      setLoading(true);
      const response = await api.post<ResponseProps>('admin/financial/installments-report', {
        currentPage: 1,
        status,
      }, token);
      setInstallments(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Ocorreu um erro!', children: 'Não foi possível buscar parcelas', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
      setLoading(false);
    }
  }

  async function fetchByFilter() {
    const obj = {};
    if (tableFilters.dueDtInitial.trim() !== '') {
      Object.assign(obj, { dueDateInitial: tableFilters.dueDtInitial });
    }
    if (tableFilters.dueDtFinal.trim() !== '') {
      Object.assign(obj, { dueDateFinal: tableFilters.dueDtFinal });
    }
    if (tableFilters.previsionDtInitial.trim() !== '') {
      Object.assign(obj, { previsionDateInitial: tableFilters.previsionDtInitial });
    }
    if (tableFilters.previsionDtFinal.trim() !== '') {
      Object.assign(obj, { previsionDateFinal: tableFilters.previsionDtFinal });
    }
    if (tableFilters.installmentNum) {
      Object.assign(obj, { installmentnum: tableFilters.installmentNum });
    }
    if (tableFilters.gateway) {
      Object.assign(obj, { gateway: tableFilters.gateway });
    }
    if (tableFilters.method) {
      Object.assign(obj, { method: tableFilters.method });
    }
    if (tableFilters.flag) {
      Object.assign(obj, { flag: tableFilters.flag });
    }
    if (tableFilters.subscription === true) {
      Object.assign(obj, { subscription: true });
    }
    if (tableFilters.paymentDateInitial.trim() !== '') {
      Object.assign(obj, { paymentDateInitial: tableFilters.paymentDateInitial });
    }
    if (tableFilters.paymentDateFinal.trim() !== '') {
      Object.assign(obj, { paymentDateFinal: tableFilters.paymentDateFinal });
    }
    if (tableFilters.lastDigit) {
      Object.assign(obj, { lastDigits: tableFilters.lastDigit });
    }
    if (minValue) {
      Object.assign(obj, { minValue });
    }
    if (maxValue) {
      Object.assign(obj, { maxValue });
    }
    console.log(obj);
    try {
      setLoading(true);
      const response = await api.post<ResponseProps>('admin/financial/installments-report', {
        currentPage: tableFilters.currentPage,
        status: tableFilters.status,
        ...obj,
      }, token);
      setInstallments(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Ocorreu um erro!', children: 'Não foi possível buscar parcelas', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
      setLoading(false);
    }
  }

  function showGatewayNameById(id: number) {
    if (id === 1) {
      return 'PagAzul Presencial';
    }
    if (id === 2) {
      return 'REDESANTA';
    }
    if (id === 3) {
      return 'PagAzul WEB';
    }
    if (id === 4) {
      return 'CIELO';
    }
    return 'GALAXPAY';
  }

  function goToPayment(responseData: ResponseDataProps) {
    if (responseData.ispj) {
      return `/contratos/pj/forma-de-pagamento/visualizar/${responseData.paymentid}`;
    }
    return `/contratos/pf/forma-de-pagamento/visualizar/${responseData.paymentid}`;
  }

  function goToContract(responseData: ResponseDataProps) {
    if (responseData.ispj) {
      return `/contratos/pj/editar/${responseData.contractid}`;
    }
    return `/contratos/pf/visualizar/${responseData.contractid}`;
  }

  function goToHolder(responseData: ResponseDataProps) {
    if (responseData.ispj) {
      return `/cliente/empresa/editar/${responseData.clientid}`;
    }
    return `/cliente/pessoa-fisica/editar-titular/${responseData.clientid}`;
  }

  function generatePagination(totalOfPages: number) {
    const pagination: number[] = [];
    for (let i = 0; i < totalOfPages; i += 1) {
      pagination.push(i + 1);
    }
    return pagination;
  }

  function goToNextPage() {
    if (tableFilters.currentPage < installaments.meta.lastPage) {
      setTableFilters((prev) => ({ ...prev, currentPage: installaments.meta.currentPage + 1 }));
    }
  }

  function goToLastPage() {
    setTableFilters((prev) => ({ ...prev, currentPage: installaments.meta.lastPage }));
  }

  function goToFirstPage() {
    setTableFilters((prev) => ({ ...prev, currentPage: installaments.meta.firstPage }));
  }

  function goToPage(page: number) {
    setTableFilters({ ...tableFilters, currentPage: Number(page) });
  }

  function showStatusLabel(item: ResponseDataProps) {
    if (installaments.meta.status === 'OTHER') {
      return <td style={{ fontSize: 12, fontWeight: 'bold' }}><p className="bg-other" style={{ width: 'fit-content' }}>Autorizado</p></td>;
    }
    if (installaments.meta.status === 'PAID') {
      return <td style={{ fontSize: 12, fontWeight: 'bold' }}><p className="bg-success" style={{ width: 'fit-content' }}>Pago</p></td>;
    }
    if (installaments.meta.status === 'EXPIRED') {
      return <td style={{ fontSize: 12, fontWeight: 'bold' }}><p className="bg-danger" style={{ width: 'fit-content' }}>Fora do prazo</p></td>;
    }
    return <td style={{ fontSize: 12, fontWeight: 'bold' }}><p className="bg-alert" style={{ width: 'fit-content' }}>No prazo</p></td>;
  }

  function resolveMethod(item: ResponseDataProps) {
    if (item.noncardtaxes) {
      return item.noncardtaxes;
    }
    const paymentOption = convertCardPaymentMethodFromApi(item.cardpaymentoption!);
    if (item.bandeira === 'OTHER') {
      return `OUTRO-${paymentOption}`;
    }
    return `${item.bandeira}-${paymentOption}`;
  }

  useEffect(() => {
    fetchInstallments();
  }, []);

  useEffect(() => {
    fetchByFilter();
  }, [tableFilters]);

  return (
    <DefaultLayout
      pageTitle="Relatório de parcelas"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <InstallmentsReportModalFilter
        dueInitialDate={dtInitial}
        dueFinalDate={dtFinal}
        lastDigit={lastDigit}
        setLastDigit={(arg) => setLastDigit(arg)}
        setDueInitialDate={(arg) => setDtInitial(arg)}
        setDueFinalDate={(arg) => setDtFinal(arg)}
        projectionInitialDate={previsionDtInitial}
        projectionFinalDate={previsionDtFinal}
        setProjectionInitialDate={(arg) => setPrevisionDtInitial(arg)}
        setProjectionFinalDate={(arg) => setPrevisionDtFinal(arg)}
        removeAllFilters={() => setTableFilters({
          currentPage: 1,
          dueDtInitial: '',
          dueDtFinal: '',
          paymentDateInitial: '',
          paymentDateFinal: '',
          previsionDtInitial: '',
          previsionDtFinal: '',
          status: 'EXPIRED',
          minValue: null,
          maxValue: null,
          subscription: false,
          gateway: null,
          flag: null,
          method: null,
          installmentNum: null,
          lastDigit,
        })}
        filter={(arg) => setTableFilters({ ...arg })}
        gateway={gateway}
        setGatewayProps={(arg) => setGateway(arg)}
        method={method}
        setMethodProps={(arg) => setMethod(arg)}
        flag={flag}
        setFlagProps={(arg) => setFlag(arg)}
        subscription={subscription}
        setSubscription={(arg) => setSubscription(arg)}
        installmentNum={installmentNum}
        setInstallmentNum={(arg) => setInstallmentNum(arg)}
        status={status}
        setStatus={(arg) => setStatus(arg)}
        paymentDateInitial={paymentDateInitial}
        setPaymentDateInitial={(arg) => setPaymentDateInitial(arg)}
        paymentDateFinal={paymentDateFinal}
        setPaymentDateFinal={(arg) => setPaymentDateFinal(arg)}
        minValue={minValue}
        setMinValue={(arg) => setMinValue(arg)}
        maxValue={maxValue}
        setMaxValue={(arg) => setMaxValue(arg)}
      />
      <div className="row products mt-4">
        <div className="d-flex justify-content-end mb-2">
          <div className="fetched-values-group">
            <div className="income-fetched-value-wrapper" style={{ marginRight: 24 }}>
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#16C784" />
                  <path d="M11.4758 19H12.5547L12.5598 17.7477C14.743 17.5781 15.9949 16.2875 16 14.4555C15.9949 12.5141 14.5038 11.6281 12.9364 11.2289L12.6005 11.1359L12.6209 8.01328C13.4351 8.16641 13.9593 8.69141 14.0407 9.47891H15.8575C15.827 7.73984 14.5547 6.47109 12.631 6.27422L12.6361 5H11.5573L11.5522 6.27422C9.64377 6.48203 8.26972 7.73984 8.27481 9.55C8.27481 11.1578 9.33842 12.082 11.0534 12.5578L11.5115 12.6836L11.4911 15.9922C10.5802 15.8391 9.92366 15.2812 9.85751 14.2914H8C8.07125 16.3422 9.38422 17.5617 11.4809 17.7477L11.4758 19ZM12.57 15.9922L12.5903 12.9898C13.5165 13.2797 14.1069 13.6625 14.112 14.4391C14.1069 15.2484 13.5013 15.8281 12.57 15.9922ZM11.5216 10.8406C10.8193 10.6 10.1781 10.2008 10.1883 9.42422C10.1934 8.71875 10.687 8.17188 11.542 8.01328L11.5216 10.8406Z" fill="white" />
                </svg>
                Total Bruto:
                {' '}
                <span className="">{`R$ ${installaments.meta.totalBruto.toFixed(2)}`}</span>
              </p>
            </div>
            <div className="income-fetched-value-wrapper" style={{ marginRight: 24 }}>
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#16C784" />
                  <path d="M11.4758 19H12.5547L12.5598 17.7477C14.743 17.5781 15.9949 16.2875 16 14.4555C15.9949 12.5141 14.5038 11.6281 12.9364 11.2289L12.6005 11.1359L12.6209 8.01328C13.4351 8.16641 13.9593 8.69141 14.0407 9.47891H15.8575C15.827 7.73984 14.5547 6.47109 12.631 6.27422L12.6361 5H11.5573L11.5522 6.27422C9.64377 6.48203 8.26972 7.73984 8.27481 9.55C8.27481 11.1578 9.33842 12.082 11.0534 12.5578L11.5115 12.6836L11.4911 15.9922C10.5802 15.8391 9.92366 15.2812 9.85751 14.2914H8C8.07125 16.3422 9.38422 17.5617 11.4809 17.7477L11.4758 19ZM12.57 15.9922L12.5903 12.9898C13.5165 13.2797 14.1069 13.6625 14.112 14.4391C14.1069 15.2484 13.5013 15.8281 12.57 15.9922ZM11.5216 10.8406C10.8193 10.6 10.1781 10.2008 10.1883 9.42422C10.1934 8.71875 10.687 8.17188 11.542 8.01328L11.5216 10.8406Z" fill="white" />
                </svg>
                Total líquido:
                {' '}
                <span className="">{`R$ ${installaments.meta.totalLiquido.toFixed(2)}`}</span>
              </p>
            </div>
            <div className="income-fetched-value-wrapper d-flex align-items-center justify-content-center" style={{ marginBottom: 2 }}>
              <p className="">
                Número geral de parcelas por status:
                {' '}
                <span className="">{`${installaments.meta.totalByFilter}/${installaments.meta.totalInstallments}`}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 card-bg p-4">
          <div className="row">
            <h1>Relatório de parcelas</h1>
            <div className="table-wrapper">
              <div className="table-actions">
                <div className="m-2 d-flex align-items-center">
                  <div className="">
                    <button className="table-btn-primary" type="button" onClick={() => null} data-bs-toggle="modal" data-bs-target="#installmentsReportFilter">
                      <span className="material-icons-round">
                        search
                      </span>
                      Filtrar
                    </button>
                  </div>

                </div>
              </div>
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">ID do contrato</th>
                    <th scope="col">ID do pagamento</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Número da parcela</th>
                    <th scope="col">Gateway</th>
                    <th scope="col">Método</th>
                    <th scope="col">Vencimento</th>
                    <th scope="col">Previsão</th>
                    <th scope="col">Dt. Pgto.</th>
                    <th scope="col">Status</th>
                    <th scope="col">Valor Bruto (R$)</th>
                    <th scope="col">Valor Líquido (R$)</th>
                  </tr>
                </thead>
                <tbody>
                  {installaments.data.length === 0 && 'Nenhum resultado para sua busca.'}
                  {installaments.data.map((item, i) => (
                    <tr key={i}>
                      <td style={{ fontSize: 12, fontWeight: 'bold' }}><Link to={goToContract(item)}>{item.ispj ? `PJ00${item.contractid}` : `PF00${item.contractid}`}</Link></td>
                      <td style={{ fontSize: 12, fontWeight: 'bold' }}><Link to={goToPayment(item)}>{item.paymentid}</Link></td>
                      <td style={{ fontSize: 12, fontWeight: 'bold' }}><Link to={goToHolder(item)}>{showCompactName(item.titular)}</Link></td>
                      <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.installmentnumber}</td>
                      <td style={{ fontSize: 12, fontWeight: 'bold' }}>{showGatewayNameById(item.gateway)}</td>
                      <td style={{ fontSize: 12, fontWeight: 'bold' }}>{resolveMethod(item)}</td>
                      <td style={{ fontSize: 12, fontWeight: 'bold' }}>{normalizeDate(item.vencimento)}</td>
                      <td style={{ fontSize: 12, fontWeight: 'bold' }}>{normalizeDate(item.prevision)}</td>
                      <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.paymentdate ? normalizeDate(item.paymentdate) : 'N/A'}</td>
                      {showStatusLabel(item)}
                      <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.valor.toFixed(2)}</td>
                      <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.valorliquido.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="table-footer">
                <button type="button" onClick={() => goToFirstPage()}>Primeira</button>
                <p>Página</p>
                <select style={{ minWidth: 300 }} value={installaments.meta.currentPage} onChange={(e) => goToPage(Number(e.target.value))}>
                  {generatePagination(installaments.meta.lastPage).map((item) => <option value={item}>{item}</option>)}
                </select>
                <p>
                  de
                  {' '}
                  <strong>{installaments.meta.lastPage}</strong>
                </p>
                <button
                  type="button"
                  onClick={() => goToNextPage()}
                >
                  Próxima

                </button>
                <button
                  type="button"
                  onClick={() => goToLastPage()}
                >
                  Última

                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
