/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import download from 'downloadjs';
import { DateTime } from 'luxon';
import { AxiosError } from 'axios';
import BasicInput from '../../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import CancelChargeModal from '../../../components/CancelChargeModal';
import CancelGalaxPayChargeModal from '../../../components/CancelGalaxPayChargeModal';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import PriceInput from '../../../components/PriceInput';
import RefundModal from '../../../components/Refund';
import Tooltip from '../../../components/Tooltip';
import api from '../../../config/axios';
import { showTodayDate } from '../../../helpers/dates';
import { maskDate } from '../../../helpers/inputMasks.js';
import resolvePaymentStatus from '../../../helpers/resolvePaymentStatus';
import { convertCardPaymentMethodFromApi } from '../../../helpers/showCardMethods';
import DefaultLayout from '../../../Layout';
import { storeType } from '../../../store';
import { PaymentGateway } from '../../PaymentTax/types';
import EditInstallmentModal from './InstallmentModal';
import NewInstallmentModal from './NewInstallmentPFModal';
import AuthorizationModal from './AuthorizationModal';
import AddGroupOfInstallmentsModal from '../../../components/AddGroupOfInstallmentsModal';
import AddSubscription from '../../../components/AddSubscription';

export type PagVipRefProps = {
  id: number,
  pagazulHolderLinksId: number,
  contractPfPaymentInstallmentId: number,
  idCharge: string,
  bankSlip: string,
  createdAt: string,
  updatedAt: string,
}

type PaymentProps = {
    id: number;
    contractPfId: number;
    contractValue: number;
    discount: number;
    aditional: number;
    installments: number;
    totalValue: number;
    approvalDate: string;
    installment: InstallmentDTO[]
    contract?: {
      signatureDate: string,
      holder: {
        id: number,
        name: string,
        galaxpayRefferId: string | null
      }
    }
}

export type InstallmentDTO = {
  id: number
  contractPfPaymentId: number
  uuid: string
  dueDate: string
  status: string
  value: number
  cieloInstallmentsGroup: number | null
  liquidValue: number,
  previsionDate: string,
  paidBy: string
  authorizationDate: string | null
  paymentDate: string | null
  paymentGatewayId: number
  nonCardTaxes: string | null
  taxUsed: number
  pagvipRef: string | null
  card: boolean
  flag: string | null
  cardLastDigits: string | null
  cardPaymentOption: string | null
  boletoPayvip: PagVipRefProps | null
  isGalaxpaySubscription: boolean
  galaxpayPaymentMethod: 'boleto' | 'cartao' | 'pix'
  galaxpayInstallmentId: string | null
}

export default function EditPaymentPFV2() {
  const { id } = useParams();
  const navigate = useNavigate();
  const admin = useSelector(({ adminReducer }: storeType) => adminReducer);

  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [reason, setReason] = useState('');
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [paymentGateways, setPaymentGateways] = useState<PaymentGateway[]>([]);
  const [payment, setPayment] = useState<PaymentProps>({
    id: 0,
    contractPfId: 0,
    contractValue: 0,
    discount: 0,
    aditional: 0,
    installments: 0,
    totalValue: 0,
    approvalDate: '',
    installment: [],
  });

  async function fetchAllTax() {
    try {
      setLoading(true);
      const response = await api.get('/admin/configuration/payment-gateway', token);
      setPaymentGateways(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  async function fetchPayment() {
    try {
      setLoading(true);
      await fetchAllTax();
      const response = await api.get(`/admin/contractpf/payment/${id}`, token);
      console.log('installments', response.data);
      setPayment(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível localizar pagamento', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
    }
  }

  async function manualInstallmentPayment(installmentId: number) {
    try {
      setLoading(true);
      setModal(null);
      await api.post('admin/contractpf/installment/manual', {
        id: installmentId,
        paymentDate: showTodayDate(),
      }, token);
      window.location.reload();
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível dar baixa manual na parcela em questão. Verifique se a mesma já não consta como status PAGO.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
      setLoading(false);
    }
  }

  function checkIfIsAdminAllowed() {
    if (admin.id === 2 || admin.id === 3 || admin.id === 4 || admin.id === 18 || admin.id === 9 || admin.id === 7 || admin.id === 63 || admin.id === 9) {
      return true;
    }
    return false;
  }

  function checkIfExistsPaidInstallment() {
    const paids = payment.installment.filter((item) => item.status === 'PAID' || item.status === 'OTHER');
    if (paids.length > 0) {
      return true;
    }
    return false;
  }

  function showFlag(flag: string) {
    if (flag === 'OTHER') {
      return 'OUTRA';
    }
    return flag;
  }

  function showGatewayName(idx: number) {
    if (idx === 1) {
      return 'PagAzul PRESENCIAL';
    }
    const filteredGateway = paymentGateways.filter((item) => item.id === idx);
    if (filteredGateway.length > 0) {
      return filteredGateway[0].name;
    }
    return '';
  }

  function showGeneratePdfPayVipButton(item: InstallmentDTO) {
    if (item.paymentGatewayId === 3 && item.nonCardTaxes === 'BOLETO') {
      return true;
    }
    return false;
  }

  function showGeneratePdfGalaxpayButton(item: InstallmentDTO) {
    if (item.paymentGatewayId === 5 && item.nonCardTaxes === 'BOLETO') {
      return true;
    }
    return false;
  }

  function showGeneratePIXGalaxpayButton(item: InstallmentDTO) {
    if (item.paymentGatewayId === 5 && item.nonCardTaxes === 'PIX') {
      return true;
    }
    return false;
  }

  async function deletePayment() {
    try {
      setLoading(true);
      if (checkIfExistsPaidInstallment()) {
        setLoading(false);
        setModal({ title: 'Ocorreu um erro', children: 'Não é possível deletar um pagamento que possua uma parcela paga.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
      } else {
        await api.delete(`admin/contractpf/payment/${payment.id}`, token);
        navigate(-1);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível remover pagamento. Certifique-se se não há uma parcela já paga.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
    }
  }

  async function changePayment() {
    try {
      setLoading(true);
      await api.put(`admin/contractpf/payment/${payment.id}`, payment, token);
      setLoading(false);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível alterar pagamento.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
    }
  }

  async function deleteOneInstallment(installmentCtx: InstallmentDTO) {
    try {
      if (installmentCtx.boletoPayvip || installmentCtx.galaxpayPaymentMethod) {
        if (admin.id !== 3 && admin.id !== 63) {
          setModal({ title: 'Ocorreu um erro', children: 'Você não tem permissão para deletar uma parcela que possui boleto gerado.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
          return;
        }
      }
      setLoading(true);
      setModal(null);
      const filteredNonpaid = payment.installment.filter((item) => item.status !== 'PAID' && item.id !== installmentCtx.id);
      await api.put(`admin/contractpf/installment/delete/${installmentCtx.id}`, {
        installments: filteredNonpaid,
      }, token);
      fetchPayment();
      setLoading(false);
    } catch (err: any) {
      setModal(null);
      if (err.response?.data?.message === 'NF Emitida') {
        setModal({ title: 'Ocorreu um erro', children: 'Não é possível excluir uma parcela que a NF já tenha sido emitida.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
      } else {
        setModal({ title: 'Ocorreu um erro', children: 'Não foi possível deletar uma parcela. Atualize a página e tente novamente.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
      }
      setLoading(false);
    }
  }

  function validateInstallmentsWithPaymentValue(): { equal: boolean, diff: 'CONTRACT IS MAJOR' | 'INSTALLMENTS ARE MAJOR' | 'NONE', value: number } {
    const installmentsValue = payment.installment.filter((item) => item.status !== 'REVERSED').map((item) => item.value).reduce((a, b) => a + b, 0);
    const result = payment.totalValue - installmentsValue;
    if (result > 0.2) {
      return {
        equal: false,
        diff: 'CONTRACT IS MAJOR',
        value: result,
      };
    }
    if (result < -0.2) {
      return {
        equal: false,
        diff: 'INSTALLMENTS ARE MAJOR',
        value: result * -1,
      };
    }
    return {
      equal: true,
      diff: 'NONE',
      value: result,
    };
  }

  function showPaymentDiffErrorOnValidator() {
    if (payment.totalValue === 0) {
      return 'Digite um valor';
    }
    if (!validateInstallmentsWithPaymentValue().equal) {
      return 'O valor do pagamento e o total das parcelas não coincidem';
    }
    return '';
  }

  function showDeleteChargePDF(installmentCtx: InstallmentDTO) {
    if (admin.id === 2 || admin.id === 3 || admin.id === 4 || admin.id === 18 || admin.id === 9 || admin.id === 7 || admin.id === 63 || admin.id === 9) {
      if (installmentCtx.boletoPayvip && installmentCtx.status !== 'PAID') {
        return true;
      }
      return false;
    }
    return false;
  }

  function showDeleteGalaxyChargePDF(installmentCtx: InstallmentDTO) {
    if (admin.id === 2 || admin.id === 3 || admin.id === 4 || admin.id === 18 || admin.id === 9 || admin.id === 7 || admin.id === 63 || admin.id === 9) {
      if ((installmentCtx.galaxpayPaymentMethod === 'boleto' || installmentCtx.galaxpayPaymentMethod === 'pix') && installmentCtx.status !== 'PAID') {
        return true;
      }
      return false;
    }
    return false;
  }

  function showRefund(installment: InstallmentDTO) {
    if ((installment.status === 'OTHER' || installment.status === 'PAID') && installment.paymentGatewayId === 5 && installment.card) {
      return true;
    }
    return false;
  }

  async function generateChargeGalaxpayPIX(installment: string) {
    try {
      setLoading(true);
      const response = await api.get(`/admin/galaxpay/pix-pf/installment/${installment}`, token);
      window.open(response.data, '_blank');
      setTimeout(() => window.location.reload(), 1000);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível gerar pix para essa fatura.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
    }
  }

  async function generateChargePDF(installment: string) {
    try {
      setLoading(true);
      const response = await api.get(`/admin/contractpf/installment/generate-boleto/${installment}`, token);
      window.location.assign(response.data);
      setTimeout(() => window.location.reload(), 1000);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível gerar boleto para essa fatura.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
    }
  }

  async function exportInstallmentsToExcel() {
    try {
      setLoading(true);
      console.log(id);
      const response = await api.get(`admin/paymentexcell/pf/${id}`, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, `Parcelas ${payment.contract ? payment.contract.holder.name : 'Parcelas'}.xlsx`);
      setLoading(false);
    } catch (err: any) {
      console.log('error', err.response.data);
      setLoading(false);
    }
  }

  async function generateChargeGalaxpayPDF(installment: string) {
    try {
      setLoading(true);
      const response = await api.get(`/admin/galaxpay/boleto-pf/installment/${installment}`, token);
      window.open(response.data, '_blank');
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível gerar boleto para essa fatura.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
    }
  }

  async function tryRefund(installment: InstallmentDTO) {
    try {
      setLoading(true);
      setModal({ title: 'Processando estorno', children: 'Processando estorno... Aguarde.', actions: [] });
      await api.put('admin/galaxpay/reverse/installment-pf', {
        galaxPayId: Number(installment.galaxpayInstallmentId),
        reason,
      }, token);
      setModal({ title: 'Estornado', children: 'Estorno realizado com sucesso!', actions: [{ label: 'Entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      setLoading(false);
    } catch (err: any) {
      console.log('erro', err.response.data);
      setModal({ title: 'Erro', children: 'Ocorreu uma falha ao realizar estorno junto à galaxpay!', actions: [{ label: 'Entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      setLoading(false);
    }
  }

  async function removeUnpaidInstallments() {
    try {
      setLoading(true);
      await api.get(`admin/contractpf/installment/delete-installments/${id}`, token);
      await fetchPayment();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Erro', children: 'Ocorreu uma falha ao tentar excluir todas parcelas não pagas / não autorizadas', actions: [{ label: 'Entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      console.log(err);
    }
  }

  function showAuthorizationBtn(installment: InstallmentDTO) {
    return installment.paymentGatewayId === 4 && installment.status === 'UNPAID';
  }

  useEffect(() => {
    fetchPayment();
  }, []);
  return (
    <DefaultLayout
      pageTitle="Visualizar pagamento"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={false}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      {paymentGateways.length > 0 && (
      <>
        <NewInstallmentModal id="add-new-installment" title="Adicionar nova parcela" paymentGateways={paymentGateways} paymentId={payment.id} />
        <AddGroupOfInstallmentsModal id="add-grupo-de-parcelas-pf" paymentId={payment.id} isPf value={payment.contractValue} signatureDate={payment.contract ? payment.contract.signatureDate : DateTime.now().toFormat('dd-MM-yyyy')} />
        <AddSubscription id="add-subscription" paymentId={payment.id} isPf clientMyId={payment.contract?.holder.galaxpayRefferId || ''} contractId={payment.contractPfId} />
      </>
      )}
      <div className="row products mt-4">
        <div className="col-12 card-bg p-4">
          <div className="row">
            <h1>
              Forma de pagamento
              <span style={{ marginLeft: 8 }}>-</span>
              <Link to={`/cliente/pessoa-fisica/editar-titular/${payment.contract ? payment.contract.holder.id : '/'}`}>
                <span className="payment-client-link">{payment.contract ? payment.contract.holder.name : ''}</span>
              </Link>
            </h1>
            <div className="col-4">
              <PriceInput
                id="valor-contrato"
                label="Valor do contrato"
                readOnly={!checkIfIsAdminAllowed()}
                helper="Ex: 500"
                error={payment.contractValue === 0 ? 'Digite um valor' : ''}
                value={payment.contractValue.toString()}
                type="number"
                onChange={(data) => setPayment({ ...payment, contractValue: Number(data) })}
              />
            </div>
            <div className="col-4">
              <PriceInput
                id="valor-final"
                label="Valor final"
                readOnly={!checkIfIsAdminAllowed()}
                helper="Ex: 600"
                error={showPaymentDiffErrorOnValidator()}
                value={payment.totalValue.toString()}
                type="number"
                onChange={(data) => setPayment({ ...payment, totalValue: Number(data) })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="data-aprovacao"
                label="Data de aprovação"
                readOnly={!checkIfIsAdminAllowed()}
                helper="Ex: 30-04-2022"
                error={payment.approvalDate.length < '30-04-2009'.length ? 'Digite uma data válida' : ''}
                value={payment.approvalDate.toString()}
                type="text"
                onChange={(data) => setPayment({ ...payment, approvalDate: maskDate(data) })}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Excluir todo pagamento"
                type={BtnType.DANGER}
                icon="delete"
                active={checkIfIsAdminAllowed()}
                onClick={() => deletePayment()}
                ref={null}
              />
              <ButtonTextIcon
                label="Alterar pagamento"
                type={BtnType.PRIMARY}
                icon="backup"
                active={checkIfIsAdminAllowed()}
                onClick={() => changePayment()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row seller">
        {!validateInstallmentsWithPaymentValue().equal && (

          <>
            {validateInstallmentsWithPaymentValue().diff === 'CONTRACT IS MAJOR' && (
            <div className="col-12 p-0 card-bg p-4 mb-4 d-flex align-items-center " style={{ backgroundColor: 'orange' }}>
              <span className="material-icons-round text-white" style={{ marginRight: 8 }}>
                warning
              </span>
              <p className="fw-bold text-white">
                O Valor total do contrato ultrapassa o valor das parcelas em R$
                {' '}
                {validateInstallmentsWithPaymentValue().value.toFixed(2)}
              </p>
            </div>
            )}
            {validateInstallmentsWithPaymentValue().diff === 'INSTALLMENTS ARE MAJOR' && (
            <div className="col-12 p-0 card-bg p-4 mb-4 d-flex align-items-center " style={{ backgroundColor: 'orange' }}>
              <span className="material-icons-round text-white" style={{ marginRight: 8 }}>
                warning
              </span>
              <p className="fw-bold text-white">
                O Valor da soma das parcelas ultrapassa o valor total do contrato em R$
                {' '}
                {validateInstallmentsWithPaymentValue().value.toFixed(2)}
              </p>
            </div>
            )}
          </>

        )}

        <div className="col-12 d-flex align-items-center justify-content-center mb-4">
          {/* <button type="button">Exportar para Excell</button> */}
          <button
            type="button"
            className="generate-excel-btn-wrapper"
            onClick={() => exportInstallmentsToExcel()}
          >
            <span className="material-icons-round">assignment_returned</span>
            Exportar parcelas para Excel
          </button>
        </div>

        <div className="col-12 p-0 card-bg p-4">
          <h1>Parcelas Estornadas</h1>
          <div className="table-wrapper">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Parcela</th>
                  <th scope="col">Vencimento</th>
                  <th scope="col">Previsão</th>
                  <th scope="col">Dt. Autorização</th>
                  <th scope="col">Valor Bruto</th>
                  <th scope="col">Valor Líq.</th>
                  <th scope="col">Gateway</th>
                  <th scope="col">Grupo</th>
                  <th scope="col">Forma</th>
                  <th scope="col">Bandeira</th>
                  <th scope="col">Status</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>

                {payment.installment.length > 0 ? payment.installment.filter((item) => item.status === 'REVERSED').map((item, i) => (
                  <tr key={item.id}>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>ESTORNADO</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.dueDate}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.previsionDate}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.authorizationDate || 'N/A'}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.value.toFixed(2)}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.liquidValue.toFixed(2)}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{showGatewayName(item.paymentGatewayId)}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.cieloInstallmentsGroup ? item.cieloInstallmentsGroup : 'N/A'}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.card ? convertCardPaymentMethodFromApi(item.cardPaymentOption!) : item.nonCardTaxes}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.card ? showFlag(item.flag!) : 'N/A'}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{resolvePaymentStatus(item.status, item.dueDate) }</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }} align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        { item.status !== 'REVERSED' && (
                        <>
                          {item.status !== 'PAID' && (
                          <div className="table-action-icon">
                            <button
                              type="button"
                              onClick={() => setModal({ title: 'Deletar uma parcela', children: 'Você ter certeza que deseja excluir essa parcela?', actions: [{ label: 'Cancelar', onClick: () => setModal(null), type: ModalActionType.PRIMARY }, { label: 'Excluir parcela', onClick: () => deleteOneInstallment(item), type: ModalActionType.DANGER }] })}
                              aria-describedby="delete-installment"
                              id="delete-installment"
                            >
                              <span className="material-icons-round" style={{ color: 'ff5000' }}>
                                delete
                              </span>
                            </button>
                            <Tooltip id="delete-installment" text="Essa ação irá excluir a parcela." />
                          </div>
                          )}
                          {showDeleteChargePDF(item) && (
                          <>
                            <div className="table-action-icon">
                              <button
                                type="button"
                                onClick={() => null}
                                aria-describedby="delete pdf"
                                data-bs-toggle="modal"
                                data-bs-target={`#cancel-charge-pdf-${item.id}`}
                                id="delete-pdf"
                              >
                                <span className="material-icons-round" style={{ color: 'orange' }}>
                                  cancel
                                </span>
                              </button>
                              <Tooltip id="delete-pdf" text="Essa ação irá deletar um boleto." />
                            </div>
                            <CancelChargeModal
                              pagVipProps={item.boletoPayvip!}
                              id={`cancel-charge-pdf-${item.id}`}
                              title="Cancelar boleto"
                              isPf
                              installmentId={item.id}
                              fetchPayment={() => fetchPayment()}
                            />
                          </>
                          )}
                          {showDeleteGalaxyChargePDF(item) && (
                          <>
                            <div className="table-action-icon">
                              <button
                                type="button"
                                onClick={() => null}
                                aria-describedby="delete pdf"
                                data-bs-toggle="modal"
                                data-bs-target={`#cancel-charge-pdf-${item.id}`}
                                id="delete-pdf"
                              >
                                <span className="material-icons-round" style={{ color: 'orange' }}>
                                  cancel
                                </span>
                              </button>
                              <Tooltip id="delete-pdf" text="Essa ação irá cancelar uma cobrança na galaxpay." />
                            </div>
                            <CancelGalaxPayChargeModal
                              id={`cancel-charge-pdf-${item.id}`}
                              title="Cancelar boleto"
                              isPf
                              galaxpayInstallmentId={item.galaxpayInstallmentId ? item.galaxpayInstallmentId : ''}
                              fetchPayment={() => fetchPayment()}
                            />
                          </>
                          )}

                          {showGeneratePdfPayVipButton(item) && (
                          <div className="table-action-icon">
                            <button
                              type="button"
                              onClick={() => generateChargePDF(item.id.toString())}
                              aria-describedby="baixa"
                              id="generate-pdf"
                            >
                              <span className="material-icons-round">
                                picture_as_pdf
                              </span>
                            </button>
                            <Tooltip id="generate-pdf" text="Essa ação irá gerar/imprimir um boleto." />
                          </div>
                          )}

                          {showGeneratePdfGalaxpayButton(item) && (
                          <div className="table-action-icon">
                            <button
                              type="button"
                              onClick={() => generateChargeGalaxpayPDF(item.id.toString())}
                              aria-describedby="baixa"
                              id="generate-pdf"
                            >
                              <span className="material-icons-round">
                                picture_as_pdf
                              </span>
                            </button>
                            <Tooltip id="generate-pdf" text="Essa ação irá gerar/imprimir um boleto." />
                          </div>
                          )}

                          {showGeneratePIXGalaxpayButton(item) && (
                          <div className="table-action-icon">
                            <button
                              type="button"
                              onClick={() => generateChargeGalaxpayPIX(item.id.toString())}
                              aria-describedby="baixa"
                              id="generate-pix"
                            >
                              <span className="material-icons-round">
                                qr_code_2
                              </span>
                            </button>
                            <Tooltip id="generate-pix" text="Essa ação irá gerar/imprimir um pix." />
                          </div>
                          )}

                          {item.status !== 'PAID' && (
                          <div className="table-action-icon">
                            <button
                              type="button"
                              onClick={() => {
                                setModal({ title: 'Realizar baixa manual', children: 'Deseja mesmo realizar a baixa manual dessa fatura?', actions: [{ label: 'Dar baixa', type: ModalActionType.PRIMARY, onClick: () => manualInstallmentPayment(item.id) }, { label: 'Cancelar', type: ModalActionType.DANGER, onClick: () => setModal(null) }] });
                              }}
                              aria-describedby="manual"
                              id="baixa-manual"
                            >
                              <span className="material-icons-round">
                                credit_score
                              </span>
                            </button>
                            <Tooltip id="baixa-manual" text="Essa ação irá realizar a baixa manual." />
                          </div>
                          )}
                        </>
                        )}

                        <div className="table-action-icon">
                          <button type="button" data-bs-toggle="modal" data-bs-target={`#installment-${item.id}`}>
                            <span className="material-icons-round">
                              more
                            </span>

                          </button>
                        </div>
                        <EditInstallmentModal
                          id={`installment-${item.id}`}
                          title="Ver Parcela"
                          installmentDTO={item}
                          installmentIndex={i}
                          paymentGateways={paymentGateways}
                          fetchAllInstallment={() => fetchPayment()}
                        />
                      </div>
                    </td>
                  </tr>
                )) : (<p>Nenhuma categoria cadastrada</p>)}
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-12 p-0 card-bg p-4 mt-4">
          <h1>Parcelas Ativas</h1>
          <div className="table-wrapper">
            <div className="table-actions d-flex justify-content-start">
              {paymentGateways.length > 0 && (
              <>
                <button className="table-btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#add-new-installment">
                  <span className="material-icons-round">
                    add
                  </span>
                  parcela
                </button>
                <button className="table-btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#add-grupo-de-parcelas-pf">
                  <span className="material-icons-round">
                    add
                  </span>
                  grupo de parcelas
                </button>
                <button className="table-btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#add-subscription">
                  <span className="material-icons-round">
                    add
                  </span>
                  recorrência galaxpay
                </button>
                <button className="table-btn-danger" type="button" onClick={removeUnpaidInstallments}>
                  <span className="material-icons-round">
                    remove
                  </span>
                  Remover parcelas não pagas / não autorizadas REDESANTA
                </button>
              </>
              )}
            </div>
            <table className="table table-borderless">
              <thead>

                <tr>
                  <th scope="col">Parcela</th>
                  <th scope="col">Vencimento</th>
                  <th scope="col">Previsão</th>
                  <th scope="col">Dt. Autorização</th>
                  <th scope="col">Valor Bruto</th>
                  <th scope="col">Valor Líq.</th>
                  <th scope="col">Gateway</th>
                  <th scope="col">Grupo</th>
                  <th scope="col">Forma</th>
                  <th scope="col">Bandeira</th>
                  <th scope="col">Status</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>

                {payment.installment.length > 0 ? payment.installment.filter((item) => item.status !== 'REVERSED').map((item, i) => (
                  <tr key={item.id}>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{`P. ${i + 1}`}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.dueDate}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.previsionDate}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.authorizationDate || 'N/A'}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.value.toFixed(2)}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.liquidValue.toFixed(2)}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{showGatewayName(item.paymentGatewayId)}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.cieloInstallmentsGroup ? item.cieloInstallmentsGroup : 'N/A'}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.card ? convertCardPaymentMethodFromApi(item.cardPaymentOption!) : item.nonCardTaxes}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.card ? showFlag(item.flag!) : 'N/A'}</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }}>{resolvePaymentStatus(item.status, item.dueDate) }</td>
                    <td style={{ fontSize: 12, fontWeight: 'bold' }} align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        { item.status !== 'REVERSED' && (
                        <>
                          {item.status !== 'PAID' && (
                          <div className="table-action-icon">
                            <button
                              type="button"
                              onClick={() => setModal({ title: 'Deletar uma parcela', children: 'Você ter certeza que deseja excluir essa parcela?', actions: [{ label: 'Cancelar', onClick: () => setModal(null), type: ModalActionType.PRIMARY }, { label: 'Excluir parcela', onClick: () => deleteOneInstallment(item), type: ModalActionType.DANGER }] })}
                              aria-describedby="delete-installment"
                              id="delete-installment"
                            >
                              <span className="material-icons-round" style={{ color: 'ff5000' }}>
                                delete
                              </span>
                            </button>
                            <Tooltip id="delete-installment" text="Essa ação irá excluir a parcela." />
                          </div>
                          )}
                          {showRefund(item) && (
                          <>
                            <div className="table-action-icon">
                              <button
                                type="button"
                                onClick={() => null}
                                aria-describedby="refund"
                                data-bs-toggle="modal"
                                data-bs-target={`#refund-${item.id}`}
                                id="refundbtn"
                              >
                                <span className="material-icons-round" style={{ color: 'orange' }}>
                                  sync_problem
                                </span>
                              </button>
                              <Tooltip id="refund" text="Essa ação irá estornar a cobrança no cartão." />
                            </div>
                            <RefundModal
                              id={`refund-${item.id}`}
                              refundReason={reason}
                              setRefundReason={(data) => setReason(data)}
                              setRefund={() => tryRefund(item)}
                            />
                          </>
                          )}
                          {showDeleteChargePDF(item) && (
                          <>
                            <div className="table-action-icon">
                              <button
                                type="button"
                                onClick={() => null}
                                aria-describedby="delete pdf"
                                data-bs-toggle="modal"
                                data-bs-target={`#cancel-charge-pdf-${item.id}`}
                                id="delete-pdf"
                              >
                                <span className="material-icons-round" style={{ color: 'orange' }}>
                                  cancel
                                </span>
                              </button>
                              <Tooltip id="delete-pdf" text="Essa ação irá deletar um boleto." />
                            </div>
                            <CancelChargeModal
                              pagVipProps={item.boletoPayvip!}
                              id={`cancel-charge-pdf-${item.id}`}
                              title="Cancelar boleto"
                              isPf
                              installmentId={item.id}
                              fetchPayment={() => fetchPayment()}
                            />
                          </>
                          )}
                          {showDeleteGalaxyChargePDF(item) && (
                          <>
                            <div className="table-action-icon">
                              <button
                                type="button"
                                onClick={() => null}
                                aria-describedby="delete pdf"
                                data-bs-toggle="modal"
                                data-bs-target={`#cancel-charge-pdf-${item.id}`}
                                id="delete-pdf"
                              >
                                <span className="material-icons-round" style={{ color: 'orange' }}>
                                  cancel
                                </span>
                              </button>
                              <Tooltip id="delete-pdf" text="Essa ação irá cancelar uma cobrança na galaxpay." />
                            </div>
                            <CancelGalaxPayChargeModal
                              id={`cancel-charge-pdf-${item.id}`}
                              title="Cancelar boleto"
                              isPf
                              galaxpayInstallmentId={item.galaxpayInstallmentId ? item.galaxpayInstallmentId : ''}
                              fetchPayment={() => fetchPayment()}
                            />
                          </>
                          )}

                          {showGeneratePdfPayVipButton(item) && (
                          <div className="table-action-icon">
                            <button
                              type="button"
                              onClick={() => generateChargePDF(item.id.toString())}
                              aria-describedby="baixa"
                              id="generate-pdf"
                            >
                              <span className="material-icons-round">
                                picture_as_pdf
                              </span>
                            </button>
                            <Tooltip id="generate-pdf" text="Essa ação irá gerar/imprimir um boleto." />
                          </div>
                          )}

                          {showAuthorizationBtn(item) && (
                          <div className="table-action-icon">
                            <button
                              type="button"
                              onClick={() => null}
                              aria-describedby="baixa"
                              id="authorize"
                              data-bs-toggle="modal"
                              data-bs-target={`#authorization-modal-${item.id}`}
                            >
                              <span className="material-icons-round" style={{ color: '#6200EE' }}>
                                spellcheck
                              </span>
                            </button>
                            <Tooltip id="authorize" text="CIELO: Essa ação irá autorizar a parcela." />
                            <AuthorizationModal id={`authorization-modal-${item.id}`} isPf installmentId={item.id} />
                          </div>
                          )}

                          {showGeneratePdfGalaxpayButton(item) && (
                          <div className="table-action-icon">
                            <button
                              type="button"
                              onClick={() => generateChargeGalaxpayPDF(item.id.toString())}
                              aria-describedby="baixa"
                              id="generate-pdf"
                            >
                              <span className="material-icons-round">
                                picture_as_pdf
                              </span>
                            </button>
                            <Tooltip id="generate-pdf" text="Essa ação irá gerar/imprimir um boleto." />
                          </div>
                          )}

                          {showGeneratePIXGalaxpayButton(item) && (
                          <div className="table-action-icon">
                            <button
                              type="button"
                              onClick={() => generateChargeGalaxpayPIX(item.id.toString())}
                              aria-describedby="baixa"
                              id="generate-pix"
                            >
                              <span className="material-icons-round">
                                qr_code_2
                              </span>
                            </button>
                            <Tooltip id="generate-pix" text="Essa ação irá gerar/imprimir um pix." />
                          </div>
                          )}

                          {item.status !== 'PAID' && (
                          <div className="table-action-icon">
                            <button
                              type="button"
                              onClick={() => {
                                setModal({ title: 'Realizar baixa manual', children: 'Deseja mesmo realizar a baixa manual dessa fatura?', actions: [{ label: 'Dar baixa', type: ModalActionType.PRIMARY, onClick: () => manualInstallmentPayment(item.id) }, { label: 'Cancelar', type: ModalActionType.DANGER, onClick: () => setModal(null) }] });
                              }}
                              aria-describedby="manual"
                              id="baixa-manual"
                            >
                              <span className="material-icons-round">
                                credit_score
                              </span>
                            </button>
                            <Tooltip id="baixa-manual" text="Essa ação irá realizar a baixa manual." />
                          </div>
                          )}
                        </>
                        )}

                        <div className="table-action-icon">
                          <button type="button" data-bs-toggle="modal" data-bs-target={`#installment-${item.id}`}>
                            <span className="material-icons-round">
                              more
                            </span>
                          </button>
                        </div>
                        <EditInstallmentModal
                          id={`installment-${item.id}`}
                          title="Ver Parcela"
                          installmentDTO={item}
                          installmentIndex={i}
                          paymentGateways={paymentGateways}
                          fetchAllInstallment={() => fetchPayment()}
                        />
                      </div>
                    </td>
                  </tr>
                )) : (<p>Nenhuma categoria cadastrada</p>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
