/* eslint-disable no-shadow */
import React, { useState } from 'react';
import TextAreaInput from '../../../components/TextAreaInput';
import api from '../../../config/axios';

type FilterProps = {
    id: string,
    setLoading: (arg0: boolean) => void,
}

enum PageStates {
    LOADING,
    SUCCESS,
    ERROR,
    IDLE
}

export default function BlockTempContractPFModal({
  id,
  setLoading,
}: FilterProps) {
  const [blockReason, setBlockReason] = useState('');
  const [actualPageState, setActualPageState] = useState(PageStates.IDLE);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [ref] = useState(React.createRef<any>());

  async function setBlock() {
    try {
      setLoading(true);
      await api.post('admin/contracts/pf/block-temp', {
        contractPfId: id,
        reason: blockReason.trim(),
      }, token);
      setActualPageState(PageStates.SUCCESS);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function onClose() {
    window.location.reload();
  }
  return (
    <div className="modal fade" id={`bloq-temp-${id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Bloqueio temporário</h5>
            <button type="button" className="btn-close d-none" onClick={() => onClose()} aria-label="Close" />
            <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" ref={ref} />
          </div>
          <div className="modal-body" style={{ minHeight: 300 }}>
            {actualPageState === PageStates.IDLE && (
            <>
              <p className="mb-4 fw-bold text-start">Essa ação irá bloquear temporariamente o contrato e todos os usuários a ele relacionado (titular e dependentes). Tem certeza?</p>
              <TextAreaInput
                id=""
                label="Motivo do bloqueio"
                readOnly={false}
                helper="motivo do bloqueio"
                error=""
                value={blockReason}
                onChange={(data) => setBlockReason(data)}
              />

            </>
            )}

            {actualPageState === PageStates.LOADING && (
            <p>Carregando, aguarde...</p>
            )}

            {actualPageState === PageStates.ERROR && (
            <p>Ocorreu um erro ao tentar fazer o bloqueio. Tente novamente mais tarde.</p>
            )}

            {actualPageState === PageStates.SUCCESS && (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 300 }}>
              <p className="text-center">Contrato bloqueado com sucesso!</p>
            </div>
            )}

          </div>
          <div className="modal-footer">
            {actualPageState !== PageStates.LOADING && (<button type="button" className="btn btn-secondary" onClick={() => onClose()}>Fechar</button>)}
            {actualPageState !== PageStates.LOADING && actualPageState !== PageStates.SUCCESS && (
            <button type="button" className="btn btn-danger" onClick={() => setBlock()}>Bloquear</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
