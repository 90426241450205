import React from 'react';

type ErrorModalProps = {
    errors : string[]
}

export default function ErrorModal({ errors }: ErrorModalProps) {
  return (
    <div className="modal fade" id="errormodal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Erro(s)</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center" style={{ minHeight: 400 }}>
            {errors.map((item) => (
              <p className="fw-bold mb-1">
                -
                {' '}
                {item}

              </p>
            ))}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
