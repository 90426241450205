/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
import { DateTime } from 'luxon';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import BasicOption from '../../../components/BasicOption';
import DashboardCardOverlay from '../../../components/DashboardCardOverlay';
import NoDataGraphOverlay from '../../../components/NoDataGraphOverlay';
import api from '../../../config/axios';
import {
  showArrayOfMonthsLimitedByStart, showAvailableYears, showMonthAsNumberBasedOnName, showMonthAsStringBasedOnNumber,
} from '../../../helpers/dates';

type ResponseProps = {
  series: string[],
  data: number[]
}

export default function SellByProducts() {
  const [series, setSeries] = useState<string[]>([]);
  const [data, setData] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const [allTimeFilter, setAllTimeFilter] = useState(false);
  const [filterMonth, setFilterMonth] = useState(DateTime.now().month);
  const [filterYear, setFilterYear] = useState(DateTime.now().year);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function fetchData() {
    try {
      setLoading(true);
      const response = await api.post<ResponseProps>('/admin/reports/contractsbyproduct', {
        month: allTimeFilter ? null : filterMonth,
        year: allTimeFilter ? null : filterYear,
      }, token);
      setData(response.data.data);
      setSeries(response.data.series);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [filterMonth, filterYear, allTimeFilter]);

  const obj = {
    series: [
      {
        name: 'Vendas',
        data: [
          {
            x: 'Ouro',
            y: 1292,
          },
          {
            x: 'Prata',
            y: 4432,
          },
          {
            x: 'Bronze',
            y: 5423,
          },
        ],
      },
    ],
    options: {
      plotOptions: {
        bar: {
          columnWidth: '60%',
        },
      },
      colors: ['#00E396'],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    },

  };
  return (
    <DashboardCardOverlay loading={loading}>
      <div className="contract-reports-cards position-relative h-100">
        <div className="title-wrapper">
          <div className="">
            <h5 className="title">Contratos por produto</h5>
          </div>
          <div className="d-flex align-items-center">
            <div className="allow-modifications">
              <button type="button" className={allTimeFilter ? 'active' : 'inactive'} onClick={() => setAllTimeFilter((prev) => !prev)}>Todo período</button>
            </div>
            <BasicOption
              id=""
              label=""
              readOnly={allTimeFilter}
              helper=""
              error=""
              value={showMonthAsStringBasedOnNumber(filterMonth)}
              onChange={(el) => setFilterMonth(showMonthAsNumberBasedOnName(el))}
            >
              {showArrayOfMonthsLimitedByStart(filterYear)}
            </BasicOption>
            <BasicOption
              id=""
              label=""
              readOnly={allTimeFilter}
              helper=""
              error=""
              value={filterYear.toString()}
              onChange={(el) => setFilterYear(Number(el))}
            >
              {showAvailableYears()}
            </BasicOption>
          </div>
        </div>
        {data.length === 0 && (
          <NoDataGraphOverlay />
        )}

        {data.length !== 0 && (
          <ReactApexChart
            options={{ ...obj.options, grid: { show: false }, xaxis: { categories: series, type: 'category' } }}
            series={[{ name: 'CONTRATOS', data }]}
            type="bar"
            height={350}
            toolbar={{ show: false }}
          />
        )}

      </div>
    </DashboardCardOverlay>

  );
}
