/* eslint-disable max-len */
import { stat } from 'fs';
import React, { useEffect, useState } from 'react';
import api from '../../../config/axios';
import ClientPJActivities from './PJ/HolderPjActivities';
import DependentsPfActivities from './PF/DependentsPfActivities';
import ClientPFActivities from './PF/HolderPfActivities';
import DependentsPjActivities from './PJ/DependentsPjActivities';

type DataProps = {
    PfTitularesAtivos: string,
    PfTitularesInativos: string,
    PfTitularesBloqueadosTemporariamente: string,
    PfTitularesBloqueadosPermanentemente: string,
    PfTitularesTotalGlobal: string,
    PfDependentesAtivos: string,
    PfDependentesInativos: string,
    PfDependentesTotal: string,
    PfDependentesBloqueadosTemporariamente: string,
    PfDependentesBloqueadosPermanentemente: string,
    PjTitularesAtivos: string,
    PjTitularesInativos: string,
    PjTitularesBloqueadosTemporariamente: string,
    PjTitularesBloqueadosPermanentemente: string,
    PjTitularesTotalGlobal: string,
    PjDependentesAtivos: string,
    PjDependentesInativos: string,
    PjDependentesTotal: string,
    PjDependentesBloqueadosTemporariamente: string,
    PjDependentesBloqueadosPermanentemente: string
}

export default function PFActivities() {
  const [stats, setStats] = useState({
    PfTitularesAtivos: 0,
    PfTitularesInativos: 0,
    PfTitularesBloqueadosTemporariamente: 0,
    PfTitularesBloqueadosPermanentemente: 0,
    PfTitularesTotalGlobal: 0,
    PfDependentesAtivos: 0,
    PfDependentesInativos: 0,
    PfDependentesTotal: 0,
    PfDependentesBloqueadosTemporariamente: 0,
    PfDependentesBloqueadosPermanentemente: 0,
    PjTitularesAtivos: 0,
    PjTitularesInativos: 0,
    PjTitularesBloqueadosTemporariamente: 0,
    PjTitularesBloqueadosPermanentemente: 0,
    PjTitularesTotalGlobal: 0,
    PjDependentesAtivos: 0,
    PjDependentesInativos: 0,
    PjDependentesTotal: 0,
    PjDependentesBloqueadosTemporariamente: 0,
    PjDependentesBloqueadosPermanentemente: 0,
  });
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [loading, setLoading] = useState(false);

  async function fetchContractStats() {
    try {
      setLoading(true);
      const response = await api.get<DataProps>('/admin/dashboard/pf', token);
      const responsePj = await api.get<DataProps>('/admin/dashboard/pj', token);
      setStats({
        PfDependentesAtivos: Number(response.data.PfDependentesAtivos),
        PfDependentesBloqueadosPermanentemente: Number(response.data.PfDependentesBloqueadosPermanentemente),
        PfDependentesBloqueadosTemporariamente: Number(response.data.PfDependentesBloqueadosTemporariamente),
        PfDependentesInativos: Number(response.data.PfDependentesInativos),
        PfDependentesTotal: Number(response.data.PfDependentesTotal),
        PfTitularesAtivos: Number(response.data.PfTitularesAtivos),
        PfTitularesBloqueadosPermanentemente: Number(response.data.PfTitularesBloqueadosPermanentemente),
        PfTitularesBloqueadosTemporariamente: Number(response.data.PfTitularesBloqueadosTemporariamente),
        PfTitularesInativos: Number(response.data.PfTitularesInativos),
        PfTitularesTotalGlobal: Number(response.data.PfTitularesTotalGlobal),
        PjDependentesAtivos: Number(responsePj.data.PjDependentesAtivos),
        PjDependentesBloqueadosPermanentemente: Number(responsePj.data.PjDependentesBloqueadosPermanentemente),
        PjDependentesBloqueadosTemporariamente: Number(responsePj.data.PjDependentesBloqueadosTemporariamente),
        PjDependentesInativos: Number(responsePj.data.PjDependentesInativos),
        PjDependentesTotal: Number(responsePj.data.PjDependentesTotal),
        PjTitularesAtivos: Number(responsePj.data.PjTitularesAtivos),
        PjTitularesBloqueadosPermanentemente: Number(responsePj.data.PjTitularesBloqueadosPermanentemente),
        PjTitularesBloqueadosTemporariamente: Number(responsePj.data.PjTitularesBloqueadosTemporariamente),
        PjTitularesInativos: Number(responsePj.data.PjTitularesInativos),
        PjTitularesTotalGlobal: Number(responsePj.data.PjTitularesTotalGlobal),
      });
      setLoading(false);
    } catch (err: any) {
      console.log('error at fetch contract activities', err.response.data);
    }
  }
  useEffect(() => {
    fetchContractStats();
  }, []);
  return (
    <div className="col-sm-12 col-xl-12 position-relative mt-2">
      <div className="row">
        <div className="col-sm-12 mt-sm-4 col-xl-6 position-relative">
          <ClientPFActivities
            total={stats.PfTitularesTotalGlobal}
            active={stats.PfTitularesAtivos}
            blocked={stats.PfTitularesBloqueadosPermanentemente}
            inactive={stats.PfTitularesInativos}
            tempBlocked={stats.PfTitularesBloqueadosTemporariamente}
            loading={loading}
          />
        </div>
        <div className="col-sm-12 mt-sm-4 col-xl-6 position-relative">
          <DependentsPfActivities
            total={stats.PfDependentesTotal}
            active={stats.PfDependentesAtivos}
            blocked={stats.PfDependentesBloqueadosPermanentemente}
            inactive={stats.PfDependentesInativos}
            tempBlocked={stats.PfDependentesBloqueadosTemporariamente}
            loading={loading}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-12 mt-sm-4 col-xl-6 position-relative">
          <ClientPJActivities
            total={stats.PjTitularesTotalGlobal}
            active={stats.PjTitularesAtivos}
            blocked={stats.PjTitularesBloqueadosPermanentemente}
            inactive={stats.PjTitularesInativos}
            tempBlocked={stats.PjTitularesBloqueadosTemporariamente}
            loading={loading}
          />
        </div>
        <div className="col-sm-12 mt-sm-4 col-xl-6 position-relative">
          <DependentsPjActivities
            total={stats.PjDependentesTotal}
            active={stats.PjDependentesAtivos}
            blocked={stats.PjDependentesBloqueadosPermanentemente}
            inactive={stats.PjDependentesInativos}
            tempBlocked={stats.PjDependentesBloqueadosTemporariamente}
            loading={loading}
          />
        </div>
      </div>
    </div>

  );
}
