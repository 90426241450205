/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import fs from 'fs';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import SwitcherPrimary from '../../../components/SwitcherPrimary';
import api from '../../../config/axios';
import ContractPJProps from '../../../dto/contractPJ';
import { Indicators } from '../../../dto/indicators';
import removeObjectField from '../../../helpers/removeObjectField';
import showCompactName, { showFirstName } from '../../../helpers/showCompactName';
import DefaultLayout from '../../../Layout';
import { FreelancerSellersProps } from '../../../dto/freelancersSellers';
import Tooltip from '../../../components/Tooltip';
import BlockPermaContractPJModal from './BlockContractPermaModal';
import UnblockTempContractPJModal from './UnblockTempContractModal';
import BlockTempContractPJModal from './BlockContractTempModal';
import UnblockClientModal from '../../../components/UnblockPerma';
import ContractModalFilter from './ContractFilterModal';

type ReffersProps = {
  id: number,
  name: string,
  cpf: string,
  paymentDetails: string,
  isActive: boolean
}

type FilterProps = {
  currentPage: number,
  contractid?: number | null,
  sellerid?: number | null,
  refferid?: number | null,
  freelancerid?: number | null,
  indicationid?: number | null,
  blockTemp?: boolean | null,
  blockPerma?: boolean | null,
  initialSignatureDate?: string | null,
  finalSignatureDate?: string | null,
  initialDueDate?: string | null,
  finalDueDate?: string | null,
  minGlobalValue?: number | null,
  maxGlobalValue?: number | null,
  titular?: string | null,
  initialRenewDate?: string | null
  finalRenewDate?: string | null
}

type MetaProps = {
  firstPage: number,
  lastPage: number,
  currentPage: number,
  total: number,
  ativos: number,
  bloqueados: number,
  cancelados: number,
  renovados: number,
}

type DataProps = {
contract_id: number,
seller_id: number,
holder_id: number,
product_id: number,
signature_date: string,
due_date: number,
reffer_id: number,
freelancer_id: null | number,
indication_id: number | number,
contract_global_value: number,
block_temp: null | boolean,
block_perma: null | boolean,
titularid: number,
titular: string,
renewed_date: string | null
}

type Response = {
  meta: MetaProps
  data: DataProps[]
}

export default function ContractsPJ() {
  const navigate = useNavigate();
  /* filter */
  const [contractId, setContractId] = useState<null | number>();
  const [sellerId, setSellerId] = useState<null | number>(null);
  const [refferId, setRefferId] = useState<null | number>(null);
  const [freelancerId, setFreelancerId] = useState<null | number>(null);
  const [indicationId, setIndicationId] = useState<null | number>(null);
  const [blockTemp, setBlockTemp] = useState<boolean | null>(null);
  const [blockPerma, setBlockPerma] = useState<boolean | null>(null);
  const [initialSignatureDate, setInitialSignatureDate] = useState<string | null>(null);
  const [finalSignatureDate, setFinalSignatureDate] = useState<string | null>(null);
  const [initialDueDate, setInitialDueDate] = useState<string | null>(null);
  const [finalDueDate, setFinalDueDate] = useState<string | null>(null);
  const [initialRenewDate, setInitialRenewDate] = useState<string | null>();
  const [finalRenewDate, setFinalRenewDate] = useState<string | null>();
  const [minGlobalValue, setMinGlobalValue] = useState<number | null>(null);
  const [maxGlobalValue, setMaxGlobalValue] = useState<number | null>(null);
  const [titular, setTitular] = useState<string | null>(null);
  const [filters, setFilters] = useState<FilterProps>({
    currentPage: 1,
    contractid: null,
    sellerid: null,
    refferid: null,
    freelancerid: null,
    indicationid: null,
    blockTemp: null,
    blockPerma: null,
    initialSignatureDate: null,
    finalSignatureDate: null,
    initialDueDate: null,
    finalDueDate: null,
    minGlobalValue: null,
    maxGlobalValue: null,
    titular: null,
    initialRenewDate: null,
    finalRenewDate: null,
  });

  /* end filter */
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<null | ModalProps>(null);
  const [contracts, setContracts] = useState<Response>({
    meta: {
      firstPage: 1,
      lastPage: 1,
      currentPage: 1,
      total: 0,
      ativos: 0,
      bloqueados: 0,
      cancelados: 0,
      renovados: 0,
    },
    data: [],
  });
  const [reffers, setReffers] = useState<ReffersProps[]>([]);
  const [freelancers, setFreelancers] = useState<FreelancerSellersProps[]>([]);
  const [indicators, setIndicators] = useState<Indicators[]>([]);
  const [loading, setLoading] = useState(false);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function fetchReffers() {
    try {
      setLoading(true);
      const response = await api.get('/admin/reffer', token);
      setReffers(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      throw new Error('');
    }
  }

  async function fetchIndication() {
    try {
      setLoading(true);
      const response = await api.get('/admin/indication', token);
      setIndicators(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      throw new Error('');
    }
  }

  async function fetchFreelancer() {
    try {
      setLoading(true);
      const response = await api.get('/admin/freelancer', token);
      setFreelancers(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      throw new Error('');
    }
  }

  async function fetchContracts() {
    try {
      setLoading(true);
      const obj = {};
      if (contractId) {
        Object.assign(obj, { contractid: contractId });
      }
      if (sellerId) {
        Object.assign(obj, { sellerid: sellerId });
      }
      if (refferId) {
        Object.assign(obj, { refferid: refferId });
      }
      if (freelancerId) {
        Object.assign(obj, { freelancerid: freelancerId });
      }
      if (indicationId) {
        Object.assign(obj, { indicationid: indicationId });
      }
      if (blockTemp) {
        Object.assign(obj, { blockTemp });
      }
      if (blockPerma) {
        Object.assign(obj, { blockPerma });
      }
      if (initialSignatureDate) {
        Object.assign(obj, { initialSignatureDate });
      }
      if (finalSignatureDate) {
        Object.assign(obj, { finalSignatureDate });
      }
      if (initialDueDate) {
        Object.assign(obj, { initialDueDate });
      }
      if (finalDueDate) {
        Object.assign(obj, { finalDueDate });
      }
      if (minGlobalValue) {
        Object.assign(obj, { minGlobalValue });
      }
      if (maxGlobalValue) {
        Object.assign(obj, { maxGlobalValue });
      }
      if (titular) {
        Object.assign(obj, { titular });
      }
      if (initialRenewDate) {
        Object.assign(obj, { initialRenewDate });
      }
      if (finalRenewDate) {
        Object.assign(obj, { finalRenewDate });
      }
      const response = await api.post(
        '/admin/contract/pj/all-new',
        {
          currentPage: filters.currentPage,
          ...obj,
        },
        token,
      );
      await fetchReffers();
      await fetchIndication();
      await fetchFreelancer();
      setContracts(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro!', children: 'Não foi possível se comunicar com a API. Verifique sua conectividade. Caso persista o problema entre em contato com o suporte.', actions: [{ label: 'Ok, Entendi', type: ModalActionType.PRIMARY, onClick: () => navigate(-1) }] });
    }
  }

  function filterIndicationById(ctxId: number | null) {
    const filteredIndication = indicators.filter((item) => item.id === ctxId);
    if (filteredIndication.length > 0) {
      return showFirstName(filteredIndication[0].name);
    }
    return 'N/A';
  }

  function filterRefferById(ctxId: number | null) {
    const filteredReffer = reffers.filter((item) => item.id === ctxId);
    if (filteredReffer.length > 0) {
      return showFirstName(filteredReffer[0].name);
    }
    return 'N/A';
  }

  function filterFreelancerById(ctxId: number | null) {
    const filteredFreelancer = freelancers.filter((item) => item.id === ctxId);
    if (filteredFreelancer.length > 0) {
      return showFirstName(filteredFreelancer[0].name);
    }
    return 'N/A';
  }

  function openContractInNewTab(contract: DataProps, evt: any) {
    evt.stopPropagation();
    if (evt.ctrlKey || evt.metaKey) {
      window.open(`https://painel.redesanta.com/contratos/pj/editar/${contract.contract_id}`, '_blank');
    } else {
      navigate(`/contratos/pj/editar/${contract.contract_id}`);
    }
  }

  function goToPage(page: number) {
    setFilters({ ...filters, currentPage: page });
  }

  function goToFirstPage() {
    setFilters((prev) => ({ ...prev, currentPage: contracts.meta.firstPage }));
  }

  function generatePagination(totalOfPages: number) {
    const pagination: number[] = [];
    for (let i = 0; i < totalOfPages; i += 1) {
      pagination.push(i + 1);
    }
    return pagination;
  }

  function goToNextPage() {
    if (filters.currentPage < contracts.meta.lastPage) {
      setFilters((prev) => ({ ...prev, currentPage: contracts.meta.currentPage + 1 }));
    }
  }

  function goToLastPage() {
    setFilters((prev) => ({ ...prev, currentPage: contracts.meta.lastPage }));
  }

  useEffect(() => {
    fetchContracts();
  }, [filters]);
  return (
    <DefaultLayout
      pageTitle="Contratos PJ"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products">
        <ContractModalFilter
          filter={(arg) => setFilters(arg)}
          contractId={contractId}
          setContractId={(arg) => setContractId(arg)}
          sellerId={null}
          setSellerId={(arg) => setSellerId(arg)}
          refferId={refferId}
          setRefferId={(arg) => setRefferId(arg)}
          freelancerId={freelancerId}
          setFreelancerId={(arg) => setFreelancerId(arg)}
          indicationId={indicationId}
          setIndicationId={(arg) => setIndicationId(arg)}
          blockTemp={blockTemp}
          setBlockTemp={(arg) => setBlockTemp(arg)}
          blockPerma={blockPerma}
          setBlockPerma={(arg) => setBlockPerma(arg)}
          initialSignatureDate={initialSignatureDate}
          setInitialSignatureDate={(arg) => setInitialSignatureDate(arg)}
          finalSignatureDate={finalSignatureDate}
          setFinalSignatureDate={(arg) => setFinalSignatureDate(arg)}
          initialDueDate={initialDueDate}
          setInitialDueDate={(arg) => setInitialDueDate(arg)}
          finalDueDate={finalDueDate}
          setFinalDueDate={(arg) => setFinalDueDate(arg)}
          minGlobalValue={minGlobalValue}
          setMinGlobalValue={(arg) => setMinGlobalValue(arg)}
          maxGlobalValue={maxGlobalValue}
          setMaxGlobalValue={(arg) => setMaxGlobalValue(arg)}
          titular={titular}
          setTitular={(arg) => setTitular(arg)}
          reffers={reffers}
          indicators={indicators}
          freelancers={freelancers}
          initialRenewDate={initialRenewDate}
          setInitialRenewDate={(arg) => setInitialRenewDate(arg)}
          finalRenewDate={finalRenewDate}
          setFinalRenewDate={(arg) => setFinalRenewDate(arg)}
        />
        <div className="row mb-2">
          <div className="col-12">
            <div className="d-flex justify-content-end mt-4">
              <div className="fetched-values-group">
                <div className="income-fetched-value-wrapper">
                  <p>
                    Ativo(s):
                    {' '}
                    <span className="text-primary">{contracts.meta.ativos}</span>
                  </p>
                </div>
              </div>
              <div className="px-2" />
              <div className="fetched-values-group">
                <div className="income-fetched-value-wrapper">
                  <p>
                    Bloqueado(s):
                    {' '}
                    <span className="text-primary">{contracts.meta.bloqueados}</span>
                  </p>
                </div>
              </div>
              <div className="px-2" />
              <div className="fetched-values-group">
                <div className="income-fetched-value-wrapper">
                  <p>
                    Cancelado(s):
                    {' '}
                    <span className="text-primary">{contracts.meta.cancelados}</span>
                  </p>
                </div>
              </div>
              <div className="px-2" />
              <div className="fetched-values-group">
                <div className="income-fetched-value-wrapper">
                  <p>
                    Renovado(s):
                    {' '}
                    <span className="text-primary">{contracts.meta.renovados}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 p-0 card-bg p-4">
          <h1>Contratos</h1>
          <div className="table-wrapper">
            <div className="table-actions d-flex justify-content-start">
              <button className="table-btn-primary" type="button" onClick={() => navigate('/contratos/pj/adicionar')}>
                <span className="material-icons-round">
                  add
                </span>
                adicionar
              </button>
              <button className="table-btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#contractModalFilter">
                <span className="material-icons-round">
                  search
                </span>
                filtrar
              </button>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Cliente</th>
                  <th scope="col">Dt. Assinatura</th>
                  <th scope="col">Dt. Vencimento</th>
                  <th scope="col">Dt. Renovação</th>
                  <th scope="col">Representante</th>
                  <th scope="col">Embaixador Master</th>
                  <th scope="col">Embaixador</th>
                  <th scope="col">Valor Global</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {contracts.data.length < 1 && <p>Nenhum resultado.</p>}
                {contracts.data.map((item, i) => (
                  <tr>
                    <td>{`PJ000${item.contract_id}`}</td>
                    <td className={item.block_perma ? 'link-red' : (item.block_temp ? 'link-orange' : '')}>{showCompactName(item.titular)}</td>
                    <td>{item.signature_date}</td>
                    <td>{item.due_date}</td>
                    <td>{item.renewed_date}</td>
                    {item.freelancer_id !== null && <td><Link to={`/representantes/editar/${item.freelancer_id}`}>{filterFreelancerById(item.freelancer_id)}</Link></td>}
                    {item.freelancer_id === null && <td>{filterFreelancerById(item.freelancer_id)}</td>}
                    {item.reffer_id !== null && <td><Link to={`/embaixador-master/editar/${item.reffer_id}`}>{filterRefferById(item.reffer_id)}</Link></td>}
                    {item.reffer_id === null && <td>{filterRefferById(item.reffer_id)}</td>}
                    {item.indication_id !== null && <td><Link to={`/embaixador/editar/${item.indication_id}`}>{filterIndicationById(item.indication_id)}</Link></td>}
                    {item.indication_id === null && <td>{filterIndicationById(item.indication_id)}</td>}
                    <td>{`R$ ${item.contract_global_value}`}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        {!item.block_perma && (
                        <>
                          <div />
                          {item.block_temp ? (
                            <>
                              <div className="table-action-icon">
                                <button
                                  type="button"
                                  onClick={() => null}
                                  aria-describedby="manual"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#desbloq-temp-${item.contract_id}`}
                                >
                                  <span className="material-icons-round" style={{ color: 'ORANGE' }}>
                                    lock_reset
                                  </span>
                                </button>
                                <Tooltip id="bloq-temp" text="Essa ação irá desbloquear temporariamente o contrato e seus usuários." />
                              </div>
                              <UnblockTempContractPJModal
                                id={item.contract_id.toString()}
                                setLoading={(arg) => setLoading(arg)}
                              />
                            </>
                          ) : (

                            <>
                              <div className="table-action-icon">
                                <button
                                  type="button"
                                  onClick={() => null}
                                  aria-describedby="manual"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#bloq-temp-${item.contract_id}`}
                                >
                                  <span className="material-icons-round" style={{ color: 'ORANGE' }}>
                                    lock_clock
                                  </span>
                                </button>
                                <Tooltip id="bloq-temp" text="Essa ação irá bloquear temporariamente o contrato e seus usuários." />
                              </div>
                              <BlockTempContractPJModal
                                id={item.contract_id.toString()}
                                setLoading={(arg) => setLoading(arg)}
                              />
                            </>
                          )}
                        </>
                        )}

                        {!item.block_perma && (
                        <>
                          <div className="table-action-icon">
                            <button
                              type="button"
                              onClick={() => null}
                              aria-describedby="manual"
                              id="bloq-perma"
                              data-bs-toggle="modal"
                              data-bs-target={`#bloq-perma-${item.contract_id}`}
                            >
                              <span className="material-icons-round" style={{ color: 'red' }}>
                                cancel
                              </span>
                            </button>
                            <Tooltip id="bloq-perma" text="Essa ação irá cancelar o contrato e seus usuários." />
                          </div>
                          <BlockPermaContractPJModal
                            id={item.contract_id.toString()}
                            setLoading={(arg) => setLoading(arg)}
                          />
                        </>
                        )}
                        {item.block_perma && (
                        <div className="table-action-icon">
                          <button
                            type="button"
                            aria-describedby="manual"
                            id="unblock-perma"
                            data-bs-toggle="modal"
                            data-bs-target={`#unblock-perma-${item.contract_id}`}
                          >
                            <span className="material-icons-round" style={{ color: 'orange' }}>
                              lock_open
                            </span>
                          </button>
                          <Tooltip id="bloq-perma" text="Essa ação irá desbloquear o usuário." />
                          <UnblockClientModal type="contractpj" userId={item.contract_id.toString()} id={`unblock-perma-${item.contract_id}`} />
                        </div>
                        )}
                        <div className="table-action-icon">
                          <button type="button" onClick={(e) => openContractInNewTab(item, e)}>
                            <span className="material-icons-round">
                              more
                            </span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="table-footer">
              <button type="button" onClick={() => goToFirstPage()}>Primeira</button>
              <p>Página</p>
              <select style={{ minWidth: 300 }} value={contracts.meta.currentPage} onChange={(e) => goToPage(Number(e.target.value))}>
                {generatePagination(contracts.meta.lastPage).map((item) => <option value={item}>{item}</option>)}
              </select>
              <p>
                de
                {' '}
                <strong>{contracts.meta.lastPage}</strong>
              </p>
              <button
                type="button"
                onClick={() => goToNextPage()}
              >
                Próxima

              </button>
              <button
                type="button"
                onClick={() => goToLastPage()}
              >
                Última

              </button>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
