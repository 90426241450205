/* eslint-disable no-shadow */
import React, { useState } from 'react';
import TextAreaInput from './TextAreaInput';
import api from '../config/axios';

type FilterProps = {
    type: 'holderpf' | 'holderpj' | 'dependentpf' | 'dependentpj' | 'contractpf' | 'contractpj'
    userId: string,
    id: string
}

enum ActualPage {
    LOADING,
    FORM,
    ERROR,
    SUCCESS,
}

export default function UnblockClientModal({
  type,
  userId,
  id,
}: FilterProps) {
  const [actualPage, setActualPage] = useState(ActualPage.FORM);
  const [reason, setReason] = useState('');
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  async function unblockHolderPj() {
    try {
      setActualPage(ActualPage.LOADING);
      await api.post('/admin/holder-perma-unblock', {
        holderId: userId,
        reason,
      }, token);
      setActualPage(ActualPage.SUCCESS);
    } catch (err:any) {
      console.log(err.response.data);
      setActualPage(ActualPage.ERROR);
    }
  }
  async function unblockContractPf() {
    try {
      setActualPage(ActualPage.LOADING);
      await api.post('/admin/contracts/pf/unblock-perma', {
        contractPfId: userId,
        reason,
      }, token);
      setActualPage(ActualPage.SUCCESS);
    } catch (err: any) {
      console.log(err.response.data);
      setActualPage(ActualPage.ERROR);
    }
  }

  async function unblockContractPj() {
    try {
      setActualPage(ActualPage.LOADING);
      await api.post('/admin/contracts/pj/unblock-perma', {
        contractPjId: userId,
        reason,
      }, token);
      setActualPage(ActualPage.SUCCESS);
    } catch (err: any) {
      console.log(err.response.data);
      setActualPage(ActualPage.ERROR);
    }
  }

  async function unblockDependentPf() {
    try {
      setActualPage(ActualPage.LOADING);
      await api.post('/admin/dependent/unblock/perma', {
        dependentUuid: userId,
        reason,
      }, token);
      setActualPage(ActualPage.SUCCESS);
    } catch (err:any) {
      console.log(err.response.data);
      setActualPage(ActualPage.ERROR);
    }
  }

  async function unblockDependentPj() {
    try {
      setActualPage(ActualPage.LOADING);
      await api.post('/admin/dependent/unblock-pj/perma', {
        dependentUuid: userId,
        reason,
      }, token);
      setActualPage(ActualPage.SUCCESS);
    } catch (err:any) {
      console.log(err.response.data);
      setActualPage(ActualPage.ERROR);
    }
  }

  function unblock() {
    if (type === 'holderpf') {
      unblockContractPf();
    }
    if (type === 'holderpj') {
      unblockHolderPj();
    }
    if (type === 'dependentpf') {
      unblockDependentPf();
    }
    if (type === 'dependentpj') {
      unblockDependentPj();
    }
    if (type === 'contractpj') {
      unblockContractPj();
    }
    if (type === 'contractpf') {
      unblockContractPf();
    }
  }

  function onClose() {
    window.location.reload();
  }
  return (
    <div className="modal fade" id={id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Desbloqueio</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => onClose()} />
          </div>
          <div className="modal-body">
            {actualPage === ActualPage.LOADING && (
            <div className="d-flex align-items-center justify-content-center w-100" style={{ minHeight: 200 }}>
              <p className="text-center fw-bold">Processando, aguarde.</p>
            </div>
            )}
            {actualPage === ActualPage.ERROR && (
            <div className="d-flex align-items-center justify-content-center w-100" style={{ minHeight: 200 }}>
              <p className="text-center fw-bold">Ocorreu um erro ao desbloquear.</p>
            </div>
            )}
            {actualPage === ActualPage.SUCCESS && (
            <div className="d-flex align-items-center justify-content-center w-100" style={{ minHeight: 200 }}>
              <p className="text-center fw-bold">Desbloqueio realizado com sucesso.</p>
            </div>
            )}
            {actualPage === ActualPage.FORM && (
            <TextAreaInput
              id=""
              label="Motivo do desbloqueio"
              readOnly={false}
              helper="O porque esse usuário está sendo desbloqueado"
              error=""
              value={reason}
              onChange={(data) => setReason(data)}
            />
            )}

          </div>
          <div className="modal-footer">
            {actualPage === ActualPage.FORM && (
            <button type="button" className="btn btn-danger" onClick={() => unblock()} style={{ background: '#039EF7' }}>desbloquear</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
