import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalProps } from '../../components/Modal';
import SwitcherPrimary from '../../components/SwitcherPrimary';
import api from '../../config/axios';
import { ProductsProps } from '../../dto/product';
import showApiErrors from '../../helpers/showApiErrors';
import DefaultLayout from '../../Layout';
import changeModalProductVisibility from './changeProductVisibility';

export default function Product() {
  const navigate = useNavigate();
  const [products, setProducts] = useState<ProductsProps[]>([]);
  const [token] = useState({
    headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` },
  });
  const [loading, setLoading] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [modal, setModal] = useState<null | ModalProps>(null);
  const [showActive, setShowActive] = useState(
    JSON.parse(localStorage.getItem('showActive_products') || 'true'),
  );
  const [showInactive, setShowInactive] = useState(
    JSON.parse(localStorage.getItem('showInactive_products') || 'false'),
  );

  async function fetchProducts() {
    try {
      setLoading(true);
      const response = await api.get('product/get-product-list/', token);
      setProducts(response.data);
      setLoading(false);
    } catch (err: any) {
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 3000);
      console.error(err.response);
    }
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  async function setVisibility(product: ProductsProps) {
    try {
      setLoading(true);
      await api.put(
        `/admin/product/${product.id}`,
        {
          name: product.name,
          benefits: product.benefits,
          pricePerHolder: product.pricePerHolder,
          pricePerDependent: product.pricePerDependent,
          isActive: !product.isActive,
        },
        token,
      );
      fetchProducts();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      if (typeof err.response !== 'undefined') {
        if (err.response.status === 422) {
          setModal(showApiErrors(setModal, err.response.data.errors));
        }
      } else {
        setShowToastError(true);
        setTimeout(() => {
          setShowToastError(false);
        }, 3000);
      }
      console.error(err.response);
      setLoading(false);
    }
  }

  useEffect(() => {
    localStorage.setItem('showActive_products', JSON.stringify(showActive));
    localStorage.setItem('showInactive_products', JSON.stringify(showInactive));
  }, [showActive, showInactive]);

  const filteredProducts = products.filter((product) => {
    if (showActive && showInactive) return true;
    if (showActive) return product.isActive;
    if (showInactive) return !product.isActive;
    return false;
  });

  if (products.length < 1) {
    return (
      <DefaultLayout
        pageTitle="Produtos"
        loading={loading}
        showToastSuccess={showToastSuccess}
        showToastError={showToastError}
        showRightSlider={false}
        rightSliderContent={undefined}
        modal={modal}
        setCloseRightSlider={() => null}
      >
        <div className="not-exsits">
          <span className="material-icons-round">not_interested</span>
          <h3 className="table-not-exists">Não existe(m) produto(s) cadastrados.</h3>
          <ButtonTextIcon
            label="Adicionar produto"
            type={BtnType.PRIMARY}
            icon="add"
            active
            onClick={() => navigate('/produtos/adicionar')}
            ref={null}
          />
        </div>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout
      pageTitle="Produtos"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Produtos</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <button
                className="table-btn-primary"
                type="button"
                onClick={() => navigate('/produtos/adicionar')}
              >
                <span className="material-icons-round">add</span>
                adicionar
              </button>
              <div className="d-flex align-items-center">
                <div className="mx-2 separ" />
                <div className="form-check lh-lg">
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    id="showInactive"
                    checked={showInactive}
                    onChange={() => setShowInactive(!showInactive)}
                  />
                  <label
                    className="form-check-label text-secondary mb-0 fs-7"
                    htmlFor="showInactive"
                  >
                    Mostrar Inativos
                  </label>
                </div>
              </div>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">Preço Titular</th>
                  <th scope="col">Preço Dependente</th>
                  <th className="text-end" scope="col">
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts.map((item, i) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{`R$ ${item.pricePerHolder.toFixed(2)}`}</td>
                    <td>{`R$ ${item.pricePerDependent.toFixed(2)}`}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <SwitcherPrimary
                          id={`status-changer-${i}`}
                          onChange={() => setModal(
                            changeModalProductVisibility(setModal, () => setVisibility(item)),
                          )}
                          isActive={item.isActive}
                        />
                        <div className="table-action-icon">
                          <button
                            type="button"
                            onClick={() => navigate(`/produtos/editar/${item.id}`)}
                          >
                            <span className="material-icons-round">more</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
