/* eslint-disable max-len */
import { isValid } from '@fnando/cnpj';
import React, { useEffect, useState } from 'react';
import * as EmailValidator from 'email-validator';
import AddInput from '../../../components/AddInput';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import { statesOfBrazil } from '../../../dto/states';
import CEPFinder from '../../../helpers/findAddressByCep';
import {
  maskCep, maskCNPJ, maskDate, maskPhone, unmaskData,
} from '../../../helpers/inputMasks.js';
import fetchCNPJ from '../../ClientPJ/fetchCNPJ';
import { ActualPage, ClientDTO } from './CreateLead';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import ErrorModal from '../ErrorModal';

type PageProps = {
  client: ClientDTO,
  setClient: React.Dispatch<React.SetStateAction<ClientDTO>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setActualPage: React.Dispatch<React.SetStateAction<ActualPage>>
}

export default function CreateClientPJ({
  client, setClient, setLoading, setActualPage,
}: PageProps) {
  const [errors, setErrors] = useState<string[]>([]);
  const [errorsRef] = useState(React.createRef<any>());
  async function findCNPJ() {
    setLoading(true);
    const response = await fetchCNPJ(unmaskData(client.cnpj));
    if (response !== null) {
      setClient({ ...client, ...response });
    }
    setLoading(false);
  }

  async function changeAddressByCep() {
    try {
      setLoading(true);
      const findCep = await new CEPFinder(unmaskData(client.addressZipcode)).findCep();
      if (findCep !== null) {
        setClient({
          ...client,
          addressCity: findCep.city,
          addressState: findCep.state,
          addressNeighborhood: findCep.neighborhood,
          addressStreet: findCep.street,
          addressIbge: findCep.ibge,
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  function validate() {
    const errorsCtx: string[] = [];
    if (!isValid(client.cnpj)) {
      errorsCtx.push('CNPJ inválido');
    }
    if (client.name.length < 1) {
      errorsCtx.push('Digite um nome');
    }
    if (client.addressState.length < 1) {
      errorsCtx.push('Digite um logradouro');
    }
    if (client.addressNumber.length < 1) {
      errorsCtx.push('Digite um número residencial');
    }
    if (client.addressNeighborhood.length < 1) {
      errorsCtx.push('Digite um bairro');
    }
    if (client.addressCity.length < 1) {
      errorsCtx.push('Digite uma cidade');
    }
    if (client.addressZipcode.length < 1) {
      errorsCtx.push('Digite um cep');
    }
    if (client.email.length < 1) {
      errorsCtx.push('Digite um email');
    }
    if (client.phone.length < 1) {
      errorsCtx.push('Digite um telefone');
    }
    if (errorsCtx.length > 0) {
      setErrors(errorsCtx);
    } else {
      setActualPage(ActualPage.HOLDER);
    }
  }

  useEffect(() => {
    if (errors.length > 0) {
      errorsRef.current.click();
    }
  }, [errors]);

  return (
    <div className="row client card-bg p-0">
      <section className="row">
        <h1>Cadastro Cliente PJ</h1>
        <div className="col-4">
          <AddInput
            id="cnpj-input"
            label="CNPJ"
            readOnly={false}
            helper="Ex: 33.000.167/0001-01"
            error={!isValid(client.cnpj) ? 'Digite um CNPJ válido' : ''}
            value={client.cnpj}
            onChange={(data) => setClient({ ...client, cnpj: maskCNPJ(data) })}
            onAdd={() => findCNPJ()}
            type="text"
          />
        </div>
        <div className="col-4">
          <BasicInput
            id="name-fantasy-input"
            label="Nome Fantasia"
            readOnly={false}
            helper="Ex: Petrobrás"
            error={client.nameFantasy.length < 1 ? 'Campo obrigatório' : ''}
            value={client.nameFantasy}
            type=""
            onChange={(data) => setClient({ ...client, nameFantasy: data })}
          />
        </div>
        <div className="col-4">
          <BasicInput
            id="name-input"
            label="Razão social"
            readOnly={false}
            helper="Petroleo brasileiro SA"
            error={client.name.length < 1 ? 'Campo obrigatório' : ''}
            value={client.name}
            type=""
            onChange={(data) => setClient({ ...client, name: data })}
          />
        </div>
        <div className="col-4">
          <BasicInput
            id="opening-input"
            label="Data de abertura"
            readOnly={false}
            helper="Ex: 31/01/1990"
            error={client.openingDate.length < 10 ? 'Digite uma data válida' : ''}
            value={client.openingDate}
            type=""
            onChange={(data) => setClient({ ...client, openingDate: maskDate(data) })}
          />
        </div>
        <div className="col-4">
          <BasicOption
            id="tipo-input"
            label="Tipo"
            readOnly={false}
            helper="Ex: ME"
            error=""
            value={client.size}
            onChange={(data) => setClient({ ...client, size: data })}
          >
            {[
              'ME',
              'MEI',
              'EPP',
              'OUTRO',
            ]}
          </BasicOption>
        </div>
        <div className="col-4">
          <AddInput
            id="address-cep-input"
            label="CEP"
            readOnly={false}
            helper="Ex: 35.171-120"
            error={client.addressZipcode.length < 10 ? 'Campo obrigatório' : ''}
            value={client.addressZipcode}
            type="text"
            onAdd={() => changeAddressByCep()}
            onChange={(data) => setClient({ ...client, addressZipcode: maskCep(data) })}
          />
        </div>
        <div className="col-4">
          <BasicInput
            id="address-street-input"
            label="LOGRADOURO"
            readOnly={false}
            helper="Ex: Rua Três"
            error={client.addressStreet.length < 2 ? 'Campo Obrigatório' : ''}
            value={client.addressStreet}
            type="text"
            onChange={(data) => setClient({ ...client, addressStreet: data })}
          />
        </div>
        <div className="col-4">
          <BasicInput
            id="address-number-input"
            label="Número"
            readOnly={false}
            helper="Ex: 133"
            error={client.addressNumber.length < 1 ? 'Campo Obrigatório' : ''}
            value={client.addressNumber}
            type="text"
            onChange={(data) => setClient({ ...client, addressNumber: data })}
          />
        </div>
        <div className="col-4">
          <BasicInput
            id="address-complement-input"
            label="Complemento"
            readOnly={false}
            helper="Ex: Bloco A"
            error=""
            value={client.addressComplement}
            type="text"
            onChange={(data) => setClient({ ...client, addressComplement: data })}
          />
        </div>
        <div className="col-4">
          <BasicInput
            id="address-neighborhood-input"
            label="Bairro"
            readOnly={false}
            helper="Centro"
            error={client.addressNeighborhood.length < 1 ? 'Campo obrigatório' : ''}
            value={client.addressNeighborhood}
            type="text"
            onChange={(data) => setClient({ ...client, addressNeighborhood: data })}
          />
        </div>
        <div className="col-4">
          <BasicInput
            id="address-city-input"
            label="Cidade"
            readOnly
            helper="Ex: Imperatriz"
            error={client.addressCity.length < 1 ? 'Campo obrigatório' : ''}
            value={client.addressCity}
            type="text"
            onChange={(data) => setClient({ ...client, addressCity: data })}
          />
        </div>
        <div className="col-4">
          <BasicOption
            id="address-state-input"
            label="Estado"
            readOnly={false}
            helper=""
            error=""
            value={client.addressState}
            onChange={(data) => setClient({ ...client, addressState: data })}
          >
            {statesOfBrazil}
          </BasicOption>
        </div>
        <div className="col-4">
          <BasicInput
            id="phone-input"
            label="Telefone"
            readOnly={false}
            helper="Ex: (31) 99999 - 9999"
            error={client.phone.length < 1 ? 'Campo obrigatório' : ''}
            value={client.phone}
            type="text"
            onChange={(data) => setClient({ ...client, phone: maskPhone(data) })}
          />
        </div>
        <div className="col-4">
          <BasicInput
            id="email-input"
            label="E-mail"
            readOnly={false}
            helper="email@email.com"
            error={!EmailValidator.validate(client.email) ? 'Digite um email válido' : ''}
            value={client.email}
            type="email"
            onChange={(data) => setClient({ ...client, email: data })}
          />
        </div>
        <div className="col-12 footer">
          <ButtonTextIcon
            label="ir para associados"
            type={BtnType.PRIMARY}
            icon="backup"
            active
            onClick={() => validate()}
            ref={null}
          />
        </div>
      </section>
      <ErrorModal errors={errors} />
      <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#errormodal" ref={errorsRef}>
        Launch static backdrop modal
      </button>
    </div>
  );
}
