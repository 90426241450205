/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import PriceInput from '../../../components/PriceInput';
import api from '../../../config/axios';
import { showFutureDateBasedOnMonths, showTodayDate } from '../../../helpers/dates';
import { maskDate } from '../../../helpers/inputMasks.js';
import showCardPaymentWays from '../../../helpers/showCardMethods';
import showNonCardPaymentWays from '../../../helpers/showNonCardMethods';
import { PaymentGateway } from '../../PaymentTax/types';
import ErrorModal from '../ErrorModal';
import {
  ActualPage, ContractDTO, InstallmentDTO, PaymentDTO,
} from './CreateLead';

type PaymentPFLeadProps = {
    payment: PaymentDTO
    setPayment: (arg0: PaymentDTO) => void
    pInstallments: InstallmentDTO[]
    setInstallments: (arg0: InstallmentDTO[]) => void
    contractProps: ContractDTO
    haveInstallments: boolean
    setHaveInstallments: (arg: boolean) => void
    sendLead: () => Promise<void>
    setActualPage: (arg: ActualPage) => void
    setModal: (arg: ModalProps | null) => void
}

export default function NewPaymentPFLead({
  payment,
  setPayment,
  pInstallments,
  setInstallments,
  contractProps,
  sendLead,
  setActualPage,
  setModal,
  haveInstallments,
  setHaveInstallments,
}: PaymentPFLeadProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [installments, setUpdateInstallments] = useState<InstallmentDTO[]>(pInstallments);
  const [paymentGateways, setPaymentGateways] = useState<PaymentGateway[]>([]);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [errors, setErrors] = useState<string[]>([]);
  const [errorModalRef] = useState(React.createRef<any>());

  function showFlag(flag: string) {
    if (flag === 'OTHER') {
      return 'OUTRA';
    }
    return flag;
  }

  function calculateInstallmentsAndSetState(e: string) {
    if (e === 'Único') {
      setPayment({ ...payment, installments: 1 });
    } else {
      setPayment({ ...payment, installments: Number(e) });
    }
  }

  function calculateDiscount() {
    const calc = (payment.contractValue * payment.discount) / 100;
    return payment.contractValue - calc;
  }

  function calculateAditional() {
    const calc = (payment.contractValue * payment.aditional) / 100;
    return calc;
  }

  function calculateFinalValue() {
    const discountResult = calculateDiscount();
    const aditionalResult = calculateAditional();
    return discountResult + aditionalResult;
  }

  function showFirstNonCardMethod() {
    if (paymentGateways[0].taxes[0].boleto !== null) {
      return 'BOLETO';
    }
    if (paymentGateways[0].taxes[0].dinheiro !== null) {
      return 'DINHEIRO';
    }
    if (paymentGateways[0].taxes[0].doc !== null) {
      return 'DOC';
    }
    if (paymentGateways[0].taxes[0].pix !== null) {
      return 'PIX';
    }
    if (paymentGateways[0].taxes[0].ted !== null) {
      return 'TED';
    }
    return 'OUTRO';
  }

  function generateStallments() {
    const installmentList: InstallmentDTO[] = [];
    if (payment.installments === 1) {
      installmentList.push({
        dueDate: showTodayDate(),
        status: 'UNPAID',
        value: Number((payment.totalValue / payment.installments).toFixed(2)),
        paymentGatewayId: paymentGateways[0].id,
        nonCardTaxes: showFirstNonCardMethod(),
        cardLastDigits: '',
        cardPaymentOption: '',
        card: false,
        flag: '',
        isMain: false,
        paymentDate: '',
        taxUsed: 0,
        pagvipRef: null,
        isSubscription: false,
        subscriptionNumber: null,
        subscriptionRef: null,

      });
    } else {
      for (let i = 0; i < payment.installments; i++) {
        installmentList.push({
          dueDate: showFutureDateBasedOnMonths(i),
          status: 'UNPAID',
          value: Number((payment.totalValue / payment.installments).toFixed(2)),
          paymentGatewayId: paymentGateways[0].id,
          nonCardTaxes: showFirstNonCardMethod().toString(),
          cardLastDigits: '',
          cardPaymentOption: '',
          card: false,
          isMain: false,
          flag: '',
          paymentDate: '',
          taxUsed: 0,
          pagvipRef: null,
          isSubscription: false,
          subscriptionNumber: null,
          subscriptionRef: null,
        });
      }
    }

    setInstallments(installmentList);
  }

  function changeDueDate(idx: number, date: string) {
    installments[idx].dueDate = maskDate(date);
    setForceRefresh((prev) => !prev);
  }

  function changePaymentValue(idx: number, installmentValue: number) {
    installments[idx].value = installmentValue;
    const previousFullfiledValueAsArray = installments.filter((item, i) => i <= idx).map((item) => item.value);
    let previousFullFiledValue = 0;
    if (previousFullfiledValueAsArray.length > 0) {
      previousFullFiledValue = previousFullfiledValueAsArray.reduce((previous, acc) => previous + acc);
    }
    const remainingValue = (calculateFinalValue() - previousFullFiledValue);
    for (let i = idx + 1; i < installments.length; i += 1) {
      installments[i].value = Number((remainingValue / (installments.length - (idx + 1))).toFixed(2));
    }
    setForceRefresh((prev) => !prev);
  }

  function showGatewayName(id: number) {
    const filteredGateway = paymentGateways.filter((item) => item.id === id);
    return filteredGateway[0].name;
  }

  function changePaymentGateway(idx: number, gateway: string) {
    const filteredGateway = paymentGateways.filter((item) => item.name === gateway);

    const cardPaymentOptions = showCardPaymentWays(filteredGateway[0].card);
    const nonCardPaymentOptions = showNonCardPaymentWays(filteredGateway[0]);

    if (nonCardPaymentOptions.length > 0) {
      for (let i = idx; i < installments.length; i += 1) {
        installments[i].paymentGatewayId = filteredGateway[0].id;
        installments[i].card = false;
        installments[i].cardLastDigits = '';
        installments[i].flag = '';
        installments[i].cardPaymentOption = '';
        installments[i].nonCardTaxes = nonCardPaymentOptions[0];
      }
      setForceRefresh((prev) => !prev);
    } else if (cardPaymentOptions.length > 0) {
      for (let i = idx; i < installments.length; i += 1) {
        installments[i].paymentGatewayId = filteredGateway[0].id;
        installments[i].card = true;
        installments[i].cardLastDigits = '';
        installments[i].flag = filteredGateway[0].card[0].flag;
        installments[i].cardPaymentOption = cardPaymentOptions[0];
        installments[i].nonCardTaxes = '';
      }
      setForceRefresh((prev) => !prev);
    } else {
      setModal({ title: 'Ocorreu um erro', children: 'O método selecionado não possui formas de pagamento disponíveis', actions: [{ label: 'Ok, entendi', type: ModalActionType.DANGER, onClick: () => setModal(null) }] });
    }
  }

  function showAvailablePaymentOption(idCtx: number) {
    const result: string[] = [];
    const filteredMethod = paymentGateways.filter((item) => item.id === idCtx);
    if (filteredMethod.length > 0) {
      if (filteredMethod[0].card.length > 0) {
        result.push('CARTÃO');
      }
      if (filteredMethod[0].taxes.length > 0) {
        result.push('OUTRO');
      }
    }
    return result;
  }

  function showCardFlag(installment: InstallmentDTO) {
    const filteredGateway = paymentGateways.filter((item) => item.id === installment.paymentGatewayId);

    return filteredGateway[0].card.map((item) => {
      if (item.flag === 'OTHER') {
        return 'OUTRA';
      }
      return item.flag;
    });
  }

  function showCardPaymentMethods(installment: InstallmentDTO) {
    const filteredGateway = paymentGateways.filter((item) => item.id === installment.paymentGatewayId);
    const filteredCardsByFlag = filteredGateway[0].card.filter((card) => {
      if (installment.flag === 'OUTRA') {
        return card.flag === 'OTHER';
      }
      return card.flag === installment.flag;
    });
    return showCardPaymentWays(filteredCardsByFlag);
  }

  function setCardFlag(idx: number, flag: string) {
    for (let i = idx; i < installments.length; i += 1) {
      installments[i].flag = flag;
      installments[i].cardPaymentOption = showCardPaymentMethods(installments[i])[0];
    }
    setForceRefresh((prev) => !prev);
  }

  function changePaymentOption(idx: number, method: string) {
    if (method === 'CARTÃO') {
      for (let i = idx; i < installments.length; i += 1) {
        installments[i].card = true;
        installments[i].nonCardTaxes = '';
        installments[i].flag = showCardFlag(installments[i])[0];
        installments[i].cardPaymentOption = showCardPaymentMethods(installments[i])[0];
      }
      setForceRefresh((prev) => !prev);
    } else {
      for (let i = idx; i < installments.length; i += 1) {
        const filteredGateway = paymentGateways.filter((item) => item.name === item.gateway);
        const nonCardPaymentOptions = showNonCardPaymentWays(filteredGateway[0]);
        installments[i].card = false;
        installments[i].flag = null;
        installments[i].cardPaymentOption = '';
        installments[i].cardLastDigits = '';
        installments[i].nonCardTaxes = nonCardPaymentOptions[0];
      }
      setForceRefresh((prev) => !prev);
    }
  }

  function showNonCardPaymentMethods(installment: InstallmentDTO) {
    const result: string[] = [];
    paymentGateways.filter((item) => item.id === installment.paymentGatewayId).forEach((item) => {
      item.taxes.forEach((taxes) => {
        if (taxes.boleto !== null) {
          result.push('BOLETO');
        }
        if (taxes.dinheiro !== null) {
          result.push('DINHEIRO');
        }
        if (taxes.doc !== null) {
          result.push('DOC');
        }
        if (taxes.pix !== null) {
          result.push('PIX');
        }
        if (taxes.ted !== null) {
          result.push('TED');
        }
      });
    });
    return result;
  }

  function setPaymentWay(idx: number, way: string) {
    for (let i = idx; i < installments.length; i += 1) {
      installments[i].nonCardTaxes = way;
    }
    setForceRefresh((prev) => !prev);
  }

  function changeCardPaymentOption(idx: number, option: string) {
    for (let i = idx; i < installments.length; i += 1) {
      installments[i].cardPaymentOption = option;
    }
    setForceRefresh((prev) => !prev);
  }

  function changeCardLastDigits(idx: number, digits: string) {
    for (let i = idx; i < installments.length; i += 1) {
      installments[i].cardLastDigits = digits;
    }
    setForceRefresh((prev) => !prev);
  }

  function validateInstallmentValues() {
    const allValues = installments.map((item) => item.value);
    let sumOfAllValues = 0;
    if (allValues.length > 0) {
      sumOfAllValues = allValues.reduce((prev, acc) => prev + acc);
    }

    if (Math.round(Number(sumOfAllValues.toFixed(1))) > Math.round(Number(calculateFinalValue().toFixed(1)))) {
      setErrors(['A soma do total das faturas ultrapassou o valor total do pagamento (contrato)']);
    } else if (Math.round((Number(sumOfAllValues.toFixed(1)))) < Math.round(Number(calculateFinalValue().toFixed(1)))) {
      setErrors(['A soma do total das faturas é menor que o valor total do pagamento (contrato)']);
    } else {
      setErrors([]);
      sendLead();
    }
  }

  async function fetchAllTax() {
    try {
      setLoading(true);
      const response = await api.get('/admin/configuration/payment-gateway', token);
      setPaymentGateways(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function changeIsMainInstallment(installment: InstallmentDTO) {
    const installmentsCtx = [...installments];
    const newInstallments: InstallmentDTO[] = [];
    installmentsCtx.forEach((item) => {
      if (item !== installment) {
        if (item.isMain) {
          newInstallments.push({ ...item, isMain: false });
        } else {
          newInstallments.push({ ...item });
        }
      } else {
        newInstallments.push({ ...installment, isMain: !installment.isMain });
      }
    });
    setInstallments(newInstallments);
  }

  useEffect(() => {
    fetchAllTax();
  }, []);

  useEffect(() => {
    setPayment({
      ...payment, approvalDate: contractProps.signatureDate, contractValue: contractProps.value, totalValue: contractProps.value,
    });
  }, []);

  useEffect(() => {
    setUpdateInstallments(pInstallments);
  }, [pInstallments]);

  useEffect(() => {
    if (errors.length > 0) {
      errorModalRef.current.click();
    }
  }, [errors]);

  return (
    <>
      <div className="row products mt-4">
        <div className="col-12 card-bg p-4">
          <div className="row">
            <h1>Forma de pagamento</h1>
            <div className="col-4">
              <PriceInput
                id="valor-contrato"
                label="Valor do contrato"
                readOnly
                helper="Ex: 500"
                error={payment.contractValue === 0 ? 'Digite um valor' : ''}
                value={payment.contractValue.toString()}
                type="number"
                onChange={(data) => setPayment({ ...payment, contractValue: Number(data) })}
              />
            </div>
            <div className="col-4">
              <PriceInput
                id="valor-final"
                label="Valor final"
                readOnly
                helper="Ex: 600"
                error={payment.totalValue === 0 ? 'Digite um valor' : ''}
                value={calculateFinalValue().toString()}
                type="number"
                onChange={(data) => null}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="data-aprovacao"
                label="Data de aprovação"
                readOnly={false}
                helper="Ex: 30-04-2022"
                error={payment.approvalDate.length < '30-04-2009'.length ? 'Digite uma data válida' : ''}
                value={payment.approvalDate.toString()}
                type="text"
                onChange={(data) => setPayment({ ...payment, approvalDate: maskDate(data) })}
              />
            </div>
            <div className="col-4">
              <BasicOption
                id="cadastro de parcelas"
                label="Cadastrar parcelas?"
                readOnly={false}
                helper="Ex: 12"
                error=""
                value={haveInstallments ? 'Sim' : 'Não'}
                onChange={(data) => {
                  if (data === 'Sim') {
                    setHaveInstallments(true);
                  } else {
                    setHaveInstallments(false);
                    setInstallments([]);
                  }
                }}
              >
                {['Sim', 'Não']}
              </BasicOption>
            </div>
            {haveInstallments && (
            <div className="col-4">
              <BasicOption
                id="número-de-parcelas"
                label="Parcelas"
                readOnly={false}
                helper="Ex: 12"
                error=""
                value={payment.installments.toString() === '1' ? 'Único' : payment.installments.toString()}
                onChange={(data) => calculateInstallmentsAndSetState(data)}
              >
                {['Único', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']}
              </BasicOption>
            </div>
            )}
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Voltar para contrato"
                type={BtnType.PRIMARY}
                icon="arrow_left"
                active
                onClick={() => setModal({
                  title: 'Tem certeza?',
                  children: ['Caso deseja retroceder, será necessário preencher novamente as parcelas para que seja atualizado o cálculo.'],
                  actions: [{ label: 'Cancelar', onClick: () => setModal(null), type: ModalActionType.PRIMARY },
                    {
                      label: 'Ok, retroceder',
                      onClick: () => {
                        setInstallments([]);
                        setActualPage(ActualPage.CONTRACT);
                        setModal(null);
                      },
                      type: ModalActionType.DANGER,
                    }],
                })}
                ref={null}
              />
              {!haveInstallments && (
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                active
                onClick={() => sendLead()}
                ref={null}
              />
              )}
              {haveInstallments && (
              <ButtonTextIcon
                label="Calcular Faturas"
                type={BtnType.PRIMARY}
                icon="calculate"
                active
                onClick={() => generateStallments()}
                ref={null}
              />
              )}
            </div>
          </div>
        </div>
      </div>

      {paymentGateways.length > 0 && installments.map((item, i) => (
        <div className="row products">
          <div className="col-12 card-bg p-4">
            <div className="row">
              <h1>{`Parcela - ${i + 1}`}</h1>
              <div className="col-12 d-flex mb-4">
                <input type="checkbox" onChange={() => changeIsMainInstallment(item)} checked={item.isMain} />
                <div className="px-2" />
                <p>Definir esta parcela como entrada</p>
              </div>
              <div className="col-4">
                <BasicInput
                  id="data-vencimento"
                  label="Data de vencimento"
                  readOnly={false}
                  helper="Ex: 30-04-2022"
                  error={item.dueDate.length < '00-00-0000'.length ? 'Digite uma data válida' : ''}
                  value={item.dueDate}
                  type="text"
                  onChange={(data) => changeDueDate(i, data)}
                />
              </div>
              <div className="col-4">
                <PriceInput
                  id={`valor-parcela-${i + 1}`}
                  label="Valor Bruto"
                  readOnly={false}
                  helper="Ex: 600"
                  error={item.value === 0 ? 'Digite um valor' : ''}
                  value={item.value.toString()}
                  type="number"
                  onChange={(data) => changePaymentValue(i, Number(data))}
                />
              </div>
              <div className="col-4">
                <BasicOption
                  id={`gateway-de-pagamento-${i + 1}`}
                  label="Gateway de pagamento"
                  readOnly={false}
                  helper="Ex: PagAzul Web"
                  error=""
                  value={showGatewayName(item.paymentGatewayId)}
                  onChange={(data) => changePaymentGateway(i, data)}
                >
                  {paymentGateways.map((gateway) => gateway.name)}
                </BasicOption>
              </div>
              <div className="col-4">
                <BasicOption
                  id={`opcoes-de-pagamento${i + 1}`}
                  label="Opções de pagamento"
                  readOnly={false}
                  helper="Ex: Cartão"
                  error=""
                  value={item.card ? 'CARTÃO' : 'OUTRO'}
                  onChange={(data) => changePaymentOption(i, data)}
                >
                  {showAvailablePaymentOption(item.paymentGatewayId).map((method) => method)}
                </BasicOption>
              </div>

              {item.card && (
                <div className="col-4">
                  <BasicOption
                    id={`flag-${i + 1}`}
                    label="Bandeira"
                    readOnly={false}
                    helper="Ex: VISA"
                    error=""
                    value={item.flag !== null ? showFlag(item.flag) : ''}
                    onChange={(data) => setCardFlag(i, data)}
                  >
                    {showCardFlag(item)}
                  </BasicOption>
                </div>
              )}

              {item.card && (
              <div className="col-4">
                <BasicOption
                  id={`card-way-${i + 1}`}
                  label="Forma de pagamento"
                  readOnly={false}
                  helper="Ex: Crédito 12x"
                  error=""
                  value={item.cardPaymentOption !== null ? item.cardPaymentOption : ''}
                  onChange={(data) => changeCardPaymentOption(i, data)}
                >
                  {showCardPaymentMethods(item)}
                </BasicOption>
              </div>
              )}

              {item.card && (
              <div className="col-4">
                <BasicInput
                  id={`card-last-digits-${i + 1}`}
                  label="Ultimos 04 dígitos"
                  readOnly={false}
                  helper="Ex: 2144"
                  value={item.cardLastDigits ? item.cardLastDigits.toString() : ''}
                  onChange={(data) => changeCardLastDigits(i, data)}
                  type="number"
                  error=""
                />
              </div>
              )}

              {!item.card && (
              <div className="col-4">
                <BasicOption
                  id={`way-${i + 1}`}
                  label="Forma de pagamento"
                  readOnly={false}
                  helper="Ex: PIX"
                  error=""
                  value={item.nonCardTaxes !== null ? item.nonCardTaxes : ''}
                  onChange={(data) => setPaymentWay(i, data)}
                >
                  {showNonCardPaymentMethods(item)}
                </BasicOption>
              </div>
              )}

            </div>
          </div>
          <ErrorModal errors={errors} />
          <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#errormodal" ref={errorModalRef}>
            Launch static backdrop modal
          </button>
        </div>
      ))}

      {installments.length > 0 && (
      <div className="col-12 footer">
        <ButtonTextIcon
          label="Salvar"
          type={BtnType.PRIMARY}
          icon="backup"
          active
          onClick={() => validateInstallmentValues()}
          ref={null}
        />
      </div>
      )}

    </>
  );
}
