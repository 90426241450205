/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import PriceInput from '../../../components/PriceInput';
import api from '../../../config/axios';
import { showFutureDateBasedOnMonths, showTodayDate } from '../../../helpers/dates';
import { maskDate } from '../../../helpers/inputMasks.js';
import showApiErrors from '../../../helpers/showApiErrors';
import showCardPaymentWays, { normalizeToApiCardPaymentWays } from '../../../helpers/showCardMethods';
import showNonCardPaymentWays from '../../../helpers/showNonCardMethods';
import DefaultLayout from '../../../Layout';
import { PaymentGateway } from '../../PaymentTax/types';

type PaymentProps = {
    contractPjId: number;
    contractValue: number;
    discount: number;
    aditional: number;
    installments: number;
    totalValue: number;
    approvalDate: string;
}

export type InstallmentDTO = {
  contractPjPaymentId: number
  dueDate: string
  status: string
  value: number
  paidBy: string
  paymentGatewayId: number
  nonCardTaxes: string | null
  card: boolean
  flag: string | null
  cardLastDigits: string | null
  cardPaymentOption: string | null
}

export default function NewPaymentPF() {
  const { contractId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [forceRefresh, setForceRefresh] = useState(false);
  const [payment, setPayment] = useState<PaymentProps>({
    contractPjId: 0,
    contractValue: 0,
    discount: 0,
    aditional: 0,
    installments: 1,
    totalValue: 0,
    approvalDate: showTodayDate(),
  });
  const [installments, setInstallments] = useState<InstallmentDTO[]>([]);
  const [paymentGateways, setPaymentGateways] = useState<PaymentGateway[]>([]);

  async function fetchAllTax() {
    try {
      setLoading(true);
      const response = await api.get('/admin/configuration/payment-gateway', token);
      setPaymentGateways(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  async function fetchContract() {
    try {
      setLoading(true);
      const response = await api.get(`/admin/contract/pj/${contractId}`, token);
      setPayment({
        ...payment,
        contractValue: response.data.contractGlobalValue,
        totalValue: response.data.contractGlobalValue,
        contractPjId: Number(contractId),
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível localizar contrato.', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.DANGER }] });
    }
  }

  function showFlag(flag: string) {
    if (flag === 'OTHER') {
      return 'OUTRA';
    }
    return flag;
  }

  function calculateInstallmentsAndSetState(e: string) {
    if (e === 'Único') {
      setPayment({ ...payment, installments: 1 });
    } else {
      setPayment({ ...payment, installments: Number(e) });
    }
  }

  function changeDiscount(input: number) {
    const normalizedInput = input > 100 ? 100 : input;
    setPayment({ ...payment, discount: normalizedInput });
  }

  function calculateDiscount() {
    const calc = (payment.contractValue * payment.discount) / 100;
    return payment.contractValue - calc;
  }

  function calculateAditional() {
    const calc = (payment.contractValue * payment.aditional) / 100;
    return calc;
  }

  function calculateFinalValue() {
    const discountResult = calculateDiscount();
    const aditionalResult = calculateAditional();
    return discountResult + aditionalResult;
  }

  function showFirstNonCardMethod() {
    if (paymentGateways[0].taxes[0].boleto !== null) {
      return 'BOLETO';
    }
    if (paymentGateways[0].taxes[0].dinheiro !== null) {
      return 'DINHEIRO';
    }
    if (paymentGateways[0].taxes[0].doc !== null) {
      return 'DOC';
    }
    if (paymentGateways[0].taxes[0].pix !== null) {
      return 'PIX';
    }
    if (paymentGateways[0].taxes[0].ted !== null) {
      return 'TED';
    }
    return 'OUTRO';
  }

  function generateStallments() {
    const installmentList: InstallmentDTO[] = [];
    if (payment.installments === 1) {
      installmentList.push({
        contractPjPaymentId: 0,
        dueDate: showTodayDate(),
        status: 'UNPAID',
        paidBy: '',
        value: Number((payment.totalValue / payment.installments).toFixed(2)),
        paymentGatewayId: paymentGateways[0].id,
        nonCardTaxes: showFirstNonCardMethod(),
        cardLastDigits: '',
        cardPaymentOption: '',
        card: false,
        flag: '',
      });
    } else {
      for (let i = 0; i < payment.installments; i++) {
        installmentList.push({
          contractPjPaymentId: 0,
          dueDate: showFutureDateBasedOnMonths(i),
          status: 'UNPAID',
          paidBy: '',
          value: Number((payment.totalValue / payment.installments).toFixed(2)),
          paymentGatewayId: paymentGateways[0].id,
          nonCardTaxes: showFirstNonCardMethod().toString(),
          cardLastDigits: '',
          cardPaymentOption: '',
          card: false,
          flag: '',
        });
      }
    }

    setInstallments(installmentList);
  }

  function changeDueDate(idx: number, date: string) {
    installments[idx].dueDate = maskDate(date);
    setForceRefresh((prev) => !prev);
  }

  function changePaymentValue(idx: number, installmentValue: number) {
    installments[idx].value = installmentValue;
    const previousFullfiledValueAsArray = installments.filter((item, i) => i <= idx).map((item) => item.value);
    let previousFullFiledValue = 0;
    if (previousFullfiledValueAsArray.length > 0) {
      previousFullFiledValue = previousFullfiledValueAsArray.reduce((previous, acc) => previous + acc);
    }
    const remainingValue = (calculateFinalValue() - previousFullFiledValue);
    for (let i = idx + 1; i < installments.length; i += 1) {
      installments[i].value = Number((remainingValue / (installments.length - (idx + 1))).toFixed(2));
    }
    setForceRefresh((prev) => !prev);
  }

  function showGatewayName(id: number) {
    const filteredGateway = paymentGateways.filter((item) => item.id === id);
    return filteredGateway[0].name;
  }

  function changePaymentGateway(idx: number, gateway: string) {
    const filteredGateway = paymentGateways.filter((item) => item.name === gateway);

    const cardPaymentOptions = showCardPaymentWays(filteredGateway[0].card);
    const nonCardPaymentOptions = showNonCardPaymentWays(filteredGateway[0]);

    if (nonCardPaymentOptions.length > 0) {
      for (let i = idx; i < installments.length; i += 1) {
        installments[i].paymentGatewayId = filteredGateway[0].id;
        installments[i].card = false;
        installments[i].cardLastDigits = '';
        installments[i].flag = '';
        installments[i].cardPaymentOption = '';
        installments[i].nonCardTaxes = nonCardPaymentOptions[0];
      }
      setForceRefresh((prev) => !prev);
    } else if (cardPaymentOptions.length > 0) {
      for (let i = idx; i < installments.length; i += 1) {
        installments[i].paymentGatewayId = filteredGateway[0].id;
        installments[i].card = true;
        installments[i].cardLastDigits = '';
        installments[i].flag = filteredGateway[0].card[0].flag;
        installments[i].cardPaymentOption = cardPaymentOptions[0];
        installments[i].nonCardTaxes = '';
      }
      setForceRefresh((prev) => !prev);
    } else {
      setModal({ title: 'Ocorreu um erro', children: 'O método selecionado não possui formas de pagamento disponíveis', actions: [{ label: 'Ok, entendi', type: ModalActionType.DANGER, onClick: () => setModal(null) }] });
    }
  }

  function showAvailablePaymentOption(idCtx: number) {
    const result: string[] = [];
    const filteredMethod = paymentGateways.filter((item) => item.id === idCtx);
    if (filteredMethod.length > 0) {
      if (filteredMethod[0].card.length > 0) {
        result.push('CARTÃO');
      }
      if (filteredMethod[0].taxes.length > 0) {
        result.push('OUTRO');
      }
    }
    return result;
  }

  function showCardFlag(installment: InstallmentDTO) {
    const filteredGateway = paymentGateways.filter((item) => item.id === installment.paymentGatewayId);

    return filteredGateway[0].card.map((item) => {
      if (item.flag === 'OTHER') {
        return 'OUTRA';
      }
      return item.flag;
    });
  }

  function showCardPaymentMethods(installment: InstallmentDTO) {
    const filteredGateway = paymentGateways.filter((item) => item.id === installment.paymentGatewayId);
    const filteredCardsByFlag = filteredGateway[0].card.filter((card) => {
      if (installment.flag === 'OUTRA') {
        return card.flag === 'OTHER';
      }
      return card.flag === installment.flag;
    });
    return showCardPaymentWays(filteredCardsByFlag);
  }

  function setCardFlag(idx: number, flag: string) {
    for (let i = idx; i < installments.length; i += 1) {
      installments[i].flag = flag;
      installments[i].cardPaymentOption = showCardPaymentMethods(installments[i])[0];
    }
    setForceRefresh((prev) => !prev);
  }

  function changePaymentOption(idx: number, method: string) {
    if (method === 'CARTÃO') {
      for (let i = idx; i < installments.length; i += 1) {
        installments[i].card = true;
        installments[i].nonCardTaxes = '';
        installments[i].flag = showCardFlag(installments[i])[0];
        installments[i].cardPaymentOption = showCardPaymentMethods(installments[i])[0];
      }
      setForceRefresh((prev) => !prev);
    } else {
      for (let i = idx; i < installments.length; i += 1) {
        const filteredGateway = paymentGateways.filter((item) => item.name === item.gateway);
        const nonCardPaymentOptions = showNonCardPaymentWays(filteredGateway[0]);
        installments[i].card = false;
        installments[i].flag = null;
        installments[i].cardPaymentOption = '';
        installments[i].cardLastDigits = '';
        installments[i].nonCardTaxes = nonCardPaymentOptions[0];
      }
      setForceRefresh((prev) => !prev);
    }
  }

  function showNonCardPaymentMethods(installment: InstallmentDTO) {
    const result: string[] = [];
    paymentGateways.filter((item) => item.id === installment.paymentGatewayId).forEach((item) => {
      item.taxes.forEach((taxes) => {
        if (taxes.boleto !== null) {
          result.push('BOLETO');
        }
        if (taxes.dinheiro !== null) {
          result.push('DINHEIRO');
        }
        if (taxes.doc !== null) {
          result.push('DOC');
        }
        if (taxes.pix !== null) {
          result.push('PIX');
        }
        if (taxes.ted !== null) {
          result.push('TED');
        }
      });
    });
    return result;
  }

  function setPaymentWay(idx: number, way: string) {
    for (let i = idx; i < installments.length; i += 1) {
      installments[i].nonCardTaxes = way;
    }
    setForceRefresh((prev) => !prev);
  }

  function changeCardPaymentOption(idx: number, option: string) {
    for (let i = idx; i < installments.length; i += 1) {
      installments[i].cardPaymentOption = option;
    }
    setForceRefresh((prev) => !prev);
  }

  function changeCardLastDigits(idx: number, digits: string) {
    for (let i = idx; i < installments.length; i += 1) {
      installments[i].cardLastDigits = digits;
    }
    setForceRefresh((prev) => !prev);
  }

  async function saveInstallments(contractPjPaymentId: number) {
    try {
      const payload = {
        installments: installments.map((ctxInstallment) => {
          const cardPaymentOption = ctxInstallment.cardPaymentOption !== null ? ctxInstallment.cardPaymentOption : '';
          const flag = ctxInstallment.flag === 'OUTRA' ? 'OTHER' : ctxInstallment.flag;
          const normalizedCardPaymentOption = normalizeToApiCardPaymentWays(cardPaymentOption);
          return {
            ...ctxInstallment, contractPjPaymentId, cardPaymentOption: normalizedCardPaymentOption, flag,
          };
        }),
      };
      await api.post('/admin/contractpj/installment/', payload, token);
    } catch (err: any) {
      console.log(err.response);
      throw new Error('Não foi possível cadastrar parcela');
    }
  }

  async function savePayment() {
    try {
      setLoading(true);
      const paymentResponse = await api.post('/admin/contractpj/payment', payment, token);
      const paymentId = paymentResponse.data.id;
      await saveInstallments(paymentId);
      setLoading(false);
      navigate(-1);
    } catch (err: any) {
      setLoading(false);
      if (err === 'Não foi possível cadastrar parcela') {
        setModal({ title: 'Ocorreu um erro', children: 'Não foi possível cadastrar parcela', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
      } else if (typeof err.response !== 'undefined') {
        setModal(showApiErrors(setModal, err.response.data.errors));
      } else {
        setModal({ title: 'Ocorreu um erro', children: 'Não foi possível cadastrar fatura.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
      }
    }
  }

  function validateInstallmentValues() {
    const allValues = installments.map((item) => item.value);
    let sumOfAllValues = 0;
    if (allValues.length > 0) {
      sumOfAllValues = allValues.reduce((prev, acc) => prev + acc);
    }

    if (Math.round(Number(sumOfAllValues.toFixed(1))) > Math.round(Number(calculateFinalValue().toFixed(1)))) {
      setModal({ title: 'Ocorreu um erro', children: 'A soma do total das faturas ultrapassou o valor total do pagamento (contrato)', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
    } else if (Math.round((Number(sumOfAllValues.toFixed(1)))) < Math.round(Number(calculateFinalValue().toFixed(1)))) {
      setModal({ title: 'Ocorreu um erro', children: 'A soma do total das faturas é menor que o valor total do pagamento (contrato)', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
    } else {
      savePayment();
    }
  }

  useEffect(() => {
    fetchContract();
    fetchAllTax();
  }, []);

  return (
    <DefaultLayout
      pageTitle="Adicionar forma de pagamento"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products mt-4">
        <div className="col-12 card-bg p-4">
          <div className="row">
            <h1>Forma de pagamento</h1>
            <div className="col-4">
              <PriceInput
                id="valor-contrato"
                label="Valor do contrato"
                readOnly
                helper="Ex: 500"
                error={payment.contractValue === 0 ? 'Digite um valor' : ''}
                value={payment.contractValue.toString()}
                type="number"
                onChange={(data) => setPayment({ ...payment, contractValue: Number(data) })}
              />
            </div>
            {/* <div className="col-4">
              <PriceInput
                id="desconto-input"
                label="% desconto sobre valor do contrato"
                readOnly={false}
                helper="Ex: 20%"
                error=""
                icon="%"
                value={payment.discount.toString()}
                type="number"
                onChange={(data) => changeDiscount(Number(data))}
              />
            </div>
            <div className="col-4">
              <PriceInput
                id="adicional-input"
                label="% adicional sobre valor do contrato"
                readOnly={false}
                helper="Ex: 20%"
                error=""
                icon="%"
                value={payment.aditional.toString()}
                type="number"
                onChange={(data) => setPayment({ ...payment, aditional: Number(data) })}
              />
            </div> */}
            <div className="col-4">
              <PriceInput
                id="valor-final"
                label="Valor final"
                readOnly
                helper="Ex: 600"
                error={payment.totalValue === 0 ? 'Digite um valor' : ''}
                value={calculateFinalValue().toString()}
                type="number"
                onChange={(data) => null}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="data-aprovacao"
                label="Data de aprovação"
                readOnly={false}
                helper="Ex: 30-04-2022"
                error={payment.approvalDate.length < '30-04-2009'.length ? 'Digite uma data válida' : ''}
                value={payment.approvalDate.toString()}
                type="text"
                onChange={(data) => setPayment({ ...payment, approvalDate: maskDate(data) })}
              />
            </div>
            <div className="col-4">
              <BasicOption
                id="número-de-parcelas"
                label="Parcelas"
                readOnly={false}
                helper="Ex: 12"
                error=""
                value={payment.installments.toString() === '1' ? 'Único' : payment.installments.toString()}
                onChange={(data) => calculateInstallmentsAndSetState(data)}
              >
                {['Único', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36']}
              </BasicOption>
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Calcular Faturas"
                type={BtnType.PRIMARY}
                icon="calculate"
                active
                onClick={() => generateStallments()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>

      {installments.map((item, i) => (
        <div className="row products">
          <div className="col-12 card-bg p-4">
            <div className="row">
              <h1>{`Parcela - ${i + 1}`}</h1>
              <div className="col-4">
                <BasicInput
                  id="data-vencimento"
                  label="Data de vencimento"
                  readOnly={false}
                  helper="Ex: 30-04-2022"
                  error={item.dueDate.length < '00-00-0000'.length ? 'Digite uma data válida' : ''}
                  value={item.dueDate}
                  type="text"
                  onChange={(data) => changeDueDate(i, data)}
                />
              </div>
              <div className="col-4">
                <PriceInput
                  id={`valor-parcela-${i + 1}`}
                  label="Valor Bruto"
                  readOnly={false}
                  helper="Ex: 600"
                  error={item.value === 0 ? 'Digite um valor' : ''}
                  value={item.value.toString()}
                  type="number"
                  onChange={(data) => changePaymentValue(i, Number(data))}
                />
              </div>
              <div className="col-4">
                <BasicOption
                  id={`gateway-de-pagamento-${i + 1}`}
                  label="Gateway de pagamento"
                  readOnly={false}
                  helper="Ex: PagAzul Web"
                  error=""
                  value={showGatewayName(item.paymentGatewayId)}
                  onChange={(data) => changePaymentGateway(i, data)}
                >
                  {paymentGateways.map((gateway) => gateway.name)}
                </BasicOption>
              </div>
              <div className="col-4">
                <BasicOption
                  id={`opcoes-de-pagamento${i + 1}`}
                  label="Opções de pagamento"
                  readOnly={false}
                  helper="Ex: Cartão"
                  error=""
                  value={item.card ? 'CARTÃO' : 'OUTRO'}
                  onChange={(data) => changePaymentOption(i, data)}
                >
                  {showAvailablePaymentOption(item.paymentGatewayId).map((method) => method)}
                </BasicOption>
              </div>

              {item.card && (
                <div className="col-4">
                  <BasicOption
                    id={`flag-${i + 1}`}
                    label="Bandeira"
                    readOnly={false}
                    helper="Ex: VISA"
                    error=""
                    value={item.flag !== null ? showFlag(item.flag) : ''}
                    onChange={(data) => setCardFlag(i, data)}
                  >
                    {showCardFlag(item)}
                  </BasicOption>
                </div>
              )}

              {item.card && (
              <div className="col-4">
                <BasicOption
                  id={`card-way-${i + 1}`}
                  label="Forma de pagamento"
                  readOnly={false}
                  helper="Ex: Crédito 12x"
                  error=""
                  value={item.cardPaymentOption !== null ? item.cardPaymentOption : ''}
                  onChange={(data) => changeCardPaymentOption(i, data)}
                >
                  {showCardPaymentMethods(item)}
                </BasicOption>
              </div>
              )}

              {item.card && (
              <div className="col-4">
                <BasicInput
                  id={`card-last-digits-${i + 1}`}
                  label="Ultimos 04 dígitos"
                  readOnly={false}
                  helper="Ex: 2144"
                  value={item.cardLastDigits ? item.cardLastDigits.toString() : ''}
                  onChange={(data) => changeCardLastDigits(i, data)}
                  type="number"
                  error=""
                />
              </div>
              )}

              {!item.card && (
              <div className="col-4">
                <BasicOption
                  id={`way-${i + 1}`}
                  label="Forma de pagamento"
                  readOnly={false}
                  helper="Ex: PIX"
                  error=""
                  value={item.nonCardTaxes !== null ? item.nonCardTaxes : ''}
                  onChange={(data) => setPaymentWay(i, data)}
                >
                  {showNonCardPaymentMethods(item)}
                </BasicOption>
              </div>
              )}

            </div>
          </div>
        </div>
      ))}

      {installments.length > 0 && (
      <div className="col-12 footer">
        <ButtonTextIcon
          label="Salvar Pagamento"
          type={BtnType.PRIMARY}
          icon="backup"
          active
          onClick={() => validateInstallmentValues()}
          ref={null}
        />
      </div>
      )}

    </DefaultLayout>
  );
}
