/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInput from '../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import TextAreaInput from '../../components/TextAreaInput';
import api from '../../config/axios';
import ContractPJProps from '../../dto/contractPJ';
import { maskCPF } from '../../helpers/inputMasks.js';
import { validateCpf } from '../../helpers/validate';
import DefaultLayout from '../../Layout';
import { ContractPFProps } from '../Contracts/PF/types';

type Category = {
    name: string,
}

export default function EditIndicationCategory() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` } });
  const [category, setCategory] = useState<Category>({
    name: '',
  });

  async function fetchCategory() {
    try {
      setLoading(true);
      const response = await api.get(`/admin/indication-category/${id}`, token);
      setCategory(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível encontrar categoria. Verifique se a id do cabeçalho está correta', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
      setLoading(false);
    }
  }

  async function updateCategory() {
    try {
      setLoading(true);
      await api.put(`/admin/indication-category/${id}`, category, token);
      setLoading(false);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Não foi possível.', children: 'Não foi possível atualizar.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  useEffect(() => {
    fetchCategory();
  }, []);

  return (

    <DefaultLayout
      pageTitle="Editar Categoria"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={null}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Editar Categoria</h1>
          <div className="row">
            <div className="col-4">
              <BasicInput
                id="indicators-nome"
                label="Nome"
                readOnly={false}
                helper="Ex. José Antunes"
                error={category.name.length < 3 ? 'Digite um nome válido' : ''}
                value={category.name}
                type="text"
                onChange={(data) => setCategory({ ...category, name: data })}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                active
                onClick={() => updateCategory()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
