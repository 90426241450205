import React, { useState } from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Modal, { ModalActionType, ModalProps } from '../components/Modal';
import ToastSuccess from '../components/Toast';
import ToastError from '../components/ToastError';
import Aside from './Aside';
import Header from './Header';
import Loading from './Loading';
import RightFloatingAside from './RightFloatAside';

interface DefaultLayoutProps {
    children: React.ReactNode;
    pageTitle: string;
    loading: boolean
    showToastSuccess: boolean;
    showToastError: boolean;
    showRightSlider: boolean;
    rightSliderContent: React.ReactNode;
    modal: ModalProps | null;
    setCloseRightSlider: () => void;
}

export default function DefaultLayout({
  children,
  pageTitle,
  loading,
  showToastSuccess,
  showToastError,
  showRightSlider,
  rightSliderContent,
  setCloseRightSlider,
  modal,
}: DefaultLayoutProps) {
  return (
    <div className="internal-layout">
      {loading && <Loading />}
      {
        modal !== null && (
          <Modal
            title={modal.title}
            actions={modal.actions}
          >
            {modal.children}
          </Modal>
        )
      }
      {showToastSuccess && (<ToastSuccess />)}
      {showToastError && (<ToastError />)}
      {showRightSlider && (
      <RightFloatingAside setClose={() => setCloseRightSlider()}>
        {rightSliderContent}
      </RightFloatingAside>
      )}
      <Aside />
      <div className="right-container">
        <Header />
        <BreadCrumb title={pageTitle} />
        <div className="container-fluid content-container">
          {children}
        </div>
      </div>
    </div>
  );
}
