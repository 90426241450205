/* eslint-disable max-len */
import download from 'downloadjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalActionType, ModalProps } from '../../components/Modal';
import Tooltip from '../../components/Tooltip';
import api from '../../config/axios';
import normalizeDate, { checkIfDateIsToday } from '../../helpers/dates';
import DefaultLayout from '../../Layout';
import LeadModalfilter from './LeadFilterModal';

type DataProps = {
  id: number,
  total_dependents_enabled: number,
  value: number,
  created_at: string,
  created_by: string,
  is_converted: boolean,
  converted_by: null | string,
  converted_date: null | string,
  name: string,
  cpf: string,
  signaturedate: string
  paid_installment_id: null | any
}

type MetaProps = {
  per_page: number,
  current_page: number,
  last_page: number,
  first_page: number
}

type ResponseProps = {
  meta: MetaProps,
  data: DataProps[]
}

export type FilterProps = {
  currentPage: number,
  cpf: string,
  name: string,
  createdBy: string,
  convertedDateInit: string,
  convertedDateFinal: string,
  createdAtInit: string,
  createdAtFinal: string,
  isConverted: boolean | null
}

export default function Lead() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [leads, setLeads] = useState<ResponseProps>({
    meta: {
      first_page: 1,
      last_page: 1,
      current_page: 1,
      per_page: 50,
    },
    data: [],
  });
  const [filter, setFilter] = useState<FilterProps>({
    currentPage: 1,
    cpf: '',
    name: '',
    createdBy: '',
    convertedDateInit: '',
    convertedDateFinal: '',
    createdAtInit: '',
    createdAtFinal: '',
    isConverted: null,
  });
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [cpf, setCpf] = useState('');
  const [name, setName] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [convertedDateInit, setConvertedDateInit] = useState('');
  const [convertedDateFinal, setConvertedDateFinal] = useState('');
  const [createdAtInit, setCreatedAtInit] = useState('');
  const [createdAtFinal, setCreatedAtFinal] = useState('');
  const [isConverted, setIsConverted] = useState<null | boolean>(null);
  const [modal, setModal] = useState<ModalProps | null>(null);

  async function fetchLead() {
    const obj = {};
    if (filter.cpf.trim().length > 0) {
      Object.assign(obj, { cpf: filter.cpf });
    }
    if (filter.name.trim().length > 0) {
      Object.assign(obj, { name: filter.name });
    }
    if (filter.createdBy.trim().length > 0) {
      Object.assign(obj, { createdBy: filter.createdBy });
    }
    if (filter.convertedDateInit.trim().length > 0) {
      Object.assign(obj, { convertedDateInit: filter.convertedDateInit });
    }
    if (filter.convertedDateFinal.trim().length > 0) {
      Object.assign(obj, { convertedDateFinal: filter.convertedDateFinal });
    }
    if (filter.createdAtInit.trim().length > 0) {
      Object.assign(obj, { createdAtInit: filter.createdAtInit });
    }
    if (filter.createdAtFinal.trim().length > 0) {
      Object.assign(obj, { createdAtFinal: filter.createdAtFinal });
    }
    if (filter.isConverted !== null) {
      Object.assign(obj, { isConverted: filter.isConverted });
    }
    console.log(obj);
    try {
      setLoading(true);
      const response = await api.post('/admin/lead/', {
        currentPage: filter.currentPage,
        ...obj,
      }, token);
      console.log(response.data.data.map((item: any) => normalizeDate(item.signaturedate)));
      setLeads(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function goToFirstPage() {
    setFilter((prev) => ({ ...prev, currentPage: leads.meta.first_page }));
  }

  function goToPage(page: number) {
    setFilter({ ...filter, currentPage: Number(page) });
  }

  function generatePagination(totalOfPages: number) {
    const pagination: number[] = [];
    for (let i = 0; i < totalOfPages; i += 1) {
      pagination.push(i + 1);
    }
    return pagination;
  }

  function goToNextPage() {
    if (leads.meta.current_page < leads.meta.last_page) {
      setFilter((prev) => ({ ...prev, currentPage: leads.meta.current_page + 1 }));
    }
  }

  function goToLastPage() {
    setFilter((prev) => ({ ...prev, currentPage: leads.meta.last_page }));
  }

  async function convertLead(lead: DataProps) {
    if (!checkIfDateIsToday(normalizeDate(lead.signaturedate))) {
      setModal({ title: 'Ocorreu um erro', children: 'A data de assinatura do termo não é o dia atual. Atualize a data de assinatura e vencimento do lead cadastrado antes de convertê-lo em contrato.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      return;
    }
    try {
      setLoading(true);
      await api.post('/admin/lead-converter', {
        leadId: lead.id,
      }, token);
      await fetchLead();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
      setLoading(false);
    } catch (err: any) {
      if (err.response && err.response.data.errors) {
        console.log(err.response.data);
        setModal({ title: '', children: <div className="d-flex flex-column">{err.response.data.errors.map((item: any) => <p className="w-100">{item}</p>)}</div>, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      } else {
        console.log(err);
        setModal({ title: '', children: 'Não foi possível converter Lead', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }

      setLoading(false);
    }
  }

  async function generateContractPDF(lead: DataProps) {
    try {
      setLoading(true);
      const response = await api.get(`admin/lead-pdf/${lead.id}`, {
        headers:
         {
           Authorization: `bearer ${localStorage.getItem('adminToken')}`,
         },
        withCredentials: true,
        responseType: 'arraybuffer',
      });
      download(response.data, `ADESAO - ${lead.name}.pdf`);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
    }
  }

  function resolveColor(installment: DataProps) {
    if (installment.is_converted) {
      return '#6200EE';
    } if (installment.paid_installment_id) {
      return '#00c500';
    }
    return 'inherit';
  }

  useEffect(() => {
    fetchLead();
  }, [filter]);

  return (
    <DefaultLayout
      pageTitle="Adicionar Termo de adesão"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Termo de adesão PF</h1>
          <div className="table-wrapper">
            <div className="table-actions d-flex justify-content-start">
              <button className="table-btn-primary" type="button" onClick={() => navigate('/cliente/pf/criar-lead')}>
                <span className="material-icons-round">
                  add
                </span>
                adicionar
              </button>
              <button className="table-btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#leadModalFilter">
                <span className="material-icons-round">
                  filter_alt
                </span>
                filtrar
              </button>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Titular</th>
                  <th scope="col">CPF</th>
                  <th scope="col">Dependentes</th>
                  <th scope="col">Valor</th>
                  <th scope="col">Convertido</th>
                  <th scope="col">Criado por</th>
                  <th scope="col">Criado em</th>
                  <th scope="col">Convertido por</th>
                  <th scope="col">Dt. Conversão</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {leads.data.length > 0 ? leads.data.map((item, i) => (
                  <tr>
                    <td style={{ color: resolveColor(item) }}>{item.name}</td>
                    <td style={{ color: resolveColor(item) }}>{item.cpf}</td>
                    <td style={{ color: resolveColor(item) }}>{item.total_dependents_enabled}</td>
                    <td style={{ color: resolveColor(item) }}>{item.value}</td>
                    <td style={{ color: resolveColor(item) }}>{item.is_converted ? 'SIM' : 'NÃO'}</td>
                    <td style={{ color: resolveColor(item) }}>{item.created_by}</td>
                    <td style={{ color: resolveColor(item) }}>{normalizeDate(item.created_at)}</td>
                    <td style={{ color: resolveColor(item) }}>{item.converted_by || 'N/A'}</td>
                    <td style={{ color: resolveColor(item) }}>{item.converted_date ? normalizeDate(item.converted_date) : 'N/A'}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <button type="button" onClick={() => generateContractPDF(item)} id="convert" aria-describedby="adesao">
                            <span className="material-icons-round" style={{ color: '#6200EE' }}>
                              description
                            </span>
                          </button>
                          <Tooltip id="adesao" text="Essa ação irá gerar o contrato de adesão." />
                        </div>
                        {!item.is_converted && (
                        <div className="table-action-icon">
                          <button type="button" onClick={() => convertLead(item)} id="convert" aria-describedby="convert">
                            <span className="material-icons-round">
                              person_add_alt_1
                            </span>
                          </button>
                          <Tooltip id="convert" text="Essa ação irá converter Lead em contrato." />
                        </div>
                        )}
                        <div className="table-action-icon">
                          <button type="button" onClick={() => navigate(`/cliente/pf/editarlead/${item.id}`)}>
                            <span className="material-icons-round">
                              more
                            </span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : <p>Não existem leads cadastrados</p>}
              </tbody>
            </table>
            <div className="table-footer">
              <button type="button" onClick={() => goToFirstPage()}>Primeira</button>
              <p>Página</p>
              <select style={{ minWidth: 300 }} value={leads.meta.current_page} onChange={(e) => goToPage(Number(e.target.value))}>
                {generatePagination(leads.meta.last_page).map((item) => <option value={item}>{item}</option>)}
              </select>
              <p>
                de
                {' '}
                <strong>{leads.meta.last_page}</strong>
              </p>
              <button
                type="button"
                onClick={() => goToNextPage()}
              >
                Próxima
              </button>
              <button
                type="button"
                onClick={() => goToLastPage()}
              >
                Última
              </button>
            </div>
          </div>
        </div>
      </div>
      <LeadModalfilter
        filter={(arg) => setFilter({ ...arg })}
        cpf={cpf}
        setCpf={(arg) => setCpf(arg)}
        name={name}
        setName={(arg) => setName(arg)}
        createdBy={createdBy}
        setCreatedBy={(arg) => setCreatedBy(arg)}
        convertedDateInit={convertedDateInit}
        setConvertedDateInit={(arg) => setConvertedDateInit(arg)}
        convertedDateFinal={convertedDateFinal}
        setConvertedDateFinal={(arg) => setConvertedDateFinal(arg)}
        createdAtInit={createdAtInit}
        setCreatedAtInit={(arg) => setCreatedAtInit(arg)}
        createdAtFinal={createdAtFinal}
        setCreatedAtFinal={(arg) => setCreatedAtFinal(arg)}
        isConverted={isConverted}
        setIsConverted={(arg) => setIsConverted(arg)}
      />
    </DefaultLayout>
  );
}
