/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

interface TermsProps {
    fetchFunction: (arg0: 'Empresa paga para o titular e desconta em folha o valor do dependente'
    | 'Empresa desconta em folha os valores do titular e do dependente'
    | 'Empresa paga para o titular e dependente'
    | 'Empresa paga 50% para o titular') => void
    loading: boolean,
    id?: string,
}

export default function TermOptionModal({ fetchFunction, loading, id }: TermsProps) {
  return (
    <div className="modal fade" id={id || 'termsOptionsModal'} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Opções de termos</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            {loading ? (
              <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <p className="text-center fw-bold mb-2 text-uppercase mt-2" style={{ fontSize: 12 }}>Selecione a opção</p>
                <button type="button" style={{ width: '100%', background: '#039EF7', height: 48 }} className="generate-excel-btn-wrapper justify-content-center" onClick={() => fetchFunction('Empresa paga para o titular e desconta em folha o valor do dependente')}>
                  Empresa paga para o titular e desconta em folha o valor do dependente
                </button>
                <button type="button" style={{ width: '100%', background: '#039EF7', height: 48 }} className="generate-excel-btn-wrapper  justify-content-center mt-2" onClick={() => fetchFunction('Empresa desconta em folha os valores do titular e do dependente')}>
                  Empresa desconta em folha os valores do titular e do dependente
                </button>
                <button type="button" style={{ width: '100%', background: '#039EF7', height: 48 }} className="generate-excel-btn-wrapper mt-2 justify-content-center" onClick={() => fetchFunction('Empresa paga para o titular e dependente')}>
                  Empresa paga para o titular e dependente
                </button>
                <button
                  type="button"
                  style={{
                    width: '100%', background: '#039EF7', textAlign: 'center', height: 48,
                  }}
                  className="generate-excel-btn-wrapper mt-2  justify-content-center"
                  onClick={() => fetchFunction('Empresa paga 50% para o titular')}
                >
                  Empresa paga 50% para o titular
                </button>
              </>
            )}

          </div>
        </div>
      </div>
    </div>
  );
}
