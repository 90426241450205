import React from 'react';
import BasicInput from '../../components/BasicInput';

interface ICreateCategoryModal {
    name: string;
    onChange: (arg0: string) => void
}

export default function CreateCategoryModal({ name, onChange }: ICreateCategoryModal) {
  return (
    <BasicInput
      id="newcategory-input"
      label="Nome da categoria"
      readOnly={false}
      helper="Ex: Óticas"
      error={name.length < 1 ? 'Campo obrigatório' : ''}
      value={name}
      type="text"
      onChange={(data) => onChange(data)}
    />
  );
}
