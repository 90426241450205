import React, { useState, useEffect } from 'react';
import DashboardCardOverlay from '../../../components/DashboardCardOverlay';
import api from '../../../config/axios';

type DataResponse = {
    id: number,
    isPj: boolean
}

export default function ContractsWithouPayment() {
  const [stats, setStats] = useState<DataResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function fetchContractStats() {
    try {
      setLoading(true);
      const response = await api.get('/admin/reports/contractwithoutpayment', token);
      setStats(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log('error at fetch contract activities', err.response.data);
    }
  }

  function openInAnotherTab() {
    stats.forEach((item) => {
      if (item.isPj) {
        window.open(`https://painel.redesanta.com/contratos/pj/editar/${item.id}`, '_blank');
      } else {
        window.open(`https://painel.redesanta.com/contratos/pf/visualizar/${item.id}`, '_blank');
      }
    });
  }

  useEffect(() => {
    fetchContractStats();
  }, []);
  return (
    <DashboardCardOverlay loading={loading}>
      <div className="card-contract-activities-primary h-100">
        <div className="title-wrapper">
          <div className="">
            <h5 className="title">Contratos sem pagamento cadastrado</h5>
          </div>
        </div>
        <div className="content-wrapper">
          <h1 className="text-center text-number-alert">{stats.length}</h1>
          {stats.length > 0 && (<button type="button" className="open-new-tab" onClick={() => openInAnotherTab()}>Abrir todos em nova aba</button>)}
        </div>
      </div>
    </DashboardCardOverlay>
  );
}
