/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import SwitcherPrimary from '../../components/SwitcherPrimary';
import api from '../../config/axios';
import PartnersCategories from '../../dto/partnersCategories';
import DefaultLayout from '../../Layout';
import EditCategoryModal from './EditCategory';
import CreateCategoryModal from './NewCategory';
import OrderCategoriesModal from './OrderCategories';
import OrderPartnerModal from './OrderPartner';

export interface IPartner {
  id: number,
  cpfCnpj: string,
  services: string,
  name: string,
  email: string,
  password: string,
  position: number,
  isActive: boolean,
  createdAt: string,
}

export default function Partners() {
  const navigate = useNavigate();
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [newCategoryName, setNewCategoryName] = useState<null | string>(null);
  const [updatedCategoryName, setUpdatedCategoryName] = useState<null | string>(null);
  const [updateCategoryID, setUpdateCategoryID] = useState<number>(0);
  const [categories, setCategories] = useState<PartnersCategories[]>([]);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [showActive, setShowActive] = useState(
    JSON.parse(localStorage.getItem('showActive_partners') || 'true'),
  );
  const [showInactive, setShowInactive] = useState(
    JSON.parse(localStorage.getItem('showInactive_partners') || 'false'),
  );

  function closeModal() {
    setNewCategoryName(null);
    setUpdatedCategoryName(null);
    setModal(null);
  }

  async function fetchCategories() {
    try {
      setLoading(true);
      const response = await api.get('/admin/accredited/category/showallcategories', token);
      setCategories(response.data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  async function tryupdateNewCategory(id: number) {
    try {
      setLoading(true);
      await api.put(`/admin/accredited/category/${id}`, { title: updatedCategoryName!.trim() }, token);
      closeModal();
      setShowToastSuccess(true);
      fetchCategories();
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      closeModal();
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 3000);
    }
  }

  async function fetchPartners() {
    try {
      setLoading(true);
      const response = await api.get('admin/accredited/all', token);
      setPartners(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  async function tryDeleteCategory(id: number) {
    try {
      setLoading(true);
      await api.delete(`/admin/accredited/category/${id}`, token);
      closeModal();
      setShowToastSuccess(true);
      fetchCategories();
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      setLoading(false);
      closeModal();
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 3000);
    }
  }

  function openUpdateCategoryModal(id: number, categoryName: string | null) {
    setUpdatedCategoryName(categoryName);
    setUpdateCategoryID(id);

    if (updatedCategoryName !== null) {
      setModal({
        title: 'Editar categoria',
        children: <EditCategoryModal
          name={updatedCategoryName === null ? '' : updatedCategoryName}
          id={id}
          onChange={(data) => setUpdatedCategoryName(data)}
        />,
        actions: [{
          label: 'Cancelar',
          onClick: () => setModal(null),
          type: ModalActionType.PRIMARY,
        },
        { label: 'Atualizar', onClick: () => tryupdateNewCategory(id), type: ModalActionType.SECONDARY },
        { label: 'Deletar', onClick: () => tryDeleteCategory(id), type: ModalActionType.DANGER }],
      });
    }
  }

  async function tryCreateNewCategory() {
    try {
      setLoading(true);
      await api.post('/admin/accredited/category/add', { title: newCategoryName!.trim() }, token);
      closeModal();
      setShowToastSuccess(true);
      fetchCategories();
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      closeModal();
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 3000);
    }
  }

  const openModal = () => {
    if (newCategoryName !== null) {
      setModal({
        title: 'Adicionar categoria',
        children: <CreateCategoryModal
          name={newCategoryName!}
          onChange={(data) => setNewCategoryName(data)}
        />,
        actions: [{ label: 'Adicionar', type: ModalActionType.PRIMARY, onClick: () => tryCreateNewCategory() }, { label: 'Cancelar', type: ModalActionType.DANGER, onClick: () => closeModal() }],
      });
    }
  };

  async function changePartnerStatus(partner: IPartner) {
    try {
      setLoading(true);
      await api.put(`/admin/accredited/${partner.id}`, { ...partner, isActive: !partner.isActive }, token);
      fetchPartners();
      fetchCategories();
      setLoading(false);
    } catch (err: any) {
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 3000);
      setLoading(false);
    }
  }

  function onChangePartnerOrderSucessFully() {
    setModal({ title: 'Tudo certo', children: 'Ordem alterada com sucesso', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
  }

  useEffect(() => {
    openModal();
  }, [newCategoryName]);

  useEffect(() => {
    fetchCategories();
    fetchPartners();
  }, []);

  useEffect(() => {
    openUpdateCategoryModal(updateCategoryID, updatedCategoryName);
  }, [updatedCategoryName]);

  useEffect(() => {
    localStorage.setItem('showActive_partners', JSON.stringify(showActive));
    localStorage.setItem('showInactive_partners', JSON.stringify(showInactive));
  }, [showActive, showInactive]);

  const filteredPartners = partners.filter((partner) => {
    if (showActive && showInactive) return true;
    if (showActive) return partner.isActive;
    if (showInactive) return !partner.isActive;
    return false;
  });

  if (categories.length < 1) {
    return (
      <DefaultLayout
        pageTitle="Rede Credenciada"
        loading={loading}
        showToastSuccess={showToastSuccess}
        showToastError={showToastError}
        showRightSlider={false}
        rightSliderContent={null}
        modal={modal}
        setCloseRightSlider={() => null}
      >
        <div className="not-exsits">
          <span className="material-icons-round">
            not_interested
          </span>
          <h3 className="table-not-exists">Hão há categoria cadastrada</h3>
          <ButtonTextIcon
            label="Adicionar categoria"
            type={BtnType.PRIMARY}
            icon="add"
            active
            onClick={() => setNewCategoryName('')}
            ref={null}
          />
        </div>
      </DefaultLayout>
    );
  }
  return (
    <DefaultLayout
      pageTitle="Rede credenciada"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <OrderCategoriesModal id="order-modal-categories" categories={categories.sort((a, b) => a.position - b.position)} setLoading={(arg) => setLoading(arg)} fetchCategories={() => fetchCategories()} onSuccess={() => onChangePartnerOrderSucessFully()} />
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Categorias</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <button className="table-btn-primary" type="button" onClick={() => setNewCategoryName('')}>
                <span className="material-icons-round">
                  add
                </span>
                adicionar
              </button>
              <button type="button" className="table-btn-primary" data-bs-toggle="modal" data-bs-target="#order-modal-categories">
                Definir ordem no site
              </button>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Título</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((item) => (
                  <tr key={item.id}>
                    <td>{item.title}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <button type="button" onClick={() => openUpdateCategoryModal(item.id, item.title)}>
                            <span className="material-icons-round">
                              more
                            </span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <OrderPartnerModal id="order-modal" partners={partners} setLoading={(arg) => setLoading(arg)} fetchAccrediteds={() => fetchPartners()} onSuccess={() => onChangePartnerOrderSucessFully()} />
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Rede credenciada</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <div className="d-flex align-items-center">
                <button className="table-btn-primary" type="button" onClick={() => navigate('/credenciados/adicionar')}>
                  <span className="material-icons-round">
                    add
                  </span>
                  adicionar
                </button>
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <button type="button" className="table-btn-primary me-4" data-bs-toggle="modal" data-bs-target="#order-modal">
                    Definir ordem no site
                  </button>
                </div>
                <div className="form-check lh-lg">
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    id="showInactive"
                    checked={showInactive}
                    onChange={() => setShowInactive(!showInactive)}
                  />
                  <label
                    className="form-check-label text-secondary mb-0 fs-7"
                    htmlFor="showInactive"
                  >
                    Mostrar Inativos
                  </label>
                </div>
              </div>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Título</th>
                  <th scope="col">Dt. Inserção</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {filteredPartners.length > 0 ? filteredPartners.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.createdAt}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <SwitcherPrimary
                            id={`switcher-${item.id}`}
                            onChange={() => changePartnerStatus(item)}
                            isActive={item.isActive}
                          />
                          <button type="button" onClick={() => navigate(`/credenciados/editar/${item.id}`)}>
                            <span className="material-icons-round">
                              more
                            </span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : <p>Nenhum credenciado cadastrado</p>}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
