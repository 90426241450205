import React from 'react';
import {
  MapContainer, TileLayer, Marker, Popup, useMap,
} from 'react-leaflet';

interface MapProps {
    lat: number;
    long: number;
}

export default function Map({ lat, long }: MapProps) {
  function ChangeView({ center, zoom }: any) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }
  return (
    <div id="map">
      <MapContainer center={[lat, long]} zoom={13} style={{ height: '100%', width: '100vw' }} scrollWheelZoom>
        <ChangeView center={[lat, long]} zoom={21} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[lat, long]} />
      </MapContainer>
    </div>
  );
}
