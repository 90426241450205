/* eslint-disable max-len */
import React, { createRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import api from '../../../config/axios';
import dataURIToBlob from '../../../helpers/fileReader';
import { maskPhone } from '../../../helpers/inputMasks.js';
import DefaultLayout from '../../../Layout';

type CategoryResponse = {
    id: number;
    isActive: boolean;
    title: string;
    type: string;
}

interface IPartner {
    id: number,
    cpfCnpj: string,
    services: string,
    name: string,
    email: string,
    password: string,
    isActive: true,
}

type Professional = {
    name: string;
    councilRegister: string;
    sex: string;
    phone: string;
    whatsapp: string;
    show: boolean;
    avatar: {
      fileUrl: string
    }
    isActive: boolean;
    accredited: IPartner[],
    category: CategoryResponse[]
}

export default function EditProfessional() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [file, setFile] = useState<string | null | ArrayBuffer>(null);
  const [fileRef] = useState(createRef<any>());
  const [categories, setCategories] = useState<CategoryResponse[]>([]);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [defautProfessional, setDefaultProfessional] = useState<Professional>({
    name: '',
    councilRegister: '',
    sex: 'f',
    isActive: true,
    phone: '',
    whatsapp: '',
    show: true,
    avatar: {
      fileUrl: '',
    },
    accredited: [],
    category: [],
  });
  const [professional, setProfessional] = useState<Professional>({
    name: '',
    councilRegister: '',
    sex: 'f',
    phone: '',
    show: true,
    whatsapp: '',
    avatar: {
      fileUrl: '',
    },
    isActive: true,
    accredited: [],
    category: [],
  });

  function createFormData() {
    const body = new FormData();
    const blob = dataURIToBlob(file! as string);
    body.append('file', blob);
    return body;
  }

  function createObjectURL(data: React.ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();
    reader.readAsDataURL(data.target.files![0]);
    reader.onload = (ctx) => {
      if (ctx.loaded / 1024 < 4000) {
        setFile(ctx.target!.result);
      } else {
        setModal({ title: 'Ocorreu um erro!', children: 'O arquivo selecionado ultrapassou o limite máximo de 4mb', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }
    };
  }

  async function fetchProfessional() {
    try {
      setLoading(true);
      const response = await api.get(`/admin/professional/${id}`, token);
      console.log(response.data);
      setProfessional({ ...professional, ...response.data });
      setDefaultProfessional(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Profissional não encontrado no banco de dados.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => navigate(-1) }] });
    }
  }

  async function fetchPartnersAndCategories() {
    try {
      setLoading(true);
      const partnersResponse = await api.get('/admin/accredited/all', token);
      const categoriesResponse = await api.get('/admin/professional-category', token);
      setPartners(partnersResponse.data);
      setCategories(categoriesResponse.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  async function saveProfessional() {
    try {
      setLoading(true);
      console.log('prof', {
        ...professional,
        whatsapp: professional.whatsapp !== '' ? professional.whatsapp : null,
        phone: professional.phone !== '' ? professional.phone : null,
        councilRegister: professional.councilRegister !== '' ? professional.councilRegister : null,
      });
      await api.put(`/admin/professional/${id}`, {
        ...professional,
        whatsapp: professional.whatsapp !== '' ? professional.whatsapp : null,
        phone: professional.phone !== '' ? professional.phone : null,
        councilRegister: professional.councilRegister !== '' ? professional.councilRegister : null,
      }, token);
      if (file !== null) {
        await api.post(`/admin/professional/avatar/${id}`, createFormData(), token);
      }
      setLoading(false);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
      fetchProfessional();
      fetchPartnersAndCategories();
    } catch (err: any) {
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível alterar profissional. Tente novamente mais tarde.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
      setLoading(false);
    }
  }

  async function deleteProfessional() {
    try {
      setLoading(true);
      await api.delete(`/admin/professional/${id}`, token);
      navigate(-1);
      setLoading(true);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível excluir profissional. Verifique se o mesmo ainda existe no sistema.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  function openModalDeleteProfessional() {
    setModal({ title: 'Excluir profissional', children: 'O profissional será excluido do banco de dados. Deseja executar essa ação ?', actions: [{ label: 'Cancelar', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }, { label: 'Deletar', type: ModalActionType.DANGER, onClick: () => deleteProfessional() }] });
  }

  function verifyIfPartnersIsAlreadyIncluded(partnerId: number) {
    const ctx = professional.accredited.filter((item) => item.id === partnerId);
    if (ctx.length > 0) {
      return true;
    }
    return false;
  }

  function verifyIfCategoriesAlreadyIncluded(partnerId: number) {
    const ctx = professional.category.filter((item) => item.id === partnerId);
    if (ctx.length > 0) {
      return true;
    }
    return false;
  }

  function removePartnerAppend(partnerId: number) {
    const ctx = professional.accredited.filter((item) => item.id !== partnerId);
    setProfessional({ ...professional, accredited: ctx });
  }

  function removeCategoryAppend(categoryId: number) {
    const ctx = professional.category.filter((item) => item.id !== categoryId);
    setProfessional({ ...professional, category: ctx });
  }

  async function saveVinculatePartners() {
    try {
      setLoading(true);
      const idsToRemove = defautProfessional.accredited.filter((item) => !professional.accredited.includes(item));
      const removePromises = idsToRemove.map((item) => api.put('/admin/professional-to-accredited', { professionalId: `${id}`, accreditedId: `${item.id}` }, token));
      await Promise.all(removePromises);
      const idsToAdd = professional.accredited.filter((item) => !defautProfessional.accredited.includes(item));
      const addPromises = idsToAdd.map((item) => api.post('/admin/professional-to-accredited', { professionalId: `${id}`, accreditedId: `${item.id}` }, token));
      await Promise.all(addPromises);
      fetchPartnersAndCategories();
      fetchProfessional();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 3000);
      console.log(err.response);
    }
  }

  async function saveVinculateCategories() {
    try {
      setLoading(true);
      const idsToRemove = defautProfessional.category.filter((item) => !professional.category.includes(item));
      const removePromises = idsToRemove.map((item) => api.put('/admin/professional-to-category', { professionalId: `${id}`, categoryId: `${item.id}` }, token));
      await Promise.all(removePromises);
      const idsToAdd = professional.category.filter((item) => !defautProfessional.category.includes(item));
      const addPromises = idsToAdd.map((item) => api.post('/admin/professional-to-category', { professionalId: `${id}`, categoryId: `${item.id}` }, token));
      await Promise.all(addPromises);
      fetchPartnersAndCategories();
      fetchProfessional();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log(err.response);
    }
  }

  useEffect(() => {
    fetchProfessional();
    fetchPartnersAndCategories();
  }, []);

  return (
    <DefaultLayout
      pageTitle="Editar profissional"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row partners">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Editar profissional</h1>
          <section className="row">
            <h1 className="text-center">Avatar do credenciado</h1>
            <div className="col-12 mb-4 d-flex justify-content-center align-items-center flex-column">
              <div className="partner-photo-wrapper">
                <img src={file === null ? professional.avatar.fileUrl : file! as string} alt="" />
              </div>
              <p className="mb-4 helper">Melhor proporção: 4/4. Ex: 400px / 400px</p>
              <input
                type="file"
                className="d-none"
                onChange={(data) => createObjectURL(data)}
                accept=".png, .jpeg, .jpg"
                ref={fileRef}
              />
              <ButtonTextIcon
                label="Selecionar imagem"
                type={BtnType.PRIMARY}
                icon="image"
                active
                onClick={() => fileRef.current.click()}
                ref={fileRef}
              />
            </div>
          </section>
          <div className="row">
            <div className="col-4">
              <BasicInput
                id="name-input"
                label="Nome"
                readOnly={false}
                helper="Ex. Dr. Áureo"
                error={professional.name.length < 1 ? 'Campo obrigatório' : ''}
                value={professional.name}
                type="text"
                onChange={(data) => setProfessional({ ...professional, name: data })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="council-input"
                label="Registro no conselho"
                readOnly={false}
                helper="Ex. CRMMA-41234"
                error={professional.councilRegister.length < 1 ? 'Campo obrigatório' : ''}
                value={professional.councilRegister}
                type="text"
                onChange={(data) => setProfessional({ ...professional, councilRegister: data })}
              />
            </div>
            <div className="col-4">
              <BasicOption
                id="sexo-input"
                label="Sexo"
                readOnly={false}
                helper="Ex. Feminino"
                error=""
                value={professional.sex === 'f' ? 'Feminino' : 'Masculino'}
                onChange={(data) => setProfessional({ ...professional, sex: data === 'Feminino' ? 'f' : 'm' })}
              >
                {['Feminino', 'Masculino']}
              </BasicOption>
            </div>
            <div className="col-4">
              <BasicInput
                id="phone-input"
                label="Telefone"
                readOnly={false}
                helper="Ex. (62) 0000-0000"
                error=""
                value={professional.phone}
                type="text"
                onChange={(data) => setProfessional({ ...professional, phone: maskPhone(data) })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="whatsapp-input"
                label="Whatsapp"
                readOnly={false}
                helper="Ex. (62) 0000-0000"
                error=""
                value={professional.whatsapp}
                type="text"
                onChange={(data) => setProfessional({ ...professional, whatsapp: maskPhone(data) })}
              />
            </div>
            <div className="col-12 mt-2 mb-4 allow-modifications">
              <input
                type="checkbox"
                onChange={() => setProfessional({ ...professional, show: !professional.show })}
                checked={professional.show}
              />
              <p>Exibir no site (www.redesanta.com/beneficios)</p>
            </div>
          </div>
          <div className="col-12 footer">
            <ButtonTextIcon
              label="Excluir"
              type={BtnType.DANGER}
              icon="delete"
              active
              onClick={() => openModalDeleteProfessional()}
              ref={null}
            />
            <ButtonTextIcon
              label="Salvar"
              type={BtnType.PRIMARY}
              icon="backup"
              active
              onClick={() => saveProfessional()}
              ref={null}
            />
          </div>
        </div>
      </div>

      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Vínculo com parceiros</h1>
          <div className="row add-remove-list">
            {partners.map((item) => (
              <div className="col-12 d-flex p-1 align-items-center">
                {verifyIfPartnersIsAlreadyIncluded(item.id) ? (
                  <button type="button" onClick={() => removePartnerAppend(item.id)}>
                    <span className="material-icons-round text-danger">
                      remove_circle
                    </span>
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      setProfessional({ ...professional, accredited: [...professional.accredited, item] });
                    }}
                  >
                    <span className="material-icons-round text-success">
                      add_circle
                    </span>
                  </button>
                )}
                <p>{item.name}</p>
              </div>
            ))}
          </div>
          <div className="col-12 footer">
            <ButtonTextIcon
              label="Salvar"
              type={BtnType.PRIMARY}
              icon="backup"
              active
              onClick={() => saveVinculatePartners()}
              ref={null}
            />
          </div>
        </div>
      </div>

      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Vínculo com especialidades</h1>
          <div className="row add-remove-list">
            {categories.map((item) => (
              <div className="col-12 d-flex p-1 align-items-center">
                {verifyIfCategoriesAlreadyIncluded(item.id) ? (
                  <button type="button" onClick={() => removeCategoryAppend(item.id)}>
                    <span className="material-icons-round text-danger">
                      remove_circle
                    </span>
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      setProfessional({ ...professional, category: [...professional.category, item] });
                    }}
                  >
                    <span className="material-icons-round text-success">
                      add_circle
                    </span>
                  </button>
                )}
                <p>{item.title}</p>
              </div>
            ))}
          </div>
          <div className="col-12 footer">
            <ButtonTextIcon
              label="Salvar"
              type={BtnType.PRIMARY}
              icon="backup"
              active
              onClick={() => saveVinculateCategories()}
              ref={null}
            />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
