/* eslint-disable react/require-default-props */
import React from 'react';

interface AddInputProps {
    id: string;
    label: string;
    readOnly: boolean;
    helper: string;
    error: string;
    value: string;
    type: string;
    icon?: string;
    onChange: (arg0: string) => void;
    onAdd: () => void;
    btnStyle?: React.CSSProperties
}

export default function AddInput({
  id, label, readOnly, helper, error, value, onChange, type, onAdd, icon, btnStyle,
}: AddInputProps) {
  return (
    <div className="add-input">
      {!readOnly && (
      <button className="add-btn" type="button" onClick={() => onAdd()} style={btnStyle}>
        <span className="material-icons-round">
          {icon || 'add'}
        </span>
      </button>
      )}
      <label htmlFor={id}>
        <p>{label}</p>
        <input
          id={id}
          readOnly={readOnly}
          className={`${readOnly ? 'readonly' : ''}`}
          value={value}
          onChange={(data) => onChange(data.target.value)}
          type={type}
        />
        {helper.length > 0
      && error.length === 0
      && !readOnly && (
        <span className="input-helper">{helper}</span>
        ) }
        {error.length > 0
        && !readOnly
      && (
      <span className="input-error">{error}</span>
      )}
      </label>
    </div>

  );
}
