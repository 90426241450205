import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { FilterProps as FilterType } from '.';
import BasicInput from '../../components/BasicInput';
import BasicOption from '../../components/BasicOption';
import { maskCPF, maskDate, unmaskData } from '../../helpers/inputMasks.js';

type FilterProps = {
    filter: (arg: FilterType) => void,
    cpf: string,
    setCpf: (arg: string) => void,
    name: string,
    setName: (arg: string) => void,
    createdBy: string,
    setCreatedBy: (arg: string) => void
    convertedDateInit: string,
    setConvertedDateInit: (arg: string) => void
    convertedDateFinal: string,
    setConvertedDateFinal: (arg: string) => void
    createdAtInit: string,
    setCreatedAtInit: (arg: string) => void
    createdAtFinal: string,
    setCreatedAtFinal: (arg: string) => void
    isConverted: null | boolean,
    setIsConverted: (arg: null | boolean) => void
}
export default function LeadModalfilter({
  filter,
  cpf,
  setCpf,
  name,
  setName,
  createdBy,
  setCreatedBy,
  convertedDateInit,
  setConvertedDateInit,
  convertedDateFinal,
  setConvertedDateFinal,
  createdAtInit,
  setCreatedAtInit,
  createdAtFinal,
  setCreatedAtFinal,
  isConverted,
  setIsConverted,
}: FilterProps) {
  const [ref] = useState(React.createRef<any>());

  async function validateDateDoubleDates(dateInitial: string, dateFinal: string) {
    try {
      const initialDateAsDateTime = DateTime.fromFormat(dateInitial, 'dd-MM-yyyy');
      const finalDateAsDateTime = DateTime.fromFormat(dateFinal, 'dd-MM-yyyy');
      if (!initialDateAsDateTime.isValid) {
        throw new Error('Data inicial inválida.');
      }
      if (!finalDateAsDateTime.isValid) {
        throw new Error('Data final inválida.');
      }

      if (initialDateAsDateTime > finalDateAsDateTime) {
        throw new Error('Data inicial maior que a data final.');
      }
      return {
        initialDateAsDateTime,
        finalDateAsDateTime,
      };
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  async function validateSingleDates(date: string) {
    try {
      const initialDateAsDateTime = DateTime.fromFormat(date, 'dd-MM-yyyy');
      if (!initialDateAsDateTime.isValid) {
        throw new Error('Data inicial inválida.');
      }

      return {
        initialDateAsDateTime,
      };
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  async function setFilter() {
    try {
      if (createdAtInit.trim() !== '') {
        await validateSingleDates(createdAtInit);
      }
      if (createdAtFinal.trim() !== '') {
        await validateSingleDates(createdAtFinal);
      }
      if (createdAtInit.trim() !== '' && createdAtFinal.trim() !== '') {
        await validateDateDoubleDates(createdAtInit, createdAtFinal);
      }
      if (convertedDateInit.trim() !== '') {
        await validateSingleDates(convertedDateInit);
      }
      if (convertedDateFinal.trim() !== '') {
        await validateSingleDates(convertedDateFinal);
      }
      if (convertedDateInit.trim() !== '' && convertedDateFinal.trim() !== '') {
        await validateDateDoubleDates(convertedDateInit, convertedDateFinal);
      }
      filter({
        currentPage: 1,
        cpf,
        name,
        createdBy,
        convertedDateInit,
        convertedDateFinal,
        createdAtInit,
        createdAtFinal,
        isConverted,
      });
      ref.current.click();
    } catch (err: any) {
      if (err.message === 'Data inicial maior que a data final.') {
        alert('Data inicial maior que a data final.');
      }
      if (err.message === 'Data final inválida.') {
        alert('Data final inválida.');
      }
      if (err.message === 'Data inicial inválida.') {
        alert('Data inicial inválida.');
      }
    }
  }

  function resolveIsConverted(params: null | boolean) {
    if (params !== null) {
      if (params) {
        return 'SIM';
      }
      return 'NAO';
    }
    return 'TODOS';
  }

  function onIsConverted(params: string) {
    if (params === 'SIM') {
      return true;
    }
    if (params === 'NAO') {
      return false;
    }
    return null;
  }

  return (
    <div className="modal fade" id="leadModalFilter" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Filtro</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={ref} />
          </div>
          <div className="modal-body">
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Name"
                    readOnly={false}
                    helper=""
                    error=""
                    value={name}
                    type="text"
                    onChange={(data) => setName(data)}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="cpf"
                    readOnly={false}
                    helper=""
                    error=""
                    value={maskCPF(cpf)}
                    type="text"
                    onChange={(data) => setCpf(unmaskData(data))}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicOption
                    id=""
                    label="Convertido"
                    readOnly={false}
                    helper=""
                    error=""
                    value={resolveIsConverted(isConverted)}
                    onChange={(data) => setIsConverted(onIsConverted(data))}
                  >
                    {['TODOS', 'NAO', 'SIM']}
                  </BasicOption>
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Criado por"
                    readOnly={false}
                    helper=""
                    error=""
                    value={createdBy}
                    type="text"
                    onChange={(data) => setCreatedBy(data)}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data incial de criação"
                    readOnly={false}
                    helper=""
                    error=""
                    value={createdAtInit}
                    type="text"
                    onChange={(data) => setCreatedAtInit(maskDate(data))}
                  />
                </div>
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data final de criação"
                    readOnly={false}
                    helper=""
                    error=""
                    value={createdAtFinal}
                    type="text"
                    onChange={(data) => setCreatedAtFinal(maskDate(data))}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data conversão Inicial"
                    readOnly={false}
                    helper=""
                    error=""
                    value={convertedDateInit}
                    type="text"
                    onChange={(data) => setConvertedDateInit(maskDate(data))}
                  />
                </div>
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data conversão Final"
                    readOnly={false}
                    helper=""
                    error=""
                    value={convertedDateFinal}
                    type="text"
                    onChange={(data) => setConvertedDateFinal(maskDate(data))}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => null}>Remover Filtros</button>
            <button type="button" className="btn btn-primary" onClick={() => setFilter()}>Filtrar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
