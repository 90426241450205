/* eslint-disable max-len */
import React, { createRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import BasicInput from '../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import DefaultLayout from '../../Layout';
import api from '../../config/axios';
import BasicOption from '../../components/BasicOption';
import PriceInput from '../../components/PriceInput';
import { maskDate } from '../../helpers/inputMasks.js';
import TextAreaInput from '../../components/TextAreaInput';
import dataURIToBlob from '../../helpers/fileReader';
import HolderProps from '../../dto/holder';
import ClientPJProps from '../../dto/clientPJ';
import rgEmittent from '../../dto/rgEmittent';
import { statesOfBrazil } from '../../dto/states';
import SwitcherPrimary from '../../components/SwitcherPrimary';

type Document = {
    id: number,
    filename: string,
    url: string,
    createdAt: string,
    updatedAt: string,
}

interface IProfit {
    description: string,
    value: number,
    liquidValue: number,
    incomeDate: string,
    taxUsed: number,
    paymentWay: string,
    contractPf: { payment: { contract: { holder: HolderProps }}} | null
    contractPj: { payment: { contract: { client: ClientPJProps }}} | null
    manualPayment: boolean,
    invoiceIssued: boolean | null
    category: string,
    notes: string,
    documents: Document[]
  }

export default function EditProfit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [allowModifications, setAllowModifications] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [file, setFile] = useState<string[] | null>(null);
  const [filesIdToBeRemoved, setFilesIdToBeRemoved] = useState<number[]>([]);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [fileInputRef] = useState(createRef<any>());
  const [profit, setProfit] = useState<IProfit>({
    description: '',
    value: 0,
    incomeDate: '',
    liquidValue: 0,
    paymentWay: 'PIX',
    taxUsed: 0,
    contractPf: null,
    contractPj: null,
    invoiceIssued: false,
    manualPayment: false,
    category: 'INVESTIMENTO',
    notes: '',
    documents: [],
  });

  async function fetchProfits() {
    try {
      setLoading(true);
      const response = await api.get(`/admin/income/${id}`, token);
      console.log(response.data);
      setProfit(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
    }
  }

  function addSavedFileToExclusion(ctxId: number) {
    const newExclusionIds = [...filesIdToBeRemoved];
    const exclusionId = profit.documents.filter((item) => item.id === ctxId);
    exclusionId.forEach((item) => newExclusionIds.push(item.id));
    setFilesIdToBeRemoved(newExclusionIds);

    const newprofitDocuments = profit.documents.filter((item) => item.id !== ctxId);
    setProfit({ ...profit, documents: newprofitDocuments });
  }

  function enableBtn() {
    if (
      profit.description.length > 0
          && profit.value.toString().length > 0
          && profit.incomeDate.length > 9
    ) {
      return true;
    }
    return false;
  }

  function removeLocalFile(fileCtx: string) {
    const newFiles = file!.filter((item) => item !== fileCtx);
    if (newFiles.length === 0) {
      setFile(null);
    } else {
      setFile(newFiles);
    }
  }

  function splitUrl(url: string) {
    const splitted = url.split('/');
    return splitted[splitted.length - 1];
  }

  function createFormData() {
    const body = new FormData();
    if (file !== null) {
      file.forEach((item) => {
        const blob = dataURIToBlob(item);
        body.append('documents[]', blob);
      });
    }
    body.append('description', profit.description);
    body.append('value', profit.value.toString());
    body.append('category', profit.category);
    body.append('paymentWay', profit.paymentWay);
    body.append('incomeDate', profit.incomeDate);
    body.append('notes', profit.notes);
    body.append('invoiceIssued', profit.invoiceIssued ? 'true' : 'false');

    return body;
  }

  async function tryDeleteProfit() {
    try {
      setLoading(true);
      await api.delete(`/admin/income/${id}`, token);
      setLoading(false);
      navigate(-1);
    } catch (err: any) {
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível excluir entrada', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  function openDeleteModal() {
    setModal({ title: 'Atenção!', children: 'Ao clicar em excluir, essa saída sumirá do sisterma.', actions: [{ label: 'Cancelar', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }, { label: 'Deletar', type: ModalActionType.DANGER, onClick: () => tryDeleteProfit() }] });
  }

  function createObjectURL(data: React.ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();
    reader.readAsDataURL(data.target.files![0]);
    reader.onload = (ctx) => {
      if (ctx.loaded / 1024 < 4000) {
        if (file !== null) {
          const prevFiles = [...file];
          prevFiles.push(ctx.target!.result as string);
          setFile(prevFiles);
        } else {
          setFile([ctx.target!.result as string]);
        }
      } else {
        setModal({ title: 'Ocorreu um erro!', children: 'O arquivo selecionado ultrapassou o limite máximo de 4mb', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }
    };
    fileInputRef.current.value = '';
  }

  async function tryRemoveFile() {
    try {
      const promises = filesIdToBeRemoved.map((item) => api.delete(`/admin/income/file/${item}`, token));
      await Promise.all(promises);
    } catch (err) {
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível excluir arquivo', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  async function saveProfit() {
    try {
      setLoading(true);
      await tryRemoveFile();
      await api.put(`/admin/income/${id}`, createFormData(), token);
      setLoading(false);
      setLoading(false);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      setLoading(false);
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível cadastar entrada', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  function isReadOnly() {
    if (profit.manualPayment) {
      return true;
    }
    return false;
  }

  function resolveProfit(data: string) {
    if (data === 'debit') {
      return 'DÉBITO';
    } if (data === 'credit_1') {
      return 'Crédito 1X';
    } if (data === 'credit_2') {
      return 'Crédito 2X';
    } if (data === 'credit_3') {
      return 'Crédito 3X';
    } if (data === 'credit_4') {
      return 'Crédito 4X';
    } if (data === 'credit_5') {
      return 'Crédito 5X';
    } if (data === 'credit_6') {
      return 'Crédito 6X';
    } if (data === 'credit_7') {
      return 'Crédito 7X';
    } if (data === 'credit_8') {
      return 'Crédito 8X';
    } if (data === 'credit_9') {
      return 'Crédito 9X';
    } if (data === 'credit_10') {
      return 'Crédito 10X';
    } if (data === 'credit_11') {
      return 'Crédito 11X';
    } if (data === 'credit_12') {
      return 'Crédito 12X';
    } if (data === 'credit_13') {
      return 'Crédito 13X';
    } if (data === 'credit_14') {
      return 'Crédito 14X';
    } if (data === 'credit_15') {
      return 'Crédito 15X';
    } if (data === 'credit_16') {
      return 'Crédito 16X';
    } if (data === 'credit_17') {
      return 'Crédito 17X';
    } if (data === 'credit_18') {
      return 'Crédito 18X';
    }
    return data;
  }

  function changeProfit(data: string) {
    if (data === 'Crédito 1X') {
      setProfit({ ...profit, paymentWay: 'credit_1' });
    } else if (data === 'Crédito 2X') {
      setProfit({ ...profit, paymentWay: 'credit_2' });
    } else if (data === 'Crédito 3X') {
      setProfit({ ...profit, paymentWay: 'credit_3' });
    } else if (data === 'Crédito 4X') {
      setProfit({ ...profit, paymentWay: 'credit_4' });
    } else if (data === 'Crédito 5X') {
      setProfit({ ...profit, paymentWay: 'credit_5' });
    } else if (data === 'Crédito 6X') {
      setProfit({ ...profit, paymentWay: 'credit_6' });
    } else if (data === 'Crédito 7X') {
      setProfit({ ...profit, paymentWay: 'credit_7' });
    } else if (data === 'Crédito 8X') {
      setProfit({ ...profit, paymentWay: 'credit_8' });
    } else if (data === 'Crédito 9X') {
      setProfit({ ...profit, paymentWay: 'credit_9' });
    } else if (data === 'Crédito 10X') {
      setProfit({ ...profit, paymentWay: 'credit_10' });
    } else if (data === 'Crédito 11X') {
      setProfit({ ...profit, paymentWay: 'credit_11' });
    } else if (data === 'Crédito 12X') {
      setProfit({ ...profit, paymentWay: 'credit_12' });
    } else if (data === 'Crédito 13X') {
      setProfit({ ...profit, paymentWay: 'credit_13' });
    } else if (data === 'Crédito 14X') {
      setProfit({ ...profit, paymentWay: 'credit_14' });
    } else if (data === 'Crédito 15X') {
      setProfit({ ...profit, paymentWay: 'credit_15' });
    } else if (data === 'Crédito 16X') {
      setProfit({ ...profit, paymentWay: 'credit_16' });
    } else if (data === 'Crédito 17X') {
      setProfit({ ...profit, paymentWay: 'credit_17' });
    } else if (data === 'Crédito 18X') {
      setProfit({ ...profit, paymentWay: 'credit_18' });
    } else if (data === 'DÉBITO') {
      setProfit({ ...profit, paymentWay: 'debit' });
    } else {
      setProfit({ ...profit, paymentWay: data });
    }
  }

  useEffect(() => {
    fetchProfits();
  }, []);

  useEffect(() => {
    console.log(profit);
  }, []);

  return (
    <DefaultLayout
      pageTitle="Financeiro > Editar entrada"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row new-sellers card-bg p-0">
        <section className="row">
          <h1>Cadastro de entradas</h1>
          <div className="col-12 allow-modifications mt-4 mb-4">
            <input
              type="checkbox"
              onChange={() => setAllowModifications((prev) => !prev)}
              checked={allowModifications}
            />
            <p>Habilitar modificações</p>
          </div>
          <div className="col-4">
            <BasicInput
              id="Descrição-input"
              label="Descrição"
              readOnly={isReadOnly()}
              helper="Ex: Compra de papel A4"
              error={profit.description.length < 1 ? 'Campo obrigatório' : ''}
              value={profit.description}
              type="text"
              onChange={(data) => setProfit({ ...profit, description: data })}
            />
          </div>

          <div className="col-4">
            <PriceInput
              id="valor-input"
              label="Valor Bruto"
              readOnly={isReadOnly()}
              helper="Ex: 100.00"
              error={profit.value < 1 ? 'Campo obrigatório' : ''}
              value={profit.value.toString()}
              type="number"
              onChange={(data) => setProfit({ ...profit, value: Number(data) })}
            />
          </div>

          <div className="col-4">
            <PriceInput
              id="valor-liquido-input"
              label="Valor Líquido"
              readOnly={isReadOnly()}
              helper="Ex: 100.00"
              error={profit.liquidValue < 1 ? 'Campo obrigatório' : ''}
              value={profit.liquidValue.toFixed(2).toString()}
              type="number"
              onChange={(data) => setProfit({ ...profit, liquidValue: Number(data) })}
            />
          </div>

          <div className="col-4">
            <BasicOption
              id="categoria-input"
              label="Tipo de receita"
              readOnly={isReadOnly()}
              helper="Ex: Contabilidade"
              error=""
              value={profit.category}
              onChange={(data) => setProfit({ ...profit, category: data })}
            >
              {['INVESTIMENTO', 'RECEBIMENTO', 'OUTROS']}
            </BasicOption>
          </div>
          {
             profit.paymentWay !== 'BAIXA MANUAL' ? (
               <div className="col-4">
                 <BasicOption
                   id="formadeentrda-input"
                   label="Forma da entrada."
                   readOnly={false}
                   helper="Ex: PIX"
                   error=""
                   value={resolveProfit(profit.paymentWay)}
                   onChange={(data) => changeProfit(data)}
                 >
                   {[
                     'Crédito 1X',
                     'Crédito 2X',
                     'Crédito 3X',
                     'Crédito 4X',
                     'Crédito 5X',
                     'Crédito 6X',
                     'Crédito 7X',
                     'Crédito 8X',
                     'Crédito 9X',
                     'Crédito 10X',
                     'Crédito 11X',
                     'Crédito 12X',
                     'Crédito 13X',
                     'Crédito 14X',
                     'Crédito 15X',
                     'Crédito 16X',
                     'Crédito 17X',
                     'Crédito 18X',
                     'PIX',
                     'BOLETO',
                     'DINHEIRO',
                     'TED',
                     'DOC',
                     'DEPOSITO',
                     'DÉBITO',
                   ]}

                 </BasicOption>
               </div>
             ) : (
               <div className="col-4">
                 <BasicInput
                   id="forma-de-entrada"
                   label="Forma da entrada"
                   readOnly
                   helper=""
                   error=""
                   value={profit.paymentWay}
                   type="text"
                   onChange={(data) => null}
                 />
               </div>
             )
           }

          <div className="col-4">
            <BasicInput
              id="datadeprofit-input"
              label="Data de pagamento"
              readOnly={isReadOnly()}
              helper="Ex: 02-02-2022"
              error=""
              value={profit.incomeDate}
              type="text"
              onChange={(data) => setProfit({ ...profit, incomeDate: maskDate(data) })}
            />
          </div>

          <div className="col-4">
            <BasicOption
              id="formadeentrda-input"
              label="NF Emitida"
              readOnly={!allowModifications}
              helper=""
              error=""
              value={profit.invoiceIssued ? 'SIM' : 'NÃO'}
              onChange={(data) => setProfit({ ...profit, invoiceIssued: data === 'SIM' })}
            >
              {[
                'SIM',
                'NÃO',
              ]}
            </BasicOption>
          </div>

          <div className="col-12">
            <TextAreaInput
              id="notes-input"
              label="Observações"
              readOnly={isReadOnly()}
              helper="Ex: Pagamento referente ao contrato XPTO"
              error=""
              value={profit.notes}
              onChange={(data) => setProfit({ ...profit, notes: data })}
            />
          </div>
          {profit.documents.length > 0 && (
          <div className="appends col-12 mt-4 mb-4">
            <p>Anexos salvos</p>
            {profit.documents.map((item) => (
              <div className="append-wrapper">
                <a href={item.url}>{splitUrl(item.url)}</a>
                {allowModifications && (
                <button type="button" className="text-danger" onClick={() => addSavedFileToExclusion(item.id)}>remover</button>
                )}
              </div>
            ))}
          </div>
          )}
          {file !== null && allowModifications && (
          <div className="appends col-12 mt-4 mb-4">
            <p>Novos Anexos</p>
            {file.map((item, i) => (
              <div className="append-wrapper">
                <span>{`Anexo ${i + 1}`}</span>
                <button type="button" className="text-danger" onClick={() => removeLocalFile(item)}>remover</button>
              </div>
            ))}
          </div>
          )}
          {
            allowModifications && (
              <div className="col-12 footer">
                <ButtonTextIcon
                  label="ANEXO"
                  type={BtnType.SECONDARY}
                  icon="add"
                  active
                  onClick={() => fileInputRef.current.click()}
                  ref={null}
                />
                {profit.category !== 'RECEBIMENTO' && (
                <ButtonTextIcon
                  label="Deletar"
                  type={BtnType.DANGER}
                  icon="delete"
                  active
                  onClick={() => openDeleteModal()}
                  ref={null}
                />
                )}

                <ButtonTextIcon
                  label="salvar"
                  type={BtnType.PRIMARY}
                  icon="backup"
                  active={enableBtn()}
                  onClick={() => saveProfit()}
                  ref={null}
                />
                <input
                  type="file"
                  className="d-none"
                  onChange={(data) => createObjectURL(data)}
                  accept=".png, .jpeg, .jpg, .pdf, .xlss, .doc, .docx, .txt, .zip, .rar, .xls"
                  ref={fileInputRef}
                />
              </div>
            )
          }
        </section>
      </div>

      { profit.contractPf && (
      <div className="row products mt-4">
        <div className="col-12 card-bg p-4">
          <div className="row">
            <h1>Dados do Titular</h1>
            <div className="col-4">
              <BasicInput
                id="name-input"
                label="Nome"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.name}
                type="text"
                onChange={() => null}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="cpf-input"
                label="CPF"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.cpf}
                type="text"
                onChange={() => null}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="rg-input"
                label="RG"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.rg}
                type="text"
                onChange={() => null}
              />
            </div>
            <div className="col-4">
              <BasicOption
                id="rg-emittent-input"
                label="RG / Emissor"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.emitent}
                onChange={() => null}
              >
                {rgEmittent}
              </BasicOption>
            </div>
            <div className="col-4">
              <BasicOption
                id="sex-input"
                label="Sexo"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.sex === 'f' ? 'Feminino' : 'Masculino'}
                onChange={() => null}
              >
                {['Masculino', 'Feminino']}
              </BasicOption>
            </div>
            <div className="col-4">
              <BasicInput
                id="birth-input"
                label="DT. Nascimento"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.birthday}
                type="text"
                onChange={() => null}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="address-cep-input"
                label="CEP"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.cpf}
                type="text"
                onChange={() => null}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="address-street-input"
                label="Rua"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.addressStreet}
                type="text"
                onChange={() => null}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="address-number-input"
                label="Número"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.addressNumber}
                type="text"
                onChange={() => null}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="address-complement-input"
                label="Complemento"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.addressComplement}
                type="text"
                onChange={() => null}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="address-neighborhood-input"
                label="Bairro"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.addressNeighborhood}
                type="text"
                onChange={() => null}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="address-city-input"
                label="Cidade"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.addressCity}
                type="text"
                onChange={() => null}
              />
            </div>
            <div className="col-4">
              <BasicOption
                id="address-state-input"
                label="Estado"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.addressState}
                onChange={() => null}
              >
                {statesOfBrazil}
              </BasicOption>
            </div>
            <div className="col-4">
              <BasicInput
                id="phone-input"
                label="Telefone"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.phone}
                type="text"
                onChange={() => null}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="email-input"
                label="email"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.email || ''}
                type="text"
                onChange={() => null}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="instagram-input"
                label="instagram"
                readOnly
                helper=""
                error=""
                value={profit.contractPf.payment.contract.holder.instagram || ''}
                type="text"
                onChange={() => null}
              />
            </div>
          </div>
        </div>
      </div>
      )}

      {profit.contractPj && (
      <div className="row products mt-4">
        <div className="col-12 card-bg p-4">
          <div className="row">
            <h1>Dados do Cliente</h1>
            <div className="col-4">
              <BasicInput
                id="cnpj-input"
                label="CNPJ"
                readOnly
                helper=""
                error=""
                value={profit.contractPj.payment.contract.client.cnpj}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="name-fantasy-input"
                label="Nome Fantasia"
                readOnly
                helper=""
                error=""
                value={profit.contractPj.payment.contract.client.nameFantasy}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="name-input"
                label="Razão social"
                readOnly
                helper=""
                error=""
                value={profit.contractPj.payment.contract.client.name}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="opening-input"
                label="Data de abertura"
                readOnly
                helper=""
                error=""
                value={profit.contractPj.payment.contract.client.openingDate}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-4">
              <BasicOption
                id="tipo-input"
                label="Tipo"
                readOnly
                helper=""
                error=""
                value={profit.contractPj.payment.contract.client.size}
                onChange={(data) => null}
              >
                {[
                  'ME',
                  'MEI',
                  'EPP',
                  'OUTRO',
                ]}
              </BasicOption>
            </div>
            <div className="col-4">
              <BasicInput
                id="address-cep-input"
                label="CEP"
                readOnly
                helper=""
                error=""
                value={profit.contractPj.payment.contract.client.addressZipcode}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="address-street-input"
                label="LOGRADOURO"
                readOnly
                helper=""
                error=""
                value={profit.contractPj.payment.contract.client.addressStreet}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="address-number-input"
                label="Número"
                readOnly
                helper=""
                error=""
                value={profit.contractPj.payment.contract.client.addressNumber}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="address-complement-input"
                label="Complemento"
                readOnly
                helper=""
                error=""
                value={profit.contractPj.payment.contract.client.addressComplement}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="address-neighborhood-input"
                label="Bairro"
                readOnly
                helper=""
                error=""
                value={profit.contractPj.payment.contract.client.addressNeighborhood}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="address-city-input"
                label="Cidade"
                readOnly
                helper=""
                error=""
                value={profit.contractPj.payment.contract.client.addressCity}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-4">
              <BasicOption
                id="address-state-input"
                label="Estado"
                readOnly
                helper=""
                error=""
                value={profit.contractPj.payment.contract.client.addressState}
                onChange={(data) => null}
              >
                {statesOfBrazil}
              </BasicOption>
            </div>
            <div className="col-4">
              <BasicInput
                id="phone-input"
                label="Telefone"
                readOnly
                helper=""
                error=""
                value={profit.contractPj.payment.contract.client.phone}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="email-input"
                label="E-mail"
                readOnly
                helper=""
                error=""
                value={profit.contractPj.payment.contract.client.email}
                onChange={(data) => null}
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
      )}

    </DefaultLayout>
  );
}
