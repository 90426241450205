import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddInput from '../../components/AddInput';
import BasicInput from '../../components/BasicInput';
import BasicOption from '../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalProps } from '../../components/Modal';
import PriceInput from '../../components/PriceInput';
import api from '../../config/axios';
import showApiErrors from '../../helpers/showApiErrors';
import DefaultLayout from '../../Layout';

export default function NewProduct() {
  const navigate = useNavigate();
  const [productNameInput, setProductNameInput] = useState('');
  const [productPriceInput, setProductPriceInput] = useState(0);
  const [pricePerDependent, setPricePerDependent] = useState(0);
  const [productBenefitsInput, setProductsBenefitsInput] = useState('');
  const [category, setCategory] = useState('GOLD');
  const [modality, setModality] = useState('INDIVIDUAL');
  const [allProductsBenefits, setAllProductsBenefits] = useState<string[]>([]);
  const [showToastError, setShowToastError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  function enableSaveButton() {
    if (
      productNameInput.length > 0
          && allProductsBenefits.length > 0
    ) {
      return true;
    }
    return false;
  }

  function addBenefits(data: string[], toAdd: string) {
    if (toAdd !== '') {
      const benefits = [...data];
      benefits.push(toAdd);
      setAllProductsBenefits(benefits);
      setProductsBenefitsInput('');
    }
  }

  function removeBenefits(data: string[], toRemove: string) {
    const benefits = data.filter((item) => item !== toRemove);
    setAllProductsBenefits(benefits);
  }

  function aggregateBenefits() {
    const benefits = [...allProductsBenefits];
    let formattedBenefits: string = '';
    benefits.forEach((item) => {
      formattedBenefits += `${item};`;
    });

    return formattedBenefits;
  }

  async function saveProduct() {
    try {
      setLoading(true);
      await api.post('/admin/product', {
        name: productNameInput.trim(),
        benefits: aggregateBenefits(),
        pricePerHolder: productPriceInput,
        pricePerDependent,
        modality,
        category,
        isActive: true,
      }, token);
      setLoading(false);
      navigate('/produtos');
    } catch (err: any) {
      if (typeof err.response !== 'undefined') {
        if (err.response.status === 422) {
          setModal(showApiErrors(setModal, err.response.data.errors));
        }
      } else {
        setShowToastError(true);
        setTimeout(() => {
          setShowToastError(false);
        }, 3000);
      }
      console.log(err.response);
      setLoading(false);
    }
  }
  return (
    <DefaultLayout
      pageTitle="Produtos > Adicionar"
      loading={loading}
      showToastSuccess={false}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={null}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products d-flex- align-items-center justify-content-center card-bg p-0">
        <section className="row">
          <h1>Dados do produto</h1>
          <div className="col-12">
            <BasicInput
              id="product-name"
              label="Nome do produto"
              readOnly={false}
              helper="Ex: Produto PF Individual"
              error={productNameInput.length < 1 ? 'Campo obrigatório' : ''}
              value={productNameInput}
              onChange={(data) => setProductNameInput(data)}
              type="text"
            />
          </div>
          <div className="col-6">
            <PriceInput
              id="product-price"
              label="Valor por Titular"
              readOnly={false}
              helper="Decimais sep. por vírgula Ex: 21,90"
              error=""
              value={productPriceInput.toString()}
              onChange={(data) => setProductPriceInput(Number(data))}
              type="number"
            />
          </div>
          <div className="col-6">
            <PriceInput
              id="product-dependent-price"
              label="Valor por Dependente"
              readOnly={false}
              helper="Decimais sep. por vírgula Ex: 21,90"
              error=""
              value={pricePerDependent.toString()}
              onChange={(data) => setPricePerDependent(Number(data))}
              type="number"
            />
          </div>
          <div className="col-6">
            <BasicOption
              id="product-category"
              label="Categoria"
              readOnly={false}
              helper=""
              error=""
              value={category}
              onChange={(data) => setCategory(data)}
            >
              {['GOLD', 'BLACK', 'PLATINUM']}
            </BasicOption>
          </div>
          <div className="col-6">
            <BasicOption
              id="product-modality"
              label="Modalidade"
              readOnly={false}
              helper=""
              error=""
              value={modality === 'ASSOCIACOES' ? 'ASSOCIAÇÕES' : modality}
              onChange={(data) => {
                if (data === 'ASSOCIAÇÕES') {
                  setModality('ASSOCIACOES');
                } else {
                  setModality(data);
                }
              }}
            >
              {['INDIVIDUAL', 'EMPRESARIAL', 'ASSOCIAÇÕES', 'SINDICATOS']}
            </BasicOption>
          </div>
          <div className="col-12">
            <AddInput
              id="product-benefits"
              label="Benefícios"
              readOnly={false}
              helper="Ex: Odontologia"
              error={allProductsBenefits.length < 1 ? 'Adicione pelo menos 1 benefício. Ex: Odontologia' : ''}
              value={productBenefitsInput}
              onChange={(data) => setProductsBenefitsInput(data)}
              onAdd={() => addBenefits(allProductsBenefits, productBenefitsInput)}
              type="text"
            />
          </div>
          <div className="col-12">
            <div className="benefits-wrapper">
              {allProductsBenefits.map((item) => (
                <button type="button" onClick={() => removeBenefits(allProductsBenefits, item)}>
                  <p>{item}</p>
                  <span className="material-icons-round">
                    cancel
                  </span>
                </button>
              ))}
            </div>
          </div>
          <div className="col-12 footer mt-4">
            <ButtonTextIcon
              active={enableSaveButton()}
              label="salvar"
              type={BtnType.PRIMARY}
              icon="backup"
              onClick={() => saveProduct()}
              ref={null}
            />
          </div>
        </section>
      </div>
    </DefaultLayout>
  );
}
