/* eslint-disable max-len */
import React, { useState } from 'react';
import download from 'downloadjs';
import api from '../../../config/axios';

type DataProps = {
    total_clients: string
    total_de_dependentes_pf_inseridos_no_dia: string
    total_de_dependentes_pj_inseridos_no_dia: string
    total_de_titulares_pf_inseridos_no_dia: string
    total_de_titulares_pj_inseridos_no_dia: string
    total_de_vidas_bloqueadas_permanentemente: string
    total_de_vidas_bloqueadas_temporariamente: string
    total_de_vidas_inseridas_no_dia: string
    total_dependentes_pf_bloqueado_permanentemente: string
    total_dependentes_pf_bloqueado_temporariamente: string
    total_dependentes_pj_bloqueado_permanentemente: string
    total_dependentes_pj_bloqueado_temporariamente: string
    total_dependents: string
    total_holders: string
    total_lifes: string
    total_titular_pf_bloqueado_permanentemente: string
    total_titular_pf_bloqueado_temporariamente: string
    total_titular_pj_bloqueado_permanentemente: string
    total_titular_pj_bloqueado_temporariamente: string
    total_vidas_pf_bloqueado_permanentemente: string
    total_vidas_pf_bloqueado_temporariamente: string
    total_vidas_pj_bloqueado_permanentemente: string
    total_vidas_pj_bloqueado_temporariamente: string
    total_titulares_ativos_pf: string
    total_dependentes_ativos_pf: string
    total_titulares_ativos_pj: string
    total_dependentes_ativos_pj: string
}

export interface IPrimaryModalContent {
    id: string,
    finalDate: string,
    data: DataProps,
}

export default function TicketSellModal({
  id, finalDate, data,
}: IPrimaryModalContent) {
  const [loading, setLoading] = useState(false);
  async function downloadToExcel() {
    try {
      setLoading(true);
      const response = await api.post('admin/reports/byperiod/logbookexcell', {
        ...data,
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, `Diario-de-bordo-${finalDate}.xlsx`);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }
  return (
    <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Diario de bordo</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          {loading && <h5 className="text-center">Carregando...</h5>}
          {!loading && (
            <>
              <div className="modal-body modal-report">
                <div className="d-flex w-100 justify-content-center">
                  <p className="fw-bold">
                    {finalDate}
                  </p>
                </div>
                <div className="bg-light mb-4 w-100 p4 text-center text-black d-flex align-items-center justify-content-center mt-4" style={{ height: 24 }}>
                  <p className="p-0 fw-bold">
                    Usuários inseridos na data selecionada
                    {' '}
                    (
                    {finalDate}
                    )
                  </p>
                </div>
                <p className="p-2 text-muted text-center text-black mb-2">Esse relatório só é válido para datas de Fev/2023 em diante</p>
                <div className="ticket-wrapper">
                  <div className="ticket-medio-container bg-light">
                    <p className="text-center text-black">Titulares PF</p>
                    <p className="text-center text-black fw-bold">
                      {' '}
                      {data.total_de_titulares_pf_inseridos_no_dia}
                    </p>
                  </div>
                  <div className="ticket-medio-container bg-light">
                    <p className="text-center text-black">Dependentes PF</p>
                    <p className="text-center text-black">
                      {data.total_de_dependentes_pf_inseridos_no_dia}
                    </p>
                  </div>
                  <div className="ticket-medio-container bg-light">
                    <p className="text-center text-black">Titulares PJ</p>
                    <p className="text-center text-black">
                      {' '}
                      {data.total_de_titulares_pj_inseridos_no_dia}
                    </p>
                  </div>
                  <div className="ticket-medio-container bg-light">
                    <p className="text-center text-black">Dependentes PJ</p>
                    <p className="text-center text-black">
                      {data.total_de_dependentes_pj_inseridos_no_dia}
                    </p>
                  </div>
                  <div className="ticket-medio-container bg-light">
                    <p className="text-center text-black">Total</p>
                    <p className="text-center text-black">
                      {data.total_de_vidas_inseridas_no_dia}
                    </p>
                  </div>
                </div>

                <div className="bg-light mb-4 w-100 p4 text-center text-black d-flex align-items-center justify-content-center mt-4" style={{ height: 24 }}>
                  <p className="p-0 fw-bold">
                    Estatísticas do início até a data informada
                    {' '}
                    (
                    {finalDate}
                    )
                  </p>
                </div>
                <div className="ticket-wrapper">
                  <div className="ticket-medio-container bg-light">
                    <p className="text-center text-black">Cliente PJ ativos</p>
                    <p className="text-center text-black">
                      {' '}
                      {data.total_clients}
                    </p>
                  </div>
                  <div className="ticket-medio-container bg-light">
                    <p className="text-center text-black">Associados Ativos</p>
                    <p className="text-center text-black">
                      {data.total_lifes}
                    </p>
                  </div>
                  <div className="ticket-medio-container bg-light">
                    <p className="text-center text-black">Titulares ativos</p>
                    <p className="text-center text-black">
                      {' '}
                      {data.total_holders}
                    </p>
                  </div>
                  <div className="ticket-medio-container bg-light">
                    <p className="text-center text-black">Dependentes Ativos</p>
                    <p className="text-center text-black">
                      {data.total_dependents}
                    </p>
                  </div>
                  <div className="ticket-medio-container bg-light">
                    <p className="text-center text-black">Associados Bloq. Temporariamente</p>
                    <p className="text-center text-black">
                      {data.total_de_vidas_bloqueadas_temporariamente}
                    </p>
                  </div>
                  <div className="ticket-medio-container bg-light">
                    <p className="text-center text-black">Associados cancelados</p>
                    <p className="text-center text-black">
                      {data.total_de_vidas_bloqueadas_permanentemente}
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-light mb-4 w-100 p4 text-center text-black d-flex align-items-center justify-content-center mt-4" style={{ height: 24 }}>
                <p className="p-0 fw-bold">Estatísticas até a data informada - Ativos detalhado</p>
              </div>
              <div className="ticket-wrapper">
                <div className="ticket-medio-container bg-light">
                  <p className="text-center text-black">Titulares PF</p>
                  <p className="text-center text-black">
                    {data.total_titulares_ativos_pf}
                  </p>
                </div>
                <div className="ticket-medio-container bg-light">
                  <p className="text-center text-black">Dependentes PF</p>
                  <p className="text-center text-black">
                    {data.total_dependentes_ativos_pf}
                  </p>
                </div>
                <div className="ticket-medio-container bg-light">
                  <p className="text-center text-black">Titulares PJ</p>
                  <p className="text-center text-black">
                    {data.total_titulares_ativos_pj}
                  </p>
                </div>
                <div className="ticket-medio-container bg-light">
                  <p className="text-center text-black">Dependentes PJ</p>
                  <p className="text-center text-black">
                    {data.total_dependentes_ativos_pj}
                  </p>
                </div>
              </div>
              <div className="bg-light mb-4 w-100 p4 text-center text-black d-flex align-items-center justify-content-center mt-4" style={{ height: 24 }}>
                <p className="p-0 fw-bold">Estatísticas até a data informada - Bloqueio temporario detalhado</p>
              </div>
              <div className="ticket-wrapper">
                <div className="ticket-medio-container bg-light">
                  <p className="text-center text-black">Titulares PF</p>
                  <p className="text-center text-black">
                    {data.total_titular_pf_bloqueado_temporariamente}
                  </p>
                </div>
                <div className="ticket-medio-container bg-light">
                  <p className="text-center text-black">Dependentes PF</p>
                  <p className="text-center text-black">
                    {data.total_dependentes_pf_bloqueado_temporariamente}
                  </p>
                </div>
                <div className="ticket-medio-container bg-light">
                  <p className="text-center text-black">Titulares PJ</p>
                  <p className="text-center text-black">
                    {data.total_titular_pj_bloqueado_temporariamente}
                  </p>
                </div>
                <div className="ticket-medio-container bg-light">
                  <p className="text-center text-black">Dependentes PJ</p>
                  <p className="text-center text-black">
                    {data.total_dependentes_pj_bloqueado_temporariamente}
                  </p>
                </div>
              </div>
              <div className="bg-light mb-4 w-100 p4 text-center text-black d-flex align-items-center justify-content-center mt-4" style={{ height: 24 }}>
                <p className="p-0 fw-bold">Estatísticas até a data informada - Cancelamento detalhado</p>
              </div>
              <div className="ticket-wrapper">
                <div className="ticket-medio-container bg-light">
                  <p className="text-center text-black">Titulares PF</p>
                  <p className="text-center text-black">
                    {data.total_titular_pf_bloqueado_permanentemente}
                  </p>
                </div>
                <div className="ticket-medio-container bg-light">
                  <p className="text-center text-black">Dependentes PF</p>
                  <p className="text-center text-black">
                    {data.total_dependentes_pf_bloqueado_permanentemente}
                  </p>
                </div>
                <div className="ticket-medio-container bg-light">
                  <p className="text-center text-black">Titulares PJ</p>
                  <p className="text-center text-black">
                    {data.total_titular_pj_bloqueado_permanentemente}
                  </p>
                </div>
                <div className="ticket-medio-container bg-light">
                  <p className="text-center text-black">Dependentes PJ</p>
                  <p className="text-center text-black">
                    {data.total_dependentes_pj_bloqueado_permanentemente}
                  </p>
                </div>
              </div>

            </>
          )}

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => downloadToExcel()}
            >
              Baixar para excel

            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => setLoading(false)}
            >
              Fechar

            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
