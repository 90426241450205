import axios, { AxiosResponse } from 'axios';
import React from 'react';
import api from '../../config/axios';
import ClientPJprops from '../../dto/clientPJ';

/* eslint-disable no-shadow */
enum WsPorte {
    EPP = 'EMPRESA DE PEQUENO PORTE',
    ME = 'MICRO EMPRESA'
}

interface ClientProps {
        id: number,
        cnpj: string,
        openingDate: string,
        name: string,
        nameFantasy: string,
        size: string,
        addressStreet: string,
        addressNumber: string,
        addressComplement: string,
        addressNeighborhood: string,
        addressCity: string,
        addressState: string,
        addressZipcode: string,
        email: string,
        phone: string,
        password: string,
        isActive: boolean,
}

interface FetchCNPJWSProps {
    complemento: string;
    nome: string;
    fantasia: string;
    uf: string;
    telefone: string;
    email: string;
    logradouro: string;
    numero: string;
    cep: string;
    abertura: string;
    municipio: string;
    porte: string;
    bairro: string;
}

function capitalizeFirstLetter(data: string) {
  if (data.length > 0) {
    const separateWord = data.toLowerCase().split(' ');
    for (let i = 0; i < separateWord.length; i += 1) {
      separateWord[i] = separateWord[i].charAt(0).toUpperCase()
       + separateWord[i].substring(1);
    }
    return separateWord.join(' ');
  }
  return '';
}

function formatEnterpriseSize(data: string) {
  if (data === 'MICRO EMPRESA') {
    return 'ME';
  }
  if (data === 'EMPRESA DE PEQUENO PORTE') {
    return 'EPP';
  }
  return 'OUTRO';
}

function formatDate(data: string) {
  if (data.length > 0) {
    const splitted = data.split('/');
    return `${splitted[0]}-${splitted[1]}-${splitted[2]}`;
  }
  return '';
}

export default async function fetchCNPJ(
  cnpj: string,
) {
  try {
    const response = await api.get(`/receitaws/${cnpj}`);
    if (typeof response.data.uf !== 'undefined') {
      const clientData: FetchCNPJWSProps = response.data;
      return {
        name: capitalizeFirstLetter(clientData.nome),
        nameFantasy: capitalizeFirstLetter(clientData.fantasia),
        addressComplement: capitalizeFirstLetter(clientData.complemento),
        addressState: clientData.uf,
        addressCity: capitalizeFirstLetter(clientData.municipio),
        addressStreet: capitalizeFirstLetter(clientData.logradouro),
        addressNeighborhood: (capitalizeFirstLetter(clientData.bairro)),
        addressNumber: clientData.numero,
        addressZipcode: clientData.cep,
        size: formatEnterpriseSize(clientData.porte),
        openingDate: formatDate(clientData.abertura),
        email: clientData.email,
        phone: clientData.telefone,
      } as ClientProps;
    }
    return null;
  } catch (err: any) {
    console.log('Error at fetch CNPJ', err.response);
    return null;
  }
}
