import { DateTime } from 'luxon';
import React, { useState } from 'react';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import { maskDate } from '../../../helpers/inputMasks.js';
import { convertCardPaymentMethodFromApi, normalizeToApiCardPaymentWays } from '../../../helpers/showCardMethods';
import {
  flagProps, GatewayProps, methodProps, StatusProps, TableFilters,
} from './Table';

const gateways: GatewayProps[] = [
  {
    id: 1,
    name: 'PagAzul Presencial',
  },
  {
    id: 2,
    name: 'REDESANTA',
  },
  {
    id: 3,
    name: 'PagAzul WEB',
  },
  {
    id: 4,
    name: 'CIELO',
  },
  {
    id: 5,
    name: 'GALAXPAY',
  },
];

const methods = [
  'PIX',
  'DINHEIRO',
  'BOLETO',
  'TED',
  'DOC',
  'DÉBITO',
  'CRÉDITO 1X',
  'CRÉDITO 2X',
  'CRÉDITO 3X',
  'CRÉDITO 4X',
  'CRÉDITO 5X',
  'CRÉDITO 6X',
  'CRÉDITO 7X',
  'CRÉDITO 8X',
  'CRÉDITO 9X',
  'CRÉDITO 10X',
  'CRÉDITO 11X',
  'CRÉDITO 12X',
  'CRÉDITO 13X',
  'CRÉDITO 14X ',
  'CRÉDITO 15X',
  'CRÉDITO 16X',
  'CRÉDITO 17X',
  'CRÉDITO 18X',
];

const flags: flagProps[] = [
  'MASTERCARD', 'VISA', 'ELO', 'AMEX', 'HIPERCARD',
];

type FilterProps = {
    dueInitialDate: string;
    dueFinalDate: string;
    setDueInitialDate: (arg0: string) => void;
    setDueFinalDate: (arg0: string) => void;
    projectionInitialDate: string;
    projectionFinalDate: string;
    setProjectionInitialDate: (arg0: string) => void;
    setProjectionFinalDate: (arg0: string) => void;
    gateway: GatewayProps | null,
    setGatewayProps: (arg0: GatewayProps | null) => void;
    method: methodProps | null;
    setMethodProps: (arg0: methodProps | null) => void;
    flag: flagProps | null,
    setFlagProps: (arg0: flagProps | null) => void;
    removeAllFilters: () => void;
    subscription: boolean,
    setSubscription: (arg0: boolean) => void;
    installmentNum: string | null
    setInstallmentNum: (arg0: string | null) => void;
    status: StatusProps,
    setStatus: (arg0: StatusProps) => void
    filter: (arg: TableFilters) => void;
    paymentDateInitial: string;
    setPaymentDateInitial: (arg0: string) => void;
    paymentDateFinal: string;
    setPaymentDateFinal: (arg0: string) => void;
    lastDigit: null | string;
    setLastDigit: (arg0: string | null) => void;
    minValue: null | number,
    setMinValue: (arg: number | null) => void;
    maxValue: null | number
    setMaxValue: (arg: number | null) => void;
}

export default function InstallmentsReportModalFilter({
  dueFinalDate,
  dueInitialDate,
  setDueFinalDate,
  setDueInitialDate,
  projectionFinalDate,
  projectionInitialDate,
  setProjectionFinalDate,
  setProjectionInitialDate,
  removeAllFilters,
  gateway,
  setGatewayProps,
  filter,
  method,
  setMethodProps,
  flag,
  setFlagProps,
  subscription,
  setSubscription,
  installmentNum,
  setInstallmentNum,
  status,
  setStatus,
  paymentDateFinal,
  paymentDateInitial,
  setPaymentDateFinal,
  setPaymentDateInitial,
  lastDigit,
  setLastDigit,
  minValue,
  setMinValue,
  maxValue,
  setMaxValue,
}: FilterProps) {
  const [ref] = useState(React.createRef<any>());
  function setGateway(name: string) {
    if (name === 'TODOS') {
      setGatewayProps(null);
    } else {
      const filtered = gateways.filter((item) => item.name === name);
      setGatewayProps(filtered[0]);
    }
  }

  function setMethod(name: string) {
    if (name === 'TODOS') {
      setMethodProps(null);
    } else {
      setMethodProps(normalizeToApiCardPaymentWays(name) as methodProps);
    }
  }

  function setFlag(name: string) {
    if (name === 'TODAS') {
      setFlagProps(null);
    } else if (name === 'OUTRA') {
      setFlagProps('OTHER');
    } else {
      setFlagProps(name as flagProps);
    }
  }

  function showFlag(flagCtx: string | null) {
    if (flagCtx === 'OTHER') {
      return 'OUTRA';
    }
    if (!flagCtx) {
      return 'TODAS';
    }
    return flagCtx!;
  }

  async function validateDateDoubleDates(dateInitial: string, dateFinal: string) {
    try {
      const initialDateAsDateTime = DateTime.fromFormat(dateInitial, 'dd-MM-yyyy');
      const finalDateAsDateTime = DateTime.fromFormat(dateFinal, 'dd-MM-yyyy');
      if (!initialDateAsDateTime.isValid) {
        throw new Error('Data inicial inválida.');
      }
      if (!finalDateAsDateTime.isValid) {
        throw new Error('Data final inválida.');
      }

      if (initialDateAsDateTime > finalDateAsDateTime) {
        throw new Error('Data inicial maior que a data final.');
      }
      return {
        initialDateAsDateTime,
        finalDateAsDateTime,
      };
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  async function validateSingleDates(date: string) {
    try {
      const initialDateAsDateTime = DateTime.fromFormat(date, 'dd-MM-yyyy');
      if (!initialDateAsDateTime.isValid) {
        throw new Error('Data inicial inválida.');
      }

      return {
        initialDateAsDateTime,
      };
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  async function setFilter() {
    try {
      if (dueInitialDate.trim() !== '') {
        await validateSingleDates(dueInitialDate);
      }
      if (dueFinalDate.trim() !== '') {
        await validateSingleDates(dueFinalDate);
      }
      if (projectionInitialDate.trim() !== '') {
        await validateSingleDates(projectionInitialDate);
      }
      if (projectionFinalDate.trim() !== '') {
        await validateSingleDates(projectionFinalDate);
      }

      if (paymentDateInitial.trim() !== '') {
        await validateSingleDates(paymentDateInitial);
      }

      if (paymentDateFinal.trim() !== '') {
        await validateSingleDates(paymentDateFinal);
      }

      if (paymentDateInitial.trim() !== '' && paymentDateFinal.trim() !== '') {
        await validateDateDoubleDates(paymentDateInitial, paymentDateFinal);
      }

      if (projectionFinalDate.trim() !== '' && projectionInitialDate.trim() !== '') {
        await validateDateDoubleDates(projectionInitialDate, projectionFinalDate);
      }
      if (dueFinalDate.trim() !== '' && dueInitialDate.trim() !== '') {
        await validateDateDoubleDates(dueInitialDate, dueFinalDate);
      }
      filter({
        currentPage: 1,
        dueDtInitial: dueInitialDate,
        dueDtFinal: dueFinalDate,
        previsionDtInitial: projectionInitialDate,
        previsionDtFinal: projectionFinalDate,
        paymentDateInitial,
        paymentDateFinal,
        status,
        minValue,
        maxValue,
        subscription,
        gateway: gateway ? gateway.id : null,
        method,
        flag,
        installmentNum: installmentNum ? Number(installmentNum) : null,
        lastDigit,
      });
      ref.current.click();
    } catch (err: any) {
      if (err.message === 'Data inicial maior que a data final.') {
        alert('Data inicial maior que a data final.');
      }
      if (err.message === 'Data final inválida.') {
        alert('Data final inválida.');
      }
      if (err.message === 'Data inicial inválida.') {
        alert('Data inicial inválida.');
      }
    }
  }

  function resolveInstallmentNum(data: string) {
    if (data === 'TODAS') {
      setInstallmentNum(null);
    } else {
      setInstallmentNum(data);
    }
  }

  function setResolvePaymentStatus(data: string) {
    if (data === 'FORA DO PRAZO') {
      setStatus('EXPIRED');
    } else if (data === 'NO PRAZO') {
      setStatus('ONTIME');
    } else if (data === 'EXPIRADO') {
      setStatus('EXPIRED');
    } else if (data === 'AUTORIZADO') {
      setStatus('OTHER');
    } else if (data === 'ESTORNADO') {
      setStatus('REVERSED');
    } else {
      setStatus('PAID');
    }
  }

  function changeLastDigits(data: string) {
    if (data.length === 0) {
      setLastDigit(null);
    } else if (data.length < 5) {
      setLastDigit(data);
    }
  }

  function resolvePaymentStatus(data: string) {
    if (data === 'UNPAID') {
      return 'FORA DO PRAZO';
    } if (data === 'ONTIME') {
      return 'NO PRAZO';
    } if (data === 'EXPIRED') {
      return 'EXPIRADO';
    } if (data === 'OTHER') {
      return 'AUTORIZADO';
    } if (data === 'PAID') {
      return 'PAGO';
    }
    return 'ESTORNADO';
  }

  return (
    <div className="modal fade" id="installmentsReportFilter" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Filtro</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={ref} />
          </div>
          <div className="modal-body">
            <div className="filter-date-input-wrapper" style={{ padding: '8px 0px' }}>
              <div className="" style={{ paddingRight: 4 }}>
                <BasicOption
                  id=""
                  label="NÚMERO DA PARCELA"
                  readOnly={false}
                  helper=""
                  error=""
                  value={installmentNum || 'TODAS'}
                  onChange={(data) => resolveInstallmentNum(data)}
                >
                  {['TODAS', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                </BasicOption>
              </div>
            </div>
            <div className="filter-date-input-wrapper" style={{ padding: '8px 0px' }}>
              <div className="" style={{ paddingRight: 4 }}>
                <BasicOption
                  id=""
                  label="STATUS"
                  readOnly={false}
                  helper=""
                  error=""
                  value={resolvePaymentStatus(status)}
                  onChange={(data) => setResolvePaymentStatus(data)}
                >
                  {['NO PRAZO', 'ESTORNADO', 'AUTORIZADO', 'EXPIRADO', 'PAGO']}
                </BasicOption>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper">
                <div className="" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data vencimento inicial"
                    readOnly={false}
                    helper=""
                    error=""
                    value={dueInitialDate}
                    type="text"
                    onChange={(data) => setDueInitialDate(maskDate(data))}
                  />
                </div>
                <div className="" style={{ paddingLeft: 4 }}>
                  <BasicInput
                    id=""
                    label="Data vencimento final"
                    readOnly={false}
                    helper=""
                    error=""
                    value={dueFinalDate}
                    type="text"
                    onChange={(data) => setDueFinalDate(maskDate(data))}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper">
                <div className="" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data previsão inicial"
                    readOnly={false}
                    helper=""
                    error=""
                    value={projectionInitialDate}
                    type="text"
                    onChange={(data) => setProjectionInitialDate(maskDate(data))}
                  />
                </div>
                <div className="" style={{ paddingLeft: 4 }}>
                  <BasicInput
                    id=""
                    label="Data previsão final"
                    readOnly={false}
                    helper=""
                    error=""
                    value={projectionFinalDate}
                    type="text"
                    onChange={(data) => setProjectionFinalDate(maskDate(data))}
                  />
                </div>
              </div>
            </div>

            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper">
                <div className="" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data pagamento inicial"
                    readOnly={false}
                    helper=""
                    error=""
                    value={paymentDateInitial}
                    type="text"
                    onChange={(data) => setPaymentDateInitial(maskDate(data))}
                  />
                </div>
                <div className="" style={{ paddingLeft: 4 }}>
                  <BasicInput
                    id=""
                    label="Data pagamento final"
                    readOnly={false}
                    helper=""
                    error=""
                    value={paymentDateFinal}
                    type="text"
                    onChange={(data) => setPaymentDateFinal(maskDate(data))}
                  />
                </div>
              </div>
            </div>

            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper">
                <BasicInput
                  id=""
                  label="Últimos 4 dígitos"
                  readOnly={false}
                  helper=""
                  error=""
                  value={lastDigit || ''}
                  type="text"
                  onChange={(data) => changeLastDigits(data)}
                />
              </div>
            </div>

            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper">
                <BasicInput
                  id=""
                  label="Valor min"
                  readOnly={false}
                  helper=""
                  error=""
                  value={minValue ? minValue.toString() : ''}
                  type="number"
                  onChange={(data) => {
                    if (data === '') {
                      setMinValue(null);
                    } else {
                      setMinValue(Number(data));
                    }
                  }}
                />
              </div>
            </div>

            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper">
                <BasicInput
                  id=""
                  label="Valor max."
                  readOnly={false}
                  helper=""
                  error=""
                  value={maxValue ? maxValue.toString() : ''}
                  type="number"
                  onChange={(data) => {
                    if (data === '') {
                      setMaxValue(null);
                    } else {
                      setMaxValue(Number(data));
                    }
                  }}
                />
              </div>
            </div>

            <div className="filter-date-input-wrapper" style={{ padding: '8px 0px' }}>
              <div className="" style={{ paddingRight: 4 }}>
                <BasicOption
                  id=""
                  label="Gateway"
                  readOnly={false}
                  helper=""
                  error=""
                  value={gateway ? gateway.name : 'TODOS'}
                  onChange={(data) => setGateway(data)}
                >
                  {['TODOS', ...gateways.map((item) => item.name)]}
                </BasicOption>
              </div>
            </div>

            <div className="filter-date-input-wrapper" style={{ padding: '8px 0px' }}>
              <div className="" style={{ paddingRight: 4 }}>
                <BasicOption
                  id=""
                  label="Método"
                  readOnly={false}
                  helper=""
                  error=""
                  value={method ? convertCardPaymentMethodFromApi(method).toUpperCase() : 'TODOS'}
                  onChange={(data) => setMethod(data)}
                >
                  {[
                    'TODOS',
                    ...methods,
                  ]}
                </BasicOption>
              </div>
            </div>
            <div className="filter-date-input-wrapper" style={{ padding: '8px 0px' }}>
              <div className="" style={{ paddingRight: 4 }}>
                <BasicOption
                  id=""
                  label="Bandeira"
                  readOnly={false}
                  helper=""
                  error=""
                  value={showFlag(flag)}
                  onChange={(data) => setFlag(data)}
                >
                  {['TODAS', 'OUTRA', ...flags] }
                </BasicOption>
              </div>
            </div>
            <div className="filter-date-input-wrapper" style={{ padding: '8px 0px' }}>
              <div className="" style={{ paddingRight: 4 }}>
                <BasicOption
                  id=""
                  label="SOMENTE RECORRENCIA"
                  readOnly={false}
                  helper=""
                  error=""
                  value={subscription ? 'SIM' : 'NÃO'}
                  onChange={(data) => {
                    if (data === 'SIM') {
                      setSubscription(true);
                    } else {
                      setSubscription(false);
                    }
                  }}
                >
                  {['NÃO', 'SIM'] }
                </BasicOption>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            <button type="button" className="btn btn-primary" onClick={() => setFilter()}>Filtrar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
