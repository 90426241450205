/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { storeType } from '../store';
import { logOut } from '../store/actions/loginAction';

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name, avatar } = useSelector(({ adminReducer }: storeType) => adminReducer);
  function tryLogout() {
    localStorage.removeItem('adminToken');
    logOut(dispatch);
  }

  return (
    <div className="header card-bg">
      <nav className="d-flex align-items-center justify-content-end h-100">
        <div className="menu-link">
          <span className="material-icons-round">
            notifications_off
          </span>
        </div>
        <div className="avatar-wrapper">
          <div className="online" />
          <p>
            {name}
            {' '}
            <br />
            <span>CEO</span>
          </p>
          {avatar && avatar.fileUrl ? (
            <img src={avatar.fileUrl} alt="" />

          ) : (
            <img src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-2/img/13-small.d796bffd.png" alt="" />

          )}
          <div className="avatar-menu-hidden">
            <div className="avatar-menu-hidden-item-wrapper" onClick={() => navigate('/perfil')}>
              <span className="material-icons-round">
                account_circle
              </span>
              <p>Meu Perfil</p>
            </div>
            <div className="avatar-menu-hidden-item-wrapper" onClick={() => tryLogout()}>
              <span className="material-icons-round">
                logout
              </span>
              <p>Sair</p>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
