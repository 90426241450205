/* eslint-disable max-len */
import React, { useState } from 'react';
import * as EmailValidator from 'email-validator';
import AddInput from '../../../components/AddInput';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import rgEmittent from '../../../dto/rgEmittent';
import { statesOfBrazil } from '../../../dto/states';
import CEPFinder from '../../../helpers/findAddressByCep';
import {
  maskCep, maskCPF, maskCPForCNPJ, maskDate, maskNumber, maskPhone, unmaskData,
} from '../../../helpers/inputMasks.js';
import { DependentDTO, HolderDTO } from './CreateLead';
import TextAreaInput from '../../../components/TextAreaInput';

type EditDependentModalModalProps = {
    updateDependent: (arg: DependentDTO) => void,
    setLoading: (arg: boolean) => void,
    holder: HolderDTO,
    pDependent: DependentDTO,
    id: string,
}

export default function EditDependentModalModal({
  updateDependent, holder, setLoading, pDependent, id,
}: EditDependentModalModalProps) {
  const [dependent, setDependent] = useState<DependentDTO>({ ...pDependent });

  function changeDependentRole(data: string) {
    if (data === 'Cônjuge') {
      setDependent({ ...dependent, role: 'spouse' });
      dependent.role = 'spouse';
    } else if (data === 'Filho(a)') {
      setDependent({ ...dependent, role: 'son' });
    } else {
      setDependent({ ...dependent, role: 'other' });
    }
  }

  function showDependentRole(data: string) {
    if (data === 'spouse') {
      return 'Cônjuge';
    } if (data === 'son') {
      return 'Filho(a)';
    }
    return 'Outro';
  }

  function replicateHolderAdress() {
    setDependent({
      ...dependent,
      addressStreet: holder.addressStreet,
      addressNumber: holder.addressNumber,
      addressComplement: holder.addressComplement,
      addressNeighborhood: holder.addressNeighborhood,
      addressCity: holder.addressCity,
      addressState: holder.addressState,
      addressZipcode: holder.addressZipcode,
      addressIbge: holder.addressZipcode,
    });
  }

  async function changeAddressByCep() {
    try {
      setLoading(true);
      const findCep = await new CEPFinder(unmaskData(holder.addressZipcode)).findCep();
      if (findCep !== null) {
        setDependent({
          ...dependent,
          addressCity: findCep.city,
          addressState: findCep.state,
          addressNeighborhood: findCep.neighborhood,
          addressStreet: findCep.street,
          addressIbge: findCep.ibge,
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  function enabledButton() {
    if (dependent.name.length > 0
        && dependent.addressStreet.length > 0
        && dependent.addressNumber.length > 0
        && dependent.addressNeighborhood.length > 0
        && dependent.addressCity.length > 0
        && dependent.addressCity.length > 0
        && dependent.addressZipcode.length >= 10
    ) {
      return true;
    }
    return false;
  }

  function add() {
    updateDependent({
      ...dependent,
    });
  }

  return (
    <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Editar</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="row w-100 mt-4 bg-light p-2">
              <div className="col-12">
                <h1>
                  Dependente
                </h1>
              </div>
              <div className="col-12 mb-4">
                <ButtonTextIcon
                  label="Preencher endereço do titular"
                  type={BtnType.PRIMARY}
                  icon=""
                  active
                  onClick={() => replicateHolderAdress()}
                  ref={null}
                />
              </div>
              <div className="col-4">
                <BasicOption
                  id="name-input"
                  label="Relação com titular"
                  readOnly={false}
                  helper="Ex: Filho(a)"
                  error=""
                  value={showDependentRole(dependent.role)}
                  onChange={(data) => { changeDependentRole(data); }}
                >
                  {['Cônjuge', 'Filho(a)', 'Outro']}
                </BasicOption>
              </div>
              <div className="col-4">
                <BasicInput
                  id="name-input"
                  label="Nome"
                  readOnly={false}
                  helper="Ex: João Batista"
                  error={dependent.name.length < 1 ? 'Campo Obrigatório' : ''}
                  value={dependent.name}
                  type="text"
                  onChange={(data) => {
                    setDependent({ ...dependent, name: data });
                  }}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="cpf-input"
                  label="CPF"
                  readOnly={false}
                  helper="Ex: 000.000.000-10"
                  error=""
                  value={dependent.cpf}
                  type="text"
                  onChange={(data) => {
                    setDependent({ ...dependent, cpf: maskCPForCNPJ(data) });
                  }}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="rg-input"
                  label="RG"
                  readOnly={false}
                  helper="Ex: 99999999"
                  error=""
                  value={dependent.rg}
                  type="text"
                  onChange={(data) => {
                    setDependent({ ...dependent, rg: maskNumber(data) });
                  }}
                />
              </div>
              <div className="col-4">
                <BasicOption
                  id="rg-emittent-input"
                  label="RG / Emissor"
                  readOnly={false}
                  helper="Ex: SSP/MA"
                  error=""
                  value={dependent.emitent}
                  onChange={(data) => {
                    setDependent({ ...dependent, emitent: data });
                  }}
                >
                  {rgEmittent}
                </BasicOption>
              </div>
              <div className="col-4">
                <BasicOption
                  id="sex-input"
                  label="Sexo"
                  readOnly={false}
                  helper="Ex: Masculino / Feminino"
                  error=""
                  value={dependent.sex === 'm' ? 'Masculino' : 'Feminino'}
                  onChange={(data) => {
                    if (data === 'Masculino') {
                      setDependent({ ...dependent, sex: 'm' });
                    } else {
                      setDependent({ ...dependent, sex: 'f' });
                    }
                  }}
                >
                  {['Masculino', 'Feminino']}
                </BasicOption>
              </div>
              <div className="col-4">
                <BasicInput
                  id="birth-input"
                  label="DT. Nascimento"
                  readOnly={false}
                  helper="Ex: 13-01-1990"
                  error=""
                  value={dependent.birthday}
                  type="text"
                  onChange={(data) => {
                    setDependent({ ...dependent, birthday: maskDate(data) });
                  }}
                />
              </div>
              <div className="col-4">
                <AddInput
                  id="address-cep-input"
                  label="CEP"
                  btnStyle={{ marginTop: 6 }}
                  readOnly={false}
                  helper="Ex: 35.171-120"
                  error={dependent.addressZipcode.length < 10 ? 'Campo obrigatório' : ''}
                  value={dependent.addressZipcode}
                  type="text"
                  onAdd={() => changeAddressByCep()}
                  onChange={(data) => {
                    setDependent({ ...dependent, addressZipcode: maskCep(data) });
                  }}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="address-street-input"
                  label="Rua"
                  readOnly={false}
                  helper="Ex: Rua Três"
                  error={dependent.addressStreet.length < 2 ? 'Campo Obrigatório' : ''}
                  value={dependent.addressStreet}
                  type="text"
                  onChange={(data) => {
                    setDependent({ ...dependent, addressStreet: data });
                  }}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="address-number-input"
                  label="Número"
                  readOnly={false}
                  helper="Ex: 133"
                  error={dependent.addressNumber.length < 1 ? 'Campo Obrigatório' : ''}
                  value={dependent.addressNumber}
                  type="text"
                  onChange={(data) => {
                    setDependent({ ...dependent, addressNumber: data });
                  }}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="address-complement-input"
                  label="Complemento"
                  readOnly={false}
                  helper="Ex: Bloco A"
                  error=""
                  value={dependent.addressComplement}
                  type="text"
                  onChange={(data) => {
                    setDependent({ ...dependent, addressComplement: data });
                  }}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="address-neighborhood-input"
                  label="Bairro"
                  readOnly={false}
                  helper="Centro"
                  error={dependent.addressNeighborhood.length < 1 ? 'Campo obrigatório' : ''}
                  value={dependent.addressNeighborhood}
                  type="text"
                  onChange={(data) => {
                    setDependent({ ...dependent, addressNeighborhood: data });
                  }}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="address-city-input"
                  label="Cidade"
                  readOnly
                  helper="Ex: Imperatriz"
                  error={dependent.addressCity.length < 1 ? 'Campo obrigatório' : ''}
                  value={dependent.addressCity}
                  type="text"
                  onChange={(data) => {
                    setDependent({ ...dependent, addressCity: data });
                  }}
                />
              </div>
              <div className="col-4">
                <BasicOption
                  id="address-state-input"
                  label="Estado"
                  readOnly
                  helper=""
                  error=""
                  value={dependent.addressState}
                  onChange={(data) => {
                    setDependent({ ...dependent, addressState: data });
                  }}
                >
                  {statesOfBrazil}
                </BasicOption>
              </div>
              <div className="col-4">
                <BasicInput
                  id="phone-input"
                  label="Telefone"
                  readOnly={false}
                  helper="Ex: (31) 99999 - 9999"
                  error=""
                  value={dependent.phone}
                  type="text"
                  onChange={(data) => {
                    setDependent({ ...dependent, phone: data });
                  }}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="phone-input-2"
                  label="Telefone 2"
                  readOnly={false}
                  helper="Ex: (31) 99999 - 9999"
                  error=""
                  value={dependent.phone2}
                  type="text"
                  onChange={(data) => {
                    setDependent({ ...dependent, phone2: data });
                  }}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="email-input"
                  label="email"
                  readOnly={false}
                  helper="Ex: usuario@usuario.com"
                  error={dependent.email && dependent.email.length > 0 && !EmailValidator.validate(dependent.email) ? 'E-mail inválido' : ''}
                  value={dependent.email ? dependent.email : ''}
                  type="text"
                  onChange={(data) => {
                    setDependent({ ...dependent, email: data });
                  }}
                />
              </div>
              <div className="col-12">
                <TextAreaInput
                  id=""
                  label="Observação"
                  readOnly={false}
                  helper=""
                  error=""
                  value={dependent.observation || ''}
                  onChange={(data) => setDependent({ ...dependent, observation: data })}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            <button type="button" className="btn btn-primary" disabled={!enabledButton()} data-bs-dismiss="modal" onClick={() => add()}>Editar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
