import api from '../config/axios';
import ContractLimitProps from '../dto/contractLimit';
import { EditClientResponseProps } from '../pages/ClientPJ/EditClient';

export function checkIfLimitLifeIsNotFulfilled(client: EditClientResponseProps) {
  let total = 0;
  const totalAllowedByContract = (client.contracts[0].totalLifes);

  client.holders.forEach((holder) => {
    if (holder.isActive) {
      total += 1;
    }

    holder.dependent.forEach((dependent) => {
      if (dependent.isActive) {
        total += 1;
      }
    });
  });

  if (total >= totalAllowedByContract) {
    return false;
  }
  return true;
}

export async function fetchClientByIdAndCheckIfLimitLifeIsNotFulfilled(clientId: string) {
  try {
    const token = { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } };
    const response = await api.get(`/admin/client/${clientId}`, token);
    const canAddOrUpdate = checkIfLimitLifeIsNotFulfilled(response.data);
    if (canAddOrUpdate) {
      return true;
    }
    return false;
  } catch (err: any) {
    console.log(err.response);
    return false;
  }
}
