/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React, {
  useEffect,
  useRef, useState,
} from 'react';
import BasicInput from './BasicInput';

import { maskCPF, unmaskData } from '../helpers/inputMasks.js';
import api from '../config/axios';
import { HolderDTO } from '../pages/Lead/PF/CreateLead';
import TextAreaInput from './TextAreaInput';
import { ProductsProps } from '../dto/product';
import BasicOption from './BasicOption';

interface MigrationModalProps {
    id: string
    type: 'holderpf' | 'holderpj' | 'dependentpf' | 'dependentpj'
    dependentUuid?: string
    holderId?: number
    name: string
}
type DependentPFMigrationScreens = 'initialChoose' | 'findHolder' | 'newContract' | 'success'
type HolderPFMigrationScreens = 'initialChoose' | 'newContract' | 'success'

interface HolderCtx extends Pick<HolderDTO, 'name'> {
    id: number
}

interface DependentPFMigrationProps {
    dependentUuid?: string,
    name: string,
}

interface HolderPFMigrationProps {
  id?: number,
  name: string,
}

function DependentPFMigration({ dependentUuid, name }: DependentPFMigrationProps) {
  const [actualScreen, setActualScreen] = useState<DependentPFMigrationScreens>('initialChoose');
  const [cpf, setCpf] = useState('');
  const [observation, setObservation] = useState('');
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [isLoading, setLoading] = useState(false);
  const [holders, setHolders] = useState<HolderCtx | null>();
  const [error, setError] = useState<null | string>(null);
  const [products, setProducts] = useState<ProductsProps[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Pick<ProductsProps, 'name' | 'id'>>({
    name: '',
    id: 0,
  });

  async function fetchAllProducts() {
    try {
      setLoading(true);
      const response = await api.get<ProductsProps[]>('admin/product', token);
      setProducts(response.data);
      if (response.data.length > 0) {
        setSelectedProduct(response.data[0]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  async function getHolderByCpf() {
    try {
      setLoading(true);
      setHolders(null);
      const response = await api.post<HolderCtx>('admin/migration/get-holder-by-cpf', {
        cpf: unmaskData(cpf.trim()),
      }, token);
      setHolders(response.data);
      setError(null);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setError('Titular não encontrado');
      console.log('error at fetching holder', err.response.data);
    }
  }

  function filterProductByName(product: string) {
    const filtered = products.filter((item) => item.name === product);
    if (filtered.length) {
      setSelectedProduct({ ...filtered[0] });
    }
  }

  async function migrate() {
    try {
      setLoading(true);
      await api.post('admin/migration/dependent/to-new-holder', {
        holderCpf: unmaskData(cpf.trim()),
        dependentUuid,
        observation,
      }, token);
      setActualScreen('success');
      setError(null);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      if (err.response && err.response.data && err.response.data === 'O novo titular nao pode ser o mesmo') {
        setError('O titular não pode ser o atual.');
      } else {
        setError('Erro ao migrar');
      }
      setLoading(false);
    }
  }

  function backToStart() {
    setLoading(false);
    setCpf('');
    setError(null);
    setObservation('');
    setHolders(null);
    setActualScreen('initialChoose');
  }

  async function newContract() {
    try {
      setLoading(true);
      await api.post('admin/migration/dependent-as-holder-contract-pf', {
        dependentUuid,
        productId: selectedProduct.id,
        observation,
      }, token);
      setActualScreen('success');
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setError('Erro ao migrar');
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAllProducts();
  }, []);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center" style={{ minWidth: 200, minHeight: 400 }}>
      <div className="w-100">
        {actualScreen === 'success' && (
        <div className="d-flex flex-column">
          <p className="text-center fw-bold mb-2 text-uppercase mt-2 text-success bg-success p-4" style={{ fontSize: 12 }}>Dependente migrado com successo</p>
        </div>

        )}
        {actualScreen !== 'initialChoose' && actualScreen !== 'success' && (
          <div className="d-flex mb-4">
            <button type="button" className="d-flex align-items-center fw-bold" onClick={backToStart}>
              <span className="material-icons-round text-black">
                chevron_left
              </span>
              Voltar às opçoes iniciais
            </button>
          </div>
        )}
        {actualScreen === 'initialChoose' && (
        <>
          <p className="text-center fw-bold mb-2 text-uppercase mt-2" style={{ fontSize: 12 }}>Selecione a opção</p>
          <button
            type="button"
            style={{ width: '100%', background: '#039EF7', height: 48 }}
            className="generate-excel-btn-wrapper justify-content-center"
            onClick={() => {
              setActualScreen('findHolder');
            }}
          >
            Migrar para outro titular
          </button>
          <div className="mt-2" />
          <button
            type="button"
            style={{ width: '100%', background: '#039EF7', height: 48 }}
            className="generate-excel-btn-wrapper justify-content-center"
            onClick={() => {
              setActualScreen('newContract');
            }}
          >
            Tornar esse dependente um titular de um novo contrato
          </button>
        </>
        )}
        {actualScreen === 'newContract' && (
        <div className="d-flex flex-column">
          <p className="text-start" style={{ lineHeight: 2 }}>
            Deseja transformar
            {' '}
            <span className="fw-bold">{name}</span>
            {' '}
            em um titular de um novo contrato PF?
          </p>
          <BasicOption
            id="product-selected-input"
            label="Produto escolhido"
            readOnly={false}
            helper="Selecione o produto"
            error=""
            value={selectedProduct !== null ? selectedProduct.name : 'Nenhum produto encontrado'}
            onChange={(data) => filterProductByName(data)}
          >
            {products.map((item) => item.name)}
          </BasicOption>
          <TextAreaInput
            id="obs"
            label="Observação"
            readOnly={false}
            helper=""
            error=""
            value={observation}
            onChange={(data) => setObservation(data)}
          />
          <button type="button" className="d-flex align-items-center bg-primary text-center text-white justify-content-center p-2 mt-4 rounded" onClick={newContract}>
            {isLoading ? (
              <div className="spinner-border text-white" role="status">
                <span className="sr-only text-center">Loading...</span>
              </div>
            ) : (
              <p>Confirmar</p>
            )}
          </button>
          {error && (
          <p className="text-center text-danger bg-danger p-4 mt-4">{error}</p>
          )}
        </div>

        )}
        {
                actualScreen === 'findHolder' && (
                <div className="flex flex-column">
                  <BasicInput
                    id="cpf"
                    label="Digite o CPF do novo titular"
                    readOnly={false}
                    helper=""
                    error=""
                    value={cpf}
                    type=""
                    onChange={(data) => setCpf(maskCPF(data))}
                  />
                  <button className="btn btn-primary" type="button" disabled={isLoading} style={{ background: '#039EF7' }} onClick={getHolderByCpf}>
                    {isLoading ? (
                      <div className="spinner-border text-white" role="status">
                        <span className="sr-only text-center">Loading...</span>
                      </div>
                    ) : (
                      <p>Pesquisar</p>
                    )}

                  </button>
                  {holders && !error && (
                    <div className="d-flex flex-column justify-content-between w-100 mt-4 bg-light p-2">
                      <p className="fw-bold text-center text-muted mb-4">{holders.name}</p>
                      <TextAreaInput
                        id="obs"
                        label="Observação"
                        readOnly={false}
                        helper=""
                        error=""
                        value={observation}
                        onChange={(data) => setObservation(data)}
                      />
                      <button type="button" className="bg-primary text-white p-2 ml-4 rounded" disabled={isLoading} onClick={migrate}>
                        {isLoading ? (
                          <div className="spinner-border text-white" role="status">
                            <span className="sr-only text-center">Loading...</span>
                          </div>
                        ) : (
                          <p>Migrar</p>
                        )}
                      </button>
                    </div>
                  )}
                  {error && (
                    <p className="text-center text-danger bg-danger p-4 mt-4">{error}</p>
                  )}
                </div>
                )
            }
      </div>
    </div>
  );
}

function HolderPJ({ name, id }: HolderPFMigrationProps) {
  const [actualScreen, setActualScreen] = useState<DependentPFMigrationScreens>('initialChoose');
  const [observation, setObservation] = useState('');
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [products, setProducts] = useState<ProductsProps[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Pick<ProductsProps, 'name' | 'id'>>({
    name: '',
    id: 0,
  });

  async function fetchAllProducts() {
    try {
      setLoading(true);
      const response = await api.get<ProductsProps[]>('admin/product', token);
      setProducts(response.data);
      if (response.data.length > 0) {
        setSelectedProduct(response.data[0]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }
  function filterProductByName(product: string) {
    const filtered = products.filter((item) => item.name === product);
    if (filtered.length) {
      setSelectedProduct({ ...filtered[0] });
    }
  }

  async function newContract() {
    try {
      setLoading(true);
      console.log({
        holderId: id?.toString(),
        productId: selectedProduct.id,
        observation,
      });
      await api.post('admin/migration/holder-pj-to-contract-pf', {
        holderId: id?.toString(),
        productId: selectedProduct.id,
        observation,
      }, token);
      setActualScreen('success');
      setError(null);
      setLoading(false);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setError('Erro ao migrar');
      setLoading(false);
    }
  }

  function backToStart() {
    setLoading(false);
    setError(null);
    setObservation('');
    setActualScreen('initialChoose');
  }

  useEffect(() => {
    fetchAllProducts();
  }, []);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center" style={{ minWidth: 200, minHeight: 400 }}>
      <div className="w-100">
        {actualScreen === 'success' && (
        <div className="d-flex flex-column">
          <p className="text-center fw-bold mb-2 text-uppercase mt-2 text-success bg-success p-4" style={{ fontSize: 12 }}>Dependente migrado com successo</p>
        </div>
        )}
        {actualScreen !== 'initialChoose' && actualScreen !== 'success' && (
        <div className="d-flex mb-4">
          <button type="button" className="d-flex align-items-center fw-bold" onClick={backToStart}>
            <span className="material-icons-round text-black">
              chevron_left
            </span>
            Voltar às opçoes iniciais
          </button>
        </div>
        )}
        {actualScreen === 'initialChoose' && (
        <>
          <p className="text-center fw-bold mb-2 text-uppercase mt-2" style={{ fontSize: 12 }}>Selecione a opção</p>
          <button
            type="button"
            style={{ width: '100%', background: '#039EF7', height: 48 }}
            className="generate-excel-btn-wrapper justify-content-center"
            onClick={() => {
              setActualScreen('newContract');
            }}
          >
            Tornar esse titular PJ em um titular de um novo contrato PF
          </button>
          {error && (
          <p className="text-center text-danger bg-danger p-4 mt-4">{error}</p>
          )}
        </>
        )}
        {actualScreen === 'newContract' && (
        <div className="d-flex flex-column">
          <p className="text-start" style={{ lineHeight: 2 }}>
            Deseja transformar
            {' '}
            <span className="fw-bold">{name}</span>
            {' '}
            em um titular de um novo contrato PF?
          </p>
          <BasicOption
            id="product-selected-input"
            label="Produto escolhido"
            readOnly={false}
            helper="Selecione o produto"
            error=""
            value={selectedProduct !== null ? selectedProduct.name : 'Nenhum produto encontrado'}
            onChange={(data) => filterProductByName(data)}
          >
            {products.map((item) => item.name)}
          </BasicOption>
          <TextAreaInput
            id="obs"
            label="Observação"
            readOnly={false}
            helper=""
            error=""
            value={observation}
            onChange={(data) => setObservation(data)}
          />
          <button type="button" className="d-flex align-items-center bg-primary text-center text-white justify-content-center p-2 mt-4 rounded" onClick={newContract}>
            {isLoading ? (
              <div className="spinner-border text-white" role="status">
                <span className="sr-only text-center">Loading...</span>
              </div>
            ) : (
              <p>Confirmar</p>
            )}
          </button>
          {error && (
          <p className="text-center text-danger bg-danger p-4 mt-4">{error}</p>
          )}
        </div>

        )}
      </div>
    </div>
  );
}

export default function MigrationModal({
  id, type, dependentUuid, name, holderId,
}: MigrationModalProps) {
  const closeRef = useRef<any>();
  return (
    <div className="modal fade" id={id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Migração -
              {' '}
              {name}
              {' '}
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeRef} onClick={() => window.location.reload()} />
          </div>
          <div className="modal-body">
            {type === 'dependentpf' && <DependentPFMigration dependentUuid={dependentUuid} name={name} />}
            {type === 'dependentpj' && <DependentPFMigration dependentUuid={dependentUuid} name={name} />}
            {type === 'holderpf' && <HolderPJ name={name} id={holderId} />}
            {type === 'holderpj' && <HolderPJ name={name} id={holderId} />}
          </div>
        </div>
      </div>
    </div>
  );
}
