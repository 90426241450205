import React, { createRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BasicInput from '../../components/BasicInput';
import ButtonIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import api from '../../config/axios';
import dataURIToBlob from '../../helpers/fileReader';
import DefaultLayout from '../../Layout';
import { storeType } from '../../store';
import setAdmin from '../../store/actions/adminAction';

export default function Profile() {
  const {
    name, email, cpf, id, avatar,
  } = useSelector(({ adminReducer }: storeType) => adminReducer);
  const dispatch = useDispatch();
  const [readOnly, setReadOnly] = useState(true);
  const [passwordReadOnly, setPasswordReadOnly] = useState(true);
  const [loading, setLoading] = useState(false);
  const [imgURL, setImageURL] = useState<null | any>(null);
  const [nameInput, setNameInput] = useState(name);
  const [emailInput, setEmailInput] = useState(email);
  const [passwordInput, setPasswordInput] = useState('');
  const [repeatPasswordInput, setRepeatPasswordInput] = useState('');
  const [fileRef] = useState(createRef<any>());
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  function createFormData() {
    const body = new FormData();
    const blob = dataURIToBlob(imgURL);
    body.append('file', blob);
    return body;
  }

  function checkFieldsToChange() {
    const obj = {};
    if (nameInput !== name) {
      Object.assign(obj, { name: nameInput.trim() });
    } else {
      Object.assign(obj, { name });
    }
    if (emailInput !== email) {
      Object.assign(obj, { email: emailInput.trim() });
    } else {
      Object.assign(obj, { email });
    }
    return obj;
  }

  async function tryUpdateProfile() {
    try {
      setLoading(true);
      if (typeof imgURL === 'string') {
        await api.post('/admin/avatar', createFormData(), token);
      }
      const response = await api.put(`/admin/profile/${id}`, checkFieldsToChange(), token);
      setAdmin(dispatch, response.data);
      setLoading(false);
      setPasswordReadOnly(true);
      setReadOnly(true);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  }

  async function tryUpdatePassword() {
    try {
      if (passwordInput === repeatPasswordInput) {
        setLoading(true);
        await api.put(`/admin/profile/${id}`, {
          name: nameInput,
          password: passwordInput,
          passwordConfirmation: repeatPasswordInput,
        }, token);
        setLoading(false);
        setShowToastSuccess(true);
        setPasswordReadOnly(true);
        setReadOnly(true);
        setTimeout(() => {
          setShowToastSuccess(false);
        }, 3000);
      }
    } catch (err: any) {
      setLoading(false);
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 3000);
      console.log(err.response);
    }
  }

  function resolveAvatar() {
    if (imgURL !== null) {
      return imgURL;
    }
    if (avatar) {
      return avatar.fileUrl;
    }
    return 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-2/img/13-small.d796bffd.png';
  }

  function createObjectURL(data: React.ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();
    reader.readAsDataURL(data.target.files![0]);
    reader.onload = (file) => {
      if (file.loaded / 1024 < 4000) {
        setImageURL(file.target!.result);
      } else {
        setModal({ title: 'Ocorreu um erro!', children: 'O arquivo selecionado ultrapassou o limite máximo de 4mb', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }
    };
  }
  return (
    <DefaultLayout
      modal={modal}
      rightSliderContent={<div />}
      showRightSlider={false}
      setCloseRightSlider={() => null}
      pageTitle="Perfil"
      loading={loading}
      showToastError={showToastError}
      showToastSuccess={showToastSuccess}
    >
      <div className="row profile card-bg">
        <div className="col-6 d-flex flex-column align-items-center left-content">
          <div className="profile-input-wrapper">
            <h1>Dados Pessoais</h1>
            <div className="col-12 allow-modifications">
              <input
                type="checkbox"
                id="#checkbox"
                onChange={() => {
                  setReadOnly((prev) => !prev);
                  setNameInput(name);
                  setEmailInput(email);
                }}
              />
              Habilitar modificações
            </div>
            <div className="col-12 avatar-wrapper">
              <img src={resolveAvatar()} alt="" />
              {!readOnly && (
                <ButtonIcon
                  type={BtnType.SECONDARY}
                  onClick={() => fileRef.current.click()}
                  active
                  ref={null}
                  icon="image"
                  label="alterar"
                />
              )}
              <input
                type="file"
                className="d-none"
                ref={fileRef}
                accept=".png, .jpg, .jpeg"
                onChange={(data) => createObjectURL(data)}
              />
            </div>
            <div className="col-12">
              <BasicInput
                id="profile-name"
                label="Nome"
                readOnly={readOnly}
                helper="Apenas letras"
                error={nameInput.length === 0 ? 'Esse campo não pode estar em branco' : ''}
                type="text"
                value={nameInput}
                onChange={setNameInput}
              />
            </div>
            <div className="col-12">
              <BasicInput
                id="profile-cof"
                label="CPF"
                readOnly
                type="text"
                helper="Apenas números"
                error=""
                value={cpf}
                onChange={() => null}
              />
            </div>
            <div className="col-12">
              <BasicInput
                id="profile-email"
                label="E-mail"
                readOnly={readOnly}
                type="email"
                helper="Digite um email válido"
                error={email.length < 6 ? 'Digite um email válido' : ''}
                value={emailInput}
                onChange={setEmailInput}
              />
            </div>
            <div className="col-12 footer mt-4">
              {!readOnly && (
              <>
                <ButtonIcon
                  active
                  label="RESTAURAR"
                  type={BtnType.SECONDARY}
                  icon="restart_alt"
                  ref={null}
                  onClick={() => {
                    setNameInput(name);
                    setEmailInput(email);
                  }}
                />
                <ButtonIcon
                  active={nameInput.length > 0 && email.length > 6}
                  label="salvar"
                  type={BtnType.PRIMARY}
                  icon="save"
                  onClick={() => tryUpdateProfile()}
                  ref={null}
                />

              </>
              )}

            </div>
          </div>

        </div>
        <div className="col-6 d-flex flex-column align-items-center right-content">
          <div className="profile-input-wrapper">
            <h1>Dados de acesso</h1>
            <div className="col-12 allow-modifications">
              <input type="checkbox" id="#checkbox" onChange={() => setPasswordReadOnly((prev) => !prev)} />
              Habilitar modificações de dados de acesso
            </div>
            <div className="col-12">
              <BasicInput
                id="profile-password"
                label="Senha"
                readOnly={passwordReadOnly}
                helper="Mínimo 06 dígitos"
                error={passwordInput.length < 6 ? 'Mínimo 06 ( seis ) dígitos' : ''}
                type="password"
                value={passwordInput}
                onChange={(data) => setPasswordInput(data)}
              />
            </div>
            <div className="col-12">
              <BasicInput
                id="profile-repeat-password"
                label="Repita a senha"
                readOnly={passwordReadOnly}
                helper="Deve ser idêntica ao campo anterior"
                error={passwordInput !== repeatPasswordInput ? 'A senha deve ser igual a digitada no campo acima' : ''}
                type="password"
                value={repeatPasswordInput}
                onChange={(data) => setRepeatPasswordInput(data)}
              />
            </div>
            {!passwordReadOnly && (
            <div className="col-12 footer mt-4">
              <ButtonIcon
                active={passwordInput.length >= 6 && passwordInput === repeatPasswordInput}
                label="salvar"
                type={BtnType.PRIMARY}
                ref={null}
                icon="save"
                onClick={() => tryUpdatePassword()}
              />
            </div>
            )}

          </div>
        </div>

      </div>
    </DefaultLayout>
  );
}
