import { DateTime } from 'luxon';
import React, { useState } from 'react';
import BasicInput from '../../components/BasicInput';
import BasicOption from '../../components/BasicOption';
import { maskDate } from '../../helpers/inputMasks.js';
import { convertCardPaymentMethodFromApi, normalizeToApiCardPaymentWays } from '../../helpers/showCardMethods';
import { GatewayProps } from '../dashboard/NonPaidInstallments/Table';
import { methodProps, TableFilters } from './Incomes';
import { statesOfBrazil } from '../../dto/states';

const gateways: GatewayProps[] = [
  {
    id: 1,
    name: 'PagAzul Presencial',
  },
  {
    id: 2,
    name: 'REDESANTA',
  },
  {
    id: 3,
    name: 'PagAzul WEB',
  },
  {
    id: 4,
    name: 'CIELO',
  },
  {
    id: 5,
    name: 'GALAXPAY',
  },
];

const methods = [
  'PIX',
  'DINHEIRO',
  'BOLETO',
  'TED',
  'DOC',
  'Débito',
  'Crédito 1X',
  'Crédito 2X',
  'Crédito 3X',
  'Crédito 4X',
  'Crédito 5X',
  'Crédito 6X',
  'Crédito 7X',
  'Crédito 8X',
  'Crédito 9X',
  'Crédito 10X',
  'Crédito 11X',
  'Crédito 12X',
  'Crédito 13X',
  'Crédito 14X ',
  'Crédito 15X',
  'Crédito 16X',
  'Crédito 17X',
  'Crédito 18X',
];

type FilterProps = {
    minValue: string
    setMinValue: (arg0: string) => void
    maxValue: string
    setMaxValue: (arg0: string) => void
    minLiquidValue: string
    setMinLiquidValue: (arg0: string) => void
    maxLiquidValue: string
    setMaxLiquidValue: (arg0: string) => void
    minTaxUsed: string
    setMinTaxUsed: (arg0: string) => void
    maxTaxUsed: string
    setMaxTaxUsed: (arg0: string) => void
    description: string
    setDescription: (arg0: string) => void
    paymentWay: methodProps | null
    setPaymentWay: (arg0: methodProps | null) => void
    gatewayId: number | null
    setGatewayId: (arg0: number | null) => void
    manualPayment: boolean | null
    setManualPayment: (arg0: boolean | null) => void
    createdByUserName: string
    setCreatedByUsername: (arg0: string) => void
    orderPosition: number | null
    setOrderPosition: (arg0: number | null) => void
    initialIncomeDate: string
    setInitialIncomeDate: (arg0: string) => void
    finalIncomeDate: string
    setFinalIncomeDate: (arg0: string) => void
    type: 1 | 2 | 3 | null | undefined,
    setType: (arg0: 1 | 2 | 3 | null | undefined) => void
    // initialCreatedAt: string
    // setInitialCreatedAt: (arg0: string) => void
    // finalCreatedAt: string
    // setFinalCreatedAt: (arg0: string) => void
    filter: (arg: TableFilters) => void;
    removeAllFilters: () => void
    invoiceIssued: null | boolean,
    setInvoiceIssued: (arg0: null | boolean) => void
    city: string
    setCity: (arg0: string) => void
    state: string
    setState: (arg0: string) => void
}

export default function ProfitModalfilter({
  minValue,
  setMinValue,
  maxValue,
  setMaxValue,
  minLiquidValue,
  setMinLiquidValue,
  maxLiquidValue,
  setMaxLiquidValue,
  minTaxUsed,
  setMinTaxUsed,
  maxTaxUsed,
  setMaxTaxUsed,
  description,
  setDescription,
  paymentWay,
  setPaymentWay,
  gatewayId,
  setGatewayId,
  manualPayment,
  setManualPayment,
  createdByUserName,
  setCreatedByUsername,
  orderPosition,
  setOrderPosition,
  initialIncomeDate,
  setInitialIncomeDate,
  finalIncomeDate,
  setFinalIncomeDate,
  type,
  setType,
  invoiceIssued,
  setInvoiceIssued,
  //   initialCreatedAt,
  //   setInitialCreatedAt,
  //   finalCreatedAt,
  //   setFinalCreatedAt,
  filter,
  removeAllFilters,
  city,
  setCity,
  state,
  setState,
}: FilterProps) {
  const [ref] = useState(React.createRef<any>());

  async function validateDateDoubleDates(dateInitial: string, dateFinal: string) {
    try {
      const initialDateAsDateTime = DateTime.fromFormat(dateInitial, 'dd-MM-yyyy');
      const finalDateAsDateTime = DateTime.fromFormat(dateFinal, 'dd-MM-yyyy');
      if (!initialDateAsDateTime.isValid) {
        throw new Error('Data inicial inválida.');
      }
      if (!finalDateAsDateTime.isValid) {
        throw new Error('Data final inválida.');
      }

      if (initialDateAsDateTime > finalDateAsDateTime) {
        throw new Error('Data inicial maior que a data final.');
      }
      return {
        initialDateAsDateTime,
        finalDateAsDateTime,
      };
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  async function validateSingleDates(date: string) {
    try {
      const initialDateAsDateTime = DateTime.fromFormat(date, 'dd-MM-yyyy');
      if (!initialDateAsDateTime.isValid) {
        throw new Error('Data inicial inválida.');
      }

      return {
        initialDateAsDateTime,
      };
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  async function setFilter() {
    try {
      if (initialIncomeDate.trim() !== '') {
        await validateSingleDates(initialIncomeDate);
      }
      if (finalIncomeDate.trim() !== '') {
        await validateSingleDates(finalIncomeDate);
      }

      if (initialIncomeDate.trim() !== '' && finalIncomeDate.trim() !== '') {
        await validateDateDoubleDates(initialIncomeDate, finalIncomeDate);
      }

      filter({
        currentPage: 1,
        minValue,
        maxValue,
        minLiquidValue,
        maxLiquidValue,
        minTaxUsed,
        maxTaxUsed,
        description,
        paymentWay,
        gatewayId,
        manualPayment,
        createdByUserName,
        orderPosition,
        initialIncomeDate,
        finalIncomeDate,
        invoiceIssued,
        tipo: type,
        state,
        city,
      });
      ref.current.click();
    } catch (err: any) {
      if (err.message === 'Data inicial maior que a data final.') {
        alert('Data inicial maior que a data final.');
      }
      if (err.message === 'Data final inválida.') {
        alert('Data final inválida.');
      }
      if (err.message === 'Data inicial inválida.') {
        alert('Data inicial inválida.');
      }
    }
  }

  function setPaymentMethod(data: string) {
    console.log(data);
    if (data === 'TODOS') {
      setPaymentWay(null);
    } else {
      setPaymentWay(normalizeToApiCardPaymentWays(data) as methodProps);
    }
  }

  function filterByGatewayId(gatewayIdCtx: number) {
    const filtered = gateways.filter((item) => item.id === gatewayIdCtx);
    return filtered[0].name;
  }

  function setGateway(gateway: string) {
    if (gateway === 'TODOS') {
      setGatewayId(null);
    } else {
      const filtered = gateways.filter((item) => item.name === gateway);
      setGatewayId(filtered[0].id);
    }
  }

  function onChangeManualPayment(ctx: string) {
    if (ctx === 'TODOS') {
      setManualPayment(null);
    } else if (ctx === 'SIM') {
      setManualPayment(true);
    } else {
      setManualPayment(false);
    }
  }

  function resolveManualPayment(ctx: boolean | null) {
    if (ctx === null) {
      return 'TODOS';
    }
    if (ctx === true) {
      return 'SIM';
    }
    return 'NÃO';
  }

  function onChangeOrderPosition(orderCtx: string) {
    if (orderCtx === 'TODOS') {
      setOrderPosition(null);
    } else {
      setOrderPosition(Number(orderCtx));
    }
  }

  // function resolveType(typeCtx: number) {
  //   if (typeCtx === 1) {
  //     return 'PF';
  //   }
  //   if (typeCtx === 2) {
  //     return 'PJ';
  //   }
  //   return 'TODOS';
  // }

  function onChangeType(typeCtx: string) {
    if (typeCtx === 'TODOS') {
      setType(null);
    } else if (typeCtx === 'PF') {
      setType(1);
    } else {
      setType(2);
    }
  }

  function setGeneratedIssue(data: 'TODOS' | 'SIM' | 'NÃO') {
    if (data === 'TODOS') {
      setInvoiceIssued(null);
    } else if (data === 'SIM') {
      setInvoiceIssued(true);
    } else {
      setInvoiceIssued(false);
    }
  }

  function showGeneratedIssue() {
    if (invoiceIssued) {
      return 'SIM';
    }
    if (invoiceIssued === false) {
      return 'NÃO';
    }
    return 'TODOS';
  }

  return (
    <div className="modal fade" id="profitsModalFilter" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Filtro</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={ref} />
          </div>
          <div className="modal-body">
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicOption
                    id=""
                    label="NF Gerada?"
                    readOnly={false}
                    helper=""
                    error=""
                    value={showGeneratedIssue()}
                    onChange={(data) => setGeneratedIssue(data as 'TODOS' | 'SIM' | 'NÃO')}
                  >
                    {['TODOS', 'SIM', 'NÃO']}
                  </BasicOption>
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Descrição"
                    readOnly={false}
                    helper=""
                    error=""
                    value={description}
                    type="text"
                    onChange={(data) => setDescription(data)}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicOption
                    id=""
                    label="Método"
                    readOnly={false}
                    helper=""
                    error=""
                    value={paymentWay ? convertCardPaymentMethodFromApi(paymentWay) : 'TODOS'}
                    onChange={(data) => setPaymentMethod(data)}
                  >
                    {['TODOS', ...methods]}
                  </BasicOption>
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicOption
                    id=""
                    label="Gateway"
                    readOnly={false}
                    helper=""
                    error=""
                    value={gatewayId ? filterByGatewayId(gatewayId) : 'TODOS'}
                    onChange={(data) => setGateway(data)}
                  >
                    {['TODOS', ...gateways.map((item) => item.name)]}
                  </BasicOption>
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicOption
                    id=""
                    label="Baixa manual ?"
                    readOnly={false}
                    helper=""
                    error=""
                    value={resolveManualPayment(manualPayment)}
                    onChange={(data) => onChangeManualPayment(data)}
                  >
                    {['TODOS', 'SIM', 'NÃO']}
                  </BasicOption>
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Baixa realizada por"
                    readOnly={false}
                    helper=""
                    type="text"
                    error=""
                    value={createdByUserName}
                    onChange={(data) => setCreatedByUsername(data)}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Cidade do cliente"
                    readOnly={false}
                    helper=""
                    type="text"
                    error=""
                    value={city}
                    onChange={(data) => setCity(data)}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicOption
                    id=""
                    label="Estado do Cliente"
                    readOnly={false}
                    helper=""
                    error=""
                    value={state}
                    onChange={setState}
                  >
                    {['TODOS'].concat(statesOfBrazil)}
                  </BasicOption>
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper">
                <div className="" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data inicial da entrada"
                    readOnly={false}
                    helper=""
                    error=""
                    value={initialIncomeDate}
                    type="text"
                    onChange={(data) => setInitialIncomeDate(maskDate(data))}
                  />
                </div>
                <div className="" style={{ paddingLeft: 4 }}>
                  <BasicInput
                    id=""
                    label="Data final da entrada"
                    readOnly={false}
                    helper=""
                    error=""
                    value={finalIncomeDate}
                    type="text"
                    onChange={(data) => setFinalIncomeDate(maskDate(data))}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper">
                <div className="" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Valor bruto mínimo"
                    readOnly={false}
                    helper=""
                    error=""
                    value={minValue}
                    type="number"
                    onChange={(data) => setMinValue(data)}
                  />
                </div>
                <div className="" style={{ paddingLeft: 4 }}>
                  <BasicInput
                    id=""
                    label="Valor bruto máximo"
                    readOnly={false}
                    helper=""
                    error=""
                    value={maxValue}
                    type="number"
                    onChange={(data) => setMaxValue(data)}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper">
                <div className="" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Valor líquido mínimo"
                    readOnly={false}
                    helper=""
                    error=""
                    value={minLiquidValue}
                    type="number"
                    onChange={(data) => setMinLiquidValue(data)}
                  />
                </div>
                <div className="" style={{ paddingLeft: 4 }}>
                  <BasicInput
                    id=""
                    label="Valor líquido máximo"
                    readOnly={false}
                    helper=""
                    error=""
                    value={maxLiquidValue}
                    type="number"
                    onChange={(data) => setMaxLiquidValue(data)}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper">
                <div className="" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Taxa aplicada - mínimo"
                    readOnly={false}
                    helper=""
                    error=""
                    value={minTaxUsed}
                    type="number"
                    onChange={(data) => setMinTaxUsed(data)}
                  />
                </div>
                <div className="" style={{ paddingLeft: 4 }}>
                  <BasicInput
                    id=""
                    label="Taxa aplicada - máximo"
                    readOnly={false}
                    helper=""
                    error=""
                    value={maxTaxUsed}
                    type="number"
                    onChange={(data) => setMaxTaxUsed(data)}
                  />
                </div>
              </div>
            </div>
            <div className="filter-date-input-wrapper" style={{ padding: '8px 0px' }}>
              <div className="" style={{ paddingRight: 4 }}>
                <BasicOption
                  id=""
                  label="NÚMERO DA PARCELA"
                  readOnly={false}
                  helper=""
                  error=""
                  value={orderPosition ? orderPosition.toString() : 'TODOS'}
                  onChange={(data) => onChangeOrderPosition(data)}
                >
                  {['TODOS', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                </BasicOption>
              </div>
            </div>
            {/* <div className="filter-date-input-wrapper" style={{ padding: '8px 0px' }}>
              <div className="" style={{ paddingRight: 4 }}>
                <BasicOption
                  id=""
                  label="Modalidade"
                  readOnly={false}
                  helper=""
                  error=""
                  value={type ? resolveType(type) : 'TODOS'}
                  onChange={(data) => onChangeType(data)}
                >
                  {['TODOS', 'PF', 'PJ']}
                </BasicOption>
              </div>
            </div> */}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => removeAllFilters()}>Remover Filtros</button>
            <button type="button" className="btn btn-primary" onClick={() => setFilter()}>Filtrar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
