import React from 'react';

export default function ToastSuccess() {
  return (
    <div className="toast-success-container">
      <p className="toast-success">Ação executada com sucesso!</p>
      <span className="material-icons-round">
        check_circle_outline
      </span>
    </div>
  );
}
