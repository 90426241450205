/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddInput from '../../components/AddInput';
import BasicInput from '../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import api from '../../config/axios';
import { maskCPF } from '../../helpers/inputMasks.js';
import DefaultLayout from '../../Layout';
import BookLog from './BookLog/BookLog';
import DefaultIndices from './DefaultIndices';
import FreelancersByDate from './Freelancers';
import IndicationsByDate from './Indications/IndicationsByPeriod';
import IndicationsCategoryByDate from './Indications/IndicationsCategoryByPeriod';
import LifesByFreelancers from './LifesByFreelancers';
import LifesByIndications from './LifesByIndications';
import LifesByPeriodSplitted from './LifesByPeriodSplitted';
import LifesByPeriod from './LifesByPeríod';
import LifesByReffers from './LifesByReffers';
import LifesBySellers from './LifesBySellers';
import ReffersByDate from './Reffers/ReffersByPeriod';
import SalesByDate from './Sales';
import SellersByDate from './Sellers';
import TicketIncome from './TicketIncome';
import TicketSell from './TicketSell';

export default function ReportsByDate() {
  const navigate = useNavigate();
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [loading, setLoading] = useState(false);

  return (
    <DefaultLayout
      pageTitle="Relatórios por data"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >

      <div className="row">
        <div className="col-12 d-flex align-items-center mb-2 mt-2">
          <h1 className="fw-bold">POR CONTRATOS</h1>
          <div className="horizontal-divider" />
        </div>
      </div>

      <SalesByDate
        setLoading={(arg) => setLoading(arg)}
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
      />

      <IndicationsByDate
        setLoading={(arg) => setLoading(arg)}
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
      />

      <IndicationsCategoryByDate
        setLoading={(arg) => setLoading(arg)}
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
      />

      <ReffersByDate
        setLoading={(arg) => setLoading(arg)}
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
      />

      <SellersByDate
        setLoading={(arg) => setLoading(arg)}
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
      />

      <FreelancersByDate
        setLoading={(arg) => setLoading(arg)}
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
      />

      {/* <DefaultIndices
        setLoading={(arg) => setLoading(arg)}
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
      /> */}

      <div className="row">
        <div className="col-12 d-flex align-items-center mb-2 mt-2">
          <h1 className="fw-bold">POR VIDA (TITULAR + DEPENDENTES) POR DATA DE INCLUSÃO NO SISTEMA</h1>
          <div className="horizontal-divider" />
        </div>
      </div>

      <LifesByPeriod
        setLoading={(arg) => setLoading(arg)}
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
      />

      <LifesByPeriodSplitted
        setLoading={(arg) => setLoading(arg)}
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
      />

      <div className="row">
        <div className="col-12 d-flex align-items-center mb-2 mt-2">
          <h1 className="fw-bold">TICKET MEDIO</h1>
          <div className="horizontal-divider" />
        </div>
      </div>

      <TicketSell
        setLoading={(arg) => setLoading(arg)}
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
      />

      <div className="row">
        <div className="col-12 d-flex align-items-center mb-2 mt-2">
          <h1 className="fw-bold">DIARIO DE BORDO</h1>
          <div className="horizontal-divider" />
        </div>
      </div>

      <BookLog
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
        setLoading={(arg) => setLoading(arg)}
      />

      {/* <TicketIncome
        setLoading={(arg) => setLoading(arg)}
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
      /> */}

      {/* <LifesByFreelancers
        setLoading={(arg) => setLoading(arg)}
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
      />

      <LifesByIndications
        setLoading={(arg) => setLoading(arg)}
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
      />

      <LifesByReffers
        setLoading={(arg) => setLoading(arg)}
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
      />

      <LifesBySellers
        setLoading={(arg) => setLoading(arg)}
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
      /> */}

    </DefaultLayout>
  );
}
