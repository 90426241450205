/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import { goTo } from 'pdfkit';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BasicInput from '../../components/BasicInput';
import api from '../../config/axios';
import resolvePaymentStatus from '../../helpers/resolvePaymentStatus';
import { convertCardPaymentMethodFromApi } from '../../helpers/showCardMethods';
import { GatewayProps } from '../dashboard/NonPaidInstallments/Table';

export type PagVipRefProps = {
  id: number,
  pagazulHolderLinksId: number,
  contractPfPaymentInstallmentId: number,
  idCharge: string,
  bankSlip: string,
  createdAt: string,
  updatedAt: string,
}

export type InstallmentDTO = {
  id: number
  contractPfPaymentId?: number
  contractPjPaymentId?: number
  uuid: string
  dueDate: string
  status: string
  value: number
  liquidValue: number,
  previsionDate: string,
  paidBy: string
  authorizationDate: string | null
  paymentDate: string | null
  paymentGatewayId: number
  orderPosition: number
  nonCardTaxes: string | null
  taxUsed: number
  pagvipRef: string | null
  card: boolean
  flag: string | null
  cardLastDigits: string | null
  cardPaymentOption: string | null
  boletoPayvip: PagVipRefProps | null
  isGalaxpaySubscription: boolean
  galaxpayPaymentMethod: 'boleto' | 'cartao' | 'pix'
  galaxpayInstallmentId: string | null
}

const gateways: GatewayProps[] = [
  {
    id: 1,
    name: 'PagAzul Presencial',
  },
  {
    id: 2,
    name: 'REDESANTA',
  },
  {
    id: 3,
    name: 'PagAzul WEB',
  },
  {
    id: 4,
    name: 'CIELO',
  },
  {
    id: 5,
    name: 'GALAXPAY',
  },
];

interface AllTimeValueProps {
  uniqueId: string,
  targetUniqueId: string,
  isPf: boolean,
  installmentPfId?: number | null,
  installmentPjId?: number | null
}

enum ActualPage {
  FORM,
  LOADING,
  SUCCESS,
  ERROR
}

export default function IncomeInstallmentModal({
  uniqueId, isPf, targetUniqueId, installmentPfId, installmentPjId,
}: AllTimeValueProps) {
  const [actualPage, setActualPage] = useState(ActualPage.FORM);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [installment, setInstallment] = useState<InstallmentDTO>({
    id: 0,
    contractPfPaymentId: undefined,
    contractPjPaymentId: undefined,
    uuid: '',
    dueDate: '',
    status: '',
    value: 0,
    liquidValue: 0,
    previsionDate: '',
    paidBy: '',
    authorizationDate: null,
    paymentDate: null,
    paymentGatewayId: 0,
    orderPosition: 0,
    nonCardTaxes: null,
    taxUsed: 0,
    pagvipRef: null,
    card: false,
    flag: null,
    cardLastDigits: null,
    cardPaymentOption: null,
    boletoPayvip: null,
    isGalaxpaySubscription: false,
    galaxpayPaymentMethod: 'boleto',
    galaxpayInstallmentId: null,
  });

  function showFlag(flag: string) {
    if (flag === 'OTHER') {
      return 'OUTRA';
    }
    return flag;
  }

  function showGatewayName(idx: number) {
    const filteredGateway = gateways.filter((item) => item.id === idx);
    if (filteredGateway.length > 0) {
      return filteredGateway[0].name;
    }
    return '';
  }

  async function onChangeModalProps(installmentPfId?: number | null, installmentPjId?: number | null) {
    try {
      if (isPf && installmentPfId) {
        setActualPage(ActualPage.LOADING);
        const response = await api.get(`admin/contractpf/installment/info/${installmentPfId}`, token);
        setInstallment(response.data);
        setActualPage(ActualPage.FORM);
      } else if (installmentPjId) {
        setActualPage(ActualPage.LOADING);
        const response = await api.get(`admin/contractpj/installment/info/${installmentPjId}`, token);
        setInstallment(response.data);
        setActualPage(ActualPage.FORM);
      }
    } catch (err: any) {
      console.log(err.response.data);
      setActualPage(ActualPage.ERROR);
    }
  }

  function goToPayment() {
    if (isPf) {
      return `https://painel.redesanta.com/contratos/pf/forma-de-pagamento/visualizar/${installment.contractPfPaymentId}`;
    }
    return `https://painel.redesanta.com/contratos/pj/forma-de-pagamento/visualizar/${installment.contractPjPaymentId}`;
  }

  useEffect(() => {
    onChangeModalProps(installmentPfId, installmentPjId);
  }, []);

  return (
    <div className="modal fade" id={uniqueId} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Parcela
              {' '}
              |
              {isPf && (
                <>
                  {' '}
                  PF_DB_ID:
                  {' '}
                  <strong className="fw-bold">
                    {installment.id}
                  </strong>
                </>
              )}
              {!isPf && (
              <>
                {' '}
                PJ_DB_ID:
                  {' '}
                <strong className="fw-bold">
                  {installment.id}
                </strong>
              </>
              )}
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            { actualPage === ActualPage.LOADING && (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
              <p className="fw-bold text-muted">carregando parcela, aguarde...</p>
            </div>
            )}

            {actualPage === ActualPage.ERROR && (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
              <p className="fw-bold text-muted">Ocorreu um erro ao carregar a parcela!</p>
            </div>
            )}

            {actualPage === ActualPage.FORM && (
            <div className="container">
              <div className="row products">
                <div className="col-12 card-bg p-4">
                  <div className="row">
                    <h4 className="text-center mb-4 text-primary fw-bold text-decoration-underline" onClick={() => window.open(goToPayment(), '_blank')} style={{ cursor: 'pointer' }}>
                      IR PARA PAGAMENTO
                    </h4>
                    <div className="payment-status">
                      <h1>
                        {`Parcela - ${installment.orderPosition}`}
                        {/* {`Parcela - ${installmentIndex + 1}`} */}
                      </h1>
                      <span>
                        {resolvePaymentStatus(installment.status, installment.dueDate)}
                      </span>
                    </div>

                    <div className="col-12">
                      <BasicInput
                        id="data-vencimento"
                        label="Data de vencimento"
                        readOnly
                        helper="Ex: 30-04-2022"
                        error=""
                        value={installment.dueDate}
                        type="text"
                        onChange={(data) => null}
                      />
                    </div>
                    <div className="col-12">
                      <BasicInput
                        id="data-previsao"
                        label="Data de previsão"
                        readOnly
                        helper="Ex: 30-04-2022"
                        error=""
                        value={installment.previsionDate}
                        type="text"
                        onChange={(data) => null}
                      />
                    </div>
                    { installment.authorizationDate && (
                    <div className="col-12">
                      <BasicInput
                        id="data-previsao"
                        label="Data de autorização"
                        readOnly
                        helper="Ex: 30-04-2022"
                        error=""
                        value={installment.authorizationDate}
                        type="text"
                        onChange={(data) => null}
                      />
                    </div>
                    )}

                    <div className="col-12">
                      <BasicInput
                        id={`valor-parcela-${1 + 1}`}
                        label="Valor Bruto"
                        readOnly
                        helper="Ex: 600"
                        error=""
                        value={installment.value.toString()}
                        type="number"
                        onChange={(data) => null}
                      />
                    </div>
                    <div className="col-12">
                      <BasicInput
                        type="text"
                        id={`gateway-de-pagamento-${1 + 1}`}
                        label="Gateway de pagamento"
                        readOnly
                        helper="Ex: PagAzul Web"
                        error=""
                        value={showGatewayName(installment.paymentGatewayId)}
                        onChange={() => null}
                      />

                    </div>
                    <div className="col-12">
                      <BasicInput
                        id={`opcoes-de-pagamento${1 + 1}`}
                        label="Opções de pagamento"
                        readOnly
                        helper="Ex: Cartão"
                        error=""
                        value={installment.card ? 'CARTÃO' : 'OUTRO'}
                        onChange={(data) => null}
                        type="text"
                      />

                    </div>
                    {installment.card && (
                    <div className="col-12">
                      <BasicInput
                        type="text"
                        id={`flag-${1 + 1}`}
                        label="Bandeira"
                        readOnly
                        helper="Ex: VISA"
                        error=""
                        value={installment.flag !== null ? showFlag(installment.flag) : ''}
                        onChange={() => null}
                      />

                    </div>
                    )}

                    {installment.card && (
                    <div className="col-12">
                      <BasicInput
                        type="text"
                        id={`card-way-${1 + 1}`}
                        label="Forma de pagamento"
                        readOnly
                        helper="Ex: Crédito 12x"
                        error=""
                        value={installment.cardPaymentOption !== null ? convertCardPaymentMethodFromApi(installment.cardPaymentOption) : ''}
                        onChange={() => null}
                      />

                    </div>
                    )}

                    {installment.card && (
                    <div className="col-12">
                      <BasicInput
                        id={`card-last-digits-${1 + 1}`}
                        label="Ultimos 04 dígitos"
                        readOnly
                        helper="Ex: 2144"
                        value={installment.cardLastDigits ? installment.cardLastDigits.toString() : ''}
                        onChange={() => null}
                        type="number"
                        error=""
                      />
                    </div>
                    )}

                    {!installment.card && (
                    <div className="col-12">
                      <BasicInput
                        type="text"
                        id={`way-${1 + 1}`}
                        label="Forma de pagamento"
                        readOnly
                        helper="Ex: PIX"
                        error=""
                        value={installment.nonCardTaxes !== null ? installment.nonCardTaxes : ''}
                        onChange={() => null}
                      />

                    </div>
                    )}
                    {
                 installment.paidBy !== null && (
                   <div className="col-12">
                     <BasicInput
                       id="baixa-realiazada-por"
                       label="Baixa realizada por"
                       readOnly
                       helper=""
                       error=""
                       value={installment.paidBy}
                       type="text"
                       onChange={() => null}
                     />
                   </div>
                 )
             }
                    {
                 installment.paymentDate !== null && (
                   <div className="col-12">
                     <BasicInput
                       id="data-da-baixa"
                       label="Data da baixa"
                       readOnly
                       helper=""
                       error=""
                       value={installment.paymentDate}
                       type="text"
                       onChange={() => null}
                     />
                   </div>
                 )
             }

                  </div>
                </div>
              </div>
            </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
