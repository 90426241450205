/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import BasicInput from '../../../components/BasicInput';
import { ActualPage, ContractDTO } from './CreateLead';
import { ProductsProps } from '../../../dto/product';
import PriceInput from '../../../components/PriceInput';
import { showIfDateOfParamsOneMajorThenParamsTwo } from '../../../helpers/dates';
import { maskDate } from '../../../helpers/inputMasks.js';
import api from '../../../config/axios';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import ErrorModal from '../ErrorModal';
import BasicOption from '../../../components/BasicOption';
import { ModalActionType, ModalProps } from '../../../components/Modal';

type CreateContractProps = {
    lifesAmount: number
    contract: ContractDTO,
    setContract: React.Dispatch<React.SetStateAction<ContractDTO>>
    setLoading: (arg: boolean) => void;
    setActualPage: (arg: ActualPage) => void;
    setModal: (arg: ModalProps | null) => void;
    products: ProductsProps[],
    selectedProduct: ProductsProps,
    setSelectedProduct: React.Dispatch<React.SetStateAction<ProductsProps>>
    changeNumberOfDependent: (arg: number) => void
    changePricePerHolder: (arg: number) => void
    changePricePerDependent: (arg: number) => void

}

export default function CreateContract({
  contract,
  setContract,
  lifesAmount,
  setLoading,
  setActualPage,
  setModal,
  products,
  selectedProduct,
  setSelectedProduct,
  changeNumberOfDependent,
  changePricePerHolder,
  changePricePerDependent,
}: CreateContractProps) {
  const [errors, setErrors] = useState<string[]>([]);
  const [errorsRef] = useState(React.createRef<any>());

  // function changeGlobalValue() {
  //   const globalContract = (contract.totalDependentsEnabled * selectedProduct.pricePerDependent) + selectedProduct.pricePerHolder;
  //   setContract({ ...contract, value: Number((globalContract * 12).toFixed(2)), productId: selectedProduct.id });
  // }

  function showDueDateErrors() {
    if (showIfDateOfParamsOneMajorThenParamsTwo(contract.signatureDate, contract.dueDate)) {
      return 'A data de vencimento deverá ser maior que a data de assinatura';
    } if (contract.dueDate.length < 10) {
      return 'Digite uma data válida';
    }
    return '';
  }

  function validate() {
    const errorsCtx: string[] = [showDueDateErrors()];
    if (errorsCtx[0] !== '') {
      setErrors(errorsCtx);
    } else {
      setErrors([]);
      setContract(contract);
      setActualPage(ActualPage.PAYMENT);
    }
  }

  function filterProductByName(name: string, ctx: ProductsProps[]) {
    const filteredProduct = ctx.filter((item) => item.name === name);
    if (filteredProduct.length > 0) {
      setSelectedProduct(filteredProduct[0]);
    }
  }

  // useEffect(() => {
  //   changeGlobalValue();
  // }, [selectedProduct]);

  useEffect(() => {
    if (errors.length > 0) {
      errorsRef.current.click();
    }
  }, [errors]);

  return (
    <div className="row products mt-4">
      <div className="col-12 card-bg p-4">
        <div className="row">
          <h1>Cadastro de Contrato</h1>
          <div className="col-4">
            <BasicInput
              id="total-dependentes-input"
              label="Qtd. Dependentes"
              readOnly
              helper="Ex: 20"
              error=""
              value={contract.totalDependentsEnabled.toString()}
              type="number"
              onChange={(data) => changeNumberOfDependent(Number(data))}
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="product-selected-input"
              label="Produto escolhido"
              readOnly={false}
              helper="Selecione o produto"
              error=""
              value={selectedProduct !== null ? selectedProduct.name : 'Nenhum produto encontrado'}
              onChange={(data) => filterProductByName(data, products)}
            >
              {products !== null ? products.map((item) => item.name) : ['Nenhum produto encontrado']}
            </BasicOption>
          </div>
          <div className="col-4">
            <PriceInput
              id="preco-titular-input"
              label="Preço por titular"
              readOnly={false}
              helper="Ex: R$20"
              error=""
              value={selectedProduct.pricePerHolder.toString()}
              type="number"
              onChange={(arg) => changePricePerHolder(Number(arg))}
            />
          </div>
          <div className="col-4">
            <PriceInput
              id="preco-dependentes-input"
              label="Preço por dependente"
              readOnly={false}
              helper="Ex: R$20"
              error=""
              value={selectedProduct.pricePerDependent.toString()}
              type="number"
              onChange={(data) => changePricePerDependent(Number(data))}
            />
          </div>
          <div className="col-4">
            <PriceInput
              id="global-value-input"
              label="Valor global do contrato"
              readOnly={false}
              helper="Ex: R$20"
              error=""
              value={contract.value.toString()}
              type="number"
              onChange={(data) => setContract({ ...contract, value: Number(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="date-start-input"
              label="Data de Início do contrato"
              readOnly={false}
              helper="Ex: 30-01-2022"
              error={contract.signatureDate.length < 10 ? 'Digite uma data válida' : ''}
              value={contract.signatureDate}
              type="text"
              onChange={(data) => setContract({ ...contract, signatureDate: maskDate(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="date-start-input"
              label="Data de Vencimento do contrato"
              readOnly={false}
              helper="Ex: 30-01-2022"
              error={showDueDateErrors()}
              value={contract.dueDate}
              type="text"
              onChange={(data) => setContract({ ...contract, dueDate: maskDate(data) })}
            />
          </div>
          <div className="col-12 d-flex justify-content-end">
            <ButtonTextIcon
              label="Voltar para titular"
              type={BtnType.PRIMARY}
              icon="arrow_left"
              active
              onClick={() => setModal({
                title: 'Tem certeza?',
                children: ['Caso deseja retroceder, será necessário preencher o produto novamente nesta aba, para que o cáculo seja executado novamente.'],
                actions: [{ label: 'Cancelar', onClick: () => setModal(null), type: ModalActionType.PRIMARY },
                  {
                    label: 'Ok, retroceder',
                    onClick: () => {
                      setActualPage(ActualPage.HOLDER);
                      setModal(null);
                    },
                    type: ModalActionType.DANGER,
                  }],
              })}
              ref={null}
            />
            <div className="px-2" />
            <ButtonTextIcon
              label="Ir para pagamento"
              type={BtnType.PRIMARY}
              icon="arrow_right"
              active
              onClick={() => validate()}
              ref={null}
            />

          </div>
        </div>
        <ErrorModal errors={errors} />
        <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#errormodal" ref={errorsRef}>
          Launch static backdrop modal
        </button>
      </div>

    </div>
  );
}
