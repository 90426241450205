/* eslint-disable no-shadow */
import React, { createRef } from 'react';

export enum BtnType {
    PRIMARY,
    SECONDARY,
    DANGER,
    ALERT,
    SUCCESS,
}

interface BtnIconProps {
    label: string;
    type: BtnType;
    icon: string;
    active: boolean;
    onClick: () => void;
    ref: React.RefObject<any> | null;
}

export default function ButtonTextIcon({
  label, type, icon, onClick, active, ref,
}: BtnIconProps) {
  if (active) {
    if (type === BtnType.PRIMARY) {
      return (
        <button
          type="button"
          className="btn-icon-primary"
          onClick={() => onClick()}
          ref={ref}
        >
          {label}
          <span className="material-icons-round">
            {icon}
          </span>
        </button>
      );
    } if (type === BtnType.SECONDARY) {
      return (
        <button
          type="button"
          className="btn-icon-secondary"
          onClick={() => onClick()}
        >
          {label}
          <span className="material-icons-round">
            {icon}
          </span>
        </button>
      );
    } if (type === BtnType.DANGER) {
      return (
        <button
          type="button"
          className="btn-icon-danger"
          onClick={() => onClick()}
          ref={ref}
        >
          {label}
          <span className="material-icons-round">
            {icon}
          </span>
        </button>
      );
    } if (type === BtnType.SUCCESS) {
      return (
        <button
          type="button"
          className="btn-icon-success"
          onClick={() => onClick()}
        >
          {label}
        </button>
      );
    } if (type === BtnType.ALERT) {
      return (
        <button
          type="button"
          className="btn-icon-primary ripple-primary"
          onClick={() => onClick()}
        >
          {label}

        </button>
      );
    }
  } else {
    if (type === BtnType.PRIMARY) {
      return (
        <button
          type="button"
          className="btn-icon-primary-inactive"
          onClick={() => null}
        >
          {label}
          <span className="material-icons-round">
            {icon}
          </span>
        </button>
      );
    } if (type === BtnType.SECONDARY) {
      return (
        <button
          type="button"
          className="btn-icon-secondary"
          onClick={() => onClick()}
        >
          {label}
          <span className="material-icons-round">
            {icon}
          </span>
        </button>
      );
    } if (type === BtnType.DANGER) {
      return (
        <button
          type="button"
          className="btn-icon-danger-inactive"
          onClick={() => null}
        >
          {label}
          <span className="material-icons-round">
            {icon}
          </span>
        </button>
      );
    } if (type === BtnType.SUCCESS) {
      return (
        <button
          type="button"
          className="btn-icon-primary ripple-primary"
          onClick={() => onClick()}
        >
          {label}
        </button>
      );
    } if (type === BtnType.ALERT) {
      return (
        <button
          type="button"
          className="btn-icon-primary ripple-primary"
          onClick={() => onClick()}
        >
          {label}

        </button>
      );
    }
  }
  return null;
}
