/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import BasicOption from '../../../../components/BasicOption';
import api from '../../../../config/axios';
import showListOfStringsOnTable from '../../../../helpers/showListOfStringsOnTable';
import ShowProfessionalModal from '../FilterProfessionals/ShowProfessionalModal';
import { AccreditedProps, CategoryProps } from '../FilterTable';

export type Professional = {
    accredited: AccreditedProps[],
    category: CategoryProps[],
    councilRegister?: string,
    id: number,
    name: string,
    phone?: string,
    whatsapp?: string,
}

export type ResponseProps = {
  data: Professional[],
  meta: {
    total: number,
    showing: number,
  }
}

interface FilterProfessionalProps {
    setLoading: (arg0: boolean) => void;
}

export default function FilterCategory({ setLoading }: FilterProfessionalProps) {
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [data, setData] = useState<Professional[]>([]);
  const [categories, setCategories] = useState<CategoryProps[]>([]);
  const [meta, setMeta] = useState({ total: 0, showing: 0 });
  const [selectedCategory, setSelectedCategory] = useState<CategoryProps>({
    title: '',
    id: 1000,
    isActive: true,
    type: 'medic',
  });

  async function fetchCategories() {
    try {
      setLoading(true);
      const response = await api.get<CategoryProps[]>('/admin/professional-category', token);
      const alphabeticSorted = response.data.sort((a, b) => a.title.charCodeAt(0) - b.title.charCodeAt(0));
      setCategories(alphabeticSorted);
      if (response.data.length > 0) {
        setSelectedCategory(response.data[0]);
      }
    } catch (err: any) {
      setLoading(false);
      console.log(err.response.data);
    }
  }

  async function fetchData() {
    try {
      setLoading(true);
      const response = await api.post<ResponseProps>('admin/accredited-list/category', {
        categoryId: selectedCategory.id,
      }, token);
      setData(response.data.data);
      setMeta(response.data.meta);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log(err.response.data);
    }
  }

  function filterCategory(title: string) {
    const filtered = categories.filter((item) => item.title === title);
    if (filtered.length > 0) {
      setSelectedCategory(filtered[0]);
    }
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedCategory]);
  return (
    <>
      <div className="row mb-2">
        <div className="col-12">
          <div className="d-flex justify-content-end">
            <div className="fetched-values-group">
              <div className="income-fetched-value-wrapper">
                <p className="" style={{ marginRight: 24 }}>
                  Exibindo:
                  {' '}
                  <span className="">{`${meta.showing} / ${meta.total}`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Profissionais por especialidade</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <div className="m-2 d-flex align-items-center">
                <div className="mt-1 installment-option">
                  <BasicOption
                    id="Especialidade"
                    label="Especialidade"
                    readOnly={false}
                    helper=""
                    error=""
                    value={selectedCategory.title}
                    onChange={(el) => filterCategory(el)}
                  >
                    {categories.map((item) => item.title)}
                  </BasicOption>
                </div>
              </div>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">Especialidade(s)</th>
                  <th scope="col">Telefone</th>
                  <th scope="col">WhatsApp</th>
                  <th scope="col">Vínculo(s)</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {data.length === 0 && (
                <p>Nenhum resultado</p>
                )}
                {data.map((item, i) => (
                  <>
                    <ShowProfessionalModal id={`show-professional-${item.id}`} data={item} />
                    {/* <ShowProfessionalModal id={`show-professional-${item.id}`} data={item} /> */}
                    <tr key={i}>
                      <td>{item.name}</td>
                      <td>{showListOfStringsOnTable(item.category.map((el) => el.title))}</td>
                      <td>{item.phone ? item.phone : 'N/A'}</td>
                      <td>{item.whatsapp ? item.whatsapp : 'N/A'}</td>
                      <td>{item.accredited.length}</td>
                      <td align="right">
                        <div className="d-flex justify-content-end align-items-center">
                          <div className="table-action-icon">
                            <button type="button" onClick={() => null} data-bs-toggle="modal" data-bs-target={`#show-professional-${item.id}`}>
                              <span className="material-icons-round">
                                more
                              </span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>

                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  );
}
