/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import BasicInput from '../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import TextAreaInput from '../../components/TextAreaInput';
import api from '../../config/axios';
import ContractPJProps from '../../dto/contractPJ';
import { maskCPF, maskDate } from '../../helpers/inputMasks.js';
import { validateCpf } from '../../helpers/validate';
import DefaultLayout from '../../Layout';
import { ContractPFProps } from '../Contracts/PF/types';

type Representantes = {
    name: string,
    cpf: string,
    paymentDetails: string,
    isActive: boolean
}

type ContractCtx = {
    id: number,
    uuid: string,
    sellerId: number,
    holderId: number | undefined,
    clientId: number | undefined
    productId: number,
    totalDependentsEnabled: number,
    totalLifes: number,
    pricePerHolder: number,
    pricePerDependent: number,
    contractGlobalValue: number,
    signatureDate: string,
    isPf: boolean,
    dueDate: string,
    isActive: boolean,
    createdAt: string,
    updatedAt: string,
}

export default function EditReffer() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` } });
  const [reffer, setReffer] = useState<Representantes>({
    name: '',
    cpf: '',
    paymentDetails: '',
    isActive: true,
  });
  const [contracts, setContracts] = useState<ContractCtx[]>([]);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  async function fetchContracts() {
    try {
      setLoading(true);
      const contractsResponse = await api.post<ContractCtx[]>('admin/reffer-contract', {
        id,
        dateInit: initialDate,
        dateFinal: finalDate,
      }, token);
      setContracts(contractsResponse.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
    }
  }

  async function fetchReffer() {
    try {
      setLoading(true);
      const response = await api.get(`/admin/reffer/${id}`, token);
      await fetchContracts();
      setReffer(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível encontrar representante. Verifique se a id do cabeçalho está correta', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
      setLoading(false);
    }
  }

  function validateRefferFields(): boolean {
    if (reffer.name.length >= 3 && validateCpf(reffer.cpf)) {
      return true;
    }
    return false;
  }

  async function updateReffer() {
    try {
      setLoading(true);
      await api.put(`/admin/reffer/${id}`, reffer, token);
      setLoading(false);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Não foi possível.', children: 'Não foi possível atualizar. Verifique se o CPF já não está cadastrado em nome de outro representante.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  function calculateBruteBloqued() {
    return contracts.filter((item) => !item.isActive).map((item) => item.contractGlobalValue).reduce((a, b) => a + b, 0);
  }

  function calculateNonBlockedBrute() {
    return contracts.filter((item) => item.isActive).map((item) => item.contractGlobalValue).reduce((a, b) => a + b, 0);
  }

  function calculateBrute() {
    return contracts.map((item) => item.contractGlobalValue).reduce((a, b) => a + b, 0);
  }
  useEffect(() => {
    fetchReffer();
  }, []);

  return (

    <DefaultLayout
      pageTitle="Editar Embaixador Master"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={null}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Editar Embaixador Master</h1>
          <div className="row">
            <div className="col-4">
              <BasicInput
                id="representante-nome"
                label="Nome"
                readOnly={false}
                helper="Ex. José Antunes"
                error={reffer.name.length < 3 ? 'Digite um nome válido' : ''}
                value={reffer.name}
                type="text"
                onChange={(data) => setReffer({ ...reffer, name: data })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="representante-cpf"
                label="CPF"
                readOnly={false}
                helper="Ex. 092.283.000-20"
                error={!validateCpf(reffer.cpf) ? 'Digite um CPF válido' : ''}
                value={reffer.cpf}
                type="text"
                onChange={(data) => setReffer({ ...reffer, cpf: maskCPF(data) })}
              />
            </div>
            <div className="col-12">
              <TextAreaInput
                id="representantes-obs"
                label="Observações"
                readOnly={false}
                helper="Ex. lembretes sobre dados de pagamento."
                error=""
                value={reffer.paymentDetails}
                onChange={(data) => setReffer({ ...reffer, paymentDetails: data })}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                active={validateRefferFields()}
                onClick={() => updateReffer()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end mb-2">
        <div className="fetched-values-group">
          <div className="income-fetched-value-wrapper">
            <p className="" style={{ marginRight: 24 }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#16C784" />
                <path d="M11.4758 19H12.5547L12.5598 17.7477C14.743 17.5781 15.9949 16.2875 16 14.4555C15.9949 12.5141 14.5038 11.6281 12.9364 11.2289L12.6005 11.1359L12.6209 8.01328C13.4351 8.16641 13.9593 8.69141 14.0407 9.47891H15.8575C15.827 7.73984 14.5547 6.47109 12.631 6.27422L12.6361 5H11.5573L11.5522 6.27422C9.64377 6.48203 8.26972 7.73984 8.27481 9.55C8.27481 11.1578 9.33842 12.082 11.0534 12.5578L11.5115 12.6836L11.4911 15.9922C10.5802 15.8391 9.92366 15.2812 9.85751 14.2914H8C8.07125 16.3422 9.38422 17.5617 11.4809 17.7477L11.4758 19ZM12.57 15.9922L12.5903 12.9898C13.5165 13.2797 14.1069 13.6625 14.112 14.4391C14.1069 15.2484 13.5013 15.8281 12.57 15.9922ZM11.5216 10.8406C10.8193 10.6 10.1781 10.2008 10.1883 9.42422C10.1934 8.71875 10.687 8.17188 11.542 8.01328L11.5216 10.8406Z" fill="white" />
              </svg>
              Total Bruto dos bloqueados:
              {' '}
              <span className="">{`R$ ${calculateBruteBloqued()}`}</span>
            </p>
          </div>
          <div className="income-fetched-value-wrapper">
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#16C784" />
                <path d="M11.4758 19H12.5547L12.5598 17.7477C14.743 17.5781 15.9949 16.2875 16 14.4555C15.9949 12.5141 14.5038 11.6281 12.9364 11.2289L12.6005 11.1359L12.6209 8.01328C13.4351 8.16641 13.9593 8.69141 14.0407 9.47891H15.8575C15.827 7.73984 14.5547 6.47109 12.631 6.27422L12.6361 5H11.5573L11.5522 6.27422C9.64377 6.48203 8.26972 7.73984 8.27481 9.55C8.27481 11.1578 9.33842 12.082 11.0534 12.5578L11.5115 12.6836L11.4911 15.9922C10.5802 15.8391 9.92366 15.2812 9.85751 14.2914H8C8.07125 16.3422 9.38422 17.5617 11.4809 17.7477L11.4758 19ZM12.57 15.9922L12.5903 12.9898C13.5165 13.2797 14.1069 13.6625 14.112 14.4391C14.1069 15.2484 13.5013 15.8281 12.57 15.9922ZM11.5216 10.8406C10.8193 10.6 10.1781 10.2008 10.1883 9.42422C10.1934 8.71875 10.687 8.17188 11.542 8.01328L11.5216 10.8406Z" fill="white" />
              </svg>
              Total Bruto dos não bloqueados:
              {' '}
              <span className="">{`R$ ${calculateNonBlockedBrute()}`}</span>
            </p>
          </div>
          <div className="income-fetched-value-wrapper" style={{ marginLeft: 24 }}>
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#16C784" />
                <path d="M11.4758 19H12.5547L12.5598 17.7477C14.743 17.5781 15.9949 16.2875 16 14.4555C15.9949 12.5141 14.5038 11.6281 12.9364 11.2289L12.6005 11.1359L12.6209 8.01328C13.4351 8.16641 13.9593 8.69141 14.0407 9.47891H15.8575C15.827 7.73984 14.5547 6.47109 12.631 6.27422L12.6361 5H11.5573L11.5522 6.27422C9.64377 6.48203 8.26972 7.73984 8.27481 9.55C8.27481 11.1578 9.33842 12.082 11.0534 12.5578L11.5115 12.6836L11.4911 15.9922C10.5802 15.8391 9.92366 15.2812 9.85751 14.2914H8C8.07125 16.3422 9.38422 17.5617 11.4809 17.7477L11.4758 19ZM12.57 15.9922L12.5903 12.9898C13.5165 13.2797 14.1069 13.6625 14.112 14.4391C14.1069 15.2484 13.5013 15.8281 12.57 15.9922ZM11.5216 10.8406C10.8193 10.6 10.1781 10.2008 10.1883 9.42422C10.1934 8.71875 10.687 8.17188 11.542 8.01328L11.5216 10.8406Z" fill="white" />
              </svg>
              Total Bruto Geral:
              {' '}
              <span className="">{`R$ ${calculateBrute()}`}</span>
            </p>
          </div>
        </div>
      </div>

      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Contratos</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <div className="m-2 d-flex align-items-center">
                <div className="mt-1">
                  <BasicInput
                    id="dt-initial"
                    label="Dt. Incial"
                    readOnly={false}
                    helper=""
                    error=""
                    value={initialDate}
                    type="text"
                    onChange={(el) => setInitialDate(maskDate(el))}
                  />
                </div>
                <div className="mt-1">
                  <BasicInput
                    id="dt-final"
                    label="Dt. Final"
                    readOnly={false}
                    helper=""
                    error=""
                    value={finalDate}
                    type="text"
                    onChange={(el) => setFinalDate(maskDate(el))}
                  />
                </div>
                <button className="table-btn-primary m-2" type="button" onClick={() => fetchContracts()}>
                  <span className="material-icons-round">
                    search
                  </span>
                  filtrar
                </button>
              </div>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Cliente</th>
                  <th scope="col">Dt Assinatura</th>
                  <th scope="col">Dt. Vencimento</th>
                  <th scope="col">Valor Global (R$)</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {contracts.length === 0 && 'Nenhum contrato vinculado'}
                {contracts.map((item, i) => (
                  <tr key={i}>
                    <td style={!item.isActive ? { textDecoration: 'line-through', color: 'orange' } : undefined}>{item.isPf ? `PF000${item.id}` : `PJ000${item.id}`}</td>
                    <td style={!item.isActive ? { textDecoration: 'line-through', color: 'orange' } : undefined}>
                      {item.isPf ? (
                        <Link to={`/cliente/pessoa-fisica/editar-titular/${item.holderId}`}>
                          {' '}
                          PF000
                          {item.holderId}

                        </Link>
                      ) : (
                        <Link to={`/cliente/empresa/editar/${item.clientId}`}>
                          {' '}
                          PJ000
                          {item.clientId}

                        </Link>
                      )}
                    </td>
                    <td style={!item.isActive ? { textDecoration: 'line-through', color: 'orange' } : undefined}>{item.signatureDate}</td>
                    <td style={!item.isActive ? { textDecoration: 'line-through', color: 'orange' } : undefined}>{item.dueDate}</td>
                    <td style={!item.isActive ? { textDecoration: 'line-through', color: 'orange' } : undefined}>{item.contractGlobalValue}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          {item.isPf ? (
                            <button type="button" onClick={() => navigate(`/contratos/pf/visualizar/${item.id}`)}>
                              <span className="material-icons-round">
                                more
                              </span>
                            </button>
                          ) : (
                            <button type="button" onClick={() => navigate(`/contratos/pj/editar/${item.id}`)}>
                              <span className="material-icons-round">
                                more
                              </span>
                            </button>
                          )}

                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </DefaultLayout>
  );
}
