/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { IModalContent } from '../StratificationModal';

interface IModalityProps {
    props: IModalContent | null
}
export default function Modality({
  props,
}: IModalityProps) {
  const [changeSliderMovement, setChangeSliderMovement] = useState(false);
  function calculatePercentage(total: number, target: number) {
    const result = (target * 100) / total;
    return result;
  }

  useEffect(() => {
    setTimeout(() => {
      setChangeSliderMovement(true);
    }, 200);
  }, []);
  return (
    <>
      <h3 className="fw-bold text-center mt-4 text-uppercase" style={{ color: '#616161' }}>
        Quantitativo geral
      </h3>
      <div className="item-container">
        <p className="fw-bold">
          Do total de
          {' '}
          {props!.holdersAmount + props!.dependentsAmount}
          {' '}
          usuários cadastrados no sistema dentre as datas.
        </p>
        <div className="item-wrapper">
          <p className="item-description">
            Total de titulares:
            {' '}
            <span>
              {props!.holdersAmount}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.holdersAmount).toFixed(2)}
              %
            </span>
          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.holdersAmount)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            total de dependentes:
            {' '}
            <span>
              {props ? props.dependentsAmount : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.dependentsAmount).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.dependentsAmount)}%` : 0 }} />
          </div>
        </div>
      </div>

      <h3 className="fw-bold text-center mt-4 text-uppercase" style={{ color: '#616161' }}>
        RELAÇÃO ENTRE DEPENDENTES E TITULARES
      </h3>
      <div className="item-container">
        <p className="fw-bold">
          Do total de
          {' '}
          {props!.dependentsAmount}
          {' '}
          dependentes cadastrados no sistema dentre as datas.
        </p>
        <div className="item-wrapper">
          <p className="item-description">
            CONJUGE:
            {' '}
            <span>
              {props!.dependentsSpouseAmount}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentsSpouseAmount).toFixed(2)}
              %
            </span>
          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentsSpouseAmount)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            FILHO(A):
            {' '}
            <span>
              {props ? props.dependentsChildAmount : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentsChildAmount).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentsChildAmount)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            OUTRO:
            {' '}
            <span>
              {props ? props.dependentsOtherRole : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentsOtherRole).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentsOtherRole)}%` : 0 }} />
          </div>
        </div>
      </div>

    </>
  );
}
