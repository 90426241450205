/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import BasicInput from '../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import {
  maskCep, maskCPF, maskCPForCNPJ, maskDate, maskNumber, maskPhone, unmaskData,
} from '../../helpers/inputMasks.js';
import { validateCnpjOrCpf, validateCpf } from '../../helpers/validate';
import DefaultLayout from '../../Layout';
import BasicOption from '../../components/BasicOption';
import { statesOfBrazil } from '../../dto/states';
import api from '../../config/axios';
import rgEmittent from '../../dto/rgEmittent';
import showApiErrors from '../../helpers/showApiErrors';
import { removeSomeObjectFields } from '../../helpers/removeObjectField';
import HolderProps, { BlockHistoryHolderOrDependentProps } from '../../dto/holder';
import SwitcherPrimary from '../../components/SwitcherPrimary';
import { DependentProps } from '../../dto/dependent';
import { fetchClientByIdAndCheckIfLimitLifeIsNotFulfilled } from '../../helpers/checkContractLimit';
import CEPFinder from '../../helpers/findAddressByCep';
import AddInput from '../../components/AddInput';
import Card, { ICard } from '../../components/Card/Card';
import BlockPermaDependentPJModal from './BlockPermaDependentModal';
import Tooltip from '../../components/Tooltip';
import TextAreaInput from '../../components/TextAreaInput';
import UnblockClientModal from '../../components/UnblockPerma';
import MigrationModal from '../../components/MigrationModal';
import UpdateUserModal from '../../components/UpdateUserModal';

export default function EditHolder() {
  const { id } = useParams();
  const { clientId } = useParams();
  const [appData, setAppData] = useState<{
    cpf: string,
    email: string
  }>();
  const navigate = useNavigate();
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [loading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [expandHolderData, setExpandHolderData] = useState(false);
  const [expandDependentData, setExpandDependentData] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [holder, setHolder] = useState<HolderProps>({
    id: 1,
    uuid: '',
    name: '',
    cardname: '',
    email: '',
    instagram: '',
    sex: '',
    birthday: '',
    cpf: '',
    rg: '',
    emitent: '',
    blockPerma: false,
    blockPermaReason: null,
    blockTemp: false,
    addressStreet: '',
    phone2: '',
    observation: '',
    addressNumber: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    addressState: '',
    addressZipcode: '',
    addressIbge: '',
    phone: '',
    enableTelemedicine: false,
    isActive: false,
    dependent: [],
    blockHistory: [],
  });

  const [cardData, setCardData] = useState<ICard>();
  const [isCardFront, setCardFront] = useState(false);

  async function changeAddressByCep() {
    try {
      setLoading(true);
      const findCep = await new CEPFinder(unmaskData(holder.addressZipcode)).findCep();
      if (findCep !== null) {
        setHolder({
          ...holder,
          addressCity: findCep.city,
          addressState: findCep.state,
          addressNeighborhood: findCep.neighborhood,
          addressStreet: findCep.street,
          addressIbge: findCep.ibge,
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  async function fetchCard(cpf: string) {
    try {
      setLoading(true);
      const response = await api.post('/admin/card-generator/', { cpf }, token);
      setCardData(response.data.card);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  async function fetchHolder() {
    try {
      setLoading(true);
      const response = await api.get(`admin/holder/${id}`, token);
      const newHolder = removeSomeObjectFields(response.data, ['id', 'uuid']);
      setHolder({
        ...newHolder,
        cpf: maskCPF(newHolder.cpf),
        phone: maskPhone(newHolder.phone),
        addressZipcode: maskCep(newHolder.addressZipcode),
        phone2: response.data.phone2 ? maskPhone(response.data.phone2) : '',
        observation: response.data.observation || '',
      });
      fetchCard(response.data.cpf);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro ao buscar', children: 'Titular não encontrado. Verifique a URL ou sua conectividade.', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
    }
  }

  async function saveHolder() {
    try {
      setLoading(true);
      await api.put(`admin/holder/${id}`, {
        ...holder,
        cpf: unmaskData(holder.cpf),
        cardname: holder.cardname || null,
        instagram: holder.instagram ? holder.instagram : null,
        email: holder.email ? holder.email : null,
        observation: holder.observation && holder.observation.length > 0 ? holder.observation : null,
        phone2: holder.phone2 && holder.phone2.length > 0 ? unmaskData(holder.phone2) : null,
      }, token);
      setLoading(false);
      await fetchHolder();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      if (typeof err.response !== 'undefined') {
        if (err.response.status === 422) {
          setModal(showApiErrors(setModal, err.response.data.errors));
        }
      } else {
        setModal({ title: 'Ocorreu um erro ao atualizar', children: 'Não foi possível atualizar um titular. Caso o problema persista, favor entrar em contato com suporte.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }
      setLoading(false);
    }
  }

  function enabledButton() {
    if (holder.name.length > 0
        && validateCpf(holder.cpf)
        && holder.addressStreet.length > 0
        && holder.addressNumber.length > 0
        && holder.addressNeighborhood.length > 0
        && holder.addressCity.length > 0
        && holder.addressCity.length > 0
        && holder.addressZipcode.length >= 10
        && holder.phone.length > 2
        && !readOnly
    ) {
      return true;
    }
    return false;
  }

  function resolveBlockStyle(block: BlockHistoryHolderOrDependentProps) {
    if (block.type === 'perma' && block.input === 'in') {
      return 'td-block-perma';
    }
    if (block.type === 'perma' && block.input === 'out') {
      return 'td-unblock';
    }
    if (block.input === 'out') {
      return 'td-unblock';
    }
    return 'td-block-temp';
  }

  async function fetchCpf() {
    try {
      setLoading(true);
      const response = await api.post('admin/manage/user', {
        cpf: unmaskData(holder.cpf),
      }, token);
      setAppData({ ...response.data });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCpf();
  }, [holder.cpf]);

  useEffect(() => {
    fetchHolder();
  }, []);

  return (
    <DefaultLayout
      pageTitle={`${holder?.name} > Editar`}
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row holders card-bg p-0">
        <input type="checkbox" id="expand-holders-data" checked={expandHolderData} />
        <section className="row">
          <h1>Aderente Titular</h1>
          {typeof cardData !== 'undefined' && (
            <div className="row mb-4 card-wrapper">
              <Card
                id="usercard"
                uuid={id}
                registration={cardData.registration}
                name={cardData.name}
                cardname={cardData.cardname}
                cpf={cardData.cpf}
                expiration={cardData.expiration}
                birth={cardData.birth}
                modality={cardData.modality}
                category={cardData.category}
                front={isCardFront}
                setCardSide={() => setCardFront((prev) => !prev)}
                isHolder
                printedCards={holder.printedCards!}
              />
            </div>
          )}
          <div className="col-4">
            <BasicInput
              id="name-input"
              label="Nome"
              readOnly={readOnly}
              helper="Ex: João Batista"
              error={holder.name.length < 1 ? 'Campo Obrigatório' : ''}
              value={holder.name}
              type="text"
              onChange={(data) => setHolder({ ...holder, name: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="card-name-input"
              label="Nome no cartão"
              readOnly={false}
              helper="Ex: João B. C. Vieira"
              error=""
              value={holder.cardname === null ? '' : holder.cardname}
              type="text"
              onChange={(data) => setHolder({ ...holder, cardname: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="cpf-input"
              label="CPF"
              readOnly={readOnly}
              helper="Ex: 000.000.000-10"
              error={!validateCnpjOrCpf(holder.cpf) ? 'Digite um CPF válido.' : ''}
              value={holder.cpf}
              type="text"
              onChange={(data) => setHolder({ ...holder, cpf: maskCPForCNPJ(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="rg-input"
              label="RG"
              readOnly={readOnly}
              helper="Ex: 99999999"
              error=""
              value={holder.rg}
              type="text"
              onChange={(data) => setHolder({ ...holder, rg: maskNumber(data) })}
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="rg-emittent-input"
              label="RG / Emissor"
              readOnly={readOnly}
              helper="Ex: SSP/MA"
              error=""
              value={holder.emitent}
              onChange={(data) => setHolder({ ...holder, emitent: data })}
            >
              {rgEmittent}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicOption
              id="sex-input"
              label="Sexo"
              readOnly={readOnly}
              helper="Ex: Masculino / Feminino"
              error=""
              value={holder.sex === 'm' ? 'Masculino' : 'Feminino'}
              onChange={(data) => setHolder({ ...holder, sex: data === 'Masculino' ? 'm' : 'f' })}
            >
              {['Masculino', 'Feminino']}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicInput
              id="birth-input"
              label="DT. Nascimento"
              readOnly={readOnly}
              helper="Ex: 13-01-1990"
              error={holder.birthday.length < 1 ? 'Campo obrigatório' : ''}
              value={holder.birthday}
              type="text"
              onChange={(data) => setHolder({ ...holder, birthday: maskDate(data) })}
            />
          </div>
          <div className="col-4">
            <AddInput
              id="address-cep-input"
              label="CEP"
              readOnly={false}
              helper="Ex: 35.171-120"
              error={holder.addressZipcode.length < 10 ? 'Campo obrigatório' : ''}
              value={holder.addressZipcode}
              type="text"
              onAdd={() => changeAddressByCep()}
              onChange={(data) => setHolder({ ...holder, addressZipcode: maskCep(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-street-input"
              label="Rua"
              readOnly={readOnly}
              helper="Ex: Rua Três"
              error={holder.addressStreet.length < 2 ? 'Campo Obrigatório' : ''}
              value={holder.addressStreet}
              type="text"
              onChange={(data) => setHolder({ ...holder, addressStreet: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-number-input"
              label="Número"
              readOnly={readOnly}
              helper="Ex: 133"
              error={holder.addressNumber.length < 1 ? 'Campo Obrigatório' : ''}
              value={holder.addressNumber}
              type="text"
              onChange={(data) => setHolder({ ...holder, addressNumber: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-complement-input"
              label="Complemento"
              readOnly={readOnly}
              helper="Ex: Bloco A"
              error=""
              value={holder.addressComplement}
              type="text"
              onChange={(data) => setHolder({ ...holder, addressComplement: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-neighborhood-input"
              label="Bairro"
              readOnly={readOnly}
              helper="Centro"
              error={holder.addressNeighborhood.length < 1 ? 'Campo obrigatório' : ''}
              value={holder.addressNeighborhood}
              type="text"
              onChange={(data) => setHolder({ ...holder, addressNeighborhood: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-city-input"
              label="Cidade"
              readOnly
              helper="Ex: Imperatriz"
              error={holder.addressCity.length < 1 ? 'Campo obrigatório' : ''}
              value={holder.addressCity}
              type="text"
              onChange={(data) => setHolder({ ...holder, addressCity: data })}
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="address-state-input"
              label="Estado"
              readOnly
              helper=""
              error=""
              value={holder.addressState}
              onChange={(data) => setHolder({ ...holder, addressState: data })}
            >
              {statesOfBrazil}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicInput
              id="phone-input"
              label="Telefone"
              readOnly={readOnly}
              helper="Ex: (31) 99999 - 9999"
              error={holder.phone.length < 1 ? 'Campo obrigatório' : ''}
              value={holder.phone}
              type="text"
              onChange={(data) => setHolder({ ...holder, phone: maskPhone(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="phone2-input"
              label="Telefone 2"
              readOnly={false}
              helper="Ex: (31) 99999 - 9999"
              error=""
              value={holder.phone2 || ''}
              type="text"
              onChange={(data) => setHolder({ ...holder, phone2: maskPhone(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="email-input"
              label="email"
              readOnly={false}
              helper="Ex: usuario@usuario.com"
              error={holder.email && holder.email.length > 0 && !EmailValidator.validate(holder.email) ? 'E-mail inválido' : ''}
              value={holder.email ? holder.email : ''}
              type="text"
              onChange={(data) => setHolder({ ...holder, email: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="instagram-input"
              label="instagram"
              readOnly={false}
              helper="Ex: valdecir_roberto"
              error=""
              value={holder.instagram ? holder.instagram : ''}
              type="text"
              onChange={(data) => setHolder({ ...holder, instagram: data })}
            />
          </div>
          <div className="col-12">
            <TextAreaInput
              id=""
              label="Observação"
              readOnly={false}
              helper=""
              error=""
              value={holder.observation || ''}
              onChange={(data) => setHolder({ ...holder, observation: data })}
            />
          </div>
          <div className="col-12 optionals mt-4 mb-4">
            <p>Opcionais</p>
            <div className="check-wrapper">
              <input type="checkbox" checked={holder.enableTelemedicine} onChange={() => setHolder({ ...holder, enableTelemedicine: !holder.enableTelemedicine })} />
              <p>Telemedicina</p>
            </div>
          </div>
          <div className="col-12 footer">
            <ButtonTextIcon
              label="salvar"
              type={BtnType.PRIMARY}
              icon="backup"
              active={enabledButton()}
              onClick={() => saveHolder()}
              ref={null}
            />
          </div>
        </section>
      </div>

      <div className="row holders card-bg p-0 mt-4">
        <input type="checkbox" id="expand-dependent-data" checked={expandDependentData} />
        <section className="row">
          <h1>Dependentes</h1>
          <button
            type="button"
            onClick={() => setExpandDependentData((prev) => !prev)}
            className="expand-dependent-btn"
          >
            <span className="material-icons-round">
              expand_more
            </span>
          </button>
          <div className="col-12 mt-4">
            <div className="table-wrapper">
              <div className="table-actions">
                <button
                  className="table-btn-primary"
                  type="button"
                  onClick={() => navigate(`/cliente/adicionar-dependente/${clientId}/${id}`)}
                >
                  <span className="material-icons-round">
                    add
                  </span>
                  adicionar
                </button>
              </div>
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Titular</th>
                    <th scope="col">CPF / CNPJ</th>
                    <th className="text-end" scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {holder.dependent.length < 1 ? (
                    <p className="text-align-center text-bold">Nenhum dependente cadastrado</p>
                  ) : (
                    holder.dependent.map((item, i) => (
                      <tr key={i}>
                        <td className={item.blockPerma ? 'link-red' : (item.blockTemp ? 'link-orange' : '')}>{item.name}</td>
                        <td>{holder.name}</td>
                        <td>{maskCPF(item.cpf)}</td>
                        <td align="right">
                          <div className="d-flex justify-content-end align-items-center">
                            {/* <SwitcherPrimary
                              id={`status-changer-${i}`}
                              onChange={() => setDependentStatus(item)}
                              isActive={item.isActive}
                            /> */}
                            <div className="table-action-icon">
                              <button
                                type="button"
                                aria-describedby="manual"
                                id="unblock-perma"
                                data-bs-toggle="modal"
                                data-bs-target={`#migration-modal-${item.id}`}
                              >
                                <span className="material-icons-round" style={{ color: 'orange' }}>
                                  transfer_within_a_station
                                </span>
                              </button>
                              <Tooltip id="migration" text="Essa ação irá migrar o usuário" />
                              <MigrationModal dependentUuid={item.uuid} id={`migration-modal-${item.id}`} holderId={item.id} type="dependentpj" name={item.name} />
                            </div>
                            {!item.blockPerma && (
                            <>
                              <div className="table-action-icon">
                                <button
                                  type="button"
                                  onClick={() => null}
                                  aria-describedby="manual"
                                  id="bloq-perma-dependent"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#bloq-perma-dependent-${item.uuid}`}
                                >
                                  <span className="material-icons-round" style={{ color: 'red' }}>
                                    cancel
                                  </span>
                                </button>
                                <Tooltip id="bloq-perma-dependent" text="Essa ação irá cancelar o dependente." />
                              </div>
                              <BlockPermaDependentPJModal
                                id={item.uuid}
                                setLoading={(arg) => setLoading(arg)}
                              />
                            </>
                            )}
                            {item.blockPerma && (
                            <div className="table-action-icon">
                              <button
                                type="button"
                                aria-describedby="manual"
                                id="unblock-perma"
                                data-bs-toggle="modal"
                                data-bs-target={`#unblock-perma-${item.id}`}
                              >
                                <span className="material-icons-round" style={{ color: 'orange' }}>
                                  lock_open
                                </span>
                              </button>
                              <Tooltip id="bloq-perma" text="Essa ação irá desbloquear o usuário." />
                              <UnblockClientModal type="dependentpj" userId={item.uuid.toString()} id={`unblock-perma-${item.id}`} />
                            </div>
                            )}
                            <div className="table-action-icon">
                              <button type="button" onClick={() => navigate(`/cliente/editar-dependente/${item.uuid}`)}>
                                <span className="material-icons-round">
                                  more
                                </span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>

      <div className="row products mt-4">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Histórico de bloqueios / desbloqueios</h1>
          <div className="table-wrapper">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Bloqueio / Cancelamento</th>
                  <th scope="col">Data</th>
                  <th scope="col">Motivo</th>
                  <th scope="col">Responsável</th>
                </tr>
              </thead>
              <tbody>
                {holder.blockHistory.length > 0 ? holder.blockHistory.map((item, i) => (
                  <tr key={i}>
                    <td className={resolveBlockStyle(item)}>{(item.input === 'in' ? 'Bloqueio' : 'Desbloqueio')}</td>
                    <td className={resolveBlockStyle(item)}>{item.blockDate}</td>
                    <td className={resolveBlockStyle(item)}>{item.reason}</td>
                    <td className={resolveBlockStyle(item)}>{item.blockedBy}</td>
                  </tr>
                )) : (
                  <p>Não existe(m) bloqueio(s) para esse usuário.</p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row products mt-4">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Histórico de bloqueios / desbloqueios</h1>
          <div className="table-wrapper">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Bloqueio / Cancelamento</th>
                  <th scope="col">Data</th>
                  <th scope="col">Motivo</th>
                  <th scope="col">Responsável</th>
                </tr>
              </thead>
              <tbody>
                {holder.blockHistory.length > 0 ? holder.blockHistory.map((item, i) => (
                  <tr key={i}>
                    <td className={resolveBlockStyle(item)}>{(item.input === 'in' ? 'Bloqueio' : 'Desbloqueio')}</td>
                    <td className={resolveBlockStyle(item)}>{item.blockDate}</td>
                    <td className={resolveBlockStyle(item)}>{item.reason}</td>
                    <td className={resolveBlockStyle(item)}>{item.blockedBy}</td>
                  </tr>
                )) : (
                  <p>Não existe(m) bloqueio(s) para esse titular.</p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row products mt-4">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Alterar acesso ao APP</h1>
          <div className="table-wrapper">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">CPF</th>
                  <th scope="col">Email</th>
                  <th scope="col" className="text-end" align="right">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {appData ? (
                    <>
                      <td>{maskCPF(appData.cpf)}</td>
                      <td>{appData.email}</td>
                      <td align="right">
                        <div className="d-flex justify-content-end align-items-center">
                          <div className="table-action-icon">
                            <button type="button" data-bs-toggle="modal" data-bs-target="#updateModal">
                              <span className="material-icons-round">
                                more
                              </span>
                            </button>
                          </div>
                        </div>
                      </td>

                    </>
                  ) : (
                    <td>Cliente ainda nao realizou o cadastro no website!</td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {appData && (
        <UpdateUserModal emailProps={appData.email} cpf={maskCPF(appData.cpf)} />
      )}
    </DefaultLayout>

  );
}
