/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { IModalContent } from '../StratificationModal';

interface IAgeProps {
    props: IModalContent | null
}
export default function Age({
  props,
}: IAgeProps) {
  const [changeSliderMovement, setChangeSliderMovement] = useState(false);
  function calculatePercentage(total: number, target: number) {
    const result = (target * 100) / total;
    return result;
  }

  useEffect(() => {
    setTimeout(() => {
      setChangeSliderMovement(true);
    }, 200);
  }, []);
  return (
    <>
      <h3 className="fw-bold text-center mt-4 text-uppercase" style={{ color: '#616161' }}>
        IDADE - Quantitativo geral
      </h3>
      <div className="item-container">
        <p className="fw-bold">
          Do total de
          {' '}
          {props!.holdersAmount + props!.dependentsAmount}
          {' '}
          usuários cadastrados no sistema dentre as datas.
        </p>
        <div className="item-wrapper">
          <p className="item-description">
            menores de 18 anos:
            {' '}
            <span>
              {props!.totalMinorThen18}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalMinorThen18).toFixed(2)}
              %
            </span>
          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalMinorThen18)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            18 a 50 anos:
            {' '}
            <span>
              {props ? props.totalBetween18and50 : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalBetween18and50).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalBetween18and50)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            51 a 65 anos:
            {' '}
            <span>
              {props ? props.totalBetween51and65 : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalBetween51and65).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalBetween51and65)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            mais de 65 anos:
            {' '}
            <span>
              {props ? props.totalMajorThen65 : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalMajorThen65).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalMajorThen65)}%` : 0 }} />
          </div>
        </div>
      </div>

      <h3 className="fw-bold text-center mt-4 text-uppercase" style={{ color: '#616161' }}>
        IDADE - Apenas titulares
      </h3>
      <div className="item-container">
        <p className="fw-bold">
          Do total de
          {' '}
          {props!.holdersAmount}
          {' '}
          titulares cadastrados no sistema dentre as datas.
        </p>
        <div className="item-wrapper">
          <p className="item-description">
            menores de 18 anos:
            {' '}
            <span>
              {props!.holdersMinorThen18}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount, props!.holdersMinorThen18).toFixed(2)}
              %
            </span>
          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount, props!.holdersMinorThen18)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            18 a 50 anos:
            {' '}
            <span>
              {props ? props.holdersBetween18and50 : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount, props!.holdersBetween18and50).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount, props!.holdersBetween18and50)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            51 a 65 anos:
            {' '}
            <span>
              {props ? props.holdersBetween51and65 : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount, props!.holdersBetween51and65).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount, props!.holdersBetween51and65)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            mais de 65 anos:
            {' '}
            <span>
              {props ? props.holdersMajorThen65 : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount, props!.holdersMajorThen65).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount, props!.holdersMajorThen65)}%` : 0 }} />
          </div>
        </div>
      </div>

      <h3 className="fw-bold text-center mt-4 text-uppercase" style={{ color: '#616161' }}>
        IDADE - Apenas dependentes
      </h3>
      <div className="item-container">
        <p className="fw-bold">
          Do total de
          {' '}
          {props!.dependentsAmount}
          {' '}
          dependentes cadastrados no sistema dentre as datas.
        </p>
        <div className="item-wrapper">
          <p className="item-description">
            menores de 18 anos:
            {' '}
            <span>
              {props!.dependentsMinorThen18}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentsMinorThen18).toFixed(2)}
              %
            </span>
          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentsMinorThen18)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            18 a 50 anos:
            {' '}
            <span>
              {props ? props.dependentsBetween18and50 : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentsBetween18and50).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentsBetween18and50)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            51 a 65 anos:
            {' '}
            <span>
              {props ? props.dependentsBetween51and65 : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentsBetween51and65).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentsBetween51and65)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            mais de 65 anos:
            {' '}
            <span>
              {props ? props.dependentsMajorThen65 : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentsMajorThen65).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentsMajorThen65)}%` : 0 }} />
          </div>
        </div>
      </div>
    </>
  );
}
