/* eslint-disable max-len */
import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import distinctColors from 'distinct-colors';
import download from 'downloadjs';
import showCompactName from '../../helpers/showCompactName';
import api from '../../config/axios';

export interface IPrimaryModalContent {
    series: string[],
    data: number[],
    labelText: string,
    id: string,
    initialDate: string,
    finalDate: string,
    title: string
}

export default function PrimaryModalContent({
  series, data, id, title, initialDate, finalDate, labelText,
}: IPrimaryModalContent) {
  const [loading, setLoading] = useState(false);

  async function fetchToExcel() {
    try {
      setLoading(true);
      const response = await api.post('/admin/reports/byperiod/dataseriestoexcel', {
        data,
        series,
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, `${title}.xlsx`);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function showPercentage(total: number, current: number) {
    return (current * 100) / total;
  }

  // function resolveLegend() {
  //   const total = data.length > 0 ? data.reduce((a, b) => a + b) : 0;
  //   const seriesCtx: string[] = [];
  //   for (let x = 0; x < data.length; x += 1) {
  //     const str = `${series[x]} - ${data[x]} - ${showPercentage(total, data[x])}%`;
  //     seriesCtx.push(str);
  //   }

  //   return seriesCtx;
  // }

  const apexOptions = {
    options: {
      chart: {
        type: 'bar',
        height: 380,
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom',
          },
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#212121'],
        },
        formatter(val: any, opt: any) {
          return `${opt.w.globals.labels[opt.dataPointIndex]}:  ${val}`;
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      xaxis: {
        categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
          'United States', 'China', 'India',
        ],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      title: {
        text: 'DATA TESTE',
        align: 'center',
        floating: true,
      },
      subtitle: {
        text: 'Category Names as DataLabels inside bars',
        align: 'center',
      },
      tooltip: {
        theme: 'dark',
        x: {
          show: false,
        },
        y: {
          title: {
            formatter() {
              return '';
            },
          },
        },
      },
    },
  };

  const colorPallete = distinctColors({ count: data.length }).map((item) => item.hex());

  return (
    <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Relatório</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body modal-report">
            {/* <h2 className="title">{title}</h2>
            <h3 className="subtitle">{`${initialDate} - ${finalDate}`}</h3> */}
            <div className="graph-wrapper">
              {/* <ReactApexChart
                options={apexOptions}
                series={data}
                type="pie"
                height={640}
                toolbar={{ show: false }}
              /> */}
              <ReactApexChart
                options={{
                  ...apexOptions,
                  grid: { show: false },
                  colors: colorPallete,
                  plotOptions: {
                    bar: {
                      barHeight: '100%',
                      distributed: true,
                      horizontal: true,
                      dataLabels: {
                        position: 'bottom',
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                      colors: ['#000000'],
                    },
                    formatter(val: any, opt: any) {
                      return `${labelText}:  ${val}`;
                    },
                  },
                  title: {
                    text: title,
                    align: 'center',
                    floating: true,
                  },
                  subtitle: {
                    text: `${initialDate} - ${finalDate} -- TOTAL: ${data.length > 0 ? data.reduce((a, b) => a + b) : 0}`,
                    align: 'center',
                  },
                  xaxis: { categories: series.map((item) => showCompactName(item)), type: 'category' },
                }}
                series={[{ name: labelText, data }]}
                type="bar"
                height={200 + data.length * 52}
                toolbar={{ show: false }}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => fetchToExcel()}
            >
              {loading ? 'Carregando' : 'Baixar para Excel'}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Fechar

            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
