import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import SwitcherPrimary from '../../components/SwitcherPrimary';
import api from '../../config/axios';
import SellerProps from '../../dto/seller';
import { maskCPF, maskCPForCNPJ } from '../../helpers/inputMasks.js';
import showApiErrors from '../../helpers/showApiErrors';
import DefaultLayout from '../../Layout';

export default function Sellers() {
  const navigate = useNavigate();
  const [sellers, setSellers] = useState<SellerProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({
    headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` },
  });
  const [showActive, setShowActive] = useState(
    JSON.parse(localStorage.getItem('showActive_sellers') || 'true'),
  );
  const [showInactive, setShowInactive] = useState(
    JSON.parse(localStorage.getItem('showInactive_sellers') || 'false'),
  );

  async function fetchSeller() {
    try {
      setLoading(true);
      const response = await api.get('/admin/seller', token);
      setSellers(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({
        title: 'Ocorreu um erro',
        children: 'Não foi possível carregar a lista de vendedores',
        actions: [
          { label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY },
        ],
      });
    }
  }

  async function setVisibility(seller: SellerProps) {
    try {
      setLoading(true);
      await api.put(
        `/admin/seller/${seller.id}`,
        {
          isActive: !seller.isActive,
        },
        token,
      );
      fetchSeller();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      if (typeof err.response !== 'undefined') {
        if (err.response.status === 422) {
          setModal(showApiErrors(setModal, err.response.data.errors));
        } else {
          setShowToastError(true);
          setTimeout(() => {
            setShowToastError(false);
          }, 3000);
          setModal({
            title: 'Ocorreu um erro',
            children: 'Não foi possível alterar vendedor',
            actions: [
              {
                label: 'Ok, entendi',
                onClick: () => setModal(null),
                type: ModalActionType.PRIMARY,
              },
            ],
          });
        }
      }
      console.log(err.response);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchSeller();
  }, []);

  useEffect(() => {
    localStorage.setItem('showActive_sellers', JSON.stringify(showActive));
    localStorage.setItem('showInactive_sellers', JSON.stringify(showInactive));
  }, [showActive, showInactive]);

  const filteredSellers = sellers.filter((seller) => {
    if (showActive && showInactive) return true;
    if (showActive) return seller.isActive;
    if (showInactive) return !seller.isActive;
    return false;
  });

  if (sellers.length < 1) {
    return (
      <DefaultLayout
        pageTitle="Vendedores"
        loading={loading}
        showToastSuccess={showToastSuccess}
        showToastError={showToastError}
        showRightSlider={false}
        rightSliderContent={null}
        modal={modal}
        setCloseRightSlider={() => null}
      >
        <div className="not-exsits">
          <span className="material-icons-round">not_interested</span>
          <h3 className="table-not-exists">Não há vendedor cadastrado.</h3>
          <ButtonTextIcon
            label="Adicionar vendedor"
            type={BtnType.PRIMARY}
            icon="add"
            active
            onClick={() => navigate('/vendedores/adicionar')}
            ref={null}
          />
        </div>
      </DefaultLayout>
    );
  }
  return (
    <DefaultLayout
      pageTitle="Vendedores"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Vendedores</h1>

          <div className="table-wrapper">
            <div className="table-actions">
              <button
                className="table-btn-primary"
                type="button"
                onClick={() => navigate('/vendedores/adicionar')}
              >
                <span className="material-icons-round">add</span>
                adicionar
              </button>
              <div className="d-flex align-items-center">
                <div className="form-check lh-lg">
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    id="showInactive"
                    checked={showInactive}
                    onChange={() => setShowInactive(!showInactive)}
                  />
                  <label
                    className="form-check-label text-secondary mb-0 fs-7"
                    htmlFor="showInactive"
                  >
                    Mostrar Inativos
                  </label>
                </div>
              </div>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">Email</th>
                  <th scope="col">CPF / CNPJ</th>
                  <th className="text-end" scope="col">
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredSellers.map((item, i) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{maskCPForCNPJ(item.cpfCnpj)}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <SwitcherPrimary
                          id={`status-changer-${i}`}
                          onChange={() => setVisibility(item)}
                          isActive={item.isActive}
                        />
                        <div className="table-action-icon">
                          <button
                            type="button"
                            onClick={() => navigate(`/vendedores/editar/${item.id}`)}
                          >
                            <span className="material-icons-round">more</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
