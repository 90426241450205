import React, { useState } from 'react';
import BasicInput from '../../components/BasicInput';
import { maskDate } from '../../helpers/inputMasks.js';

type FilterProps = {
    dueInitialDate: string;
    dueFinalDate: string;
    setDueInitialDate: (arg0: string) => void;
    setDueFinalDate: (arg0: string) => void;
    projectionInitialDate: string;
    projectionFinalDate: string;
    setProjectionInitialDate: (arg0: string) => void;
    setProjectionFinalDate: (arg0: string) => void;
    projectionDateActive: boolean;
    setProjectionDateActive: () => void;
    dueDateActive: boolean;
    setDueDateActive: () => void;
    removeAllFilters: () => void;
    filter: () => void;
}

export default function FutureIncomesModalFilter({
  dueFinalDate,
  dueInitialDate,
  setDueFinalDate,
  setDueInitialDate,
  projectionFinalDate,
  projectionInitialDate,
  setProjectionFinalDate,
  setProjectionInitialDate,
  projectionDateActive,
  setProjectionDateActive,
  dueDateActive,
  setDueDateActive,
  removeAllFilters,
  filter,
}: FilterProps) {
  return (
    <div className="modal fade" id="profitFilter" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Filtro</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="filter-wrapper">
              <div className="activate">
                <input type="checkbox" onChange={() => setDueDateActive()} checked={dueDateActive} />
                <p>Data de vencimento</p>
              </div>
              <div className="filter-date-input-wrapper">
                <div className="" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data Incial"
                    readOnly={!dueDateActive}
                    helper=""
                    error=""
                    value={dueInitialDate}
                    type="text"
                    onChange={(data) => setDueInitialDate(maskDate(data))}
                  />
                </div>
                <div className="" style={{ paddingLeft: 4 }}>
                  <BasicInput
                    id=""
                    label="Data Final"
                    readOnly={!dueDateActive}
                    helper=""
                    error=""
                    value={dueFinalDate}
                    type="text"
                    onChange={(data) => setDueFinalDate(maskDate(data))}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="activate">
                <input type="checkbox" onChange={() => setProjectionDateActive()} checked={projectionDateActive} />
                <p>Data de projeção</p>
              </div>
              <div className="filter-date-input-wrapper">
                <div className="" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data Incial"
                    readOnly={!projectionDateActive}
                    helper=""
                    error=""
                    value={projectionInitialDate}
                    type="text"
                    onChange={(data) => setProjectionInitialDate(maskDate(data))}
                  />
                </div>
                <div className="" style={{ paddingLeft: 4 }}>
                  <BasicInput
                    id=""
                    label="Data Final"
                    readOnly={!projectionDateActive}
                    helper=""
                    error=""
                    value={projectionFinalDate}
                    type="text"
                    onChange={(data) => setProjectionFinalDate(maskDate(data))}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => removeAllFilters()}>Remover Filtros</button>
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => filter()}>Filtrar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
