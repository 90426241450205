import { Dispatch } from 'redux';
import { loginActionType } from '../reducers/login';

export default function logIn(dispatch: Dispatch) {
  dispatch({ type: loginActionType.LOGIN });
}

export function logOut(dispatch: Dispatch) {
  dispatch({ type: loginActionType.LOGOFF });
}
