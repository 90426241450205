import { PaymentGateway } from '../pages/PaymentTax/types';

export default function showNonCardPaymentWays(gateway: PaymentGateway) {
  const tax: string[] = [];
  if (gateway.taxes.length > 0) {
    if (gateway.taxes[0].boleto !== null) {
      tax.push('BOLETO');
    }
    if (gateway.taxes[0].dinheiro !== null) {
      tax.push('DINHEIRO');
    }
    if (gateway.taxes[0].doc !== null) {
      tax.push('DOC');
    }
    if (gateway.taxes[0].ted !== null) {
      tax.push('TED');
    }
    if (gateway.taxes[0].pix !== null) {
      tax.push('PIX');
    }
  }
  return tax;
}
