/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import DashboardCardOverlay from '../../../components/DashboardCardOverlay';
import api from '../../../config/axios';

type ContractStatsProps = {
  total: number,
  active: number,
  blocked: number,
  canceled: number
}

export default function ContractActivites() {
  const [contractStats, setContractStats] = useState<ContractStatsProps>({
    total: 0,
    active: 0,
    blocked: 0,
    canceled: 0,
  });
  const [loading, setLoading] = useState(false);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function fetchContractStats() {
    try {
      setLoading(true);
      const response = await api.get('/admin/reports/contractactivities', token);
      setContractStats(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log('error at fetch contract activities', err.response.data);
    }
  }

  function calculatePercentageOfActives() {
    const result = (contractStats.active * 100) / contractStats.total;
    return result === Infinity ? 0 : result;
  }

  function calculatePercentageOfBlocked() {
    const result = (contractStats.blocked * 100) / contractStats.total;
    return result === Infinity ? 0 : result;
  }

  function calculatePercentageOfCanceled() {
    const result = (contractStats.canceled * 100) / contractStats.total;
    return result === Infinity ? 0 : result;
  }

  useEffect(() => {
    fetchContractStats();
  }, []);
  return (
    <DashboardCardOverlay loading={loading}>
      <div className="card-contract-activities h-100" id="contract-activities" style={{ minHeight: 400 }}>
        <div className="title-wrapper">
          <div className="">
            <h5 className="title">Atividade de contratos</h5>
          </div>
        </div>
        <div className="content-wrapper">
          <button type="button">
            <p>
              Ativos -
              {' '}
              {contractStats.active}
              {' '}
              -
              {' '}
              {calculatePercentageOfActives().toFixed(2)}
              %
            </p>
            <div className="track-wrapper">
              <div className="track-active" style={{ width: `${calculatePercentageOfActives()}%` }} />
            </div>
          </button>
          <button type="button">
            <p>
              Bloqueados Temporariamente -
              {' '}
              {contractStats.blocked}
              {' '}
              {!Number.isNaN(calculatePercentageOfBlocked()) && `- ${calculatePercentageOfBlocked().toFixed(2)}%`}
            </p>
            <div className="track-wrapper">
              <div className="track-inactive" style={{ width: `${calculatePercentageOfBlocked()}%` }} />
            </div>
          </button>
          <button type="button">
            <p>
              Cancelados -
              {' '}
              {contractStats.canceled}
              {' '}
              {!Number.isNaN(calculatePercentageOfCanceled()) && `- ${calculatePercentageOfCanceled().toFixed(2)}%`}
            </p>
            <div className="track-wrapper">
              <div className="track-inactive" style={{ width: `${calculatePercentageOfCanceled()}%` }} />
            </div>
          </button>
        </div>
      </div>
    </DashboardCardOverlay>
  );
}
