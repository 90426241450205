import React from 'react';

interface BreadCrumbProps {
    title: string;
}

export default function BreadCrumb({ title }: BreadCrumbProps) {
  return (
    <div className="container-fluid breadcrumb">
      <div className="row">
        <div className="col-12 d-flex align-items-center">
          <div className="home">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home">
              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
              <polyline points="9 22 9 12 15 12 15 22" />
            </svg>
            <p>Home</p>
          </div>
          <p>{` | ${title}`}</p>
        </div>
      </div>
    </div>
  );
}
