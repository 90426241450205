/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import BasicInput from './BasicInput';
import BasicOption from './BasicOption';
import ButtonTextIcon, { BtnType } from './BtnIcon';
import PriceInput from './PriceInput';
import api from '../config/axios';
import { showFutureDateBasedOnMonths, showFutureDateBasedOnMonthsAndApprovalDate, showTodayDate } from '../helpers/dates';
import { maskDate } from '../helpers/inputMasks.js';
import showCardPaymentWays, { normalizeToApiCardPaymentWays } from '../helpers/showCardMethods';
import showNonCardPaymentWays from '../helpers/showNonCardMethods';
import { PaymentGateway } from '../pages/PaymentTax/types';

type PaymentProps = {
    installmentsValue: number;
    discount: number;
    aditional: number;
    installments: number;
    totalValue: number;
    approvalDate: string;
}

export type InstallmentDTO = {
  contractPfPaymentId?: number
  contractPjPaymentId?: number
  dueDate: string
  status: string
  value: number
  paidBy: string
  paymentGatewayId: number
  nonCardTaxes: string | null
  card: boolean
  flag: string | null
  cardLastDigits: string | null
  cardPaymentOption: string | null
}

// eslint-disable-next-line no-shadow
enum PageStatus {
    LOADING,
    FORM,
    SUCCESS,
    ERROR
}

interface OwnProps {
    id: string
    paymentId: number
    isPf: boolean,
    value: number,
    signatureDate: string;
}

export default function AddGroupOfInstallmentsModal(props: OwnProps) {
  const [pageStatus, setPageStatus] = useState(PageStatus.FORM);
  const [error, setError] = useState<string[]>([]);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [forceRefresh, setForceRefresh] = useState(false);
  const [payment, setPayment] = useState<PaymentProps>({
    installmentsValue: props.value,
    discount: 0,
    aditional: 0,
    installments: 1,
    totalValue: 0,
    approvalDate: showTodayDate(),
  });
  const [installments, setInstallments] = useState<InstallmentDTO[]>([]);
  const [paymentGateways, setPaymentGateways] = useState<PaymentGateway[]>([]);

  useEffect(() => {
    setPayment({ ...payment, installmentsValue: props.value });
  }, [props]);

  async function fetchAllTax() {
    try {
      setPageStatus(PageStatus.LOADING);
      const response = await api.get('/admin/configuration/payment-gateway', token);
      setPaymentGateways(response.data);
      setPageStatus(PageStatus.FORM);
    } catch (err) {
      setPageStatus(PageStatus.ERROR);
      setError(['Não foi possivel carregar taxas.']);
    }
  }

  function showFlag(flag: string) {
    if (flag === 'OTHER') {
      return 'OUTRA';
    }
    return flag;
  }

  function calculateInstallmentsAndSetState(e: string) {
    if (e === 'Único') {
      setPayment({ ...payment, installments: 1 });
    } else {
      setPayment({ ...payment, installments: Number(e) });
    }
  }

  function calculateDiscount() {
    const calc = (payment.installmentsValue * payment.discount) / 100;
    return payment.installmentsValue - calc;
  }

  function calculateAditional() {
    const calc = (payment.installmentsValue * payment.aditional) / 100;
    return calc;
  }

  function calculateFinalValue() {
    const discountResult = calculateDiscount();
    const aditionalResult = calculateAditional();
    return discountResult + aditionalResult;
  }

  function showFirstNonCardMethod() {
    if (paymentGateways[0].taxes[0].boleto !== null) {
      return 'BOLETO';
    }
    if (paymentGateways[0].taxes[0].dinheiro !== null) {
      return 'DINHEIRO';
    }
    if (paymentGateways[0].taxes[0].doc !== null) {
      return 'DOC';
    }
    if (paymentGateways[0].taxes[0].pix !== null) {
      return 'PIX';
    }
    if (paymentGateways[0].taxes[0].ted !== null) {
      return 'TED';
    }
    return 'OUTRO';
  }

  function generateStallments() {
    const installmentList: InstallmentDTO[] = [];
    if (payment.installments === 1) {
      installmentList.push({
        contractPfPaymentId: 0,
        dueDate: showFutureDateBasedOnMonthsAndApprovalDate(props.signatureDate, 0),
        status: 'UNPAID',
        paidBy: '',
        value: Number((payment.installmentsValue / payment.installments).toFixed(2)),
        paymentGatewayId: paymentGateways[0].id,
        nonCardTaxes: showFirstNonCardMethod(),
        cardLastDigits: '',
        cardPaymentOption: '',
        card: false,
        flag: '',
      });
    } else {
      for (let i = 0; i < payment.installments; i += 1) {
        installmentList.push({
          contractPfPaymentId: 0,
          dueDate: showFutureDateBasedOnMonthsAndApprovalDate(props.signatureDate, i),
          status: 'UNPAID',
          paidBy: '',
          value: Number((payment.installmentsValue / payment.installments).toFixed(2)),
          paymentGatewayId: paymentGateways[0].id,
          nonCardTaxes: showFirstNonCardMethod().toString(),
          cardLastDigits: '',
          cardPaymentOption: '',
          card: false,
          flag: '',
        });
      }
    }

    setInstallments(installmentList);
  }

  function changeDueDate(idx: number, date: string) {
    installments[idx].dueDate = maskDate(date);
    setForceRefresh((prev) => !prev);
  }

  function changePaymentValue(idx: number, installmentValue: number) {
    installments[idx].value = installmentValue;
    const previousFullfiledValueAsArray = installments.filter((item, i) => i <= idx).map((item) => item.value);
    let previousFullFiledValue = 0;
    if (previousFullfiledValueAsArray.length > 0) {
      previousFullFiledValue = previousFullfiledValueAsArray.reduce((previous, acc) => previous + acc);
    }
    const remainingValue = (calculateFinalValue() - previousFullFiledValue);
    for (let i = idx + 1; i < installments.length; i += 1) {
      installments[i].value = Number((remainingValue / (installments.length - (idx + 1))).toFixed(2));
    }
    setForceRefresh((prev) => !prev);
  }

  function showGatewayName(id: number) {
    const filteredGateway = paymentGateways.filter((item) => item.id === id);
    return filteredGateway[0].name;
  }

  function changePaymentGateway(idx: number, gateway: string) {
    const filteredGateway = paymentGateways.filter((item) => item.name === gateway);

    const cardPaymentOptions = showCardPaymentWays(filteredGateway[0].card);
    const nonCardPaymentOptions = showNonCardPaymentWays(filteredGateway[0]);

    if (nonCardPaymentOptions.length > 0) {
      for (let i = idx; i < installments.length; i += 1) {
        installments[i].paymentGatewayId = filteredGateway[0].id;
        installments[i].card = false;
        installments[i].cardLastDigits = '';
        installments[i].flag = '';
        installments[i].cardPaymentOption = '';
        installments[i].nonCardTaxes = nonCardPaymentOptions[0];
      }
      setForceRefresh((prev) => !prev);
    } else if (cardPaymentOptions.length > 0) {
      for (let i = idx; i < installments.length; i += 1) {
        installments[i].paymentGatewayId = filteredGateway[0].id;
        installments[i].card = true;
        installments[i].cardLastDigits = '';
        installments[i].flag = filteredGateway[0].card[0].flag;
        installments[i].cardPaymentOption = cardPaymentOptions[0];
        installments[i].nonCardTaxes = '';
      }
      setForceRefresh((prev) => !prev);
    } else {
      setError(['O método selecionado não possui formas de pagamento disponíveis']);
      setPageStatus(PageStatus.ERROR);
    }
  }

  function showAvailablePaymentOption(idCtx: number) {
    const result: string[] = [];
    const filteredMethod = paymentGateways.filter((item) => item.id === idCtx);
    if (filteredMethod.length > 0) {
      if (filteredMethod[0].card.length > 0) {
        result.push('CARTÃO');
      }
      if (filteredMethod[0].taxes.length > 0) {
        result.push('OUTRO');
      }
    }
    return result;
  }

  function showCardFlag(installment: InstallmentDTO) {
    const filteredGateway = paymentGateways.filter((item) => item.id === installment.paymentGatewayId);

    return filteredGateway[0].card.map((item) => {
      if (item.flag === 'OTHER') {
        return 'OUTRA';
      }
      return item.flag;
    });
  }

  function showCardPaymentMethods(installment: InstallmentDTO) {
    const filteredGateway = paymentGateways.filter((item) => item.id === installment.paymentGatewayId);
    const filteredCardsByFlag = filteredGateway[0].card.filter((card) => {
      if (installment.flag === 'OUTRA') {
        return card.flag === 'OTHER';
      }
      return card.flag === installment.flag;
    });
    return showCardPaymentWays(filteredCardsByFlag);
  }

  function setCardFlag(idx: number, flag: string) {
    for (let i = idx; i < installments.length; i += 1) {
      installments[i].flag = flag;
      installments[i].cardPaymentOption = showCardPaymentMethods(installments[i])[0];
    }
    setForceRefresh((prev) => !prev);
  }

  function changePaymentOption(idx: number, method: string) {
    if (method === 'CARTÃO') {
      for (let i = idx; i < installments.length; i += 1) {
        installments[i].card = true;
        installments[i].nonCardTaxes = '';
        installments[i].flag = showCardFlag(installments[i])[0];
        installments[i].cardPaymentOption = showCardPaymentMethods(installments[i])[0];
      }
      setForceRefresh((prev) => !prev);
    } else {
      for (let i = idx; i < installments.length; i += 1) {
        const filteredGateway = paymentGateways.filter((item) => item.name === item.gateway);
        const nonCardPaymentOptions = showNonCardPaymentWays(filteredGateway[0]);
        installments[i].card = false;
        installments[i].flag = null;
        installments[i].cardPaymentOption = '';
        installments[i].cardLastDigits = '';
        installments[i].nonCardTaxes = nonCardPaymentOptions[0];
      }
      setForceRefresh((prev) => !prev);
    }
  }

  function showNonCardPaymentMethods(installment: InstallmentDTO) {
    const result: string[] = [];
    paymentGateways.filter((item) => item.id === installment.paymentGatewayId).forEach((item) => {
      item.taxes.forEach((taxes) => {
        if (taxes.boleto !== null) {
          result.push('BOLETO');
        }
        if (taxes.dinheiro !== null) {
          result.push('DINHEIRO');
        }
        if (taxes.doc !== null) {
          result.push('DOC');
        }
        if (taxes.pix !== null) {
          result.push('PIX');
        }
        if (taxes.ted !== null) {
          result.push('TED');
        }
      });
    });
    return result;
  }

  function setPaymentWay(idx: number, way: string) {
    for (let i = idx; i < installments.length; i += 1) {
      installments[i].nonCardTaxes = way;
    }
    setForceRefresh((prev) => !prev);
  }

  function changeCardPaymentOption(idx: number, option: string) {
    for (let i = idx; i < installments.length; i += 1) {
      installments[i].cardPaymentOption = option;
    }
    setForceRefresh((prev) => !prev);
  }

  function changeCardLastDigits(idx: number, digits: string) {
    for (let i = idx; i < installments.length; i += 1) {
      installments[i].cardLastDigits = digits;
    }
    setForceRefresh((prev) => !prev);
  }

  async function saveInstallments(contractPaymentId: number) {
    try {
      setPageStatus(PageStatus.LOADING);
      const payload = {
        installments: installments.map((ctxInstallment) => {
          const cardPaymentOption = ctxInstallment.cardPaymentOption !== null ? ctxInstallment.cardPaymentOption : '';
          const flag = ctxInstallment.flag === 'OUTRA' ? 'OTHER' : ctxInstallment.flag;
          const normalizedCardPaymentOption = normalizeToApiCardPaymentWays(cardPaymentOption);
          if (props.isPf) {
            return {
              ...ctxInstallment, contractPfPaymentId: contractPaymentId, cardPaymentOption: normalizedCardPaymentOption, flag,
            };
          }
          return {
            ...ctxInstallment, contractPjPaymentId: contractPaymentId, cardPaymentOption: normalizedCardPaymentOption, flag,
          };
        }),
      };
      const url = props.isPf ? '/admin/contractpf/installment/' : '/admin/contractpj/installment/';
      await api.post(url, payload, token);
      window.location.reload();
    } catch (err: any) {
      console.log(err.response.data);
      setPageStatus(PageStatus.ERROR);
      setError(['Não foi possível cadastrar parcelas']);
      setPageStatus(PageStatus.ERROR);
    }
  }

  useEffect(() => {
    fetchAllTax();
  }, []);
  return (
    <div className="modal fade" id={props.id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Adicionar grupo de parcelas</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body" style={{ minHeight: 200, minWidth: 200 }}>
            {pageStatus === PageStatus.ERROR && (
            <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
              {error.map((item) => (
                <p className="fw-bold">{item}</p>
              ))}
            </div>
            )}
            {pageStatus === PageStatus.LOADING && (
            <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
              <p className="fw-bold">Carregando, aguarde</p>
            </div>
            )}
            {pageStatus === PageStatus.FORM && (
            <div className="row products mt-4">
              <div className="col-12 card-bg p-4">
                <div className="row">
                  <h1>Forma de pagamento</h1>
                  <div className="col-4">
                    <PriceInput
                      id="valor-contrato"
                      label="Valor total das parcelas"
                      readOnly={false}
                      helper="Ex: 500"
                      error={payment.installmentsValue === 0 ? 'Digite um valor' : ''}
                      value={payment.installmentsValue.toString()}
                      type="number"
                      onChange={(data) => setPayment({ ...payment, installmentsValue: Number(data) })}
                    />
                  </div>
                  <div className="col-4">
                    <BasicOption
                      id="número-de-parcelas"
                      label="Parcelas"
                      readOnly={false}
                      helper="Ex: 12"
                      error=""
                      value={payment.installments.toString() === '1' ? 'Único' : payment.installments.toString()}
                      onChange={(data) => calculateInstallmentsAndSetState(data)}
                    >
                      {['Único', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36']}
                    </BasicOption>
                  </div>
                  <div className="col-12 footer">
                    <ButtonTextIcon
                      label="Calcular Faturas"
                      type={BtnType.PRIMARY}
                      icon="calculate"
                      active
                      onClick={() => generateStallments()}
                      ref={null}
                    />
                  </div>
                </div>
              </div>
            </div>
            )}

            {pageStatus === PageStatus.FORM && (
            <>
              {installments.map((item, i) => (
                <div className="row products">
                  <div className="col-12 card-bg p-4">
                    <div className="row">
                      <h1>{`Parcela - ${i + 1}`}</h1>
                      <div className="col-4">
                        <BasicInput
                          id="data-vencimento"
                          label="Data de vencimento"
                          readOnly={false}
                          helper="Ex: 30-04-2022"
                          error={item.dueDate.length < '00-00-0000'.length ? 'Digite uma data válida' : ''}
                          value={item.dueDate}
                          type="text"
                          onChange={(data) => changeDueDate(i, data)}
                        />
                      </div>
                      <div className="col-4">
                        <PriceInput
                          id={`valor-parcela-${i + 1}`}
                          label="Valor Bruto"
                          readOnly={false}
                          helper="Ex: 600"
                          error={item.value === 0 ? 'Digite um valor' : ''}
                          value={item.value.toString()}
                          type="number"
                          onChange={(data) => changePaymentValue(i, Number(data))}
                        />
                      </div>
                      <div className="col-4">
                        <BasicOption
                          id={`gateway-de-pagamento-${i + 1}`}
                          label="Gateway de pagamento"
                          readOnly={false}
                          helper="Ex: PagAzul Web"
                          error=""
                          value={showGatewayName(item.paymentGatewayId)}
                          onChange={(data) => changePaymentGateway(i, data)}
                        >
                          {paymentGateways.map((gateway) => gateway.name)}
                        </BasicOption>
                      </div>
                      <div className="col-4">
                        <BasicOption
                          id={`opcoes-de-pagamento${i + 1}`}
                          label="Opções de pagamento"
                          readOnly={false}
                          helper="Ex: Cartão"
                          error=""
                          value={item.card ? 'CARTÃO' : 'OUTRO'}
                          onChange={(data) => changePaymentOption(i, data)}
                        >
                          {showAvailablePaymentOption(item.paymentGatewayId).map((method) => method)}
                        </BasicOption>
                      </div>

                      {item.card && (
                      <div className="col-4">
                        <BasicOption
                          id={`flag-${i + 1}`}
                          label="Bandeira"
                          readOnly={false}
                          helper="Ex: VISA"
                          error=""
                          value={item.flag !== null ? showFlag(item.flag) : ''}
                          onChange={(data) => setCardFlag(i, data)}
                        >
                          {showCardFlag(item)}
                        </BasicOption>
                      </div>
                      )}

                      {item.card && (
                      <div className="col-4">
                        <BasicOption
                          id={`card-way-${i + 1}`}
                          label="Forma de pagamento"
                          readOnly={false}
                          helper="Ex: Crédito 12x"
                          error=""
                          value={item.cardPaymentOption !== null ? item.cardPaymentOption : ''}
                          onChange={(data) => changeCardPaymentOption(i, data)}
                        >
                          {showCardPaymentMethods(item)}
                        </BasicOption>
                      </div>
                      )}

                      {item.card && (
                      <div className="col-4">
                        <BasicInput
                          id={`card-last-digits-${i + 1}`}
                          label="Ultimos 04 dígitos"
                          readOnly={false}
                          helper="Ex: 2144"
                          value={item.cardLastDigits ? item.cardLastDigits.toString() : ''}
                          onChange={(data) => changeCardLastDigits(i, data)}
                          type="number"
                          error=""
                        />
                      </div>
                      )}

                      {!item.card && (
                      <div className="col-4">
                        <BasicOption
                          id={`way-${i + 1}`}
                          label="Forma de pagamento"
                          readOnly={false}
                          helper="Ex: PIX"
                          error=""
                          value={item.nonCardTaxes !== null ? item.nonCardTaxes : ''}
                          onChange={(data) => setPaymentWay(i, data)}
                        >
                          {showNonCardPaymentMethods(item)}
                        </BasicOption>
                      </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            <button type="button" disabled={installments.length === 0} className="btn btn-primary" onClick={() => saveInstallments(props.paymentId)}>Gerar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
