/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import BasicInput from '../../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import api from '../../../config/axios';
import { maskDate } from '../../../helpers/inputMasks.js';
import PrimaryModalContent, { IPrimaryModalContent } from '../PrimaryModalContent';

interface PageProps {
    setModal: (arg0: React.ReactNode) => void;
    setLoading: (arg0: boolean) => void;
}

export default function SellersByDate({ setModal, setLoading }: PageProps) {
  const [initialDateInput, setInitialDateInput] = useState('01-01-2022');
  const [finalDateInput, setFinalDateInput] = useState(DateTime.now().toFormat('dd-MM-yyyy'));
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [modalProps, setModalProps] = useState<IPrimaryModalContent | null>(null);
  const [modalBtnRef] = useState(React.createRef<any>());
  const [showBlocked, setShowBlocked] = useState(false);

  async function fetchData(initialDate: string, finalDate: string) {
    try {
      const response = await api.post('admin/reports/byperiod/sellers', {
        initialDate: `${initialDate} 00:00:00`,
        finalDate: `${finalDate} 23:59:59`,
        showBlocked,
      }, token);
      return response;
    } catch (err: any) {
      throw new Error('Erro ao buscar relatórios.');
    }
  }

  async function validateDate() {
    try {
      const initialDateAsDateTime = DateTime.fromFormat(initialDateInput, 'dd-MM-yyyy');
      const finalDateAsDateTime = DateTime.fromFormat(finalDateInput, 'dd-MM-yyyy');
      if (!initialDateAsDateTime.isValid) {
        throw new Error('Data inicial inválida.');
      }
      if (!finalDateAsDateTime.isValid) {
        throw new Error('Data final inválida.');
      }

      if (initialDateAsDateTime > finalDateAsDateTime) {
        throw new Error('Data inicial maior que a data final.');
      }
      return {
        initialDateAsDateTime,
        finalDateAsDateTime,
      };
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  async function callApi() {
    try {
      setLoading(true);
      const date = await validateDate();
      const response = await fetchData(date.initialDateAsDateTime.toFormat('dd-MM-yyyy'), date.finalDateAsDateTime.toFormat('dd-MM-yyyy'));
      setModalProps(response.data);
      modalBtnRef.current.click();
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setModal(<p>{err.message}</p>);
    }
  }

  return (
    <>
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>
            Relatórios de
            {' '}
            <strong className="fw-bold text-primary">contratos</strong>
            {' '}
            por vendedores REDESANTA
          </h1>
          <div className="row">
            <div className="col-12 allow-modifications mb-4">
              <input type="checkbox" onChange={() => setShowBlocked((prev) => !prev)} checked={showBlocked} />
              <p>Incluir nos relatórios, contratos de usuários permanentemente bloqueados.</p>
            </div>
            <div className="col-4">
              <BasicInput
                id="indication-initial-date"
                label="Data Inicial"
                readOnly={false}
                helper="Ex: 13-06-2022"
                error=""
                value={initialDateInput}
                onChange={(data) => setInitialDateInput(maskDate(data))}
                type=""
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="indication-initial-date"
                label="Data final"
                readOnly={false}
                helper="Ex: 22-06-2022"
                error=""
                value={finalDateInput}
                onChange={(data) => setFinalDateInput(maskDate(data))}
                type=""
              />
            </div>
            <div className="col-4 d-flex align-items-center mb-3">
              <ButtonTextIcon
                label="Gerar relatório"
                type={BtnType.PRIMARY}
                icon="search"
                active
                onClick={() => callApi()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>
      <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#SellersByPeriod" ref={modalBtnRef}>
        Launch static backdrop modal
      </button>
      <PrimaryModalContent
        series={modalProps ? modalProps.series : []}
        data={modalProps ? modalProps.data : []}
        id="SellersByPeriod"
        labelText="CONTRATOS"
        title="Contratos por vendedores REDESANTA definidos por período."
        initialDate={initialDateInput}
        finalDate={finalDateInput}
      />
    </>
  );
}
