/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import api from '../../../config/axios';
import DefaultLayout from '../../../Layout';

type MetaProps = {
    total: number,
    per_page: number,
    current_page: number,
    last_page: number,
    first_page: number,
}

type ProfessionalProps = {
    id: number,
    name: string,
    sex: string,
    councilRegister: string,
    phone: string,
    whatsapp: null | string,
}

type ProfessionalsCategory = {
    id: number,
    title: string,
    type: 'medic' | 'health' | 'dentist'
}

type ConsultationsProps = {
    id: number,
    professionalId: number,
    professionalCategoryId: number,
    accreditedId: number,
    description: string,
    value: number,
    observation: string,
    createdAt: string,
    updatedAt: string,
   professional: ProfessionalProps,
   accredited: AccreditedProps
   category: ProfessionalsCategory
}

type AccreditedProps = {
    id: number,
    name: string,
}

type ResponseProps = {
    meta: MetaProps,
    data: ConsultationsProps[]
}

type AccrecreditedProps = {
  id: number;
  name: string
}

type CategoryPreoloadProps = {
  id: number;
  title: string;
  professionals: ProfessionalProps[]
}

export default function ShowConsultation() {
  const navigate = useNavigate();
  const [consultation, setConsultation] = useState<ConsultationsProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [accrediteds, setAccrediteds] = useState<AccrecreditedProps[]>([]);
  const [selectedAccredited, setSelectedAccredited] = useState<null | AccreditedProps>(null);
  const [categories, setCategories] = useState<CategoryPreoloadProps[]>([]);
  const [observation, setObservation] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<null | CategoryPreoloadProps>(null);
  const [professionals, setProfessionals] = useState<ProfessionalProps[]>([]);
  const [selectedProfessional, setSelectedProfessional] = useState<ProfessionalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` } });
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [actualPage, setActualPage] = useState(1);
  const [meta, setMeta] = useState<MetaProps>({
    total: 0,
    per_page: 0,
    current_page: 1,
    last_page: 1,
    first_page: 1,
  });

  async function fetchExams() {
    try {
      setLoading(true);
      const response = await api.post<ResponseProps>('/admin/consultation-search', {
        page: actualPage,
        professionalId: selectedProfessional ? selectedProfessional.id : null,
        description: null,
        professionalCategoryId: selectedCategory ? selectedCategory.id : null,
        accreditedId: selectedAccredited ? selectedAccredited.id : null,
      }, token);
      setConsultation(response.data.data);
      setMeta(response.data.meta);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível carregar consultas', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
      setLoading(false);
    }
  }

  async function fetchPreload() {
    try {
      setLoading(true);
      const response = await api.get('/admin/consultation-preload', token);
      setAccrediteds(response.data.accrediteds);
      setCategories(response.data.categories);
      setProfessionals(response.data.allProfessionals);
      const allProfessionals: ProfessionalProps[] = [];
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível carregar os credenciados e categorias.', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
    }
  }

  function generatePagination(totalOfPages: number) {
    const pagination: number[] = [];
    for (let i = 0; i < totalOfPages; i += 1) {
      pagination.push(i + 1);
    }
    return pagination;
  }

  function goToNextPage() {
    if (actualPage < meta.last_page) {
      setActualPage((prev) => prev + 1);
    }
  }

  function changeAccredited(data: string) {
    if (data === 'SELECIONE') {
      setSelectedAccredited(null);
    } else {
      const filtered = accrediteds.filter((item) => item.name === data);

      if (filtered.length > 0) {
        setSelectedAccredited(filtered[0]);
      }
    }
  }

  function changeCategory(el: string) {
    if (el === 'TODOS') {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(categories.filter((item) => item.title === el)[0]);
    }
  }

  function openInNewTab(dataId: number, evt: any) {
    evt.stopPropagation();
    if (evt.ctrlKey || evt.metaKey) {
      window.open(`https://painel.redesanta.com/consultas/editar/${dataId}`, '_blank');
    } else {
      navigate(`/consultas/editar/${dataId}`);
    }
  }

  useEffect(() => {
    fetchPreload();
    fetchExams();
  }, [actualPage]);

  return (
    <DefaultLayout
      pageTitle="Consultas"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Consultas</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <button className="table-btn-primary" type="button" onClick={() => navigate('/consultas/criar')}>
                <span className="material-icons-round">
                  add
                </span>
                adicionar
              </button>
              <div className="m-2 d-flex align-items-center">
                <div className="mt-1 installment-option extended-input" style={{ }}>
                  <BasicOption
                    id="Credenciado"
                    label="Credenciados"
                    readOnly={false}
                    helper=""
                    error=""
                    value={selectedAccredited ? selectedAccredited.name : 'SELECIONE'}
                    onChange={(el) => changeAccredited(el)}
                  >
                    {['SELECIONE'].concat(...accrediteds.map((item) => item.name))}
                  </BasicOption>

                </div>
                <div className="mt-1 installment-option">
                  <BasicOption
                    id="Categoria"
                    label="Especialidade"
                    readOnly={false}
                    helper=""
                    error=""
                    value={selectedCategory === null ? 'TODOS' : selectedCategory.title}
                    onChange={(el) => changeCategory(el)}
                  >
                    {['TODOS'].concat(categories.map((item) => item.title))}
                  </BasicOption>
                </div>
                <div className="mt-1 installment-option">
                  <BasicOption
                    id="Professional"
                    label="Profissional"
                    readOnly={false}
                    helper=""
                    error=""
                    value={selectedProfessional === null ? 'TODOS' : selectedProfessional.name}
                    onChange={(el) => (el === 'TODOS' ? setSelectedProfessional(null) : setSelectedProfessional(professionals.filter((item) => item.name === el)[0]))}
                  >
                    {['TODOS'].concat(professionals.map((item) => item.name))}
                  </BasicOption>
                </div>
                <button className="table-btn-primary m-2" type="button" onClick={() => fetchExams()}>
                  <span className="material-icons-round">
                    search
                  </span>
                  filtrar
                </button>
              </div>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Profissional</th>
                  <th scope="col">Especialidade</th>
                  <th scope="col">Credenciado</th>
                  <th scope="col">Valor</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {consultation.length > 0 ? consultation.map((item, i) => (
                  <tr>
                    <td><Link to={`/profissionais/editar/${item.professional.id}`}>{item.professional.name}</Link></td>
                    <td><Link to={`/profissionais/especialidade/editar/${item.category.id}`}>{item.category.title}</Link></td>
                    <td><Link to={`/credenciados/editar/${item.accredited.id}`}>{item.accredited.name}</Link></td>
                    <td>{`R$ ${item.value}`}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <button type="button" onClick={(evt) => openInNewTab(item.id, evt)}>
                            <span className="material-icons-round">
                              more
                            </span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : 'Nenhum resultado'}
              </tbody>
            </table>
            <div className="table-footer">
              <button type="button" onClick={() => setActualPage(1)}>Primeira</button>
              <p>Página</p>
              <select style={{ minWidth: 300 }} value={meta.current_page} onChange={(e) => setActualPage(Number(e.target.value))}>
                {generatePagination(meta.last_page).map((item) => <option value={item}>{item}</option>)}
              </select>
              <p>
                de
                {' '}
                <strong>{meta.last_page}</strong>
              </p>
              <button
                type="button"
                onClick={() => goToNextPage()}
              >
                Próxima

              </button>
              <button
                type="button"
                onClick={() => setActualPage(meta.last_page)}
              >
                Última

              </button>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
