import React from 'react';

type DashboardOverlayProps = {
    children: React.ReactNode
    loading: boolean
}

export default function DashboardCardOverlay({ children, loading }: DashboardOverlayProps) {
  return (
    <>
      <div className="card-overlay" style={{ display: loading ? 'flex' : 'none' }}>
        <div className="spinner-grow text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      {children }
    </>
  );
}
