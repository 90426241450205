import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import api from '../../../config/axios';
import DefaultLayout from '../../../Layout';

type NewCategoryProps = {
    title: string;
    isActive: boolean;
}

export default function NewProceduresCategory() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [category, setCategory] = useState<NewCategoryProps>({
    title: '',
    isActive: true,
  });

  function enableButton() {
    return category.title !== '';
  }

  async function saveCategory() {
    try {
      setLoading(true);
      await api.post('/admin/procedures-category/', category, token);
      setLoading(false);
      navigate(-1);
    } catch (err: any) {
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível criar categoria', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  return (
    <DefaultLayout
      pageTitle="Adicionar Categoria de procedimentos"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Categoria de procedimentos</h1>
          <div className="row">
            <div className="col-4">
              <BasicInput
                id="title-input"
                label="Título"
                readOnly={false}
                helper="Biópsia"
                error={category.title.length < 1 ? 'Campo obrigatório' : ''}
                value={category.title}
                type="text"
                onChange={(data) => setCategory({ ...category, title: data })}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                active={enableButton()}
                onClick={() => saveCategory()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>

    </DefaultLayout>
  );
}
