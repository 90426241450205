/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import BasicInput from '../../components/BasicInput';
import DefaultLayout from '../../Layout';

import { statesOfBrazil } from '../../dto/states';
import {
  maskCep, maskCNPJ, maskDate, maskNumber, maskPhone, unmaskData,
} from '../../helpers/inputMasks.js';
import api from '../../config/axios';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import BasicOption from '../../components/BasicOption';
import CEPFinder from '../../helpers/findAddressByCep';
import AddInput from '../../components/AddInput';

export default function Config() {
  const [enterpriseCopy, setEnterpriseCopy] = useState({
    cnpj: '',
    openingDate: '',
    name: '',
    nameFantasy: '',
    size: '',
    addressStreet: '',
    addressNumber: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    addressState: '',
    addressZipcode: '',
    email: '',
    phone: '',
  });
  const [enterprise, setEnterprise] = useState({
    cnpj: '',
    openingDate: '',
    name: '',
    nameFantasy: '',
    size: '',
    addressStreet: '',
    addressNumber: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    addressState: '',
    addressZipcode: '',
    email: '',
    phone: '',
  });
  const [readOnly, setReadOnly] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showToastError, setShowToastError] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [token] = useState({ headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` } });

  async function changeAddressByCep() {
    try {
      setLoading(true);
      const findCep = await new CEPFinder(unmaskData(enterprise.addressZipcode)).findCep();
      if (findCep !== null) {
        setEnterprise({
          ...enterprise,
          addressCity: findCep.city,
          addressState: findCep.state,
          addressNeighborhood: findCep.neighborhood,
          addressStreet: findCep.street,
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }
  function checkFullFilledForm() {
    if (
      enterprise.cnpj.length > 0
          && enterprise.openingDate.length > 0
          && enterprise.name.length > 0
          && enterprise.nameFantasy.length > 0
          && enterprise.size.length > 0
          && enterprise.addressStreet.length > 0
          && enterprise.addressNumber.length > 0
          && enterprise.addressNeighborhood.length > 0
          && enterprise.addressCity.length > 0
          && enterprise.addressState.length > 0
          && enterprise.addressZipcode.length > 0
          && enterprise.email.length > 0
          && enterprise.phone.length > 0
    ) {
      return true;
    }
    return false;
  }

  async function updateConfig() {
    try {
      setLoading(true);
      const payload = {
        cnpj: enterprise.cnpj.trim(),
        openingDate: enterprise.openingDate.trim(),
        name: enterprise.name,
        nameFantasy: enterprise.nameFantasy,
        size: enterprise.size.trim(),
        addressStreet: enterprise.addressStreet.trim(),
        addressNumber: enterprise.addressNumber.trim(),
        addressComplement: enterprise.addressComplement.trim(),
        addressNeighborhood: enterprise.addressNeighborhood.trim(),
        addressCity: enterprise.addressCity.trim(),
        addressState: enterprise.addressState.trim(),
        addressZipcode: enterprise.addressZipcode.trim(),
        logoUrl: 'http:/exemplo.com/image.jpg',
        email: enterprise.email,
        phone: enterprise.phone,
      };
      console.log(payload);
      const response = await api.put('admin/configuration/update', payload, token);
      setEnterprise(response.data);
      setEnterpriseCopy(response.data);
      setLoading(false);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      setLoading(false);
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 3000);
      console.log(err.response);
    }
  }

  useEffect(() => {
    async function fetchConfig() {
      try {
        setLoading(true);
        const response = await api.get('/admin/configuration', token);
        console.log(response.data);
        setEnterpriseCopy({
          ...response.data,
          cnpj: maskCNPJ(response.data.cnpj),
          addressZipcode: maskCep(response.data.addressZipcode),
        });
        setEnterprise({
          ...response.data,
          cnpj: maskCNPJ(response.data.cnpj),
          addressZipcode: maskCep(response.data.addressZipcode),
          phone: maskPhone(response.data.phone),
        });
        setLoading(false);
      } catch (err: any) {
        setShowToastError(true);
        setTimeout(() => {
          setShowToastError(false);
        }, 3000);
        console.log('Não foi possível pegar dados de config da empresa', err);
      }
    }
    fetchConfig();
  }, []);
  return (
    <DefaultLayout
      modal={null}
      rightSliderContent={<div />}
      showRightSlider={false}
      setCloseRightSlider={() => null}
      pageTitle="Configurações"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
    >
      <div className="row config d-flex- align-items-center justify-content-center card-bg p-0">
        <section className="row">
          <h1>Dados da Redesanta</h1>
          <div className="col-12 allow-modifications">
            <input
              type="checkbox"
              id="#checkbox"
              onChange={() => {
                setReadOnly((prev) => !prev);
              }}
            />
            Habilitar modificações
          </div>
          <div className="col-4">
            <BasicInput
              id="cnpj-config"
              label="CNPJ"
              readOnly={readOnly}
              helper={!readOnly ? 'Apenas números' : ''}
              error={enterprise.cnpj.length < 18 ? 'Digite um CNPJ válido' : ''}
              value={enterprise.cnpj}
              onChange={(data) => setEnterprise({ ...enterprise, cnpj: maskCNPJ(data) })}
              type="text"
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="opening-date-config"
              label="DATA DE ABERTURA"
              readOnly={readOnly}
              helper="dia-mês-ano"
              error=""
              value={enterprise.openingDate}
              onChange={(data) => setEnterprise({ ...enterprise, openingDate: maskDate(data) })}
              type="text"
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="name-config"
              label="NOME"
              readOnly={readOnly}
              helper="Ex: Rede Santa Ltda."
              error={enterprise.name.length < 1 ? 'Esse Campo não pode ficar em branco' : ''}
              value={enterprise.name}
              onChange={(data) => setEnterprise({ ...enterprise, name: data })}
              type="text"
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="fantasy-name-config"
              label="NOME FANTASIA"
              readOnly={readOnly}
              helper="Ex: Rede Santa"
              error={enterprise.nameFantasy.length < 1 ? 'Esse Campo não pode ficar em branco' : ''}
              value={enterprise.nameFantasy}
              onChange={(data) => setEnterprise({ ...enterprise, nameFantasy: data })}
              type="text"
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="size-config"
              label="Classificação"
              readOnly={readOnly}
              helper="Selecione uma Opção"
              error=""
              value={enterprise.size}
              onChange={(data) => setEnterprise({ ...enterprise, size: data })}
            >
              {
                    [
                      'ME',
                      'MEI',
                      'EPP',
                      'OUTRO',
                    ]
                }
            </BasicOption>
          </div>
          <div className="col-4">
            <AddInput
              id="address-cep-input"
              label="CEP"
              readOnly={readOnly}
              helper="Ex: 35.171-120"
              error={enterprise.addressZipcode.length < 10 ? 'Campo obrigatório' : ''}
              value={enterprise.addressZipcode}
              type="text"
              onAdd={() => changeAddressByCep()}
              onChange={(data) => setEnterprise({ ...enterprise, addressZipcode: maskCep(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-config"
              label="Logradouro"
              readOnly={readOnly}
              helper="Ex: Rua Três"
              error={enterprise.addressStreet.length < 1 ? 'Digite um endereço válido' : ''}
              value={enterprise.addressStreet}
              onChange={(data) => setEnterprise({ ...enterprise, addressStreet: data })}
              type="text"
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-number-config"
              label="Número"
              readOnly={readOnly}
              helper="Apenas números"
              error={enterprise.addressNumber.length < 0 ? 'Esse campo precisa ser preenchido' : ''}
              value={enterprise.addressNumber}
              onChange={(data) => setEnterprise({ ...enterprise, addressNumber: maskNumber(data) })}
              type="text"
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-complement-config"
              label="Complemento"
              readOnly={readOnly}
              helper="Ex: Bloco A"
              error=""
              value={enterprise.addressComplement === '' ? 'N/A' : enterprise.addressComplement}
              onChange={(data) => setEnterprise({ ...enterprise, addressComplement: data })}
              type="text"
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-neighborhood-config"
              label="Bairro"
              readOnly={readOnly}
              helper="Ex: Centro"
              error={enterprise.addressNeighborhood.length < 1 ? 'Esse campo precisa ser preenchido' : ''}
              value={enterprise.addressNeighborhood}
              onChange={(data) => setEnterprise({ ...enterprise, addressNeighborhood: data })}
              type="text"
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-city-config"
              label="Cidade"
              readOnly={readOnly}
              helper="Ex: Imperatriz"
              error={enterprise.addressCity.length < 1 ? 'Esse campo precisa ser preenchido' : ''}
              value={enterprise.addressCity}
              onChange={(data) => setEnterprise({ ...enterprise, addressCity: data })}
              type="text"
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="address-state-config"
              label="Estado"
              readOnly={readOnly}
              helper="Ex: MA"
              error=""
              value={enterprise.addressState}
              onChange={(data) => setEnterprise({ ...enterprise, addressState: data })}
            >
              {statesOfBrazil}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicInput
              id="address-email-config"
              label="E-mail"
              readOnly={readOnly}
              helper="Ex: redesanta@redesanta.com"
              error=""
              value={enterprise.email}
              onChange={(data) => setEnterprise({ ...enterprise, email: data })}
              type="text"
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-phone-config"
              label="Telefone"
              readOnly={readOnly}
              helper="Ex: (31) 99999-9999"
              error={enterprise.phone.length < 1 ? 'Esse campo precisa ser preenchido' : ''}
              value={enterprise.phone}
              onChange={(data) => setEnterprise({ ...enterprise, phone: maskPhone(data) })}
              type="text"
            />
          </div>
          <div className="col-12 footer mt-4">
            {!readOnly && (
            <>
              <ButtonTextIcon
                active
                label="RESTAURAR"
                type={BtnType.SECONDARY}
                icon="restart_alt"
                ref={null}
                onClick={() => setEnterprise(enterpriseCopy)}
              />
              <ButtonTextIcon
                active={checkFullFilledForm()}
                label="salvar"
                type={BtnType.PRIMARY}
                icon="save"
                onClick={() => updateConfig()}
                ref={null}
              />

            </>
            )}
          </div>
        </section>
      </div>
    </DefaultLayout>
  );
}
