/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import api from '../../../config/axios';
import DefaultLayout from '../../../Layout';

type ConsultationProps = {
    id: number,
    professionalId: number,
    professionalCategoryId: number,
    accreditedId: number,
    description: string,
    value: string,
    observation: string | null,
}

type AccrecreditedProps = {
    id: number;
    name: string
}

type ProfessionalProps = {
  id: number,
  name: string,
  sex: string,
  councilRegister: string,
  phone: string,
  whatsapp: null | string,
}

type CategoryPreoloadProps = {
  id: number;
  title: string;
  professionals: ProfessionalProps[]
}

export default function EditConsultation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [accrediteds, setAccrediteds] = useState<AccrecreditedProps[]>([]);
  const [selectedAccredited, setSelectedAccredited] = useState<AccrecreditedProps | null>(null);
  const [categories, setCategories] = useState<CategoryPreoloadProps[]>([]);
  const [allProfessionals, setAllProfessionals] = useState<ProfessionalProps[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<CategoryPreoloadProps | null>(null);
  const [selectedProfessional, setSelectedProfessional] = useState<ProfessionalProps | null>(null);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [consultation, setConsultation] = useState<ConsultationProps>({
    id: 100000000,
    professionalCategoryId: 100000,
    accreditedId: 1000000000,
    professionalId: 100000000,
    description: '',
    value: '',
    observation: '',
  });

  async function fetchPreload(): Promise<{
    accrediteds: AccrecreditedProps[],
    categories: CategoryPreoloadProps[],
    allProfessionals: ProfessionalProps[]
  }> {
    try {
      setLoading(true);
      const response = await api.get('/admin/consultation-preload', token);
      setAllProfessionals(response.data.allProfessionals);
      setAccrediteds(response.data.accrediteds);
      setCategories(response.data.categories.filter((item: any) => item.professionals.length > 0));
      return {
        accrediteds: response.data.accrediteds,
        categories: response.data.categories,
        allProfessionals: response.data.allProfessionals,
      };
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      return {
        accrediteds: [],
        categories: [],
        allProfessionals: [],
      };
    }
  }

  async function fetchExams() {
    try {
      setLoading(true);
      const preload = await fetchPreload();
      if (preload.accrediteds.length === 0) {
        setModal({ title: 'Ocorreu um erro', children: 'Não foi possível carregar lista de credenciados.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => navigate(-1) }] });
        return;
      }
      if (preload.categories.length === 0) {
        setModal({ title: 'Ocorreu um erro', children: 'Não foi possível carregar lista de categorias.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => navigate(-1) }] });
        return;
      }
      if (preload.categories.length === 0) {
        setModal({ title: 'Ocorreu um erro', children: 'Não foi possível carregar lista de categorias.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => navigate(-1) }] });
        return;
      }
      const response = await api.get<ConsultationProps>(`admin/consultation/${id}`, token);
      setConsultation(response.data);
      setSelectedCategory(preload.categories.filter((item) => item.id === response.data.professionalCategoryId)[0]);
      setSelectedAccredited(preload.accrediteds.filter((item) => item.id === response.data.accreditedId)[0]);
      setSelectedProfessional(preload.allProfessionals.filter((item) => item.id === response.data.professionalId)[0]);
      console.log(preload.allProfessionals.filter((item) => item.id === response.data.professionalId)[0]);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível buscar exame.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => navigate(-1) }] });
    }
  }

  async function saveExam() {
    try {
      setLoading(true);
      await api.put(`admin/consultation/${id}`, {
        ...consultation,
        professionalId: selectedProfessional!.id.toString(),
        professionalCategoryId: selectedCategory!.id.toString(),
        accreditedId: selectedAccredited!.id.toString(),
        description: consultation.description,
        value: consultation.value,
        observation: consultation.observation && consultation.observation.length > 0 ? consultation.observation : null,
      }, token);
      setLoading(false);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível editar consulta.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  async function deleteExam() {
    try {
      setLoading(true);
      await api.delete(`admin/consultation/${id}`, token);
      setLoading(false);
      navigate(-1);
    } catch (err: any) {
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível editar consulta.', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  function changeProfessional(data: string) {
    // setSelectedProfessional(selectedCategory!.professionals.filter((item) => item.name === data)[0]);
  }

  function changeValue(data: string) {
    setConsultation({ ...consultation, value: data });
  }

  function changeObservation(data: string) {
    setConsultation({ ...consultation, observation: data });
  }

  function changeCategory(data: string) {
    // const selected = categories.filter((item) => item.title === data)[0];
    // setSelectedCategory(selected);
    // setSelectedProfessional(selected.professionals[0]);
  }

  function validateFields() {
    if (consultation.value === '') {
      return false;
    }
    return true;
  }

  useEffect(() => {
    fetchExams();
  }, []);

  return (
    <DefaultLayout
      pageTitle="Visualizar Consulta"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Visualizar Consulta</h1>
          <div className="row">
            <div className="col-4">
              <BasicOption
                id="accredited"
                label="Credenciados"
                readOnly={false}
                helper="Ex: HSM"
                error=""
                value={selectedAccredited ? selectedAccredited.name : 'SELECIONE'}
                onChange={(data) => setSelectedAccredited(accrediteds.filter((item) => item.name === data)[0])}
              >
                {[...accrediteds.map((item) => item.name)]}
              </BasicOption>
            </div>
            <div className="col-4">
              <BasicOption
                id="accredited"
                label="Especialidades"
                readOnly={false}
                helper="Ex: Otorrinolaringologia"
                error=""
                value={selectedCategory ? selectedCategory.title : 'SELECIONE'}
                onChange={(data) => changeCategory(data)}
              >
                {[...categories.map((item) => item.title)]}
              </BasicOption>
            </div>
            <div className="col-4">
              <BasicOption
                id="description"
                label="Profissional"
                readOnly={false}
                helper="Ex: Áureo"
                error=""
                value={selectedProfessional ? selectedProfessional.name : 'SELECIONE'}
                onChange={(data) => changeProfessional(data)}
              >
                {selectedCategory ? [...selectedCategory.professionals.map((professional) => professional.name)] : ['SELECIONE']}
              </BasicOption>
            </div>
            <div className="col-4">
              <BasicInput
                id="valor-"
                label="valor"
                readOnly={false}
                helper="Ex: 100,00"
                error=""
                value={consultation.value}
                type="number"
                onChange={(data) => changeValue(data)}
              />
            </div>
            <div className="col-6">
              <BasicInput
                id="description-"
                label="Observação"
                readOnly={false}
                helper="Ex: Corte Axial"
                error=""
                value={consultation.observation ? consultation.observation : ''}
                type="text"
                onChange={(data) => changeObservation(data)}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="EXCLUIR"
                type={BtnType.DANGER}
                icon="delete"
                active
                onClick={() => deleteExam()}
                ref={null}
              />
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                active={validateFields()}
                onClick={() => saveExam()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>

    </DefaultLayout>
  );
}
