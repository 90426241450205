import React from 'react';

export default function ToastError() {
  return (
    <div className="toast-error-container">
      <p className="toast-error">Ocorreu um erro ao exectuar a ação!</p>
      <span className="material-icons-round">
        error
      </span>
    </div>
  );
}
