/* eslint-disable max-len */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { ModalActionType, ModalProps } from '../../../../components/Modal';
import api from '../../../../config/axios';
import { ProductsProps } from '../../../../dto/product';
import { maskDate, unmaskData } from '../../../../helpers/inputMasks.js';
import { normalizeToApiCardPaymentWays } from '../../../../helpers/showCardMethods';
import DefaultLayout from '../../../../Layout';
import { PagVipRefProps } from '../../../Contracts/PF/EditPaymentPFV2';
import EditClientPJ from './EditClient';
import EditContract from './EditContract';
import EditHolder from './EditHolder';
import EditPayment from './EditPayment';

export enum ActualPage {
    CLIENT,
    HOLDER,
    DEPENDENTS,
    CONTRACT,
    PAYMENT,
}

export type InstallmentDTO = {
    id: number,
    paymentGatewayId: number,
    dueDate: string,
    status: string,
    paymentDate: string,
    value: number,
    taxUsed: number,
    galaxpayUrl?: null | string
    galaxMyId?: null | string
    pagvipRef: null | PagVipRefProps,
    isSubscription: boolean,
    subscriptionNumber: null | number,
    subscriptionRef: null | string,
    nonCardTaxes: string | null,
    card: boolean,
    isMain: boolean,
    flag: null | string,
    cardLastDigits: null | string,
    cardPaymentOption: null | string
  }

export type PaymentDTO = {
    contractPfId: number;
    contractValue: number;
    discount: number;
    aditional: number;
    installments: number;
    totalValue: number;
    approvalDate: string;
  }

export type HolderDTO = {
    id: number,
    uuidCtx: string
    name: string,
    sex: 'm' | 'f',
    cpf: string,
    birthday: string,
    rg: string,
    emitent: string,
    addressStreet: string,
    addressNumber: string,
    addressComplement: string,
    addressNeighborhood: string,
    addressCity: string,
    addressIbge: string | null,
    addressState: string,
    addressZipcode: string,
    phone: string,
    phone2: string,
    isConverted: boolean,
    email: string,
    dependents: DependentDTO[],
    observation?: null | string,
  }

export type DependentDTO = {
    id: number,
    uuidCtx: string,
    name: string,
    sex: 'm' | 'f',
    cpf: string,
    birthday: string,
    rg: string,
    role: 'son' | 'spouse' | 'other',
    emitent: string,
    addressStreet: string,
    addressNumber: string,
    addressComplement: string,
    addressNeighborhood: string,
    addressCity: string,
    addressIbge: string | null,
    addressState: string,
    addressZipcode: string,
    phone: string,
    phone2: string,
    email: string,
    observation?: null | string
  }

export type ContractDTO = {
    productId: number,
    value: number,
    totalDependentsEnabled: number,
    totalHoldersEnabled: number,
    signatureDate: string,
    dueDate: string
  }

export type ClientDTO = {
    id: number,
    cnpj: string,
    openingDate: string,
    name: string,
    nameFantasy: string,
    size: string,
    addressStreet: string,
    addressNumber: string,
    addressComplement: string,
    addressIbge: string | null,
    addressNeighborhood: string,
    addressCity: string,
    addressState: string,
    addressZipcode: string,
    email: string,
    phone: string,
    password: string,
    isActive: boolean,
}

export default function EditLeadPJ() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [actualPage, setActualPage] = useState(ActualPage.CLIENT);
  const [loading, setLoading] = useState(false);
  const [haveInstallments, setHaveInstallments] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [originalHolders, setOriginalHolders] = useState<HolderDTO[]>([]);
  const [holders, setHolders] = useState<HolderDTO[]>([{
    id: 0,
    uuidCtx: uuid(),
    cpf: '',
    email: '',
    name: '',
    rg: '',
    emitent: 'SSP/MA',
    birthday: '',
    isConverted: false,
    addressIbge: '',
    addressStreet: '',
    addressNumber: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    sex: 'm',
    addressState: 'MA',
    addressZipcode: '',
    phone: '',
    phone2: '',
    dependents: [],
  }]);
  const [contract, setContract] = useState<ContractDTO>({
    productId: 0,
    value: 0,
    totalDependentsEnabled: 0,
    totalHoldersEnabled: 0,
    signatureDate: '',
    dueDate: '',
  });
  const [payment, setPayment] = useState<PaymentDTO>({
    contractPfId: 0,
    contractValue: 0,
    discount: 0,
    aditional: 0,
    installments: 0,
    totalValue: 0,
    approvalDate: '',
  });
  const [installments, setInstallments] = useState<InstallmentDTO[]>([]);
  const [originalInstallments, setOriginalInstallments] = useState<InstallmentDTO[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [modalErrorRef] = useState(React.createRef<any>());
  const [selectedProduct, setSelectedProduct] = useState<ProductsProps>({
    id: 0,
    name: '',
    benefits: '',
    pricePerHolder: 0,
    pricePerDependent: 0,
    isActive: true,
  });
  const [products, setProducts] = useState<ProductsProps[]>([]);
  const [client, setClient] = useState<ClientDTO>({
    id: 0,
    cnpj: '',
    openingDate: '',
    name: '',
    nameFantasy: '',
    size: 'ME',
    addressStreet: '',
    addressNumber: '',
    addressComplement: '',
    addressIbge: '',
    addressNeighborhood: '',
    addressCity: '',
    addressState: 'MA',
    addressZipcode: '',
    email: '',
    phone: '',
    password: '',
    isActive: true,
  });
  const [originalDependents, setOriginalDependents] = useState<DependentDTO[]>([]);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  async function fetchAllProducts() {
    try {
      setLoading(true);
      const response = await api.get('admin/product', token);
      setProducts(response.data);
      if (contract.productId === 0) {
        setSelectedProduct(response.data[0]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function filterInstallmentsToRemove() {
    const ids: number[] = [];
    const originalIds = originalInstallments.map((item) => item.id);
    const installmentsIds = installments.map((item) => item.id);
    originalIds.forEach((item) => {
      if (!installmentsIds.includes(item)) {
        ids.push(item);
      }
    });
    return ids;
  }

  function filterDependentsToRemove() {
    const ids: number[] = [];
    const originalIds = originalDependents.map((item) => item.id);
    const dependentsIds = holders.flatMap((item) => item.dependents.map((dep) => dep.id));
    originalIds.forEach((item) => {
      if (!dependentsIds.includes(item)) {
        ids.push(item);
      }
    });
    return ids;
  }

  function filterHoldersToRemove() {
    const ids: number[] = [];
    const originalIds = originalHolders.map((item) => item.id);
    const holderIds = holders.map((item) => item.id);
    originalIds.forEach((item) => {
      if (!holderIds.includes(item)) {
        ids.push(item);
      }
    });
    return ids;
  }

  async function removeComponents() {
    await api.post('/admin/lead-delete-components-pj', {
      deleteInstallments: filterInstallmentsToRemove().map((item) => ({
        id: item,
      })),
      deleteHoldersIds: filterHoldersToRemove().map((item) => ({ id: item })),
      deleteDependentsIds: filterDependentsToRemove().map((item) => ({ id: item })),
    }, token);
  }

  function filterNewInstallments() {
    return installments.filter((item) => item.id === 0);
  }

  function filterNonNewInstallments() {
    return installments.filter((item) => item.id !== 0);
  }

  async function sendLead() {
    try {
      setLoading(true);
      await removeComponents();
      await api.put('admin/lead-pj', {
        client: {
          ...client,
          phone: client.phone.length > 0 ? unmaskData(client.phone) : client.phone,
          addressZipcode: client.addressZipcode.length > 0 ? unmaskData(client.addressZipcode) : client.addressZipcode,
          cnpj: client.cnpj.length > 0 ? unmaskData(client.cnpj) : '',
        },
        holders: [...holders.map((item) => ({
          ...item,
          cpf: item.cpf.length > 0 ? unmaskData(item.cpf) : item.cpf,
          birthday: maskDate(item.birthday),
          addressZipcode: item.addressZipcode.length > 0 ? unmaskData(item.addressZipcode) : item.addressZipcode,
          phone: item.phone.length > 0 ? unmaskData(item.phone) : item.phone,
          dependents: [...item.dependents.map((dep) => ({
            ...dep,
            addressZipcode: dep.addressZipcode.length > 0 ? unmaskData(dep.addressZipcode) : dep.addressZipcode,
            cpf: dep.cpf.length > 0 ? unmaskData(dep.cpf) : dep.cpf,
            phone: dep.phone.length > 0 ? unmaskData(dep.phone) : dep.phone,
            birthday: maskDate(dep.birthday),
          }))],
        }))],
        lead: {
          ...contract, id: Number(id), pricePerHolder: selectedProduct.pricePerHolder, pricePerDependent: selectedProduct.pricePerDependent,
        },
        installments: [...filterNonNewInstallments().map((item) => ({ ...item, cardPaymentOption: normalizeToApiCardPaymentWays(item.cardPaymentOption || '') }))],
        newInstallments: [...filterNewInstallments().map((item) => ({ ...item, cardPaymentOption: normalizeToApiCardPaymentWays(item.cardPaymentOption || '') }))],
      }, token);
      setLoading(false);
      setErrors([]);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
      // navigate('/cliente/pf/lead');
    } catch (err: any) {
      console.log(err);
      const errorCtx: string[] = [];
      console.log(err.response.data);
      if (err.response && err.response.status === 422) {
        err.response.data.errors.forEach((e: any) => {
          errorCtx.push(e.message);
        });
        // setErrors(errorCtx);
      } else {
        errorCtx.push('Ocorreu um erro interno e não foi possível processar o cadastro do lead.');
      }
      setModal({
        title: 'Ocorreu um erro',
        children: [
          <div className="d-flex flex-column">
            {errorCtx.map((item) => (
              <p className="w-100 text-center">
                -
                {' '}
                {item}
              </p>
            ))}
          </div>,
        ],
        actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }],
      });
      setLoading(false);
    }
  }

  async function fetchLead() {
    try {
      setLoading(true);
      const response = await api.get(`admin/lead-pj/${id}`, token);
      console.log(response.data);
      setClient({
        id: response.data.client.id ? response.data.client.id : 0,
        cnpj: response.data.client.cnpj ? response.data.client.cnpj : '',
        openingDate: response.data.client.openingDate ? response.data.client.openingDate : '',
        name: response.data.client.name ? response.data.client.name : '',
        nameFantasy: response.data.client.nameFantasy ? response.data.client.nameFantasy : '',
        size: response.data.client.size ? response.data.client.size : '',
        addressStreet: response.data.client.addressStreet ? response.data.client.addressStreet : '',
        addressNumber: response.data.client.addressNumber ? response.data.client.addressNumber : '',
        addressComplement: response.data.client.addressComplement ? response.data.client.addressComplement : '',
        addressNeighborhood: response.data.client.addressNeighborhood ? response.data.client.addressNeighborhood : '',
        addressCity: response.data.client.addressCity ? response.data.client.addressCity : '',
        addressState: response.data.client.addressState ? response.data.client.addressState : '',
        addressZipcode: response.data.client.addressZipcode ? response.data.client.addressZipcode : '',
        email: response.data.client.email ? response.data.client.email : '',
        phone: response.data.client.phone ? response.data.client.phone : '',
        password: response.data.client.password ? response.data.client.password : '',
        isActive: response.data.client.isActive ? response.data.client.isActive : true,
        addressIbge: response.data.client.addressIbge ? response.data.client.addressIbge : '',
      });
      setHolders([
        ...response.data.client.holders.map((item: any) => ({
          ...item,
          uuidCtx: uuid(),
          name: item.name ? item.name : '',
          sex: item.sex ? item.sex : 'm',
          cpf: item.cpf ? item.cpf : '',
          birthday: item.birthday ? item.birthday : '',
          rg: item.rg ? item.rg : '',
          emitent: item.emitent ? item.emitent : 'SSP/MA',
          addressStreet: item.addressStreet ? item.addressStreet : '',
          addressNumber: item.addressNumber ? item.addressNumber : '',
          addressComplement: item.addressComplement ? item.addressComplement : '',
          addressNeighborhood: item.addressNeighborhood ? item.addressNeighborhood : '',
          addressCity: item.addressCity ? item.addressCity : '',
          addressState: item.addressState ? item.addressState : 'MA',
          addressZipcode: item.addressZipcode ? item.addressZipcode : '',
          phone: item.phone ? item.phone : '',
          phone2: item.phone2 ? item.phone2 : '',
          observation: item.observation ? item.observation : '',
          email: item.email ? item.email : '',
          addressIbge: item.addressIbge ? item.addressIbge : '',
          dependents: item.leadDependent.map((dep: DependentDTO) => ({
            ...dep,
            uuidCtx: uuid(),
            name: dep.name ? dep.name : '',
            sex: dep.sex ? dep.sex : 'm',
            cpf: dep.cpf ? dep.cpf : '',
            birthday: dep.birthday ? dep.birthday : '',
            rg: dep.rg ? dep.rg : '',
            role: dep.role ? dep.role : 'son',
            emitent: dep.emitent ? dep.emitent : 'SSP/MA',
            addressStreet: dep.addressStreet ? dep.addressStreet : '',
            addressNumber: dep.addressNumber ? dep.addressNumber : '',
            addressComplement: dep.addressComplement ? dep.addressComplement : '',
            addressNeighborhood: dep.addressNeighborhood ? dep.addressNeighborhood : '',
            addressState: dep.addressState ? dep.addressState : 'MA',
            addressZipcode: dep.addressZipcode ? dep.addressZipcode : '',
            phone: dep.phone ? dep.phone : '',
            phone2: dep.phone2 ? dep.phone2 : '',
            email: dep.email ? dep.email : '',
            addressIbge: dep.addressIbge ? dep.addressIbge : '',
            observation: dep.observation ? dep.observation : '',
          })),
        })),
      ]);
      setOriginalHolders([
        ...response.data.client.holders.map((item: any) => ({
          ...item,
          uuidCtx: uuid(),
          name: item.name ? item.name : '',
          sex: item.sex ? item.sex : 'm',
          cpf: item.cpf ? item.cpf : '',
          birthday: item.birthday ? item.birthday : '',
          rg: item.rg ? item.rg : '',
          emitent: item.emitent ? item.emitent : 'SSP/MA',
          addressStreet: item.addressStreet ? item.addressStreet : '',
          addressNumber: item.addressNumber ? item.addressNumber : '',
          addressComplement: item.addressComplement ? item.addressComplement : '',
          addressNeighborhood: item.addressNeighborhood ? item.addressNeighborhood : '',
          addressCity: item.addressCity ? item.addressCity : '',
          addressState: item.addressState ? item.addressState : 'MA',
          addressZipcode: item.addressZipcode ? item.addressZipcode : '',
          phone: item.phone ? item.phone : '',
          phone2: item.phone2 ? item.phone2 : '',
          email: item.email ? item.email : '',
          observation: item.observation ? item.observation : '',
          addressIbge: item.addressIbge ? item.addressIbge : '',
          dependents: item.leadDependent.map((dep: DependentDTO) => ({
            ...dep,
            uuidCtx: uuid(),
            name: dep.name ? dep.name : '',
            sex: dep.sex ? dep.sex : 'm',
            cpf: dep.cpf ? dep.cpf : '',
            birthday: dep.birthday ? dep.birthday : '',
            rg: dep.rg ? dep.rg : '',
            role: dep.role ? dep.role : 'son',
            emitent: dep.emitent ? dep.emitent : 'SSP/MA',
            addressStreet: dep.addressStreet ? dep.addressStreet : '',
            addressNumber: dep.addressNumber ? dep.addressNumber : '',
            addressComplement: dep.addressComplement ? dep.addressComplement : '',
            addressNeighborhood: dep.addressNeighborhood ? dep.addressNeighborhood : '',
            addressState: dep.addressState ? dep.addressState : 'MA',
            addressZipcode: dep.addressZipcode ? dep.addressZipcode : '',
            phone: dep.phone ? dep.phone : '',
            phone2: dep.phone2 ? dep.phone2 : '',
            email: dep.email ? dep.email : '',
            addressIbge: dep.addressIbge ? dep.addressIbge : '',
            observation: dep.observation ? dep.observation : '',
          })),
        })),
      ]);

      const allHolders: any[] = response.data.client.holders;
      const allDeps = allHolders.flatMap((item) => item.leadDependent.map((dep:any) => dep));
      setOriginalDependents(allDeps);
      setContract({
        ...contract,
        productId: response.data.productId,
        totalDependentsEnabled: response.data.totalDependentsEnabled,
        totalHoldersEnabled: response.data.totalHoldersEnabled,
        signatureDate: response.data.signatureDate,
        dueDate: response.data.dueDate,
        value: response.data.value,
      });
      setHaveInstallments(response.data.installment.length > 0);
      const filteredProduct = products.filter((item) => item.id === response.data.productId);
      console.log('response', response.data);
      if (filteredProduct.length > 0) {
        setSelectedProduct({ ...filteredProduct[0], pricePerHolder: response.data.pricePerHolder, pricePerDependent: response.data.pricePerDependent });
      }
      setInstallments(response.data.installment);
      setOriginalInstallments(response.data.installment);
      setPayment({ ...payment, installments: response.data.installment.length });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function changeGlobalValue() {
    const globalContract = (contract.totalDependentsEnabled * selectedProduct.pricePerDependent) + (selectedProduct.pricePerHolder * contract.totalHoldersEnabled);
    setContract({ ...contract, value: Number((globalContract * 12).toFixed(2)), productId: selectedProduct.id });
  }

  async function generatePayment(installmentId: number) {
    try {
      setLoading(true);
      const response = await api.post('/admin/lead/pj/generatesinglecharge', {
        leadPjInstallmentId: installmentId,
      }, token);
      await sendLead();
      window.open(response.data.Charge.Transactions[0].Pix.page, '_blank');
      await fetchLead();
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
    }
  }

  async function cancelPayment(installmentId: number) {
    try {
      setLoading(true);
      const response = await api.post('admin/lead/pj/cancelcharge', {
        leadPjInstallmentId: installmentId,
      }, token);
      await fetchLead();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function changePricePerHolder(price: number) {
    const globalContract = (contract.totalHoldersEnabled * price) + (contract.totalDependentsEnabled * selectedProduct.pricePerDependent);
    setContract({ ...contract, value: Number((globalContract * 12).toFixed(2)) });
    setSelectedProduct({ ...selectedProduct, pricePerHolder: price });
  }

  function changePricePerDependent(price: number) {
    const globalContract = (contract.totalDependentsEnabled * price) + (contract.totalHoldersEnabled * selectedProduct.pricePerHolder);
    setContract({ ...contract, value: Number((globalContract * 12).toFixed(2)) });
    setSelectedProduct({ ...selectedProduct, pricePerDependent: price });
  }

  useEffect(() => {
    fetchAllProducts();
  }, []);

  useEffect(() => {
    changeGlobalValue();
  }, [selectedProduct]);

  useEffect(() => {
    fetchAllProducts();
  }, []);

  useEffect(() => {
    fetchLead();
  }, [products]);

  useEffect(() => {
    console.log(originalDependents);
  }, [originalDependents]);

  useEffect(() => {
    console.log('holders', holders);
  }, [holders]);

  return (
    <DefaultLayout
      pageTitle="Adesão"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      {actualPage === ActualPage.HOLDER && (
      <EditHolder
        pHolders={holders}
        setLoading={setLoading}
        setPHolders={setHolders}
        setActualPage={setActualPage}
        setContractLifes={(holders, dep) => setContract({ ...contract, totalDependentsEnabled: dep, totalHoldersEnabled: holders })}
      />
      )}
      {actualPage === ActualPage.CLIENT && (
      <EditClientPJ
        client={client}
        setClient={setClient}
        setActualPage={setActualPage}
        setLoading={setLoading}
      />
      )}
      {actualPage === ActualPage.CONTRACT && (
      <EditContract
        lifesAmount={0}
        contract={contract}
        setContract={setContract}
        setLoading={setLoading}
        setActualPage={setActualPage}
        setModal={setModal}
        products={products}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        changePricePerHolder={(arg) => changePricePerHolder(arg)}
        changePricePerDependent={(arg) => changePricePerDependent(arg)}
      />
      )}
      {actualPage === ActualPage.PAYMENT && (
      <EditPayment
        setModal={(arg) => setModal(arg)}
        setActualPage={(arg) => setActualPage(arg)}
        payment={payment}
        sendLead={() => sendLead()}
        haveInstallments={haveInstallments}
        setHaveInstallments={(arg) => setHaveInstallments(arg)}
        setPayment={(data) => setPayment(data)}
        pInstallments={installments}
        setInstallments={(data) => setInstallments(data)}
        contractProps={contract}
        generateInstallment={(arg) => generatePayment(arg)}
        cancelPayment={(arg) => cancelPayment(arg)}
      />
      )}
    </DefaultLayout>
  );
}
