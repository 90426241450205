import React, { useEffect, useState } from 'react';
import * as EmailValidator from 'email-validator';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInput from '../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import {
  maskCep, maskCPForCNPJ, maskDate, maskNumber, maskPhone, unmaskData,
} from '../../helpers/inputMasks.js';
import { validateCnpjOrCpf, validateCpf } from '../../helpers/validate';
import DefaultLayout from '../../Layout';
import BasicOption from '../../components/BasicOption';
import { statesOfBrazil } from '../../dto/states';
import ClientPJProps from '../../dto/clientPJ';
import api from '../../config/axios';
import rgEmittent from '../../dto/rgEmittent';
import showApiErrors from '../../helpers/showApiErrors';
import { fetchClientByIdAndCheckIfLimitLifeIsNotFulfilled } from '../../helpers/checkContractLimit';
import CEPFinder from '../../helpers/findAddressByCep';
import AddInput from '../../components/AddInput';
import TextAreaInput from '../../components/TextAreaInput';

export default function AddNewHolder() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [client, setClient] = useState<ClientPJProps | null>(null);
  const [holder, setHolder] = useState({
    id: 0,
    cpf: '',
    name: '',
    cardname: '',
    addressStreet: '',
    addressNumber: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    email: '',
    addressIbge: '',
    instagram: '',
    sex: 'm',
    phone2: '',
    rg: '',
    emitent: 'SSP/MA',
    birthday: '',
    addressState: 'MA',
    addressZipcode: '',
    phone: '',
    enableTelemedicine: false,
    isActive: true,
    observation: '',
  });

  async function changeAddressByCep() {
    try {
      setLoading(true);
      const findCep = await new CEPFinder(unmaskData(holder.addressZipcode)).findCep();
      if (findCep !== null) {
        setHolder({
          ...holder,
          addressCity: findCep.city,
          addressState: findCep.state,
          addressNeighborhood: findCep.neighborhood,
          addressStreet: findCep.street,
          addressIbge: findCep.ibge,
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  async function fetchClient() {
    try {
      setLoading(true);
      const response = await api.get(`admin/client/${id}`, token);
      setClient(response.data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro ao buscar', children: 'Cliente não encontrado. Verifique a URL ou sua conectividade.', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
      console.log(err.response);
    }
  }

  async function checkAllowAdd() {
    try {
      const canProceed = await fetchClientByIdAndCheckIfLimitLifeIsNotFulfilled(id!);
      if (canProceed) {
        return;
      }
      setModal({ title: 'Ocorreu um erro', children: 'O Limite de vidas ativas previstas em contrato já foi atingido. Desative uma vida para adicionar outra', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
    } catch (err: any) {
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível comunicar com a api. Verifique sua conectividade e tente em instantes.', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
    }
  }

  async function saveHolder() {
    try {
      setLoading(true);
      await checkAllowAdd();
      await api.post(`admin/holder/${id}`, {
        ...holder,
        cpf: unmaskData(holder.cpf),
        addressZipcode: unmaskData(holder.addressZipcode),
        phone: unmaskData(holder.phone),
        phone2: holder.phone2 && holder.phone2.length > 0 ? unmaskData(holder.phone2) : '',
        observation: holder.observation || '',
      }, token);
      setLoading(false);
      navigate(-1);
    } catch (err: any) {
      if (typeof err.response !== 'undefined') {
        if (err.response.status === 422) {
          setModal(showApiErrors(setModal, err.response.data.errors));
        }
      } else {
        setModal({ title: 'Ocorreu um erro ao cadastrar', children: 'Não foi possível cadastrar um titular. Caso o problema persista, favor entrar em contato com suporte.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }
      console.log(err.response);
      setLoading(false);
    }
  }

  function enabledButton() {
    if (holder.name.length > 0
        && validateCpf(holder.cpf)
        && holder.addressStreet.length > 0
        && holder.addressNumber.length > 0
        && holder.addressNeighborhood.length > 0
        && holder.addressCity.length > 0
        && holder.addressCity.length > 0
        && holder.addressZipcode.length >= 10
        && holder.phone.length > 2
    ) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    checkAllowAdd();
    fetchClient();
  }, []);

  return (
    <DefaultLayout
      pageTitle={`${client?.name} > Adicionar Aderente`}
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row holders card-bg p-0">
        <section className="row">
          <h1>Cadastro Aderente Titular</h1>
          <div className="col-4">
            <BasicInput
              id="name-input"
              label="Nome"
              readOnly={false}
              helper="Ex: João Batista"
              error={holder.name.length < 1 ? 'Campo Obrigatório' : ''}
              value={holder.name}
              type="text"
              onChange={(data) => setHolder({ ...holder, name: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="card-name-input"
              label="Nome no cartão"
              readOnly={false}
              helper="Ex: João B. C. Vieira"
              error=""
              value={holder.cardname === null ? '' : holder.cardname}
              type="text"
              onChange={(data) => setHolder({ ...holder, cardname: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="cpf-input"
              label="CPF"
              readOnly={false}
              helper="Ex: 000.000.000-10"
              error={!validateCnpjOrCpf(holder.cpf) ? 'Digite um CPF válido.' : ''}
              value={holder.cpf}
              type="text"
              onChange={(data) => setHolder({ ...holder, cpf: maskCPForCNPJ(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="rg-input"
              label="RG"
              readOnly={false}
              helper="Ex: 99999999"
              error=""
              value={holder.rg}
              type="text"
              onChange={(data) => setHolder({ ...holder, rg: maskNumber(data) })}
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="rg-emittent-input"
              label="RG / Emissor"
              readOnly={false}
              helper="Ex: SSP/MA"
              error=""
              value={holder.emitent}
              onChange={(data) => setHolder({ ...holder, emitent: data })}
            >
              {rgEmittent}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicOption
              id="sex-input"
              label="Sexo"
              readOnly={false}
              helper="Ex: Masculino / Feminino"
              error=""
              value={holder.sex === 'm' ? 'Masculino' : 'Feminino'}
              onChange={(data) => setHolder({ ...holder, sex: data === 'Masculino' ? 'm' : 'f' })}
            >
              {['Masculino', 'Feminino']}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicInput
              id="birth-input"
              label="DT. Nascimento"
              readOnly={false}
              helper="Ex: 13-01-1990"
              error={holder.birthday.length < 1 ? 'Campo obrigatório' : ''}
              value={holder.birthday}
              type="text"
              onChange={(data) => setHolder({ ...holder, birthday: maskDate(data) })}
            />
          </div>
          <div className="col-4">
            <AddInput
              id="address-cep-input"
              label="CEP"
              readOnly={false}
              helper="Ex: 35.171-120"
              error={holder.addressZipcode.length < 10 ? 'Campo obrigatório' : ''}
              value={holder.addressZipcode}
              type="text"
              onAdd={() => changeAddressByCep()}
              onChange={(data) => setHolder({ ...holder, addressZipcode: maskCep(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-street-input"
              label="Rua"
              readOnly={false}
              helper="Ex: Rua Três"
              error={holder.addressStreet.length < 2 ? 'Campo Obrigatório' : ''}
              value={holder.addressStreet}
              type="text"
              onChange={(data) => setHolder({ ...holder, addressStreet: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-number-input"
              label="Número"
              readOnly={false}
              helper="Ex: 133"
              error={holder.addressNumber.length < 1 ? 'Campo Obrigatório' : ''}
              value={holder.addressNumber}
              type="text"
              onChange={(data) => setHolder({ ...holder, addressNumber: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-complement-input"
              label="Complemento"
              readOnly={false}
              helper="Ex: Bloco A"
              error=""
              value={holder.addressComplement}
              type="text"
              onChange={(data) => setHolder({ ...holder, addressComplement: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-neighborhood-input"
              label="Bairro"
              readOnly={false}
              helper="Centro"
              error={holder.addressNeighborhood.length < 1 ? 'Campo obrigatório' : ''}
              value={holder.addressNeighborhood}
              type="text"
              onChange={(data) => setHolder({ ...holder, addressNeighborhood: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-city-input"
              label="Cidade"
              readOnly
              helper="Ex: Imperatriz"
              error={holder.addressCity.length < 1 ? 'Campo obrigatório' : ''}
              value={holder.addressCity}
              type="text"
              onChange={(data) => setHolder({ ...holder, addressCity: data })}
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="address-state-input"
              label="Estado"
              readOnly
              helper=""
              error=""
              value={holder.addressState}
              onChange={(data) => setHolder({ ...holder, addressState: data })}
            >
              {statesOfBrazil}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicInput
              id="phone-input"
              label="Telefone"
              readOnly={false}
              helper="Ex: (31) 99999 - 9999"
              error={holder.phone.length < 1 ? 'Campo obrigatório' : ''}
              value={holder.phone}
              type="text"
              onChange={(data) => setHolder({ ...holder, phone: maskPhone(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="phone2-input"
              label="Telefone 2"
              readOnly={false}
              helper="Ex: (31) 99999 - 9999"
              error=""
              value={holder.phone2 || ''}
              type="text"
              onChange={(data) => setHolder({ ...holder, phone2: maskPhone(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="email-input"
              label="email"
              readOnly={false}
              helper="Ex: usuario@usuario.com"
              error={holder.email && holder.email.length > 0 && !EmailValidator.validate(holder.email) ? 'E-mail inválido' : ''}
              value={holder.email ? holder.email : ''}
              type="text"
              onChange={(data) => setHolder({ ...holder, email: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="instagram-input"
              label="instagram"
              readOnly={false}
              helper="Ex: valdecir_roberto"
              error=""
              value={holder.instagram ? holder.instagram : ''}
              type="text"
              onChange={(data) => setHolder({ ...holder, instagram: data })}
            />
          </div>
          <div className="col-12">
            <TextAreaInput
              id=""
              label="Observação"
              readOnly={false}
              helper=""
              error=""
              value={holder.observation || ''}
              onChange={(data) => setHolder({ ...holder, observation: data })}
            />
          </div>
          <div className="col-12 optionals mt-4 mb-4">
            <p>Opcionais</p>
            <div className="check-wrapper">
              <input type="checkbox" checked={holder.enableTelemedicine} onChange={() => setHolder({ ...holder, enableTelemedicine: !holder.enableTelemedicine })} />
              <p>Telemedicina</p>
            </div>
          </div>
          <div className="col-12 footer">
            <ButtonTextIcon
              label="salvar"
              type={BtnType.PRIMARY}
              icon="backup"
              active={enabledButton()}
              onClick={() => saveHolder()}
              ref={null}
            />
          </div>
        </section>
      </div>
    </DefaultLayout>
  );
}
