import { DateTime } from 'luxon';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import DashboardCardOverlay from '../../../components/DashboardCardOverlay';
import api from '../../../config/axios';
import { maskDate } from '../../../helpers/inputMasks.js';

type ResponseProps = {
    id: number,
    name: string,
    isHolder: boolean,
    isPj: boolean,
    clientPjId: null | number
    birth: string,
    message: null | string
}

export default function Birthday() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ResponseProps[]>([]);
  const [fetchedDate, setFetchedDate] = useState('');
  const [fetchedDateError, setFetchedDateErrror] = useState(false);
  const [date, setDate] = useState(DateTime.local({ zone: 'America/Sao_Paulo' }).toFormat('dd-MM-yyyy'));
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function fetchBirthday() {
    try {
      setLoading(true);
      const response = await api.post('admin/reports/birthdayadvisor', {
        date,
      }, token);
      setData(response.data);
      setFetchedDateErrror(false);
      console.log('birth', response.data);
      setFetchedDate(date !== '' ? date : DateTime.local({ zone: 'America/Sao_Paulo' }).toFormat('dd-MM-yyyy'));
      setLoading(false);
    } catch (err) {
      setFetchedDateErrror(true);
      setLoading(false);
    }
  }

  function goToClient(client: ResponseProps) {
    if (client.isPj) {
      if (client.isHolder) {
        navigate(`/cliente/editar-aderente/${client.clientPjId}/${client.id}`);
      } else {
        navigate(`/cliente/editar-dependente/${client.id}`);
      }
    } else if (client.isHolder) {
      navigate(`/cliente/pessoa-fisica/editar-titular/${client.id}`);
    } else {
      navigate(`/cliente/editar-dependente-pf/${client.id}`);
    }
  }

  useEffect(() => {
    fetchBirthday();
  }, []);

  return (
    <DashboardCardOverlay loading={loading}>
      <div className="contract-reports-cards position-relative h-100">
        <div className="title-wrapper">
          <div className="">
            <h5 className="title">
              Aniversariantes do dia
              {' '}
              {fetchedDate}
            </h5>
          </div>
        </div>
        <div className="table-wrapper px-4 mt-0">
          <div className="table-actions">
            <div className="d-flex">
              <BasicInput
                id="date-birth"
                label="Data"
                readOnly={false}
                helper=""
                error=""
                value={date}
                type="text"
                onChange={(el) => setDate(maskDate(el))}
              />
              <button className="table-btn-primary m-2 mt-3" type="button" onClick={() => fetchBirthday()}>
                <span className="material-icons-round">
                  search
                </span>
                filtrar
              </button>
            </div>
          </div>
          {fetchedDateError && (
          <p style={{ color: 'red', fontSize: 8 }}>Digite uma data válida</p>
          )}
          <table className="table table-borderless">
            <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">TIPO</th>
                <th className="text-end" scope="col">Ações</th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 && 'Nenhum aniversariante hoje.'}
              {data.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.isHolder ? 'Titular' : 'Dependente'}</td>
                  <td align="right">
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="table-action-icon">
                        <button type="button" onClick={() => goToClient(item)}>
                          <span className="material-icons-round">
                            more
                          </span>
                        </button>
                      </div>
                      {item.message && (
                      <div className="table-action-icon">
                        <button type="button" onClick={() => window.open(item.message!, '_blank')}>
                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" height="24px" width="24px" version="1.1" id="Capa_1" viewBox="0 0 58 58" xmlSpace="preserve">
                            <g>
                              <path style={{ fill: '#2CB742' }} d="M0,58l4.988-14.963C2.457,38.78,1,33.812,1,28.5C1,12.76,13.76,0,29.5,0S58,12.76,58,28.5   S45.24,57,29.5,57c-4.789,0-9.299-1.187-13.26-3.273L0,58z" />
                              <path style={{ fill: '#FFFFFF' }} d="M47.683,37.985c-1.316-2.487-6.169-5.331-6.169-5.331c-1.098-0.626-2.423-0.696-3.049,0.42   c0,0-1.577,1.891-1.978,2.163c-1.832,1.241-3.529,1.193-5.242-0.52l-3.981-3.981l-3.981-3.981c-1.713-1.713-1.761-3.41-0.52-5.242   c0.272-0.401,2.163-1.978,2.163-1.978c1.116-0.627,1.046-1.951,0.42-3.049c0,0-2.844-4.853-5.331-6.169   c-1.058-0.56-2.357-0.364-3.203,0.482l-1.758,1.758c-5.577,5.577-2.831,11.873,2.746,17.45l5.097,5.097l5.097,5.097   c5.577,5.577,11.873,8.323,17.45,2.746l1.758-1.758C48.048,40.341,48.243,39.042,47.683,37.985z" />
                            </g>
                          </svg>
                        </button>
                      </div>
                      )}

                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </DashboardCardOverlay>
  );
}
