/* eslint-disable max-len */
import React from 'react';
import DashboardCardOverlay from '../../../../components/DashboardCardOverlay';

type DataResponse = {
  total: number,
  active: number,
  blocked: number,
  inactive: number,
  tempBlocked: number,
  loading: boolean
}

export default function ClientPFActivities({
  total, active, blocked, inactive, tempBlocked, loading,
}: DataResponse) {
  // function calculatePercentageOfInactives() {
  //   const result = (inactive * 100) / total;
  //   return result === Infinity ? 0 : result;
  // }

  function calculatePercentageOfActives() {
    const result = (active * 100) / total;
    return result === Infinity ? 0 : result;
  }

  function calculatePercentageOfBlocked() {
    const result = (blocked * 100) / total;
    return result === Infinity ? 0 : result;
  }

  function calculatePercentageOfTempBlocked() {
    const result = (tempBlocked * 100) / total;
    return result === Infinity ? 0 : result;
  }

  return (
    <DashboardCardOverlay loading={loading}>
      <div className="card-contract-activities h-100">
        <div className="title-wrapper">
          <div className="w-100">
            <h5 className="title">Atividades de Titulares PF</h5>
            <div className="d-flex w-100 justify-content-center align-items-center">
              <div className="total-wrapper mt-4">
                <p>total</p>
                <p>{total}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <button type="button">
            <p>
              Ativos -
              {' '}
              {active}
              {' '}
              {!Number.isNaN(calculatePercentageOfActives()) && `- ${calculatePercentageOfActives().toFixed(2)}%`}
            </p>
            <div className="track-wrapper">
              <div className="track-active" style={{ width: `${calculatePercentageOfActives()}%` }} />
            </div>
          </button>
          {/* <button type="button">
            <p>
              Inativos -
              {' '}
              {inactive}
              {' '}
              {!Number.isNaN(calculatePercentageOfInactives()) && `- ${calculatePercentageOfInactives().toFixed(2)}%`}
            </p>
            <div className="track-wrapper">
              <div className="track-inactive" style={{ width: `${calculatePercentageOfInactives()}%` }} />
            </div>
          </button> */}
          <button type="button">
            <p>
              Bloqueados -
              {' '}
              {tempBlocked}
              {' '}
              {!Number.isNaN(calculatePercentageOfTempBlocked()) && `- ${calculatePercentageOfTempBlocked().toFixed(2)}%`}
            </p>
            <div className="track-wrapper">
              <div className="track-inactive" style={{ width: `${calculatePercentageOfTempBlocked()}%` }} />
            </div>
          </button>
          <button type="button">
            <p>
              Cancelados -
              {' '}
              {blocked}
              {' '}
              {!Number.isNaN(calculatePercentageOfBlocked()) && `- ${calculatePercentageOfBlocked().toFixed(2)}%`}
            </p>
            <div className="track-wrapper">
              <div className="track-inactive" style={{ width: `${calculatePercentageOfBlocked()}%` }} />
            </div>
          </button>
        </div>
      </div>
    </DashboardCardOverlay>
  );
}
