import React, { useEffect, useState } from 'react';
import BasicInput from '../../components/BasicInput';
import { ModalProps } from '../../components/Modal';
import PriceInput from '../../components/PriceInput';
import api from '../../config/axios';
import DefaultLayout from '../../Layout';
import { PaymentGateway } from './types';

export default function PaymentTax() {
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [paymentGateways, setPaymentGateways] = useState<PaymentGateway[]>([]);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function fetchAllPaymentTax() {
    try {
      setLoading(true);
      const response = await api.get('/admin/configuration/payment-gateway', token);
      setPaymentGateways(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAllPaymentTax();
  }, []);

  return (
    <DefaultLayout
      pageTitle="Taxas de pagamento"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >

      {paymentGateways.map((item) => (
        <div className="row products" key={item.id}>
          <div className="col-12 p-0 card-bg p-4">
            <h1>{item.name}</h1>
            <div className="row">
              <div className="col-12">
                {item.card.length > 0 && (
                  <h4 className="fw-bold">Taxas cartão</h4>
                )}

                {item.card.length > 0 && item.card.map((card) => (
                  <div className="bg-light p-4 mt-2" style={{ marginBottom: 42 }} key={card.id}>
                    <BasicInput
                      id=""
                      label=""
                      readOnly
                      helper=""
                      error=""
                      value={card.flag}
                      type=""
                      onChange={() => null}
                    />
                    <div className="row">
                      {card.credit_1 && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_1}`}
                            label="1X Crédito"
                            readOnly
                            icon="%"
                            helper=""
                            error=""
                            value={card.credit_1.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}
                      {card.credit_2 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_2}`}
                            icon="%"
                            label="2X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_2.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_3 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_3}`}
                            icon="%"
                            label="3X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_3.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_4 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_4}`}
                            icon="%"
                            label="4X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_4.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_5 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_5}`}
                            icon="%"
                            label="5X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_5.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_6 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_6}`}
                            icon="%"
                            label="6X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_6.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_7 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_7}`}
                            icon="%"
                            label="7X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_7.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_8 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_8}`}
                            icon="%"
                            label="8X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_8.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_9 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_9}`}
                            icon="%"
                            label="9X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_9.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_10 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_10}`}
                            icon="%"
                            label="10X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_10.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_11 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_11}`}
                            icon="%"
                            label="11X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_11.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_12 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_12}`}
                            icon="%"
                            label="12X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_12.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_13 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_13}`}
                            icon="%"
                            label="13X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_13.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_14 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_14}`}
                            icon="%"
                            label="14X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_14.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_15 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_15}`}
                            icon="%"
                            label="15X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_15.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_16 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_16}`}
                            icon="%"
                            label="16X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_16.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_17 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_17}`}
                            icon="%"
                            label="17X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_17.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                      {card.credit_18 !== null && (
                        <div className="col-4">
                          <PriceInput
                            id={`${card.id}${card.credit_18}`}
                            icon="%"
                            label="18X Crédito"
                            readOnly
                            helper=""
                            error=""
                            value={card.credit_18.toString()}
                            type=""
                            onChange={() => null}
                          />
                        </div>
                      )}

                    </div>
                  </div>
                ))}

                {item.taxes.length > 0 && (
                <h4 className="fw-bold">Outras taxas</h4>
                )}

                {item.taxes.length > 0 && item.taxes.map((tax) => (
                  <div className="row mt-4" key={tax.id}>
                    {tax.boleto !== null && (

                    <div className="col-4">
                      <PriceInput
                        key={tax.id}
                        id={tax.id.toString()}
                        icon="R$"
                        label="boleto"
                        readOnly
                        helper=""
                        error=""
                        value={tax.boleto.toString()}
                        type=""
                        onChange={() => null}
                      />
                    </div>

                    )}

                    {tax.dinheiro !== null && (

                    <div className="col-4">
                      <PriceInput
                        key={tax.id}
                        id={tax.id.toString()}
                        icon="%"
                        label="Dinheiro"
                        readOnly
                        helper=""
                        error=""
                        value={tax.dinheiro.toString()}
                        type=""
                        onChange={() => null}
                      />
                    </div>

                    )}
                    {tax.doc !== null && (

                    <div className="col-4">
                      <PriceInput
                        key={tax.id}
                        id={tax.id.toString()}
                        icon="%"
                        label="DOC"
                        readOnly
                        helper=""
                        error=""
                        value={tax.doc.toString()}
                        type=""
                        onChange={() => null}
                      />
                    </div>

                    )}

                    {tax.ted !== null && (

                    <div className="col-4">
                      <PriceInput
                        key={tax.id}
                        id={tax.id.toString()}
                        icon="%"
                        label="TED"
                        readOnly
                        helper=""
                        error=""
                        value={tax.ted.toString()}
                        type=""
                        onChange={() => null}
                      />
                    </div>

                    )}

                    {tax.pix !== null && (
                    <div className="col-4">
                      <PriceInput
                        key={tax.id}
                        id={tax.id.toString()}
                        icon={tax.pixPercentage ? '%' : 'R$'}
                        label="PIX"
                        readOnly
                        helper=""
                        error=""
                        value={tax.pix.toString()}
                        type=""
                        onChange={() => null}
                      />
                    </div>
                    )}

                  </div>
                ))}

              </div>
            </div>
          </div>
        </div>
      ))}
    </DefaultLayout>
  );
}
