/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import TextAreaInput from '../../../components/TextAreaInput';
import api from '../../../config/axios';
import BasicOption from '../../../components/BasicOption';

type FilterProps = {
    id: string,
    setLoading: (arg0: boolean) => void,
}

enum PageStates {
    LOADING,
    SUCCESS,
    ERROR,
    IDLE
}

type DataProps = {
  id: number,
  justification: string,
  isActive: boolean
}

export default function BlockPermaContractPJModal({
  id,
  setLoading,
}: FilterProps) {
  const [blockReason, setBlockReason] = useState('');
  const [actualPageState, setActualPageState] = useState(PageStates.IDLE);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [ref] = useState(React.createRef<any>());
  const [cancelResons, setCancelReasons] = useState<DataProps[]>([]);
  const [selectedCancelReason, setSelectedCancelReason] = useState<DataProps>();

  async function fetchCancelReasons() {
    try {
      setActualPageState(PageStates.LOADING);
      const response = await api.get<DataProps[]>('/admin/block-justifications/active', token);
      if (response.data.length > 0) {
        setSelectedCancelReason(response.data[0]);
      }
      setCancelReasons(response.data);
      setActualPageState(PageStates.IDLE);
    } catch (err) {
      setActualPageState(PageStates.ERROR);
    }
  }

  function selectCancelReason(param: string) {
    const filtered = cancelResons.find((item) => item.justification === param);
    if (filtered) {
      setSelectedCancelReason(filtered);
    }
  }

  async function setBlock() {
    try {
      setLoading(true);
      await api.post('admin/contracts/pj/block-perma', {
        contractPjId: id,
        justificationId: selectedCancelReason?.id.toString(),
        reason: blockReason.trim(),
      }, token);
      setActualPageState(PageStates.SUCCESS);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCancelReasons();
  }, []);

  function onClose() {
    window.location.reload();
  }
  return (
    <div className="modal fade" id={`bloq-perma-${id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Cancelamento do contrato</h5>
            <button type="button" className="btn-close d-none" onClick={() => onClose()} aria-label="Close" />
            <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" ref={ref} />
          </div>
          <div className="modal-body" style={{ minHeight: 300 }}>
            {actualPageState === PageStates.IDLE && (
            <>
              <p className="mb-4 fw-bold text-start">Essa ação irá cancelar o contrato e todos os usuários a ele relacionado (titular e dependentes). Tem certeza?</p>
              <BasicOption
                id="cancel-reason-option"
                label="Motivo"
                readOnly={false}
                helper=""
                error=""
                value={selectedCancelReason?.justification || ''}
                onChange={(cb) => selectCancelReason(cb)}
              >
                {[...cancelResons.map((item) => item.justification)]}
              </BasicOption>
              <TextAreaInput
                id=""
                label="Observação"
                readOnly={false}
                helper="Observação"
                error=""
                value={blockReason}
                onChange={(data) => setBlockReason(data)}
              />

            </>
            )}

            {actualPageState === PageStates.LOADING && (
            <p>Carregando, aguarde...</p>
            )}

            {actualPageState === PageStates.ERROR && (
            <p>Ocorreu um erro ao tentar fazer o bloqueio. Tente novamente mais tarde.</p>
            )}

            {actualPageState === PageStates.SUCCESS && (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 300 }}>
              <p className="text-center">Contrato bloqueado com sucesso!</p>
            </div>
            )}

          </div>
          <div className="modal-footer">
            {actualPageState !== PageStates.LOADING && (<button type="button" className="btn btn-secondary" onClick={() => onClose()}>Fechar</button>)}
            {actualPageState !== PageStates.LOADING && actualPageState !== PageStates.SUCCESS && (
            <button type="button" className="btn btn-danger" onClick={() => setBlock()}>Bloquear</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
