/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import api from '../../../config/axios';
import PartnersCategories from '../../../dto/partnersCategories';

type OrderCategoriesModalProps = {
    id: string;
    setLoading: (arg0: boolean) => void;
    fetchCategories: () => void;
    categories: PartnersCategories[];
    onSuccess: () => void;
}

export default function OrderCategoriesModal({
  id, categories, fetchCategories, setLoading, onSuccess,
}: OrderCategoriesModalProps) {
  const [selectedPartner, setSelectedPartner] = useState<PartnersCategories>();
  const [categoriesCtx, setCategoriesCtx] = useState<PartnersCategories[]>([]);
  const [ref] = useState(React.createRef<any>());

  function arrayMove(arr: any, oldIndex: number, newIndex: number) {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }

  function up() {
    let elements = [...categoriesCtx];
    if (selectedPartner) {
      elements.forEach((item, i) => {
        if (item === selectedPartner && i !== 0) {
          elements = arrayMove([...elements], i, i - 1);
        }
      });
    }
    setCategoriesCtx(elements);
  }

  function down() {
    let elements = [...categoriesCtx];
    if (selectedPartner) {
      elements.forEach((item, i) => {
        if (item === selectedPartner && i !== elements.length - 1) {
          elements = arrayMove([...elements], i, i + 1);
        }
      });
    }
    setCategoriesCtx(elements);
  }

  async function changePosition() {
    try {
      setLoading(true);
      const newArray = categoriesCtx.map((item, i) => ({
        ...item,
        position: i,
      }));
      const request = newArray.map((item) => api.put(`/admin/accredited/category/${item.id}`, item, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } }));
      await Promise.all(request);
      await fetchCategories();
      setLoading(false);
      onSuccess();
      ref.current.click();
    } catch (err: any) {
      setLoading(false);
      console.log(err.response);
      alert('Ocorreu um erro');
    }
  }

  useEffect(() => {
    setCategoriesCtx(categories.sort((a, b) => a.position - b.position));
  }, [categories]);
  return (
    <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog d-flex align-items-center flex-column">
        <div className="modal-content" style={{ width: 800 }}>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Selecione a ordem</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={ref} />
          </div>
          <div className="modal-body d-flex w-100">
            <div className="partners-modal-wrapper d-flex flex-column p-2" style={{ flex: 1, overflow: 'scroll', maxHeight: 500 }}>
              {categoriesCtx.map((item, i) => (
                <button type="button" className={selectedPartner === item ? 'partner-active' : 'partner-inactive'} onClick={() => setSelectedPartner(item)}>
                  {i + 1}
                  {' '}
                  -
                  {' '}
                  {item.title}
                </button>
              ))}
            </div>
            <div className="controller-wrapper d-flex flex-column align-items-center justify-content-center">
              <button type="button" onClick={() => up()}>
                <span className="material-icons-round">expand_less</span>
              </button>
              <button type="button" onClick={() => down()}>
                <span className="material-icons-round">expand_more</span>
              </button>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            <button type="button" className="btn btn-primary" onClick={() => changePosition()}>Alterar ordem</button>
          </div>
        </div>
      </div>
    </div>
  );
}
