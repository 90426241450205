/* eslint-disable react/require-default-props */
import React from 'react';

interface PriceInputProps {
    id: string;
    label: string;
    readOnly: boolean;
    helper: string;
    error: string;
    value: string;
    type: string;
    icon?: string;
    onChange: (arg0: string) => void;
}

export default function PriceInput({
  id, label, readOnly, helper, error, value, onChange, type, icon,
}: PriceInputProps) {
  return (
    <div className="price-input">
      <span className="flag">{icon || 'R$'}</span>
      <label htmlFor={id}>
        <p>{label}</p>
        <input
          id={id}
          readOnly={readOnly}
          className={`${readOnly ? 'readonly' : ''}`}
          value={value}
          onChange={(data) => onChange(data.target.value)}
          type={type}
          step=".01"
        />
        {helper.length > 0
      && error.length === 0
      && !readOnly && (
        <span className="input-helper">{helper}</span>
        ) }
        {error.length > 0
        && !readOnly
      && (
      <span className="input-error">{error}</span>
      )}
      </label>
    </div>

  );
}
