/* eslint-disable no-restricted-syntax */
export default function removeObjectField(object: any, field: string) {
  const newObj: any = { ...object };
  delete newObj[field];
  return newObj;
}

export function removeSomeObjectFields(object: any, field: any[]) {
  const newObj: any = { ...object };

  field.forEach((item) => {
    delete newObj[item];
  });
  return newObj;
}
