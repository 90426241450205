import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicInput from '../../components/BasicInput';
import BasicOption from '../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import TextAreaInput from '../../components/TextAreaInput';
import api from '../../config/axios';
import DefaultLayout from '../../Layout';

import { maskCPF } from '../../helpers/inputMasks.js';
import { validateCpf } from '../../helpers/validate';

type Indications = {
    name: string,
    paymentDetails: string,
    cpf: string,
    contactInfo: string,
    isActive: boolean
    categoryId: null | number
}

type CategoryProps = {
  id: number;
  name: string;
}

export default function NewIndicator() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` } });
  const [indicator, setIndicator] = useState<Indications>({
    name: '',
    paymentDetails: '',
    isActive: true,
    cpf: '',
    contactInfo: '',
    categoryId: null,
  });
  const [selectedCategory, setSelectedCategory] = useState<CategoryProps | null>(null);
  const [categories, setCategories] = useState<CategoryProps[]>([]);

  function validateIndicatorFields(): boolean {
    if (indicator.name.length >= 3) {
      return true;
    }
    return false;
  }

  async function fetchAllCategories() {
    try {
      setLoading(true);
      const response = await api.get('/admin/indication-category', token);
      setCategories(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Não foi possível.', children: 'Não foi possível cadastrar.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  function filterCategoryByName(name: string) {
    const filtered = categories.filter((item) => item.name === name);
    if (filtered.length > 0) {
      setSelectedCategory(filtered[0]);
    } else {
      setSelectedCategory(null);
    }
  }

  async function saveIndicator() {
    try {
      setLoading(true);
      const data = { ...indicator, categoryId: selectedCategory ? selectedCategory.id : null };
      await api.post('/admin/indication', { ...indicator, categoryId: selectedCategory ? selectedCategory.id : null }, token);
      setLoading(false);
      navigate(-1);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Não foi possível.', children: 'Não foi possível cadastrar.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  useEffect(() => {
    fetchAllCategories();
  }, []);

  return (
    <DefaultLayout
      pageTitle="Embaixador"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={null}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Embaixador</h1>
          <div className="row">
            <div className="col-4">
              <BasicInput
                id="indicator-nome"
                label="Nome"
                readOnly={false}
                helper="Ex. José Antunes"
                error={indicator.name.length < 3 ? 'Digite um nome válido' : ''}
                value={indicator.name}
                type="text"
                onChange={(data) => setIndicator({ ...indicator, name: data })}
              />
            </div>
            <div className="col-3">
              <BasicInput
                id="indicator-cpf"
                label="CPF"
                readOnly={false}
                helper="Ex. 092.283.000-20"
                error={!validateCpf(indicator.cpf) ? 'Digite um CPF válido' : ''}
                value={indicator.cpf}
                type="text"
                onChange={(data) => setIndicator({ ...indicator, cpf: maskCPF(data) })}
              />
            </div>
            <div className="col-2">
              <BasicOption
                id="indicator-category"
                label="Categoria"
                readOnly={false}
                helper="Ex. José Antunes"
                error=""
                value={selectedCategory === null ? 'Nenhum' : selectedCategory.name}
                onChange={(data) => (data === 'Nenhum' ? setSelectedCategory(null) : filterCategoryByName(data))}
              >
                {['Nenhum'].concat(categories.map((item) => item.name))}
              </BasicOption>
            </div>
            <div className="col-3">
              <BasicInput
                id="indicator-contact-info"
                label="Informações de contato"
                readOnly={false}
                helper="Ex. Telefone: (31) 3842-9875"
                error={indicator.contactInfo.length < 3 ? 'Digite uma informação válida' : ''}
                value={indicator.contactInfo}
                type="text"
                onChange={(data) => setIndicator({ ...indicator, contactInfo: data })}
              />
            </div>
            <div className="col-12">
              <TextAreaInput
                id="indicator-obs"
                label="Observações"
                readOnly={false}
                helper="Ex. lembretes sobre dados de pagamento."
                error=""
                value={indicator.paymentDetails}
                onChange={(data) => setIndicator({ ...indicator, paymentDetails: data })}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                active={validateIndicatorFields()}
                onClick={() => saveIndicator()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
