/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import api from '../../../config/axios';
import { ProductsProps } from '../../../dto/product';
import { unmaskData } from '../../../helpers/inputMasks.js';
import { normalizeToApiCardPaymentWays } from '../../../helpers/showCardMethods';
import DefaultLayout from '../../../Layout';
import { PagVipRefProps } from '../../expensesAndProfits/InstallmentModal';
import CreateContract from './CreateContract';
import CreateHolder from './CreateHolder';
import NewPaymentPFLead from './CreatePayment';

// eslint-disable-next-line no-shadow
export enum ActualPage {
    HOLDER,
    DEPENDENTS,
    CONTRACT,
    PAYMENT,
}

export type InstallmentDTO = {
  paymentGatewayId: number,
  dueDate: string,
  status: string,
  paymentDate: string,
  value: number,
  taxUsed: number,
  pagvipRef: null | PagVipRefProps,
  isSubscription: boolean,
  subscriptionNumber: null | number,
  subscriptionRef: null | string,
  nonCardTaxes: string | null,
  card: boolean,
  isMain: boolean,
  flag: null | string,
  cardLastDigits: null | string,
  cardPaymentOption: null | string
}

export type PaymentDTO = {
  contractPfId: number;
  contractValue: number;
  discount: number;
  aditional: number;
  installments: number;
  totalValue: number;
  approvalDate: string;
}

export type HolderDTO = {
  name: string,
  sex: 'm' | 'f',
  cpf: string,
  addressIbge: null | string,
  birthday: string,
  rg: string,
  emitent: string,
  addressStreet: string,
  addressNumber: string,
  addressComplement: string,
  addressNeighborhood: string,
  addressCity: string,
  addressState: string,
  addressZipcode: string,
  phone: string,
  phone2: string,
  email: string,
  observation?: string | null,
}

export type DependentDTO = {
  uuidCtx: string,
  name: string,
  sex: 'm' | 'f',
  cpf: string,
  birthday: string,
  rg: string,
  role: 'son' | 'spouse' | 'other',
  emitent: string,
  addressStreet: string,
  addressNumber: string,
  addressIbge: string | null,
  addressComplement: string,
  addressNeighborhood: string,
  addressCity: string,
  addressState: string,
  addressZipcode: string,
  phone: string,
  phone2: string,
  email: string,
  observation?: string | null,
}

export type ContractDTO = {
  productId: number,
  value: number,
  totalDependentsEnabled: number,
  signatureDate: string,
  dueDate: string
}

export default function CreateLead() {
  const navigate = useNavigate();
  const [actualPage, setActualPage] = useState(ActualPage.HOLDER);
  const [loading, setLoading] = useState(false);
  const [haveInstallments, setHaveInstallments] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  /* Holder */
  const [holder, setHolder] = useState<HolderDTO>({
    cpf: '',
    email: '',
    name: '',
    rg: '',
    emitent: 'SSP/MA',
    birthday: '',
    addressStreet: '',
    addressIbge: '',
    addressNumber: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    sex: 'm',
    addressState: 'MA',
    addressZipcode: '',
    phone: '',
    phone2: '',
  });
  const [dependents, setDependents] = useState<DependentDTO[]>([]);
  /* Contract */
  const [contract, setContract] = useState<ContractDTO>({
    productId: 0,
    value: 0,
    totalDependentsEnabled: 0,
    signatureDate: '',
    dueDate: '',
  });
  /* Payments */
  const [payment, setPayment] = useState<PaymentDTO>({
    contractPfId: 0,
    contractValue: 0,
    discount: 0,
    aditional: 0,
    installments: 0,
    totalValue: 0,
    approvalDate: '',
  });
  const [installments, setInstallments] = useState<InstallmentDTO[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [modalErrorRef] = useState(React.createRef<any>());

  const [selectedProduct, setSelectedProduct] = useState<ProductsProps>({
    id: 0,
    name: '',
    benefits: '',
    pricePerHolder: 0,
    pricePerDependent: 0,
    isActive: true,
  });
  const [products, setProducts] = useState<ProductsProps[]>([]);

  async function sendLead() {
    try {
      setLoading(true);
      await api.post('admin/lead/create', {
        holder: {
          ...holder,
          cpf: holder.cpf.length > 0 ? unmaskData(holder.cpf) : holder.cpf,
          phone: holder.phone.length > 0 ? unmaskData(holder.phone) : holder.phone,
          phone2: holder.phone2.length > 0 ? unmaskData(holder.phone2) : holder.phone2,
          addressZipcode: holder.addressZipcode.length > 0 ? unmaskData(holder.addressZipcode) : holder.addressZipcode,
        },
        dependents: [...dependents.map((item) => ({
          ...item,
          cpf: item.cpf.length > 0 ? unmaskData(item.cpf) : item.cpf,
          phone: item.phone.length > 0 ? unmaskData(item.phone) : item.phone,
          phone2: item.phone2.length > 0 ? unmaskData(item.phone2) : item.phone,
          addressZipcode: item.addressZipcode.length > 0 ? unmaskData(item.addressZipcode) : item.addressZipcode,
        }))],
        lead: { ...contract, pricePerHolder: selectedProduct.pricePerHolder, pricePerDependent: selectedProduct.pricePerDependent },
        installments: [...installments.map((item) => ({ ...item, cardPaymentOption: normalizeToApiCardPaymentWays(item.cardPaymentOption || '') }))],
      }, token);
      setLoading(false);
      setErrors([]);
      navigate('/cliente/pf/lead');
    } catch (err: any) {
      const errorCtx: string[] = [];
      console.log(err.response.data);
      if (err.response && err.response.status === 422) {
        err.response.data.errors.forEach((e: any) => {
          errorCtx.push(e.message);
        });
      } else {
        errorCtx.push('Ocorreu um erro interno e não foi possível processar o cadastro do lead.');
      }
      setModal({
        title: 'Ocorreu um erro',
        children: [
          <div className="d-flex flex-column">
            {errorCtx.map((item) => (
              <p className="w-100 text-center">
                -
                {' '}
                {item}
              </p>
            ))}
          </div>,
        ],
        actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }],
      });
      setLoading(false);
    }
  }

  async function fetchAllProducts() {
    try {
      setLoading(true);
      const response = await api.get('admin/product', token);
      setProducts(response.data);
      if (contract.productId === 0) {
        setSelectedProduct(response.data[0]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function changeGlobalValue() {
    const globalContract = (contract.totalDependentsEnabled * selectedProduct.pricePerDependent) + selectedProduct.pricePerHolder;
    setContract({ ...contract, value: Number((globalContract * 12).toFixed(2)), productId: selectedProduct.id });
  }

  function changeNumberOfDependent(dependentParams: number) {
    const globalContract = (dependentParams * selectedProduct.pricePerDependent) + selectedProduct.pricePerHolder;
    setContract({ ...contract, totalDependentsEnabled: dependentParams, value: Number((globalContract * 12).toFixed(2)) });
  }

  function changePricePerHolder(price: number) {
    const globalContract = price + (contract.totalDependentsEnabled * selectedProduct.pricePerDependent);
    setContract({ ...contract, value: Number((globalContract * 12).toFixed(2)) });
    setSelectedProduct({ ...selectedProduct, pricePerHolder: price });
  }

  function changePricePerDependent(price: number) {
    const globalContract = (contract.totalDependentsEnabled * price) + selectedProduct.pricePerHolder;
    setContract({ ...contract, value: Number((globalContract * 12).toFixed(2)) });
    setSelectedProduct({ ...selectedProduct, pricePerDependent: price });
  }

  useEffect(() => {
    changeGlobalValue();
    console.log(selectedProduct);
  }, [selectedProduct]);

  useEffect(() => {
    fetchAllProducts();
  }, []);

  useEffect(() => {
    if (errors.length > 0) {
      modalErrorRef.current.click();
    }
  }, [errors]);
  return (
    <DefaultLayout
      pageTitle="Adesão"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      {actualPage === ActualPage.HOLDER && (
        <CreateHolder
          holder={holder}
          setHolder={setHolder}
          setLoading={(arg) => setLoading(arg)}
          dependents={dependents}
          setDependents={setDependents}
          setActualPage={(arg) => setActualPage(arg)}
          setContractLifes={(holders, dep) => setContract({ ...contract, totalDependentsEnabled: dep })}
        />
      )}
      {actualPage === ActualPage.PAYMENT && (
        <NewPaymentPFLead
          setModal={(arg) => setModal(arg)}
          setActualPage={(arg) => setActualPage(arg)}
          payment={payment}
          sendLead={() => sendLead()}
          haveInstallments={haveInstallments}
          setHaveInstallments={(arg) => setHaveInstallments(arg)}
          setPayment={(data) => setPayment(data)}
          pInstallments={installments}
          setInstallments={(data) => setInstallments(data)}
          contractProps={contract}
        />
      )}

      {actualPage === ActualPage.CONTRACT && (
        <CreateContract
          products={products}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          contract={contract}
          setModal={(arg) => setModal(arg)}
          setContract={setContract}
          lifesAmount={dependents.length + 1}
          setLoading={(arg) => setLoading(arg)}
          setActualPage={(arg) => setActualPage(arg)}
          changeNumberOfDependent={(arg) => changeNumberOfDependent(arg)}
          changePricePerDependent={(arg) => changePricePerDependent(arg)}
          changePricePerHolder={(arg) => changePricePerHolder(arg)}
        />
      )}

    </DefaultLayout>
  );
}
