export default function showCompactName(name: string) {
  const splittedName = name.split(' ');
  if (splittedName.length <= 2) {
    return name;
  }
  let response = '';
  splittedName.forEach((item, i) => {
    if (i === 0) {
      response = response.concat(item);
    } else if (i === splittedName.length - 1) {
      response = response.concat(item);
    } else {
      response = response.concat(` ${item[0]}. `);
    }
  });
  return response;
}

export function showFirstName(fullName: string) {
  const data = fullName.split(' ');

  if (data.length > 0) {
    return data[0];
  }
  return fullName;
}

export function showDoctorCompactName(name: string) {
  let splittedName = name.split(' ');
  if (splittedName[0].toLowerCase() === 'dr.' || splittedName[0].toLowerCase() === 'dra.' || splittedName[0].toLowerCase() === 'dr' || splittedName[0].toLowerCase() === 'dra' || splittedName[0].toLowerCase() === 'doutor' || splittedName[0].toLowerCase() === 'doutora') {
    splittedName = splittedName.slice(1, splittedName.length);
  }
  if (splittedName.length === 0) {
    return `${splittedName[0]}.`;
  }
  return `${splittedName[0]} ${splittedName[splittedName.length - 1][0].toUpperCase()}.`;
}
