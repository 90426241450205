/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import DefaultLayout from '../../../Layout';
import Stratification from './Content';

export default function StratificationByDate() {
  const navigate = useNavigate();
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [loading, setLoading] = useState(false);

  return (
    <DefaultLayout
      pageTitle="Relatórios por data"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >

      <div className="row">
        <div className="col-12 d-flex align-items-center mb-2 mt-2">
          <h1 className="fw-bold">RELATORIO ESTRATIFICADO</h1>
          <div className="horizontal-divider" />
        </div>
      </div>

      <Stratification
        setModal={(data) => setModal({ title: 'Ocorreu um erro', children: data, actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] })}
        setLoading={(el) => setLoading(el)}
      />

    </DefaultLayout>
  );
}
