/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { IModalContent } from '../StratificationModal';

interface ISexProps {
    props: IModalContent | null
}
export default function Sex({
  props,
}: ISexProps) {
  const [changeSliderMovement, setChangeSliderMovement] = useState(false);
  function calculatePercentage(total: number, target: number) {
    const result = (target * 100) / total;
    return result;
  }

  useEffect(() => {
    setTimeout(() => {
      setChangeSliderMovement(true);
    }, 200);
  }, []);
  return (
    <>
      <h3 className="fw-bold text-center mt-4 text-uppercase" style={{ color: '#616161' }}>
        SEXO - Quantitativo geral
      </h3>
      <div className="item-container">
        <p className="fw-bold">
          Do total de
          {' '}
          {props!.holdersAmount + props!.dependentsAmount}
          {' '}
          usuários cadastrados no sistema dentre as datas.
        </p>
        <div className="item-wrapper">
          <p className="item-description">
            masculino:
            {' '}
            <span>
              {props!.totalMale}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalMale).toFixed(2)}
              %
            </span>
          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalMale)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            feminino:
            {' '}
            <span>
              {props ? props.totalFemale : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalFemale).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount + props!.dependentsAmount, props!.totalFemale)}%` : 0 }} />
          </div>
        </div>
      </div>

      <h3 className="fw-bold text-center mt-4 text-uppercase" style={{ color: '#616161' }}>
        SEXO - APENAS TITULARES
      </h3>
      <div className="item-container">
        <p className="fw-bold">
          Do total de
          {' '}
          {props!.holdersAmount}
          {' '}
          titulares cadastrados no sistema dentre as datas.
        </p>
        <div className="item-wrapper">
          <p className="item-description">
            masculino:
            {' '}
            <span>
              {props!.holdersMaleAmount}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount, props!.holdersMaleAmount).toFixed(2)}
              %
            </span>
          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount, props!.holdersMaleAmount)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            feminino:
            {' '}
            <span>
              {props ? props.holdersFemaleAmount : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.holdersAmount, props!.holdersFemaleAmount).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.holdersAmount, props!.holdersFemaleAmount)}%` : 0 }} />
          </div>
        </div>
      </div>

      <h3 className="fw-bold text-center mt-4 text-uppercase" style={{ color: '#616161' }}>
        SEXO - APENAS DEPENDENTES
      </h3>
      <div className="item-container">
        <p className="fw-bold">
          Do total de
          {' '}
          {props!.dependentsAmount}
          {' '}
          dependentes cadastrados no sistema dentre as datas.
        </p>
        <div className="item-wrapper">
          <p className="item-description">
            masculino:
            {' '}
            <span>
              {props!.dependentsMaleAmount}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentsMaleAmount).toFixed(2)}
              %
            </span>
          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentsMaleAmount)}%` : 0 }} />
          </div>
        </div>
        <div className="item-wrapper">
          <p className="item-description">
            feminino:
            {' '}
            <span>
              {props ? props.dependentsFemaleAmount : 0}
              {' '}
              -
              {' '}
              {calculatePercentage(props!.dependentsAmount, props!.dependentsFemaleAmount).toFixed(2)}
              %
            </span>

          </p>
          <div className="slider-wrapper">
            <div className="slider" style={{ width: changeSliderMovement ? `${calculatePercentage(props!.dependentsAmount, props!.dependentsFemaleAmount)}%` : 0 }} />
          </div>
        </div>
      </div>

    </>
  );
}
