import React, { createRef, useEffect, useState } from 'react';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';

interface ContractFilesProps {
    id: number,
    file: string | null | ArrayBuffer,
    setFile: (arg0: string | ArrayBuffer | null) => void;
    setFileSizeError: (arg0: ModalProps | null) => void;
    setModal: React.Dispatch<React.SetStateAction<ModalProps | null>>;
}

export default function ContractFiles({
  id, file, setFile, setFileSizeError, setModal,
}: ContractFilesProps) {
  const [inputRef] = useState(createRef<any>());

  function createObjectURL(data: React.ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();
    reader.readAsDataURL(data.target.files![0]);
    reader.onload = (ctx) => {
      if (ctx.loaded / 1024 < 2000) {
        setFile(ctx.target!.result);
      } else {
        setFileSizeError({ title: 'Ocorreu um erro!', children: 'O arquivo selecionado ultrapassou o limite máximo de 4mb', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }
    };
  }

  if (file === null) {
    return (
      <div className="contract-file-wrapper">
        <input
          type="file"
          className="d-none"
          onChange={(data) => createObjectURL(data)}
          accept=".png, .jpeg, .jpg, .gif, .bmp, .svg, .pdf, .doc, .docx, .xls, .xlss, .zip, .rar"
          ref={inputRef}
        />
        <ButtonTextIcon
          label="Adicionar Anexo"
          type={BtnType.PRIMARY}
          icon="upload"
          active
          onClick={() => inputRef.current.click()}
          ref={null}
        />
      </div>
    );
  }
  return (
    <div className="d-flex flex-column">
      <p className="mb-4 text-center">1 arquivo em anexo</p>
      <ButtonTextIcon
        label="Remover anexo"
        type={BtnType.DANGER}
        icon="delete"
        active
        onClick={() => setFile(null)}
        ref={null}
      />
    </div>
  );
}
