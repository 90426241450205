import { combineReducers } from 'redux';
import loginReducer from './reducers/login';
import adminReducer, { AdminProps } from './reducers/admin';

export type storeType = {
  loginReducer: { isLoggedIn: boolean };
  adminReducer: AdminProps
}

export default combineReducers({
  loginReducer,
  adminReducer,
});
