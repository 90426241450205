/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-tabs */
/* eslint-disable no-restricted-syntax */
import { isValid } from '@fnando/cnpj';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import download from 'downloadjs';
import BasicInput from '../../components/BasicInput';
import BasicOption from '../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import api from '../../config/axios';
import { statesOfBrazil } from '../../dto/states';
import {
  maskCep, maskCNPJ, maskCPF, maskDate, maskPhone, unmaskData,
} from '../../helpers/inputMasks.js';
import showApiErrors from '../../helpers/showApiErrors';
import DefaultLayout from '../../Layout';
import SwitcherPrimary from '../../components/SwitcherPrimary';
import checkNull from '../../helpers/checkNull';
import ContractPJProps from '../../dto/contractPJ';
import HolderProps, { BlockHistoryHolderOrDependentProps } from '../../dto/holder';
import { fetchClientByIdAndCheckIfLimitLifeIsNotFulfilled } from '../../helpers/checkContractLimit';
import CEPFinder from '../../helpers/findAddressByCep';
import AddInput from '../../components/AddInput';
import Tooltip from '../../components/Tooltip';
import BlockPermaHolderPJModal from './BlockPermaHolderModal';
import { BlockHistoryProps } from '../Contracts/PF/types';
import TermOptionModal from '../../components/TermOptionsModal';
import UnblockClientModal from '../../components/UnblockPerma';
import MigrationModal from '../../components/MigrationModal';

export interface EditClientResponseProps {
   id: number,
   addressIbge: null | string
   email: string,
   cnpj: string,
   openingDate: string,
   name: string,
   nameFantasy: string,
   size: string,
   addressStreet: string,
   addressNumber: string,
   addressComplement: string,
   addressNeighborhood: string,
   addressCity: string,
   addressState: string,
   addressZipcode: string,
   phone: string,
   password: string,
   isActive: boolean,
   contracts: ContractPJProps[],
   holders: HolderProps[],
}

type HolderTableFilters = {
  total: number,
  perPage: number,
  currentPage: number,
  firstPage: number,
  lastPage: number,
}

export default function EditClientPJ() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [expandClientData, setExpandClientData] = useState(false);
  const [expandHoldersData, setExpandHoldersData] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState('');
  const [allowModifyPassword, setAllowModifyPassword] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<null | ModalProps>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [client, setClient] = useState<EditClientResponseProps>({
    id: 0,
    cnpj: '',
    openingDate: '',
    name: '',
    nameFantasy: '',
    size: '',
    addressStreet: '',
    addressNumber: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    addressState: 'MA',
    addressZipcode: '',
    email: '',
    phone: '',
    addressIbge: '',
    password: '',
    isActive: true,
    holders: [],
    contracts: [],
  });

  const [holders, setHolders] = useState<HolderProps[]>([]);
  const [meta, setMeta] = useState<HolderTableFilters>({
    total: 0,
    currentPage: 1,
    perPage: 20,
    firstPage: 1,
    lastPage: 1,
  });
  const [tableHoldersFilters, setTableHolderFilters] = useState({
    currentPage: 1,
  });
  const [nameFilter, setNameFilter] = useState('');

  function enableSaveButton() {
    if (allowModifyPassword) {
      if (
        client.cnpj.length > 0
              && client.openingDate.length > 9
              && client.name.length > 0
              && client.nameFantasy.length > 0
              && client.addressStreet.length > 0
              && client.addressNumber.length > 0
              && client.addressNeighborhood.length > 0
              && client.addressCity.length > 0
              && client.addressZipcode.length > 0
              && client.phone.length > 0
              && EmailValidator.validate(client.email)
              && client.password.length > 5
              && client.password === repeatPassword
      ) {
        return true;
      }
      return false;
    }
    if (
      client.cnpj.length > 0
                   && client.openingDate.length > 9
                   && client.name.length > 0
                   && client.nameFantasy.length > 0
                   && client.addressStreet.length > 0
                   && client.addressNumber.length > 0
                   && client.addressNeighborhood.length > 0
                   && client.addressCity.length > 0
                   && client.addressZipcode.length > 0
                   && client.phone.length > 0
                   && EmailValidator.validate(client.email)
    ) {
      return true;
    }
    return false;
  }

  async function fetchHoldersFromClient() {
    try {
      setLoading(true);
      const holderResult = await api.post('admin/client/get-holders/', {
        clientId: id,
        actualPage: tableHoldersFilters.currentPage,
        holderName: nameFilter || undefined,
      }, token);
      console.log(holderResult.data.data);
      setHolders(holderResult.data.data);
      setMeta(holderResult.data.meta);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível buscar os titulares dessa empresa.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function changeAddressByCep() {
    try {
      setLoading(true);
      const findCep = await new CEPFinder(unmaskData(client.addressZipcode)).findCep();
      if (findCep !== null) {
        setClient({
          ...client,
          addressCity: findCep.city,
          addressState: findCep.state,
          addressNeighborhood: findCep.neighborhood,
          addressStreet: findCep.street,
          addressIbge: findCep.ibge,
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  async function saveClient() {
    try {
      setLoading(true);
      const noMaskData = {
        ...client, cnpj: unmaskData(client.cnpj), addressZipcode: unmaskData(client.addressZipcode), phone: unmaskData(client.phone),
      };
      await api.put(`/admin/client/${id}`, noMaskData, token);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
      setLoading(false);
    } catch (err: any) {
      if (typeof err.response !== 'undefined') {
        if (err.response.status === 422) {
          setModal(showApiErrors(setModal, err.response.data.errors));
        } else {
          setShowToastError(true);
          setTimeout(() => {
            setShowToastError(false);
          }, 3000);
          setModal({ title: 'Ocorreu um erro', children: 'Não foi possível processar sua solicitação', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
        }
      }
      setLoading(false);
      console.log(err.response);
    }
  }

  async function fetchClientById() {
    try {
      setLoading(true);
      const response = await api.get(`/admin/client/${id}`, token);
      console.log('cliente', response.data);
      const clientDataWithNonNullableValues = checkNull(client, response.data);
      setClient({
        ...clientDataWithNonNullableValues,
        cnpj: maskCNPJ(clientDataWithNonNullableValues.cnpj),
        phone: maskPhone(clientDataWithNonNullableValues.phone),
        addressZipcode: maskCep(clientDataWithNonNullableValues.addressZipcode),
      });
      setLoading(false);
    } catch (err: any) {
      setModal({ title: 'Ocorreu um erro ao buscar', children: 'Cliente não encontrado. Verifique a URL ou sua conectividade.', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
    }
  }

  function resolveBlockStyle(block: BlockHistoryProps) {
    if (block.type === 'perma' && block.input === 'in') {
      return 'td-block-perma';
    }
    if (block.type === 'perma' && block.input === 'out') {
      return 'td-unblock';
    }
    if (block.input === 'out') {
      return 'td-unblock';
    }
    return 'td-block-temp';
  }

  function calcFulFilledLifes() {
    let total = 0;
    holders.forEach((holder) => {
      if (!holder.blockPerma) {
        total += 1;
      }
      holder.dependent.forEach((dependent) => {
        if (!dependent.blockPerma) {
          total += 1;
        }
      });
    });

    return total;
  }

  async function generateContractPDF(holderName: string, holderId: number, model: 'Empresa paga para o titular e desconta em folha o valor do dependente'
  | 'Empresa desconta em folha os valores do titular e do dependente'
  | 'Empresa paga para o titular e dependente'
  | 'Empresa paga 50% para o titular') {
    try {
      setLoading(true);
      const response = await api.post('admin/contractpjpdfsingle', {
        clientid: id,
        holderid: holderId,
        model,
      }, {
        headers:
         {
           Authorization: `bearer ${localStorage.getItem('adminToken')}`,
         },
        withCredentials: true,
        responseType: 'arraybuffer',
      });
      download(response.data, `ADESAO - ${holderName}.pdf`);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
    }
  }

  async function exportHoldersToExcel() {
    try {
      setLoading(true);
      const response = await api.get(`/admin/reports/pj/getonlypjholdersexcelfromclient/${id}`, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
      download(response.data, `relatório_clientes_${client.name}.xlsx`);
      setLoading(false);
    } catch (err: any) {
      console.log('error', err.response.data);
      setLoading(false);
    }
  }

  function generatePagination(totalOfPages: number) {
    const pagination: number[] = [];
    for (let i = 0; i < totalOfPages; i += 1) {
      pagination.push(i + 1);
    }
    return pagination;
  }

  function goToNextPage() {
    if (tableHoldersFilters.currentPage < meta.lastPage) {
      setTableHolderFilters((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  }

  useEffect(() => {
    fetchClientById();
  }, []);

  useEffect(() => {
    fetchHoldersFromClient();
  }, [tableHoldersFilters]);

  return (
    <DefaultLayout
      pageTitle={`Cliente PJ > ${client.name}`}
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row mb-2">

        {client.contracts.length === 1 && (
        <div className="col-12 info-section-container">
          <div className="info-wrapper">
            <p>Situação:</p>
            <p>Ativa</p>
          </div>
          <div className="info-wrapper">
            <p>Contrato em atividade:</p>
            <p>{`PJ000${client.contracts[0].id}`}</p>
          </div>
          {/* <div className="info-wrapper">
            <p>Vidas:</p>
            <p>{`${calcFulFilledLifes()}/${client.contracts[0].totalLifes}`}</p>
          </div> */}
        </div>
        )}

      </div>
      <div className="row client card-bg p-0">
        <input type="checkbox" id="expand-client-data" checked={expandClientData} />
        <section className="row">
          <h1>Dados do cliente</h1>
          <button
            type="button"
            onClick={() => setExpandClientData((prev) => !prev)}
            className="expand-btn"
          >
            <span className="material-icons-round">
              expand_more
            </span>
          </button>
          <div className="col-4">
            <BasicInput
              id="cnpj-input"
              label="CNPJ"
              readOnly={false}
              helper="Ex: 33.000.167/0001-01"
              error={!isValid(client.cnpj) ? 'Digite um CNPJ válido' : ''}
              value={client.cnpj}
              onChange={(data) => setClient({ ...client, cnpj: maskCNPJ(data) })}
              type="text"
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="name-fantasy-input"
              label="Nome Fantasia"
              readOnly={false}
              helper="Ex: Petrobrás"
              error={client.nameFantasy.length < 1 ? 'Campo obrigatório' : ''}
              value={client.nameFantasy}
              type=""
              onChange={(data) => setClient({ ...client, nameFantasy: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="name-input"
              label="Razão social"
              readOnly={false}
              helper="Petroleo brasileiro SA"
              error={client.name.length < 1 ? 'Campo obrigatório' : ''}
              value={client.name}
              type=""
              onChange={(data) => setClient({ ...client, name: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="opening-input"
              label="Data de abertura"
              readOnly={false}
              helper="Ex: 31/01/1990"
              error={client.openingDate.length < 10 ? 'Digite uma data válida' : ''}
              value={client.openingDate}
              type=""
              onChange={(data) => setClient({ ...client, openingDate: maskDate(data) })}
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="tipo-input"
              label="Tipo"
              readOnly={false}
              helper="Ex: ME"
              error=""
              value={client.size}
              onChange={(data) => setClient({ ...client, size: data })}
            >
              {[
                'ME',
                'MEI',
                'EPP',
                'OUTRO',
              ]}
            </BasicOption>
          </div>
          <div className="col-4">
            <AddInput
              id="address-cep-input"
              label="CEP"
              readOnly={false}
              helper="Ex: 35.171-120"
              error={client.addressZipcode.length < 10 ? 'Campo obrigatório' : ''}
              value={client.addressZipcode}
              type="text"
              onAdd={() => changeAddressByCep()}
              onChange={(data) => setClient({ ...client, addressZipcode: maskCep(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-street-input"
              label="LOGRADOURO"
              readOnly
              helper="Ex: Rua Três"
              error={client.addressStreet.length < 2 ? 'Campo Obrigatório' : ''}
              value={client.addressStreet}
              type="text"
              onChange={(data) => setClient({ ...client, addressStreet: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-number-input"
              label="Número"
              readOnly={false}
              helper="Ex: 133"
              error={client.addressNumber.length < 1 ? 'Campo Obrigatório' : ''}
              value={client.addressNumber}
              type="text"
              onChange={(data) => setClient({ ...client, addressNumber: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-complement-input"
              label="Complemento"
              readOnly={false}
              helper="Ex: Bloco A"
              error=""
              value={client.addressComplement}
              type="text"
              onChange={(data) => setClient({ ...client, addressComplement: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-neighborhood-input"
              label="Bairro"
              readOnly
              helper="Centro"
              error={client.addressNeighborhood.length < 1 ? 'Campo obrigatório' : ''}
              value={client.addressNeighborhood}
              type="text"
              onChange={(data) => setClient({ ...client, addressNeighborhood: data })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="address-city-input"
              label="Cidade"
              readOnly
              helper="Ex: Imperatriz"
              error={client.addressCity.length < 1 ? 'Campo obrigatório' : ''}
              value={client.addressCity}
              type="text"
              onChange={(data) => setClient({ ...client, addressCity: data })}
            />
          </div>
          <div className="col-4">
            <BasicOption
              id="address-state-input"
              label="Estado"
              readOnly={false}
              helper=""
              error=""
              value={client.addressState}
              onChange={(data) => setClient({ ...client, addressState: data })}
            >
              {statesOfBrazil}
            </BasicOption>
          </div>
          <div className="col-4">
            <BasicInput
              id="phone-input"
              label="Telefone"
              readOnly={false}
              helper="Ex: (31) 99999 - 9999"
              error={client.phone.length < 1 ? 'Campo obrigatório' : ''}
              value={client.phone}
              type="text"
              onChange={(data) => setClient({ ...client, phone: maskPhone(data) })}
            />
          </div>
          <div className="col-4">
            <BasicInput
              id="email-input"
              label="E-mail"
              readOnly={false}
              helper="email@email.com"
              error={!EmailValidator.validate(client.email) ? 'Digite um email válido' : ''}
              value={client.email}
              type="email"
              onChange={(data) => setClient({ ...client, email: data })}
            />
          </div>
          <div className="col-12 allow-modifications">
            <input
              type="checkbox"
              onChange={() => {
                setAllowModifyPassword((prev) => !prev);
                setClient({ ...client, password: '' });
                setRepeatPassword('');
              }}
              checked={allowModifyPassword}
            />
            <p>Habilitar modificar senha</p>
          </div>
          <div className="col-12 mt-4">
            <div className="col-4">
              <BasicInput
                id="password-input"
                label="Senha"
                readOnly={!allowModifyPassword}
                helper="Mínimo 06 dígitos"
                error={client.password.length < 6 ? 'Mínimo 06 dígitos' : ''}
                value={client.password}
                type="password"
                onChange={(data) => setClient({ ...client, password: data })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="repeat-password-input"
                label="Repita a senha"
                readOnly={!allowModifyPassword}
                helper="Deve coincidir com a senha"
                error={repeatPassword !== client.password ? 'Deve coincidir com a senha' : ''}
                value={repeatPassword}
                type="password"
                onChange={(data) => setRepeatPassword(data)}
              />
            </div>
          </div>
          <div className="col-12 footer">
            <ButtonTextIcon
              label="salvar"
              type={BtnType.PRIMARY}
              icon="backup"
              active={enableSaveButton()}
              onClick={() => saveClient()}
              ref={null}
            />
          </div>
        </section>
      </div>

      <div className="row client card-bg p-0 mt-4">
        <input type="checkbox" id="expand-holders-data" checked={expandHoldersData} />
        <section className="row">
          <h1>Aderentes titulares</h1>
          <button
            type="button"
            onClick={() => setExpandHoldersData((prev) => !prev)}
            className="expand-holders-btn"
          >
            <span className="material-icons-round">
              expand_more
            </span>
          </button>
          <div className="col-12 mt-4">
            <div className="table-wrapper">
              <div className="table-actions justify-content-between">
                <div className="d-flex">
                  <button className="table-btn-primary" type="button" onClick={() => navigate(`/cliente/empresa/adicionar-aderente-titular/${id}`)}>
                    <span className="material-icons-round">
                      add
                    </span>
                    adicionar
                  </button>
                  <button className="table-btn-primary" type="button" onClick={exportHoldersToExcel}>
                    <span className="material-icons-round">
                      assignment_returned
                    </span>
                    Gerar Excel com todos titulares
                  </button>
                </div>
                <div className="mt-1 d-flex">
                  <BasicInput
                    id="Busca-por-nome"
                    label="Nome"
                    readOnly={false}
                    helper=""
                    error=""
                    value={nameFilter}
                    type="text"
                    onChange={(data) => setNameFilter(data)}
                  />
                  <button className="table-btn-primary m-2 mt-3" type="button" onClick={() => fetchHoldersFromClient()}>
                    <span className="material-icons-round">
                      search
                    </span>
                    filtrar
                  </button>
                </div>

              </div>
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Dependentes</th>
                    <th scope="col">CPF / CNPJ</th>
                    <th className="text-end" scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {holders.length < 1 ? (
                    <p className="text-align-center text-bold">Nenhum titular cadastrado</p>
                  ) : (
                    holders.map((item, i) => (
                      <tr key={i}>
                        <td className={item.blockPerma ? 'link-red' : (item.blockTemp ? 'link-orange' : '')}>{item.name}</td>
                        <td>{item.dependent.length}</td>
                        <td>{maskCPF(item.cpf)}</td>
                        <td align="right">
                          <TermOptionModal
                            fetchFunction={(arg) => generateContractPDF(item.name, item.id, arg)}
                            loading={loading}
                            id={`adesao${i}`}
                          />
                          <div className="d-flex justify-content-end align-items-center">
                            <button style={{ background: 'none', border: 'none' }} type="button" id="convert" aria-describedby="adesao" data-bs-toggle="modal" data-bs-target={`#adesao${i}`}>
                              <span className="material-icons-round" style={{ color: '#6200EE' }}>
                                description
                              </span>
                            </button>
                            <div className="table-action-icon">
                              <button
                                type="button"
                                aria-describedby="manual"
                                id="unblock-perma"
                                data-bs-toggle="modal"
                                data-bs-target={`#migration-modal-${item.id}`}
                              >
                                <span className="material-icons-round" style={{ color: 'orange' }}>
                                  transfer_within_a_station
                                </span>
                              </button>
                              <Tooltip id="bloq-perma" text="Essa ação irá migrar o usuário" />
                              <MigrationModal id={`migration-modal-${item.id}`} holderId={item.id} type="holderpj" name={item.name} />
                            </div>
                            {!item.blockPerma && (
                            <>
                              <div className="table-action-icon">
                                <button
                                  type="button"
                                  onClick={() => null}
                                  aria-describedby="manual"
                                  id="bloq-perma-dependent"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#bloq-perma-holder-${item.id.toString()}`}
                                >
                                  <span className="material-icons-round" style={{ color: 'red' }}>
                                    cancel
                                  </span>
                                </button>
                                <Tooltip id="bloq-perma-dependent" text="Essa ação irá cancelar o titular e seus dependentes." />
                              </div>
                              <BlockPermaHolderPJModal
                                id={item.id.toString()}
                                setLoading={(arg) => setLoading(arg)}
                              />
                            </>
                            )}
                            {item.blockPerma && (
                              <div className="table-action-icon">
                                <button
                                  type="button"
                                  aria-describedby="manual"
                                  id="unblock-perma"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#unblock-perma-${item.id}`}
                                >
                                  <span className="material-icons-round" style={{ color: 'orange' }}>
                                    lock_open
                                  </span>
                                </button>
                                <Tooltip id="bloq-perma" text="Essa ação irá desbloquear o usuário." />
                                <UnblockClientModal type="holderpj" userId={item.id.toString()} id={`unblock-perma-${item.id}`} />
                              </div>
                            )}
                            <div className="table-action-icon">
                              <button type="button" onClick={() => navigate(`/cliente/editar-aderente/${id}/${item.id}`)}>
                                <span className="material-icons-round">
                                  more
                                </span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div className="table-footer">
                <button type="button" onClick={() => setTableHolderFilters((prev) => ({ ...prev, currentPage: 1 }))}>Primeira</button>
                <p>Página</p>
                <select style={{ minWidth: 300 }} value={meta.currentPage} onChange={(e) => setTableHolderFilters({ ...tableHoldersFilters, currentPage: Number(e.target.value) })}>
                  {generatePagination(meta.lastPage).map((item) => <option value={item}>{item}</option>)}
                </select>
                <p>
                  de
                  {' '}
                  <strong>{meta.lastPage}</strong>
                </p>
                <button
                  type="button"
                  onClick={() => goToNextPage()}
                >
                  Próxima

                </button>
                <button
                  type="button"
                  onClick={() => setTableHolderFilters((prev) => ({ ...prev, currentPage: meta.lastPage }))}
                >
                  Última

                </button>
              </div>
            </div>
          </div>
        </section>
      </div>

      {client.contracts.map((contract) => (
        <div className="row products mt-4">
          <div className="col-12 p-0 card-bg p-4">
            <h1>Histórico de bloqueios / desbloqueios</h1>
            <div className="table-wrapper">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Bloqueio / Cancelamento</th>
                    <th scope="col">Data</th>
                    <th scope="col">Motivo</th>
                    <th scope="col">Responsável</th>
                  </tr>
                </thead>
                <tbody>
                  {contract.blockHistory.length > 0 ? contract.blockHistory.map((item, i) => (
                    <tr key={i}>
                      <td className={resolveBlockStyle(item)}>{(item.input === 'in' ? 'Bloqueio' : 'Desbloqueio')}</td>
                      <td className={resolveBlockStyle(item)}>{item.blockedDate}</td>
                      <td className={resolveBlockStyle(item)}>{item.reason}</td>
                      <td className={resolveBlockStyle(item)}>{item.blockedBy}</td>
                    </tr>
                  )) : (
                    <p>Não existe(m) bloqueio(s) para esse cliente.</p>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      ))}

    </DefaultLayout>
  );
}
