/* eslint-disable no-shadow */
/* eslint-disable max-len */
import { DateTime } from 'luxon';
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import AllTimeValueModal from '../../../components/AllTimeValueModal';
import BasicOption from '../../../components/BasicOption';
import DashboardCardOverlay from '../../../components/DashboardCardOverlay';
import api from '../../../config/axios';
import {
  showArrayOfMonthsLimitedByStart, showAvailableYears, showMonthAsNumberBasedOnName, showMonthAsStringBasedOnNumber,
} from '../../../helpers/dates';

type IncomeResponseProps = {
  actualMonthLiquidValue: string,
  previousMonthLiquidValue: string,
  gainOrLosePercentageBasedOnPastMonth: string | null
}

enum TrendingMonth {
  'ALL' = 200,
  '1M' = 0,
  '3M' = 2,
  '6M' = 5,
  '1A' = 11
}

type TrendingProps = {
  data: number[],
  series: string[]
}

export default function SellGraph() {
  const [income, setIncome] = useState<IncomeResponseProps>({
    actualMonthLiquidValue: '0',
    previousMonthLiquidValue: '0',
    gainOrLosePercentageBasedOnPastMonth: '0',
  });

  const [loading, setLoading] = useState(false);
  const [incomeMonth, setIncomeMonth] = useState(DateTime.now().month);
  const [incomeYear, setIncomeYear] = useState(DateTime.now().year);
  const [allValue, setAllValue] = useState({ total: 0 });
  const [dataTrending, setDataTrending] = useState<TrendingProps>({
    data: [0],
    series: ['JAN'],
  });
  const [trendingByMonths, setTrendingByMonths] = useState<TrendingMonth>(TrendingMonth.ALL);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [modalRef] = useState(React.createRef<any>());

  async function fetchIncome() {
    try {
      setLoading(true);
      const response = await api.post('/admin/reports/sellbydate', {
        month: incomeMonth,
        year: incomeYear,
      }, token);
      setIncome(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log('fetch income error', err.response.data);
      setLoading(false);
    }
  }

  async function fetchTrendingByDate() {
    try {
      setLoading(true);
      const response = await api.post('/admin/reports/selltrendingbyperiod', {
        periodInMonths: trendingByMonths.valueOf(),
      }, token);
      if (response.data.data.length === 1) {
        setDataTrending({ ...response.data, data: [100, ...response.data.data], series: ['Junho/2022', ...response.data.series] });
      } else {
        setDataTrending(response.data);
      }
      setLoading(false);
    } catch (err: any) {
      console.log('fetch trending income error', err.response.data);
      setLoading(false);
    }
  }

  async function fetchAllValue() {
    try {
      setLoading(true);
      const response = await api.get('admin/total/selled', token);
      setAllValue(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
    }
  }

  function showPercentage() {
    if (income.gainOrLosePercentageBasedOnPastMonth !== null) {
      if (Number(income.gainOrLosePercentageBasedOnPastMonth) >= 0) {
        return (
          <>
            <span className="material-icons-round text-success">arrow_upward</span>
            <p className="percent text-success">
              {income.gainOrLosePercentageBasedOnPastMonth}
              %
            </p>
          </>
        );
      }
      return (
        <>
          <span className="material-icons-round text-danger">arrow_downward</span>
          <p className="percent text-danger">
            {income.gainOrLosePercentageBasedOnPastMonth}
            %
          </p>
        </>
      );
    }
    return (
      <>
        <span className="material-icons-round text-success">arrow_upward</span>
        <span className="material-icons-round text-success">all_inclusive</span>
      </>
    );
  }

  useEffect(() => {
    fetchIncome();
  }, [incomeMonth, incomeYear]);

  useEffect(() => {
    fetchTrendingByDate();
  }, [trendingByMonths]);

  useEffect(() => {
    fetchAllValue();
  }, []);

  const data = {
    toolbar: {
      visible: false,
    },
    grid: { show: false },
    options: {
      dataLabels: {
        enabled: false,
      },
      chart: {
        xaxis: { show: false },
        stroke: { curve: 'smooth' },
        toolbar: { show: false },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    series: [
      {
        name: 'entradas',
        data: [31, 40, 28, 51, 42, 109, 100, 1, 2, 10, 11, 12],
      },
    ],
  };

  return (
    <DashboardCardOverlay loading={loading}>
      <div className="card-finance-income">
        <div className="title-wrapper">
          <div className="">
            <h5 className="title">Vendas</h5>
            <p className="subtitle">
              {' '}
              {income.actualMonthLiquidValue}
              {showPercentage()}
            </p>
          </div>
          <div className="d-flex align-items-center">
            <div className="" style={{ marginLeft: 8, marginRight: 4, marginBottom: 18 }}>
              <button type="button" className="btn-all" data-bs-toggle="modal" data-bs-target="#all-time" onClick={() => null}>Ver total</button>
            </div>
            <BasicOption
              id=""
              label=""
              readOnly={false}
              helper=""
              error=""
              value={showMonthAsStringBasedOnNumber(incomeMonth)}
              onChange={(el) => setIncomeMonth(showMonthAsNumberBasedOnName(el))}
            >
              {showArrayOfMonthsLimitedByStart(incomeYear)}
            </BasicOption>
            <BasicOption
              id=""
              label=""
              readOnly={false}
              helper=""
              error=""
              value={incomeYear.toString()}
              onChange={(el) => setIncomeYear(Number(el))}
            >
              {showAvailableYears()}
            </BasicOption>
          </div>
        </div>
        <div className="trend-wrapper">
          <div className="chart-time-wrapper" style={{ opacity: dataTrending.data.length === 0 ? 0 : 1 }}>
            <div className={trendingByMonths === TrendingMonth.ALL ? 'chart-time-active' : 'chart-time-inactive'}>
              <button type="button" onClick={() => setTrendingByMonths(TrendingMonth.ALL)}>Tudo</button>
            </div>
            <div className={trendingByMonths === TrendingMonth['1M'] ? 'chart-time-active' : 'chart-time-inactive'}>
              <button type="button" onClick={() => setTrendingByMonths(TrendingMonth['1M'])}>1M</button>
            </div>
            <div className={trendingByMonths === TrendingMonth['3M'] ? 'chart-time-active' : 'chart-time-inactive'}>
              <button type="button" onClick={() => setTrendingByMonths(TrendingMonth['3M'])}>3M</button>
            </div>
            <div className={trendingByMonths === TrendingMonth['6M'] ? 'chart-time-active' : 'chart-time-inactive'}>
              <button type="button" onClick={() => setTrendingByMonths(TrendingMonth['6M'])}>6M</button>
            </div>
            <div className={trendingByMonths === TrendingMonth['1A'] ? 'chart-time-active' : 'chart-time-inactive'}>
              <button type="button" onClick={() => setTrendingByMonths(TrendingMonth['1A'])}>1A</button>
            </div>
          </div>
          <div className="custom-chart-inactive">
            <Chart
              options={{
                ...data.options,
                colors: ['#6200EE'],
                grid: {
                  show: false,
                  padding: {
                    top: 32, left: 0, right: 0, bottom: 0,
                  },
                },
                chart: { sparkline: { enabled: true } },
                xaxis: { categories: dataTrending.series, type: 'category' },
              }}
              series={[{ name: 'CONTRATOS', data: dataTrending.data }]}
              type="area"
              width="100%"
              height="100%"
              toolbar={{ show: false }}
            />
          </div>
        </div>

      </div>
      <AllTimeValueModal id="all-time" title="Total de vendas" text="TOTAL CONSIDERANDO TODO PERÍODO" value={allValue.total.toString()} />
    </DashboardCardOverlay>
  );
}
