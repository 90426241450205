/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import SwitcherPrimary from '../../components/SwitcherPrimary';
import api from '../../config/axios';
import { maskCPF } from '../../helpers/inputMasks.js';
import showApiErrors from '../../helpers/showApiErrors';
import DefaultLayout from '../../Layout';

type FreelancerSellerProps = {
  id: number;
  name: string;
  cpf: string;
  paymentDetails: string;
  isActive: boolean;
};

export default function FreelancerSellers() {
  const navigate = useNavigate();
  const [freelancers, setFreelancers] = useState<FreelancerSellerProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [token] = useState({
    headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` },
  });
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [showActive, setShowActive] = useState(
    JSON.parse(localStorage.getItem('showActive_freelancer') || 'true'),
  );
  const [showInactive, setShowInactive] = useState(
    JSON.parse(localStorage.getItem('showInactive_freelancer') || 'false'),
  );

  async function fetchFreelancers() {
    try {
      setLoading(true);
      const response = await api.get('/admin/freelancer', token);
      setFreelancers(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({
        title: 'Ocorreu um erro',
        children: 'Não foi possível carregar a lista de vendedores autônomos',
        actions: [
          {
            label: 'Ok, entendi',
            onClick: () => navigate(-1),
            type: ModalActionType.PRIMARY,
          },
        ],
      });
      setLoading(false);
    }
  }

  async function deleteFreelancer(freelancer: FreelancerSellerProps) {
    try {
      setLoading(true);
      setModal(null);
      await api.delete(`admin/freelancer/${freelancer.id}`, {
        headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` },
      });
      await fetchFreelancers();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  async function setVisibility(freelancer: FreelancerSellerProps) {
    try {
      setLoading(true);
      await api.put(
        `/admin/freelancer/${freelancer.id}`,
        {
          isActive: !freelancer.isActive,
        },
        token,
      );
      fetchFreelancers();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      if (typeof err.response !== 'undefined') {
        if (err.response.status === 422) {
          setModal(showApiErrors(setModal, err.response.data.errors));
        } else {
          setShowToastError(true);
          setTimeout(() => {
            setShowToastError(false);
          }, 3000);
          setModal({
            title: 'Ocorreu um erro',
            children: 'Não foi possível alterar status.',
            actions: [
              {
                label: 'Ok, entendi',
                onClick: () => setModal(null),
                type: ModalActionType.PRIMARY,
              },
            ],
          });
        }
      }
      console.log(err.response);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchFreelancers();
  }, []);

  useEffect(() => {
    localStorage.setItem('showActive_freelancer', JSON.stringify(showActive));
    localStorage.setItem('showInactive_freelancer', JSON.stringify(showInactive));
  }, [showActive, showInactive]);

  const filteredFreelancers = freelancers.filter((freelancer) => {
    if (showActive && showInactive) return true;
    if (showActive) return freelancer.isActive;
    if (showInactive) return !freelancer.isActive;
    return false;
  });

  if (freelancers.length < 1) {
    return (
      <DefaultLayout
        pageTitle="Representantes"
        loading={loading}
        showToastSuccess={showToastSuccess}
        showToastError={showToastError}
        showRightSlider={false}
        rightSliderContent={null}
        modal={modal}
        setCloseRightSlider={() => null}
      >
        <div className="not-exsits">
          <span className="material-icons-round">not_interested</span>
          <h3 className="table-not-exists">Hão há representante cadastrado.</h3>
          <ButtonTextIcon
            label="Adicionar vendedor autônomo"
            type={BtnType.PRIMARY}
            icon="add"
            active
            onClick={() => navigate('/representantes/adicionar')}
            ref={null}
          />
        </div>
      </DefaultLayout>
    );
  }
  return (
    <DefaultLayout
      pageTitle="Representantes"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Representantes</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <button
                className="table-btn-primary"
                type="button"
                onClick={() => navigate('/representantes/adicionar')}
              >
                <span className="material-icons-round">add</span>
                adicionar
              </button>
              <div className="d-flex align-items-center">
                <div className="mx-2 separ" />
                <div className="form-check lh-lg">
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    id="showInactive"
                    checked={showInactive}
                    onChange={() => setShowInactive(!showInactive)}
                  />
                  <label
                    className="form-check-label text-secondary mb-0 fs-7"
                    htmlFor="showInactive"
                  >
                    Mostrar Inativos
                  </label>
                </div>
              </div>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">CPF</th>
                  <th scope="col">Observações</th>
                  <th className="text-end" scope="col">
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredFreelancers.map((item, i) => (
                  <tr key={i + 1}>
                    <td>{item.name}</td>
                    <td>{maskCPF(item.cpf)}</td>
                    <td
                      style={{
                        maxWidth: '10vw',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.paymentDetails}
                    </td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <button
                            type="button"
                            onClick={() => setModal({
                              title: 'Excluir Embaixador',
                              children: 'Deseja mesmo excluir? Essa será uma ação irreversível!',
                              actions: [
                                {
                                  label: 'Cancelar',
                                  onClick: () => setModal(null),
                                  type: ModalActionType.PRIMARY,
                                },
                                {
                                  label: 'Excluir',
                                  onClick: () => deleteFreelancer(item),
                                  type: ModalActionType.DANGER,
                                },
                              ],
                            })}
                          >
                            <span className="material-icons-round text-danger">delete</span>
                          </button>
                        </div>
                        <SwitcherPrimary
                          id={`status-changer-${i}`}
                          onChange={() => setVisibility(item)}
                          isActive={item.isActive}
                        />
                        <div className="table-action-icon">
                          <button
                            type="button"
                            onClick={() => navigate(`/representantes/editar/${item.id}`)}
                          >
                            <span className="material-icons-round">more</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
