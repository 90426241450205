/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
import React from 'react';
import { DateTime } from 'luxon';

export default function resolvePaymentStatus(status: string, duoDate: string) {
  const splittedDuoDate = duoDate.split('-');
  const duoExpirationDay = splittedDuoDate[0];
  const duoExpirationMonth = splittedDuoDate[1];
  const duoExpirationYear = splittedDuoDate[2];

  if (isNaN(Number(duoExpirationDay)) || isNaN(Number(duoExpirationMonth)) || isNaN(Number(duoExpirationYear))) {
    return '';
  }

  const expirationDate = DateTime.fromObject({ day: Number(duoExpirationDay), month: Number(duoExpirationMonth), year: Number(duoExpirationYear) });

  const now = DateTime.now();
  if (status === 'PAID') {
    return <p className="bg-success">Pago</p>;
  }
  if (status === 'OTHER') {
    return <p className="bg-other">Autorizado</p>;
  }
  if (status === 'REVERSED') {
    return <p className="bg-danger">Estornado</p>;
  }
  if (now.day === expirationDate.day && now.month === expirationDate.month && now.year === expirationDate.year) {
    return <p className="bg-alert">No prazo</p>;
  }
  if (now <= expirationDate) {
    return <p className="bg-alert">No prazo</p>;
  }
  return <p className="bg-danger">Vencido</p>;
}
