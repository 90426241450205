/* eslint-disable no-shadow */
/* eslint-disable max-len */
import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import download from 'downloadjs';
import api from '../../../config/axios';
import Modality from './ModalContent/Modality';
import Sex from './ModalContent/Sex';
import Age from './ModalContent/Age';
import City from './ModalContent/City';
import Ticket from './ModalContent/Ticket';

export interface IModalContent {
  holdersAmount: number,
  dependentsAmount: number,
  holdersMaleAmount: number,
  holdersFemaleAmount: number,
  dependentsMaleAmount: number,
  dependentsFemaleAmount: number,
  dependentsSpouseAmount: number,
  dependentsChildAmount: number,
  dependentsOtherRole: number,
  totalMale: number,
  totalFemale: number,
  totalLifes: number,
  holdersMinorThen18: number,
  holdersBetween18and50: number,
  holdersBetween51and65: number,
  holdersMajorThen65: number,
  dependentsMinorThen18: number,
  dependentsBetween18and50: number,
  dependentsBetween51and65: number,
  dependentsMajorThen65: number,
  totalMinorThen18: number,
  totalBetween18and50: number,
  totalBetween51and65: number,
  totalMajorThen65: number,
  dependentfromImperatriz: number,
  dependentfromAcailandia: number,
  dependentfromAguiarnopolis: number,
  dependentfromGovEdsonLobao: number,
  dependentfromJoaoLisboa: number,
  dependentfromAraguatins: number,
  dependentfromAnotherCities: number,
  holderfromImperatriz: number,
  holderfromAcailandia: number,
  holderfromAguiarnopolis: number,
  holderfromGovEdsonLobao: number,
  holderfromJoaoLisboa: number,
  holderfromAraguatins: number,
  holderfromAnotherCities: number,
  totalfromImperatriz: number,
  totalfromAcailandia: number,
  totalfromAguiarnopolis: number,
  totalfromGovEdsonLobao: number,
  totalfromJoaoLisboa: number,
  totalfromAraguatins: number,
  totalfromAnotherCities: number,
  ticketPerContractPf: number,
  ticketPerContractPj: number,
  ticketPerContractTotal: number,
  ticketPerLifeTotal: number
}

enum ActualPage {
  MODALITY,
  SEX,
  AGE,
  MEDIUMTICKET,
  CITY
}

export interface IPrimaryModalContent {
    id: string,
    initialDate: string,
    finalDate: string,
    title: string,
    fetchedData: IModalContent | null
}

export default function StratificationModal({
  id, title, initialDate, finalDate, fetchedData,
}: IPrimaryModalContent) {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(ActualPage.MODALITY);

  // async function fetchToExcel() {
  //   try {
  //     setLoading(true);
  //     const response = await api.post('/admin/reports/byperiod/dataseriestoexcel', {
  //       data,
  //       series,
  //     }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` }, responseType: 'arraybuffer' });
  //     download(response.data, `${title}.xlsx`);
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //   }
  // }

  function resolveActualPage() {
    if (currentPage === ActualPage.MODALITY && fetchedData !== null) {
      return <Modality props={fetchedData} />;
    }
    if (currentPage === ActualPage.SEX) {
      return <Sex props={fetchedData} />;
    }

    if (currentPage === ActualPage.AGE) {
      return <Age props={fetchedData} />;
    }
    if (currentPage === ActualPage.CITY) {
      return <City props={fetchedData} />;
    }
    return <Ticket props={fetchedData} />;
  }

  return (
    <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Relatório</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body modal-report">
            <h1 className="title">
              Relatório estratificado |
              {' '}
              {`${initialDate} à ${finalDate}`}
            </h1>
            <div className="header-btn-wrapper">
              <button type="button" className={currentPage === ActualPage.MODALITY ? 'active' : 'inactive'} onClick={() => setCurrentPage(ActualPage.MODALITY)}>Modalidade</button>
              <button type="button" className={currentPage === ActualPage.SEX ? 'active' : 'inactive'} onClick={() => setCurrentPage(ActualPage.SEX)}>Sexo</button>
              <button type="button" className={currentPage === ActualPage.AGE ? 'active' : 'inactive'} onClick={() => setCurrentPage(ActualPage.AGE)}>Idade</button>
              <button type="button" className={currentPage === ActualPage.CITY ? 'active' : 'inactive'} onClick={() => setCurrentPage(ActualPage.CITY)}>Cidade Moradia</button>
              <button type="button" className={currentPage === ActualPage.MEDIUMTICKET ? 'active' : 'inactive'} onClick={() => setCurrentPage(ActualPage.MEDIUMTICKET)}>Ticket Médio</button>
            </div>
            <div className="content">
              {resolveActualPage()}
            </div>
          </div>
          <div className="modal-footer">
            {/* <button
              type="button"
              className="btn btn-primary"
              onClick={() => fetchToExcel()}
            >
              {loading ? 'Carregando' : 'Baixar para Excel'}
            </button> */}
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Fechar

            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
