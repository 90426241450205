/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { PaymentPrizes } from '.';
import BasicInput from '../../components/BasicInput';
import PriceInput from '../../components/PriceInput';

type ModalProps = {
    id: string
    gatewayTarget: string,
    gatewayMethodTarget: string,
    changeFunction: () => Promise<void>,
    gatewayProps: PaymentPrizes,
    checked: boolean | null,
    text: string | null,
    changeInput: (arg0: string | null) => void,
    changeChecked: (arg0: boolean | null) => void,
}

export default function GatewayPrevisionModal({
  id, changeFunction, gatewayMethodTarget, gatewayTarget, gatewayProps, checked, text, changeChecked, changeInput,
}: ModalProps) {
  const [input, setInput] = useState('');
  const [ctxChecked, setChecked] = useState(false);
  function onClose() {
    window.location.reload();
  }

  async function execute() {
    try {
      await changeFunction();
      window.location.reload();
    } catch (err) {
      console.log(err);
    }

    // window.location.reload();
  }

  function validate() {
    if (ctxChecked && input === '0') {
      return false;
    }
    if (ctxChecked && input.trim() === '') {
      return false;
    }
    if (input.trim() === '') {
      return false;
    }
    return true;
  }

  function validateMessage() {
    console.log(ctxChecked, Number(input));
    if (ctxChecked && Number(input) === 0) {
      return 'Caso 0 (Mesmo dia), desmaque a opção "Dias úteis"';
    }
    return 'Digite quantos dias';
  }

  useEffect(() => {
    changeInput(gatewayProps.intoBankAccountInDays.toString());
    changeChecked(gatewayProps.isCalcBasedInBussinessDays);
  }, []);

  return (
    <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Editar -
              {' '}
              {gatewayTarget}
              {' '}
              -
              {' '}
              {gatewayMethodTarget}
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => onClose()} />
          </div>
          <div className="modal-body">
            <BasicInput
              id="prize "
              label="Prazo em dias"
              readOnly={false}
              helper="Digite um novo prazo"
              error={validate() ? validateMessage() : ''}
              value={input.toString()}
              type="number"
              onChange={(data) => {
                if (data === '') {
                  setInput('0');
                  changeInput('0');
                } else {
                  setInput(data);
                  changeInput(data);
                }
              }}
            />
            <div className="col-12 allow-modifications">
              <input
                type="checkbox"
                onChange={() => {
                  changeChecked(checked !== null ? !checked : !gatewayProps.isCalcBasedInBussinessDays);
                  setChecked((prev) => !prev);
                }}
                checked={checked !== null ? checked : gatewayProps.isCalcBasedInBussinessDays}
              />
              <p>Dias úteis</p>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => onClose()}>Fechar</button>
            <button type="button" className="btn btn-primary" onClick={() => execute()} disabled={!validate()}>Alterar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
