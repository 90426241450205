export default function showListOfStringsOnTable(elements: string[]) {
  if (elements.length === 0) {
    return '';
  }

  let finalString = '';

  elements.forEach((item, i) => {
    if (elements.length === 1) {
      finalString = `${item}.`;
    } else if (i === elements.length - 1) {
      finalString += `${item}.`;
    } else {
      finalString += `${item}, `;
    }
  });

  return finalString;
}
