/* eslint-disable max-len */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import DefaultLayout from '../../Layout';

export default function Forbbiden() {
  const navigate = useNavigate();
  return (
    <DefaultLayout
      pageTitle="Accesso negado"
      loading={false}
      showToastSuccess={false}
      showToastError={false}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={null}
      setCloseRightSlider={() => null}
    >
      <div className="d-flex flex-column align-items-center justify-content-center">
        <h1 style={{ fontSize: 21, fontWeight: 'bold', marginBottom: 32 }}>Accesso Negado</h1>
        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="242.41705" height="212.57302" viewBox="0 0 742.41705 712.57302" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path id="aa38b2e7-2ea9-4025-adcf-83f87c0cfc4a-126" data-name="Path 968" d="M853.60644,262.60547h-3.9v-106.977a61.915,61.915,0,0,0-61.915-61.915h-226.65a61.915,61.915,0,0,0-61.916,61.914v586.884a61.915,61.915,0,0,0,61.915,61.915h226.648a61.915,61.915,0,0,0,61.915-61.915v-403.758h3.9Z" transform="translate(-228.79147 -93.71349)" fill="#3f3d56" />
          <path id="f27259c2-0089-4137-bfd8-cb22dff914f1-127" data-name="Path 969" d="M837.00647,151.48149v595.175a46.959,46.959,0,0,1-46.942,46.952h-231.3a46.966,46.966,0,0,1-46.973-46.952v-595.175a46.965,46.965,0,0,1,46.971-46.951h28.058a22.329,22.329,0,0,0,20.656,30.74h131.868a22.329,22.329,0,0,0,20.656-30.74h30.055a46.959,46.959,0,0,1,46.951,46.942Z" transform="translate(-228.79147 -93.71349)" fill="#fff" />
          <circle id="fc31cc31-f989-45cc-bd1e-521a0ee1871b" data-name="Ellipse 18" cx="445.56497" cy="200.129" r="96.565" fill="#6c63ff" />
          <path id="a573942c-3bf3-4c43-b474-689725a927f7-128" data-name="Path 39" d="M779.67245,494.30947h-205.537a3.81,3.81,0,0,1-3.806-3.806V439.51949a3.811,3.811,0,0,1,3.806-3.806h205.537a3.811,3.811,0,0,1,3.806,3.806v50.985a3.811,3.811,0,0,1-3.806,3.806Zm-205.537-57.074a2.286,2.286,0,0,0-2.284,2.284v50.985a2.286,2.286,0,0,0,2.284,2.284h205.537a2.286,2.286,0,0,0,2.284-2.284v-50.985a2.286,2.286,0,0,0-2.284-2.284Z" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
          <path id="fb5feb1e-1fe2-48fa-806d-0a2b51d12b49-129" data-name="Path 40" d="M637.69945,454.07448a2.66449,2.66449,0,0,0,0,5.329h125.605a2.665,2.665,0,0,0,.2041-5.32611q-.0585-.00221-.11713-.0019h-125.692Z" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
          <path id="bcb9932f-0864-4b7e-b0ed-f7254bb9b8bf-130" data-name="Path 41" d="M637.69945,470.05846a2.66449,2.66449,0,0,0,0,5.329h125.605a2.665,2.665,0,0,0,.2041-5.32611q-.0585-.00219-.11713-.00189h-125.692Z" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
          <path id="f88e2a8b-becc-4da9-bd36-af011daaaaf2-131" data-name="Path 42" d="M779.67245,579.28947h-205.537a3.81,3.81,0,0,1-3.806-3.806V524.49949a3.811,3.811,0,0,1,3.806-3.806h205.537a3.811,3.811,0,0,1,3.806,3.806v50.985A3.811,3.811,0,0,1,779.67245,579.28947Zm-205.537-57.074a2.286,2.286,0,0,0-2.284,2.284v50.985a2.286,2.286,0,0,0,2.284,2.284h205.537a2.286,2.286,0,0,0,2.284-2.284v-50.985a2.286,2.286,0,0,0-2.284-2.284Z" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
          <path id="e192d34f-2ccc-4f79-b47a-46430e4d87f6-132" data-name="Path 43" d="M637.69945,539.33047a2.66449,2.66449,0,0,0,0,5.329h125.605a2.665,2.665,0,0,0,.087-5.328h-125.692Z" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
          <path id="b230677c-48cc-4ee6-87d6-a753204ffe47-133" data-name="Path 44" d="M637.69945,555.3185a2.66449,2.66449,0,0,0,0,5.329h125.605a2.665,2.665,0,0,0,.087-5.328h-125.692Z" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
          <path id="b88a2a79-0ae3-4fd9-9894-02e0db9a2271-134" data-name="Path 39-2" d="M779.67245,664.54748h-205.537a3.81,3.81,0,0,1-3.806-3.806V609.7575a3.811,3.811,0,0,1,3.806-3.806h205.537a3.811,3.811,0,0,1,3.806,3.806v50.985a3.811,3.811,0,0,1-3.806,3.806Zm-205.537-57.074a2.286,2.286,0,0,0-2.284,2.284v50.985a2.286,2.286,0,0,0,2.284,2.284h205.537a2.286,2.286,0,0,0,2.284-2.284v-50.985a2.286,2.286,0,0,0-2.284-2.284Z" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
          <path id="aa2394a9-8b56-4a12-a2cd-a583e6ea12eb-135" data-name="Path 40-2" d="M637.69945,624.59148a2.66449,2.66449,0,1,0,0,5.329h125.605a2.665,2.665,0,0,0,.2041-5.32611q-.0585-.00219-.11713-.00189h-125.692Z" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
          <path id="bc352cb2-8959-4b51-bd18-df6817a9a47e-136" data-name="Path 41-2" d="M637.69945,640.57846a2.66449,2.66449,0,0,0,0,5.329h125.605a2.665,2.665,0,0,0,.2041-5.32611q-.0585-.0022-.11713-.00189h-125.692Z" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
          <path id="b30ee82f-5b3a-4aa4-9db7-ead828191937-137" data-name="Path 970" d="M969.27042,806.28651h-738.541c-1.071,0-1.938-.468-1.938-1.045s.868-1.045,1.938-1.045H969.27054c1.06994,0,1.938.468,1.938,1.045S970.34146,806.28651,969.27042,806.28651Z" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
          <g id="f24cf2fd-107c-4150-a0f1-6f6b12d88bf3" data-name="Group 58">
            <path id="a2b576eb-bb95-4480-aa60-cd8aa023fe61-138" data-name="Path 438" d="M937.99248,765.52151a19.4741,19.4741,0,0,1-18.806-3.313c-6.587-5.528-8.652-14.636-10.332-23.07l-4.97-24.945,10.405,7.165c7.483,5.152,15.134,10.47,20.316,17.933s7.443,17.651,3.28,25.726" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
            <path id="fe1c5433-44e0-47a6-8430-329cbd7c4dea-139" data-name="Path 439" d="M936.38547,797.45852c1.31-9.542,2.657-19.206,1.738-28.849-.816-8.565-3.429-16.93-8.749-23.789a39.57365,39.57365,0,0,0-10.153-9.2c-1.015-.641-1.95.968-.939,1.606a37.62192,37.62192,0,0,1,14.881,17.956c3.24,8.241,3.76,17.224,3.2,25.977-.338,5.294-1.053,10.553-1.774,15.805a.964.964,0,0,0,.65,1.144.936.936,0,0,0,1.144-.65Z" transform="translate(-228.79147 -93.71349)" fill="#f2f2f2" />
            <path id="f0043cfc-c9b9-4981-8afd-8c13c1b6f93f-140" data-name="Path 442" d="M926.95847,782.14846a14.336,14.336,0,0,1-12.491,6.447c-6.323-.3-11.595-4.713-16.34-8.9l-14.035-12.395,9.289-.444c6.68-.32,13.533-.618,19.9,1.442s12.231,7.018,13.394,13.6" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
            <path id="b883c521-5e9e-4a38-b47c-4f8b0cd8c6cd-141" data-name="Path 443" d="M940.08649,802.94344c-6.3-11.156-13.618-23.555-26.685-27.518a29.77874,29.77874,0,0,0-11.224-1.159c-1.192.1-.894,1.94.3,1.837a27.6648,27.6648,0,0,1,17.912,4.739c5.051,3.438,8.983,8.217,12.311,13.286,2.039,3.1,3.865,6.341,5.691,9.573C938.97147,804.73348,940.67746,803.98843,940.08649,802.94344Z" transform="translate(-228.79147 -93.71349)" fill="#f2f2f2" />
          </g>
          <g id="b241f6a5-c54d-499c-8ecd-4235a19d143f" data-name="Group 59">
            <circle id="bfaee8a8-2192-45bf-83cb-a2f223d41a02" data-name="Ellipse 5" cx="370.98597" cy="370.985" r="15.986" fill="#6c63ff" />
            <path id="ae47602b-fb54-4e88-955d-49b260f35f7f-142" data-name="Path 40-3" d="M592.12445,461.71247c-.184,0-.333,1.193-.333,2.664s.149,2.665.333,2.665h15.719c.184.024.336-1.149.339-2.62a5.94291,5.94291,0,0,0-.328-2.708h-15.73Z" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
          </g>
          <g id="a1e1e52b-75f8-4aa9-8279-faf451e6aae1" data-name="Group 60">
            <circle id="fa3a3ce2-c232-40ee-81b4-25b83d5b931c" data-name="Ellipse 5-2" cx="370.98597" cy="456.278" r="15.986" fill="#6c63ff" />
            <path id="aaaffa69-7c16-47ec-b12d-87139789d2df-143" data-name="Path 40-4" d="M592.12445,547.00547c-.184,0-.333,1.193-.333,2.664s.149,2.665.333,2.665h15.719c.184.024.336-1.149.339-2.62a5.94291,5.94291,0,0,0-.328-2.708h-15.73Z" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
          </g>
          <g id="fd2b54c2-3dec-4fb6-9f72-e6c667a136b8" data-name="Group 61">
            <circle id="abc19202-5605-459b-9c25-b430addb8839" data-name="Ellipse 5-3" cx="370.98597" cy="541.53599" r="15.986" fill="#6c63ff" />
            <path id="f69fbb38-8754-494b-94fa-96f6df4fd0e7-144" data-name="Path 40-5" d="M592.12445,632.26346c-.184,0-.333,1.193-.333,2.664s.149,2.665.333,2.665h15.719c.184.024.336-1.149.339-2.62a5.943,5.943,0,0,0-.328-2.708h-15.73Z" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
          </g>
          <rect x="670.35645" y="260.34249" width="8" height="67" transform="translate(-239.05506 469.193) rotate(-45)" fill="#fff" />
          <rect x="670.35645" y="260.34249" width="8" height="67" transform="translate(176.50097 -484.49103) rotate(45)" fill="#fff" />
          <path d="M319.05083,549.84841a10.05579,10.05579,0,0,0,5.38778-14.44741l23.56521-26.86373-18.39547-2.53779-19.5577,25.89933a10.11027,10.11027,0,0,0,9.00018,17.9496Z" transform="translate(-228.79147 -93.71349)" fill="#9f616a" />
          <polygon points="118.679 694.215 102.244 694.214 94.425 630.82 118.682 630.821 118.679 694.215" fill="#9f616a" />
          <path d="M351.66209,803.8602l-52.99477-.002v-.6703a20.62815,20.62815,0,0,1,20.627-20.62671h.00131l32.3674.00131Z" transform="translate(-228.79147 -93.71349)" fill="#2f2e41" />
          <polygon points="204.713 680.461 189.017 685.334 162.751 627.11 185.917 619.918 204.713 680.461" fill="#9f616a" />
          <path d="M442.23214,788.14677,391.62055,803.8602l-.19877-.64014a20.62813,20.62813,0,0,1,13.58247-25.81574l.00126-.00039,30.91186-9.59713Z" transform="translate(-228.79147 -93.71349)" fill="#2f2e41" />
          <path id="ae9bdfa9-f7e0-4b2f-8168-ca8868d01318-145" data-name="Path 973" d="M334.88445,495.65649l-24.34114,27.8773,19.05613,1.71471Z" transform="translate(-228.79147 -93.71349)" fill="#e6e6e6" />
          <path id="b88343d2-9c47-4183-8a8a-427a73d8d9be-146" data-name="Path 975" d="M325.37344,531.58948s-8.455,4.227-9.512,23.251,3.171,68.7,3.171,68.7-4.227,22.194,0,42.274-4.227,93,1.057,93,32.762,3.171,33.819,0,2.114-50.729,2.114-50.729,8.455-24.308,0-39.1c0,0,29.52086,51.54832,48.615,90.889,4.17891,8.61,35.933-1.057,30.649-10.569s-17.966-52.843-17.966-52.843-9.512-31.706-26.421-45.445l8.455-67.639s17.967-45.445,7.4-51.786S325.37344,531.58948,325.37344,531.58948Z" transform="translate(-228.79147 -93.71349)" fill="#2f2e41" />
          <circle id="fdc24b21-a9e1-47f2-b2e4-7458730b7c21" data-name="Ellipse 182" cx="128.28696" cy="238.129" r="27.478" fill="#a0616a" />
          <path id="ac268c91-41f4-49c7-bce6-773e5eb69fa2-147" data-name="Path 976" d="M387.72745,361.43447l-34.16,20.08s-13.08,7.366-17.966,20.08c-5.208,13.55-2.181,32.628,0,36.99,4.227,8.455-1.773,29.592-1.773,29.592l-5.284,48.615s-19.023,17.966-4.227,20.08,41.217-1.057,57.07,0,33.819,3.171,28.535-7.4-11.625-17.967-5.284-39.1c4.962-16.54,4.747-78.383,4.419-104.5a21.025,21.025,0,0,0-10.211-17.767Z" transform="translate(-228.79147 -93.71349)" fill="#e5e5e5" />
          <path id="aebed2c8-94ca-417e-8b11-27e17cbf3024-148" data-name="Path 980" d="M372.40744,394.72649l3.17,64.468-30.726,62.223-5.211-1.983,31.706-58.127Z" transform="translate(-228.79147 -93.71349)" opacity="0.1" style={{ isolation: 'isolate' }} />
          <path id="e13ea0c4-bc86-4025-9528-1665a42d3372-149" data-name="Path 982" d="M407.27945,472.93247v-7.4l-35.929,59.186Z" transform="translate(-228.79147 -93.71349)" opacity="0.1" style={{ isolation: 'isolate' }} />
          <path id="eb3190ba-6b3f-4b2b-91d1-1543e580b413-150" data-name="Path 983" d="M337.57645,306.38749l-4.539-1.816s9.5-10.457,22.713-9.548l-3.717-4.092s9.085-3.637,17.345,5.91c4.342,5.019,9.365,10.919,12.5,17.564h4.865l-2.03,4.471,7.106,4.471-7.294-.8a24.73921,24.73921,0,0,1-.69,11.579l.2,3.534s-8.459-13.089-8.459-14.905v4.547s-4.543-4.092-4.543-6.82l-2.478,3.183-1.239-5-15.28,5,2.476-4.094-9.5,1.364,3.717-5s-10.737,5.91-11.15,10.912-5.781,11.366-5.781,11.366l-2.478-4.547S325.60443,313.20747,337.57645,306.38749Z" transform="translate(-228.79147 -93.71349)" fill="#2f2e41" />
          <path d="M355.35408,552.83885a10.05581,10.05581,0,0,0,2.73778-15.17434l18.42263-30.62-18.55382.76775-14.64995,28.95959a10.11028,10.11028,0,0,0,12.04336,16.067Z" transform="translate(-228.79147 -93.71349)" fill="#9f616a" />
          <path id="a62f6567-f472-4931-86f9-ff652851a370-151" data-name="Path 981" d="M397.24046,375.17548l7.926-1.585s23.779,17.438,16.381,52.314-40.16,87.719-40.16,87.719-7.4,9.512-9.512,11.625-6.341,0-4.227,3.171-3.171,5.284-3.171,5.284-23.251,0-21.137-8.455,38.047-68.7,38.047-68.7l-5.284-56.013S371.87446,373.0615,397.24046,375.17548Z" transform="translate(-228.79147 -93.71349)" fill="#e5e5e5" />
        </svg>
        <p style={{ marginTop: 32, marginBottom: 32 }}>Esse conteúdo não existe ou você não tem permissão para acessa-lo. Verifique a URL e tente novamente.</p>

        <ButtonTextIcon
          label="Voltar"
          type={BtnType.PRIMARY}
          icon="arrow_back"
          active
          onClick={() => navigate(-1)}
          ref={null}
        />
      </div>
    </DefaultLayout>
  );
}
