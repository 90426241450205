/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInput from '../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import TextAreaInput from '../../components/TextAreaInput';
import api from '../../config/axios';
import ContractPJProps from '../../dto/contractPJ';
import { maskCPF } from '../../helpers/inputMasks.js';
import { validateCpf } from '../../helpers/validate';
import DefaultLayout from '../../Layout';
import { ContractPFProps } from '../Contracts/PF/types';

type FreelancerSellerProps = {
    name: string,
    cpf: string,
    paymentDetails: string,
    isActive: boolean,
    contractPf: ContractPFProps[],
    contractPj: ContractPJProps[],
}

export default function EditFreelancerSeller() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` } });
  const [freelancer, setFreelancer] = useState<FreelancerSellerProps>({
    name: '',
    cpf: '',
    paymentDetails: '',
    isActive: true,
    contractPf: [],
    contractPj: [],
  });

  async function fetchFreelancers() {
    try {
      setLoading(true);
      const response = await api.get(`/admin/freelancer/${id}`, token);
      setFreelancer(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível encontrar vendedor autônomo. Verifique se a id do cabeçalho está correta', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
      setLoading(false);
    }
  }

  function validateRefferFields(): boolean {
    if (freelancer.name.length >= 3 && validateCpf(freelancer.cpf)) {
      return true;
    }
    return false;
  }

  async function updateFreelancer() {
    try {
      setLoading(true);
      await api.put(`/admin/freelancer/${id}`, freelancer, token);
      setLoading(false);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      setLoading(false);
      console.log(err.response.data);
      setModal({ title: 'Não foi possível.', children: 'Não foi possível atualizar. Verifique se o CPF já não está cadastrado em nome de outro representante.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  useEffect(() => {
    fetchFreelancers();
  }, []);

  return (

    <DefaultLayout
      pageTitle="Editar representante"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={null}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Editar representante</h1>
          <div className="row">
            <div className="col-4">
              <BasicInput
                id="representante-nome"
                label="Nome"
                readOnly={false}
                helper="Ex. José Antunes"
                error={freelancer.name.length < 3 ? 'Digite um nome válido' : ''}
                value={freelancer.name}
                type="text"
                onChange={(data) => setFreelancer({ ...freelancer, name: data })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="representante-cpf"
                label="CPF"
                readOnly={false}
                helper="Ex. 092.283.000-20"
                error={!validateCpf(freelancer.cpf) ? 'Digite um CPF válido' : ''}
                value={freelancer.cpf}
                type="text"
                onChange={(data) => setFreelancer({ ...freelancer, cpf: maskCPF(data) })}
              />
            </div>
            <div className="col-12">
              <TextAreaInput
                id="representantes-obs"
                label="Observações"
                readOnly={false}
                helper="Ex. lembretes sobre dados de pagamento."
                error=""
                value={freelancer.paymentDetails}
                onChange={(data) => setFreelancer({ ...freelancer, paymentDetails: data })}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                active={validateRefferFields()}
                onClick={() => updateFreelancer()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Contratos PF vinculados</h1>
          <div className="table-wrapper">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Dt Assinatura</th>
                  <th scope="col">Dt. Vencimento</th>
                  <th scope="col">Valor Global (R$)</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {freelancer.contractPf.length === 0 && 'Nenhum contrato vinculado'}
                {freelancer.contractPf.map((item, i) => (
                  <tr key={i}>
                    <td>{`PF000${item.id}`}</td>
                    <td>{item.signatureDate}</td>
                    <td>{item.dueDate}</td>
                    <td>{item.contractGlobalValue}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <button type="button" onClick={() => navigate(`/contratos/pf/visualizar/${item.id}`)}>
                            <span className="material-icons-round">
                              more
                            </span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Contratos PJ vinculados</h1>
          <div className="table-wrapper">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Dt Assinatura</th>
                  <th scope="col">Dt. Vencimento</th>
                  <th scope="col">Valor Global  (R$)</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {freelancer.contractPj.length > 0
                  ? freelancer.contractPj.map((item, i) => (
                    <tr key={i}>
                      <td>{`PJ000${item.id}`}</td>
                      <td>{item.signatureDate}</td>
                      <td>{item.dueDate}</td>
                      <td>{item.contractGlobalValue}</td>
                      <td align="right">
                        <div className="d-flex justify-content-end align-items-center">
                          <div className="table-action-icon">
                            <button type="button" onClick={() => navigate(`/contratos/pj/editar/${item.id}`)}>
                              <span className="material-icons-round">
                                more
                              </span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )) : (
                    <p>Nenhum contrato vinculado</p>
                  )}

              </tbody>
            </table>
          </div>
        </div>
      </div>

    </DefaultLayout>
  );
}
