/* eslint-disable max-len */
/* eslint-disable no-shadow */
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import api from '../config/axios';
import { normalizeDate } from '../helpers/inputMasks.js';
import TextAreaInput from './TextAreaInput';

type PagVipRefProps = {
  id: number,
  pagazulHolderLinksId: number,
  idCharge: string,
  bankSlip: string,
  createdAt: string,
  updatedAt: string,
}

interface PageProps {
    pagVipProps: PagVipRefProps
    id: string,
    title: string,
    isPf: boolean,
    installmentId: number,
    fetchPayment: () => void
}

enum ActualPage {
  FORM,
  LOADING,
  SUCCESS,
  ERROR
}

export default function CancelChargeModal({
  pagVipProps, id, title, fetchPayment, isPf, installmentId,
}: PageProps) {
  const [reason, setReason] = useState('');
  const [actualPage, setActualPage] = useState(ActualPage.FORM);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [error, setError] = useState('');

  async function tryCancelCharge() {
    try {
      setActualPage(ActualPage.LOADING);
      const route = isPf ? '/admin/contractpf/installment/cancel-boleto' : '/admin/contractpj/installment/cancel-boleto';
      const response = await api.post(route, {
        id: installmentId,
        justification: reason.trim(),
      }, token);
      console.log(response.data);
      setActualPage(ActualPage.SUCCESS);
    } catch (err: any) {
      console.log('erro ao cancelar boleto', err.response.data);
      if (err.response) {
        if (err.response.data.error) {
          setError(err.response.data.error);
        }
      }
      setActualPage(ActualPage.ERROR);
    }
  }

  function closeAndFetch() {
    window.location.reload();
  }
  return (
    <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">{title}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeAndFetch()} />
          </div>
          <div className="modal-body">

            { actualPage === ActualPage.LOADING && (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
              <p className="fw-bold text-muted text-center">Estabelecendo comunicação com API da PagVip, aguarde...</p>
            </div>
            )}

            { actualPage === ActualPage.FORM && (
            <>
              <div className="pag-azul-info-container">
                <div className="pag-azul-info-wrapper">
                  <p className="pag-azul-item-title">
                    Boleto criado em
                  </p>
                  <p className="pag-azul-item-description">{DateTime.fromISO(pagVipProps.createdAt).toFormat('dd-MM-yyyy')}</p>
                </div>
                <div className="pag-azul-info-wrapper">
                  <p className="pag-azul-item-title">
                    Bank Slip
                  </p>
                  <p className="pag-azul-item-description">{pagVipProps.bankSlip}</p>
                </div>
                <div className="pag-azul-info-wrapper">
                  <p className="pag-azul-item-title">Charge ID</p>
                  <p className="pag-azul-item-description">{pagVipProps.idCharge}</p>
                </div>
              </div>
              <TextAreaInput
                id="charge-cancel-reason"
                label="Motivo do cancelamento"
                readOnly={false}
                helper="Alteração de valores"
                error={reason.length < 1 ? 'Campo obrigatório' : ''}
                value={reason}
                onChange={(data) => setReason(data)}
              />
            </>
            )}

            { actualPage === ActualPage.SUCCESS && (
            <div className="d-flex align-items-center justify-content-center text-center" style={{ minHeight: 400 }}>
              <p className="fw-bold text-muted">De acordo com a a resposta do sistema da PagVip, a cobrança foi cancelada com successo!</p>
            </div>
            )}

            {actualPage === ActualPage.ERROR && (
            <div className="d-flex flex-column align-items-center justify-content-center text-center" style={{ minHeight: 400 }}>
              <p className="fw-bold text-muted">Ocorreu um erro ao excluir o boleto.</p>
              {error !== '' && (
              <p className="fw-bold text-danger mt-4 fw-bold">
                {error}
                .
              </p>
              )}
            </div>
            )}

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => closeAndFetch()}>Fechar</button>
            {actualPage === ActualPage.FORM && (
              <button type="button" className="btn btn-primary" onClick={() => tryCancelCharge()}>Cancelar Cobrança</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
