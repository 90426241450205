/* eslint-disable max-len */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import BasicInput from '../../../components/BasicInput';
import { ModalProps } from '../../../components/Modal';
import DefaultLayout from '../../../Layout';
import LifesPF from './PF';
import LifesPJ from './PJ';

enum ActualPage {
    PF,
    PJ
}

export default function Lifes() {
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [showRightSlider, setShowRightSlider] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [actualPage, setActualPage] = useState(ActualPage.PF);

  return (
    <DefaultLayout
      pageTitle="Vidas"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={showRightSlider}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => setShowRightSlider}
    >
      <div className="row">
        <div className="col-12 header-btn-wrapper">
          <button
            type="button"
            className={actualPage === ActualPage.PF ? 'btn-active' : 'btn-inactive'}
            onClick={() => setActualPage(ActualPage.PF)}
            style={{ width: 'fit-content', paddingLeft: 8, paddingRight: 8 }}
          >
            Vidas PF
          </button>
          <button
            type="button"
            className={actualPage === ActualPage.PJ ? 'btn-active' : 'btn-inactive'}
            onClick={() => setActualPage(ActualPage.PJ)}
            style={{ width: 'fit-content', paddingLeft: 8, paddingRight: 8 }}
          >
            Vidas PJ
          </button>
        </div>
      </div>

      {actualPage === ActualPage.PF ? (
        <LifesPF setModal={(props) => setModal(props)} setLoading={(arg) => setLoading(arg)} setShowToastSuccess={(arg) => setShowToastSuccess(arg)} />
      ) : (
        <LifesPJ setModal={(props) => setModal(props)} setLoading={(arg) => setLoading(arg)} setShowToastSuccess={(arg) => setShowToastSuccess(arg)} />
      )}
    </DefaultLayout>
  );
}
