import axios from 'axios';
import Geolocation, { GeolocalizationCoordinates } from '../dto/geolocalization';

export default class OpenStreetGeolocation implements Geolocation {
  city: string;

  street: string;

  neighborhood: string;

  number: string;

  constructor(city: string, street: string, neighborhood: string, number: string) {
    this.city = city;
    this.street = street;
    this.neighborhood = neighborhood;
    this.number = number;
  }

  async findByAddress(): Promise<GeolocalizationCoordinates | null> {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search.php?q=${this.street} ${this.neighborhood} ${this.number} ${this.city}&polygon_geojson=1&format=jsonv2`);
      const location: any = response.data;
      if (location.length < 1) {
        return null;
      } if (typeof location[0].lon === 'undefined' || typeof location[0].lat === 'undefined') {
        return null;
      }
      return {
        latitude: location[0].lat,
        longitude: location[0].lon,
      };
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}
