import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicInput from '../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import TextAreaInput from '../../components/TextAreaInput';
import api from '../../config/axios';
import { maskCPF } from '../../helpers/inputMasks.js';
import { validateCpf } from '../../helpers/validate';
import DefaultLayout from '../../Layout';

type Representantes = {
    name: string,
    cpf: string,
    paymentDetails: string,
    isActive: boolean
}

export default function NewReffer() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` } });
  const [reffer, setReffer] = useState<Representantes>({
    name: '',
    cpf: '',
    paymentDetails: '',
    isActive: true,
  });

  function validateRefferFields(): boolean {
    if (reffer.name.length >= 3 && validateCpf(reffer.cpf)) {
      return true;
    }
    return false;
  }

  async function saveReffer() {
    try {
      setLoading(true);
      await api.post('/admin/reffer', reffer, token);
      setLoading(false);
      navigate(-1);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Não foi possível.', children: 'Não foi possível cadastrar. Verifique se o CPF já não está cadastrado em nome de outro embaixador.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  return (

    <DefaultLayout
      pageTitle="Embaixador Master"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={null}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Embaixador Master</h1>
          <div className="row">
            <div className="col-4">
              <BasicInput
                id="representante-nome"
                label="Nome"
                readOnly={false}
                helper="Ex. José Antunes"
                error={reffer.name.length < 3 ? 'Digite um nome válido' : ''}
                value={reffer.name}
                type="text"
                onChange={(data) => setReffer({ ...reffer, name: data })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="representante-cpf"
                label="CPF"
                readOnly={false}
                helper="Ex. 092.283.000-20"
                error={!validateCpf(reffer.cpf) ? 'Digite um CPF válido' : ''}
                value={reffer.cpf}
                type="text"
                onChange={(data) => setReffer({ ...reffer, cpf: maskCPF(data) })}
              />
            </div>
            <div className="col-12">
              <TextAreaInput
                id="representantes-obs"
                label="Observações"
                readOnly={false}
                helper="Ex. lembretes sobre dados de pagamento."
                error=""
                value={reffer.paymentDetails}
                onChange={(data) => setReffer({ ...reffer, paymentDetails: data })}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                active={validateRefferFields()}
                onClick={() => saveReffer()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
