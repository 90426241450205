/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ModalActionType, ModalProps } from '../../components/Modal';
import Tooltip from '../../components/Tooltip';
import api from '../../config/axios';
import normalizeDate, { showTodayDate } from '../../helpers/dates';
import { convertCardPaymentMethodFromApi } from '../../helpers/showCardMethods';
import { showFirstName } from '../../helpers/showCompactName';
import DefaultLayout from '../../Layout';
import FutureIncomesModalFilter from './FutureIncomesModalFilter';
import { FutureIncomesFilterProps, ProfitFilterProps } from './profitFilters';
import { storeType } from '../../store';
import CancelGalaxPayChargeModal from '../../components/CancelGalaxPayChargeModal';

interface IFutureIncomes {
  contractid: number,
  titular: string,
  clientid: number,
  vencimento: string,
  gateway: number,
  metodo: string,
  cartao: boolean,
  bandeira: null | string,
  valor: number,
  valorliquido: number,
  ispf: boolean,
  status: string,
  numparcela: number,
  prevision: string,
  installmentid: number,
  noncardtaxes: string | null,
  cardpaymentoption: string | null,
  paymentid: number,
  galaxpay_payment_method: 'boleto' | 'pix' | null
  galaxpay_installment_id: null | string
}

interface ResponseProps {
  meta: MetaProps
  data: IFutureIncomes[]
}

interface MetaProps {
  valBruto: number,
  valLiquido: number,
  per_page: number,
  current_page: number,
  last_page: number,
  first_page: number,
}

export default function FutureIncomes() {
  const navigate = useNavigate();
  const admin = useSelector(({ adminReducer }: storeType) => adminReducer);
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [futureIncomes, setFutureIncomes] = useState<ResponseProps>({
    meta: {
      valBruto: 0,
      valLiquido: 0,
      per_page: 50,
      current_page: 1,
      last_page: 1,
      first_page: 1,
    },
    data: [],
  });
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [profitTableFilters, setProfitTableFilters] = useState<FutureIncomesFilterProps>({
    maxRowsPerPage: 15,
    actualPage: 1,
    previsionInitalDate: '',
    previsionFinalDate: '',
    dueDateInitial: '',
    dueDateFinal: '',
    profitType: '',
    category: null,
  });

  const [initialPrevisionProfitDate, setInitialPrevisionProfitDate] = useState('');
  const [finalPrevisionProfitDate, setFinalPrevisionProfitDate] = useState('');
  const [initialDueProfitDate, setInitialDueProfitDate] = useState('');
  const [finalDueProfitDate, setFinalDueProfitDate] = useState('');
  const [projectionDateActive, setProjectionDateActive] = useState(false);
  const [dueDateActive, setDueDateActive] = useState(false);

  async function fetchFutureIncomes() {
    try {
      setLoading(true);
      const response = await api.post('/admin/prevision', {
        currentPage: profitTableFilters.actualPage,
      }, token);
      console.log('projecao', response.data);
      setFutureIncomes(response.data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log(err.response.data);
    }
  }

  function filterByDate() {
    setProfitTableFilters({
      ...profitTableFilters, actualPage: 1, previsionInitalDate: initialPrevisionProfitDate, previsionFinalDate: finalPrevisionProfitDate, dueDateInitial: initialDueProfitDate, dueDateFinal: finalDueProfitDate,
    });
  }

  async function fetchByDates() {
    try {
      setLoading(true);
      const response = await api.post('/admin/prevision', {
        previsionDateInitial: profitTableFilters.previsionInitalDate,
        previsionDateFinal: profitTableFilters.previsionFinalDate,
        dueDateInitial: profitTableFilters.dueDateInitial,
        dueDateFinal: profitTableFilters.dueDateFinal,
        currentPage: profitTableFilters.actualPage,
      }, token);
      setFutureIncomes(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Datas incorretas.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  function removeFilters() {
    setProfitTableFilters({
      ...profitTableFilters, previsionInitalDate: '', previsionFinalDate: '', dueDateInitial: '', dueDateFinal: '', actualPage: 1,
    });
    setInitialDueProfitDate('');
    setFinalDueProfitDate('');
    setInitialPrevisionProfitDate('');
    setFinalPrevisionProfitDate('');
    setDueDateActive(false);
    setProjectionDateActive(false);
  }

  function removeDueDateFilters() {
    if (dueDateActive) {
      setDueDateActive(false);
      setInitialDueProfitDate('');
      setFinalDueProfitDate('');
    } else {
      setDueDateActive(true);
    }
  }

  function removePrevisionDateFilters() {
    setProfitTableFilters((prev) => ({ ...prev, actualPage: 1 }));
    if (projectionDateActive) {
      setProjectionDateActive(false);
      setInitialPrevisionProfitDate('');
      setFinalPrevisionProfitDate('');
    } else {
      setProjectionDateActive(true);
    }
  }

  function showGatewayNameById(id: number) {
    if (id === 1) {
      return 'PagAzul Presencial';
    }
    if (id === 2) {
      return 'REDESANTA';
    }
    if (id === 3) {
      return 'PagAzul WEB';
    }
    if (id === 4) {
      return 'CIELO';
    }
    return 'GALAXPAY';
  }

  async function manualInstallmentPayment(installment: IFutureIncomes) {
    try {
      setLoading(true);
      if (!installment.ispf) {
        await api.post('admin/contractpj/installment/manual', {
          id: installment.installmentid,
          paymentDate: showTodayDate(),
        }, token);
      } else {
        await api.post('admin/contractpf/installment/manual', {
          id: installment.installmentid,
          paymentDate: showTodayDate(),
        }, token);
      }
      setModal(null);
      fetchFutureIncomes();
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível dar baixa manual na parcela em questão. Verifique se a mesma já não consta como status PAGO.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
      setLoading(false);
    }
  }

  function showMethod(futureIncome: IFutureIncomes) {
    if (futureIncome.cartao) {
      return `${futureIncome.bandeira === 'OTHER' ? 'OUTRO' : futureIncome.bandeira} - ${convertCardPaymentMethodFromApi(futureIncome.cardpaymentoption!)}`;
    }
    return futureIncome.noncardtaxes;
  }

  function showIdBasedOnClientType(futureIncome: IFutureIncomes) {
    if (!futureIncome.ispf) {
      return `PJ${futureIncome.contractid}`;
    }
    return `PF${futureIncome.contractid}`;
  }

  function goToPayment(futureIncome: IFutureIncomes) {
    if (!futureIncome.ispf) {
      navigate(`/contratos/pj/forma-de-pagamento/visualizar/${futureIncome.paymentid}`);
    } else {
      navigate(`/contratos/pf/forma-de-pagamento/visualizar/${futureIncome.paymentid}`);
    }
  }

  function goToContract(futureIncome: IFutureIncomes) {
    if (!futureIncome.ispf) {
      return `/contratos/pj/editar/${futureIncome.contractid}`;
    }
    return `/contratos/pf/visualizar/${futureIncome.contractid}`;
  }

  function goToHolder(futureIncome: IFutureIncomes) {
    if (!futureIncome.ispf) {
      return `/cliente/empresa/editar/${futureIncome.clientid}`;
    }
    return `/cliente/pessoa-fisica/editar-titular/${futureIncome.clientid}`;
  }

  function goToPage(page: number) {
    setProfitTableFilters({ ...profitTableFilters, actualPage: page });
  }

  function goToFirstPage() {
    setProfitTableFilters((prev) => ({ ...prev, actualPage: futureIncomes.meta.first_page }));
  }

  function generatePagination(totalOfPages: number) {
    const pagination: number[] = [];
    for (let i = 0; i < totalOfPages; i += 1) {
      pagination.push(i + 1);
    }
    return pagination;
  }

  function goToNextPage() {
    if (profitTableFilters.actualPage < futureIncomes.meta.last_page) {
      setProfitTableFilters((prev) => ({ ...prev, actualPage: futureIncomes.meta.current_page + 1 }));
    }
  }

  function goToLastPage() {
    setProfitTableFilters((prev) => ({ ...prev, actualPage: futureIncomes.meta.last_page }));
  }

  function calculateOutOfDate(income: IFutureIncomes) {
    const local = DateTime.local({ zone: 'America/Sao_Paulo' });
    const now = DateTime.fromObject({ day: local.day, month: local.month, year: local.year });
    const previsionToDateString = normalizeDate(income.prevision);
    const previsonToDate = DateTime.fromFormat(previsionToDateString, 'dd-MM-yyyy');

    if (previsonToDate.toSeconds() < now.toSeconds()) {
      return true;
    }
    return false;
  }

  function isDueValid(dueDateISO: string): boolean {
    const today = DateTime.fromFormat(DateTime.local({ zone: 'America/Sao_Paulo' }).toFormat('dd-MM-yyyy'), 'dd-MM-yyyy');
    const dueDate = DateTime.fromISO(dueDateISO, { zone: 'America/Sao_Paulo' }).startOf('day');

    if (dueDate.toMillis() >= today.toMillis()) {
      return true;
    }

    return false;
  }

  function showDeleteGalaxyChargePDF(installmentCtx: IFutureIncomes) {
    if (admin.id === 2 || admin.id === 3 || admin.id === 4 || admin.id === 18 || admin.id === 9 || admin.id === 7 || admin.id === 63 || admin.id === 9) {
      if ((installmentCtx.galaxpay_payment_method === 'boleto' || installmentCtx.galaxpay_payment_method === 'pix') && installmentCtx.status !== 'PAID') {
        return true;
      }
      return false;
    }
    return false;
  }

  function showGeneratePdfGalaxpayButton(item: IFutureIncomes) {
    if (item.gateway === 5 && item.galaxpay_payment_method === 'boleto') {
      return true;
    } if (item.gateway === 5 && item.noncardtaxes === 'BOLETO' && isDueValid(item.vencimento)) {
      return true;
    }
    return false;
  }

  function showGeneratePIXGalaxpayButton(item: IFutureIncomes) {
    if (item.gateway === 5 && item.galaxpay_payment_method === 'pix') {
      return true;
    }
    if (item.gateway === 5 && item.noncardtaxes === 'PIX' && isDueValid(item.vencimento)) {
      return true;
    }
    return false;
  }

  async function generateChargeGalaxpayPIX(installment: string, isPf: boolean) {
    try {
      setLoading(true);
      const response = await api.get(`/admin/galaxpay/${isPf ? 'pix-pf' : 'pix-pj'}/installment/${installment}`, token);
      window.open(response.data, '_blank');
      setTimeout(() => window.location.reload(), 1000);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível gerar pix para essa fatura.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
    }
  }

  async function generateChargeGalaxpayPDF(installment: string, isPf: boolean) {
    try {
      setLoading(true);
      const response = await api.get(`/admin/galaxpay/${isPf ? 'boleto-pf' : 'boleto-pj'}/installment/${installment}`, token);
      window.open(response.data, '_blank');
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível gerar boleto para essa fatura.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.DANGER }] });
    }
  }

  useEffect(() => {
    if (profitTableFilters.dueDateFinal !== '' || profitTableFilters.dueDateInitial !== '' || profitTableFilters.previsionInitalDate !== '' || profitTableFilters.previsionFinalDate !== '') {
      fetchByDates();
    } else {
      fetchFutureIncomes();
    }
  }, [profitTableFilters]);

  return (
    <DefaultLayout
      pageTitle="Financeiro > Entradas previstas"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="col-12 mb-2">
        <div className="d-flex justify-content-end">
          <div className="fetched-values-group">
            <div className="income-fetched-value-wrapper">
              <p className="" style={{ marginRight: 24 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#16C784" />
                  <path d="M11.4758 19H12.5547L12.5598 17.7477C14.743 17.5781 15.9949 16.2875 16 14.4555C15.9949 12.5141 14.5038 11.6281 12.9364 11.2289L12.6005 11.1359L12.6209 8.01328C13.4351 8.16641 13.9593 8.69141 14.0407 9.47891H15.8575C15.827 7.73984 14.5547 6.47109 12.631 6.27422L12.6361 5H11.5573L11.5522 6.27422C9.64377 6.48203 8.26972 7.73984 8.27481 9.55C8.27481 11.1578 9.33842 12.082 11.0534 12.5578L11.5115 12.6836L11.4911 15.9922C10.5802 15.8391 9.92366 15.2812 9.85751 14.2914H8C8.07125 16.3422 9.38422 17.5617 11.4809 17.7477L11.4758 19ZM12.57 15.9922L12.5903 12.9898C13.5165 13.2797 14.1069 13.6625 14.112 14.4391C14.1069 15.2484 13.5013 15.8281 12.57 15.9922ZM11.5216 10.8406C10.8193 10.6 10.1781 10.2008 10.1883 9.42422C10.1934 8.71875 10.687 8.17188 11.542 8.01328L11.5216 10.8406Z" fill="white" />
                </svg>
                Total Bruto:
                {' '}
                <span className="">{`R$ ${futureIncomes.meta.valBruto.toFixed(2)}`}</span>
              </p>
            </div>
            <div className="income-fetched-value-wrapper">
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#16C784" />
                  <path d="M11.4758 19H12.5547L12.5598 17.7477C14.743 17.5781 15.9949 16.2875 16 14.4555C15.9949 12.5141 14.5038 11.6281 12.9364 11.2289L12.6005 11.1359L12.6209 8.01328C13.4351 8.16641 13.9593 8.69141 14.0407 9.47891H15.8575C15.827 7.73984 14.5547 6.47109 12.631 6.27422L12.6361 5H11.5573L11.5522 6.27422C9.64377 6.48203 8.26972 7.73984 8.27481 9.55C8.27481 11.1578 9.33842 12.082 11.0534 12.5578L11.5115 12.6836L11.4911 15.9922C10.5802 15.8391 9.92366 15.2812 9.85751 14.2914H8C8.07125 16.3422 9.38422 17.5617 11.4809 17.7477L11.4758 19ZM12.57 15.9922L12.5903 12.9898C13.5165 13.2797 14.1069 13.6625 14.112 14.4391C14.1069 15.2484 13.5013 15.8281 12.57 15.9922ZM11.5216 10.8406C10.8193 10.6 10.1781 10.2008 10.1883 9.42422C10.1934 8.71875 10.687 8.17188 11.542 8.01328L11.5216 10.8406Z" fill="white" />
                </svg>
                Total Líquido:
                {' '}
                <span className="">{`R$ ${futureIncomes.meta.valLiquido.toFixed(2)}`}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row new-sellers card-bg p-4">
        <h1>Entradas previstas</h1>
        <div className="table-wrapper">
          <div className="table-actions">
            <div className="d-flex align-items-center">
              <div className="m-2">
                <button className="table-btn-primary mb-2" type="button" onClick={() => null} data-bs-toggle="modal" data-bs-target="#profitFilter">
                  <span className="material-icons-round">
                    filter_alt
                  </span>
                  Filtro
                </button>
              </div>
              {profitTableFilters.previsionInitalDate !== '' || profitTableFilters.previsionFinalDate !== '' || profitTableFilters.dueDateInitial !== '' || profitTableFilters.dueDateFinal !== '' ? (
                <div className="m-2">
                  <button className="table-btn-danger mb-2" type="button" onClick={() => removeFilters()}>
                    <span className="material-icons-round">
                      delete
                    </span>
                    Remover filtro(s)
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <table className="table table-borderless">
            <thead>
              <tr>
                <th scope="col">Contrato</th>
                <th scope="col">Titular</th>
                <th scope="col">Parcela</th>
                <th scope="col">Projeção</th>
                <th scope="col">Vencimento</th>
                <th scope="col">Status</th>
                <th scope="col">Gateway</th>
                <th scope="col">Método</th>
                <th scope="col">V. Bruto</th>
                <th scope="col">V. Líquido</th>
                <th className="text-end" scope="col">Ações</th>
              </tr>
            </thead>
            <tbody>
              {futureIncomes.data.length > 0 ? futureIncomes.data.map((item, i) => (
                <tr key={i}>
                  <td style={{ fontSize: 12, fontWeight: 'bold' }}><Link to={goToContract(item)}>{showIdBasedOnClientType(item)}</Link></td>
                  <td style={{ fontSize: 12, fontWeight: 'bold' }}><Link to={goToHolder(item)}>{showFirstName(item.titular)}</Link></td>
                  <td style={{ fontSize: 12, fontWeight: 'bold' }}>{item.numparcela}</td>
                  <td style={{ fontSize: 12, fontWeight: 'bold' }}>{normalizeDate(item.prevision)}</td>
                  <td style={{ fontSize: 12, fontWeight: 'bold' }}>{normalizeDate(item.vencimento)}</td>
                  <td style={{ fontSize: 12, fontWeight: 'bold' }}>{calculateOutOfDate(item) ? <p className="bg-danger text-center">Passou do prazo</p> : <p className="bg-alert text-center">No prazo</p>}</td>
                  <td style={{ fontSize: 12, fontWeight: 'bold' }}>{showGatewayNameById(item.gateway)}</td>
                  <td style={{ fontSize: 12, fontWeight: 'bold' }}>{showMethod(item)}</td>
                  <td style={{ fontSize: 12, fontWeight: 'bold' }}>{`R$ ${item.valor.toFixed(2)}`}</td>
                  <td style={{ fontSize: 12, fontWeight: 'bold' }}>{`R$ ${item.valorliquido.toFixed(2)}`}</td>
                  <td style={{ fontSize: 12, fontWeight: 'bold' }} align="right">
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="table-action-icon">
                        {showDeleteGalaxyChargePDF(item) && (
                        <>
                          <div className="table-action-icon">
                            <button
                              type="button"
                              onClick={() => null}
                              aria-describedby="delete pdf"
                              data-bs-toggle="modal"
                              data-bs-target={`#cancel-charge-pdf-${item.installmentid}`}
                              id="delete-pdf"
                            >
                              <span className="material-icons-round" style={{ color: 'orange' }}>
                                cancel
                              </span>
                            </button>
                            <Tooltip id="delete-pdf" text="Essa ação irá cancelar uma cobrança na galaxpay." />
                          </div>
                          <CancelGalaxPayChargeModal
                            id={`cancel-charge-pdf-${item.installmentid}`}
                            title="Cancelar boleto"
                            isPf
                            galaxpayInstallmentId={item.galaxpay_installment_id ? item.galaxpay_installment_id : ''}
                            fetchPayment={() => fetchFutureIncomes()}
                          />
                        </>
                        )}

                        {showGeneratePIXGalaxpayButton(item) && (
                          <div className="table-action-icon">
                            <button
                              type="button"
                              onClick={() => generateChargeGalaxpayPIX(item.installmentid.toString(), item.ispf)}
                              aria-describedby="baixa"
                              id="generate-pix"
                            >
                              <span className="material-icons-round">
                                qr_code_2
                              </span>
                            </button>
                            <Tooltip id="generate-pix" text="Essa ação irá gerar/imprimir um pix." />
                          </div>
                        )}
                        {showGeneratePdfGalaxpayButton(item) && (
                        <div className="table-action-icon">
                          <button
                            type="button"
                            onClick={() => generateChargeGalaxpayPDF(item.installmentid.toString(), item.ispf)}
                            aria-describedby="baixa"
                            id="generate-pdf"
                          >
                            <span className="material-icons-round">
                              picture_as_pdf
                            </span>
                          </button>
                          <Tooltip id="generate-pdf" text="Essa ação irá gerar/imprimir um boleto." />
                        </div>
                        )}
                      </div>
                      <div className="table-action-icon">
                        <button
                          type="button"
                          onClick={() => {
                            setModal({ title: 'Realizar baixa manual', children: 'Deseja mesmo realizar a baixa manual dessa fatura?', actions: [{ label: 'Realizar baixa', type: ModalActionType.PRIMARY, onClick: () => manualInstallmentPayment(item) }, { label: 'Cancelar', type: ModalActionType.DANGER, onClick: () => setModal(null) }] });
                          }}
                          aria-describedby="manual"
                          id="baixa-manual"
                        >
                          <span className="material-icons-round">
                            credit_score
                          </span>
                        </button>
                        <Tooltip id="baixa-manual" text="Essa ação irá realizar a baixa manual." />
                      </div>
                      <div className="table-action-icon">
                        <button type="button" onClick={() => goToPayment(item)}>
                          <span className="material-icons-round">
                            more
                          </span>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )) : (
                <p>Não existem entradas futuras cadastradas.</p>
              )}
            </tbody>
          </table>
          <div className="table-footer">
            <button type="button" onClick={() => goToFirstPage()}>Primeira</button>
            <p>Página</p>
            <select style={{ minWidth: 300 }} value={futureIncomes.meta.current_page} onChange={(e) => goToPage(Number(e.target.value))}>
              {generatePagination(futureIncomes.meta.last_page).map((item) => <option value={item}>{item}</option>)}
            </select>
            <p>
              de
              {' '}
              <strong>{futureIncomes.meta.last_page}</strong>
            </p>
            <button
              type="button"
              onClick={() => goToNextPage()}
            >
              Próxima
            </button>
            <button
              type="button"
              onClick={() => goToLastPage()}
            >
              Última
            </button>
          </div>
        </div>
      </div>
      <FutureIncomesModalFilter
        dueInitialDate={initialDueProfitDate}
        dueFinalDate={finalDueProfitDate}
        setDueInitialDate={(data) => setInitialDueProfitDate(data)}
        setDueFinalDate={(data) => setFinalDueProfitDate(data)}
        projectionInitialDate={initialPrevisionProfitDate}
        projectionFinalDate={finalPrevisionProfitDate}
        setProjectionInitialDate={(data) => setInitialPrevisionProfitDate(data)}
        setProjectionFinalDate={(data) => setFinalPrevisionProfitDate(data)}
        projectionDateActive={projectionDateActive}
        setProjectionDateActive={() => removePrevisionDateFilters()}
        dueDateActive={dueDateActive}
        setDueDateActive={() => removeDueDateFilters()}
        removeAllFilters={() => removeFilters()}
        filter={() => filterByDate()}
      />
    </DefaultLayout>
  );
}
