/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import AddInput from '../../components/AddInput';
import BasicInput from '../../components/BasicInput';
import BasicOption from '../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import CardGold, { ICard } from '../../components/Card/Card';
import Modal, { ModalActionType, ModalProps } from '../../components/Modal';
import SwitcherPrimary from '../../components/SwitcherPrimary';
import TextAreaInput from '../../components/TextAreaInput';
import api from '../../config/axios';
import { DependentProps } from '../../dto/dependent';
import HolderProps, { BlockHistoryHolderOrDependentProps } from '../../dto/holder';
import rgEmittent from '../../dto/rgEmittent';
import { statesOfBrazil } from '../../dto/states';
import CEPFinder from '../../helpers/findAddressByCep';
import {
  maskCep, maskCPF, maskCPForCNPJ, maskDate, maskNumber, maskPhone, unmaskData,
} from '../../helpers/inputMasks.js';
import showApiErrors from '../../helpers/showApiErrors';
import { validateCnpjOrCpf, validateCpf } from '../../helpers/validate';
import DefaultLayout from '../../Layout';
import BlockClientModal from './BlockClientModal';
import Tooltip from '../../components/Tooltip';
import BlockPermaDependentModal from './BlockPermaDependentModal';
import UnblockClientModal from '../../components/UnblockPerma';
import MigrationModal from '../../components/MigrationModal';
import UpdateUserModal from '../../components/UpdateUserModal';

export default function EditHolderPF() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [appData, setAppData] = useState<{
    cpf: string,
    email: string
  }>();
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [blockReason, setBlockReason] = useState('');
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [blockModalRef] = useState(React.createRef<any>());
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [holder, setHolder] = useState<HolderProps>({
    uuid: '',
    id: 1,
    cpf: '',
    name: '',
    cardname: '',
    rg: '',
    emitent: '',
    blockPerma: false,
    blockTemp: false,
    blockPermaReason: null,
    birthday: '',
    addressStreet: '',
    addressNumber: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    sex: '',
    addressIbge: '',
    email: '',
    instagram: '',
    addressState: '',
    addressZipcode: '',
    phone: '',
    observation: '',
    isActive: false,
    enableTelemedicine: false,
    dependent: [],
    blockHistory: [],
    printedCards: [],
  });

  const [cardData, setCardData] = useState<ICard>();
  const [isCardFront, setCardFront] = useState(false);

  function enabledButton() {
    if (holder.name.length > 0
        && validateCpf(holder.cpf)
        && holder.addressStreet.length > 0
        && holder.addressNumber.length > 0
        && holder.addressNeighborhood.length > 0
        && holder.addressCity.length > 0
        && holder.addressCity.length > 0
        && holder.addressZipcode.length >= 10
        && holder.phone.length > 2
    ) {
      return true;
    }
    return false;
  }

  async function setBlock() {
    try {
      setLoading(true);
      await api.post('admin/holder-block', {
        id: Number(id),
        reason: blockReason,
      }, token);
      navigate('/cliente/pf/bloqueados-permanentemente');
      setLoading(false);
    } catch (err: any) {
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível bloquear permanentemente esse cliente.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      setLoading(false);
    }
  }

  async function changeAddressByCep() {
    try {
      setLoading(true);
      const findCep = await new CEPFinder(unmaskData(holder.addressZipcode)).findCep();
      if (findCep !== null) {
        setHolder({
          ...holder,
          addressCity: findCep.city,
          addressState: findCep.state,
          addressNeighborhood: findCep.neighborhood,
          addressStreet: findCep.street,
          addressIbge: findCep.ibge,
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  async function fetchCard(cpf: string) {
    try {
      setLoading(true);
      const response = await api.post('/admin/card-generator/', { cpf }, token);
      console.log(response.data);
      setCardData(response.data.card);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
    }
  }

  async function fetchHolder() {
    try {
      setLoading(true);
      const response = await api.get(`/admin/holder-pf/${id}`, token);
      setHolder({
        ...response.data,
        cpf: maskCPF(response.data.cpf),
        addressZipcode: maskCep(response.data.addressZipcode),
        phone: maskPhone(response.data.phone),
        phone2: response.data.phone2 ? maskPhone(response.data.phone2) : '',
        observation: response.data.observation || '',
      });
      await fetchCard(response.data.cpf);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setModal({ title: 'Ocorreu um erro', children: 'Titular não encontrado.', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
      setLoading(false);
    }
  }

  async function saveHolder() {
    try {
      setLoading(true);
      await api.put(`/admin/holder-pf/${holder.id}`, {
        ...holder,
        cpf: unmaskData(holder.cpf),
        addressZipcode: unmaskData(holder.addressZipcode),
        phone: unmaskData(holder.phone),
        phone2: holder.phone2 && holder.phone2.length > 0 ? unmaskData(holder.phone2) : null,
        instagram: holder.instagram ? holder.instagram : null,
        email: holder.email ? holder.email : null,
        cardname: holder.cardname || null,
        observation: holder.observation && holder.observation.length > 0 ? holder.observation : null,
      }, token);
      await fetchHolder();
      setLoading(false);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      if (typeof err.response !== 'undefined') {
        if (err.response.status === 422) {
          setModal(showApiErrors(setModal, err.response.data.errors));
        }
      } else {
        setModal({ title: 'Ocorreu um erro ao atualizar', children: 'Não foi possível atualizar dados do cliente.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }
      console.log(err.response);
      setLoading(false);
    }
  }

  function isFormReadOnly() {
    return holder.blockPerma ?? false;
  }

  function resolveBlockStyle(block: BlockHistoryHolderOrDependentProps) {
    if (block.type === 'perma' && block.input === 'in') {
      return 'td-block-perma';
    }
    if (block.type === 'perma' && block.input === 'out') {
      return 'td-unblock';
    }
    if (block.input === 'out') {
      return 'td-unblock';
    }
    return 'td-block-temp';
  }

  async function fetchCpf() {
    try {
      setLoading(true);
      const response = await api.post('admin/manage/user', {
        cpf: unmaskData(holder.cpf),
      }, token);
      setAppData({ ...response.data });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchHolder();
  }, []);

  useEffect(() => {
    fetchCpf();
  }, [holder.cpf]);

  return (
    <>
      <DefaultLayout
        pageTitle="Clientes PF"
        loading={loading}
        showToastSuccess={showToastSuccess}
        showToastError={showToastError}
        showRightSlider={false}
        rightSliderContent={undefined}
        modal={modal}
        setCloseRightSlider={() => null}
      >
        <div className="row products mt-4">
          <div className="col-12 card-bg p-4">
            <div className="row">
              <h1>Editar cadastro do titular PF</h1>
              {holder.blockPerma && (
              <div className="p-4 mb-4 rounded" style={{ backgroundColor: '#6200EE' }}>
                <p className="text-center text-white">Esse usuário foi desativado PERMANENTEMENTE e seus dados estarão disponíveis apenas para leitura.</p>
                {holder.blockPermaReason && (
                <p className="text-center text-white mt-2">
                  {' '}
                  Motivo do desativamento:
                  {' '}
                  <strong>{holder.blockPermaReason}</strong>
                </p>
                )}
              </div>
              )}
              {typeof cardData !== 'undefined' && (
              <div className="row mb-4 card-wrapper">
                <CardGold
                  id="usercard"
                  uuid={holder.id}
                  registration={cardData.registration}
                  name={cardData.name}
                  cardname={cardData.cardname}
                  cpf={cardData.cpf}
                  expiration={cardData.expiration}
                  birth={cardData.birth}
                  modality={cardData.modality}
                  category={cardData.category}
                  front={isCardFront}
                  setCardSide={() => setCardFront((prev) => !prev)}
                  isHolder
                  printedCards={holder.printedCards!}
                />
              </div>
              )}
              <div className="col-4">
                <BasicInput
                  id="name-input"
                  label="Nome"
                  readOnly={isFormReadOnly()}
                  helper="Ex: João Batista"
                  error={holder.name.length < 1 ? 'Campo Obrigatório' : ''}
                  value={holder.name}
                  type="text"
                  onChange={(data) => setHolder({ ...holder, name: data })}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="card-name-input"
                  label="Nome no cartão"
                  readOnly={isFormReadOnly()}
                  helper="Ex: João B. C. Vieira"
                  error=""
                  value={holder.cardname === null ? '' : holder.cardname}
                  type="text"
                  onChange={(data) => setHolder({ ...holder, cardname: data })}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="cpf-input"
                  label="CPF"
                  readOnly={isFormReadOnly()}
                  helper="Ex: 000.000.000-10"
                  error={!validateCnpjOrCpf(holder.cpf) ? 'Digite um CPF válido.' : ''}
                  value={holder.cpf}
                  type="text"
                  onChange={(data) => setHolder({ ...holder, cpf: maskCPForCNPJ(data) })}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="rg-input"
                  label="RG"
                  readOnly={isFormReadOnly()}
                  helper="Ex: 99999999"
                  error=""
                  value={holder.rg}
                  type="text"
                  onChange={(data) => setHolder({ ...holder, rg: maskNumber(data) })}
                />
              </div>
              <div className="col-4">
                <BasicOption
                  id="rg-emittent-input"
                  label="RG / Emissor"
                  readOnly={isFormReadOnly()}
                  helper="Ex: SSP/MA"
                  error=""
                  value={holder.emitent}
                  onChange={(data) => setHolder({ ...holder, emitent: data })}
                >
                  {rgEmittent}
                </BasicOption>
              </div>
              <div className="col-4">
                <BasicOption
                  id="sex-input"
                  label="Sexo"
                  readOnly={isFormReadOnly()}
                  helper="Ex: Masculino / Feminino"
                  error=""
                  value={holder.sex === 'm' ? 'Masculino' : 'Feminino'}
                  onChange={(data) => setHolder({ ...holder, sex: data === 'Masculino' ? 'm' : 'f' })}
                >
                  {['Masculino', 'Feminino']}
                </BasicOption>
              </div>
              <div className="col-4">
                <BasicInput
                  id="birth-input"
                  label="DT. Nascimento"
                  readOnly={isFormReadOnly()}
                  helper="Ex: 13-01-1990"
                  error={holder.birthday.length < 1 ? 'Campo obrigatório' : ''}
                  value={holder.birthday}
                  type="text"
                  onChange={(data) => setHolder({ ...holder, birthday: maskDate(data) })}
                />
              </div>
              <div className="col-4">
                <AddInput
                  id="address-cep-input"
                  label="CEP"
                  readOnly={isFormReadOnly()}
                  helper="Ex: 35.171-120"
                  error={holder.addressZipcode.length < 10 ? 'Campo obrigatório' : ''}
                  value={holder.addressZipcode}
                  type="text"
                  onAdd={() => changeAddressByCep()}
                  onChange={(data) => setHolder({ ...holder, addressZipcode: maskCep(data) })}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="address-street-input"
                  label="Rua"
                  readOnly={isFormReadOnly()}
                  helper="Ex: Rua Três"
                  error={holder.addressStreet.length < 2 ? 'Campo Obrigatório' : ''}
                  value={holder.addressStreet}
                  type="text"
                  onChange={(data) => setHolder({ ...holder, addressStreet: data })}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="address-number-input"
                  label="Número"
                  readOnly={isFormReadOnly()}
                  helper="Ex: 133"
                  error={holder.addressNumber.length < 1 ? 'Campo Obrigatório' : ''}
                  value={holder.addressNumber}
                  type="text"
                  onChange={(data) => setHolder({ ...holder, addressNumber: data })}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="address-complement-input"
                  label="Complemento"
                  readOnly={isFormReadOnly()}
                  helper="Ex: Bloco A"
                  error=""
                  value={holder.addressComplement}
                  type="text"
                  onChange={(data) => setHolder({ ...holder, addressComplement: data })}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="address-neighborhood-input"
                  label="Bairro"
                  readOnly={isFormReadOnly()}
                  helper="Centro"
                  error={holder.addressNeighborhood.length < 1 ? 'Campo obrigatório' : ''}
                  value={holder.addressNeighborhood}
                  type="text"
                  onChange={(data) => setHolder({ ...holder, addressNeighborhood: data })}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="address-city-input"
                  label="Cidade"
                  readOnly
                  helper="Ex: Imperatriz"
                  error={holder.addressCity.length < 1 ? 'Campo obrigatório' : ''}
                  value={holder.addressCity}
                  type="text"
                  onChange={(data) => setHolder({ ...holder, addressCity: data })}
                />
              </div>
              <div className="col-4">
                <BasicOption
                  id="address-state-input"
                  label="Estado"
                  readOnly
                  helper=""
                  error=""
                  value={holder.addressState}
                  onChange={(data) => setHolder({ ...holder, addressState: data })}
                >
                  {statesOfBrazil}
                </BasicOption>
              </div>
              <div className="col-4">
                <BasicInput
                  id="phone-input"
                  label="Telefone"
                  readOnly={isFormReadOnly()}
                  helper="Ex: (31) 99999 - 9999"
                  error={holder.phone.length < 1 ? 'Campo obrigatório' : ''}
                  value={holder.phone}
                  type="text"
                  onChange={(data) => setHolder({ ...holder, phone: maskPhone(data) })}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="phone2-input"
                  label="Telefone 2"
                  readOnly={false}
                  helper="Ex: (31) 99999 - 9999"
                  error=""
                  value={holder.phone2 || ''}
                  type="text"
                  onChange={(data) => setHolder({ ...holder, phone2: maskPhone(data) })}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="email-input"
                  label="email"
                  readOnly={false}
                  helper="Ex: usuario@usuario.com"
                  error={holder.email && holder.email.length > 0 && !EmailValidator.validate(holder.email) ? 'E-mail inválido' : ''}
                  value={holder.email ? holder.email : ''}
                  type="text"
                  onChange={(data) => setHolder({ ...holder, email: data })}
                />
              </div>
              <div className="col-4">
                <BasicInput
                  id="instagram-input"
                  label="instagram"
                  readOnly={false}
                  helper="Ex: valdecir_roberto"
                  error=""
                  value={holder.instagram ? holder.instagram : ''}
                  type="text"
                  onChange={(data) => setHolder({ ...holder, instagram: data })}
                />
              </div>

              <div className="col-12">
                <TextAreaInput
                  id=""
                  label="Observação"
                  readOnly={false}
                  helper=""
                  error=""
                  value={holder.observation || ''}
                  onChange={(data) => setHolder({ ...holder, observation: data })}
                />
              </div>

              {!holder.blockPerma && (
              <>
                <div className="col-12 optionals mt-4 mb-4">
                  <p>Opcionais</p>
                  <div className="check-wrapper">
                    <input type="checkbox" checked={holder.enableTelemedicine} onChange={() => setHolder({ ...holder, enableTelemedicine: !holder.enableTelemedicine })} />
                    <p>Telemedicina</p>
                  </div>
                </div>
                <div className="col-12 footer">
                  {/* <ButtonTextIcon
                  label="DESATIVAR PERMANENTEMENTE"
                  type={BtnType.DANGER}
                  icon="backup"
                  active={enabledButton()}
                  onClick={() => { blockModalRef.current.click(); }}
                  ref={null}
                /> */}
                  <ButtonTextIcon
                    label="salvar"
                    type={BtnType.PRIMARY}
                    icon="backup"
                    active={enabledButton()}
                    onClick={() => saveHolder()}
                    ref={null}
                  />
                </div>

              </>
              )}

            </div>
          </div>
        </div>

        <button className="d-none" type="button" data-bs-toggle="modal" data-bs-target="#blockModal" ref={blockModalRef}> open modal </button>

        <BlockClientModal
          blockReason={blockReason}
          setBlockReason={(data) => setBlockReason(data)}
          setBlock={() => setBlock()}
        />

        <div className="row products mt-4">
          <div className="col-12 card-bg p-4">
            <div className="row">
              <h1>Dependentes</h1>
              <div className="table-wrapper">
                {!holder.blockPerma && (
                <div className="table-actions">
                  <button className="table-btn-primary" type="button" onClick={() => navigate(`/cliente/adicionar-dependente-pf/${holder.id}`)}>
                    <span className="material-icons-round">
                      add
                    </span>
                    adicionar
                  </button>
                </div>
                ) }

                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Nome</th>
                      <th className="text-end" scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {holder.dependent.length < 1 ? (
                      <p>Nenhum dependente cadastrado</p>
                    )
                      : holder.dependent.map((item, i) => (
                        <tr key={i}>
                          <td className={item.blockPerma ? 'link-red' : (item.blockTemp ? 'link-orange' : '')}>{item.name}</td>
                          <td align="right">
                            <div className="d-flex justify-content-end align-items-center">
                              {/* <SwitcherPrimary
                              id={`status-changer-${i}`}
                              onChange={() => setDependentStatus(item)}
                              isActive={item.isActive}
                            /> */}
                              <div className="table-action-icon">
                                <button
                                  type="button"
                                  aria-describedby="manual"
                                  id="unblock-perma"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#migration-modal-${item.id}`}
                                >
                                  <span className="material-icons-round" style={{ color: 'orange' }}>
                                    transfer_within_a_station
                                  </span>
                                </button>
                                <Tooltip id="bloq-perma" text="Essa ação irá migrar o usuário" />
                                <MigrationModal id={`migration-modal-${item.id}`} dependentUuid={item.uuid} type="dependentpf" name={item.name} />
                              </div>
                              {!item.blockPerma && (
                              <>
                                <div className="table-action-icon">
                                  <button
                                    type="button"
                                    onClick={() => null}
                                    aria-describedby="manual"
                                    id="bloq-perma-dependent"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#bloq-perma-dependent-${item.uuid}`}
                                  >
                                    <span className="material-icons-round" style={{ color: 'red' }}>
                                      cancel
                                    </span>
                                  </button>
                                  <Tooltip id="bloq-perma-dependent" text="Essa ação irá cancelar o dependente." />
                                </div>
                                <BlockPermaDependentModal
                                  id={item.uuid}
                                  setLoading={(arg) => setLoading(arg)}
                                />
                              </>
                              )}
                              {item.blockPerma && (
                              <div className="table-action-icon">
                                <button
                                  type="button"
                                  aria-describedby="manual"
                                  id="unblock-perma"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#unblock-perma-${item.id}`}
                                >
                                  <span className="material-icons-round" style={{ color: 'orange' }}>
                                    lock_open
                                  </span>
                                </button>
                                <Tooltip id="bloq-perma" text="Essa ação irá desbloquear o usuário." />
                                <UnblockClientModal type="dependentpf" userId={item.uuid.toString()} id={`unblock-perma-${item.id}`} />
                              </div>
                              )}
                              <div className="table-action-icon">
                                <button type="button" onClick={() => navigate(`/cliente/editar-dependente-pf/${item.uuid}`)}>
                                  <span className="material-icons-round">
                                    more
                                  </span>
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row products mt-4">
          <div className="col-12 p-0 card-bg p-4">
            <h1>Histórico de bloqueios / desbloqueios</h1>
            <div className="table-wrapper">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Bloqueio / Cancelamento</th>
                    <th scope="col">Data</th>
                    <th scope="col">Motivo</th>
                    <th scope="col">Responsável</th>
                  </tr>
                </thead>
                <tbody>
                  {holder.blockHistory.length > 0 ? holder.blockHistory.map((item, i) => (
                    <tr key={i}>
                      <td className={resolveBlockStyle(item)}>{(item.input === 'in' ? 'Bloqueio' : 'Desbloqueio')}</td>
                      <td className={resolveBlockStyle(item)}>{item.blockDate}</td>
                      <td className={resolveBlockStyle(item)}>{item.reason}</td>
                      <td className={resolveBlockStyle(item)}>{item.blockedBy}</td>
                    </tr>
                  )) : (
                    <p>Não existe(m) bloqueio(s) para esse titular.</p>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row products mt-4">
          <div className="col-12 p-0 card-bg p-4">
            <h1>Alterar acesso ao APP</h1>
            <div className="table-wrapper">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">CPF</th>
                    <th scope="col">Email</th>
                    <th scope="col" className="text-end" align="right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {appData ? (
                      <>
                        <td>{maskCPF(appData.cpf)}</td>
                        <td>{appData.email}</td>
                        <td align="right">
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="table-action-icon">
                              <button type="button" data-bs-toggle="modal" data-bs-target="#updateModal">
                                <span className="material-icons-round">
                                  more
                                </span>
                              </button>
                            </div>
                          </div>
                        </td>

                      </>
                    ) : (
                      <td>Cliente ainda nao realizou o cadastro no website!</td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </DefaultLayout>
      {appData && (
      <UpdateUserModal emailProps={appData.email} cpf={maskCPF(appData.cpf)} />
      )}
    </>
  );
}
