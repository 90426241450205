/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import SwitcherPrimary from '../../../components/SwitcherPrimary';
import Tooltip from '../../../components/Tooltip';
import api from '../../../config/axios';
import { maskCPF, unmaskData } from '../../../helpers/inputMasks.js';
import { storeType } from '../../../store';

interface PageProps {
    setModal: (arg0: ModalProps | null) => void
    setLoading: (arg0: boolean) => void
    setShowToastSuccess: (arg0: boolean) => void
}

type MetaProps = {
    total: number,
    totalTitular: number,
    totalDependente: number,
    per_page: number,
    current_page: number,
    last_page: number,
    first_page: number
}

type DataProps = {
    name: string,
    id: number,
    isactive: boolean,
    cpf: string | null
    blocktemp: boolean,
    blockperma: boolean,
    uuid: string,
    isholder: boolean,
    contractid: number
}

type ResponseProps = {
    meta: MetaProps,
    data: DataProps[]
}

export default function LifesPF({ setModal, setLoading, setShowToastSuccess }: PageProps) {
  const admin = useSelector(({ adminReducer }: storeType) => adminReducer);
  const [searchString, setSearchString] = useState('');
  const [lifes, setLifes] = useState<ResponseProps>({
    meta: {
      total: 0,
      totalDependente: 0,
      totalTitular: 0,
      per_page: 50,
      current_page: 1,
      last_page: 1,
      first_page: 1,
    },
    data: [],
  });
  const [filters, setFilters] = useState('Todos');
  const [actualPage, setActualPage] = useState(1);
  const [searchType, setSearchType] = useState('Nome');

  async function setActive(item: DataProps) {
    return null;
  }

  function generateProfileLink(data: DataProps) {
    if (data.isholder) {
      return `/cliente/pessoa-fisica/editar-titular/${data.id}`;
    }
    return `/cliente/editar-dependente-pf/${data.uuid}`;
  }

  function generateContractLink(data: DataProps) {
    return `/contratos/pf/visualizar/${data.contractid}`;
  }

  function generateFilterObj() {
    // 'Todos', 'Ativos', 'Bloq Temporariamente', 'Cancelados']
    if (filters === 'Todos') {
      return {};
    }
    if (filters === 'Ativos') {
      return {
        active: true,
      };
    }
    if (filters === 'Bloq Temporariamente') {
      return {
        blockTemp: true,
      };
    }
    return {
      blockPerma: true,
    };
  }

  async function fetchData() {
    try {
      setLoading(true);
      const response = await api.post('/admin/lifes-info/pf', {
        name: searchString === '' || searchType === 'CPF' ? undefined : searchString,
        cpf: searchString === '' || searchType === 'Nome' ? undefined : unmaskData(searchString),
        currentPage: actualPage,
        ...generateFilterObj(),
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      setLifes(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível buscar dados', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      setLoading(false);
    }
  }

  async function fetchByFilter() {
    try {
      setLoading(true);
      const response = await api.post('/admin/lifes-info/pf', {
        name: searchString === '' || searchType === 'CPF' ? undefined : searchString,
        cpf: searchString === '' || searchType === 'Nome' ? undefined : unmaskData(searchString),
        currentPage: 1,
        ...generateFilterObj(),
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      setLifes(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível buscar dados', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      setLoading(false);
    }
  }

  function generatePagination(totalOfPages: number) {
    const pagination: number[] = [];
    for (let i = 0; i < totalOfPages; i += 1) {
      pagination.push(i + 1);
    }
    return pagination;
  }

  function goToNextPage() {
    if (actualPage < lifes.meta.last_page) {
      setActualPage((prev) => prev + 1);
    }
  }

  async function setActiveHolder(client: DataProps) {
    try {
      setLoading(true);
      await api.put(`/admin/holder-pf/${client.id}`, {
        isActive: !client.isactive,
      }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      fetchData();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      if (typeof err.response !== 'undefined') {
        setModal({ title: 'Ocorreu um erro', children: 'Não foi possível alterar status do titular.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }
      console.log(err.response);
      setLoading(false);
    }
  }

  async function setActiveDependent(dependent: DataProps) {
    try {
      setLoading(true);
      await api.put(`/admin/dependent/${dependent.uuid}`, { registration: null, isActive: !dependent.isactive }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      fetchData();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log(err.response.data);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível alterar o status do dependente.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  useEffect(() => {
    fetchData();
  }, [actualPage]);

  return (
    <>
      <div className="row mb-2">
        <div className="col-12">
          <div className="d-flex justify-content-end mt-4">
            <div className="fetched-values-group">
              <div className="income-fetched-value-wrapper px-2">
                <p>
                  Total:
                  {' '}
                  <span className="text-primary">{`${lifes.meta.total}`}</span>
                </p>
              </div>
              <div className="income-fetched-value-wrapper px-2">
                <p>
                  Dependentes:
                  {' '}
                  <span className="text-primary">{`${lifes.meta.totalDependente}`}</span>
                </p>
              </div>
              <div className="income-fetched-value-wrapper px-2">
                <p>
                  Titulares:
                  {' '}
                  <span className="text-primary">{`${lifes.meta.totalTitular}`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row products mt-4">
        <div className="col-12 card-bg p-4">
          <div className="row">
            <h1>Vidas PF</h1>
            <div className="table-wrapper">
              <div className="table-actions">
                <div className="m-2 d-flex align-items-center">
                  <div className="mt-1 large-option">
                    <BasicOption
                      id="por"
                      label="Tipo"
                      readOnly={false}
                      helper=""
                      error=""
                      value={searchType}
                      onChange={(data) => {
                        setSearchString('');
                        setSearchType(data);
                      }}
                    >
                      {['Nome', 'CPF']}
                    </BasicOption>
                  </div>
                  <div className="mt-1">
                    <BasicInput
                      id="Busca-por-nome"
                      label={searchType === 'Nome' ? 'Nome' : 'CPF'}
                      readOnly={false}
                      helper=""
                      error=""
                      value={searchString}
                      type="text"
                      onChange={searchType === 'CPF' ? (data) => setSearchString(maskCPF(data)) : (data) => setSearchString(data)}
                    />
                  </div>
                  <div className="mt-1 large-option px-2">
                    <BasicOption
                      id="filtros"
                      label="Filtros"
                      readOnly={false}
                      helper=""
                      error=""
                      value={filters}
                      onChange={(data) => setFilters(data)}
                    >
                      {['Todos', 'Ativos', 'Bloq Temporariamente', 'Cancelados']}
                    </BasicOption>
                  </div>

                  <button className="table-btn-primary m-2" type="button" onClick={() => fetchByFilter()}>
                    <span className="material-icons-round">
                      search
                    </span>
                    filtrar
                  </button>
                </div>
              </div>
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Contrato</th>
                    <th scope="col">Classificação</th>
                    <th scope="col">CPF</th>
                    <th scope="col">Cancelado</th>
                    <th scope="col">Bloq. Temp.</th>
                    {/* {(admin.id === 2 || admin.id === 3) && (
                    <th className="text-end" scope="col">Ações</th>
                    )} */}
                  </tr>
                </thead>
                <tbody>
                  {lifes.data.length === 0 && 'Nenhum resultado para sua busca.'}
                  {lifes.data.map((item, i) => (
                    <tr key={i}>
                      <td><Link to={generateProfileLink(item)}>{item.name}</Link></td>
                      <td>
                        <Link to={generateContractLink(item)}>
                          PF
                          {item.contractid}
                        </Link>
                      </td>
                      <td>{item.isholder ? 'TITULAR' : 'DEPENDENTE'}</td>
                      <td>{item.cpf ? maskCPF(item.cpf) : 'N/A'}</td>
                      <td>{item.blockperma ? 'SIM' : 'NÃO'}</td>
                      <td>{item.blocktemp ? 'SIM' : 'NÃO'}</td>
                      <td align="right">
                        <div className="d-flex justify-content-end align-items-center">
                          {/* {(admin.id === 2 || admin.id === 3) && (
                            <SwitcherPrimary
                              id={`status-changer-${item.id}`}
                              onChange={item.isholder ? () => setActiveHolder(item) : () => setActiveDependent(item)}
                              isActive={item.isactive}
                            />
                          )} */}

                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="table-footer">
                <button type="button" onClick={() => setActualPage(1)}>Primeira</button>
                <p>Página</p>
                <select style={{ minWidth: 300 }} value={lifes.meta.current_page} onChange={(e) => setActualPage(Number(e.target.value))}>
                  {generatePagination(lifes.meta.last_page).map((item) => <option value={item}>{item}</option>)}
                </select>
                <p>
                  de
                  {' '}
                  <strong>{lifes.meta.last_page}</strong>
                </p>
                <button
                  type="button"
                  onClick={() => goToNextPage()}
                >
                  Próxima

                </button>
                <button
                  type="button"
                  onClick={() => setActualPage(lifes.meta.last_page)}
                >
                  Última

                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
