import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicOption from '../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import SwitcherPrimary from '../../components/SwitcherPrimary';
import api from '../../config/axios';
import { maskCPF } from '../../helpers/inputMasks.js';
import showApiErrors from '../../helpers/showApiErrors';
import DefaultLayout from '../../Layout';

type IndicatorResponse = {
  id: number;
  name: string;
  cpf: string;
  contactInfo: string;
  paymentDetails: string;
  isActive: boolean;
  category: CategoriesResponse[];
};

type CategoriesResponse = {
  id: number;
  name: string;
};

export default function Indicators() {
  const navigate = useNavigate();
  const [indicators, setIndicators] = useState<IndicatorResponse[]>([]);
  const [categories, setCategories] = useState<CategoriesResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [token] = useState({ headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` } });
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [filter, setFilter] = useState('TODOS');
  const [showActive, setShowActive] = useState(
    JSON.parse(localStorage.getItem('showActive_indicators') || 'true'),
  );
  const [showInactive, setShowInactive] = useState(
    JSON.parse(localStorage.getItem('showInactive_indicators') || 'false'),
  );

  async function fetchCategories() {
    try {
      setLoading(true);
      const response = await api.get('admin/indication-category', token);
      setCategories(response.data);
      setLoading(false);
    } catch (err: any) {
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível carregar categorias', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
      setLoading(false);
    }
  }

  async function fetchIndicators() {
    try {
      const obj: any = {};
      if (filter !== 'TODOS') {
        const selectedCategory = categories.find((item) => item.name === filter);
        if (selectedCategory) {
          obj.category = selectedCategory.id;
        }
      }
      setLoading(true);
      const response = await api.post('/admin/indication-all', obj, token);
      setIndicators(response.data);
      setLoading(false);
    } catch (err: any) {
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível carregar a lista de embaixadores', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
      setLoading(false);
    }
  }

  async function deleteIndicator(indicatorCtx: IndicatorResponse) {
    try {
      setLoading(true);
      setModal(null);
      await api.delete(`admin/indication/${indicatorCtx.id}`, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
      await fetchIndicators();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  async function setVisibility(indicatorCtx: IndicatorResponse) {
    try {
      setLoading(true);
      await api.put(`/admin/indication/${indicatorCtx.id}`, { isActive: !indicatorCtx.isActive }, token);
      fetchIndicators();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      if (err.response?.status === 422) {
        setModal(showApiErrors(setModal, err.response.data.errors));
      } else {
        setShowToastError(true);
        setTimeout(() => {
          setShowToastError(false);
        }, 3000);
        setModal({ title: 'Ocorreu um erro', children: 'Não foi possível alterar status.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCategories();
    fetchIndicators();
  }, [filter]);

  useEffect(() => {
    localStorage.setItem('showActive_indicators', JSON.stringify(showActive));
    localStorage.setItem('showInactive_indicators', JSON.stringify(showInactive));
  }, [showActive, showInactive]);

  const filteredIndicators = indicators.filter((indicatorCtx) => {
    if (showActive && showInactive) return true;
    if (showActive) return indicatorCtx.isActive;
    if (showInactive) return !indicatorCtx.isActive;
    return false;
  });

  return (
    <DefaultLayout
      pageTitle="Embaixadores"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Categorias</h1>
          <div className="table-wrapper">
            <div className="table-actions justify-content-start">
              <button className="table-btn-primary" type="button" onClick={() => navigate('/embaixador/adicionar-categoria')}>
                <span className="material-icons-round">
                  add
                </span>
                adicionar
              </button>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {categories.length > 0 ? categories.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <button type="button" onClick={() => navigate(`/embaixador/editar-categoria/${item.id}`)}>
                            <span className="material-icons-round">
                              more
                            </span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : (<p>Nenhuma categoria cadastrada</p>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Embaixadores</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <div className="d-flex align-items-center">
                <button className="table-btn-primary" type="button" onClick={() => navigate('/embaixador/adicionar')}>
                  <span className="material-icons-round">
                    add
                  </span>
                  adicionar
                </button>
                <div className="mt-1" style={{ width: 120 }}>
                  <BasicOption
                    id=""
                    label="Categoria"
                    readOnly={false}
                    helper=""
                    error=""
                    value={filter}
                    onChange={(data) => setFilter(data)}
                  >
                    {['TODOS'].concat(categories.map((item) => item.name))}
                  </BasicOption>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="mx-2 separ" />
                <div className="form-check lh-lg">
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    id="showInactive"
                    checked={showInactive}
                    onChange={() => setShowInactive(!showInactive)}
                  />
                  <label
                    className="form-check-label text-secondary mb-0 fs-7"
                    htmlFor="showInactive"
                  >
                    Mostrar Inativos
                  </label>
                </div>
              </div>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">Observações</th>
                  <th scope="col">Info. Contato</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {filteredIndicators.length > 0 ? filteredIndicators.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td style={{
                      maxWidth: '10vw', overflow: 'hidden', textOverflow: 'ellipsis',
                    }}
                    >
                      {item.paymentDetails}
                    </td>
                    <td>{item.contactInfo}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <button
                            type="button"
                            onClick={() => setModal({
                              title: 'Excluir Embaixador',
                              children: 'Deseja mesmo excluir? Essa será uma ação irreversível!',
                              actions: [
                                { label: 'Cancelar', onClick: () => setModal(null), type: ModalActionType.PRIMARY },
                                { label: 'Excluir', onClick: () => deleteIndicator(item), type: ModalActionType.DANGER },
                              ],
                            })}
                          >
                            <span className="material-icons-round text-danger">
                              delete
                            </span>
                          </button>
                        </div>
                        <SwitcherPrimary
                          id={`status-changer-${item.id}`}
                          onChange={() => setVisibility(item)}
                          isActive={item.isActive}
                        />
                        <div className="table-action-icon">
                          <button type="button" onClick={() => navigate(`/embaixador/editar/${item.id}`)}>
                            <span className="material-icons-round">
                              more
                            </span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : <p>Nenhum indicador cadastrado</p>}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
