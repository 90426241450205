import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import api from '../../../config/axios';
import DefaultLayout from '../../../Layout';

type NewCategoryProps = {
    id: number,
    title: string;
    isActive: boolean;
}

export default function EditExamsCategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [category, setCategory] = useState<NewCategoryProps>({
    id: 1000000000000,
    title: '',
    isActive: true,
  });

  function enableButton() {
    return category.title !== '';
  }

  async function saveCategory() {
    try {
      setLoading(true);
      await api.put(`admin/exams-category/${id}`, {
        title: category.title,
      }, token);
      setLoading(false);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível editar categoria', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  async function deleteCategory() {
    try {
      setLoading(true);
      await api.delete(`admin/exams-category/${id}`, token);
      setLoading(false);
      navigate(-1);
    } catch (err: any) {
      console.log(err.response);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível remover categoria', actions: [{ label: 'Ok, entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    }
  }

  async function fetchCategory() {
    try {
      setLoading(true);
      const response = await api.get(`admin/exams-category/${id}`, token);
      setCategory(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <DefaultLayout
      pageTitle="Visualizar Categoria de exames"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Categoria de exames</h1>
          <div className="row">
            <div className="col-4">
              <BasicInput
                id="title-input"
                label="Título"
                readOnly={false}
                helper="Ultrassonografia"
                error={category.title.length < 1 ? 'Campo obrigatório' : ''}
                value={category.title}
                type="text"
                onChange={(data) => setCategory({ ...category, title: data })}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Remover"
                type={BtnType.DANGER}
                icon="backup"
                active={enableButton()}
                onClick={() => deleteCategory()}
                ref={null}
              />
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                active={enableButton()}
                onClick={() => saveCategory()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>

    </DefaultLayout>
  );
}
