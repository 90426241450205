import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import SwitcherPrimary from '../../../components/SwitcherPrimary';
import api from '../../../config/axios';
import { maskCPF } from '../../../helpers/inputMasks.js';
import showApiErrors from '../../../helpers/showApiErrors';
import DefaultLayout from '../../../Layout';

type NewExamsCategoryProps = {
    id: number,
    title: string
}

export default function ShowExamsCategories() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<NewExamsCategoryProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [token] = useState({ headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` } });
  const [modal, setModal] = useState<ModalProps | null>(null);

  async function fetchCategories() {
    try {
      setLoading(true);
      const response = await api.get('/admin/exams-category/', token);
      setCategories(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível carregar categorias dos exames', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.PRIMARY }] });
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  if (categories.length < 1) {
    return (
      <DefaultLayout
        pageTitle="Categorias de Exames"
        loading={loading}
        showToastSuccess={showToastSuccess}
        showToastError={showToastError}
        showRightSlider={false}
        rightSliderContent={null}
        modal={modal}
        setCloseRightSlider={() => null}
      >
        <div className="not-exsits">
          <span className="material-icons-round">
            not_interested
          </span>
          <h3 className="table-not-exists">Hão há categorias de exames cadastrados.</h3>
          <ButtonTextIcon
            label="Adicionar categoria"
            type={BtnType.PRIMARY}
            icon="add"
            active
            onClick={() => navigate('/exames/categoria/criar')}
            ref={null}
          />
        </div>
      </DefaultLayout>
    );
  }
  return (
    <DefaultLayout
      pageTitle="Categorias de Exames"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row seller">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Categorias</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <button className="table-btn-primary" type="button" onClick={() => navigate('/exames/categoria/criar')}>
                <span className="material-icons-round">
                  add
                </span>
                adicionar
              </button>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((item, i) => (
                  <tr>
                    <td>{item.title}</td>

                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <button type="button" onClick={() => navigate(`/exames/categoria/editar/${item.id}`)}>
                            <span className="material-icons-round">
                              more
                            </span>

                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
