/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import download from 'downloadjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../../components/Modal';
import PriceInput from '../../../components/PriceInput';
import api from '../../../config/axios';
import { FreelancerSellersProps } from '../../../dto/freelancersSellers';
import { Indicators } from '../../../dto/indicators';
import { ProductsProps } from '../../../dto/product';
import { Reffer } from '../../../dto/reffers';
import SellerProps from '../../../dto/seller';
import { showIfDateOfParamsOneMajorThenParamsTwo } from '../../../helpers/dates';
import dataURIToBlob from '../../../helpers/fileReader';
import { maskDate } from '../../../helpers/inputMasks.js';
import DefaultLayout from '../../../Layout';
import { storeType } from '../../../store';
import ContractFiles from '../ContractFilesModal';
import { BlockHistoryProps, ClientPFProps, ContractPFProps } from './types';
import ArchivedContractDataModal from '../../../components/RenewedContractDataModal';

export default function EditContractPF() {
  const navigate = useNavigate();
  const admin = useSelector(({ adminReducer }: storeType) => adminReducer);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  /* */
  const [clients, setClients] = useState<ClientPFProps[]>([]);
  const [allSellers, setAllSellers] = useState<SellerProps[]>([]);
  const [allIndicators, setAllIndicators] = useState<Indicators[]>([]);
  const [allReffers, setAllReffers] = useState<Reffer[]>([]);
  const [allFreelancers, setAllFreelancers] = useState<FreelancerSellersProps[]>([]);
  /* */
  const [reffers, setReffers] = useState<Reffer[]>([]);
  const [freelancers, setFreelancers] = useState<FreelancerSellersProps[]>([]);
  const [indicators, setIndicators] = useState<Indicators[]>([]);
  const [selectedReffer, setSelectedReffer] = useState<Reffer | null>(null);
  const [file, setFile] = useState<string | null | ArrayBuffer>(null);
  const [products, setProducts] = useState<ProductsProps[]>([]);
  const [selectedClient, setSelectedClient] = useState<ClientPFProps | null>(null);
  const [selectedSeller, setSelectedSeller] = useState<SellerProps | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<ProductsProps | null>(null);
  const [selectedIndicator, setSelectedIndicator] = useState<Indicators | null>(null);
  const [selectedFreelancer, setSelectedFreelancer] = useState<FreelancerSellersProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
  const [contract, setContract] = useState<ContractPFProps>({
    id: 0,
    uuid: '0',
    sellerId: 0,
    holderId: 0,
    holder: { name: '', blockPerma: false },
    productId: 0,
    refferId: null,
    freelancerId: null,
    indicationId: null,
    totalDependentsEnabled: 0,
    totalLifes: 0,
    pricePerHolder: 0,
    pricePerDependent: 0,
    contractGlobalValue: 0,
    signatureDate: '',
    dueDate: '',
    isActive: false,
    payment: [],
    files: [],
    blockHistory: [],
    archived: [],
  });

  async function fetchProducts() {
    try {
      const response = await api.get('/admin/product', token);
      setProducts(response.data);
      setLoading(false);
    } catch (err: any) {
      setShowToastError(true);
    }
  }

  async function fetchReffers(contractCtx: ContractPFProps) {
    try {
      setLoading(true);
      const response = await api.get('/admin/reffer/load/active', token);
      const refferCtx: Reffer[] = [];

      if (typeof contractCtx.reffer !== 'undefined') {
        if (!contractCtx.reffer.isActive) {
          refferCtx.push(contractCtx.reffer);
          response.data.forEach((item: Reffer) => {
            refferCtx.push(item);
          });
          setReffers(refferCtx);
        } else {
          setReffers(response.data);
        }
      } else {
        setReffers(response.data);
      }
      setLoading(false);
    } catch (err: any) {
      throw new Error('');
    }
  }

  async function fetchIndication(contractCtx: ContractPFProps) {
    try {
      setLoading(true);
      const response = await api.get('/admin/indication/load/active', token);
      const indicationCtx: Indicators[] = [];
      console.log('indication', response.data);

      if (typeof contractCtx.indication !== 'undefined') {
        if (!contractCtx.indication.isActive) {
          indicationCtx.push(contractCtx.indication);
          response.data.forEach((item: Indicators) => {
            indicationCtx.push(item);
          });
          setIndicators(indicationCtx);
        } else {
          setIndicators(response.data);
        }
      } else {
        setIndicators(response.data);
      }
      setLoading(false);
    } catch (err: any) {
      throw new Error('');
    }
  }

  async function fetchFreelancer(contractCtx: ContractPFProps) {
    try {
      setLoading(true);
      const response = await api.get('/admin/freelancer/load/active', token);
      const freelancerCtx: FreelancerSellersProps[] = [];

      if (typeof contractCtx.freelancer !== 'undefined') {
        if (!contractCtx.freelancer.isActive) {
          freelancerCtx.push(contractCtx.freelancer);
          response.data.forEach((item: FreelancerSellersProps) => {
            freelancerCtx.push(item);
          });
          setFreelancers(freelancerCtx);
        } else {
          setFreelancers(response.data);
        }
      } else {
        setFreelancers(response.data);
      }
      setLoading(false);
    } catch (err: any) {
      throw new Error('');
    }
  }

  async function fetchAllSellers() {
    try {
      setLoading(true);
      const response = await api.get('/admin/seller', token);
      setAllSellers(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function filterArchivedSeller(idCtx: number) {
    const filtered = allSellers.filter((item) => item.id === idCtx);
    if (filtered.length > 0) {
      return filtered[0].name;
    }
    return 'N/A';
  }

  async function fetchAllIndications() {
    try {
      setLoading(true);
      const response = await api.get('/admin/indication', token);
      setAllIndicators(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function filterArchivedIndication(idCtx: number) {
    const filtered = allIndicators.filter((item) => item.id === idCtx);
    if (filtered.length > 0) {
      return filtered[0].name;
    }
    return 'N/A';
  }

  async function fetchAllFreelancers() {
    try {
      setLoading(true);
      const response = await api.get('/admin/freelancer', token);
      setAllFreelancers(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function filterArchivedFreelancer(idCtx: number) {
    const filtered = allFreelancers.filter((item) => item.id === idCtx);
    if (filtered.length > 0) {
      return filtered[0].name;
    }
    return 'N/A';
  }

  async function fetchAllReffers() {
    try {
      setLoading(true);
      const response = await api.get('/admin/reffer', token);
      setAllReffers(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function filterArchivedReffers(idCtx: number) {
    const filtered = allReffers.filter((item) => item.id === idCtx);
    if (filtered.length > 0) {
      return filtered[0].name;
    }
    return 'N/A';
  }

  function filterArchivedProduct(idCtx: number) {
    const filtered = products.filter((item) => item.id === idCtx);
    if (filtered.length > 0) {
      return filtered[0].name;
    }
    return 'N/A';
  }

  async function fetchContract() {
    try {
      setLoading(true);
      const response = await api.get(`/admin/contract/pf/${id}`, token);
      console.log('contrato', response.data);
      const responseSeller = await api.get(`/admin/seller/${response.data.sellerId}`, token);
      const responseProduct = await api.get(`/admin/product/${response.data.productId}`, token);
      const responseHolder = await api.get(`/admin/holder-pf/${response.data.holderId}`, token);
      await fetchProducts();
      await fetchReffers(response.data);
      await fetchIndication(response.data);
      await fetchFreelancer(response.data);
      await fetchAllReffers();
      await fetchAllFreelancers();
      await fetchAllSellers();
      await fetchAllIndications();
      setSelectedProduct(responseProduct.data);
      setSelectedSeller(responseSeller.data);
      setSelectedClient(responseHolder.data);
      setContract(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: '', actions: [{ label: 'Ok, entendi', onClick: () => navigate(-1), type: ModalActionType.DANGER }] });
    }
  }

  function filterRefferByName(name: string, ctx: Reffer[]) {
    const filteredReffer = ctx.filter((item) => item.name === name);
    if (filteredReffer.length > 0) {
      setSelectedReffer(filteredReffer[0]);
      setContract({ ...contract, refferId: filteredReffer[0].id });
    } else {
      setSelectedReffer(null);
      setContract({ ...contract, refferId: null });
    }
  }

  function filterIndicatorByName(name: string, ctx: Indicators[]) {
    const filteredIndicator = ctx.filter((item) => item.name === name);
    if (filteredIndicator.length > 0) {
      setSelectedIndicator(filteredIndicator[0]);
      setContract({ ...contract, indicationId: filteredIndicator[0].id });
    } else {
      setSelectedIndicator(null);
      setContract({ ...contract, indicationId: null });
    }
  }

  function filterProductByName(name: string, ctx: ProductsProps[]) {
    const filteredProduct = ctx.filter((item) => item.name === name);
    if (filteredProduct.length > 0) {
      setSelectedProduct(filteredProduct[0]);
      setContract({
        ...contract,
        productId: filteredProduct[0].id,
        pricePerHolder: filteredProduct[0].pricePerHolder,
        pricePerDependent: filteredProduct[0].pricePerDependent,
        contractGlobalValue: (filteredProduct[0].pricePerHolder + (filteredProduct[0].pricePerDependent * Number(contract.totalDependentsEnabled))) * 12,
      });
    }
  }

  function filterFreelancerByName(name: string, ctx: FreelancerSellersProps[]) {
    const filteredFreelancer = ctx.filter((item) => item.name === name);
    if (filteredFreelancer.length > 0) {
      setSelectedFreelancer(filteredFreelancer[0]);
      setContract({ ...contract, freelancerId: filteredFreelancer[0].id });
    } else {
      setSelectedFreelancer(null);
      setContract({ ...contract, freelancerId: null });
    }
  }

  function showIndicatorById(ctxId: number | null) {
    const filtered = indicators.filter((item) => item.id === ctxId);
    if (filtered.length > 0) {
      return filtered[0].name;
    }
    return 'Nenhum';
  }

  function showFreelancerById(ctxId: number | null) {
    const filtered = freelancers.filter((item) => item.id === ctxId);
    if (filtered.length > 0) {
      return filtered[0].name;
    }
    return 'Nenhum';
  }

  function showRefferById(ctxId: number | null) {
    const filtered = reffers.filter((item) => item.id === ctxId);
    if (filtered.length > 0) {
      return filtered[0].name;
    }
    return 'Nenhum';
  }

  function createFormData() {
    const body = new FormData();
    const blob = dataURIToBlob(file! as string);
    body.append('files', blob);
    return body;
  }

  async function sendFile() {
    try {
      setLoading(true);
      setModal(null);
      await api.post(`/admin/contractpf/files/${id}`, createFormData(), {
        headers: {
          Authorization: `bearer ${localStorage.getItem('adminToken')}`,
          'Content-Type': 'undefined',
        },
      });
      setFile(null);
      fetchContract();
      setModal({ title: 'Tudo certo!', children: 'O arquivo foi adicionado com sucesso', actions: [{ label: 'Ok, Entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
    } catch (err: any) {
      setFile(null);
      setModal({ title: 'Ocorreu um erro!', children: 'Não foi possível enviar o arquivo. Verifique a extensão e se o tamanho do arquivo nao ultrapassou 100mb.', actions: [{ label: 'Ok, Entendi', type: ModalActionType.PRIMARY, onClick: () => setModal(null) }] });
      setLoading(false);
      console.log(err.response);
    }
  }

  function openFilesModal() {
    setModal({
      title: 'Adicionar arquivos',
      children: <ContractFiles id={contract.id} file={file} setFile={(data) => setFile(data)} setFileSizeError={(modalResponse) => setModal(modalResponse)} setModal={setModal} />,
      actions: file !== null ? [
        { label: 'Enviar', type: ModalActionType.PRIMARY, onClick: () => sendFile() },
        { label: 'Cancelar', type: ModalActionType.DANGER, onClick: () => setModal(null) },
      ] : [
        { label: 'Cancelar', type: ModalActionType.DANGER, onClick: () => setModal(null) },
      ],
    });
  }

  function changeNumberOfDependent(dependentParams: number) {
    const globalContract = (dependentParams * contract.pricePerDependent) + contract.pricePerHolder;
    setContract({ ...contract, totalDependentsEnabled: dependentParams, contractGlobalValue: Number((globalContract * 12).toFixed(2)) });
  }

  function changePricePerHolder(price: number) {
    const globalContract = price + (contract.totalDependentsEnabled * contract.pricePerDependent);
    setContract({ ...contract, pricePerHolder: price, contractGlobalValue: Number((globalContract * 12).toFixed(2)) });
  }

  function changePricePerDependent(price: number) {
    const globalContract = (contract.totalDependentsEnabled * price) + contract.pricePerHolder;
    setContract({ ...contract, pricePerDependent: price, contractGlobalValue: Number((globalContract * 12).toFixed(2)) });
  }

  function showDueDateErrors() {
    if (showIfDateOfParamsOneMajorThenParamsTwo(contract.signatureDate, contract.dueDate)) {
      return 'A data de vencimento deverá ser maior que a data de assinatura';
    } if (contract.dueDate.length < 10) {
      return 'Digite uma data válida';
    }
    return '';
  }

  async function updateContract() {
    try {
      setLoading(true);
      await api.put(`admin/contract/pf/${id}`, contract, token);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível alterar o contrato', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function deleteFile(idCtx: number) {
    try {
      setLoading(true);
      setModal(null);
      await api.delete(`admin/contractpf/files/${idCtx}`, token);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
      await fetchContract();
      setLoading(false);
    } catch (err: any) {
      console.log(err.response);
      setLoading(false);
      setModal({ title: 'Ocorreu um erro', children: 'Não foi possível deletar o arquivo. Verifique se o mesmo já nao foi removido e tente novamente em instantes.', actions: [{ label: 'Ok, entendi.', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  async function generateContractPDF() {
    try {
      setLoading(true);
      const response = await api.get(`/admin/contractpfpdf/${id}`, {
        headers:
         {
           Authorization: `bearer ${localStorage.getItem('adminToken')}`,
         },
        withCredentials: true,
        responseType: 'arraybuffer',
      });
      download(response.data, `ADESAO - ${contract.holder.name}.pdf`);
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
      setLoading(false);
    }
  }

  function showDeleteFileModal(filedId: number) {
    setModal({ title: 'Deletar arquivo', children: 'Deseja mesmo remover o arquivo do sistema? Essa operação será irreversível', actions: [{ label: 'Remover', onClick: () => deleteFile(filedId), type: ModalActionType.DANGER }, { label: 'Cancelar', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
  }

  function checkPermission() {
    if (admin.id === 2 || admin.id === 3 || admin.id === 4 || admin.id === 18 || admin.id === 9 || admin.id === 7 || admin.id === 63 || admin.id === 9 || admin.id === 63 || admin.id === 9) {
      return true;
    }
    return false;
  }

  function resolveBlockStyle(block: BlockHistoryProps) {
    if (block.type === 'perma' && block.input === 'in') {
      return 'td-block-perma';
    }
    if (block.type === 'perma' && block.input === 'out') {
      return 'td-unblock';
    }
    if (block.input === 'out') {
      return 'td-unblock';
    }
    return 'td-block-temp';
  }

  useEffect(() => {
    if (file !== null) {
      openFilesModal();
    }
  }, [file]);

  useEffect(() => {
    fetchContract();
    fetchAllReffers();
    fetchAllFreelancers();
    fetchAllSellers();
    fetchAllIndications();
  }, []);

  const definitiveReffersArray: any[] = [];
  const definitiveIndicatorArray: any[] = [];
  const definitiveFreelancerArray: any[] = [];

  return (
    <DefaultLayout
      pageTitle="Visualizar contrato PF"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products mt-4">
        <div className="col-12 card-bg p-4">
          <div className="row">
            <h1>Visualizar contrato PF</h1>
            <div className="col-4">
              <BasicInput
                id="vendedor-inout"
                label="Vendedor"
                readOnly={!checkPermission()}
                helper="Quem intermediou o contrato"
                error=""
                value={selectedSeller !== null ? selectedSeller.name : 'Nenhum vendedor encontrado'}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="client-PF-input"
                label="Cliente PF"
                readOnly={!checkPermission()}
                helper="Selecione um cliente titular"
                error=""
                value={selectedClient !== null ? selectedClient.name : 'Nenhum cliente encontrado'}
                onChange={(data) => null}
                type="text"
              />
            </div>
            <div className="col-4">
              <BasicOption
                id="product-selected-input"
                label="Produto escolhido"
                readOnly={!checkPermission()}
                helper="Selecione o produto"
                error=""
                value={selectedProduct !== null ? selectedProduct.name : 'Nenhum produto encontrado'}
                onChange={(data) => filterProductByName(data, products)}
              >
                {products !== null ? products.map((item) => item.name) : ['Nenhum produto encontrado']}
              </BasicOption>
            </div>
            {/* <div className="col-4">
              <BasicInput
                id="product-selected-input"
                label="Produto escolhido"
                readOnly={!checkPermission()}
                helper="Selecione o produto"
                error=""
                value={selectedProduct !== null ? selectedProduct.name : 'Nenhum produto encontrado'}
                onChange={(data) => null}
                type="text"
              />
            </div> */}
            {reffers.length > 0 ? (
              <div className="col-4">
                <BasicOption
                  id="representante-input"
                  label="Embaixador master"
                  readOnly={false}
                  helper=""
                  error=""
                  value={showRefferById(contract.refferId)}
                  onChange={(data) => filterRefferByName(data, reffers)}
                >
                  {definitiveReffersArray.concat(reffers).concat('Nenhum').map((item) => (typeof item.name !== 'undefined' ? item.name : 'Nenhum'))}
                </BasicOption>
              </div>
            ) : (
              <div className="col-4">
                <BasicOption
                  id="representante-input"
                  label="Embaixador master"
                  readOnly
                  helper=""
                  error=""
                  value="Nenhum cadastrado"
                  onChange={() => null}
                >
                  {['Nenhum cadastrado']}
                </BasicOption>
              </div>
            )}

            {indicators.length > 0 ? (
              <div className="col-4">
                <BasicOption
                  id="indications-input"
                  label="Embaixador"
                  readOnly={false}
                  helper="Quem indicou o contrato"
                  error=""
                  value={showIndicatorById(contract.indicationId)}
                  onChange={(data) => filterIndicatorByName(data, indicators)}
                >
                  {
                    definitiveIndicatorArray.concat(indicators).concat('Nenhum').map((item) => (typeof item.name !== 'undefined' ? item.name : 'Nenhum'))
                  }
                </BasicOption>
              </div>
            ) : (
              <div className="col-4">
                <BasicOption
                  id="representante-input"
                  label="Embaixador"
                  readOnly
                  helper="Quem indicou o contrato"
                  error=""
                  value="Nenhum cadastrado"
                  onChange={() => null}
                >
                  {['Nenhum cadastrado']}
                </BasicOption>
              </div>
            )}

            {freelancers.length > 0 ? (
              <div className="col-4">
                <BasicOption
                  id="freelancer-input"
                  label="Representante"
                  readOnly={false}
                  helper=""
                  error=""
                  value={showFreelancerById(contract.freelancerId)}
                  onChange={(data) => filterFreelancerByName(data, freelancers)}
                >
                  {
                    definitiveFreelancerArray.concat(freelancers).concat('Nenhum').map((item) => (typeof item.name !== 'undefined' ? item.name : 'Nenhum'))
                  }
                </BasicOption>
              </div>
            ) : (
              <div className="col-4">
                <BasicOption
                  id="freelancer-input"
                  label="Representante"
                  readOnly
                  helper=""
                  error=""
                  value="Nenhum cadastrado"
                  onChange={() => null}
                >
                  {['Nenhum cadastrado']}
                </BasicOption>
              </div>
            )}

            <div className="col-4">
              <BasicInput
                id="total-vidas-input"
                label="Qtd. Vidas"
                readOnly={!checkPermission()}
                helper="Ex: 100"
                error={contract.totalLifes === 0 ? 'Mínimo de 1.' : ''}
                value={contract.totalLifes.toString()}
                type="number"
                onChange={(data) => setContract({ ...contract, totalLifes: Number(data) })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="total-dependentes-input"
                label="Qtd. Dependentes"
                readOnly={!checkPermission()}
                helper="Ex: 20"
                error={contract.totalDependentsEnabled + 1 > contract.totalLifes ? 'Ultrapassou o total de vidas' : ''}
                value={contract.totalDependentsEnabled.toString()}
                type="number"
                onChange={(data) => changeNumberOfDependent(Number(data))}
              />
            </div>
            <div className="col-4">
              <PriceInput
                id="preco-titular-input"
                label="Preço por titular"
                readOnly={!checkPermission()}
                helper="Ex: R$20"
                error={contract.pricePerHolder === 0 ? 'Adicione um preço por titular' : ''}
                value={contract.pricePerHolder.toString()}
                type="number"
                onChange={(data) => changePricePerHolder(Number(data))}
              />
            </div>
            <div className="col-4">
              <PriceInput
                id="preco-dependentes-input"
                label="Preço por dependente"
                readOnly={!checkPermission()}
                helper="Ex: R$20"
                error=""
                value={contract.pricePerDependent.toString()}
                type="number"
                onChange={(data) => changePricePerDependent(Number(data))}
              />
            </div>
            <div className="col-4">
              <PriceInput
                id="global-value-input"
                label="Valor global do contrato"
                readOnly={!checkPermission()}
                helper="Ex: R$20"
                error=""
                value={contract.contractGlobalValue.toString()}
                type="number"
                onChange={(data) => setContract({ ...contract, contractGlobalValue: Number(data) })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="date-start-input"
                label="Data de Início do contrato"
                readOnly={!checkPermission()}
                helper="Ex: 30-01-2022"
                error={contract.signatureDate.length < 10 ? 'Digite uma data válida' : ''}
                value={contract.signatureDate}
                type="text"
                onChange={(data) => setContract({ ...contract, signatureDate: maskDate(data) })}
              />
            </div>
            <div className="col-4">
              <BasicInput
                id="date-start-input"
                label="Data de Vencimento do contrato"
                readOnly={!checkPermission()}
                helper="Ex: 30-01-2022"
                error={showDueDateErrors()}
                value={contract.dueDate}
                type="text"
                onChange={(data) => setContract({ ...contract, dueDate: maskDate(data) })}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Renovar contrato"
                type={BtnType.SECONDARY}
                icon="update"
                active
                onClick={() => navigate(`/contratos/pf/renovar/${id}`)}
                ref={null}
              />
              <ButtonTextIcon
                label="Gerar Termo de adesão"
                type={BtnType.SECONDARY}
                icon="download"
                active
                onClick={() => generateContractPDF()}
                ref={null}
              />
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                active={contract.dueDate.length === '00-00-0000'.length && !showIfDateOfParamsOneMajorThenParamsTwo(contract.signatureDate, contract.dueDate)}
                onClick={() => updateContract()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Pagamentos</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              {contract.payment.length === 0 && (
              <div className="table-top-left-actions">
                <button className="table-btn-primary" type="button" onClick={() => navigate(`/contratos/pf/forma-de-pagamento/adicionar/${id}`)}>
                  <span className="material-icons-round">
                    add
                  </span>
                  pagamentos
                </button>
              </div>
              )}
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Valor Global</th>
                  <th scope="col">Dt. aprovação</th>
                  <th scope="col">Parcelas</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {contract.payment.length > 0 ? contract.payment.filter((e) => !e.contractPfArchivedId).map((item, i) => (
                  <tr key={i}>
                    <td>{item.id}</td>
                    <td>{`R$ ${item.totalValue}`}</td>
                    <td>{item.approvalDate}</td>
                    <td>{item.installments}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <button type="button" onClick={() => navigate(`/contratos/pf/forma-de-pagamento/visualizar/${item.id}`)}>
                            <span className="material-icons-round">
                              more
                            </span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : (
                  <p>Não existe(m) fatura(s) para esse contrato.</p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Contratos arquivados</h1>
          <div className="table-wrapper">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Observação</th>
                  <th scope="col">Dt. renovação</th>
                  <th scope="col">Renovado por</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {contract.archived && contract.archived.length > 0 ? contract.archived.map((item, i) => (
                  <tr key={i}>
                    <td>{item.id}</td>
                    <td>{item.history[0].observation || 'N/A'}</td>
                    <td>{item.history[0].renewedDate}</td>
                    <td>{item.history[0].renewedBy}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <button className="text-primary" type="button" onClick={() => navigate(`/contratos/pf/forma-de-pagamento/visualizar/${item.payment.id}`)}>
                            Ver Pagamento
                          </button>
                        </div>
                        <div className="table-action-icon">
                          <button className="text-primary" type="button" onClick={() => null} data-bs-toggle="modal" data-bs-target={`#archived${item.id}`}>
                            Ver Contrato
                          </button>
                          <ArchivedContractDataModal
                            id={`archived${item.id}`}
                            contract={item}
                            seller={filterArchivedSeller(item.sellerId)}
                            client={selectedClient ? selectedClient.name : 'N/A'}
                            selectedProduct={filterArchivedProduct(item.productId)}
                            reffer={filterArchivedReffers(item.refferId || 300000)}
                            indication={filterArchivedIndication(item.indicationId || 300000)}
                            freelancer={filterArchivedFreelancer(item.freelancerId || 300000)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : (
                  <p>Não existe(m) contrato(s) arquivados.</p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Histórico de bloqueios / desbloqueios</h1>
          <div className="table-wrapper">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Bloqueio / Cancelamento</th>
                  <th scope="col">Data</th>
                  <th scope="col">Motivo</th>
                  <th scope="col">Responsável</th>
                </tr>
              </thead>
              <tbody>
                {contract.blockHistory.length > 0 ? contract.blockHistory.map((item, i) => (
                  <tr key={i}>
                    <td className={resolveBlockStyle(item)}>{(item.input === 'in' ? 'Bloqueio' : 'Desbloqueio')}</td>
                    <td className={resolveBlockStyle(item)}>{item.blockedDate}</td>
                    <td className={resolveBlockStyle(item)}>{item.reason}</td>
                    <td className={resolveBlockStyle(item)}>{item.blockedBy}</td>
                  </tr>
                )) : (
                  <p>Não existe(m) bloqueio(s) para esse contrato.</p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Arquivos</h1>
          <div className="table-wrapper">
            <div className="table-actions">
              <button
                className="table-btn-primary"
                type="button"
                onClick={() => openFilesModal()}
              >
                <span className="material-icons-round">
                  add
                </span>
                anexo
              </button>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Dt. Envio</th>
                  <th className="text-end" scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {contract.files.length > 0 ? contract.files.map((item, i) => (
                  <tr key={i}>
                    <td>{item.id}</td>
                    <td>{item.createdAt}</td>
                    <td align="right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="table-action-icon">
                          <a
                            href={item.fileUrl}
                            type="button"
                            className="text-decoration-none px-2"
                            onClick={() => null}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>Abrir</span>
                          </a>
                          <button
                            type="button"
                            onClick={() => showDeleteFileModal(item.id)}
                          >
                            <span className="text-danger">Excluir</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : (
                  <p>Não existe(m) arquivos(s) para esse contrato.</p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </DefaultLayout>
  );
}
