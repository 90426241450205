import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddInput from '../../components/AddInput';
import BasicInput from '../../components/BasicInput';
import { ModalProps } from '../../components/Modal';
import TextAreaInput from '../../components/TextAreaInput';
import api from '../../config/axios';
import { maskCPF, unmaskData } from '../../helpers/inputMasks.js';
import DefaultLayout from '../../Layout';

type Dependent = {
  id: number;
  uuid: string;
  name: string;
  sex: string;
  birthday: string;
  cpf: string;
  rg: string;
  emitent: string;
  addressStreet: string;
  addressNumber: string;
  addressComplement: null | string;
  addressNeighborhood: string;
  addressCity: string;
  addressState: string;
  addressZipcode: string;
  phone: string;
  isActive: boolean;
};

type UserResponse = {
  msg: string;
  role: string;
  type: string;
  info: string;
  child: string;
  color: string;
};

function ColoredInput({
  id,
  label,
  value,
  color,
  readOnly,
}: {
  id: string;
  label: string;
  value: string;
  color: string;
  readOnly: boolean;
}) {
  return (
    <div className="col-12" style={{ marginBottom: '15px' }}>
      <label
        htmlFor={id}
        style={{
          color: '#5e5873',
          marginBottom: '12px',
          fontSize: '0.68rem',
          fontWeight: 600,
          textTransform: 'uppercase',
          letterSpacing: '1.2px',
          display: 'block',
        }}
      >
        {label}
      </label>
      <input
        id={id}
        value={value}
        readOnly={readOnly}
        style={{
          color,
          borderColor: color,
          width: '100%',
          padding: '8px',
          borderRadius: '4px',
          borderWidth: '1px',
          borderStyle: 'solid',
        }}
      />
    </div>
  );
}

export default function SituationByCPF() {
  const { cpf } = useParams();
  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [showRightSlider, setShowRightSlider] = useState(false);
  const [CPFInput, setCPFInput] = useState('');
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [situationErrorText, setSituationErrorText] = useState('');
  const [user, setUser] = useState<UserResponse>();
  const [token] = useState({
    headers: {
      Authorization: `bearer ${localStorage.getItem('adminToken')}`,
    },
  });

  async function fetchSituation() {
    try {
      setLoading(true);
      const response = await api.post(
        '/admin/search',
        { cpf: unmaskData(CPFInput.trim()) },
        token,
      );
      setUser(response.data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      if (typeof err.response !== 'undefined' && err.response.status === 400) {
        setUser(err.response.data);
      } else {
        setSituationErrorText('Não foi possível realizar a busca.');
      }
    }
  }

  async function fetchSituationByParams() {
    try {
      setLoading(true);
      const response = await api.post(
        '/admin/search',
        { cpf: unmaskData(cpf?.trim()) },
        token,
      );
      setUser(response.data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      if (typeof err.response !== 'undefined' && err.response.status === 400) {
        setUser(err.response.data);
      } else {
        setSituationErrorText('Não foi possível realizar a busca.');
      }
    }
  }

  useEffect(() => {
    if (cpf) {
      setCPFInput(maskCPF(cpf));
      fetchSituationByParams();
    }
  }, []);

  return (
    <DefaultLayout
      pageTitle="Consultar situação por CPF"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={showRightSlider}
      rightSliderContent={undefined}
      modal={modal}
      setCloseRightSlider={() => setShowRightSlider(false)}
    >
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Pesquisa de usuário por CPF</h1>
          <div className="col-4">
            <AddInput
              id="cpf-input"
              label="CPF"
              icon="search"
              readOnly={false}
              helper="Ex: 092.000.000-00"
              error=""
              value={CPFInput}
              onChange={(data) => setCPFInput(maskCPF(data))}
              onAdd={() => fetchSituation()}
              type=""
            />
          </div>
          {user && user.info && (
            <>
              <div className="col-12">
                <BasicInput
                  id="info"
                  label="Informação"
                  readOnly
                  error=""
                  value={user.info}
                  onChange={() => null}
                  helper=""
                  type=""
                />
              </div>
              <ColoredInput
                id="situation"
                label="Situação"
                value={user.msg}
                color={user.color}
                readOnly
              />
              <div className="col-12">
                <BasicInput
                  id="type"
                  label="Tipo"
                  readOnly
                  error=""
                  value={user.type}
                  onChange={() => null}
                  helper=""
                  type=""
                />
              </div>
              <div className="col-12">
                <BasicInput
                  id="child"
                  label="Titularidade"
                  readOnly
                  error=""
                  value={user.child}
                  onChange={() => null}
                  helper=""
                  type=""
                />
              </div>
            </>
          )}

          {user && !user.info && user.msg && (
            <div className="col-12">
              <TextAreaInput
                id="situation"
                label="Situação"
                readOnly
                error=""
                value={user.msg}
                onChange={() => null}
                helper=""
              />
            </div>
          )}

          {!user && situationErrorText !== '' && (
            <div className="col-12">
              <TextAreaInput
                id="situation"
                label="Situação"
                readOnly
                error=""
                value="Não foi possível realizar busca"
                onChange={() => null}
                helper=""
              />
            </div>
          )}

          {situationErrorText !== '' && (
            <div className="col-12">
              <TextAreaInput
                id="situation"
                label="Situação"
                readOnly
                error=""
                value="Não foi possível realizar busca"
                onChange={() => null}
                helper=""
              />
            </div>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
}
