/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import BasicInput from '../../../components/BasicInput';
import api from '../../../config/axios';
import { validateSingleDates } from '../../../helpers/dates';
import { maskDate } from '../../../helpers/inputMasks.js';

type PageProps = {
    id: string
    isPf: boolean
    installmentId: number,
}

enum Pages {
    FORM,
    LOADING,
    SUCCESS,
    ERROR
}

export default function AuthorizationModal({ id, isPf, installmentId }: PageProps) {
  const [authorizationDate, setAuthorizationDate] = useState('');
  const [dateIsWrong, setDateIsWrong] = useState(false);
  const [actualPage, setActualPage] = useState(Pages.FORM);
  async function validateDate() {
    try {
      await validateSingleDates(authorizationDate);
      setDateIsWrong(false);
    } catch (err) {
      setDateIsWrong(true);
    }
  }

  async function changeInstallmentsStatus() {
    try {
      if (!dateIsWrong) {
        const route = isPf ? 'admin/contractpf/installment/authorize' : 'admin/contractpj/installment/authorize';
        setActualPage(Pages.LOADING);
        await api.post(route, {
          id: installmentId,
          authorizationDate,
        }, { headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });
        setActualPage(Pages.SUCCESS);
      }
    } catch (err) {
      setActualPage(Pages.ERROR);
    }
  }

  function onClose() {
    window.location.reload();
  }

  useEffect(() => {
    validateDate();
  }, [authorizationDate]);
  return (
    <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Autorizar Parcela CIELO</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => onClose()} />
          </div>
          <div className="modal-body">
            {actualPage === Pages.FORM && (
            <>
              <h5 className="text-start">Digite abaixo a data em que a operação foi capturada na CIELO</h5>
              <div className="col-12" style={{ color: 'red !important' }}>
                <BasicInput
                  id=""
                  label=""
                  readOnly={false}
                  helper="Data em que a operação foi capturada na CIELO"
                  error={dateIsWrong ? 'Data inválida' : ''}
                  value={authorizationDate}
                  type="text"
                  onChange={(data) => setAuthorizationDate(maskDate(data))}
                />
              </div>
            </>
            )}
            { actualPage === Pages.LOADING && (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
              <p className="fw-bold text-muted">Alterando parcela, aguarde...</p>
            </div>
            )}

            { actualPage === Pages.SUCCESS && (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
              <p className="fw-bold text-muted">Parcela alterada com sucesso!</p>
            </div>
            )}

            { actualPage === Pages.ERROR && (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 400 }}>
              <p className="fw-bold text-muted">Ocorreu um erro ao alterar parcela!</p>
            </div>
            )}

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => onClose()}
              style={{ backgroundColor: '#6c757d' }}
            >
              Fechar
            </button>
            {actualPage === Pages.FORM && (
            <button
              type="button"
              className="btn btn-primary"
              style={{ backgroundColor: '#0d6efd' }}
              onClick={() => changeInstallmentsStatus()}
            >
              Autorizar
            </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
