import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicInput from '../../components/BasicInput';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalActionType, ModalProps } from '../../components/Modal';
import TextAreaInput from '../../components/TextAreaInput';
import api from '../../config/axios';
import DefaultLayout from '../../Layout';

type Indications = {
    name: string,
}

export default function NewIndicatorCategory() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` } });
  const [category, setCategory] = useState<Indications>({
    name: '',
  });

  function validateCategoryFields(): boolean {
    if (category.name.length >= 1) {
      return true;
    }
    return false;
  }

  async function saveCategory() {
    try {
      setLoading(true);
      await api.post('/admin/indication-category', category, token);
      setLoading(false);
      navigate(-1);
    } catch (err: any) {
      console.log(err.response.data);
      setModal({ title: 'Não foi possível.', children: 'Não foi possível cadastrar.', actions: [{ label: 'Ok, entendi', onClick: () => setModal(null), type: ModalActionType.PRIMARY }] });
    }
  }

  return (
    <DefaultLayout
      pageTitle="Nova Categoria de Embaixadores"
      loading={loading}
      showToastSuccess={showToastSuccess}
      showToastError={showToastError}
      showRightSlider={false}
      rightSliderContent={null}
      modal={modal}
      setCloseRightSlider={() => null}
    >
      <div className="row products">
        <div className="col-12 p-0 card-bg p-4">
          <h1>Categoria de Embaixadores</h1>
          <div className="row">
            <div className="col-4">
              <BasicInput
                id="indicator-nome"
                label="Nome"
                readOnly={false}
                helper="Ex. Hospital Santa Mônica"
                error={category.name.length < 3 ? 'Digite um nome válido' : ''}
                value={category.name}
                type="text"
                onChange={(data) => setCategory({ ...category, name: data })}
              />
            </div>
            <div className="col-12 footer">
              <ButtonTextIcon
                label="Salvar"
                type={BtnType.PRIMARY}
                icon="backup"
                active={validateCategoryFields()}
                onClick={() => saveCategory()}
                ref={null}
              />
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
