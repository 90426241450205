/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import BasicInput from '../../../components/BasicInput';
import BasicOption from '../../../components/BasicOption';
import { maskCPF, maskDate, unmaskData } from '../../../helpers/inputMasks.js';
import { Reffer } from '../../../dto/reffers';
import { Indicators } from '../../../dto/indicators';
import { FreelancerSellersProps } from '../../../dto/freelancersSellers';

type FilterProps = {
    currentPage: number,
    contractid?: number | null,
    sellerid?: number | null,
    refferid?: number | null,
    freelancerid?: number | null,
    indicationid?: number | null,
    blockTemp?: boolean | null,
    blockPerma?: boolean | null,
    initialSignatureDate?: string | null,
    finalSignatureDate?: string | null,
    initialDueDate?: string | null,
    finalDueDate?: string | null,
    minGlobalValue?: number | null,
    maxGlobalValue?: number | null,
    titular?: string | null
    finalRenewDate?: string | null,
    initialRenewDate?: string | null
}

type ContractModalFilterProps = {
    filter: (arg: FilterProps) => void,
    contractId?: null | number,
    setContractId: (arg: null | number) => void
    sellerId?: null | number
    setSellerId: (arg: null | number) => void
    refferId?: null | number
    setRefferId: (arg: null | number) => void
    freelancerId: number | null
    setFreelancerId: (arg: number | null) => void
    indicationId?: number | null
    setIndicationId: (arg: number | null) => void
    blockTemp?: null | boolean
    setBlockTemp: (arg: null | boolean) => void
    blockPerma?: null | boolean
    setBlockPerma: (arg: null | boolean) => void
    initialSignatureDate?: string | null
    setInitialSignatureDate: (arg: string | null) => void;
    initialRenewDate?: string | null
    setInitialRenewDate: (arg: string | null) => void;
    finalRenewDate?: string | null
    setFinalRenewDate: (arg: string | null) => void;
    finalSignatureDate?: string | null
    setFinalSignatureDate: (arg: string | null) => void;
    initialDueDate?: string | null
    setInitialDueDate: (arg: string | null) => void
    finalDueDate?: string | null
    setFinalDueDate: (arg: string | null) => void
    minGlobalValue?: number | null
    setMinGlobalValue: (arg: number | null) => void
    maxGlobalValue?: number | null
    setMaxGlobalValue: (arg: number | null) => void
    titular?: null | string
    setTitular: (arg: null | string) => void,
    reffers: Reffer[]
    indicators: Indicators[]
    freelancers: FreelancerSellersProps[]
}

export default function ContractModalFilter({
  filter,
  contractId,
  setContractId,
  sellerId,
  setSellerId,
  refferId,
  setRefferId,
  freelancerId,
  setFreelancerId,
  indicationId,
  setIndicationId,
  blockTemp,
  setBlockTemp,
  blockPerma,
  setBlockPerma,
  initialSignatureDate,
  setInitialSignatureDate,
  finalSignatureDate,
  setFinalSignatureDate,
  initialDueDate,
  setInitialDueDate,
  finalDueDate,
  setFinalDueDate,
  minGlobalValue,
  setMinGlobalValue,
  maxGlobalValue,
  setMaxGlobalValue,
  initialRenewDate,
  setInitialRenewDate,
  finalRenewDate,
  setFinalRenewDate,
  titular,
  setTitular,
  reffers,
  freelancers,
  indicators,
}: ContractModalFilterProps) {
  const [ref] = useState(React.createRef<any>());

  async function validateDateDoubleDates(dateInitial: string, dateFinal: string) {
    try {
      const initialDateAsDateTime = DateTime.fromFormat(dateInitial, 'dd-MM-yyyy');
      const finalDateAsDateTime = DateTime.fromFormat(dateFinal, 'dd-MM-yyyy');
      if (!initialDateAsDateTime.isValid) {
        throw new Error('Data inicial inválida.');
      }
      if (!finalDateAsDateTime.isValid) {
        throw new Error('Data final inválida.');
      }

      if (initialDateAsDateTime > finalDateAsDateTime) {
        throw new Error('Data inicial maior que a data final.');
      }
      return {
        initialDateAsDateTime,
        finalDateAsDateTime,
      };
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  async function validateSingleDates(date: string) {
    try {
      const initialDateAsDateTime = DateTime.fromFormat(date, 'dd-MM-yyyy');
      if (!initialDateAsDateTime.isValid) {
        throw new Error('Data inicial inválida.');
      }

      return {
        initialDateAsDateTime,
      };
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  async function setFilter() {
    try {
      if (initialDueDate) {
        await validateSingleDates(initialDueDate);
      }
      if (finalDueDate) {
        await validateSingleDates(finalDueDate);
      }
      if (initialDueDate && finalDueDate) {
        await validateDateDoubleDates(initialDueDate, finalDueDate);
      }
      if (initialSignatureDate) {
        await validateSingleDates(initialSignatureDate);
      }
      if (finalSignatureDate) {
        await validateSingleDates(finalSignatureDate);
      }
      if (initialSignatureDate && finalSignatureDate) {
        await validateDateDoubleDates(initialSignatureDate, finalSignatureDate);
      }
      filter({
        currentPage: 1,
        contractid: contractId,
        sellerid: sellerId,
        refferid: refferId,
        freelancerid: freelancerId,
        indicationid: indicationId,
        blockTemp,
        blockPerma,
        initialSignatureDate,
        finalSignatureDate,
        initialDueDate,
        finalDueDate,
        minGlobalValue,
        maxGlobalValue,
        titular,
        finalRenewDate,
        initialRenewDate,
      });
      ref.current.click();
    } catch (err: any) {
      if (err.message === 'Data inicial maior que a data final.') {
        alert('Data inicial maior que a data final.');
      }
      if (err.message === 'Data final inválida.') {
        alert('Data final inválida.');
      }
      if (err.message === 'Data inicial inválida.') {
        alert('Data inicial inválida.');
      }
    }
  }

  function changeContractId(data: string) {
    if (data === '') {
      setContractId(null);
    } else {
      setContractId(Number(data));
    }
  }

  function changeReffer(data: string) {
    if (data === 'TODOS') {
      setRefferId(null);
    } else {
      const filtered = reffers.filter((item) => item.name === data);
      if (filtered.length > 0) {
        setRefferId(filtered[0].id);
      } else {
        setRefferId(null);
      }
    }
  }

  function resolveReffer() {
    if (refferId === null) {
      return 'TODOS';
    }
    const filtered = reffers.filter((item) => item.id === refferId);
    if (filtered.length > 0) {
      return filtered[0].name;
    }
    return 'TODOS';
  }

  function changeFreelancer(data: string) {
    if (data === 'TODOS') {
      setFreelancerId(null);
    } else {
      const filtered = freelancers.filter((item) => item.name === data);
      if (filtered.length > 0) {
        setFreelancerId(filtered[0].id);
      } else {
        setFreelancerId(null);
      }
    }
  }

  function resolveFreelancer() {
    if (freelancerId === null) {
      return 'TODOS';
    }
    const filtered = freelancers.filter((item) => item.id === freelancerId);
    if (filtered.length > 0) {
      return filtered[0].name;
    }
    return 'TODOS';
  }

  function changeIndication(data: string) {
    if (data === 'TODOS') {
      setIndicationId(null);
    } else {
      const filtered = indicators.filter((item) => item.name === data);
      if (filtered.length > 0) {
        setIndicationId(filtered[0].id);
      } else {
        setIndicationId(null);
      }
    }
  }

  function resolveIndication() {
    if (indicationId === null) {
      return 'TODOS';
    }
    const filtered = indicators.filter((item) => item.id === indicationId);
    if (filtered.length > 0) {
      return filtered[0].name;
    }
    return 'TODOS';
  }

  function changeBlockTemp(data: string) {
    if (data === 'SIM') {
      setBlockTemp(true);
    } else if (data === 'NÃO') {
      setBlockTemp(false);
    } else {
      setBlockTemp(null);
    }
  }

  function resolveBlockTemp() {
    if (blockTemp) {
      return 'SIM';
    }
    if (blockTemp === false) {
      return 'NÃO';
    }
    return 'TODOS';
  }

  function changeBlockPerma(data: string) {
    if (data === 'SIM') {
      setBlockPerma(true);
    } else if (data === 'NÃO') {
      setBlockPerma(false);
    } else {
      setBlockPerma(null);
    }
  }

  function resolveBlockPerma() {
    if (blockPerma) {
      return 'SIM';
    }
    if (blockPerma === false) {
      return 'NÃO';
    }
    return 'TODOS';
  }

  return (
    <div className="modal fade" id="contractModalFilter" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Filtro</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={ref} />
          </div>
          <div className="modal-body">
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="ID do contrato"
                    readOnly={false}
                    helper=""
                    error=""
                    value={contractId ? contractId.toString() : ''}
                    type="text"
                    onChange={(data) => changeContractId(data)}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Titular"
                    readOnly={false}
                    helper=""
                    error=""
                    value={titular ? titular.toString() : ''}
                    type="text"
                    onChange={(data) => {
                      if (data === '') {
                        setTitular(null);
                      } else {
                        setTitular(data);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicOption
                    id=""
                    label="Representante"
                    readOnly={false}
                    helper=""
                    error=""
                    value={resolveFreelancer()}
                    onChange={(data) => changeFreelancer(data)}
                  >
                    {['TODOS'].concat(...freelancers.map((item) => item.name))}
                  </BasicOption>
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicOption
                    id=""
                    label="Embaixador Master"
                    readOnly={false}
                    helper=""
                    error=""
                    value={resolveReffer()}
                    onChange={(data) => changeReffer(data)}
                  >
                    {['TODOS'].concat(...reffers.map((item) => item.name))}
                  </BasicOption>
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicOption
                    id=""
                    label="Embaixador"
                    readOnly={false}
                    helper=""
                    error=""
                    value={resolveIndication()}
                    onChange={(data) => changeIndication(data)}
                  >
                    {['TODOS'].concat(...indicators.map((item) => item.name))}
                  </BasicOption>
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicOption
                    id=""
                    label="Bloqueados Temp. ?"
                    readOnly={false}
                    helper=""
                    error=""
                    value={resolveBlockTemp()}
                    onChange={(data) => changeBlockTemp(data)}
                  >
                    {['TODOS', 'SIM', 'NÃO']}
                  </BasicOption>
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicOption
                    id=""
                    label="Cancelado?"
                    readOnly={false}
                    helper=""
                    error=""
                    value={resolveBlockPerma()}
                    onChange={(data) => changeBlockPerma(data)}
                  >
                    {['TODOS', 'SIM', 'NÃO']}
                  </BasicOption>
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data inicial de assinatura"
                    readOnly={false}
                    helper=""
                    error=""
                    value={initialSignatureDate || ''}
                    type="text"
                    onChange={(data) => {
                      if (data === '') {
                        setInitialSignatureDate(null);
                      } else {
                        setInitialSignatureDate(maskDate(data));
                      }
                    }}
                  />
                </div>
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data final de assinatura"
                    readOnly={false}
                    helper=""
                    error=""
                    value={finalSignatureDate || ''}
                    type="text"
                    onChange={(data) => {
                      if (data === '') {
                        setFinalSignatureDate(null);
                      } else {
                        setFinalSignatureDate(maskDate(data));
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data inicial de vencimento"
                    readOnly={false}
                    helper=""
                    error=""
                    value={initialDueDate || ''}
                    type="text"
                    onChange={(data) => {
                      if (data === '') {
                        setInitialDueDate(null);
                      } else {
                        setInitialDueDate(maskDate(data));
                      }
                    }}
                  />
                </div>
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data final de vencimento"
                    readOnly={false}
                    helper=""
                    error=""
                    value={finalDueDate || ''}
                    type="text"
                    onChange={(data) => {
                      if (data === '') {
                        setFinalDueDate(null);
                      } else {
                        setFinalDueDate(maskDate(data));
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data inicial de renovação"
                    readOnly={false}
                    helper=""
                    error=""
                    value={initialRenewDate || ''}
                    type="text"
                    onChange={(data) => {
                      if (data === '') {
                        setInitialRenewDate(null);
                      } else {
                        setInitialRenewDate(maskDate(data));
                      }
                    }}
                  />
                </div>
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Data final de renovação"
                    readOnly={false}
                    helper=""
                    error=""
                    value={finalRenewDate || ''}
                    type="text"
                    onChange={(data) => {
                      if (data === '') {
                        setFinalRenewDate(null);
                      } else {
                        setFinalRenewDate(maskDate(data));
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Valor Global Min."
                    readOnly={false}
                    helper=""
                    error=""
                    value={minGlobalValue ? minGlobalValue.toString() : ''}
                    type="number"
                    onChange={(data) => {
                      if (data === '') {
                        setMinGlobalValue(null);
                      } else {
                        setMinGlobalValue(Number(data));
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="filter-wrapper">
              <div className="filter-date-input-wrapper w-100">
                <div className="w-100" style={{ paddingRight: 4 }}>
                  <BasicInput
                    id=""
                    label="Valor Global Max."
                    readOnly={false}
                    helper=""
                    error=""
                    value={maxGlobalValue ? maxGlobalValue.toString() : ''}
                    type="number"
                    onChange={(data) => {
                      if (data === '') {
                        setMaxGlobalValue(null);
                      } else {
                        setMaxGlobalValue(Number(data));
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            <button type="button" className="btn btn-primary" onClick={() => setFilter()}>Filtrar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
