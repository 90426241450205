/* eslint-disable max-len */
import { DateTime, Duration } from 'luxon';

export default function normalizeDate(date: string): string {
  const splittedFullDate = date.split('T');
  const splittedDate = splittedFullDate[0].split('-');
  return `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
}

export function normalizeDateToApi(date: string) {
  const firstSplit = date.split('-');
  return `${firstSplit[2]}-${firstSplit[1]}-${firstSplit[0]}`;
}

export function showTodayDate() {
  const { day, month, year } = DateTime.now();
  let finalDay: string;
  let finalMonth: string;

  if (day < 10) {
    finalDay = `0${day}`;
  } else {
    finalDay = `${day}`;
  }

  if (month < 10) {
    finalMonth = `0${month}`;
  } else {
    finalMonth = `${month}`;
  }

  return `${finalDay}-${finalMonth}-${year}`;
}

export function showFutureDateBasedOnMonths(plusMonth: number) {
  const date = DateTime.now();
  const { day, month, year } = date.plus(Duration.fromObject({ months: plusMonth }));
  let finalDay: string;
  let finalMonth: string;

  if (day < 10) {
    finalDay = `0${day}`;
  } else {
    finalDay = `${day}`;
  }

  if (month < 10) {
    finalMonth = `0${month}`;
  } else {
    finalMonth = `${month}`;
  }

  return `${finalDay}-${finalMonth}-${year}`;
}

export function showFutureDateBasedOnMonthsAndApprovalDate(approval: string, plusMonth: number) {
  try {
    const date = DateTime.fromFormat(approval, 'dd-MM-yyyy');
    const { day, month, year } = date.plus(Duration.fromObject({ months: plusMonth }));
    let finalDay: string;
    let finalMonth: string;

    if (day < 10) {
      finalDay = `0${day}`;
    } else {
      finalDay = `${day}`;
    }

    if (month < 10) {
      finalMonth = `0${month}`;
    } else {
      finalMonth = `${month}`;
    }

    return `${finalDay}-${finalMonth}-${year}`;
  } catch (err) {
    return showFutureDateBasedOnMonths(plusMonth);
  }
}

export function showIfDateOfParamsOneMajorThenParamsTwo(dateOneParam: string, dateTwoParam: string) {
  if (dateOneParam.length < 10 || dateTwoParam.length < 10) {
    return false;
  }
  const splittedDateOne = dateOneParam.split('-');
  const dateOneExpirationDay = splittedDateOne[0];
  const dateOneExpirationMonth = splittedDateOne[1];
  const dateOneExpirationYear = splittedDateOne[2];

  const dateOne = DateTime.fromObject({
    day: Number(dateOneExpirationDay),
    month: Number(dateOneExpirationMonth),
    year: Number(dateOneExpirationYear),
  });

  const splittedDateTwo = dateTwoParam.split('-');
  const dateTwoExpirationDay = splittedDateTwo[0];
  const dateTwoExpirationMonth = splittedDateTwo[1];
  const dateTwoExpirationYear = splittedDateTwo[2];

  const dateTwo = DateTime.fromObject({
    day: Number(dateTwoExpirationDay),
    month: Number(dateTwoExpirationMonth),
    year: Number(dateTwoExpirationYear),
  });

  if (dateOne >= dateTwo) {
    return true;
  }
  return false;
}

export function showMonthAsNumberBasedOnName(name: string) {
  switch (name) {
    case 'JANEIRO':
      return 1;
    case 'FEVEREIRO':
      return 2;
    case 'MARÇO':
      return 3;
    case 'ABRIL':
      return 4;
    case 'MAIO':
      return 5;
    case 'JUNHO':
      return 6;
    case 'JULHO':
      return 7;
    case 'AGOSTO':
      return 8;
    case 'SETEMBRO':
      return 9;
    case 'OUTUBRO':
      return 10;
    case 'NOVEMBRO':
      return 11;
    default:
      return 12;
  }
}

export function showMonthAsStringBasedOnNumber(name: number) {
  switch (name) {
    case 1:
      return 'JANEIRO';
    case 2:
      return 'FEVEREIRO';
    case 3:
      return 'MARÇO';
    case 4:
      return 'ABRIL';
    case 5:
      return 'MAIO';
    case 6:
      return 'JUNHO';
    case 7:
      return 'JULHO';
    case 8:
      return 'AGOSTO';
    case 9:
      return 'SETEMBRO';
    case 10:
      return 'OUTUBRO';
    case 11:
      return 'NOVEMBRO';
    default:
      return 'DEZEMBRO';
  }
}

export function showArrayOfMonthsLimitedByStart(selectedYear: number) {
  const now = DateTime.now();
  const startYear = 2022;
  const limitYear = now.year;

  const months: string[] = [];

  if (selectedYear === limitYear) {
    for (let x = 1; x <= now.month; x += 1) {
      months.push(showMonthAsStringBasedOnNumber(x));
    }

    return months;
  }
  return ['JANEIRO', 'FEVEREIRO', 'MARÇO', 'ABRIL', 'MAIO', 'JUNHO', 'JULHO', 'AGOSTO', 'SETEMBRO', 'OUTUBRO', 'NOVEMBRO', 'DEZEMBRO'];
}

export function showAvailableYears() {
  const startYear = 2022;
  const now = DateTime.now();

  const years: string[] = [];

  for (let x = startYear; x <= now.year; x += 1) {
    years.push(x.toString());
  }
  return years;
}

export async function validateSingleDates(date: string) {
  try {
    const initialDateAsDateTime = DateTime.fromFormat(date, 'dd-MM-yyyy');
    if (!initialDateAsDateTime.isValid) {
      throw new Error('Data inicial inválida.');
    }

    return {
      initialDateAsDateTime,
    };
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export function checkIfDateIsToday(date: string) {
  const dateAsDateTime = DateTime.fromFormat(date, 'dd-MM-yyyy');
  const now = DateTime.local({ zone: 'America/Sao_Paulo' });

  if (dateAsDateTime.day === now.day && dateAsDateTime.month === now.month && dateAsDateTime.year === now.year) {
    return true;
  }
  return false;
}
