/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import BasicOption from '../../components/BasicOption';
import ButtonTextIcon, { BtnType } from '../../components/BtnIcon';
import { ModalProps } from '../../components/Modal';
import SwitcherPrimary from '../../components/SwitcherPrimary';
import api from '../../config/axios';
import { maskCPF } from '../../helpers/inputMasks.js';
import DefaultLayout from '../../Layout';
import { AdminProps } from '../../store/reducers/admin';
import changeModalPermission from './changeModalPermission';
import SendEmailContainer from './sendEmailContainer';

export default function UsersManagement() {
  const [loading, setLoading] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showRightSlider, setShowRightSlider] = useState(false);
  const [pendingUsers, setPendingUsers] = useState<AdminProps[]>([]);
  const [confirmedUsers, setConfirmedUsers] = useState<AdminProps[]>([]);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('adminToken')}` } });

  async function fetchAllUsers() {
    try {
      setLoading(true);
      const response = await api.get('/admin/list', token);
      const filterConfirmedUsers = response.data.filter((item: AdminProps) => item.cpf !== null);
      setConfirmedUsers(filterConfirmedUsers);
      const filterPendingUsers = response.data.filter((item: AdminProps) => item.cpf === null);
      setPendingUsers(filterPendingUsers);
      setLoading(false);
    } catch (err) {
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 3000);
      console.log(err);
    }
  }

  async function changeAdminStatus(
    { id, isActive, role }: { id: number, isActive: boolean, role: string },
  ) {
    try {
      setLoading(true);
      await api.put('/admin/update', {
        id: id.toString(),
        isActive,
        role: role === 'representante' ? 'reffers' : role,
      }, token);
      fetchAllUsers();
      setLoading(false);
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
    } catch (err: any) {
      console.log(err.response);
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 3000);
      setLoading(false);
    }
  }

  async function resendEmailToPendentAdmin(id: number) {
    try {
      setLoading(true);
      await api.post(`/admin/register/resend/${id}`, {}, token);
      setLoading(false);
    } catch (err) {
      alert('Não foi possível reenviar o email');
    }
  }

  async function deleteInviteToPendentAdmin(id: number) {
    try {
      setLoading(true);
      await api.delete(`/admin/register/${id}`, token);
      fetchAllUsers();
      setLoading(false);
    } catch (err) {
      alert('Não foi possível reenviar o email');
    }
  }

  function closeAddRightSlider(shouldUpdate: boolean) {
    if (shouldUpdate) {
      setShowRightSlider(false);
      fetchAllUsers();
    } else {
      setShowRightSlider(false);
    }
  }

  useEffect(() => {
    fetchAllUsers();
  }, []);
  return (
    <DefaultLayout
      modal={modal}
      rightSliderContent={(
        <SendEmailContainer
          setClose={() => closeAddRightSlider(false)}
          setCloseAndUpdate={() => closeAddRightSlider(true)}
          setModal={setModal}
        />
)}
      showRightSlider={showRightSlider}
      setCloseRightSlider={() => setShowRightSlider(false)}
      showToastError={showToastError}
      showToastSuccess={showToastSuccess}
      loading={loading}
      pageTitle="Acessos e Permissões"
    >
      <div className="users row">
        <div className="col-12 p-0">
          <div className="left-content card-bg">
            <h1>Usuários cadastrados</h1>
            <div className="table-wrapper">
              <div className="table-actions">
                <button className="table-btn-primary" type="button" onClick={() => setShowRightSlider(true)}>
                  <span className="material-icons-round">
                    person_add
                  </span>
                  adicionar
                </button>
              </div>
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">CPF</th>
                    <th scope="col">Nível de acesso</th>
                    <th className="text-end" scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  { confirmedUsers.map((item, i) => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{maskCPF(item.cpf)}</td>
                      {/* <td>{item.role}</td> */}
                      <td>
                        <BasicOption
                          id=""
                          label="Selecione"
                          readOnly={false}
                          helper=""
                          error=""
                          value={item.role === 'reffers' ? 'representante' : item.role}
                          onChange={(data) => setModal(changeModalPermission(
                            setModal,
                            () => changeAdminStatus({ id: item.id, isActive: item.isActive, role: data }),
                          ))}
                        >
                          {
                              ['admin', 'representante']
                              // ['admin', 'normal', 'representante']
                          }
                        </BasicOption>

                      </td>
                      <td align="right">
                        <SwitcherPrimary
                          id={`status-changer-${i}`}
                          onChange={() => changeAdminStatus(
                            { id: item.id, role: item.role, isActive: !item.isActive },
                          )}
                          isActive={item.isActive}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-12 p-0 mt-4">
          <div className="right-content card-bg">
            <h1>Aguardando finalizar o cadastro</h1>
            <div className="table-wrapper">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">E-mail</th>
                    <th className="text-center" align="center" scope="col">Status</th>
                    <th className="text-end" scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingUsers.map((item) => (
                    <tr>
                      <td>{item.email}</td>
                      <td align="center">Pendente</td>
                      <td align="right">
                        <div className="mb-2">
                          <ButtonTextIcon
                            label="REENVIARR"
                            type={BtnType.SECONDARY}
                            icon="refresh"
                            active
                            onClick={() => resendEmailToPendentAdmin(item.id)}
                            ref={null}
                          />
                        </div>
                        <ButtonTextIcon
                          label="Excluir convite"
                          type={BtnType.DANGER}
                          icon="delete_forever"
                          active
                          onClick={() => deleteInviteToPendentAdmin(item.id)}
                          ref={null}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
